import styled, { keyframes } from "styled-components";

export const ActionContainer = styled.div`
    display:flex;
    justify-content:space-around;
    align-items:center;
    height:auto;
    width:100%;
    gap:30px;
    margin-top:20px;
`;