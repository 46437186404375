import { WhiteLabelWizardContext } from 'context/Context';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import celebration from './lottie/celebration.json';

let interval = undefined;

const Success = () => {

  const { user } = useContext(WhiteLabelWizardContext);
  const [running, setRunning] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (running) {
      interval = setInterval(() => {
        setProgress((prev) => prev + 1);
      }, 100);
    } else {
      clearInterval(interval);
    }
  }, [running]);

  useEffect(() => {
    if (progress === 100) {
      setRunning(false);
      clearInterval(interval);
      navigateUserToNewDashboard();
    }
  }, [progress]);

  const navigateUserToNewDashboard = () => {
    window.location = `https://${user.subdomain}.postlyai.co`;
  }

  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie animationData={celebration} loop={false} />
            </div>
          </div>
          <h4 className="mb-1">Customization successful!</h4>
          <p className="fs-0">Redirecting to your new dashboard in ...{10 - (Math.floor(progress / 10))}s</p>
          <ProgressBar now={progress} />
        </Col>
      </Row>
    </>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired
};

export default Success;
