import styled, { keyframes } from "styled-components";

export const ImageContainer = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    max-height:400px;
    max-width:400px;
`;
export const ImageBgContainer = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    max-height:200px;
    max-width:200px;
`;
export const CreditBox = styled.div`
   height: 50px;
   width: 250px;
   position: absolute;
   top:20px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   right: 10px;
`;
export const CreditBoxLeft = styled.div`
   height: 50px;
   width:auto;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   gap: 5px;
`;
export const Text = styled.p`
   font-size: 15px;
   margin-top: 15px;
`;


