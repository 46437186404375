import React, { useState, useEffect } from 'react';
import StringUtils from 'utils/string';
import PollChoice from './poll_choice';
import {
    shouldAddNewPollChoice,
    setShouldAddNewPollChoice,
    getPollChoiceValue,
    setChoiceData,
    getChoiceData,
    getEditablePost
} from '../../../../../redux/slices/postslice';
import { useDispatch, useSelector } from 'react-redux';

export default function PollChoices(props) {

    const dispatch = useDispatch();
    const canAddNewPollChoice = useSelector(shouldAddNewPollChoice);
    const pollChoiceValue = useSelector(getPollChoiceValue);
    const choiceData = useSelector(getChoiceData);
    const updatablePost = useSelector(getEditablePost);
    const updatablePollData = updatablePost?.data?.poll_data ?? {};

    useEffect(() => {
        if (canAddNewPollChoice) {
            addChoice();
            dispatch(setShouldAddNewPollChoice(false));
        }
    }, [canAddNewPollChoice]);

    const addChoice = () => {
        let choicesCopy = [...choices];
        let initialChoicesLength = choicesCopy.length;
        if (initialChoicesLength < 5) {
            let newLengthOfChoices = initialChoicesLength + 1;
            for (let existingChoice of choicesCopy) {
                existingChoice.choicesLength = newLengthOfChoices;
            }
            let lastChoice = {
                choiceId: StringUtils.generateRandomString(10),
                label: `Choice ${newLengthOfChoices}`,
            };
            choicesCopy.push(lastChoice);
            lastChoice.choiceIndex = choicesCopy.length - 1;
            lastChoice.choicesLength = choicesCopy.length;
            setChoices([...choicesCopy]);
        }
    }

    let initialChoices = [
        {
            choiceId: StringUtils.generateRandomString(10),
            label: `Choice 1`,
            choiceIndex: 0,
        },
        {
            choiceId: StringUtils.generateRandomString(10),
            label: `Choice 2`,
            choiceIndex: 1,
        }
    ];

    initialChoices[1].choicesLength = initialChoices.length;
    const [choices, setChoices] = useState(updatablePost ? [] : [...initialChoices]);

    useEffect(() => {
        if (updatablePost) {
            let updatableChoices = updatablePollData.choices ?? [];
            let startChoices = [];
            let updatableChoicesLength = updatableChoices.length;
            let choiceDataCopy = { ...choiceData };
            for (let i = 0; i < updatableChoices.length; i++) {
                let pollChoiceId = StringUtils.generateRandomString(10);
                let choiceEntry = {
                    choiceId: pollChoiceId,
                    label: `Choice ${i + 1}`,
                    choiceIndex: i,
                    choicesLength: updatableChoicesLength,
                    value: updatableChoices[i],
                }
                startChoices.push(choiceEntry);
                choiceDataCopy[pollChoiceId] = updatableChoices[i];
            }
            dispatch(setChoiceData(choiceDataCopy));
            setChoices([...startChoices]);
        }
    }, [updatablePost]);

    useEffect(() => {
        if (pollChoiceValue) {
            if (pollChoiceValue.choiceId) {
                let choiceDataCopy = { ...choiceData };
                choiceDataCopy[pollChoiceValue.choiceId] = pollChoiceValue.value;
                dispatch(setChoiceData(choiceDataCopy));
            }
        }
    }, [pollChoiceValue]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: 'column',
                gap: 10
            }}>
            {
                choices.map(choice => {
                    return <PollChoice
                        label={choice.label}
                        choiceId={choice.choiceId}
                        choiceIndex={choice.choiceIndex}
                        choicesLength={choice.choicesLength}
                        value={choice.value}
                    />
                })
            }
        </div>
    );
}