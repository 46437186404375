import React, { useEffect, useState } from "react";
import {
    faChevronRight,
    faChevronUp,
    faImage,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import AllSavedCopiesPage, { fetchAllSavedArtCopies, fetchAllSavedLocalCopies, } from './all_saved_copies';
import { getAIWriterResults, setAIWriterResults } from "../../../redux/slices/ai_writer_slice";
import Tabs from "components/common/tabs";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getActiveWorkSpace, getSearchTerm, setActiveWorkspace, setSearchHint } from "redux/slices/workspaceslice";
import APIService from "http/api_service";
import Divider from "components/common/Divider";
import PageHeader from "components/common/PageHeader";

export const art_tools = [
    {
        name: "Generate Art",
        tool: 'GenerateArt',
        description: 'Generate a description for your art',
        navigationTitle: 'What is the name of your art?',
        navigationTitleHint: 'E.g Picture of a mice',
        navigationDescription: 'Describe your art',
        prefix: `Write a creative art description on the following:`,
        prioritizeProduct: true,
        navigationDescriptionHint: 'e.g a growth hacker for small businesses and entrepreneurs',
        optional: true,
        toneable: true,
        icon: faImage,
        color: '#EF6C00'
    },
];

export default function AIArtGenerator(props) {

    const { tools } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [categories, setCategories] = useState(tools);
    const [originalCategories] = useState(categories);
    const [savedTitle] = useState("Saved Copies");
    const savedAIWriterResults = useSelector(getAIWriterResults);
    const workSpace = useSelector(getActiveWorkSpace);
    const searchTerm = useSelector(getSearchTerm);

    const checkAIWriterUsageResumption = () => {
        APIService.checkAIWriterUsageResumption(workSpace['_id'], (res, err) => {
            APIService.fetchWorkSpace(workSpace['_id'], (response, error) => {
                if (error) {
                    return;
                }
                if (response) {
                    let responseData = response['data'];
                    let receivedWorkspace = responseData['workspace'];
                    receivedWorkspace['role'] = responseData['role'];
                    receivedWorkspace['value'] = receivedWorkspace['name'];
                    receivedWorkspace['title'] = responseData['title'];
                    receivedWorkspace['label'] = receivedWorkspace['name'];
                    dispatch(setActiveWorkspace({ ...receivedWorkspace }));
                }
            });
        });
    }

    useEffect(() => {
        setCategories((initialCategories) => {
            return originalCategories?.filter((category) => {
                let containedInTopLevel = category.name.toLowerCase().includes(searchTerm.toLowerCase());
                let containedInLowerLevel = false;
                if (category.sub_items) {
                    let subItemCategory = category.sub_items.filter(x => x.name.toLowerCase().includes(searchTerm.toLowerCase()))
                    containedInLowerLevel = (subItemCategory ?? []).length > 0;
                }
                return containedInTopLevel || containedInLowerLevel;
            });
        });
    }, [searchTerm]);

    useEffect(() => {
        dispatch(setSearchHint("Search AI Writer Categories"));
        let retrievedResults = fetchAllSavedLocalCopies();
        dispatch(setAIWriterResults(retrievedResults));
        checkAIWriterUsageResumption();
        fetchAllSavedArtCopies(workSpace, retrievedResults, dispatch);
    }, []);

    const ContentWritingSubItemCategory = React.forwardRef((props, ref) => {

        const { name, tool, index, icon } = props;

        return (
            <Card
                onClick={(e) => {
                    e.stopPropagation();
                    navigate(`./${tool}?tag=${index}`)
                }}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: 10,
                    paddingLeft: 15,
                    paddingRight: 15,
                    borderRadius: 100
                }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 10,
                        alignItems: 'center'
                    }}>
                    <FontAwesomeIcon
                        icon={icon}
                    />
                    <span>{name}</span>
                </div>
            </Card>
        );
    });

    const ContentWritingCategory = React.forwardRef((props, ref) => {
        const { name, description, tool, icon, sub_items, index, color } = props;
        const [expanded, setExpanded] = useState(false);
        return (
            <Card
                onClick={() => {
                    if (sub_items) {
                        setExpanded(!expanded);
                    } else {
                        navigate(`./${tool}`);
                    }
                }}
                ref={ref}
                style={{
                    width: '80%',
                    padding: 20,
                    paddingTop: 25,
                    paddingBottom: 25,
                    borderRadius: 10,
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        gap: 15
                    }}>
                    {
                        icon &&
                        <FontAwesomeIcon
                            color={color}
                            icon={icon}
                            style={{
                                color: color,
                                width: 40,
                                marginTop: 5,
                                height: 40
                            }}
                        />
                    }
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                        <h6 type={'h6'} style={{ fontSize: 25 }}>{name}</h6>
                        <span style={{ fontSize: 15 }}>{description}</span>
                        <SlideDown className={'my-dropdown-slidedown'}>
                            {
                                sub_items && expanded &&
                                <div
                                    style={{
                                        paddingTop: 15,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                    <h6>Pick a category:</h6>
                                    <Divider />
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                            maxHeight: 600,
                                            width: '100%',
                                            padding: 5,
                                            overflow: 'scroll',
                                            gap: 16
                                        }}>
                                        {
                                            sub_items.map((subItem, index) => {
                                                subItem.tool = tool
                                                subItem.index = index;
                                                return (<ContentWritingSubItemCategory {...subItem} />)
                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </SlideDown>
                    </div>
                    <div style={{ flex: 1 }} />
                    {
                        sub_items &&
                        <FontAwesomeIcon
                            icon={
                                expanded ? faChevronUp : faChevronRight
                            }
                            style={{
                                marginTop: 20
                            }}
                        />
                    }
                </div>
            </Card>
        );
    });

    return (
        <>
            <PageHeader title="AI Art" titleTag="h5" className="mb-3">
                <p className="fs--1 mt-1">Generate Arts by describing what you wish to generate.</p>
            </PageHeader>
            <Tabs
                titles={["Generate",
                    savedAIWriterResults.length > 0 ? `Saved Copies (${savedAIWriterResults.length})` : savedTitle]}
                contents={
                    [
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 20,
                                marginTop: 20
                            }}>
                            {
                                categories?.map((x, index) => {
                                    x.index = index;
                                    return <ContentWritingCategory {...x} />
                                })
                            }
                        </div>,
                        <AllSavedCopiesPage />
                    ]
                }
            />
        </>
    );

}