import React from 'react'
import { EachMetaDataSlide, Header, MainArena } from './metadataPopStyles'
import { convertToTitleFormat, displayPlatformIcon } from 'utils/platform_utils'
import AppContext from 'context/Context';
import { useContext } from 'react';
import Flex from 'components/common/Flex';
import { Card } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';


const MetaDataPop = (props) => {
    const { config } = useContext(AppContext);
    const { isDark } = config
    const { platformMetaData, target, isGeneral, preSchedule } = props
    const imagesKeys = ['picture']

    const truncateWords = (str, numberOfWords = 100) => {
        try {
            if (str.length > numberOfWords) {
                return str.substring(0, numberOfWords) + '...';
            }
            return str;
        } catch (e) {
            return str
        }
    }

    const renderMetaData = () => {
        if (platformMetaData) {
            if (preSchedule) {
                if (platformMetaData?.length) {
                    return <Flex
                        className={'p-2 gap-3'}
                        wrap={'wrap'}
                    >
                        {
                            platformMetaData.map((notification, index) => {
                                return <Card
                                    className='mb-2'
                                    style={{
                                        width: 350
                                    }}
                                >
                                    <Flex
                                        justifyContent={'between'}
                                        className={'p-2 mb-0'}
                                    >
                                        {
                                            notification?.platform && displayPlatformIcon(notification?.platform)
                                        }
                                        <SoftBadge pill bg={notification?.type}>
                                            {notification?.type}
                                        </SoftBadge>
                                    </Flex>
                                    <Card.Body className="p-2">
                                        <h6 className="mb-1">
                                            {notification?.header}
                                        </h6>
                                        <p className="fs--1 mb-0" style={{ wordBreak: 'break-word' }}>
                                            {notification?.message}
                                        </p>
                                    </Card.Body>
                                </Card>
                            })
                        }
                    </Flex>
                } else {
                    return null
                }
            } else {
                return Object.entries(platformMetaData).map((entry) => {
                    let key = entry[0];
                    let data = entry[1];

                    if (Array.isArray(data)) {
                        return data.map((e, index) => {
                            return <>
                                {
                                    Object.entries(e).map((entry) => {
                                        let key = entry[0];
                                        let data = entry[1];
                                        if (imagesKeys.includes(key)) {
                                            return (
                                                <EachMetaDataSlide key={key}>
                                                    <div>{convertToTitleFormat(key)}:</div> <div><img src={data} alt="" height={30} width={50} /></div>
                                                </EachMetaDataSlide>
                                            )
                                        }
                                        return (
                                            <EachMetaDataSlide key={key}>
                                                {data === '' ? <div>{[key]}: N/A</div> : <><div>{convertToTitleFormat(key)}:</div> <div>{data === true ? 'YES' : truncateWords(data)}</div></>}
                                            </EachMetaDataSlide>
                                        )
                                    })
                                }
                                <div
                                    style={{
                                        borderBottom: '1px dashed #ccc',
                                        width: '100%',
                                        marginTop: 10,
                                        marginBottom: 10
                                    }}
                                ></div>
                            </>

                        })
                    }

                    if (key === 'post_approver') {
                        return <EachMetaDataSlide key={key}>
                            <div>{convertToTitleFormat(key)}:</div> <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 5
                                }}
                            >
                                {data.email} {data?.name && `(${data?.name})`} <img src={data.avatar} alt="" height={30} width={30} style={{
                                    borderRadius: '50%'
                                }} />
                            </div>
                        </EachMetaDataSlide>
                    }
                    try {
                        if (data?.includes('http')) {
                            data = <a href={data} target="_blank" rel="noreferrer">
                                {data}
                            </a>
                        }
                    } catch (e) {
                        // do nothing
                    }
                    return (
                        <EachMetaDataSlide key={key}>
                            <div>{convertToTitleFormat(key)}:</div> <div>{data === true ? 'YES' : truncateWords(data)} {convertToTitleFormat(key, true, true)}</div>
                        </EachMetaDataSlide>
                    )
                })
            }
        }
    }

    return (
        <MainArena
            bg={isDark ? '#232e3c' : '#d5e5fa'}
        >
            <Header>
                {preSchedule ? 'Pre-schedule updates' : isGeneral ? 'General Metadata' : ` Metadata for ${convertToTitleFormat(target)}`}
            </Header>
            {renderMetaData()}
        </MainArena>
    )
}

export default MetaDataPop