import SoftBadge from '../../../common/SoftBadge';
import Flex from '../../../common/Flex';
import { Strings } from '../../../../constants';
import { faLemon } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faDiamond, faInfinity, faLayerGroup, faLeaf, faSeedling, faTags } from '@fortawesome/free-solid-svg-icons';

export default function FeedCategoryView(props) {

    let { post } = props;
    let { selectedPostCategory = { name: "Campaign" } } = post ?? {};
    let backgrounds = ["primary", "secondary", "success", "info", "warning", "danger", "light", "dark"];
    let icons = [faLemon, faLayerGroup, faTags, faLeaf, faSeedling, faDiamond, faCube, faInfinity];
    let usedCategoryKeys = ["Campaign", "Category"];

    const getMappingForCategory = (name) => {
        try {
            let mappingsString = localStorage.getItem(Strings.POST_CATEGORY_MAPPINGS);
            if (!mappingsString) {
                let newEntry = { name, position: 0 };
                let newMappings = [newEntry];
                localStorage.setItem(Strings.POST_CATEGORY_MAPPINGS, JSON.stringify(newMappings));
                return newEntry;
            }
            let mappings = JSON.parse(mappingsString);
            let existing = mappings.find(x => x.name === name);
            if (existing) {
                return existing;
            } else {
                let lastMappingsEntry = mappings[mappings.length - 1];
                let lastPosition = lastMappingsEntry?.position ?? 0;
                let newPosition = 0;
                if (lastPosition >= 7) {
                    newPosition = 0;
                } else {
                    newPosition = lastPosition + 1;
                }
                let latestEntry = { name, position: newPosition };
                mappings.push(latestEntry);
                localStorage.setItem(Strings.POST_CATEGORY_MAPPINGS, JSON.stringify(mappings));
                return latestEntry;
            }
        } catch (e) {
            return { name, position: 0 };
        }
    }

    const getColorAndIconForCategory = () => {
        if (usedCategoryKeys.includes(selectedPostCategory?.name)) {
            return null;
        }
        try {
            let randPoint = getMappingForCategory(selectedPostCategory.name)?.position ?? 0;
            return [backgrounds[randPoint], icons[randPoint]];
        } catch (e) {
            return [backgrounds[0], icons[0]];
        }
    }

    const randomColorAndIcon = getColorAndIconForCategory();

    if (!randomColorAndIcon) {
        return null;
    }

    return (
        selectedPostCategory.name !== "Campaign" ?
            <SoftBadge bg={randomColorAndIcon[0]} className='me-2 cursor-pointer'>
                <Flex className={'align-items-center gap-2 px-1'}>
                    <FontAwesomeIcon icon={randomColorAndIcon[1]} />
                    <span>
                        {selectedPostCategory.name}
                    </span>
                </Flex>
            </SoftBadge>
            : null
    )

}