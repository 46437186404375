import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faImage,
    faInfoCircle,
    faClose,
    faPlus
} from '@fortawesome/free-solid-svg-icons';
import { findPlatformByIdentifier } from 'components/app/platforms';
import { MetricsProps, PlatformIdentifier, RoutePaths } from '../../../../constants';
import {
    getYouTubeVideoTitle,
    getYouTubeVideoThumbnail,
    getYouTubeVideoPrivacy,
    setYouTubeVideoPrivacy,
    setYouTubeVideoTitle,
    setCanOpenThumbnailPreviewDialog,
    setThumbnailPreviewData,
    setYouTubeVideoThumbnail,
    setShouldNotifyYouTubeSubscribers,
    shouldNotifyYouTubeSubscribers,
    getUploadableBulkContent,
    setUploadableBulkContent,
    getIsYouTubeVideoEmbeddable,
    getIsYouTubeVideoMadeForKids,
    getRegionCode, getYouTubeVideoCategories,
    getYouTubeVideoCategoryId,
    getYouTubeVideoLicense,
    getYouTubeVideoTags,
    setIsYouTubeVideoMadeForKids,
    setYouTubeVideoCategories,
    setYouTubeVideoCategoryId,
    setYouTubeVideoEmbeddable,
    setYouTubeVideoLicense,
    setYouTubeVideoTags,
    setYouTubeVideoComment,
    getYouTubeVideoComment
} from '../../../../redux/slices/postslice';
import { useSelector, useDispatch } from 'react-redux';
import EditableSection from './debounceable_edit_box';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import { WithContext as ReactTags } from 'react-tag-input';
import './youtube_tags.css';
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import MultimediaSelectionDropdown from 'components/common/MultimediaSelectionDropdown';
import Flex from 'components/common/Flex';
import Divider from 'components/common/Divider';
import PrePublishErrorResolver from './PrePublishErrorResolver';
import { getActiveWorkSpace, getPlatformVideoUploadLimit } from 'redux/slices/workspaceslice';
import StringUtils from 'utils/string';
import { isFreeWorkspace } from 'utils/workspace_utils';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from 'components/common/ConfirmModal';
import { setPopupUpgradeAlert } from 'redux/slices/alertSlice';
import FigureUtils from 'utils/figures';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { setCollectMetric } from 'redux/slices/analytics_slice';

export default function YouTubeOptionsField(props) {

    const {
        bulkContentMode,
        rowIndex = 0
    } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const activeWorkSpace = useSelector(getActiveWorkSpace);
    const uploadableBulkContent = useSelector(getUploadableBulkContent);
    const bulkContentRow = uploadableBulkContent[rowIndex] ?? {};
    const youtubePlatform = findPlatformByIdentifier(PlatformIdentifier.YOUTUBE);
    const [videoErrorMessage, setVideoErrorMessage] = useState(props.videoErrorMessage);
    const videoTitle = useSelector(getYouTubeVideoTitle);
    const videoComment = useSelector(getYouTubeVideoComment);
    const [bulkContentVideoTitle, setBulkContentVideoTitle] = useState(bulkContentRow?.youtube_video_title);
    const videoPrivacy = useSelector(getYouTubeVideoPrivacy);
    const [bulkContentYouTubeVideoPrivacy, setBulkContentYouTubeVideoPrivacy] = useState(bulkContentRow?.youtube_video_privacy?.toLowerCase());
    const youTubeVideoThumbnail = useSelector(getYouTubeVideoThumbnail);
    const [bulkContentYouTubeVideoThumbUrl] = useState(bulkContentRow?.youtube_video_thumbnail_url);
    const [bulkContentYoutubeVideoComment, setBulkContentYoutubeVideoComment] = useState(bulkContentRow?.youtube_video_comment);
    const notifyYouTubeSubscribers = useSelector(shouldNotifyYouTubeSubscribers);
    const platformVideoUploadLimit = useSelector(getPlatformVideoUploadLimit);
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const workspace = useSelector(getActiveWorkSpace);
    const isFreeWorkSpace = isFreeWorkspace(workspace);
    const [show, setShow] = useState(true);
    const bulkContentNotifyYouTubeSubscribersMappings = {
        "Yes": true,
        "YES": true,
        "No": false,
        "NO": false,
        "yes": true,
        "no": false,
        true: true,
        false: false
    }
    const [bulkContentNotifyYouTubeSubscribers, setBulkContentNotifyYouTubeSubscribers] = useState((bulkContentRow?.notify_youtube_subscribers === true || bulkContentRow?.notify_youtube_subscribers === false) ? bulkContentRow?.notify_youtube_subscribers : bulkContentNotifyYouTubeSubscribersMappings[bulkContentRow?.notify_youtube_subscribers] ?? true);
    const youTubePrivacyOptions = [
        {
            label: 'Public', value: 'public'
        },
        {
            label: 'Private', value: 'private'
        },
        {
            label: 'Unlisted', value: 'unlisted'
        }
    ];
    const regionCode = useSelector(getRegionCode);
    const youTubeVideoCategoryId = useSelector(getYouTubeVideoCategoryId);
    const [bulkContentVideoCategoryId, setBulkContentVideoCategoryId] = useState(bulkContentRow?.youTubeVideoCategory ?? youTubeVideoCategoryId);
    const youTubeVideoCategories = useSelector(getYouTubeVideoCategories);
    const youTubeVideoCategoryOptions = youTubeVideoCategories.map(x => {
        return {
            label: x?.snippet?.title,
            value: x.id
        }
    })
    const licenses = [
        {
            label: "Standard YouTube License", value: "youtube"
        },
        {
            label: "Creative Commons - Attribution", value: "creativeCommon"
        }
    ]
    const youTubeVideoLicense = useSelector(getYouTubeVideoLicense);
    const youTubeVideoTags = useSelector(getYouTubeVideoTags);
    const [bulkContentYouTubeVideoTags, setBulkContentYouTubeVideoTags] = useState(bulkContentRow?.youtube_video_tags ?? youTubeVideoTags);
    const madeForKids = useSelector(getIsYouTubeVideoMadeForKids);
    const [bulkContentMadeForKids, setBulkContentMadeForKids] = useState(bulkContentRow?.youtube_video_made_for_kids ?? madeForKids);
    const videoEmbeddable = useSelector(getIsYouTubeVideoEmbeddable);
    const [bulkContentEmbeddable, setBulkContentEmbeddable] = useState(bulkContentRow?.youtube_video_embeddable ?? videoEmbeddable);
    const [openTagView, setOpenTagView] = useState(false);
    const KeyCodes = {
        comma: 188,
        enter: 13
    };
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const convertTagsToJSON = (tagsString) => {
        //check if tagsString is empty or empty space
        try {
            if (!tagsString || tagsString?.trim()?.length === 0) {
                return [];
            }
            const tagsArray = tagsString.split(',').map(tag => tag.trim());

            const jsonTags = tagsArray.map(tag => {
                return {
                    id: tag,
                    text: tag
                };
            });
            return jsonTags;
        } catch (e) {
            return [];
        }
    }

    const removeTag = (tagString, tagToRemove) => {
        const tagsArray = tagString.split(',').map(tag => tag.trim());
        const updatedTagsArray = tagsArray.filter(tag => tag !== tagToRemove);
        const updatedTagsString = updatedTagsArray.join(', ');
        return updatedTagsString;
    }

    const handleDelete = i => {
        let newTags = youTubeVideoTags.filter((tag, index) => index !== i);
        if (bulkContentMode) {
            let jsonArrangedTags = convertTagsToJSON(bulkContentYouTubeVideoTags);
            let newTags = removeTag(bulkContentYouTubeVideoTags, jsonArrangedTags[i].text);
            setBulkContentYouTubeVideoTags(newTags);
            let updatedRow = { ...bulkContentRow }
            updatedRow.youTubeVideoTags = newTags;
            updateBulkContentRow(updatedRow);
        } else {
            dispatch(setYouTubeVideoTags(newTags));
        }
    };

    const addNewTag = (tagsString, newTag) => {
        const tagsArray = tagsString.split(',').map(tag => tag.trim());
        tagsArray.push(newTag);
        const updatedTagsString = tagsArray.join(', ');
        return updatedTagsString;
    }


    const handleAddition = tag => {
        if (bulkContentMode) {
            let newTags = addNewTag(bulkContentYouTubeVideoTags, tag.text);
            setBulkContentYouTubeVideoTags(newTags);
            let updatedRow = { ...bulkContentRow }
            updatedRow.youTubeVideoTags = newTags;
            updateBulkContentRow(updatedRow);
        } else {
            dispatch(setYouTubeVideoTags([...youTubeVideoTags, tag]));
        }
    };

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = (bulkContentMode ? bulkContentYouTubeVideoTags : youTubeVideoTags).slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        if (bulkContentMode) {
            setBulkContentYouTubeVideoTags(newTags);
            let updatedRow = { ...bulkContentRow }
            updatedRow.youTubeVideoTags = newTags;
            updateBulkContentRow(updatedRow);
        } else {
            dispatch(setYouTubeVideoTags(newTags));
        }
    };

    const fetchYouTubeVideoCategories = () => {
        APIService.fetchYouTubeVideoCategories(activeWorkSpace._id, regionCode, (response, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            if (!response) {
                return;
            }
            let { data } = response;
            if (!data) {
                return;
            }
            dispatch(setYouTubeVideoCategories(data));
            dispatch(setYouTubeVideoCategoryId(data[0]?.id));
        });
    }

    const updateBulkContentRow = (updatedRow) => {
        let uploadableBulkContentCopy = [...uploadableBulkContent];
        uploadableBulkContentCopy[rowIndex] = updatedRow;
        dispatch(setUploadableBulkContent(uploadableBulkContentCopy));
    }

    useEffect(() => {
        setVideoErrorMessage(props.videoErrorMessage);
    }, [props.videoErrorMessage]);

    useEffect(() => {
        fetchYouTubeVideoCategories();
    }, []);

    useEffect(() => {
        if (bulkContentMode && bulkContentYouTubeVideoTags) {
            setOpenTagView(true);
        }
    }, []);

    const formatBulkContentStringsValuesIntoView = (value) => {
        if (typeof (value) === 'boolean') {
            return value;
        }
        return ['no', 'No', 'NO'].includes(value) ? false : true;
    }


    const displayYouTubeVideoSizeLimit = () => {
        if (!platformVideoUploadLimit) {
            return `N/A`; // Not available
        }
        if (!platformVideoUploadLimit[PlatformIdentifier.YOUTUBE]) {
            return `N/A`; // Not available
        }
        const statement = FigureUtils.convertBytes(platformVideoUploadLimit[PlatformIdentifier.YOUTUBE])?.value
        if (platformVideoUploadLimit?.canUpgrade) {
            return `${statement} (Upgrade to increase limit)`
        }
        return statement;
    }

    return (
        <Card className='mb-3 shadow-none'>
            <FalconCardHeader
                title={bulkContentMode ? "YouTube" : "For YouTube"}
                endEl={
                    <Flex
                        alignItems="center"
                        justifyContent="center"
                    >
                        {/* <div
                        style={{
                            width: 20,
                            height: 20,
                            borderRadius: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: twitterPlatform.color
                        }}> */}
                        <FontAwesomeIcon
                            icon={youtubePlatform.icon}
                            style={{
                                color: 'white',
                                height: 33,
                                width: 33
                            }}
                        />
                        {/* </div> */}
                        <Flex
                            className="ms-2"
                            // alignItems="center"
                            // justifyContent="center"
                            style={{ cursor: 'pointer' }}
                        >
                            {
                                show ?
                                    <IoIosArrowUp
                                        onClick={() => setShow(false)}
                                        style={{
                                            // color: 'black',
                                            height: 20,
                                            width: 20
                                        }}
                                    /> :
                                    <IoIosArrowDown
                                        onClick={() => setShow(true)}
                                        style={{
                                            // color: 'black',
                                            height: 20,
                                            width: 20
                                        }}
                                    />
                            }
                        </Flex>
                    </Flex>
                } />
            {
                show &&
                <Card.Body className="bg-light">
                    <Flex direction={'column'}>
                        <Form.Check
                            style={{
                                alignSelf: 'flex-end'
                            }}
                            id={'notify-youtube-subscribers-check'}
                            type='checkbox'
                            label={'Notify Subscribers'}
                            checked={bulkContentMode ? bulkContentNotifyYouTubeSubscribers : notifyYouTubeSubscribers}
                            onChange={(e) => {
                                let value = e.target.checked;
                                if (bulkContentMode) {
                                    setBulkContentNotifyYouTubeSubscribers(value);
                                    let updatedRow = { ...bulkContentRow }
                                    updatedRow.notify_youtube_subscribers = value;
                                    updateBulkContentRow(updatedRow);
                                } else {
                                    dispatch(setShouldNotifyYouTubeSubscribers(value));
                                }
                            }}
                        />
                        <Row>
                            <Col lg={6}>
                                <EditableSection
                                    value={bulkContentMode ? bulkContentVideoTitle : videoTitle}
                                    placeholder={'Video Title'}
                                    title={'Video Title'}
                                    onChangeHandler={(value) => {
                                        if (bulkContentMode) {
                                            setBulkContentVideoTitle(value);
                                            let updatedRow = { ...bulkContentRow }
                                            if (!value) {
                                                value = " ";
                                            }
                                            updatedRow.youtube_video_title = value;
                                            updateBulkContentRow(updatedRow);
                                        } else {
                                            dispatch(setYouTubeVideoTitle(value));
                                        }
                                    }}
                                />
                            </Col>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Video Privacy</Form.Label>
                                    <Form.Select
                                        value={bulkContentMode ? bulkContentYouTubeVideoPrivacy : videoPrivacy}
                                        placeholder={'Select video Privacy for YouTube'}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            if (bulkContentMode) {
                                                setBulkContentYouTubeVideoPrivacy(value);
                                                let updatedRow = { ...bulkContentRow }
                                                if (!value) {
                                                    value = "public";
                                                }
                                                updatedRow.youtube_video_privacy = value;
                                                updateBulkContentRow(updatedRow);
                                            } else {
                                                dispatch(setYouTubeVideoPrivacy(value));
                                            }
                                        }}
                                    >
                                        {
                                            youTubePrivacyOptions.map((x) => {
                                                return (<option value={x.value}>{x.label}</option>)
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        {
                            !bulkContentMode &&
                            <div
                                className='mt-3 mb-3'
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                <MultimediaSelectionDropdown
                                    dropDownAnchor={
                                        <span id='global-photo-span'>
                                            <Button
                                                style={{ width: '100%' }}
                                                variant={'falcon-light'}
                                                id='youtube-thumbnail-select'>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        gap: 5
                                                    }}>
                                                    <FontAwesomeIcon
                                                        icon={faImage}
                                                    />
                                                    <span className='fs--1'>Select an optional video thumbnail for YouTube video
                                                    </span>
                                                </div>
                                            </Button>
                                        </span>
                                    }
                                    alignment={"start"}
                                    initiatorId={"youtube-thumbnail-select"}
                                    prompt={"Select Thumbnail From"}
                                    fileType={"image"}
                                    multiple={false}
                                    externalFilePickerTitle={"Enter Thumbnail Location"}
                                    externalPickerPromptLabel={"Thumbnail"}
                                    platformIdentifier={PlatformIdentifier.YOUTUBE}
                                    thumbnail={true}
                                />
                            </div>
                        }
                        <Row>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Video Category</Form.Label>
                                    <Form.Select
                                        value={bulkContentMode ? bulkContentVideoCategoryId : youTubeVideoCategoryId}
                                        placeholder={'Select Video Category'}
                                        onChange={(e) => {
                                            if (bulkContentMode) {
                                                setBulkContentVideoCategoryId(e.target.value);
                                                let updatedRow = { ...bulkContentRow }
                                                updatedRow.youTubeVideoCategory = e.target.value;
                                                updateBulkContentRow(updatedRow);
                                            } else {
                                                dispatch(setYouTubeVideoCategoryId(e.target.value));
                                            }
                                        }}>
                                        {
                                            youTubeVideoCategoryOptions.map((x) => {
                                                return (<option value={x.value}>{x.label}</option>)
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Video License</Form.Label>
                                    <Form.Select
                                        value={youTubeVideoLicense}
                                        placeholder={'License'}
                                        onChange={(e) => {
                                            dispatch(setYouTubeVideoLicense(e.target.value));
                                        }}>
                                        {
                                            licenses.map((x) => {
                                                return (<option value={x.value}>{x.label}</option>)
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='mt-4'>
                            <Form.Group>
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder={'Description'}
                                    value={bulkContentMode ? bulkContentYoutubeVideoComment : videoComment}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        if (bulkContentMode) {
                                            setBulkContentYoutubeVideoComment(value);
                                            let updatedRow = { ...bulkContentRow }
                                            if (!value) {
                                                value = " ";
                                            }
                                            updatedRow.youtube_video_comment = value;
                                            setBulkContentYoutubeVideoComment(updatedRow);
                                        } else {
                                            if (isFreeWorkSpace) {
                                                let metricInitializer = {
                                                    action: MetricsProps.YOUTUBE_FIRST_COMMENT,
                                                    detailedAction: `Upgrade notice: YouTube video description`,
                                                    timestamp: new Date().toISOString(),
                                                    route: window.location.pathname.replace(workspace._id, '').replace('//', '/'),
                                                }
                                                dispatch(setCollectMetric(metricInitializer))
                                                dispatch(setPopupUpgradeAlert(true))
                                                dispatch(setYouTubeVideoComment(''));
                                                return;
                                            }
                                            dispatch(setYouTubeVideoComment(value));
                                        }
                                    }}
                                />
                            </Form.Group>
                        </Row>
                        <Row className='mt-4'>
                            <Col lg={6}>
                                <Flex direction={'column'}>
                                    <h6>Tags
                                    </h6>
                                    {
                                        !openTagView &&
                                        <Button
                                            variant='falcon-light'
                                            size='sm'
                                            onClick={() => {
                                                setOpenTagView(!openTagView);
                                            }}
                                            style={{
                                                fontSize: 13,
                                                border: '1px dashed gray'
                                            }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    gap: 2,
                                                    alignItems: 'center'
                                                }}>
                                                <FontAwesomeIcon icon={faPlus} />
                                                <span>New Tag</span>
                                            </div>
                                        </Button>
                                    }
                                    {
                                        openTagView &&
                                        <Flex alignItems={'center'} className={'gap-3'}>
                                            <ReactTags
                                                placeholder='Type and press enter or comma to add a new tag'
                                                tags={bulkContentMode ? convertTagsToJSON(bulkContentYouTubeVideoTags) : youTubeVideoTags}
                                                delimiters={delimiters}
                                                handleDelete={handleDelete}
                                                handleAddition={handleAddition}
                                                handleDrag={handleDrag}
                                                inputFieldPosition="bottom"
                                                autocomplete
                                            />
                                            <FontAwesomeIcon
                                                style={{
                                                    cursor: 'pointer'
                                                }}
                                                icon={faClose}
                                                onClick={() => {
                                                    setOpenTagView(!openTagView);
                                                }}
                                            />
                                        </Flex>
                                    }
                                </Flex>
                            </Col>
                        </Row>
                        <Flex justifyContent={'space-between'} className={'mt-4'} alignItems={'space-between'}>
                            <Form.Check
                                type='checkbox'
                                id={'made-for-kids-check'}
                                label={'Made for Kids?'}
                                checked={bulkContentMode ? formatBulkContentStringsValuesIntoView(bulkContentMadeForKids) : madeForKids}
                                onChange={(e) => {
                                    let value = e.target.checked;
                                    if (bulkContentMode) {
                                        setBulkContentMadeForKids(value);
                                        let updatedRow = { ...bulkContentRow }
                                        updatedRow.youtube_video_made_for_kids = value;
                                        updateBulkContentRow(updatedRow);
                                    } else {
                                        dispatch(setIsYouTubeVideoMadeForKids(value));
                                    }
                                }}
                            />
                            <div style={{ flex: 1 }}></div>
                            <Form.Check
                                id={'embeddable-check'}
                                type={'checkbox'}
                                label={'Embeddable?'}
                                checked={bulkContentMode ? formatBulkContentStringsValuesIntoView(bulkContentEmbeddable) : videoEmbeddable}
                                onChange={(e) => {
                                    let value = e.target.checked
                                    if (bulkContentMode) {
                                        setBulkContentEmbeddable(value);
                                        let updatedRow = { ...bulkContentRow }
                                        updatedRow.youtube_video_embeddable = value;
                                        updateBulkContentRow(updatedRow);
                                    } else {
                                        dispatch(setYouTubeVideoEmbeddable(value));
                                    }
                                }}
                            />
                        </Flex>
                        {
                            (youTubeVideoThumbnail || bulkContentYouTubeVideoThumbUrl) &&
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    position: 'relative'
                                }}>
                                <h6
                                    type={'h5'}
                                    style={{
                                        fontSize: 15
                                    }}>Selected YouTube Video Thumbnail
                                </h6>
                                <img
                                    onClick={() => {
                                        if (!bulkContentMode) {
                                            dispatch(setThumbnailPreviewData(youTubeVideoThumbnail));
                                            dispatch(setCanOpenThumbnailPreviewDialog(true));
                                        }
                                    }}
                                    src={bulkContentMode ? bulkContentYouTubeVideoThumbUrl : StringUtils.isStringAFirebaseLink(youTubeVideoThumbnail) ? youTubeVideoThumbnail : youTubeVideoThumbnail['url']}
                                    height={bulkContentMode ? 100 : 200}
                                    width={'100%'}
                                    alt={"YouTube Video Thumbnail"}
                                    style={{
                                        borderRadius: 5,
                                        objectFit: 'cover'
                                    }}
                                />
                                {
                                    !bulkContentMode &&
                                    <Button
                                        variant="text"
                                        size='sm'
                                        style={{
                                            backgroundColor: 'rgba(0,0,0,0.9)',
                                            color: 'white',
                                            position: 'absolute',
                                            bottom: 10,
                                            left: 0,
                                            right: 0,
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            width: 100
                                        }}
                                        onClick={() => {
                                            dispatch(setYouTubeVideoThumbnail(null));
                                        }}
                                    >
                                        Remove
                                    </Button>
                                }
                            </div>
                        }
                        {
                            videoErrorMessage &&
                            <PrePublishErrorResolver
                                error={videoErrorMessage}
                            />
                        }
                        {
                            !bulkContentMode &&
                            <Flex direction={'column'}>
                                <Divider />
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 5
                                    }}>
                                    <Alert variant="warning" className="p-2 mb-0">
                                        <Flex>
                                            <FontAwesomeIcon icon="exclamation-triangle" className="fs-2" />
                                            <div className="ms-3 flex-1">
                                                <h4 className="alert-heading">Please note</h4>
                                                1. YouTube Shorts can be up to 60 seconds in length. Videos must be in a vertical orientation with a 9:16 aspect ratio.
                                                <br />
                                                2. Description will be used as YouTube 1st Comment.
                                                3. Maximum allowed size for video is {displayYouTubeVideoSizeLimit()}.
                                            </div>
                                        </Flex>
                                    </Alert>
                                    {/* <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    style={{
                                        width: 15,
                                        height: 15,
                                    }}
                                />
                                <span
                                    style={{
                                        fontSize: 12,
                                    }}>YouTube converts videos that are less than 2MB and 60 seconds automatically to YouTube Shorts.
                                </span> */}
                                </div>
                            </Flex>
                        }
                    </Flex>
                </Card.Body>
            }
            <ConfirmModal
                open={openUpgradeDialog}
                title={"Upgrade Account"}
                message={"This feature requires an account upgrade. Kindly upgrade to a premium account to continue"}
                confirmText={"UPGRADE"}
                cancelText={"CANCEL"}
                onCancel={() => {
                    setOpenUpgradeDialog(false);
                    if (isFreeWorkSpace) {
                        dispatch(setYouTubeVideoComment(''));
                    }
                }}
                onConfirm={() => {
                    setOpenUpgradeDialog(false);
                    navigate(`${RoutePaths.BILLING_STRIPE}`)
                }}
            />
        </Card>
    );
}