import React, { useState } from 'react';
import { GoogleIcon } from './google_icon';

export default function AuthLoginButton(props) {

    const [hovered, setHovered] = useState(false);

    const { icon, color, label, style, disabled = false, onClick, ...otherProps } = props

    const baseStyle = {
        height: '39px',
        width: '100%',
        border: `2px solid ${color ?? '#4285f4'}`,
        borderRadius: '4px',
        fontSize: '15px',
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'background-color .218s, border-color .218s, box-shadow .218s',
        cursor: 'pointer',
        userSelect: 'none',
        ...style
    }

    const darkStyle = {
        backgroundColor: props?.backgroundColor ?? color ?? '#4285f4',
        color: '#fff',
        ...baseStyle
    }

    const lightStyle = {
        backgroundColor: props?.backgroundColor ?? '#fff',
        color: 'rgba(0,0,0,.54)',
        ...baseStyle
    }

    const iconStyle = {
        width: '48px',
        textAlign: 'center',
        display: 'block',
        borderRadius: '1px',
    }

    const svgStyle = {
        width: '48px',
        height: '40px',
        display: 'block'
    }

    const hoverStyle = {
        boxShadow: '0 0 3px 3px rgba(66,133,244,.2)',
        transition: 'background-color .218s, border-color .218s, box-shadow .218s'
    }

    const disabledStyle = {
        backgroundColor: 'rgba(37, 5, 5, .08)',
        color: 'rgba(0, 0, 0, .40)',
        cursor: 'not-allowed'
    }

    const disabledIconStyle = {
        backgroundColor: 'transparent'
    }

    const getStyle = (propStyles) => {
        const baseStyle = props.type === 'dark' ? darkStyle : lightStyle
        if (hovered) {
            return { ...baseStyle, ...hoverStyle, ...propStyles }
        }
        if (disabled) {
            return { ...baseStyle, ...disabledStyle, ...propStyles }
        }
        return { ...baseStyle, ...propStyles }
    }

    const mouseOver = () => {
        if (!disabled) {
            setHovered(true);
        }
    }

    const mouseOut = () => {
        if (!disabled) {
            setHovered(false);
        }
    }

    const click = e => {
        if (!disabled) {
            onClick(e)
        }
    }

    return (
        <div
            {...otherProps}
            role="button"
            onClick={click}
            style={getStyle(style)}
            onMouseOver={!props?.disableMouseActions ? mouseOver : null}
            onMouseOut={!props?.disableMouseActions ? mouseOut : null}
        >
            {icon === 'no-icon' ? null : icon ?? <GoogleIcon {...props} />}
            <span style={{
                color: color ?? '#4285f4'
            }}>{label}</span>
        </div>
    )
}