import React from 'react';
import { reports } from 'data/support-desk/quickLinksData';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faTwitter, faLinkedinIn, faYoutube, faGoogle, faPinterest, faRedditAlien, faTiktok, faWordpress, faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const iconContainerStyle = {
  display: "flex",
  fontSize: "24px",
  borderRadius: "50%",
};

const getIcon = (title) => {
  switch (title) {
    case "Facebook":
      return <FontAwesomeIcon icon={faFacebookF} style={{ color: "#1877f2" }} />;
    case "Instagram":
      return <FontAwesomeIcon icon={faInstagram} style={{ color: "#e4405f" }} />;
    case "Twitter":
      return <FontAwesomeIcon icon={faTwitter} style={{ color: "#1da1f2" }} />;
    case "LinkedIn":
      return <FontAwesomeIcon icon={faLinkedinIn} style={{ color: "#0e76a8" }} />;
    case "YouTube":
      return <FontAwesomeIcon icon={faYoutube} style={{ color: "#ff0000" }} />;
    case "Google My Business":
      return <FontAwesomeIcon icon={faGoogle} style={{ color: "#db4437" }} />;
    case "Pinterest":
      return <FontAwesomeIcon icon={faPinterest} style={{ color: "#bd081c" }} />;
    case "Reddit":
      return <FontAwesomeIcon icon={faRedditAlien} style={{ color: "#ff4500" }} />;
    case "TikTok":
      return <FontAwesomeIcon icon={faTiktok} style={{ color: "#000000" }} />;
    case "WordPress":
      return <FontAwesomeIcon icon={faWordpress} style={{ color: "#21759b" }} />;
    case "Telegram":
      return <FontAwesomeIcon icon={faTelegramPlane} style={{ color: "#0088cc" }} />;
    default:
      return null;
  }
};

const ReportsItem = ({ report }) => {
  return (
    <Col lg={4} xxl={4}>
      <div className="card bg-light rounded-3 border">
        <div className="card-body">
          <div style={iconContainerStyle}>
            {getIcon(report.title)}
          </div>
          {/* <img src={report.img} alt="" width="39" /> */}
          <h5 className="card-title mt-2">
            <a href={report.link} className="text-dark stretched-link" target='_blank' rel="noreferrer">
              {report.title}
            </a>
          </h5>
          <h6 className="card-subtitle mb-2 text-muted">
            {report.description}
          </h6>
        </div>
      </div>
    </Col>
  );
};

ReportsItem.propTypes = {
  report: PropTypes.shape({
    img: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  })
};

const Reports = () => {
  return (
    <Card>
      <Card.Header className="bg-light">
        <h4 className="text-600 mb-1">Help Center</h4>
      </Card.Header>
      <Card.Header className="position-relative border-bottom">
        <div className="position-relative z-index-2">
          <Flex className="py-3 flex-wrap">
            <div className="pe-3 col-lg-4 mb-4">
              <h4 className="text-primary">Account Support</h4>
              <p className="text-muted">If you have any questions or need assistance with your Postly account, please reach out to us.</p>
              <p className="mb-0"><strong>Email:</strong> <a href="mailto:support@postly.ai">support@postly.ai</a></p>
            </div>
            <div className="pe-3 col-lg-4 mb-4">
              <h4 className="text-primary">Talk to Sales Team</h4>
              <p className="text-muted">For inquiries related to product pricing, plans, or any other sales-related questions.</p>
              <p className="mb-0"><strong>Email:</strong> <a href="mailto:sales@postly.ai">sales@postly.ai</a></p>
            </div>
            <div className="pe-3 col-lg-4 mb-4">
              <h4 className="text-primary">Join Our Community</h4>
              <p className="text-muted">Gain access to valuable digital marketing strategy tips and insights.</p>
              <p className="mb-0"><strong>Link:</strong> <a href="https://www.facebook.com/groups/postly.community" target='_blank' rel="noreferrer">Join Postly Community</a></p>
            </div>
          </Flex>
          <Card className="bg-light" style={{
            boxShadow: 'none'
          }}>
            <Card.Body className="p-3">
              <p className="fs--1 mb-0">
                <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                When contacting for support, please include your Postly account email address and details of the issue, along with screenshots or video recordings.
              </p>
            </Card.Body>
          </Card>
        </div>
      </Card.Header>
      <Card.Body>
        <h5 className="fs-0 mb-2">General Support</h5>
        <Row className="g-3">
          {reports.general_support.map((report, index) => (
            <ReportsItem key={index} report={report} />
          ))}
        </Row>
        <h5 className="fs-0 mb-2 mt-5">Account Connection Help</h5>
        <Row className="g-3">
          {reports.accountConnection.map((report, index) => (
            <ReportsItem key={index} report={report} />
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Reports;
