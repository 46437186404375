import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: [],
}

export const postMediaArrangementSlice = createSlice({
    name: 'media_arrangement',
    initialState,
    reducers: {
        setMediaArrangement: (state, action) => {
            state.value = action.payload;
        }
    }
});

export let getMediaArrangement = (state) => state.media_arrangement.value;
export let {
    setMediaArrangement
} = postMediaArrangementSlice.actions;
export default postMediaArrangementSlice.reducer;