import React, { useEffect, useState } from 'react'
import StatisticsCard from './stats-cards/StatisticsCard'
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import { useSelector } from 'react-redux';
import { RoutePaths } from 'constants';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';
import { getTotalWorkSpaces } from 'redux/slices/admin_slice';

const TotalWorkspaces = () => {
    const [stat, setStat] = useState({
        title: 'Workspaces',
        value: 0,
        decimal: false,
        suffix: '',
        prefix: '',
        valueClassName: 'text-info',
        badgeBg: 'info',
        badgeText: '',
        link: null,
        // link: RoutePaths.ALL_WORKSPACES,
        linkText: null,
        // linkText: 'All workspaces',
        image: bg2
    })
    const totalWorkspaces = useSelector(getTotalWorkSpaces)

    useEffect(() => {
        if (!totalWorkspaces) return;
        setStat({
            ...stat,
            value: totalWorkspaces
        })
    }, [totalWorkspaces])


    return (
        <>
            <StatisticsCard stat={stat} style={{ minWidth: '12rem' }} />
        </>
    )
}

export default TotalWorkspaces