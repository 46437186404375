import { toast } from "react-toastify";
import { forcefullyLogout } from "./auth_utils";

export default class HttpErrorHandler {
    static spitHttpErrorMsg(e) {
        if (e !== undefined && e !== null) {
            if (e.hasOwnProperty('response')) {
                let response = e.response;
                if (response !== undefined && response !== null) {
                    if (response.hasOwnProperty('data')) {
                        let responseData = response['data'];
                        if (responseData && responseData.hasOwnProperty('message')) {
                            let error = responseData['message'];

                            console.log("CR_stamp 👀 ~ file: http_error_handler.js:15 ~ HttpErrorHandler ~ spitHttpErrorMsg ~ error:", error)

                            if (error?.toLowerCase() === `no such account`
                                || error?.toLowerCase() === `apikey is missing`
                                || error?.toLowerCase()?.includes("invalid session")
                                || error?.toLowerCase()?.includes("compulsory reauthentication required")
                                || error?.toLowerCase()?.includes("api_key missing") || error?.toLowerCase()?.includes("invalid api_key provided")) {
                                console.log('about to forcefully logout');
                                if (error?.toLowerCase()?.includes("compulsory reauthentication required")) {
                                    toast.error('Mandatory re-authentication required. You will be taken to the login page now.', {
                                        theme: 'colored',
                                    });
                                    setTimeout(() => {
                                        forcefullyLogout();
                                    }, 5000);
                                } else {
                                    forcefullyLogout()
                                }

                            } else {
                                return responseData['message'];
                            }
                        }
                    }
                }
            }
        }
        if (navigator.onLine) {
            console.log('error on key: navigator.onLine. Reporting to console.')
        }
        return navigator.onLine ? '' : "Oops! An error occurred!.\nEnsure your data connection is still right.";
    }
}