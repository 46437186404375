import styled, { keyframes } from "styled-components";

const fadeInAnimation = keyframes`
from {
  opacity: 0;
  margin-left:-10%;
}
to {
  opacity: 1;
  margin-left:0%;
}
`;

export const ParentSection = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  animation: ${fadeInAnimation} 0.3s linear;
  cursor: auto;
  justify-content: center;
  align-items: flex-start;
`;

export const Wrapper = styled.div`
  /* flex: 9; */
  /* height: fit-content; */
  width: 100%;
  position: relative;
`;

export const PreviewContentArea = styled.div`
  /* border-style:solid;
  border-width:1px; */
  width: 100%;
  background-color: ${(props) => props.mainBgColor};
  /* z-index: 2; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-top: 10px;
  padding-top: 8px;
  /* margin-bottom: -60px; */
  animation: ${fadeInAnimation} 0.4s linear;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0 !important;
  }
`;


export const TopHeader = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const NameAndTime = styled.div`
padding-top:5px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction:column;
`;

export const Main = styled.div`
  height: fit-content;
  width: 94%;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  margin: 10px 6px 0px 6px;
  gap: 10px;
`;

export const LeftSide = styled.div`
  height: 100%;
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:10px;
`;

export const ImageHolder = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow:auto;
  overflow-y:hidden;
  gap:5px;
`;

export const TextsHolder = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  gap:10px;
  align-items: center;
  flex-direction:column;
`;

export const TextsHolderTitle = styled.div`
  height: 100%;
  width: 100%;
  flex:1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const TextsHolderCenter = styled.div`
  height: 100%;
  width: 100%;
  flex:4;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const LearnMoreBtn = styled.div`
  height: 80%;
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius:8px;
  gap:5px;
  margin-bottom:10px;
`;

export const TextsHolderBottom = styled.div`
  height: 100%;
  width: 100%;
  flex:1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const RightSide = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding:0 10px;
  align-items: center;
`;

export const BottomHeader = styled.div`
  height: 25px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  padding-left: 10px;
`;

export const TopHeaderLeft = styled.div`
  height: 100%;
  width: 100%;
  flex: 5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  gap:10px;
`;

export const TopHeaderRight = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const PencilEdit = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
  
  :active {
    opacity:0;
  }
`;

export const VideoEdit = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
  :active {
    opacity:0;
  }
`;

export const TextEdit = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
  
  :active {
    opacity:0;
  }
`;

export const ImageDelete = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  color: white;
  right: 5%;
  top: 2%;
  cursor: pointer;
  transition: transform 0.2s;
  
  :active {
    opacity:0;
  }
`;

export const EditContainer = styled.div`
  min-height: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: center;
  gap:10px;
  border-radius: 8px;
  margin-top: 10px;
  flex: 1;
`;