import styled, { keyframes } from "styled-components";

export const Wrapper = styled.section`
padding:1%;
  height: fit-content;
  max-width: 100%;
  position: relative;
  display:grid;
  grid-template-columns:repeat( auto-fit, minmax(130px, 1fr));
  justify-content: center;
  align-content: start;
  gap:10px;
`;
export const VideoWrapper = styled.section`
padding:1%;
  height: fit-content;
  max-width: 100%;
  position: relative;
  display:flex;
  flex-wrap:wrap;
  justify-content: center;
  align-content: start;
  gap:20px;

`;
export const EachImageBox = styled.div`
  /* background-color:red; */
  height: 100px;
  width: 100%;
  position: relative;
`;
export const FlexDivInput = styled.div`
  display: flex;
  gap:5px;
  justify-content: flex-start;
  align-items: center;
`;
export const CiteDrop = styled.div`
  height:100%;
  width: 100%;
  position: absolute;
  background-color: #0b1727ad;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  flex-direction: column;
  padding: 2%;
  text-align: center;
  cursor: pointer;
`;