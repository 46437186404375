/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  faCommentDots,
  faHeart,
  faHome,
  faPencil,
  faPlus,
  faRemove,
  faSearch,
  faShare,
  faUserFriends,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import { PlatformData, PlatformIdentifier } from "../../../../../constants";
import {
  getGoogleDriveManager,
  getManuallyEditedPlatforms,
  getMediaAddedViaSpecificPlaformUrlMethod,
  getSelectingPhotoFromHome,
  getSpecificMediaPlatformTied,
  setGoogleDriveManager,
  setManuallyEditedPlatforms,
  setSpecificMediaPlatformTied,
} from "../../../../../redux/slices/postslice";
import { faMessage, faUser } from "@fortawesome/free-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { darkTheme, lightTheme } from "utils/theme";
import { toast } from "react-toastify";
import { platforms } from "../../../platforms";
import MultimediaSelectionDropdown from "components/common/MultimediaSelectionDropdown";
import EditTextBox from "./editTextBox.js";
import PlayMediaTikTok from "./playMediaTikTok.js";
import {
  BottomIconBox,
  BottomIconBoxCenter,
  FirstSpace,
  FollowWrapper,
  IconHolder,
  IconsContainer,
  ImageDelete,
  LastSpace,
  ParentSection,
  PreviewContentArea,
  TextSpace,
  VideoWrapper,
  Wrapper,
} from "./styles/tiktok";
import AppContext from "context/Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularButton from "components/common/circularbutton";
import GDriveDashboard from "components/common/g-drive/dashboard";
import ConfirmModal from "components/common/ConfirmModal";
import { getPublishablePostMedia } from "redux/slices/post_media_slice";
import { getMediaArrangement, setMediaArrangement } from "redux/slices/publishable_media_arrangement_slice";
import Flex from "components/common/Flex";
import { Info } from "./styles/facebook";
import { displayPlatformIcon } from "utils/platform_utils";
import { getPlatformVideoUploadLimit } from "redux/slices/workspaceslice";
import FigureUtils from "utils/figures";

const TikTok = (props) => {
  const { viewOnly } = props;
  const dispatch = useDispatch();
  const googleDriveManager = useSelector(getGoogleDriveManager)
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const googleDriveRef = useRef(null);
  const [currentMediaType, setCurrentMediaType] = useState(null);
  const specificMediaPlatformTied = useSelector(getSpecificMediaPlatformTied);
  const mediaObjects = useSelector(getPublishablePostMedia);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [addViaUrl, setAddViaUrl] = useState();
  const [openMediaSelction, setOpenMediaSelection] = useState(false);
  const mediaAddedViaSpecificPlaformUrlMethod = useSelector(
    getMediaAddedViaSpecificPlaformUrlMethod
  );
  const selectingPhotoFromHome = useSelector(getSelectingPhotoFromHome);
  const [toggleEditTextInput, setToggleEditTextInput] = useState(false);
  const media_items_arrangement = useSelector(getMediaArrangement);
  const [limitedPostTextBody, setLimitedPostTextBody] = useState(
    media_items_arrangement?.tiktok?.text
  );
  const [timer, setTimer] = useState();
  const textEditRef = useRef(null);
  const [seeMore, setSeeMore] = useState(false);
  const [openVideoEditArea, setOpenVideoEditArea] = useState(false);
  const [blockMediaItemsUpdate, setBlockMediaItemsUpdate] = useState(true);
  const manuallyEditedPlatforms = useSelector(getManuallyEditedPlatforms);
  const platformsWithLimits = [...platforms];
  const { config } = useContext(AppContext);
  const [textToDisplay, setTextToDisplay] = useState('');
  const [platform] = useState(PlatformIdentifier.FACEBOOK)
  const platformVideoUploadLimit = useSelector(getPlatformVideoUploadLimit);
  let typingTimeout;

  useEffect(() => {
    const shouldOpenTextEdit = localStorage.getItem('open_hashtag_pop');
    if (shouldOpenTextEdit == 'true') {
      handleEditClick();
    }
  }, [])

  let videoInfoText = `TikTok videos should be 1080 x 1920`;
  let editVideoFormat = `TikTok videos support MP4 and MOV format`;

  const registerDraft = () => {
    setAddViaUrl(media_items_arrangement?.tiktok?.media[0]);
  };

  const dispatchEditedFlag = () => {
    if (!manuallyEditedPlatforms[PlatformIdentifier.TIKTOK]) {
      const newData = { ...manuallyEditedPlatforms, tiktok: true };
      dispatch(setManuallyEditedPlatforms(newData || {}));
    }
  };

  const syncNewThread = async () => {
    let syncingThread = [];
    if (!selectedVideo) {
      const oldData = { ...media_items_arrangement };
      const diapatchableData = {
        ...oldData,
        tiktok: { ...oldData.tiktok, media: [] },
      };
      dispatch(setMediaArrangement(diapatchableData));
      return;
    }
    try {
      const newThreadEntry = {
        name: selectedVideo?.name,
        url: selectedVideo?.url,
        is_video: selectedVideo?.is_video,
        external: selectedVideo?.external ?? false,
        uploaded: selectedVideo?.uploaded ?? false,
        file: selectedVideo?.file,
      };
      const oldData = { ...media_items_arrangement };
      const diapatchableData = {
        ...oldData,
        tiktok: { ...oldData.tiktok, media: [newThreadEntry] },
      };
      dispatch(setMediaArrangement(diapatchableData));
    } catch (error) { }
    return syncingThread;
  };

  const handleUpdateTextFromEditBox = (text) => {
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {
      dispatchEditedFlag();
      const oldData = { ...media_items_arrangement };
      const getTextLimit = platformsWithLimits.find(
        (x) => x.identifier === PlatformIdentifier.TIKTOK
      );
      const diapatchableData = {
        ...oldData,
        tiktok: {
          ...oldData.tiktok,
          text: text.substring(0, getTextLimit.textLimit),
        },
      };
      dispatch(setMediaArrangement(diapatchableData));
    }, 1000);
  };

  useEffect(() => {
    if (!blockMediaItemsUpdate) {
      setBlockMediaItemsUpdate(true);
      syncNewThread();
    }
  }, [selectedVideo]);

  useEffect(() => {
    registerDraft();
  }, []);

  useEffect(() => {
    addViaUrl && setSelectedVideo(addViaUrl);
  }, [addViaUrl]);

  const mediaRemoveHandler = (removableMediaObject) => {
    try {
      setBlockMediaItemsUpdate(false);
      setAddViaUrl(null);
      setSelectedVideo(null);
    } catch (error) { }
  };

  const handleDeleteMedia = (data) => {
    dispatchEditedFlag();
    mediaRemoveHandler(data);
  };

  const mediaOpener = (
    <div
      style={{
        position: "relative",
        zIndex: 5,
        width: "96%",
        marginLeft: "4%",
        marginBottom: 20,
        marginTop: 20,
        width: "85%",
      }}
    >
      {
        <MultimediaSelectionDropdown
          specificPlatformTied={PlatformIdentifier.TIKTOK}
          fromPreview={true}
          initiatorId={
            currentMediaType === "image"
              ? `${PlatformIdentifier.TIKTOK}-photo-chooser`
              : `${PlatformIdentifier.TIKTOK}-video-chooser`
          }
          externalFilePickerTitle={
            currentMediaType === "image"
              ? "Enter Photo Location"
              : "Enter Video Location"
          }
          prompt={
            currentMediaType === "image" ? "Add Photo From" : "Add Video From"
          }
          fileType={currentMediaType === "image" ? "image" : "video"}
          externalPickerPromptLabel={
            currentMediaType === "image" ? "Photo" : "Video"
          }
          multiple={false}
          fileSelectionHandler={(response) => {
            if (response) {
              let { files, from_local, ...others } = response;
              updateMediaItems(files, from_local, others);
            }
          }}
        />
      }
    </div>
  );

  const updateMediaItems = async (
    files,
    canCreateObjectURL = true,
    moreProps = {}
  ) => {
    let mo = [];
    for (let file of files) {
      if (canCreateObjectURL) {
        let fileUrl = URL.createObjectURL(file, { autoRevoke: false });
        let fileName = file.name;
        let existingFile = mo.find((x) => x.url === fileUrl);
        if (!existingFile) {
          let fileExtension = `.${fileName.split(".").pop()}`;
          let newThreadObject = {
            name: fileName,
            url: fileUrl,
            extension: fileExtension,
            file: file,
            ...moreProps,
          };

          if (platformVideoUploadLimit[platform] && file.size > platformVideoUploadLimit[platform]) {
            let errorMsg = `${file?.name} have been removed from ${platform} because it exceeds the allowed sized limit of ${FigureUtils.convertBytes(platformVideoUploadLimit[platform])?.value}.`
            if (platformVideoUploadLimit['canUpgrade']) {
              errorMsg = errorMsg + ` Upgrade your plan to get more upload limits.`;
            }
            toast.error(errorMsg, { theme: "colored" });
            return;
          }

          console.log(file, 'file')

          if (file.type !== "video/mp4" && file.type !== "video/mov" && file.type !== "video/webm") {
            toast.error(
              `Only MP4, MOV and WEBM video formats are supported`,
              { theme: "colored" }
            );
            continue;
          }

          if (file.type.includes("video")) {
            const video = document.createElement("video");
            video.preload = "metadata";
            video.onloadedmetadata = async function () {
              return new Promise((resolve, reject) => {
                window.URL.revokeObjectURL(video.src);
                const duration = video.duration;
                if (duration > 600) {
                  toast.error(
                    `Maximum allowed video duration is 10 minutes`,
                    { theme: "colored" }
                  );
                  reject('Video duration is too long');
                } else {
                  resolve();
                }
              });
            };

            try {
              await video.onloadedmetadata();
            } catch (error) {
              toast.error(error, { theme: "colored" })
              continue;
            }
          }
          mo.push(newThreadObject);
        }
      } else {
        let fileWithName = mo.find((item) => item.name === file.name);
        console.log(file, 'as file')
        if (!fileWithName) {
          mo.push(file);
        }
      }
    }
    mo?.map((item) => {
      setBlockMediaItemsUpdate(false);
      setAddViaUrl(null);
      setTimeout(() => {
        setAddViaUrl(item);
      }, 1);
    });

    dispatchEditedFlag();
    setOpenMediaSelection(false);
    return;
  };

  const addMediaFromUrl = (obj = mediaAddedViaSpecificPlaformUrlMethod[0]) => {
    try {
      const { extension, thumbnail, ...rest } = obj
      setBlockMediaItemsUpdate(false);
      setSelectedVideo(rest);
      setAddViaUrl(rest);

      dispatchEditedFlag();
      dispatch(setSpecificMediaPlatformTied("global"));
      setOpenMediaSelection(false);
      if (googleDriveManager?.docs) {
        dispatch(setGoogleDriveManager({ ...googleDriveManager, docs: null }))
      }
    } catch (error) { }
  };



  const immediateActionWhenClieked = (remove) => {
    if (remove) {
      localStorage.removeItem("gdrivePlatform");
      return;
    }
    localStorage.setItem("gdrivePlatform", PlatformIdentifier.TIKTOK);
  }

  useEffect(() => {
    if (!googleDriveManager?.docs || localStorage.getItem('gdrivePlatform') !== PlatformIdentifier.TIKTOK) return;
    addMediaFromUrl(googleDriveManager?.docs);
    immediateActionWhenClieked(true)
  }, [googleDriveManager])

  useEffect(() => {
    specificMediaPlatformTied === "tiktok" &&
      mediaAddedViaSpecificPlaformUrlMethod.length &&
      addMediaFromUrl();
  }, [mediaAddedViaSpecificPlaformUrlMethod]);

  const getFirstVideo = () => {
    try {
      if (!manuallyEditedPlatforms[PlatformIdentifier.TIKTOK]) {
        const foundVideo = mediaObjects.find((item) => item.is_video === true);
        if (foundVideo) {
          setAddViaUrl(foundVideo);
          return;
        }
        setAddViaUrl(null);
      }
    } catch (error) { }
  };

  useEffect(() => {
    getFirstVideo();
  }, [mediaObjects]);

  const limitChars = (text, limit) => {
    setLimitedPostTextBody(text?.substring(0, limit));
  };

  const waitTime = 1000;

  function runWhenUserStopTying() {
    window.clearTimeout(timer);
    setTimer(
      window.setTimeout(() => {
        if (!manuallyEditedPlatforms[PlatformIdentifier.TIKTOK]) {
          limitChars(media_items_arrangement?.tiktok?.text, 250);
        }
      }, waitTime)
    );
  }

  useEffect(() => {
    runWhenUserStopTying();
  }, [media_items_arrangement?.tiktok?.text]);

  const handleEditClick = () => {
    setToggleEditTextInput(true);
  };
  const handleSeeMoreToggle = () => {
    seeMore ? setSeeMore(false) : setSeeMore(true);
  };

  const seeMoreOption = (
    <span
      onClick={handleSeeMoreToggle}
      style={{ fontWeight: "600", cursor: "pointer", fontSize: 11 }}
    >
      See more
    </span>
  );

  const hideOption = (
    <span
      onClick={handleSeeMoreToggle}
      style={{ fontWeight: "600", cursor: "pointer", fontSize: 11 }}
    >
      Hide
    </span>
  );

  const viewSeeMore = (
    <pre
      style={{
        color: `${!config.isDark ? "white" : "white"}`,
        padding: "0 5%",
        maxWidth: "100%",
        width: "100%",
        wordWrap: "break-word",
        whiteSpace: "pre-wrap",
        textShadow: "1px 1px 1px #212121",
        fontSize: 10,
      }}
    >
      {limitedPostTextBody?.substring(0, 60)}{" "}
      {limitedPostTextBody?.length > 59 && `...`}{" "}
      {limitedPostTextBody?.length > 59 && seeMoreOption}
    </pre>
  );
  const viewHideMore = (
    <pre
      style={{
        color: `${!config.isDark ? "white" : "white"}`,
        padding: "0 5%",
        maxWidth: "100%",
        width: "100%",
        wordWrap: "break-word",
        whiteSpace: "pre-wrap",
        textShadow: "1px 1px 1px #212121",
        fontSize: 10,
      }}
    >
      {limitedPostTextBody} {hideOption}
    </pre>
  );

  const handleConnect = async () => {
    try {
      googleDriveRef.current.click();
    } catch (error) {

    }
  }


  if (selectedVideo) {
    return (
      <>
        {
          !viewOnly &&
          <Flex
            gap={3}
            className={`mt-2`}
            wrap={'wrap'}
          >
            <ConfirmModal
              open={openConfirmation}
              title={"Connect Your Google Drive Account"}
              message={'Your google drive account is not connected or has been disconnected. Do you wish to connect?'}
              confirmText={"PROCEED"}
              cancelText={"CANCEL"}
              showDialoguePrompt
              onCancel={() => {
                setOpenConfirmation(false);
                dispatch(setGoogleDriveManager({
                  ...googleDriveManager,
                  promptConnect: false,
                }))
              }}
              onConfirm={() => {
                handleConnect();
                dispatch(setGoogleDriveManager({ ...googleDriveManager, promptConnect: false, fromPreviews: false }))
              }}
            />
            <MultimediaSelectionDropdown
              dropDownAnchor={
                <CircularButton title="Add Video">
                  <FontAwesomeIcon icon={faVideo} />
                </CircularButton>
              }
              specificPlatformTied={PlatformIdentifier.TIKTOK}
              fromPreview={true}
              initiatorId={`${PlatformIdentifier.TIKTOK}-video-chooser`}
              externalFilePickerTitle={"Enter Video Location"}
              prompt={"Add Video From"}
              fileType={"video"}
              externalPickerPromptLabel={"Video"}
              multiple={false}
              fileSelectionHandler={(response) => {
                if (response) {
                  let { files, from_local, ...others } = response;
                  updateMediaItems(files, from_local, others);
                }
              }}
            />

            {!toggleEditTextInput && (
              <CircularButton
                editBgColor={!config.isDark ? "#e0e0e0" : "#333333"}
                onClick={() => handleEditClick()}
                title="Edit Text"
              >
                <FontAwesomeIcon icon={faPencil} />
              </CircularButton>
            )}
          </Flex>
        }
        <ParentSection>
          <Wrapper>
            {toggleEditTextInput && (
              <EditTextBox
                ref={textEditRef}
                isPlatformEdited={PlatformIdentifier.TIKTOK}
                incomingRef={textEditRef}
                value={limitedPostTextBody}
                setValue={setLimitedPostTextBody}
                setToggleEditTextInput={setToggleEditTextInput}
                platform={"tiktok"}
                setter={{ ...manuallyEditedPlatforms, tiktok: true }}
                updateText={handleUpdateTextFromEditBox}
              />
            )}
            {!viewOnly && openMediaSelction && !selectingPhotoFromHome && mediaOpener}
            <PreviewContentArea
              mainBgColor={!config.isDark ? "white" : "#0b1727"}
              style={{
                borderColor: `${!config.isDark ? "#ededed" : "transparent"}`,
              }}
              theme={!config.isDark ? "1px 1px 3px #949494" : "none"}
            >
              {!viewOnly && manuallyEditedPlatforms[PlatformIdentifier.TIKTOK] && (
                <div
                  style={{
                    height: "auto",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    fontStyle: "italic",
                    position: "absolute",
                    top: 0,
                    right: 5,
                    fontSize: 11,
                  }}
                >
                  <p style={{ color: !config.isDark ? "grey" : "grey" }}>
                    Edited
                  </p>
                </div>
              )}
              <Flex
                gap={2}
                alignContent={"start"}
                alignItems={"start"}
                style={{
                  width: '100%',
                  padding: '2%'
                }}
              >
                {displayPlatformIcon(PlatformIdentifier.TIKTOK)}
                <Info>
                  <span
                    type={"p"}
                    style={{
                      fontSize: 11,
                      fontWeight: "600",
                      color: `${!config.isDark ? "#050505" : "#E4E6EB"}`,
                    }}
                  >
                    TikTok Preview
                  </span>
                </Info>
              </Flex>
              <VideoWrapper>
                {selectedVideo?.url && (
                  <PlayMediaTikTok
                    platform={PlatformIdentifier.TIKTOK}
                    minVideoDuration={3}
                    maxVideoDuration={30}
                    videoHeightLimit={1023}
                    videoWidthLimit={1079}
                    url={selectedVideo?.url}
                    videoInfoText={videoInfoText}
                    editVideoFormat={editVideoFormat}
                    maximunHeight={600 > 0 && 500}
                    ImageDelete={ImageDelete}
                    file={selectedVideo?.file}
                    item={selectedVideo}
                    external={selectedVideo?.external}
                    openVideoEditArea={openVideoEditArea}
                    setOpenVideoEditArea={setOpenVideoEditArea}
                    viewOnly={viewOnly}
                  />
                )}
              </VideoWrapper>
              {
                !viewOnly &&
                <ImageDelete
                  editBgColor={!config.isDark ? "#e0e0e0" : "#0b1727"}
                  onClick={(e) => handleDeleteMedia()}
                >
                  <FontAwesomeIcon icon={faRemove} />
                </ImageDelete>
              }
              <FirstSpace>
                <p style={{ color: "#e8e8e8", fontSize: 12 }}>Following</p>
                <p style={{ color: "white", fontSize: 14 }}>For You</p>
                <div style={{ position: "absolute", right: 5 }}>
                  <FontAwesomeIcon
                    style={{
                      color: "white",
                    }}
                    icon={faSearch}
                  />
                </div>
              </FirstSpace>
              <IconsContainer>
                <IconHolder overflowState={"auto"}>
                  <div>
                    <img
                      src="https://i.guim.co.uk/img/media/63de40b99577af9b867a9c57555a432632ba760b/0_266_5616_3370/master/5616.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=93458bbe24b9f88451ea08197888ab8e"
                      alt=""
                      style={{ height: 30, width: 30, borderRadius: "100%" }}
                    />
                    <FollowWrapper>
                      <FontAwesomeIcon
                        style={{
                          color: "white",
                          height: 8,
                          width: 8,
                        }}
                        icon={faPlus}
                      />
                    </FollowWrapper>
                  </div>
                </IconHolder>
                <IconHolder>
                  <FontAwesomeIcon
                    style={{
                      color: "white",
                      height: 25,
                      width: 25,
                    }}
                    icon={faHeart}
                  />
                </IconHolder>
                <IconHolder>
                  <FontAwesomeIcon
                    style={{
                      color: "white",
                      height: 25,
                      width: 25,
                    }}
                    icon={faCommentDots}
                  />
                </IconHolder>
                <IconHolder>
                  <FontAwesomeIcon
                    style={{
                      color: "white",
                      height: 25,
                      width: 25,
                    }}
                    icon={faShare}
                  />
                </IconHolder>
                <IconHolder>
                  <div>
                    <img
                      src="https://app.postly.ai/static/media/postly_logo.2e3243cd201fdf7e1b5c.png"
                      alt=""
                      style={{ height: 30, width: 30, borderRadius: "100%" }}
                    />
                  </div>
                </IconHolder>
              </IconsContainer>
              <TextSpace>
                <p
                  style={{
                    color: `${!config.isDark ? "white" : "white"}`,
                    margin: "0 0 0 2%",
                    maxWidth: "100%",
                    width: "100%",
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                    fontSize: 10,
                    fontWeight: "600",
                    textShadow: "1px 1px 1px #212121",
                  }}
                >
                  @postlyTiktokPreview
                </p>
                {/* <TextareaAutoSize
                  id="compose-box"
                  minRows={2}
                  ref={textRef}
                  as="textarea"
                  placeholder={''}
                  className={`shadow-none resize-none border-0 ${config.isDark ? "border-x" : ""
                    } px-2 border-200 bg-transparent`}
                  onChange={(e) => {
                    let value = e.target.value;
                    handleUpdateTextFromEditBox(value);
                  }}
                  style={{
                    width: "100%",
                    outline: "none",
                    whiteSpace: "pre-wrap",
                    display: "inherit",
                    background: config.isDark ? "#0b1727" : "#fff",
                    color: "whitesmoke",
                    fontSize: 11,
                  }}
                  maxRows={20}
                /> */}
                <pre
                  style={{
                    color: `${!config.isDark ? "white" : "white"}`,
                    margin: "0 0 0 -2%",
                    maxWidth: "100%",
                    width: "100%",
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                    textShadow: "1px 1px 1px #212121",
                    fontSize: 10,
                  }}
                >
                  {!seeMore ? viewSeeMore : viewHideMore}
                </pre>
              </TextSpace>
              <LastSpace>
                <BottomIconBox>
                  <FontAwesomeIcon
                    style={{
                      color: "white",
                    }}
                    icon={faHome}
                  />
                  <p
                    style={{
                      fontSize: 8,
                      color: "white",
                      textShadow: "1px 1px 1px black",
                    }}
                  >
                    Home
                  </p>
                </BottomIconBox>
                <BottomIconBox>
                  <FontAwesomeIcon
                    style={{
                      color: "white",
                    }}
                    icon={faUserFriends}
                  />
                  <p
                    style={{
                      fontSize: 8,
                      color: "white",
                      textShadow: "1px 1px 1px black",
                    }}
                  >
                    Friends
                  </p>
                </BottomIconBox>
                <BottomIconBoxCenter>
                  <img
                    src="/myTiktokAddIcon.png"
                    alt=""
                    height={25}
                    width={40}
                    style={{ marginTop: 3 }}
                  />
                </BottomIconBoxCenter>
                <BottomIconBox>
                  <FontAwesomeIcon
                    style={{
                      color: "white",
                    }}
                    icon={faMessage}
                  />
                  <p
                    style={{
                      fontSize: 8,
                      color: "white",
                      textShadow: "1px 1px 1px black",
                    }}
                  >
                    Inbox
                  </p>
                </BottomIconBox>
                <BottomIconBox>
                  <FontAwesomeIcon
                    style={{
                      color: "white",
                    }}
                    icon={faUser}
                  />
                  <p
                    style={{
                      fontSize: 8,
                      color: "white",
                      textShadow: "1px 1px 1px black",
                    }}
                  >
                    Profile
                  </p>
                </BottomIconBox>
              </LastSpace>
            </PreviewContentArea>
          </Wrapper>
          {/* <EditContainer
          style={{ justifyContent: "space-around" }}
          editBgColor={
            !config.isDark ? lightTheme.surfaceColor : darkTheme.rootBackground
          }
        >
      
        </EditContainer> */}
          {
            googleDriveManager?.openPicker ? <GDriveDashboard platform={PlatformIdentifier.TIKTOK} /> : null
          }
        </ParentSection>
      </>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "50px",
        color: `${!config.isDark
          ? darkTheme.workSpaceSettingsHeaderBar
          : lightTheme.workSpaceSettingsHeaderBar
          }`,
        height: "100px",
        width: "100%",
        position: "relative",
        columnGap: 18,
      }}
    >
      <ConfirmModal
        open={openConfirmation}
        title={"Connect Your Google Drive Account"}
        message={'Your google drive account is not connected or has been disconnected. Do you wish to connect?'}
        confirmText={"PROCEED"}
        cancelText={"CANCEL"}
        showDialoguePrompt
        onCancel={() => {
          setOpenConfirmation(false);
          dispatch(setGoogleDriveManager({
            ...googleDriveManager,
            promptConnect: false,
          }))
        }}
        onConfirm={() => {
          handleConnect();
          dispatch(setGoogleDriveManager({ ...googleDriveManager, promptConnect: false, fromPreviews: false }))
        }}
      />
      {
        googleDriveManager?.openPicker ? <GDriveDashboard platform={PlatformIdentifier.TIKTOK} /> : null
      }
      <MultimediaSelectionDropdown
        dropDownAnchor={
          <CircularButton title="Add Video">
            <FontAwesomeIcon icon={faVideo} />
          </CircularButton>
        }
        specificPlatformTied={PlatformIdentifier.TIKTOK}
        fromPreview={true}
        initiatorId={`${PlatformIdentifier.TIKTOK}-video-chooser`}
        externalFilePickerTitle={"Enter Video Location"}
        prompt={"Add Video From"}
        fileType={"video"}
        externalPickerPromptLabel={"Video"}
        multiple={false}
        fileSelectionHandler={(response) => {
          if (response) {
            let { files, from_local, ...others } = response;
            updateMediaItems(files, from_local, others);
          }
        }}
      />
      You need to have a video to preview TikTok
      {!viewOnly && openMediaSelction && !selectingPhotoFromHome && mediaOpener}
    </div>
  );
};

export default TikTok;
