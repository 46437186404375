import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Flex from "components/common/Flex";
import classNames from "classnames";
import Avatar from "components/common/Avatar";
import { Alert, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import LastMessage from "./LastMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChatSidebarDropdownAction from "./ChatSidebarDropdownAction";
import { ChatContext } from "context/Context";
import { findPlatformByIdentifier } from "components/app/platforms";
import { PlatformIdentifier } from "../../../../constants";
import { whatsApp } from "../setup/MessagingAccountsConnector";
import { getTagForPages } from "utils/gen";
import { formatDate } from "../content/MessageTextArea";
import { useFetchPageOwner } from "../hooks/useFetchUser";
import { markMessageAsRead } from "../Chat";

const incomingPageOrAccountVariantMapping = {
  [PlatformIdentifier.INSTAGRAM]: "warning",
  [PlatformIdentifier.WHATSAPP]: "success",
  [PlatformIdentifier.TWITTER]: "info",
  [PlatformIdentifier.LINKED_IN]: "dark",
  [PlatformIdentifier.FACEBOOK]: "primary",
};

const IncomingPageOrAccountPreview = ({ platform, account }) => {
  const { user } = account ?? {};
  if (!platform) {
    return <></>;
  }
  let item = findPlatformByIdentifier(platform.identifier);
  if (platform.target === PlatformIdentifier.WHATSAPP) {
    item = whatsApp;
  }
  let platformName = item?.name;
  item = { ...item, ...platform };
  let targetName = getTagForPages(item, false);

  const items = targetName.split("(s)/");

  // Filter out the groups
  const pages = items.filter((item) => item.startsWith("page"));

  // Join the matched pages back into a string
  const result = pages ? pages.join(", ") : "";

  return (
    <OverlayTrigger
      overlay={
        <Tooltip>{`This message is associated with your connected ${platformName} ${result} (${user?.user_name})`}</Tooltip>
      }
    >
      <span style={{ position: "absolute", left: 0, bottom: "-55%" }}>
        <Alert
          variant={incomingPageOrAccountVariantMapping[platform?.identifier]}
          className="p-0 px-2 m-0 fs--2"
          style={{ display: "inline-block" }}
        >
          <Flex alignItems={"center"} className={"gap-1"}>
            <FontAwesomeIcon
              style={{ width: 10, height: 10 }}
              icon={item?.icon}
            />
            <span style={{ fontSize: 10 }}>{user?.user_name}</span>
          </Flex>
        </Alert>
      </span>
    </OverlayTrigger>
  );
};

const ConversationView = ({ showUnread, thread, index }) => {
  // const currentUserMessages = useSelector(getCurrentUserMessages)
  const {
    getUser,
    conversations,
    activeRoomId,
    setScrollToBottom,
    currentChatsDispatch,
    conversationsDispatch,
    users,
    setCurrentConversation,
    setHideSidebar,
    setIsOpenThreadInfo,
    setActiveRoomId,
    setSenderId,
    currentChats,
  } = useContext(ChatContext);
  const user = useMemo(() => getUser(thread?.room_id), [thread?.room_id]);

  // const user = currentUserMessages?.length === 0 ? getUser(thread) : getUser(thread?.room_id)
  const account = useFetchPageOwner(thread?.room_id, conversations, users);
  // const message = messages?.find(({ id }) => id === thread?.messagesId);
  const conversation = conversations.find(
    ({ room_id }) => room_id === thread.room_id
  );
  const lastMessage =
    conversation?.messages[conversation?.messages?.length - 1];
  // const lastMessage = currentUserMessages?.length === 0 ? message?.content[message.content.length - 1] : conversation?.messages[conversation?.messages?.length - 1]
  const lastMessageTime = formatDate(lastMessage?.time);
  const [conversationThreads, setConversationThreads] = useState([]); // Your chat conversations
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    setConversationThreads(conversation); // Make sure you set the conversationThreads appropriately
  }, [conversation]);

  const calculateUnreadMessagesCount = (conversation) => {
    if (!conversation?.messages) {
      return 0;
    }

    const unreadCount = conversation.messages.filter(
      (message) => !message.read && message.sender !== conversation.user_id
    ).length;

    // Mark messages as read for non-user senders in the active room
    if (
      activeRoomId &&
      conversation.room_id === activeRoomId &&
      lastMessage.sender !== conversation.user_id &&
      unreadCount > 0
    ) {
      markMessageAsRead(
        thread,
        setHideSidebar,
        setIsOpenThreadInfo,
        setActiveRoomId,
        setSenderId,
        currentChats,
        conversationsDispatch,
        currentChatsDispatch,
        setCurrentConversation,
        setScrollToBottom
      );
    }

    return unreadCount;
  };

  useEffect(() => {
    // Debounce the calculation of unread message counts
    const timerId = setTimeout(() => {
      const newUnreadCount = calculateUnreadMessagesCount(conversationThreads);

      if (
        newUnreadCount !== unreadCount &&
        thread.user_id !== lastMessage.sender
      ) {
        setUnreadCount(newUnreadCount);
      }
    }, 500); // Adjust the debounce delay as needed

    return () => {
      clearTimeout(timerId); // Clear the timer if the component unmounts or conversations change
    };
  }, [
    conversationThreads,
    thread?.room_id,
    unreadCount,
    lastMessage?.sender,
    activeRoomId,
  ]);

  return (
    <>
      <>
        <Nav.Link
          eventKey={index}
          className={classNames(`chat-contact hover-actions-trigger p-3`, {
            "unread-message": !lastMessage?.read,
            "read-message": lastMessage?.read,
          })}
          style={{ height: "5rem", position: " relative" }}
          onClick={() =>
            markMessageAsRead(
              thread,
              setHideSidebar,
              setIsOpenThreadInfo,
              setActiveRoomId,
              setSenderId,
              currentChats,
              conversationsDispatch,
              currentChatsDispatch,
              setCurrentConversation,
              setScrollToBottom
            )
          }
        >
          <div className="d-md-none d-lg-block">
            {/* <ChatSidebarDropdownAction /> */}
          </div>
          <Flex>
            <Avatar className={user?.status} src={user?.avatar} size="xl" />
            <div className="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
              <Flex justifyContent="between" style={{ position: "relative" }}>
                <h6 className="mb-0 chat-contact-title">{user?.name}</h6>
                <span className="message-time fs--2">
                  {showUnread
                    ? lastMessageTime?.ago
                    : !!lastMessageTime?.time && lastMessageTime?.time}
                </span>
                {/* Display unread count */}
                {unreadCount !== 0 &&
                lastMessage?.sender !== thread?.user_id &&
                thread?.room_id !== activeRoomId ? (
                  <span
                    style={{
                      position: "absolute",
                      right: "8%",
                      width: "20px",
                      height: "20px",
                      bottom: "-220%",
                      borderRadius: "50%",
                      background: "#2c7be5",
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: "4",
                      fontSize: "12px",
                    }}
                  >
                    {unreadCount}
                  </span>
                ) : null}
              </Flex>
              <div className="min-w-0">
                <div className="chat-contact-content pe-3">
                  <LastMessage lastMessage={lastMessage} thread={thread} />
                  <div className="position-absolute bottom-0 end-0 hover-hide">
                    {!!lastMessage?.status && (
                      <FontAwesomeIcon
                        icon={classNames({
                          check:
                            lastMessage?.status === "seen" ||
                            lastMessage?.status === "sent",
                          "check-double": lastMessage?.status === "delivered",
                          clock: lastMessage?.status === "pending", // Add this line for the "time" icon
                        })}
                        transform="shrink-5 down-4"
                        className={classNames({
                          "text-success": lastMessage?.status === "seen",
                          "text-400":
                            lastMessage?.status === "delivered" ||
                            lastMessage?.status === "sent",
                        })}
                      />
                    )}
                  </div>
                  {/* <br /> */}
                  <IncomingPageOrAccountPreview
                    platform={thread}
                    account={account}
                  />
                  {/* <IncomingPageOrAccountPreview platform={getRandomDemoPageOrAccount()} /> */}
                </div>
              </div>
            </div>
          </Flex>
        </Nav.Link>
      </>
      {/* {!currentUser ? (
        <>
          <Nav.Link

            eventKey={index}
            className={classNames(`chat-contact hover-actions-trigger p-3`, {
              'unread-message': !thread?.read,
              'read-message': thread?.read
            })}
            style={{ height: '5rem' }}
          >
            <div className="d-md-none d-lg-block">
              <ChatSidebarDropdownAction />
            </div>
            <Flex>
              <Avatar className={user?.status} src={user?.avatarSrc} size="xl" />
              <div className="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
                <Flex justifyContent="between">
                  <h6 className="mb-0 chat-contact-title">{user?.name}</h6>
                  <span className="message-time fs--2">
                    {' '}
                    {!!lastMessage && lastMessage.time.day}{' '}
                  </span>
                </Flex>
                <div className="min-w-0">
                  <div className="chat-contact-content pe-3">
                    <LastMessage lastMessage={lastMessage} thread={thread} />
                    <div className="position-absolute bottom-0 end-0 hover-hide">
                      {!!lastMessage?.status && (
                        <FontAwesomeIcon
                          icon={classNames({
                            check:
                              lastMessage.status === 'seen' ||
                              lastMessage.status === 'sent',
                            'check-double': lastMessage.status === 'delivered'
                          })}
                          transform="shrink-5 down-4"
                          className={classNames({
                            'text-success': lastMessage.status === 'seen',
                            'text-400':
                              lastMessage.status === 'delivered' ||
                              lastMessage.status === 'sent'
                          })}
                        />
                      )}
                    </div>
                    <IncomingPageOrAccountPreview platform={getRandomDemoPageOrAccount()} />
                  </div>
                </div>
              </div>
            </Flex>
          </Nav.Link>
        </>
      ) : (
        <>
          <Nav.Link
            eventKey={index}
            className={classNames(`chat-contact hover-actions-trigger p-3`, {
              'unread-message': !lastMessage?.read,
              'read-message': lastMessage?.read
            })}
            style={{ height: '5rem' }}
            onClick={() => onSelect(thread?.roomId)}

          >
            <div className="d-md-none d-lg-block">
              <ChatSidebarDropdownAction />
            </div>
            <Flex>
              <Avatar className={user?.status} src={user?.avatar} size="xl" />
              <div className="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
                <Flex justifyContent="between" style={{ position: 'relative' }}>
                  <h6 className="mb-0 chat-contact-title">{user?.name}</h6>
                  <span className="message-time fs--2">
                    {' '}
                    {!!lastMessageTime?.time && lastMessageTime?.time}{' '}
                  </span>
                  {unreadCount > 0 && lastMessage?.sender !== currentUser?.page_id ? <span style={{
                    position: 'absolute',
                    right: '10%', // Adjust the right position as needed
                    width: '20px', // Adjust the width for the circle
                    height: '20px',
                    bottom: '-200%', // Adjust the height for the circle
                    borderRadius: '50%', // Makes it a circle
                    background: "#2c7be5", // Change the background color as desired
                    color: 'white', // Change the text color as desired
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: "4",
                    fontSize: '12px', // Adjust the font size as needed
                  }}>
                    {unreadCount}
                  </span> : null}
                </Flex>
                <div className="min-w-0">
                  <div className="chat-contact-content pe-3">
                    <LastMessage lastMessage={lastMessage} thread={thread} />
                    <div className="position-absolute bottom-0 end-0 hover-hide">
                      {!!lastMessage?.status && (
                        <FontAwesomeIcon
                          icon={classNames({
                            check:
                              lastMessage?.status === 'seen' ||
                              lastMessage?.status === 'sent',
                            'check-double': lastMessage?.status === 'delivered'
                          })}
                          transform="shrink-5 down-4"
                          className={classNames({
                            'text-success': lastMessage?.status === 'seen',
                            'text-400':
                              lastMessage?.status === 'delivered' ||
                              lastMessage?.status === 'sent'
                          })}
                        />
                      )}
                    </div>
                    <IncomingPageOrAccountPreview platform={getRandomDemoPageOrAccount()} />
                  </div>
                </div>
              </div>
            </Flex>
          </Nav.Link>
        </>
      )} */}
    </>
  );
};

ConversationView.propTypes = {
  thread: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default ConversationView;