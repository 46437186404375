import Flex from "../../../../common/Flex";
import TargetAudienceAnimation from 'assets/animations/target_audience.json';
import { Button, Col, Form, Row } from 'react-bootstrap';
import moment from "moment";
import Lottie from 'lottie-react';
import { useEffect, useState } from "react";

export default function NewAudienceGroupView(props) {

    const { handleAudienceGroupCreation, done = false, activeAudienceGroup } = props;
    const [audienceGroupName, setAudienceGroupName] = useState(activeAudienceGroup?.name);
    const [creatingAudienceGroupName, setCreatingAudienceGroupName] = useState(false);

    useEffect(() => {
        if (creatingAudienceGroupName && done) {
            setCreatingAudienceGroupName(false);
        }
    }, [done]);

    return (
        <Flex
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            style={{
                textAlign: 'center'
            }}
            className={'p-4'}>
            <Row className="justify-content-center">
                <Col xs={10} md={10}>
                    <Lottie
                        autoPlay
                        animationData={TargetAudienceAnimation}
                    />
                </Col>
            </Row>
            <h3 className="mt-3 fw-normal fs-2 mt-md-4 fs-md-3 text-align-center">
                Avoid repeatedly selecting the same targets anytime you want to post
            </h3>
            <p className="lead mb-5 text-align-center">
                Use audience groups to save your selections for later use.{' '}
                <br className="d-none d-md-block" /> You can have as many
                audience groups as you want.
            </p>
            <Form
                direction={'column'}
                style={{
                    width: '100%'
                }}
                onSubmit={(e) => {
                    e.preventDefault();
                    setCreatingAudienceGroupName(true);
                    handleAudienceGroupCreation(audienceGroupName);
                }}>
                <Form.Control
                    type="text"
                    placeholder={`Enter Name of Audience Group E.g ${moment().format("MMMM")} Discount targets`}
                    aria-label="Audience Label"
                    className="mb-3"
                    value={audienceGroupName}
                    onChange={(e) => {
                        setAudienceGroupName(e.target.value);
                    }}
                />
                <Button
                    type="submit"
                    variant="primary"
                    className="d-block w-100"
                    disabled={creatingAudienceGroupName || !audienceGroupName}
                >
                    {creatingAudienceGroupName ? `${activeAudienceGroup ? "Updating..." : "Creating..."}` : `${activeAudienceGroup ? "Update" : "Create"}`}
                </Button>
            </Form>
        </Flex>
    );

}