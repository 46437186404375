/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
// import ImageAnnotation from './ImageAnnotation';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import 'react-tooltip/dist/react-tooltip.css';
import { getUserTags } from 'redux/slices/postslice';
import Image from './Image';


const IgTagUsersComponent = (props) => {
  const { useableMedia, currentTaggableImageIndex, setCanUpdateTags } = props;
  const areas = useSelector(getUserTags)
  const [_useableMedia, _setUseableMedia] = useState(useableMedia);
  const [activeMedia, setActiveMedia] = useState(_useableMedia[currentTaggableImageIndex] ?? {});
  console.log("🚀 ~ file: IgTagUsersComponent.js:16 ~ IgTagUsersComponent ~ activeMedia:", activeMedia)

  useEffect(() => {
    setActiveMedia(_useableMedia[currentTaggableImageIndex] ?? {});
  }, [currentTaggableImageIndex])

  useEffect(() => {
    // const imagesMedia = useableMedia?.filter(
    //   (x) => x.is_video === false
    // );
    // _setUseableMedia(imagesMedia);
    setActiveMedia(useableMedia[currentTaggableImageIndex] ?? {});
  }, [useableMedia])

  // if (activeMedia?.is_video || !activeMedia?.url) {
  //   return null;
  // }
  return (
    <div>
      <Image
        imageUrl={activeMedia?.url}
        isVideo={activeMedia?.is_video ?? false}
        areas={areas}
        setCanUpdateTags={setCanUpdateTags}
      />
    </div>
  );
}

export default IgTagUsersComponent