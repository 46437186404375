import { useEffect, useState } from "react";
import ReactPlayer from 'react-player'

export default function BulkContentVideoPreview(props) {

    const [row, setRow] = useState(props.row);

    useEffect(() => {
        setRow(props.row);
    }, [props.row]);

    return (
        <div
            style={{
                marginTop: 10,
                marginBottom: 10,
                borderRadius: 10,
            }}>
            <ReactPlayer
                style={{
                    borderRadius: 10
                }}
                controls
                url={row.video_url}
                width={200}
                height={200}
            />
        </div>
    )
}
