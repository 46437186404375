import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { Col, Form, FormCheck } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getBillYearlyForWhiteLabelStripeConnect, setBillYearlyForWhiteLabelStripeConnect } from 'redux/slices/whitelabel_slice';

const WhiteLabelMonthlyYearlySwitch = (props) => {
    const dispatch = useDispatch();
    const billedYearly = useSelector(getBillYearlyForWhiteLabelStripeConnect);

    return (
        <Col
            sm="auto"
            as={Flex}
            justifyContent="center"
            alignItems="center"
            className="mt-1 mt-sm-0"
            style={props?.style}
        >
            <FormCheck.Label htmlFor="custom-switch" className='me-2'>
                Monthly
            </FormCheck.Label>
            <Form.Check type="switch" id="yearly" className='mb-2'>
                <Form.Check.Input
                    type="checkbox"
                    checked={billedYearly}
                    onChange={(e) => {
                        dispatch(setBillYearlyForWhiteLabelStripeConnect(e.target.checked));
                    }}
                />
            </Form.Check>
            <Form.Check.Label>
                Yearly  {" "}
                <SoftBadge bg="success"  pill>
                    Save 20%
                </SoftBadge>
            </Form.Check.Label>
            
        </Col>
    )
}
export default WhiteLabelMonthlyYearlySwitch;