import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Logo from "components/common/Logo";
import Flex from "components/common/Flex";
import { useSelector } from "react-redux";
import { getLoggedInUser } from "redux/slices/user_slice";
import { RoutePaths } from '../../../constants';

const PurchaseCard = () => {
  const [show, setShow] = useState(true);
  const loggedInUser = useSelector(getLoggedInUser);
  const [currentPlan] = useState(loggedInUser.active_plan);

  return (
    show && (
      <div className="settings ">
        <hr />
        <Card className="p-0 rounded-2 position-relative">
          {/* <div
            className="position-absolute"
            style={{ right: '6px', top: '3px' }}
          >
            <FalconCloseButton
              size="sm"
              className="fs--2"
              noOutline
              onClick={() => setShow(false)}
            />
          </div> */}
          <Card.Body className="text-center">
            <Flex className="fs--1 mb-3" justifyContent="between">
              <p className="mb-0">{currentPlan["Name"]} Plan</p>
              {currentPlan.Name === "Free" ? (
                <Button
                  as={Link}
                  to={RoutePaths.BILLING_STRIPE}
                  variant="primary"
                  className="d-block"
                  size="sm"
                  transform="shrink-5"
                  style={{ padding: "2px 4px", fontSize: "12px" }}
                >
                  Upgrade
                </Button>
              ) : null}
            </Flex>
            <Logo width={23} fontSize={20} />
          </Card.Body>
        </Card>
      </div>
    )
  );
};

export default PurchaseCard;
