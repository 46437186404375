import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, Col, Button, Form, Modal, Row } from "react-bootstrap";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SoftBadge from "components/common/SoftBadge";
import { useDispatch, useSelector } from "react-redux";
import {
  getBilledYearly,
  setSocialAccountQuantity,
} from "redux/slices/workspaceslice";
import Flex from "components/common/Flex";
import MonthlyYearlySwitch from "./MonthlyYearlySwitch";
import { pricingDataNew } from "./pricingDataNew";
import { toast } from "react-toastify";
import ConfirmModal from "components/common/ConfirmModal";
import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { getLoggedInUser } from "redux/slices/user_slice";
import { isLTD } from "utils/ltd_utils";

const PricingDefaultCard = (props) => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(getLoggedInUser);
  const [currentPlan] = useState(loggedInUser.active_plan);
  const [cancellingSubscription, setCancellingSubscription] = useState(false);
  const [openSubscriptionCancelPrompt, setOpenSubscriptionCancelPrompt] =
    useState(false);
  const [openSocialAccountQuantityPicker, setOpenSocialAccountQuantityPicker] =
    useState(false);
  const [planType, setPlanType] = useState(0);
  const [formData, setFormData] = useState({
    numberOfSocialAccounts: "",
  });
  const handleBilling = props.handleBilling;
  const handleAddSubscriptionQuantity = props.handleAddSubscriptionQuantity;
  const [pricing, setPricing] = useState(props.pricing);

  const {
    id,
    subTitle,
    price,
    extraPrice,
    currencySign,
    periodical,
    buttonText,
    activePlanButtonText,
    badge,
    isFeatured,
    activePlan,
    billingFor,
    featureTitle,
    features,
    cancel_url,
    update_url,
    ...others
  } = pricing;

  const handleNumberOfSocualAccountChange = (event) => {
    const value = parseInt(event.target.value);
    dispatch(setSocialAccountQuantity(event.target.value));
    if (value >= 0) {
      setFormData({
        ...formData,
        numberOfSocialAccounts: value,
      });
    } else {
      setFormData({
        ...formData,
        numberOfSocialAccounts: "",
      });
    }
  };

  const hashUpdatableUrl = () => {
    let cancelUrl = props.activePlanFromDB?.cancel_url;
    let updateUrl = props.activePlanFromDB?.update_url;
    if (updateUrl) {
      return updateUrl;
    }
    if (cancelUrl) {
      return cancelUrl.replace("cancel", "update");
    }
    return null;
  };
  const updatableUrl = hashUpdatableUrl();

  const cancelSubscription = (e) => {
    setCancellingSubscription(true);
    if (props.activePlanFromDB.cancel_url) {
      const cancelUrl = props.activePlanFromDB.cancel_url;
      window.open(cancelUrl, '_blank');
      setCancellingSubscription(false);
    } else {
      toast.info('You cannot cancel your subscription at the moment. Please contact support', { theme: "colored" });
      setCancellingSubscription(false);
    }
  };

  const selectedPlan = pricingDataNew[planType];

  useEffect(() => {
    setPricing(props.pricing);
  }, [props.pricing]);

  const billedYearly = useSelector(getBilledYearly);
  const totalAmountToBeCharged =
    formData.numberOfSocialAccounts *
    (billedYearly ? selectedPlan.extraPrice.Year : selectedPlan.extraPrice.Month);

  const handleClickOpenSocialAccountQuantityPicker = (planType) => {
    if (planType === 0) {
      setOpenSocialAccountQuantityPicker(true);
      setPlanType(0);
    } else if (planType === 1) {
      setOpenSocialAccountQuantityPicker(true);
      setPlanType(1);
    } else if (planType === 2) {
      setOpenSocialAccountQuantityPicker(true);
      setPlanType(2);
    }
  };

  return (
    <>
      <Col
        lg={4}
        className={classNames("border-top border-200 border border-bottom", {
          "dark__bg-1000 px-4 px-lg-0": isFeatured,
        })}
        style={{ backgroundColor: isFeatured && "rgba(115, 255, 236, 0.18)" }}
      >
        <div className="h100">
          <div className="text-center p-2 pt-4">
            {badge && (
              <p className="mt--10 fs-1" style={{ marginTop: "-6px" }}>
                <SoftBadge bg="primary" pill>
                  {badge}{" "}
                </SoftBadge>
              </p>
            )}
            <h3 className="fw-normal my-0 fs-1 text-900">
              <b>{others.title}</b>
            </h3>
            <p className="mt-3 fs--1">{subTitle}</p>
            <h2 className="fw-medium my-4">
              <sup className="fw-normal fs-2 me-1">{currencySign}</sup>
              {billedYearly
                ? price.Year.toLocaleString()
                : price.Month.toLocaleString()
              }
              {periodical && (
                <small className="fs--1 text-700">
                  /{billedYearly ? `yr` : `mo`}
                </small>
              )}
            </h2>
            <Button
              onClick={() => {
                if (others.plan) {
                  if (activePlan) {
                    handleClickOpenSocialAccountQuantityPicker(id)
                  } else {
                    handleBilling(others);
                  }
                }
              }}
              variant={activePlan ? "success" : "primary"}
            >
              <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
                {activePlan && <FontAwesomeIcon icon={faPlus} />}
                <span>{activePlan ? activePlanButtonText : buttonText}</span>
              </div>
            </Button>
          </div>
          {activePlan ?
            <div className="text-center">
              <small className="text-center">
                Next billing date:{" "}
                <b>
                  {loggedInUser?.active_plan?.next_bill_date}
                </b>
              </small>
            </div> :
            ""
          }
          <hr className="border-bottom-0 m-0" />
          {activePlan && (
            <Card className=" rounded-3 overflow-hidden mb-md-0">
              <div className="bg-light">
                {updatableUrl && <div></div>}
                {updatableUrl && (
                  <a
                    href={updatableUrl}
                    className={"text-decoration-none cursor-pointer"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Flex
                      className={"gap-1 text-primary mt-3"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <FontAwesomeIcon
                        style={{ width: 10, height: 10 }}
                        icon={faPen}
                      />
                      <span className="fs-0">
                        {" "}
                        <b> Update Payment Details</b>
                      </span>
                    </Flex>
                  </a>
                )}
                {
                  props?.activePlanFromDB.cancel_url && (
                    <Flex
                      className={"gap-1 text-primary"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <span className="fs--1 " cursor="pointer">
                        <Button
                          variant="text"
                          className="btn btn-sm btn-link ps-0 text-600 mb--2"
                          style={{
                            textDecoration: "none",
                          }}
                          onClick={() => {
                            setOpenSubscriptionCancelPrompt(true);
                          }}
                        >
                          {
                            cancellingSubscription
                              ? "Cancelling subscription..."
                              : " Cancel your subscription"
                          }
                        </Button>
                      </span>
                    </Flex>
                  )
                }
              </div>
            </Card>
          )}
          <div className="text-start px-sm-3 py-3">
            <ul className="list-unstyled mt-3">
              {
                features.map((feature) => (
                  <li className="py-1 fs--1" key={feature.id}>
                    <FontAwesomeIcon icon="check" className="me-1 text-success" />{" "}
                    {feature.title}
                    {feature.title === "Unlimited Characters/mo" && (
                      <span style={{ fontSize: 16, color: "red" }}> *</span>
                    )}
                    {
                      feature.tag && (
                        <SoftBadge pill bg={feature.tag.type}>
                          {feature.tag.label}
                        </SoftBadge>
                      )
                    }
                    {
                      feature.title === "Extra Social Account" && (
                        <span>
                          <span style={{ fontSize: 16 }}>=</span>{" "}
                          <span style={{ fontWeight: "bold" }}>${billedYearly ? extraPrice.Year : extraPrice.Month}/{billedYearly ? `year` : `month`}</span>
                        </span>
                      )
                    }
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
        <ConfirmModal
          open={openSubscriptionCancelPrompt}
          onCancel={() => {
            setOpenSubscriptionCancelPrompt(false);
          }}
          title="Cancel your subscription?"
          message="This will cancel your current subscription and downgrade your account to the free plan."
          cancelText="NO"
          confirmText="CONTINUE"
          onConfirm={() => {
            setOpenSubscriptionCancelPrompt(false);
            cancelSubscription();
          }}
        />
        <Modal
          show={openSocialAccountQuantityPicker}
          onHide={() => setOpenSocialAccountQuantityPicker(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Upgrade
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              isLTD(currentPlan) ? (
                <p>You are not eligible for this request</p>
              ) :
                (
                  <>
                    <MonthlyYearlySwitch />
                    <hr />
                    {planType !== 2 && (
                      <span className="mt-0 fs-0 fs-sm-0 fs-lg-0 px-2">
                        Your Active Plan: <b>{currentPlan["Name"]}</b>
                      </span>
                    )}
                    <br />
                    <span className="mt-0 fs-0 fs-sm-0 fs-lg-0 px-2">
                      Your Number of Social Accounts: <b>{currentPlan["SocialAccounts"]}</b>
                    </span>
                    <br />
                    {currentPlan["Name"] === "Free" && (
                      <span className="mt--4 fs-0 fs-sm-0 fs-lg-0 px-2">
                        Request:{" "}
                        <b>
                          Upgrade to {selectedPlan.title}
                        </b>
                      </span>
                    )}
                    <br /> <br />
                    <Flex direction={"column"} className={"gap-2"}>
                      {
                        planType !== 2 && (
                          <>
                            <Form.Group className="mt-3">
                              <Form.Label>
                                {" "}
                                How many social accounts do you want?
                              </Form.Label>
                              <Row className="align-items-center gx-0">
                                <Col
                                  xs
                                  md="auto"
                                  className="d-flex justify-content-start"
                                >
                                  <Form.Group controlId="numberOfSocialAccounts">
                                    <Form.Control
                                      type="number"
                                      value={formData.numberOfSocialAccounts}
                                      name="numberOfSocialAccounts"
                                      onChange={handleNumberOfSocualAccountChange}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col
                                  xs="auto"
                                  className="d-flex justify-content-start ml-10"
                                >
                                  <p className="mb-1 fs-0 fs-sm-0 fs-lg-0 px-2">
                                    x $
                                    {billedYearly
                                      ? selectedPlan.extraPrice.Year
                                      : selectedPlan.extraPrice.Month}
                                    /{billedYearly ? "yr" : "mo"} = $
                                    {totalAmountToBeCharged}/
                                    {billedYearly ? "yr" : "mo"}
                                  </p>
                                </Col>
                              </Row>
                            </Form.Group>
                            <p className="mt-4 fs-0 fs-sm-0 fs-lg-0 px-2">
                              You can buy more social accounts at any time. You will
                              be charged a prorated fee for the billing cycle.
                            </p>
                          </>
                        )}
                      <div className="text-end">
                        <Button
                          disabled={formData.numberOfSocialAccounts <= 0}
                          onClick={() => {
                            if (others.plan) {
                              handleAddSubscriptionQuantity(others);
                            }
                          }}
                          style={{ width: "100%" }}
                        >
                          Pay now
                        </Button>
                      </div>
                      <div className="text-center">
                        <Button
                          onClick={() => {
                            setOpenSocialAccountQuantityPicker(false);
                          }}
                          variant={"outlined"}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Flex>
                  </>
                )}
          </Modal.Body>
        </Modal>
      </Col>
    </>
  );
};

PricingDefaultCard.propTypes = {
  pricing: PropTypes.shape({
    title: PropTypes.string.isRequired,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    url: PropTypes.string,
    buttonText: PropTypes.string,
    isAddOn: PropTypes.bool,
    isFeatured: PropTypes.bool,
    featureTitle: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        tag: PropTypes.shape({
          label: PropTypes.string,
          type: PropTypes.string,
        }),
      })
    ),
  }),
};

export default PricingDefaultCard;
