import { createSlice } from "@reduxjs/toolkit";

const messagingSlice = createSlice({
    name: "messenger",
    initialState: { user: null, messages: [] },
    reducers: {
        setCredentials: (state, action) => {
            state.user = action.payload.user;
        },
        logOut: (state) => {
            state.user = null
        },
        setFriends: (state, action) => {
            const { friends } = action.payload;
            if (state.user) {
                state.user.friends = friends
            } else {
                console.error("user friends non-existent :(")
            }

        },
        setMessages: (state, action) => {
            const conversations = action.payload.messages;
            state.messages = conversations
            console.log("🚀 ~ file: messagingSlice.js:25 ~ messages:", conversations)
            console.log("🚀 ~ file: messagingSlice.js:26 ~ action.payload:", action.payload)
        },
        setMessage: (state, action) => {
            const updatedMessages = state.messages.map((currentMessage) => {

                if (currentMessage._id === action.payload.message._id) return action.payload.message;
                return currentMessage
            })
            state.messages = updatedMessages
        }
    },
})

export const getCurrentUser = (state) => state.messenger.user
export const getCurrentUserId = (state) => state.messenger.user.user_id
export const getCurrentPageId = (state) => state.messenger.user.page_id
export const getCurrentUserMessages = (state) => state.messenger.messages
export const getCurrentFriend = (state) => state.messenger.user.friends
export const { setCredentials, logOut, setFollowers, setMessages, setMessage } = messagingSlice.actions
export default messagingSlice.reducer