import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    getGMBEventTitle,
    getGMBEventSchedule,
    setGMBEventSchedule,
    setGMBEventTitle
} from '../../../../../redux/slices/postslice';
import DatePicker from 'react-datepicker';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import EditableSection from '../debounceable_edit_box';
import StringUtils from 'utils/string';
import CTAPostPanel from './cta_post_panel';
import { Col, Form, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Divider from 'components/common/Divider';

function EventPostPanel(props, ref) {

    const {
        bulkContentMode = false,
        rowUpdateHandler,
        titleLabel = "event",
        bulkContentRow = {}
    } = props;
    const dispatch = useDispatch();
    const eventTitle = useSelector(getGMBEventTitle);
    const [bulkContentEventTitle, setBulkContentEventTitle] = useState(props.eventTitle);
    const eventSchedule = useSelector(getGMBEventSchedule);
    const [bulkContentEventSchedule, setBulkContentEventSchedule] = useState({
        startTime: props.startDateAndTime,
        endTime: props.endDateAndTime
    });


    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime();
    };

    const arrangeDefaultStartAndEndDate = () => {
        if (!eventSchedule?.startTime || !eventSchedule?.endTime) {
            let startTime = new Date();
            let endTime = moment(startTime).add(1, 'day').toDate();
            let eventScheduleCopy = {};
            if (eventSchedule || bulkContentEventSchedule) {
                eventScheduleCopy = { ...(bulkContentMode ? bulkContentEventSchedule : eventSchedule) }
            }
            if (bulkContentMode) {
                let updatedRow = { ...bulkContentRow }
                updatedRow.google_my_business_event_start_date_and_time = startTime;
                updatedRow.google_my_business_event_end_date_and_time = endTime;
                rowUpdateHandler(updatedRow);
                setBulkContentEventSchedule((prevSchedule) => {
                    return { ...prevSchedule, startTime, endTime }
                })
            } else {
                eventScheduleCopy.startTime = startTime;
                eventScheduleCopy.endTime = endTime;
                dispatch(setGMBEventSchedule(eventScheduleCopy));
            }
        }
    }

    useEffect(() => {
        arrangeDefaultStartAndEndDate();
    }, [])

    useEffect(() => {
        setBulkContentEventTitle(props.bulkContentEventTitle);
    }, [props.eventTitle]);

    useImperativeHandle(ref, () => ({
        getErrorMessage: () => {
            if (!eventTitle) {
                return `Please enter ${titleLabel} title`;
            }
            if (!eventSchedule) {
                return `Please select the start and end time for this ${titleLabel}`;
            }
            if (!eventSchedule.startTime) {
                return `Please enter the Start time for this ${titleLabel}`;
            }
            if (!eventSchedule.endTime) {
                return `Please enter the End time for this ${titleLabel}`;
            }
            if (eventSchedule.startTime === eventSchedule.endTime) {
                return `${titleLabel} Start time and End time cannot be the same`;
            }
            return null;
        }
    }));

    const EventDateAndTimeSection = React.forwardRef((props, ref) => {
        return (
            <fieldset
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    borderRadius: 3
                }}>
                <legend><h5 type={'h5'} style={{ paddingLeft: 5, paddingRight: 5 }}>{StringUtils.capitalizeString(titleLabel)} Date &amp; Time</h5>
                </legend>
                <Row>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>Start</Form.Label>
                            <DatePicker
                                className='form-control'
                                minDate={new Date()}
                                showTimeSelect
                                filterTime={filterPassedTime}
                                dateFormat="MM/dd/yyyy h:mm aa"
                                formatWeekDay={day => day.slice(0, 3)}
                                timeIntervals={1}
                                onChange={(e) => {
                                    let startTime = new Date(e);
                                    let eventScheduleCopy = {};
                                    if (eventSchedule || bulkContentEventSchedule) {
                                        eventScheduleCopy = { ...(bulkContentMode ? bulkContentEventSchedule : eventSchedule) }
                                    }
                                    if (bulkContentMode) {
                                        let updatedRow = { ...bulkContentRow }
                                        updatedRow.google_my_business_event_start_date_and_time = startTime;
                                        rowUpdateHandler(updatedRow);
                                        setBulkContentEventSchedule((prevSchedule) => {
                                            return { ...prevSchedule, startTime }
                                        })
                                    } else {
                                        eventScheduleCopy.startTime = startTime;
                                        dispatch(setGMBEventSchedule(eventScheduleCopy));
                                    }
                                }}
                                selected={(bulkContentMode ? new Date(bulkContentEventSchedule?.startTime ?? new Date()) : new Date(eventSchedule?.startTime ?? new Date())) ?? new Date()}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>End</Form.Label>
                            <DatePicker
                                minDate={new Date(bulkContentMode ? bulkContentEventSchedule?.startTime : eventSchedule?.startTime) ?? new Date()}
                                className='form-control'
                                showTimeSelect
                                filterTime={filterPassedTime}
                                dateFormat="MM/dd/yyyy h:mm aa"
                                formatWeekDay={day => day.slice(0, 3)}
                                timeIntervals={1}
                                onChange={(e) => {
                                    let endTime = new Date(e);
                                    let eventScheduleCopy = {};
                                    if (eventSchedule || bulkContentEventSchedule) {
                                        eventScheduleCopy = { ...(bulkContentMode ? bulkContentEventSchedule : eventSchedule) }
                                    }
                                    if (bulkContentMode) {
                                        let updatedRow = { ...bulkContentRow }
                                        updatedRow.google_my_business_event_end_date_and_time = endTime;
                                        rowUpdateHandler(updatedRow);
                                        setBulkContentEventSchedule((prevSchedule) => {
                                            return { ...prevSchedule, endTime }
                                        })
                                    } else {
                                        eventScheduleCopy.endTime = endTime;
                                        dispatch(setGMBEventSchedule(eventScheduleCopy));
                                    }
                                }}
                                selected={bulkContentMode ? new Date(bulkContentEventSchedule?.endTime ?? new Date()) : new Date(eventSchedule?.endTime ?? new Date()) ?? moment(new Date(bulkContentMode ? bulkContentEventSchedule?.startTime : eventSchedule?.startTime ?? new Date())).add(1, 'day').toDate()}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </fieldset>
        )
    });

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 10
            }}>
            <EditableSection
                title={`${StringUtils.capitalizeString(titleLabel)} title`}
                placeholder={`Please enter ${titleLabel} title`}
                value={bulkContentMode ? bulkContentEventTitle : eventTitle}
                onChangeHandler={(value) => {
                    if (bulkContentMode) {
                        setBulkContentEventTitle(value);
                        let updatedRow = { ...bulkContentRow }
                        if (!value) {
                            value = " ";
                        }
                        updatedRow.google_my_business_event_title = value;
                        rowUpdateHandler(updatedRow);
                    } else {
                        dispatch(setGMBEventTitle(value));
                    }
                }}
            />
            <EventDateAndTimeSection />
            {
                titleLabel.toLowerCase() === 'event' &&
                <CTAPostPanel
                    bulkContentMode={bulkContentMode}
                    bulkContentRow={bulkContentRow}
                    rowUpdateHandler={rowUpdateHandler}
                    callToActionButton={bulkContentRow?.google_my_business_call_to_action_button}
                    callToActionUrl={bulkContentRow?.google_my_business_call_to_action_url}
                />
            }
            {
                titleLabel.toLowerCase() === 'event' && !bulkContentMode &&
                <Flex direction={'column'}>
                    <Divider />
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 5
                        }}>
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            style={{
                                width: 15,
                                height: 15,
                            }}
                        />
                        <span
                            style={{
                                fontSize: 12,
                            }}>Message above will be used as the {titleLabel} details
                        </span>
                    </div>
                </Flex>
            }
        </div>
    );
}
export default React.forwardRef(EventPostPanel);