import styled from 'styled-components';

export const PlatformBottomShadow = styled.div`
position: absolute;
background: rgba(0, 0, 0, 0.7);
height: 30px;
width: 100%;
bottom: 0px;
z-index: 100;
&:after{
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  background-image: linear-gradient(to bottom, rgb(246 249 252),#455A647B);
  pointer-events: none;
}
`;

export const BulkFullScreenContainer = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 10000;
background: ${(props) => props?.bgColor};
/* background: #121e2e; */
/* background: rgba(0, 0, 0, 0.7); */
display: flex;
align-items: flex-start;
justify-content: center;
overflow-y: auto;
overflow-x: hidden;
`;

export const BulkFullScreenContainerInner = styled.div`
  height: auto;
  width:100vw;
  overflow-x: hidden;
`;