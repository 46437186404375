import React, { useEffect } from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { numberWithCommasFormat } from "utils/analytics_utils";
import {
  OverviewContainer,
  ProfileHeader,
  ProfileText,
} from "./styles/Overview";

const Overview = ({
  title,
  value,
  isLoadingProfileStats,
  children,
  statDescription = "Unavailable",
  ...rest
}) => {
  let profileValue = numberWithCommasFormat(parseInt(value ?? 0));
  const tooltipId = `overview-info-${statDescription}`;

  return (
    <>
      {!isLoadingProfileStats && (
        <OverviewContainer>
          <ProfileHeader>
            <ProfileText fontSize={"14px"} fontWeight={"400"}>
              {title}
            </ProfileText>
            <a data-tooltip-id="my-tooltip" data-tooltip-content={statDescription}>
              <IoMdInformationCircleOutline />
            </a>
          </ProfileHeader>
          <ProfileText fontSize={"1.4rem"}>{profileValue}</ProfileText>
          <Tooltip id="my-tooltip" />
        </OverviewContainer>
      )}
    </>
  );
};

export default Overview;
