import { faClone, faLocationArrow } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Flex from 'components/common/Flex'
import { RoutePaths } from 'constants'
import React, { useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setPublishablePostTextBody } from 'redux/slices/post_text_body_slice'
import { setComposerFocused, setOpenDraftPrompt } from 'redux/slices/postslice'
import { copyToClipboard } from 'utils/browser'

const PreviewResult = (props) => {
    const { textGenerated } = props
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [copyText, setCopyText] = useState("Copy");

    const handleCopy = (text) => {
        setCopyText("Copied");
        setTimeout(() => setCopyText("Copy"), 2000);
        copyToClipboard(text);
    };

    const extractLanguage = (text) => {
        const regex = /^CODE101_Auto detected to be (\w+)\./;
        const match = text.match(regex);
        if (match) {
            return match[1];
        } else {
            return null;
        }
    }

    const removeFirstSentence = (text) => {
        const regex = /^CODE101_Auto detected to be \w+\. (.*)$/;
        const match = text.match(regex);
        if (match) {
            return match[1];
        } else {
            return text;
        }
    }


    return (
        <Card
            className={'mt-3'}
            style={{
                width: '100%',
                padding: 25,
                borderRadius: 5
            }}>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10
                }}>
                <Form.Group>
                    <Form.Label>
                        {
                            extractLanguage(textGenerated) ? `Translated from ${extractLanguage(textGenerated)}` : `Translated text:`
                        }
                    </Form.Label>
                </Form.Group>
                <div

                >
                    {removeFirstSentence(textGenerated)}
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        gap: 10
                    }}>
                    <Button
                        variant='outline-primary'
                        className="rounded-pill me-1 mb-1"
                        style={{
                            minWidth: 90
                        }}
                        onClick={() => {
                            dispatch(setComposerFocused(true));
                            dispatch(setPublishablePostTextBody(removeFirstSentence(textGenerated)));
                            navigate(RoutePaths.POSTS);
                            dispatch(setOpenDraftPrompt(false));
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 5,
                            }}>
                            <FontAwesomeIcon
                                icon={faLocationArrow}
                            />
                            <span>Post
                            </span>
                        </div>
                    </Button>
                    <Button
                        variant='outline-primary'
                        className="rounded-pill me-1 mb-1"
                        onClick={() => {
                            handleCopy(removeFirstSentence(textGenerated));
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 5,
                            }}>
                            <FontAwesomeIcon
                                icon={faClone}
                            />
                            <span>{copyText}
                            </span>
                        </div>
                    </Button>
                </div>
            </div>
        </Card>
    )
}

export default PreviewResult