import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const NewBoardSectionBase = styled.form`
background-color:${props => props.theme.baseColor};
`;

export const GridItemCheckIcon = styled(FontAwesomeIcon)`
position: absolute;
top: 10px;
right: 10px;
width: 25px;
height: 25px;
color: green;
`;

export const NoteContainer = styled.div`
position: relative;
width: 100%;
height: 100%;
padding: 10px;
border-radius: 5px;
font-size: 14px;
`