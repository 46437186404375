import styled from "styled-components";

export const MainArena = styled.div`
  width: 100%;
  padding: 10px;
  height: auto;
  /* min-width:500px; */
`;

export const PlatformList = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 40px;
  row-gap: 30px;
  flex-wrap: wrap;
`;
export const FixAllContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:-7%;
  column-gap: 20px;
`;

export const PlatformTabItem = styled.div`
  background-color: ${(props) => props.editBgColor};
  border-radius: 100%;
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:pointer;
  :hover {
    background-color: ${(props) => props.hoverColor ?? "#d9d9d9"};
  }
  :active {
    opacity: 0;
  }
`;
