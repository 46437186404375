import styled from 'styled-components';

export const OverallContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;   
`

export const CustomRepeatArea = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
`;
export const TopNav = styled.div`
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
`;
export const RepeatAngle = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
`

export const PerIdentifier = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width:40px;
    border-radius: 100%;
    font-size: 13px;
    background-color: ${props => props.selected ? '#407bca' : '#e3e5e6'};
    color: ${props => props.selected ? 'white' : 'black'};
    transition: background-color 0.3s, box-shadow 0.3s;
    cursor: pointer;
    /* &:hover{
        background-color: #407bca;
        color:white;
    } */
    &:active{
        background-color: #e3e5e6;
    box-shadow: 0 0 10px #407bca;
        transition: background-color 0.3s, box-shadow 0.3s, width 0.3s, height 0.3s;
    }
`
export const RadioHolder = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    margin-top: 10px;
    `
export const RadioInput = styled.input`
        background-color: #407bca;
        height: 18px;
        width: 18px;
        color:white;
`

export const InputTag = styled.input`
    width: 50px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #e3e5e6;
    padding: 10px;
    outline: none;
    text-align: center;
`
export const InputTagChild = styled.input`
    width: 50px;
    height: 40px;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: transparent;
    /* padding: 10px; */
    outline: none;
    text-align: center;
`

export const InputTagHolder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #e3e5e6;
    padding: 10px 0 10px 3px;
    outline: none;
    height: 40px;
    gap: 10px;
`

export const IncrementDecrementHolder = styled.div`
    display: flex;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 10px;
`

export const TimeUnitDrop = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
     width: 100%;
`
export const EachTimeUnitDrop = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 100%;
    &:hover{
        background-color: #e3e5e6;
        cursor: pointer;
    }
`

export const SubscriptionHolder = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-top: 10px;

`

export const EachOption = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
    background-color: ${props => props?.bg || "transparent"};
    &:hover {
        background-color: #f5f5f5;
    }
`
export const FlexCard = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
`;
export const IconCard = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    width:80px
`;