import { useState, useEffect, useContext } from "react";
import StringUtils from 'utils/string';
import Autolinker from 'autolinker';
import './continuous_expansion_textview_styles.css';
import AppContext from "context/Context";

export default function ContinousExpansionTextView(props) {

    const {
        style = {},
        inputStyle = {},
        maxLengthInView = 280,
        text,
        search = "",
        fromPostDetailsDialog = false
    } = props;
    const { config } = useContext(AppContext);
    const [expansionIndex, setExpansionIndex] = useState(1);
    const [canShowLess, setCanShowLess] = useState(false);
    const [canShowMore, setCanShowMore] = useState(true);
    const [viewableText, setViewableText] = useState();
    const autolinker = new Autolinker({
        urls: {
            schemeMatches: true,
            wwwMatches: true,
            tldMatches: true,
        },
        email: true,
        phone: true,
        mention: 'twitter',
        hashtag: 'twitter',
        stripPrefix: true,
        stripTrailingSlash: true,
        newWindow: true,
        truncate: {
            length: 0,
            location: 'end'
        },
        className: !config.isDark ? null : 'link'
    });

    const processedText = () => {
        let processedText = viewableText;
        if (processedText) {
            processedText = processedText.replace(/\n\r?/g, "<br/>");
        }
        if (search && processedText) {
            processedText = processedText.replace(new RegExp(search, "gi"), `<span style='background:orange;'>${search}</span>`);
        }
        return processedText;
    }

    const generateViewableText = () => {
        if (text.length > maxLengthInView) {
            let viewableTextLength = expansionIndex * maxLengthInView;
            if (viewableTextLength < text.length) {
                setCanShowLess(false);
                setCanShowMore(true);
                setExpansionIndex((index) => {
                    return index + 1;
                });
                let viewableString = text.substring(0, viewableTextLength);
                viewableString = StringUtils.substringBeforeLast(viewableString, ".");
                setViewableText(viewableString + ".");
            } else {
                setExpansionIndex(1);
                setCanShowLess(true);
                setViewableText(text);
            }
        } else {
            setCanShowMore(false);
            setViewableText(text);
        }
    }

    useEffect(() => {
        generateViewableText();
    }, []);

    useEffect(() => {
        generateViewableText();
    }, [text]);

    useEffect(() => {
        generateViewableText();
    }, [search]);

    const autoLinkedText = () => {
        let autoLinkedText = autolinker.link(processedText());
        return autoLinkedText;
    }

    return (
        <div
            style={{
                display: 'flex',
                transition: 'all .4s',
                flexWrap: 'wrap',
                ...style
            }}>
            <span
                style={{
                    fontSize: fromPostDetailsDialog ? 15 : 11,
                    ...inputStyle,
                    wordWrap: 'break-word'
                }}
                dangerouslySetInnerHTML={{ __html: autoLinkedText() }}>
            </span>
            <span
                onClick={() => {
                    generateViewableText();
                }}
                style={{
                    color: '#2c7be5',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    fontSize: fromPostDetailsDialog ? 15 : 11,
                }}>{canShowLess ? 'Less' : canShowMore ? '...See more' : ''}
            </span>
        </div>
    );
}