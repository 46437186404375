import styled from "styled-components";

export const FolderContainer = styled.section`
   margin-top: 20px;
   padding: 0;
   margin: 0;
   min-height:10px !important;
   padding-bottom: 70px;
   width: 100%;
   max-height: 100%;
   overflow-y: auto; 
  display: flex;
   flex-wrap: wrap;
   gap: 50px;
   justify-content: flex-start;
   align-items: center;

`;
export const EachFolderBox = styled.div`
   min-height: ${props => props.minHeight};
   width: 100%;
   max-width: ${props => props.maxWidth};
   display: flex;
   flex-direction: ${props => props.flexDirection};
   align-items: center;
   gap: ${props => props.gap};
   cursor: pointer;
   justify-content: ${props => props.justifyContent};
   &:hover {
     opacity: 0.5;
   }
   &:active {
      opacity: 0;
   }
`;
export const FolderName = styled.p`
   font-size: 12px;
   font-weight: 500;
   margin: 0;
   padding: 0;
   text-align: center;
   margin-top: 5px;
   margin-bottom: 5px;
`;
export const LoadingOverlay = styled.div`
   height: 100vh;
   width: 100vw;
   position: fixed;
   top: 0;
   left: 0;
   background-color: rgba(0, 0, 0, 0.5);
   z-index: 999999;
   display: flex;
   justify-content: center;
   align-items: center;
`;
