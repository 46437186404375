import { CustomRepeatArea, EachOption, EachTimeUnitDrop, FlexCard, IconCard, IncrementDecrementHolder, InputTag, InputTagChild, InputTagHolder, PerIdentifier, RadioHolder, RadioInput, RepeatAngle, SubscriptionHolder, TimeUnitDrop, TopNav } from 'components/app/social/feed/previewer/styles/repeat_option_pop_styles'
import PopDrop from 'components/common/PopDrop'
import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Modal } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'
import { MdOutlineCheck, MdOutlineKeyboardBackspace, MdOutlineSave } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { getRepeatOption, getRepeatOptionType, getTimeToPostContent, setRepeatOption, setRepeatOptionType } from 'redux/slices/postslice'

export const getCurrentNumberOfWeek = (timeToPost) => {
    const currentDate = new Date(timeToPost) ?? new Date();
    const dayOfMonth = currentDate.getDate();
    const currentWeekNumber = Math.ceil(dayOfMonth / 7);
    if (currentWeekNumber === 1) {
        return 'first'
    }
    if (currentWeekNumber === 2) {
        return 'second'
    }
    if (currentWeekNumber === 3) {
        return 'third'
    }
    if (currentWeekNumber === 4) {
        return 'fourth'
    }
    if (currentWeekNumber === 5) {
        return 'fifth'
    }

}

const RepeatOptionPop = (props) => {
    const ref = useRef(null)
    const dispatch = useDispatch()
    const timeToPost = useSelector(getTimeToPostContent);
    const repeat_option_type = useSelector(getRepeatOptionType)
    const repeatOption = useSelector(getRepeatOption)
    const [showWeekdaySelection, setShowWeekdaySelection] = useState(false)
    const [selectedOption, setSelectedOption] = useState(repeat_option_type)
    const [selectedWeekdaysInRepeatOption, setSelectedWeekdaysInRepeatOption] = useState(repeatOption?.type === "every_weekday_(week_days)" ? repeatOption?.data?.week_days : [])
    const [openCustomModal, setOpenCustomModal] = useState(null);
    const [customRepeatEveryValue, setCustomRepeatEveryValue] = useState(1)
    const [endAfterValue, setEndAfterValue] = useState(1)
    const [customRepeatEveryUnit, setCustomRepeatEveryUnit] = useState("day")
    const [repeatDaysSelected, setRepeatDaysSelected] = useState([])
    const neverRadioRef = useRef(null)
    const onRadioRef = useRef(null)
    const afterRadioRef = useRef(null)
    const [endOption, setEndOption] = useState("never")
    const [endDate, setEndDate] = useState(new Date())
    const [selectedMonthlyOption, setSelectedMonthlyOption] = useState('monthly_by_date')
    const [doneFilling, setDoneFilling] = useState(false)
    const [repeatDaysIdentifiers, setRepeatDaysIdentifier] = useState([
        {
            name: "Sunday",
            identifier: "S",
        },
        {
            name: "Monday",
            identifier: "M",
        },
        {
            name: "Tuesday",
            identifier: "T",
        },
        {
            name: "Wednesday",
            identifier: "W",
        },
        {
            name: "Thursday",
            identifier: "T",
        },
        {
            name: "Friday",
            identifier: "F",
        },
        {
            name: "Saturday",
            identifier: "S",
        },
    ])
    const weekdays = [
        {
            name: "Monday",
            value: 1
        },
        {
            name: "Tuesday",
            value: 2
        },
        {
            name: "Wednesday",
            value: 3
        },
        {
            name: "Thursday",
            value: 4
        },
        {
            name: "Friday",
            value: 5
        },
    ]
    const weekdays2 = [
        {
            name: "Sunday",
            value: 0
        },
        {
            name: "Monday",
            value: 1
        },
        {
            name: "Tuesday",
            value: 2
        },
        {
            name: "Wednesday",
            value: 3
        },
        {
            name: "Thursday",
            value: 4
        },
        {
            name: "Friday",
            value: 5
        },
        {
            name: "Saturday",
            value: 6
        },
    ]


    function replaceInterval(text) {
        if (text.includes('day')) {
            return 'day'
        }
        if (text.includes('week')) {
            return 'week'
        }
        if (text.includes('month')) {
            return 'month'
        }
        if (text.includes('year')) {
            return 'year'
        }
    }

    const refillSavedValues = () => {
        if (doneFilling) return
        if (repeatOption?.type === 'custom') {
            const dataType = repeatOption?.data?.type;
            const interval = repeatOption?.data?.interval;
            const week_days = repeatOption?.data?.week_days ?? []
            setCustomRepeatEveryValue(interval);
            setCustomRepeatEveryUnit(replaceInterval(dataType));
            //get week days from weekdays2 array and set it to repeatDaysSelected
            const _repeatDaysSelected = []
            week_days?.forEach((x, i) => {
                const day = weekdays2.find(d => d.value === x)
                _repeatDaysSelected.push(day?.name)
            }
            )
            setRepeatDaysSelected(_repeatDaysSelected)
            const end = repeatOption?.data?.end;
            if (end?.type === 'on') {
                setEndDate(end.date);
                setEndOption('on')
            }
            if (end?.type === 'after') {
                setEndAfterValue(end.occurrences);
                setEndOption('after')
            }
        }
    }

    useEffect(() => {
        refillSavedValues();
    }, [])


    const getFormattedDate = (date) => {
        const formattedDate = format(date, "dd MMM, yyyy hh:mm a")
        return formattedDate
    }

    const handleRepeatSelectedDays = (day) => {
        let _repeatDaysSelected = [...repeatDaysSelected]
        if (_repeatDaysSelected.includes(day)) {
            _repeatDaysSelected = _repeatDaysSelected.filter(d => d !== day)
        }
        else {
            _repeatDaysSelected.push(day)
        }
        setRepeatDaysSelected(_repeatDaysSelected)
    }

    const displayRepeatIdentifiers = repeatDaysIdentifiers?.map((x, i) => {
        const selected = repeatDaysSelected?.includes(x?.name)
        return <PerIdentifier key={i}
            selected={selected}
            onClick={() => handleRepeatSelectedDays(x?.name)}
        >
            {x?.identifier}
        </PerIdentifier>
    })

    const options = [
        "no_repeat",
        "daily",
        "weekly_on_",
        "monthly_on_the_",
        "annually_on_",
        "every_weekday_",
        "custom"
    ]


    const weekdaySelectionFn = (weekday) => {
        let _selectedWeekdaysInRepeatOption = selectedWeekdaysInRepeatOption
        let _weekdays = [..._selectedWeekdaysInRepeatOption]
        if (_weekdays.includes(weekday)) {
            _weekdays = _weekdays.filter(w => w !== weekday)
        }
        else {
            _weekdays.push(weekday)
        }
        // let data = {
        //     type: "every_weekday_(week_days)",
        //     data: {
        //         week_days: _weekdays
        //     }
        // }
        // dispatch(setRepeatOption(data))
        setSelectedWeekdaysInRepeatOption(_weekdays)
    }

    const getWeekdayOrdinalPosition = () => {
        const today = new Date(timeToPost) ?? new Date();
        const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)

        const month = today.getMonth();
        const year = today.getFullYear();

        let occurrence = 0;

        for (let date = 1; date <= today.getDate(); date++) {
            const currentDate = new Date(year, month, date);
            if (currentDate.getDay() === dayOfWeek) {
                occurrence++;
            }
        }

        return occurrence;

    }

    const arrangeOptions = (option) => {
        let options = {}
        if (option === "custom") {
            setOpenCustomModal(true)
            return;
        }

        if (option === "no_repeat") {
            options.type = "no_repeat"
            dispatch(setRepeatOptionType(option));
            dispatch(setRepeatOption(options))
        }
        if (option === "daily") {
            options.type = "daily"
            dispatch(setRepeatOptionType(option));
            dispatch(setRepeatOption(options))
        }
        if (option === "weekly_on_") {
            options.type = 'weekly_on_(weekday)'
            let data = {
                weekday: new Date(timeToPost).getDay() ?? new Date().getDay()
            };
            options.data = data
            dispatch(setRepeatOptionType(option));
            dispatch(setRepeatOption(options))
        }
        if (option === "monthly_on_the_") {
            options.type = 'monthly_on_the_(ordinal)_(weekday)'
            let data = {
                ordinal: getWeekdayOrdinalPosition(),
                weekday: new Date().getDay()
            };
            options.data = data
            dispatch(setRepeatOptionType(option));
            dispatch(setRepeatOption(options))
        }
        if (option === "annually_on_") {
            options.type = 'annually_on_(month)_(date_of_month)'
            let data = {
                month: new Date().getMonth(),
                date_of_month: new Date().getDate()
            };
            options.data = data
            dispatch(setRepeatOptionType(option));
            dispatch(setRepeatOption(options))
        }
        if (option === "every_weekday_") {
            options.type = 'every_weekday_(week_days)'
            setShowWeekdaySelection(true)
        }
    }

    const isSelected = (option) => {
        return option === repeat_option_type
    }

    const saveWeekdayOptions = (option) => {
        let data = {
            type: "every_weekday_(week_days)",
            data: {
                week_days: selectedWeekdaysInRepeatOption
            }
        }
        dispatch(setRepeatOptionType(option));
        dispatch(setRepeatOption(data))
        document.body.click();
    }

    const displayOptions = options.map((option, index) => {
        return (
            <EachOption
                key={index}
                onClick={() => {
                    setSelectedOption(option)
                    arrangeOptions(option)
                }}
            >

                <span className="ms-2">
                    {
                        option === "no_repeat" ? "Doesn't Repeat" :
                            option === "daily" ? "Daily" :
                                option === "weekly_on_" ? "Weekly" :
                                    option === "monthly_on_the_" ? "Monthly" :
                                        option === "annually_on_" ? "Annually" :
                                            option === "every_weekday_" ? "Every Weekday (Monday to Friday)" :
                                                option === "custom" ? "Custom" : ""
                    }
                </span>
                {
                    isSelected(option) && <MdOutlineCheck size={20} color="green" />
                }
            </EachOption>
        )
    })

    const displayWeekdaySelection = weekdays.map((weekday, index) => {
        const isSelected = selectedWeekdaysInRepeatOption?.includes(weekday.value) ?? false
        return (
            <EachOption
                key={index}
                onClick={() => {
                    weekdaySelectionFn(weekday.value)
                }}
            >
                {weekday.name}
                {
                    isSelected && <MdOutlineCheck size={20} color="green" />
                }
            </EachOption>
        )
    })


    if (showWeekdaySelection) {
        return <Card>
            <Card.Body className="p-0">
                <FlexCard>
                    <IconCard
                        onClick={() => {
                            setShowWeekdaySelection(false)
                        }}
                    >
                        <MdOutlineKeyboardBackspace size={20} />
                    </IconCard>
                    <IconCard
                        onClick={() => {
                            saveWeekdayOptions('every_weekday_')
                        }}
                    >
                        <MdOutlineSave size={20} />
                    </IconCard>
                </FlexCard>
                {displayWeekdaySelection}
            </Card.Body>
        </Card>
    }

    const handleRadioChange = (e) => {
        setEndOption(e)
        if (e === 'never') {
            afterRadioRef.current.checked = false
            onRadioRef.current.checked = false
        }
        if (e === 'after') {
            neverRadioRef.current.checked = false
            onRadioRef.current.checked = false
        }
        if (e === 'on') {
            neverRadioRef.current.checked = false
            afterRadioRef.current.checked = false
        }
    }

    const handleCustomRepeatOption = () => {
        let _repeat_option = {};
        _repeat_option.type = "custom"
        //set the end
        let end = {};

        if (endOption === 'never') {
            end.type = 'never'
        }
        if (endOption === 'after') {
            end.type = 'after'
            end.occurrences = endAfterValue
        }
        if (endOption === 'on') {
            end.type = 'on'
            end.date = endDate
        }
        // _repeat_option.end = end;
        _repeat_option.start = timeToPost;

        if (customRepeatEveryUnit === 'day') {
            _repeat_option.data = {
                type: "(interval)-days",
                interval: customRepeatEveryValue,
                end: end,
                start: timeToPost
            }
        }
        if (customRepeatEveryUnit === 'week') {
            const weekDaysValues = repeatDaysSelected.map(day => {
                const value = weekdays2.find(weekday => weekday.name === day).value
                return value
            });
            _repeat_option.data = {
                type: "(interval)-weeks",
                interval: customRepeatEveryValue,
                week_days: weekDaysValues,
                end: end,
                start: timeToPost
            }
        }

        if (customRepeatEveryUnit === 'month') {
            if (selectedMonthlyOption === 'monthly_by_date') {
                const currentDate = new Date(timeToPost) ?? new Date();
                const dayOfMonth = currentDate.getDate();
                _repeat_option.data = {
                    type: "(interval)-months",
                    interval: customRepeatEveryValue,
                    date: dayOfMonth,
                    end: end,
                    start: timeToPost
                }
            }
            const currentDate = new Date(timeToPost) ?? new Date();
            const dayOfMonth = currentDate.getDate();
            const currentWeekNumber = Math.ceil(dayOfMonth / 7);
            _repeat_option.data = {
                type: "(interval)-months",
                interval: customRepeatEveryValue,
                weekday: currentWeekNumber,
                end: end,
                start: timeToPost
            }
        }

        if (customRepeatEveryUnit === 'year') {
            _repeat_option.data = {
                type: "yearly",
                interval: customRepeatEveryValue,
                end: end,
                start: timeToPost
            }
        }
        dispatch(setRepeatOptionType('custom'));
        dispatch(setRepeatOption(_repeat_option))
        setOpenCustomModal(false)
    }



    const getSelectedMonthOption = (overrides) => {
        if (!overrides) {
            if (selectedMonthlyOption === 'monthly_by_date') {
                const currentDate = new Date(timeToPost) ?? new Date();
                const dayOfMonth = currentDate.getDate();
                return `Monthly on ${dayOfMonth}`
            }
            const currentDate = new Date(timeToPost) ?? new Date();
            const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            const currentDayOfWeek = daysOfWeek[currentDate.getDay()];
            return `Monthly on ${getCurrentNumberOfWeek(timeToPost)} ${currentDayOfWeek}`;
        }
        if (overrides === 'monthly_by_date') {
            const currentDate = new Date(timeToPost) ?? new Date();
            const dayOfMonth = currentDate.getDate();
            return `Monthly on ${dayOfMonth}`
        }
        if (overrides === 'monthly_by_weekday') {
            const currentDate = new Date(timeToPost) ?? new Date();
            const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            const currentDayOfWeek = daysOfWeek[currentDate.getDay()];
            return `Monthly on ${getCurrentNumberOfWeek(timeToPost)} ${currentDayOfWeek}`;
        }
    }

    return (
        <>
            <Card>
                <Card.Body className="p-0">
                    {displayOptions}
                </Card.Body>
            </Card>

            <Modal
                size='lg'
                centered={true}
                show={openCustomModal}
                onHide={() => {
                    setOpenCustomModal(false);
                }}
                onExit={() => {
                    setOpenCustomModal(false);
                }}>
                <Modal.Header>
                    <Modal.Title>Custom recurrence</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CustomRepeatArea>
                        <TopNav>
                            <p
                                className="mb-0"
                            >
                                Repeat every:
                            </p>
                            <InputTag
                                value={customRepeatEveryValue}
                                /* type="number" */
                                onChange={(e) => {
                                    setCustomRepeatEveryValue(e.target.value)
                                }}
                            />
                            <IncrementDecrementHolder>
                                <AiFillCaretUp size={17}
                                    onClick={() => {
                                        setCustomRepeatEveryValue(customRepeatEveryValue + 1)
                                    }}
                                    style={{
                                        cursor: "pointer"
                                    }}
                                />
                                <AiFillCaretDown size={17}
                                    onClick={() => {
                                        setCustomRepeatEveryValue(customRepeatEveryValue - 1)
                                    }}
                                    style={{
                                        cursor: "pointer"
                                    }}
                                />

                            </IncrementDecrementHolder>
                            <PopDrop
                                width={270}
                                dropDownAnchor={
                                    <Button
                                        ref={ref}
                                        size='md'
                                        variant={'outline-secondary'}
                                        style={{
                                            height: 40,
                                            width: 100,
                                            marginLeft: 2
                                        }}
                                    >
                                        <span style={{ fontSize: '13px' }}>
                                            {`${customRepeatEveryUnit}${customRepeatEveryValue > 1 ? 's' : ''}`}
                                        </span>
                                    </Button>
                                }
                                PopContainer={
                                    <TimeUnitDrop>
                                        <EachTimeUnitDrop
                                            onClick={() => {
                                                setCustomRepeatEveryUnit('day')
                                                document.body.click()
                                            }}
                                        >
                                            day
                                        </EachTimeUnitDrop>
                                        <EachTimeUnitDrop
                                            onClick={() => {
                                                setCustomRepeatEveryUnit('week')
                                                document.body.click()
                                            }}
                                        >week</EachTimeUnitDrop>
                                        <EachTimeUnitDrop
                                            onClick={() => {
                                                setCustomRepeatEveryUnit('month')
                                                document.body.click()
                                            }}
                                        >month</EachTimeUnitDrop>
                                        <EachTimeUnitDrop
                                            onClick={() => {
                                                setCustomRepeatEveryUnit('year')
                                                document.body.click()
                                            }}
                                        >year</EachTimeUnitDrop>
                                    </TimeUnitDrop>
                                }
                            />
                        </TopNav>
                        {
                            customRepeatEveryUnit === 'week' &&
                            <>
                                <p
                                    className="mb-0 mt-2"
                                >
                                    Repeats on:
                                </p>
                                <RepeatAngle>
                                    {displayRepeatIdentifiers}
                                </RepeatAngle>
                            </>
                        }
                        {
                            customRepeatEveryUnit === 'month' &&
                            <>
                                <RepeatAngle>
                                    <PopDrop
                                        alignment={'start'}
                                        width={270}
                                        dropDownAnchor={
                                            <Button
                                                ref={ref}
                                                size='md'
                                                variant={'outline-secondary'}
                                                style={{
                                                    /* marginTop: -20, */
                                                    height: 40,
                                                    // width: 100,
                                                    marginLeft: 2
                                                }}
                                            /* className='mx-2 fs--2 px-1 fw-medium cursor-pointer' */
                                            >
                                                <span style={{ fontSize: '13px' }}>
                                                    {
                                                        getSelectedMonthOption()
                                                    }
                                                </span>
                                            </Button>
                                        }
                                        PopContainer={
                                            <TimeUnitDrop>
                                                <EachTimeUnitDrop
                                                    onClick={() => {
                                                        setSelectedMonthlyOption(`monthly_by_date`)
                                                        document.body.click()
                                                    }}
                                                >
                                                    {getSelectedMonthOption('monthly_by_date')}
                                                </EachTimeUnitDrop>
                                                <EachTimeUnitDrop
                                                    onClick={() => {
                                                        setSelectedMonthlyOption(`monthly_by_weekday`)
                                                        document.body.click()
                                                    }}
                                                >
                                                    {getSelectedMonthOption('monthly_by_weekday')}
                                                </EachTimeUnitDrop>
                                            </TimeUnitDrop>
                                        }
                                    />
                                </RepeatAngle>
                            </>
                        }

                        <p
                            className="mb-0 mt-2"
                        >
                            Starts: {getFormattedDate(new Date(timeToPost) ?? new Date())}
                        </p>
                        <p
                            className="mb-0 mt-2"
                        >
                            Ends:
                        </p>

                        <RadioHolder>
                            <RadioInput
                                ref={neverRadioRef}
                                checked={endOption === 'never'}
                                type="radio"
                                onChange={() => handleRadioChange('never')}
                            /> Never
                        </RadioHolder>
                        <RadioHolder>
                            <RadioInput
                                ref={onRadioRef}
                                checked={endOption === 'on'}
                                type="radio"
                                onChange={() => handleRadioChange('on')}
                            /> On
                            <DatePicker
                                style={{
                                    marginLeft: 50
                                }}
                                minDate={new Date()}
                                /* filterTime={filterPassedTime} */
                                selected={endDate}
                                onChange={(date) => {
                                    setEndDate(date)
                                    /* setTimeChanged(true); */
                                }}
                                formatWeekDay={day => day.slice(0, 3)}
                                className='form-control'
                                placeholderText="Select Date"
                                timeIntervals={1}
                                dateFormat="MM/dd/yyyy"
                                /* showTimeSelect */
                                fixedHeight
                            />
                        </RadioHolder>
                        <RadioHolder>
                            <RadioInput
                                ref={afterRadioRef}
                                checked={endOption === 'after'}
                                type="radio"
                                onChange={() => handleRadioChange('after')}
                            /> After
                            <InputTagHolder>
                                <InputTagChild
                                    value={endAfterValue}
                                    /* type="number" */
                                    onChange={(e) => {
                                        setEndAfterValue(e.target.value)
                                    }}
                                />
                                occurrence
                                <IncrementDecrementHolder>
                                    <AiFillCaretUp size={17}
                                        onClick={() => {
                                            setEndAfterValue(endAfterValue + 1)
                                        }}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                    />
                                    <AiFillCaretDown size={17}
                                        onClick={() => {
                                            if (endAfterValue > 1) {
                                                setEndAfterValue(endAfterValue - 1)
                                            }
                                        }}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                    />
                                </IncrementDecrementHolder>
                            </InputTagHolder>
                        </RadioHolder>

                    </CustomRepeatArea>
                </Modal.Body>
                <Modal.Footer>
                    <SubscriptionHolder>
                        <Button
                            onClick={() => setOpenCustomModal(false)}
                            variant='light'>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                // setOpenCustomModal(false)
                                handleCustomRepeatOption()
                                // setRepeatOption('custom')
                            }}
                            variant='primary'>
                            Done
                        </Button>
                    </SubscriptionHolder>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default RepeatOptionPop