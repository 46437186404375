import PropTypes from "prop-types";
import { Image, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "components/common/Avatar";
import Flex from "components/common/Flex";
import classNames from "classnames";
import FalconLightBox from "components/common/FalconLightBox";
import FalconLightBoxGallery from "components/common/FalconLightBoxGallery";
import ChatMessageOptions from "./ChatMessageOptions";
import { useSelector } from "react-redux";
import { ChatContext } from "context/Context";
import { useContext, useState } from "react";
import { useFetchPageOwner } from "../hooks/useFetchUser";
import { getContentUploadingProgress } from "redux/slices/postslice";
import { useEffect } from "react";

// {
//   (message?.attachments && message.attachments.length === 1) && (
//     <FalconLightBox image={getGalleryImages(message?.attachments)} onClose={() => setSelectedImgIndex(0)}>
//       {renderAttachmentPreview(message)}
//     </FalconLightBox>
//   )
// }

// function renderAttachmentPreview(message) {
//   const commonStyles = {
//     display: 'grid',
//     placeItems: 'center',
//     position: 'relative',
//   };

//   return (
//     message?.attachments['url'] ? (
//       <div
//         className="previewFiles"
//         style={{
//           ...commonStyles,
//           maxWidth: '200px',
//           overflow: 'hidden',
//           border: '1px solid #ccc',
//           borderRadius: '8px',
//           boxShadow: '0 0 4px rgba(0, 0, 0, 0.2)',
//           gridTemplateColumns: 'repeat(auto-fit, minmax(min(100%, 200px), 1fr))',
//           padding: 0,
//         }}
//       >
//         <div className='position-absolute' style={{ visibility: `${status === "pending" ? "visible" : "hidden"}`, opacity: `${status === "pending" ? "1" : "0"}`, display: "flex", justifyContent: 'center', alignItems: 'center', flexDirection: 'column', inset: "0", zIndex: "5", backdropFilter: "blur(20px)", borderRadius: '8px', overflow: "hidden" }}>
//           <div
//             style={{
//               fontSize: '13px',
//               marginBottom: '10px',
//               position: 'relative',
//               width: '100px',
//               height: '100px',
//               background: "transparent",
//               display: "flex",
//               justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
//             }}
//           >
//             {isLoading ? (
//               <span style={borderStyle}></span>
//             ) : (
//               <div style={{ textAlign: 'center' }}>
//                 <Spinner animation="border" style={{ borderColor: "#2c7be5", borderTopColor: "transparent" }} />
//               </div>
//             )}
//           </div>
//         </div>
//         <div
//           className="previewFile"
//           style={{
//             ...commonStyles,
//             // other styling for previewFile
//           }}
//         >
//           {renderFileType(message?.attachments[0], setSelectedImgIndex, selectedImgIndex)}
//         </div>
//         <span
//           style={{ position: "absolute", bottom: "-10px", fontSize: '15px', right: "5%" }}
//           className="mb-0"
//           dangerouslySetInnerHTML={{
//             __html: reactions && reactions[0]?.emoji
//           }}
//         />
//       </div>
//     ) : (message?.attachments['url'] && message?.attachments['sticker_id']) ? (
//       <div
//         className="previewFiles"
//         style={{
//           ...commonStyles,
//           maxWidth: '200px',
//           overflow: 'hidden',
//           border: '1px solid #ccc',
//           borderRadius: '8px',
//           boxShadow: '0 0 4px rgba(0, 0, 0, 0.2)',
//           gridTemplateColumns: 'repeat(auto-fit, minmax(min(100%, 200px), 1fr))',
//           padding: 0,
//         }}
//       >
//         <div className='position-absolute' style={{ visibility: `${status === "pending" ? "visible" : "hidden"}`, opacity: `${status === "pending" ? "1" : "0"}`, display: "flex", justifyContent: 'center', alignItems: 'center', flexDirection: 'column', inset: "0", zIndex: "5", backdropFilter: "blur(20px)", borderRadius: '8px', overflow: "hidden" }}>
//           <div
//             style={{
//               fontSize: '13px',
//               marginBottom: '10px',
//               position: 'relative',
//               width: '100px',
//               height: '100px',
//               background: "transparent",
//               display: "flex",
//               justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
//             }}
//           >
//             {isLoading ? (
//               <span style={borderStyle}></span>
//             ) : (
//               <div style={{ textAlign: 'center' }}>
//                 <Spinner animation="border" style={{ borderColor: "#2c7be5", borderTopColor: "transparent" }} />
//               </div>
//             )}
//           </div>
//         </div>
//         <div
//           className="previewFile"
//           style={{
//             ...commonStyles,
//             // other styling for previewFile
//           }}
//         >
//           {renderFileType(message?.attachments[0], setSelectedImgIndex, selectedImgIndex)}
//         </div>
//         <span
//           style={{ position: "absolute", bottom: "-10px", fontSize: '15px', right: "5%" }}
//           className="mb-0"
//           dangerouslySetInnerHTML={{
//             __html: reactions && reactions[0]?.emoji
//           }}
//         />
//       </div>
//     ) : null
//   );
// }

const Message = ({
  message,
  senderUserId,
  time,
  status,
  isGroup,
  reactions,
}) => {
  const contentUploadProgress = useSelector(getContentUploadingProgress);
  const { activeRoomId, conversations, users } = useContext(ChatContext);
  const [selectedImgIndex, setSelectedImgIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(0);

  const roomChat =
    conversations?.find(({ room_id }) => room_id === activeRoomId) || {};
  const { user } = useFetchPageOwner(activeRoomId, conversations, users) ?? {};
  const name = user?.user_name?.split(" ")[0];
  const isLeft = senderUserId !== roomChat?.user_id;

  useEffect(() => {
    if (
      status !== "pending" ||
      !message?.attachments ||
      message.attachments.length === 0
    ) {
      return; //? Exit early if status is not "pending" or there are no attachments
    }

    const interval = setInterval(() => {
      setIsLoading((prevLoading) => {
        if (
          prevLoading < 15 &&
          (contentUploadProgress?.completed === 100 || status === "pending")
        ) {
          return prevLoading + 1;
        } else {
          clearInterval(interval);
          return false;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [status, message?.attachments, contentUploadProgress?.completed]);

  const borderStyle = {
    width: "35px",
    height: "35px",
    borderRadius: "50%",
    transition: "border-color 1s ease-in",
    border: "3px solid #2c7be5",
    borderRightColor: isLoading >= 3 ? "#2c7be5" : "transparent",
    borderBottomColor: isLoading >= 5 ? "#2c7be5" : "transparent",
    borderLeftColor: isLoading >= 10 ? "#2c7be5" : "transparent",
    borderTopColor: isLoading >= 15 ? "#2c7be5" : "transparent",
  };

  const getGalleryImages = (attachments) => {
    return attachments
      .filter((file) => file.type === "image")
      .map((imageFile) => imageFile.payload.url);
  };

  const renderFileType = (attachment, setImgIndex, index) => {
    switch (attachment.type) {
      case "image":
        return (
          <Image
            fluid
            rounded
            style={{
              objectFit: "cover",
              aspectRatio: "1/1",
              width: " 100%",
              height: "100%",
            }}
            className="mb-2 cursor-pointer"
            src={attachment.payload.url}
            alt=""
            onClick={() => setImgIndex(index)}
          />
        );
      case "video":
        return (
          <video style={{ width: "100%", borderRadius: "1rem" }} controls>
            <source src={attachment.payload.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      case "audio":
        return (
          <audio style={{ width: "250px" }} controls>
            <source src={attachment.payload.url} type="audio/mp3" />
            Your browser does not support the audio tag.
          </audio>
        );
      case "file":
        return (
          <a
            href={attachment.payload.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            View Document
          </a>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Flex className={classNames("p-3", { "d-block": !isLeft })}>
        {isLeft && <Avatar size="l" className="me-2" src={message?.avatar} />}
        <div
          className={classNames("flex-1", {
            "d-flex justify-content-end": !isLeft,
          })}
        >
          <div
            className={classNames("w-xxl-75", {
              "w-100": !isLeft,
            })}
          >
            <Flex
              alignItems="center"
              className={classNames("hover-actions-trigger", {
                "flex-end-center": !isLeft,
                "align-items-center": isLeft,
              })}
            >
              {message?.attachments && message.attachments.length > 1 ? (
                // Display gallery for multiple attachments

                <div className="chat-message chat-gallery">
                  <FalconLightBoxGallery
                    images={getGalleryImages(message?.attachments)}
                  >
                    {(setImgIndex) => (
                      <div
                        className="previewFiles"
                        style={{
                          display: "grid",
                          gridAutoFlow: "dense",
                          position: "relative",
                          gap: ".2rem",
                          maxWidth: "310px",
                          gridTemplateColumns:
                            "repeat(auto-fit, minmax(min(100%, 150px), 1fr))",
                          padding: 0,
                        }}
                      >
                        {message?.attachments.length > 4 && (
                          <div
                            key="remaining"
                            className="previewFile"
                            style={{
                              display: "grid",
                              placeItems: "center",
                              position: "absolute",
                              right: "5%",
                              bottom: "5%",
                              zIndex: "4",
                              overflow: " hidden",
                              padding: ".2rem",
                              borderRadius: "3px",
                              background: "rgba(0, 0, 0, 0.2)",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "20px",
                                color: "#ffffff",
                                fontWeight: "bold",
                              }}
                            >
                              +{message?.attachments.length - 4}
                            </div>
                          </div>
                        )}

                        <div
                          className="position-absolute"
                          style={{
                            visibility: `${
                              status === "pending" ? "visible" : "hidden"
                            }`,
                            opacity: `${status === "pending" ? "1" : "0"}`,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            inset: "0",
                            zIndex: "5",
                            backdropFilter: "blur(20px)",
                            borderRadius: "8px",
                            overflow: "hidden",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "13px",
                              marginBottom: "10px",
                              position: "relative",
                              width: "100px",
                              height: "100px",
                              background: "transparent",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            {isLoading ? (
                              <span style={borderStyle}></span>
                            ) : (
                              <div style={{ textAlign: "center" }}>
                                <Spinner
                                  animation="border"
                                  style={{
                                    borderColor: "#2c7be5",
                                    borderTopColor: "transparent",
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        {message?.attachments
                          ?.slice(0, 4)
                          .map((attachment, index) => (
                            <div
                              key={index}
                              className="previewFile"
                              style={{
                                display: "grid",
                                placeItems: "center",
                                position: "relative",
                                overflow: " hidden",
                                border: "1px solid #ccc",
                                borderRadius: "8px",
                                boxShadow: "0 0 4px rgba(0, 0, 0, 0.2)",
                              }}
                            >
                              {renderFileType(attachment, setImgIndex, index)}
                            </div>
                          ))}

                        <span
                          style={{
                            position: "absolute",
                            bottom: "-10px",
                            fontSize: "15px",
                            right: "5%",
                          }}
                          className="mb-0"
                          dangerouslySetInnerHTML={{
                            __html: reactions && reactions[0]?.emoji,
                          }}
                        />
                      </div>
                    )}
                  </FalconLightBoxGallery>
                </div>
              ) : (
                //? Display single attachment

                message?.attachments &&
                message.attachments.length === 1 && (
                  <FalconLightBox
                    image={getGalleryImages(message?.attachments)}
                    onClose={() => setSelectedImgIndex(0)}
                  >
                    <div
                      className="previewFiles"
                      style={{
                        display: "grid",
                        gridAutoFlow: "dense",
                        maxWidth: "200px",
                        position: "relative",
                        overflow: " hidden",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        boxShadow: "0 0 4px rgba(0, 0, 0, 0.2)",
                        gridTemplateColumns:
                          "repeat(auto-fit, minmax(min(100%, 200px), 1fr))",
                        padding: 0,
                      }}
                    >
                      <div
                        className="position-absolute"
                        style={{
                          visibility: `${
                            status === "pending" ? "visible" : "hidden"
                          }`,
                          opacity: `${status === "pending" ? "1" : "0"}`,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          inset: "0",
                          zIndex: "5",
                          backdropFilter: "blur(20px)",
                          borderRadius: "8px",
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "13px",
                            marginBottom: "10px",
                            position: "relative",
                            width: "100px",
                            height: "100px",
                            background: "transparent",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          {isLoading ? (
                            <span style={borderStyle}></span>
                          ) : (
                            <div style={{ textAlign: "center" }}>
                              <Spinner
                                animation="border"
                                style={{
                                  borderColor: "#2c7be5",
                                  borderTopColor: "transparent",
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="previewFile"
                        style={{
                          display: "grid",
                          placeItems: "center",
                          position: "relative",
                          // overflow: ' hidden',
                        }}
                      >
                        {renderFileType(
                          message?.attachments[0],
                          setSelectedImgIndex,
                          selectedImgIndex
                        )}
                      </div>
                      <span
                        style={{
                          position: "absolute",
                          bottom: "-10px",
                          /* cursor: "pointer",*/ fontSize: "15px",
                          right: "5%",
                        }}
                        className="mb-0"
                        dangerouslySetInnerHTML={{
                          __html: reactions && reactions[0]?.emoji,
                        }}
                      />
                    </div>
                  </FalconLightBox>
                )
              )}

              {!message?.attachments && (
                // Display text message if no attachments
                <div
                  style={{ position: "relative" }}
                  className={classNames("p-2 rounded-2 chat-message", {
                    "bg-200": isLeft,
                    "bg-primary text-white light": !isLeft,
                  })}
                >
                  {message?.text && (
                    <>
                      <p
                        className="mb-0 text-nowrap"
                        dangerouslySetInnerHTML={{
                          __html: message?.text && message?.text,
                        }}
                      />
                      <span
                        style={{
                          position: "absolute",
                          bottom: "-10px",
                          /* cursor: "pointer",*/ fontSize: "15px",
                          right: "5%",
                        }}
                        className="mb-0"
                        dangerouslySetInnerHTML={{
                          __html: reactions && reactions[0]?.emoji,
                        }}
                      />
                    </>
                  )}
                </div>
              )}
            </Flex>

            <div
              className={classNames("text-400 fs--2", {
                "text-end": !isLeft,
              })}
            >
              {isLeft && isGroup && (
                <span className="font-weight-semi-bold me-2">{name}</span>
              )}
              {time?.time}
              {!isLeft && !!message && !!status && (
                <FontAwesomeIcon
                  icon={classNames({
                    check: status === "seen" || status === "sent",
                    "check-double": status === "delivered",
                    clock: status === "pending",
                  })}
                  className={classNames("ms-2", {
                    "text-success": status === "seen",
                  })}
                />
              )}
            </div>
          </div>
        </div>
      </Flex>

      {/* <Flex className={classNames('p-3', { 'd-block': !isLeft })}>
        {isLeft && <Avatar size="l" className="me-2" src={message?.avatar ? message?.avatar : "😎"} />}
        <div
          className={classNames('flex-1', {
            'd-flex justify-content-end': !isLeft
          })}
        >
          <div
            className={classNames('w-xxl-75', {
              'w-100': !isLeft
            })}
          >
            <Flex
              alignItems="center"
              className={classNames('hover-actions-trigger', {
                'flex-end-center': !isLeft,
                'align-items-center': isLeft
              })}
            >
               {!isLeft && <ChatMessageOptions id={message.id} onActionClick={handleActionClick} />}

              {message?.attachments && message.attachments.length > 1 ? (
                <div className="chat-message chat-gallery">
                  <FalconLightBoxGallery images={message?.attachments}>
                    {setImgIndex => (
                      <Row className="mx-n1">
                        {message?.attachments?.map((img, index) => (
                          <Col
                            xs={6}
                            md={4}
                            className="px-1"
                            style={{ minWidth: 50 }}
                            key={index}
                          >
                            <Image
                              fluid
                              rounded
                              className="mb-2 cursor-pointer"
                              src={img}
                              alt=""
                              onClick={() => setImgIndex(index)}
                            />
                          </Col>
                        ))}
                      </Row>
                    )}
                  </FalconLightBoxGallery>
                </div>
              ) : (
                (message?.attachments && message.attachments.length === 1) && (
                  <FalconLightBox image={message?.attachments[0]}>
                    <Image
                      fluid
                      rounded
                      src={message?.attachments[0]}
                      width={150}
                      alt=""
                    />
                  </FalconLightBox>
                )
              )}
              {!message?.attachments && (
                <div
                  className={classNames('p-2 rounded-2 chat-message', {
                    'bg-200': isLeft,
                    'bg-primary text-white light': !isLeft
                  })}
                >
                  {(message || message?.text) && (
                    <p
                      className="mb-0"
                      dangerouslySetInnerHTML={{
                        __html: message?.text ? message?.text : message
                      }}
                    />
                  )}
                </div>
              )}
               {isLeft && <ChatMessageOptions id={message.id} onActionClick={handleActionClick} />} 

            </Flex>
            <div
              className={classNames('text-400 fs--2', {
                'text-end': !isLeft
              })}
            >
              {isLeft && isGroup && (
                <span className="font-weight-semi-bold me-2">{name}</span>
              )}
              {time?.time}
              {!isLeft && !!message && !!status && (
                <FontAwesomeIcon
                  icon={classNames({
                    check: status === 'seen' || status === 'sent',
                    'check-double': status === 'delivered',
                    'clock': status === 'pending'  // Add this line for the "time" icon
                  })}
                  className={classNames('ms-2', {
                    'text-success': status === 'seen'
                  })}
                />
              )}
            </div>
          </div>
        </div>
      </Flex>*/}
    </>
  );
};
Message.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  senderUserId: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  status: PropTypes.string,
  time: PropTypes.object.isRequired,
  isGroup: PropTypes.bool,
};

Message.defaultProps = { status: "" };

export default Message;

//  {currentUserMessages?.length === 0 ? (

//   <Flex className={classNames('p-3', { 'd-block': !isLeft })}>
//     {isLeft && <Avatar size="l" className="me-2" src={user?.avatarSrc} />}
//     <div
//       className={classNames('flex-1', {
//         'd-flex justify-content-end': !isLeft
//       })}
//     >
//       <div
//         className={classNames('w-xxl-75', {
//           'w-100': !isLeft
//         })}
//       >
//         <Flex
//           alignItems="center"
//           className={classNames('hover-actions-trigger', {
//             'flex-end-center': !isLeft,
//             'align-items-center': isLeft
//           })}
//         >
//           {!isLeft && <ChatMessageOptions />}
//           {message?.attachments ? (
//             <div className="chat-message chat-gallery">
//               {message?.text && (
//                 <p
//                   className="mb-0"
//                   dangerouslySetInnerHTML={{
//                     __html: message?.text ? message?.text : message
//                   }}
//                 />
//               )}
//               <FalconLightBoxGallery images={message?.attachments}>
//                 {setImgIndex => (
//                   <Row className="mx-n1">
//                     {message?.attachments?.map((img, index) => {
//                       return (
//                         <Col
//                           xs={6}
//                           md={4}
//                           className="px-1"
//                           style={{ minWidth: 50 }}
//                           key={index}
//                         >
//                           <Image
//                             fluid
//                             rounded
//                             className="mb-2 cursor-pointer"
//                             src={img}
//                             alt=""
//                             onClick={() => setImgIndex(index)}
//                           />
//                         </Col>
//                       );
//                     })}
//                   </Row>
//                 )}
//               </FalconLightBoxGallery>
//             </div>
//           ) : (
//             <>
//               <div
//                 className={classNames('p-2 rounded-2 chat-message', {
//                   'bg-200': isLeft,
//                   'bg-primary text-white light': !isLeft
//                 })}
//               >
//                 {(message || message?.text) && (
//                   <p
//                     className="mb-0"
//                     dangerouslySetInnerHTML={{
//                       __html: message?.text ? message?.text : message
//                     }}
//                   />
//                 )}
//                 {message?.attachment && (
//                   <FalconLightBox image={message?.attachment}>
//                     <Image
//                       fluid
//                       rounded
//                       src={message?.attachment}
//                       width={150}
//                       alt=""
//                     />
//                   </FalconLightBox>
//                 )}
//               </div>
//             </>
//           )}
//           {isLeft && <ChatMessageOptions />}
//         </Flex>
//         <div
//           className={classNames('text-400 fs--2', {
//             'text-end': !isLeft
//           })}
//         >
//           {isLeft && isGroup && (
//             <span className="font-weight-semi-bold me-2">{name}</span>
//           )}
//           {time?.time}
//           {!isLeft && !!message && !!status && (
//             <FontAwesomeIcon
//               icon={classNames({
//                 check: status === 'seen' || status === 'sent',
//                 'check-double': status === 'delivered'
//               })}
//               className={classNames('ms-2', {
//                 'text-success': status === 'seen'
//               })}
//             />
//           )}
//         </div>
//       </div>
//     </div>
//   </Flex>
// ) : (
//   <Flex className={classNames('p-3', { 'd-block': !isLeft })}>
//     {isLeft && <Avatar size="l" className="me-2" src={message?.avatar ? message?.avatar : "😎"} />}
//     <div
//       className={classNames('flex-1', {
//         'd-flex justify-content-end': !isLeft
//       })}
//     >
//       <div
//         className={classNames('w-xxl-75', {
//           'w-100': !isLeft
//         })}
//       >
//         <Flex
//           alignItems="center"
//           className={classNames('hover-actions-trigger', {
//             'flex-end-center': !isLeft,
//             'align-items-center': isLeft
//           })}
//         >
//           {!isLeft && <ChatMessageOptions />}
//           {message?.attachments ? (
//             <div className="chat-message chat-gallery">
//               {message?.text && (
//                 <p
//                   className="mb-0"
//                   dangerouslySetInnerHTML={{
//                     __html: message?.text ? message?.text : message
//                   }}
//                 />
//               )}
//               <FalconLightBoxGallery images={message?.attachments}>
//                 {setImgIndex => (
//                   <Row className="mx-n1">
//                     {message?.attachments?.map((img, index) => {
//                       return (
//                         <Col
//                           xs={6}
//                           md={4}
//                           className="px-1"
//                           style={{ minWidth: 50 }}
//                           key={index}
//                         >
//                           <Image
//                             fluid
//                             rounded
//                             className="mb-2 cursor-pointer"
//                             src={img}
//                             alt=""
//                             onClick={() => setImgIndex(index)}
//                           />
//                         </Col>
//                       );
//                     })}
//                   </Row>
//                 )}
//               </FalconLightBoxGallery>
//             </div>
//           ) : (
//             <>
//               <div
//                 className={classNames('p-2 rounded-2 chat-message', {
//                   'bg-200': isLeft,
//                   'bg-primary text-white light': !isLeft
//                 })}
//               >
//                 {(message || message?.text) && (
//                   <p
//                     className="mb-0"
//                     dangerouslySetInnerHTML={{
//                       __html: message?.text ? message?.text : message
//                     }}
//                   />
//                 )}
//                 {message?.attachment && (
//                   <FalconLightBox image={message?.attachment}>
//                     <Image
//                       fluid
//                       rounded
//                       src={message?.attachment}
//                       width={150}
//                       alt=""
//                     />
//                   </FalconLightBox>
//                 )}
//               </div>
//             </>
//           )}
//           {isLeft && <ChatMessageOptions />}
//         </Flex>
//         <div
//           className={classNames('text-400 fs--2', {
//             'text-end': !isLeft
//           })}
//         >
//           {isLeft && isGroup && (
//             <span className="font-weight-semi-bold me-2">{name}</span>
//           )}
//           {time?.time}
//           {!isLeft && !!message && !!status && (
//             <FontAwesomeIcon
//               icon={classNames({
//                 check: status === 'seen' || status === 'sent',
//                 'check-double': status === 'delivered',
//                 'clock': status === 'pending'  // Add this line for the "time" icon

//               })}
//               className={classNames('ms-2', {
//                 'text-success': status === 'seen'
//               })}
//             />
//           )}
//         </div>
//       </div>
//     </div>
//   </Flex>
// )}