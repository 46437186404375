import { DiamondsFour, GearSix, Users } from '@phosphor-icons/react';
import Flex from 'components/common/Flex';
import React, { useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import OrganizationMemberSettings from './organization_member_settings';
import OrganizationGeneralSettings from './organization_general_settings';
import OrganizationWorkspaceSettings from './organization_workspace_settings';

export default function OrganizationSettingsPanel({ initialPanel = 'general' }) {

    const { t } = useTranslation();
    const [activePanel, setActivePanel] = useState(t(initialPanel));

    const settings = [
        {
            title: t('general'),
            icon: <GearSix size={20} style={{ marginRight: '10px' }} />,
            panel: <OrganizationGeneralSettings />
        },
        {
            title: t('members'),
            icon: <Users size={20} style={{ marginRight: '10px' }} />,
            panel: <OrganizationMemberSettings />
        },
        {
            title: t('workspaces'),
            icon: <DiamondsFour size={20} style={{ marginRight: '10px' }} />,
            panel: <OrganizationWorkspaceSettings />
        }
    ];

    const renderPanelContent = () => {
        const activeSetting = settings.find(setting => setting.title === activePanel);
        return activeSetting ? activeSetting.panel : <OrganizationGeneralSettings />;
    };

    return (
        <Flex style={{ width: '100%' }}>
            <Flex style={{ width: '20%', marginRight: '20px' }}>
                <ListGroup variant="flush">
                    {settings.map(({ title, icon }) => (
                        <ListGroup.Item
                            key={title}
                            className={`rounded-3 shadow-none border-0 py-1 my-1 cursor-pointer ${activePanel === title ? 'bg-200' : ''} hover-bg-200`}
                            onClick={() => setActivePanel(title)}
                        >
                            <Flex alignItems={'center'} className={'gap-1'}>
                                {icon}
                                {title}
                            </Flex>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Flex>
            <Flex
                style={{
                    width: '95%',
                    transition: 'all .3s',
                    paddingTop: '0px'
                }}>
                {renderPanelContent()}
            </Flex>
        </Flex>
    );
}
