import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

export default function PollTimeSlot(props) {

    const { label, options, onOptionsChangeHandler } = props;
    const [selectedValue, setSelectedValue] = useState(props.selectedValue);

    useEffect(() => {
        setSelectedValue(props.selectedValue);
    }, [props.selectedValue]);

    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Form.Select
                value={selectedValue}
                onChange={(e) => {
                    let value = e.target.value;
                    setSelectedValue(value);
                    onOptionsChangeHandler(value);
                }}
            >
                {
                    options.map((x) => {
                        return (<option value={x.value}>{x.label}</option>)
                    })
                }
            </Form.Select>
        </Form.Group>
    );
}