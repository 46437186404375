import Avatar from 'components/common/Avatar';
import FalconLightBox from 'components/common/FalconLightBox';
import Flex from 'components/common/Flex';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Card, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getPostBatchData, setOpenPostDetailsDialog, setPostBatchData } from 'redux/slices/postslice';
import { getLoggedInUser } from 'redux/slices/user_slice';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { createDateWithTimeZone } from 'utils/time';
import FailedTargetsButton from './FailedTargetsButton';
import FeedDropdown from './FeedDropdown';
import { FeedStatus } from './FeedStatus';

const FeedCardHeader = ({ created_by, enableView = true, postType, post, handleCheckUpdateUpdate, fromPostDetailsDialog, ...rest }) => {

  let {
    _id,
    first_name,
    last_name,
    avatar,
    email,
    logged_in = false
  } = created_by ?? {}

  const activeWorkspace = useSelector(getActiveWorkSpace);
  const loggedInUser = useSelector(getLoggedInUser);
  const [recurring, setRecurring] = useState(rest.recurring);
  const dispatch = useDispatch();
  const [openDraft, setOpenDraft] = useState(false);
  const checkboxRef = useRef(null)
  const postBatchData = useSelector(getPostBatchData)

  useEffect(() => {
    const checked = postBatchData.find((item) => item._id === post._id);
    if (checked) {
      checkboxRef.current.checked = true;
    } else {
      checkboxRef.current.checked = false;
    }
  }, [postBatchData])


  const newUpdateHandler = (newData) => {
    setRecurring(newData.recurring);
  }

  const getAuthorName = () => {
    if (_id === loggedInUser?._id) {
      return "You";
    }
    if (rest?.data?.createdBy?._id === loggedInUser?._id) {
      return "You";
    }
    if (!first_name && !last_name) {
      return email ?? activeWorkspace?.name;
    }
    return `${first_name} ${last_name}`;
  }

  const getStatus = () => {
    if (getAuthorName() === "You") {
      return `status-online`
    }
    return `status-${logged_in ? 'online' : 'offline'}`
  }

  const getAuthorAvatar = () => {
    return avatar ?? activeWorkspace?.avatar;
  }

  const getRelativeTime = (postTime) => {
    let time = moment(postTime).calendar();
    if (time.toLowerCase().includes("yesterday")
      || time.toLowerCase().includes("today")) {
      return time;
    }
    return moment(postTime).format('LLLL').replace(`, ${new Date().getFullYear()}`, " at ");
  }

  const getFullTime = (postTime) => {
    return moment(postTime).format('LLLL');
  }

  const authorAvatar = getAuthorAvatar();
  const status = getStatus();
  const authorName = getAuthorName();
  const postTime = getRelativeTime(createDateWithTimeZone(new Date(rest?.data?.time_to_post ?? rest.updated_at), rest?.data?.timeZone));
  const fullTime = getFullTime(createDateWithTimeZone(new Date(rest?.data?.time_to_post ?? rest.updated_at), rest?.data?.timeZone));
  const createdAtFullTime = getFullTime(createDateWithTimeZone(new Date(rest.created_at)));

  const openPost = () => {
    if (enableView) {
      let location = window.location;
      let postDetailsPath = `${location.origin}/${activeWorkspace._id}/posts/${rest._id}`;
      window.history.pushState("", "", postDetailsPath);
      dispatch(setOpenPostDetailsDialog({ open: true, data: rest._id }));
    }
  }

  return (
    <Card.Header className="bg-light">
      <Row className="justify-content-between">
        <Col>
          <Flex>
            {
              fromPostDetailsDialog && (authorAvatar ?
                <FalconLightBox image={authorAvatar}>
                  <Avatar fallBackName={authorName} size="lg" src={authorAvatar} className={status} />
                </FalconLightBox>
                :
                <Avatar size="lg" name={authorName} className={status} />)
            }
            <div
              className="flex-1 align-self-center ms-2 cursor-pointer"
              onClick={() => {
                if (postType === 'draft') {
                  setOpenDraft(true);
                } else {
                  openPost()
                }
              }}>
              <p className="mb-1 lh-1">
                <OverlayTrigger
                  placement='top'
                  overlay={
                    <Tooltip id="post-author-overlay-trigger">
                      This post was created by <b>{authorName}</b> on <i>{createdAtFullTime}</i> in this workspace
                    </Tooltip>
                  }>
                  <span style={{ cursor: 'pointer', color: '#2c7be5', fontSize: 11 }} className="fw-semi-bold" to="">
                    {authorName}
                  </span>
                </OverlayTrigger>
              </p>
              <div
                className="mb-0 fs--1"
                style={{
                  display: 'flex',
                  gap: 5,
                  alignItems: 'center',
                  flexWrap: 'wrap'
                }}>
                <FeedStatus post={{ ...rest }}
                  fromPostDetailsDialog={fromPostDetailsDialog}
                />
                •
                <OverlayTrigger
                  placement='top'
                  overlay={
                    <Tooltip id="post-time-overlay-trigger">
                      {rest.posted ? "" : rest.drafted ? "Drafted on " : rest.pending_approval ? "" : rest.failed ? "Failed to publish on " : "To be published on "}{fullTime}
                    </Tooltip>
                  }>
                  <span style={{ cursor: 'pointer', fontSize: 10 }}>
                    {postTime}
                  </span>
                </OverlayTrigger>
                {
                  (recurring || rest?.data?.repeat_option) &&
                  <div style={{ display: 'flex', gap: 10, alignItem: 'center' }}>
                    •
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id="recurring-time-overlay-trigger">
                          {"This is a recurring post"}
                        </Tooltip>
                      }>
                      <Alert className='p-0 m-0 px-2 cursor-pointer'
                        style={{
                          fontSize: 10
                        }}
                        onClick={() => {
                          openPost();
                        }}>Recurring</Alert>
                    </OverlayTrigger>
                  </div>
                }
              </div>
            </div>
          </Flex>
        </Col>
        <Col xs="auto">
          <Flex className={'gap-2'}>
            <FailedTargetsButton {...rest} post={post} />
            <Form.Check
              type="checkbox"
              style={{
                width: '20px',
                height: '20px',
                cursor: 'pointer',
                marginTop: 2,
              }}
              label=""
              onChange={(e) => {
                if (e.target.checked) {
                  const newBatchData = [...postBatchData, {
                    _id: post._id,
                    uniqueContentDraftId: post?.uniqueContentDraftId || '',
                    posted: post?.posted || false,
                  }];
                  dispatch(setPostBatchData(newBatchData));
                } else {
                  const newBatchData = postBatchData.filter((item) => item._id !== post._id);
                  dispatch(setPostBatchData(newBatchData));
                }
              }}
              ref={checkboxRef}
            />
            <FeedDropdown
              enableView={enableView}
              newUpdateHandler={newUpdateHandler}
              recurring={recurring}
              openDraft={openDraft}
              {...rest}
            />
          </Flex>
        </Col>
      </Row>
    </Card.Header>
  );
};

FeedCardHeader.propTypes = {
  status: PropTypes.string,
  avatarSrc: PropTypes.string,
  time: PropTypes.string,
  name: PropTypes.string,
  share: PropTypes.string,
  location: PropTypes.string,
  privacy: PropTypes.string
};

export default FeedCardHeader;
