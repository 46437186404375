import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Nav } from "react-bootstrap";
import ChatThread from "./ConversationView";
import SimpleBarReact from "simplebar-react";
import AddMoreAccountsToChat from "./AddMoreAccountsToChat";
import classNames from "classnames";
import AppContext, { ChatContext } from "context/Context";
import { getCurrentUser } from "redux/slices/messagingSlice";
import { useSelector } from "react-redux";
// import { getColor } from 'helpers/utils';

const ChatSidebar = ({
  setSelectedChatRoomId,
  hideSidebar,
  connectAccounts,
}) => {
  const { currentChats, setActiveRoomId } = useContext(ChatContext);
  const { config } = useContext(AppContext);
  const [showUnread, setShowUnread] = useState(false);
  const messages = showUnread
    ? currentChats.filter(
        (message) =>
          message.user_id !== message.last_message_sender &&
          !message.last_message_read
      )
    : currentChats;
  //? Function for showing only unread messages
  const showUnreadMessages = () => {
    setShowUnread(true);
    setActiveRoomId(null);
  };

  //? Function for showing only all messages
  const showAllMessages = () => {
    setShowUnread(false);
    setActiveRoomId(null);
  };

  // Define a function to extract the timestamp of the latest message in a conversation
  const getLatestMessageTimestamp = (conversation) => {
    const latestMessage =
      conversation?.messages[conversation?.messages?.length - 1];
    return latestMessage ? latestMessage.time : 0; // Return the timestamp from the latest message
  };

  const sortedConversations = [...messages].sort((a, b) => {
    const timestampA = getLatestMessageTimestamp(a);
    const timestampB = getLatestMessageTimestamp(b);
    return timestampB - timestampA; // Sort in descending order (latest first)
  });

  const selectedColorScheme = config.isDark ? "#0b1727" : "#ffffff";
  const shadowColorScheme = config.isDark ? "transparent" : "#efefef";

  return (
    <div
      style={{ backgroundColor: `${selectedColorScheme}` }}
      className={classNames("chat-sidebar", { "start-0": hideSidebar })}
    >
      <div
        style={{
          position: "sticky",
          zIndex: "5",
          top: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "2rem",
          padding: ".5rem",
        }}
      >
        <button
          onClick={showAllMessages}
          style={{
            fontSize: "12px",
            color: `${
              config.isDark ? (!showUnread ? "#3498db" : "#ffffff") : ""
            }`,
            fontWeight: "500",
            background: `${shadowColorScheme}`,
            whiteSpace: "nowrap",
            transition: "box-shadow 0.2s ease-out",
            border: "none",
            boxShadow: `${
              !showUnread
                ? "none"
                : `${" -5px -5px 10px  rgba(0, 0, 0, 0.2), /* Top and left inner shadow */0 0 5px rgba(0, 0, 0, 0.2),0 0 5px rgba(0, 0, 0, 0.2),0 0 5px rgba(0, 0, 0, 0.4),0 0 0px rgba(0, 0, 0, 0.2)"}`
            }`,
            width: "fit-content",
            height: "max-content",
            borderRadius: "5px",
          }}
        >{`All message${sortedConversations?.length > 1 ? "s" : ""}`}</button>
        <button
          onClick={showUnreadMessages}
          style={{
            fontSize: "12px",
            color: `${
              config.isDark ? (showUnread ? "#3498db" : "#ffffff") : ""
            }`,
            fontWeight: "500",
            background: `${shadowColorScheme}`,
            transition: "box-shadow 0.2s ease-out",
            whiteSpace: "nowrap",
            width: "fit-content",
            border: "none",
            boxShadow: `${
              showUnread
                ? "none"
                : " 5px -5px 10px rgba(0, 0, 0, 0.1), /* Top and right inner shadow */0 0 5px rgba(0, 0, 0, 0.2),0 0 5px rgba(0, 0, 0, 0.3),0 0 5px rgba(0, 0, 0, 0.4),0 0 2px rgba(0, 0, 0, 0.5) "
            }`,
            height: "max-content",
            borderRadius: "5px",
          }}
        >
          {`Unread message${showUnread && messages?.length > 1 ? "s" : ""}`}
        </button>{" "}
      </div>
      <div className="contacts-list">
        <SimpleBarReact style={{ height: "100%", minWidth: "65px" }}>
          <Nav className="border-0">
            {messages.length !== 0 ? (
              sortedConversations?.map((thread, index) => (
                <ChatThread
                  showUnread={showUnread}
                  onSelect={setSelectedChatRoomId}
                  thread={thread}
                  index={index}
                  key={thread?.room_id}
                />
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  minHeight: "50vh",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: ".5rem",
                }}
              >
                <p style={{ width: "100%", textAlign: "center" }}>
                  No new message
                </p>
              </div>
            )}
          </Nav>
        </SimpleBarReact>
      </div>
      {/* <AddMoreAccountsToChat connectAccounts={connectAccounts} /> */}
    </div>
  );
};

ChatSidebar.propTypes = {
  hideSidebar: PropTypes.bool,
};

export default ChatSidebar;