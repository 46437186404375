import React, { useEffect, useState } from "react";
import WorkspaceBanner from "../WorkspaceBanner";
import coverSrc from "assets/img/icons/spot-illustrations/authentication-corner.png";
import { Col, Row, Spinner } from "react-bootstrap";
import WorkspaceSettings from "./WorkspaceSettings";
import DangerZone from "./DangerZone";
import ApprovalSettings from "./ApprovalSettings";
import {
  PERMISSION_CREATE_NEW_WORKSPACES,
  PERMISSION_DELETE_WORKSPACE,
  PERMISSION_UPDATE_WORKSPACE_APPROVAL_WORKFLOW,
} from "../../../../constants";
import PostsSettings from "./PostsSettings";
import TimeZoneSettings from "./TimeZoneSettings";
import DelegatedAdditionalWorkspaceSettings from "./DelegatedAdditionalWorkspaceSettings";
import HomePanelSettings from "./HomePanelsSettings";
import PostEmailNotificationsSettings from "./PostEmailNotificationsSettings";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveWorkSpace,
  getRightBarSectionsPositions,
  setSearchHint,
} from "redux/slices/workspaceslice";
import { getLoggedInUser } from "redux/slices/user_slice";
import { userCanPerformActionInWorkspace } from "utils/workspace_utils";
import { isLoggedInUserOwnerOfWorkspace } from "utils/user_utils";
import {
  canRefreshPopularDays,
  setRefreshPopularDays,
} from "redux/slices/postslice";
import APIService from "http/api_service";
import Events from "../events/Events";
import Flex from "components/common/Flex";
import ContentPlannerCalendarSettings from "./ContentPlannerCalendarSettings";
import LanguageAndRegion from "./LanguageAndRegion";

const Settings = () => {
  const loggedInUser = useSelector(getLoggedInUser);
  const dispatch = useDispatch();
  const workSpace = useSelector(getActiveWorkSpace);
  const loadingEvents = useSelector(canRefreshPopularDays);
  const [workSpaceOwnerDetails, setWorkSpaceOwnerDetails] = useState();
  const [visibilityOptions, setVisibilityOptions] = useState({});
  const [events, setEvents] = useState([]);
  const panelPositions = useSelector(getRightBarSectionsPositions);
  const [children, setChildren] = useState({});

  const componentEnabled = (name) => {
    let panelChoices = workSpace?.panel_visibility_choices ?? {};
    let userPanelChoices = panelChoices[loggedInUser._id] ?? { [name]: true };
    if (userPanelChoices.hasOwnProperty(name)) {
      return userPanelChoices[name];
    } else {
      return true;
    }
  };

  const updateVisibilityOptions = (option) => {
    setVisibilityOptions((prevOptions) => {
      prevOptions = prevOptions ?? {};
      prevOptions[option] = true;
      return { ...prevOptions };
    });
  };

  const fetchUpcomingAndPopularDays = () => {
    APIService.fetchUpcomingPopularEvents(workSpace._id, (response, error) => {
      dispatch(setRefreshPopularDays(false));
      updateVisibilityOptions("EVENTS");
      if (response) {
        let { data } = response;
        if (data) {
          data = data.filter((event) => new Date(event.start) >= new Date());
          data = data.sort((a, b) => new Date(a.start) - new Date(b.start));
        }
        setEvents(data);
        setChildren((prevChildren) => {
          prevChildren = prevChildren ?? {};
          prevChildren[panelPositions["EVENTS"]] = (
            <Events cardTitle="Events" events={data} />
          );
          return { ...prevChildren };
        });
      }
    });
  };

  useEffect(() => {
    if (workSpace) {
      if (componentEnabled("EVENTS")) {
        fetchUpcomingAndPopularDays();
      } else {
        updateVisibilityOptions("EVENTS");
      }
    }
  }, [loadingEvents]);

  useEffect(() => {
    dispatch(setSearchHint("Search..."));
  }, []);

  const stateHandler = (ownerDetails) => {
    setWorkSpaceOwnerDetails(ownerDetails);
  };

  return (
    <>
      <WorkspaceBanner>
        <WorkspaceBanner.Header coverSrc={coverSrc} />
      </WorkspaceBanner>
      <Row className="g-3">
        <Col lg={8} style={{width: "50%"}}>
          <WorkspaceSettings />
          <br/>
          {/* {!isLoggedInUserOwnerOfWorkspace(loggedInUser, workSpace) &&
            userCanPerformActionInWorkspace(
              workSpace,
              PERMISSION_CREATE_NEW_WORKSPACES
            ) && (
              <>
                <DelegatedAdditionalWorkspaceSettings
                  stateHandler={stateHandler}
                />
              </>
            )} */}
          {userCanPerformActionInWorkspace(
            workSpace,
            PERMISSION_UPDATE_WORKSPACE_APPROVAL_WORKFLOW
          ) && (
            <React.Fragment>
              <ApprovalSettings
                userRoleInWorkspace={workSpace.role.toUpperCase()}
              />
              {workSpace.owner_id === loggedInUser._id && (
                <DangerZone workSpaceOwnerDetails={workSpaceOwnerDetails} />
              )}
            </React.Fragment>
          )}
          
        </Col>
        <Col lg={8} style={{width: "50%"}}>
          {userCanPerformActionInWorkspace(
            workSpace,
            PERMISSION_UPDATE_WORKSPACE_APPROVAL_WORKFLOW
          ) && (
            <React.Fragment>
              <PostsSettings />
              <LanguageAndRegion />
              <TimeZoneSettings />
              <ContentPlannerCalendarSettings />
            </React.Fragment>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Settings;
