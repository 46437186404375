import { useState } from 'react';
import { toast } from 'react-toastify';
import APIService from '../../../http/api_service';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../../constants';
import { Button, Form } from 'react-bootstrap';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { useSelector } from 'react-redux';

export default function MemberRoleUpdateView(props) {

    const roles = [
        { label: 'Owner', value: 'Owner' },
        { label: 'Admin', value: 'Admin' },
        { label: 'Approver', value: 'Approver' },
        { label: 'Reviewer', value: 'Reviewer' },
        { label: 'Editor', value: 'Editor' },
    ];
    const { memberEmail, excludedRole = '', memberId, updateDoneCallback } = props;
    const usableRoles = () => {
        return roles.filter(role => role.value.toLowerCase() !== excludedRole.toLowerCase());
    }
    const validRoles = usableRoles();
    const [formData, setFormData] = useState({
        email: '',
        role: validRoles[0].value
    });
    const defaultBtnMessage = "Update";
    const [buttonMessage, setButtonMessage] = useState(defaultBtnMessage);
    const cancelHandler = props.cancelHandler;
    const [updating, setUpdating] = useState(false);
    const navigate = useNavigate();
    const workSpace = useSelector(getActiveWorkSpace);

    const handleSubmit = (e) => {
        e.preventDefault();
        const memberRole = formData.role;
        if (!memberRole) {
            toast.error('Please select a new role for this member', { theme: 'colored' });
            return;
        }
        let rolesArray = Object.values(roles).map((entry) => entry.value.toLowerCase());
        if (!rolesArray.includes(memberRole.toLowerCase())) {
            toast.error('Please select a role for this member', { theme: 'colored' });
            return;
        }
        if (memberRole.toLowerCase().includes('role')) {
            toast.error('Please select a role for this member', { theme: 'colored' });
            return;
        }
        setButtonMessage("Please wait...");
        setUpdating(true);
        APIService.updateMemberRole(workSpace['_id'], memberId, memberRole, (response, error) => {
            setButtonMessage(defaultBtnMessage);
            setUpdating(false);
            if (error) {
                if (error.toLowerCase().includes('upgrade')) {
                    navigate(`${RoutePaths.ACCOUNT}?t=billing&msg=${encodeURIComponent(error.message)}`);
                } else {
                    toast.error(error, { theme: 'colored' });
                }
                return;
            }
            let { message } = response;
            toast.success(message, { theme: "colored" });
            if (updateDoneCallback) {
                updateDoneCallback(response.data);
            }
            cancelHandler(false);
        });
    }

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div style={{
            paddingLeft: 0,
            paddingRight: 0
        }}>
            <h5 className='my-3' style={{ textAlign: 'center' }}>Update Member Role</h5>
            <Form onSubmit={handleSubmit}>
                <Form.Group className='mb-3'>
                    <Form.Label>Select a new Role for <b>{memberEmail}</b></Form.Label>
                    <Form.Select
                        name='role'
                        value={formData.role}
                        onChange={handleFieldChange}
                        required
                    >
                        {
                            validRoles.map((role) => {
                                return <option value={role.value}>{role.label}</option>
                            })
                        }
                    </Form.Select>
                </Form.Group>
                <div
                    className='text-end mb-3'
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: 10
                    }}>
                    <Button
                        variant="light"
                        onClick={() => {
                            if (cancelHandler) {
                                cancelHandler(false);
                            }
                        }}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        isWorking={updating}
                        variant={'primary'}
                        disabled={updating}>
                        {buttonMessage}
                    </Button>
                </div>
            </Form>
        </div>
    );
}