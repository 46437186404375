import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: [],
}

export const postMediaSlice = createSlice({
    name: 'post_media',
    initialState,
    reducers: {
        setPublishablePostMedia: (state, action) => {
            state.value = action.payload;
        }
    }
});

export let getPublishablePostMedia = (state) => state.post_media.value;
export let {
    setPublishablePostMedia
} = postMediaSlice.actions;
export default postMediaSlice.reducer;