import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { Link, useNavigate } from 'react-router-dom';
import Calendar from 'components/common/Calendar';
import { useDispatch } from 'react-redux';
import {
  setComposerFocused, setDisplayGetStarted,
  setOpenComposer, setRefreshPopularDays, setUpdatableEventDay
} from 'redux/slices/postslice';
import ContentTips from 'utils/content_tips';
import IconButton from 'components/common/IconButton';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfirmModal from 'components/common/ConfirmModal';
import { toast } from 'react-toastify';
import APIService from 'http/api_service';
import { getActiveWorkSpace, setOpenCustomDayCreator } from 'redux/slices/workspaceslice';
import { useSelector } from 'react-redux';
import { setPublishablePostTextBody } from 'redux/slices/post_text_body_slice';
import { RoutePaths } from 'constants';

const Event = ({ details, isLast }) => {
  const {
    name,
    start,
    updatableEvent,
    location,
    userCountryCode,
  } = details;

  const dispatch = useDispatch();
  const [openEventDeletePrompt, setOpenEventDeletePrompt] = useState(false);
  const workSpace = useSelector(getActiveWorkSpace);
  const navigate = useNavigate();

  const getEventMonthAndDay = () => {
    let date = new Date(start);
    let currentYear = new Date().getFullYear();
    let options = {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    };
    let locale = userCountryCode ? `en-${userCountryCode}` : 'en-US';
    let formattedDate = date.toLocaleDateString(locale, options);
    let parsedDate = new Date(formattedDate);
    let month = parsedDate.toLocaleDateString('default', { month: 'short' });
    let day = parsedDate.getDate();
    let year = currentYear === parsedDate.getFullYear() ? undefined : parsedDate.getFullYear();
    let data = { day, month, year };
    return data;
  }

  const deleteEvent = () => {
    toast.info("Deleting Event...", { theme: 'colored' });
    APIService.deleteCustomEventDay(workSpace._id, updatableEvent._id, (response, error) => {
      if (error) {
        toast.error(error, { theme: 'colored' });
        return;
      }
      let { message } = response;
      toast.success(message, { theme: 'colored' });
      dispatch(setRefreshPopularDays(true));
    });
  }

  const EventOptionsDropdown = () => {
    return (
      <Dropdown className="font-sans-serif btn-reveal-trigger">
        <Dropdown.Toggle variant="link" size="sm" className="px-1 text-600">
          <FontAwesomeIcon icon="ellipsis-h" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Item
            onClick={() => {
              dispatch(setUpdatableEventDay(updatableEvent));
              dispatch(setOpenCustomDayCreator(true));
            }}>Edit</Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setOpenEventDeletePrompt(true);
            }}>Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <Flex>
      <Calendar {...getEventMonthAndDay()} />
      <div className="flex-1 position-relative ps-2">
        <h6 className="fs--1 mb-0">
          <Flex className="justify-content-between" wrap={'wrap'}>
            <Link to="#" className='flex-1' style={{ textDecoration: 'none' }}>
              <span className="me-1">{name}</span>
            </Link>
            {
              updatableEvent &&
              <EventOptionsDropdown />
            }
          </Flex>
        </h6>
        <p className='mb-1 fs--1'>{location}</p>
        <p className="mb-1">
          <IconButton
            className="mt-1 py-0 border"
            variant="light"
            size="sm"
            icon={faComment}
            transform="shrink-5"
            onClick={() => {
              navigate(RoutePaths.POSTS);
              dispatch(setPublishablePostTextBody(ContentTips.generateContentFromEventDay(name)))
              dispatch(setComposerFocused(true));
              dispatch(setOpenComposer(true));
              dispatch(setDisplayGetStarted(false));
              document.getElementById("draft-heads-up")?.scrollIntoView();
            }}
          >
            <span className="fs--1">Make a Post</span>
          </IconButton>
        </p>
        {!isLast && <div className="border-dashed-bottom my-3"></div>}
      </div>
      <ConfirmModal
        open={openEventDeletePrompt}
        title={`Delete Event`}
        message={`Are you sure you want to delete the event <b>${name}</b>?<br/>There is no going back, so be certain`}
        onCancel={() => setOpenEventDeletePrompt(false)}
        onConfirm={() => {
          setOpenEventDeletePrompt(false);
          deleteEvent();
        }}
        cancelText={"NO"}
        confirmText={"DELETE"}
      />
    </Flex>
  );
};

Event.propTypes = {
  details: PropTypes.shape({
    calendar: PropTypes.shape(Calendar.propTypes),
    title: PropTypes.string.isRequired,
    organizer: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    place: PropTypes.string.isRequired,
    location: PropTypes.string,
    duration: PropTypes.string,
    interested: PropTypes.string,
    badge: PropTypes.object
  }),
  isLast: PropTypes.bool
};

export default Event;
