import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Flex from "components/common/Flex";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getDatePeriod } from "redux/slices/analytics_slice";
import PageInsightsChart from "./PageInsightsChart";
import FalconCardHeader from "components/common/FalconCardHeader";

const PageInsights = (props) => {
  const { title, loading, insightData } = props;
  const datePeriod = useSelector(getDatePeriod);
  const [month, setMonth] = useState(0);
  const [isNew, setIsNew] = useState(true);
  const chartRef = useRef(null);
  const pageInsightErrorMsg = `No data to display`;

  const handleLegend = (seriesName) => {
    seriesName === "New" && setIsNew(!isNew);
    chartRef.current.getEchartsInstance().dispatchAction({
      type: "legendToggleSelect",
      name: seriesName,
    });
  };

  useEffect(() => {
    const getMonth = moment(datePeriod.since).format("MM");
    setMonth(getMonth);
  }, [datePeriod, insightData]);

  return (
    <Card className="h-100 mb-4">
      <FalconCardHeader
        title={title}
        titleTag="h6"
        className="py-2"
        light
      // endEl={
      //   <ReportExportDropdown
      //     reportData={insightData}
      //     reportTitle={title}
      //     ref={containerRef}
      //   />
      // }
      />

      {loading && (
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          className={"vh-50"}
        >
          <Spinner animation="border" size={40} />
        </Flex>
      )}

      <div>
        {!loading && insightData && (
          <Card.Body className="pb-0" style={{ backgroundColor: "#fff" }}>
            <PageInsightsChart
              ref={chartRef}
              dateDuration={datePeriod}
              newData={insightData?.map((item) => item)}
              month={month}
              errorMessage={pageInsightErrorMsg}
            />
          </Card.Body>
        )}
      </div>
    </Card>
  );
};

export default PageInsights;
