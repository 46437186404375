import APIService from "http/api_service";
import React, { useState } from "react";
import {
    ListGroup, Form,
    Button, Alert,
    ProgressBar
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useOrganizationStore, { defaultOrganization } from "state/organization_store";
import { useSelector, useDispatch } from 'react-redux';
import { getActiveWorkSpace, setActiveWorkspace } from "redux/slices/workspaceslice";

export default function OrganizationsToMoveSelectedWorkspacesTo({ workspaces, incomingOrganizations, onWorkspacesMoved }) {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);
    const allWorkspaces = useOrganizationStore(state => state.allWorkspaces);
    const setAllWorkspaces = useOrganizationStore(state => state.setAllWorkspaces);
    const organizationsFromStore = useOrganizationStore(state => state.organizationList);
    const setActiveOrganization = useOrganizationStore(state => state.setActiveOrganization);
    const activeWorkspace = useSelector(getActiveWorkSpace);

    const organizationsMap = new Map();
    if ((organizationsFromStore ?? []).length == 0) {
        organizationsMap.set(defaultOrganization._id, defaultOrganization);
    }

    organizationsFromStore.filter(org => org).forEach(org => organizationsMap.set(org._id, org));
    const organizations = incomingOrganizations ?? Array.from(organizationsMap.values()).filter(org => !org.new && org._id !== defaultOrganization._id);

    const [moving, setMoving] = useState(false);

    const handleSelectOrganization = (orgId) => {
        setSelectedOrganizationId(orgId);
    }

    const handleSubmit = async () => {
        if (selectedOrganizationId) {
            setMoving(true);
            APIService.moveWorkspacesToOrganization({ workspaces: workspaces.map(x => x._id), organization: selectedOrganizationId }, (response, error) => {
                setMoving(false);
                if (error) {
                    toast.error(error, { theme: 'colored' });
                    return;
                }
                let { data } = response;
                let updatedWorkspaces = data;
                let allWorkspacesCopy = [...allWorkspaces];
                for (let i = 0; i < allWorkspacesCopy.length; i++) {
                    let workspace = allWorkspacesCopy[i];
                    let updatedWorkspace = updatedWorkspaces.find(w => w._id === workspace._id);
                    if (updatedWorkspace) {
                        allWorkspacesCopy[i] = updatedWorkspace;
                    }
                }
                setAllWorkspaces(allWorkspacesCopy);
                let updatedActiveWorkspace = updatedWorkspaces.find(w => w._id === activeWorkspace?._id);
                if (updatedActiveWorkspace) {
                    dispatch(setActiveWorkspace({ ...activeWorkspace, organization: updatedActiveWorkspace.organization }));
                    setActiveOrganization(updatedActiveWorkspace.organization);
                }
                if (onWorkspacesMoved) {
                    onWorkspacesMoved(updatedWorkspaces);
                }
            });
        }
    }

    return (
        <>
            {organizations.length > 0 ? (
                <>
                    <ListGroup>
                        {organizations.map(org => (
                            <ListGroup.Item
                                key={org._id}
                                active={selectedOrganizationId === org._id}
                                onClick={() => handleSelectOrganization(org._id)}
                                action
                            >
                                {org.name}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                    {
                        moving &&
                        <ProgressBar label={t('moving_workspaces')} animated now={100} className="mt-3" />
                    }
                    <Form.Group className="mb-3 mt-4 text-end">
                        <Button
                            variant="falcon-primary"
                            onClick={handleSubmit}
                            disabled={!selectedOrganizationId || moving}
                        >
                            {t('move')}
                        </Button>
                    </Form.Group>
                </>
            ) : (
                <Alert variant="info" className="mt-4">
                    {t('no_other_organizations')}
                </Alert>
            )}
        </>
    );
}
