import SubmitButton from "components/common/SubmitButton";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import WorkspaceLogoPicker from "./workspace_logo_picker";
import Flex from "components/common/Flex";
import StringUtils from "utils/string";
import { getLoggedInUser } from "redux/slices/user_slice";
import { useState } from "react";
import useOrganizationStore from "state/organization_store";

export default function NewWorkspaceInOrganization({ attributes = {}, handleOnClose }) {

    const { t } = useTranslation();
    const workspaces = useOrganizationStore(state => state.allWorkspaces);
    const workspace = useOrganizationStore(state => state.workspaceForOrganizationBeingCreated);
    const organization = useOrganizationStore(state => state.organizationBeingCreated);
    const setWorkspaceForOrganizationBeingCreated = useOrganizationStore(state => state.setWorkspaceForOrganizationBeingCreated);
    const [form, setForm] = useState({ ...(workspace ?? {}) });

    const authenticatedUser = useSelector(getLoggedInUser);
    const [openWorkspaceCreationPanel, setOpenWorkspaceCreationPanel] = useState(workspace);
    const [openWorkspaceSelector, setOpenWorkspaceSelector] = useState(false);
    const [showNextButton, setShowNextButton] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (Object.entries(form).length === 0) {
            return;
        }
        form.owner = authenticatedUser._id;
        form._id = StringUtils.generateRandomString();
        form.attributes = attributes;
        form.avatar = workspace?.avatar
        setWorkspaceForOrganizationBeingCreated(form);
        if (handleOnClose) {
            handleOnClose();
        }
    }

    const workspaceCreationPrompt = () => {
        if ((workspaces ?? []).length === 0) {
            return `${t('you_have_no_workspace_set_up', { organization_name: `<b>${organization?.name}</b>` })}`;
        }
        let organizationWorkspaces = (workspaces ?? []).filter(w => w.organization?._id === organization?._id);
        if ((organizationWorkspaces ?? []).length === 0) {
            return `${t('you_have_no_workspace_set_up', { organization_name: `<b>${organization?.name}</b>` })}`;
        }
        return `${t('create_workspace_within_org', { organization_name: `<b>${organization?.name}</b>` })}`;
    }

    const openWorkspaceSelectorOrCreator = () => {
        if (workspaces.length > 0) {
            if (!workspace) {
                setWorkspaceForOrganizationBeingCreated({ ...workspaces[0], existing: true });
            }
            setOpenWorkspaceSelector(true);
            setShowNextButton(true);
        } else {
            setOpenWorkspaceCreationPanel(true);
        }
    }

    const handleSelect = (event) => {
        const worskspaceId = event.target.value;
        if (worskspaceId === 'createNew') {
            setOpenWorkspaceSelector(false);
            setOpenWorkspaceCreationPanel(true);
            return;
        }
        const selectedWorkspace = workspaces.find(w => w._id === worskspaceId);
        setWorkspaceForOrganizationBeingCreated({ ...selectedWorkspace, workspace_name: selectedWorkspace.name, existing: true });
        setShowNextButton(true);
    }

    return (
        <>
            {
                !openWorkspaceCreationPanel && !openWorkspaceSelector &&
                <Flex direction={'column'} className={'mb-2'}>
                    <span
                        className="text-center py-3"
                        dangerouslySetInnerHTML={{
                            __html: workspaceCreationPrompt()
                        }}
                    />
                    <Button
                        onClick={() => {
                            openWorkspaceSelectorOrCreator();
                        }}
                        size="sm"
                        className="rounded-pill">{t('create')}
                    </Button>
                </Flex>
            }
            {
                openWorkspaceSelector &&
                <Flex direction={'column'}>
                    <Form.Group>
                        <Form.Label>{t('move_existing_workspace_or_create_new_one')}</Form.Label>
                        <Form.Select
                            value={workspace?._id}
                            onChange={handleSelect}
                            placeholder={t('select')}
                            variant="falcon-default"
                        >
                            <option value="" disabled>{t('select_workspace')}</option>
                            {workspaces.map((workspace) => (
                                <option
                                    key={workspace._id}
                                    value={workspace._id}
                                >
                                    {workspace.name}
                                </option>
                            ))}
                            <option value="createNew">{`+ ${t('create_new_workspace')}`}</option>
                        </Form.Select>
                    </Form.Group>
                    {
                        showNextButton &&
                        <Button
                            className="rounded-pill mt-2"
                            variant="primary"
                            size="sm"
                            onClick={() => {
                                if (handleOnClose) {
                                    if (!workspace) {
                                        setWorkspaceForOrganizationBeingCreated({ ...workspaces[0], existing: true });
                                    }
                                    handleOnClose();
                                }
                            }}>
                            {t('next')}
                        </Button>
                    }
                </Flex>
            }
            {
                openWorkspaceCreationPanel &&
                <Form onSubmit={handleSubmit}>
                    <Flex direction={'column'} className={'gap-3'}>
                        <Form.Group controlId="workspace_name">
                            <Form.Label className='text-capitalize'>{t('name')}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('enter_name_of_new_workspace')}
                                name="workspace_name"
                                value={form.workspace_name}
                                onChange={(e) => {
                                    let name = e.target.value;
                                    let wkSpace = { ...(workspace ?? {}) };
                                    wkSpace.name = name;
                                    setForm({
                                        ...form,
                                        existing: false,
                                        workspace_name: name
                                    });
                                    setWorkspaceForOrganizationBeingCreated({ ...wkSpace, workspace_name: name });
                                }}
                            />
                        </Form.Group>
                        <WorkspaceLogoPicker />
                        <Flex alignItems={'center'} className={'gap-2'}>
                            {
                                workspaces.length > 0 &&
                                <Button
                                    className="rounded-pill"
                                    variant="falcon-default"
                                    size="sm"
                                    onClick={() => {
                                        if (!workspace) {
                                            setWorkspaceForOrganizationBeingCreated({ ...workspaces[0], existing: true });
                                        }
                                        setShowNextButton(true);
                                        setOpenWorkspaceCreationPanel(false);
                                        setOpenWorkspaceSelector(true);
                                    }}>{t('use_existing')}
                                </Button>
                            }
                            <SubmitButton size="sm" className={'rounded-pill'} label={t('next')} processing={false} />
                        </Flex>
                    </Flex>
                </Form>
            }
        </>
    );
}