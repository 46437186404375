import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConnectedIcon from 'assets/images/connected_badge.svg';
import AppContext from 'context/Context';
import React, {
    useContext,
    useEffect,
    useState
} from 'react';
import { Badge, Card, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getActiveWorkSpace, getSearchTerm, setSearchHint } from 'redux/slices/workspaceslice';
import {
    PERMISSION_VIEW_AND_MANAGE_PLATFORMS, PlatformIdentifier, RoutePaths
} from '../../../constants';
import APIService from '../../../http/api_service';
import { getChangedPlatform } from '../../../redux/slices/platformSlice';
import { platforms } from './index';
import { isFreeWorkspace, userCanPerformActionInWorkspace } from 'utils/workspace_utils';
import { getLoggedInUser } from 'redux/slices/user_slice';
import { canLoggedInUserBeDemo } from 'utils/user_utils';

export default function PlatformsGrid(props) {

    const workSpace = useSelector(getActiveWorkSpace);
    const changedPlatform = useSelector(getChangedPlatform);
    const [socialPlatforms, setSocialPlatforms] = useState(platforms);
    const [originalSocialPlatforms, setOriginalSocialPlatforms] = useState(platforms);
    const [platformsFetched, setPlatformsFetched] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const { config } = useContext(AppContext);
    const dispatch = useDispatch();
    const searchTerm = useSelector(getSearchTerm);
    const loggedInUser = useSelector(getLoggedInUser);
    const isDemoAccount = canLoggedInUserBeDemo(loggedInUser);


    useEffect(() => {
        dispatch(setSearchHint("Search platforms..."));
    }, []);

    useEffect(() => {
        setSocialPlatforms((p) => {
            return originalSocialPlatforms.filter((platform) => platform.name.toLowerCase().includes(searchTerm.toLowerCase()));
        });
    }, [searchTerm]);

    const getConnectionExpiryTime = (last_connected_date, is_platform_connection_active, expireWindow) => {
        let staticExpireDate = (new Date() - new Date(last_connected_date));
        staticExpireDate = staticExpireDate ? expireWindow - parseInt((staticExpireDate / (1000 * 60 * 60 * 24))) : 0;
        if (!last_connected_date) {
            return {
                expired: true,
                dateValid: false,
                daysLeft: 0
            };
        }
        if (is_platform_connection_active) {
            return {
                expired: false,
                dateValid: true,
                daysLeft: staticExpireDate
            };
        }
        const today = new Date();
        if (today > new Date(last_connected_date)) {
            return {
                expired: true,
                dateValid: true,
                daysLeft: 0
            };
        }
        if (today < new Date(last_connected_date)) {
            return {
                expired: !is_platform_connection_active,
                dateValid: true,
                daysLeft: staticExpireDate
            };
        }
    }

    const stamp_PRO_OnPlatform = (identifier) => {
        return false // removing pro status from free plan users for now...
        let targets = [PlatformIdentifier.LINKED_IN, PlatformIdentifier.GOOGLE_MY_BUSINESS, PlatformIdentifier.TWITTER, PlatformIdentifier.TELEGRAM, PlatformIdentifier.YOUTUBE, PlatformIdentifier.WORDPRESS, PlatformIdentifier.RSS_FEED, PlatformIdentifier.TIKTOK]
        const targetFound = targets?.find(target => target === identifier);
        if (isFreeWorkspace(workSpace) && targetFound) return true;
        return false
    }

    const PlatformGridItem = React.forwardRef(({ e, index, style }, ref) => {

        function isPlatformAccessible(platform) {
            if (workSpace !== null && platform.pro) {
                if (isFreeWorkspace(workSpace)) {
                    return false;
                }
            }
            return true;
        }

        if (e.disabled) {
            return <></>
        }
        const dayToExpire = getConnectionExpiryTime(e?.last_possible_connection_date, e.is_platform_connection_active, e?.defaultExpireWindow);
        return (
            <Link
                style={{
                    position: 'relative',
                    display: 'flex',
                    color: config.isDark ? 'hsl(0deg 0% 100% / 55%)' : '#5e6e82',
                    textDecoration: 'none',
                    pointerEvents: userCanPerformActionInWorkspace(workSpace, PERMISSION_VIEW_AND_MANAGE_PLATFORMS) ? 'all' : 'none'
                }}
                to={stamp_PRO_OnPlatform(e.identifier) ? RoutePaths.BILLING_STRIPE : `./${e.identifier}`}>
                <Card
                    key={index}
                    ref={ref}
                    onClick={() => {
                        if (stamp_PRO_OnPlatform(e.identifier)) return null
                        setSocialPlatforms(originalSocialPlatforms);
                    }}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'relative',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        ...style
                    }}>
                    {
                        e.added &&
                        <img
                            src={ConnectedIcon}
                            alt=''
                            style={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                                width: 15,
                                height: 15
                            }}
                        />
                    }
                    {/* {!isPlatformAccessible(e) &&
                        <Badge pill bg="200" className="text-primary fs--2" style={{
                            position: 'absolute',
                            top: 10,
                            left: 10,
                        }}>
                            <FontAwesomeIcon icon="crown" className="me-1 mb-0" />
                            PRO
                        </Badge>
                    } */}
                    {
                        e.svg ? <img
                            src={e.svg}
                            alt={e.name}
                            style={{
                                width: 50,
                                height: 50
                            }} /> :
                            <FontAwesomeIcon
                                icon={e.icon}
                                style={{
                                    color: e.color,
                                    fontSize: 50,
                                }}
                            />
                    }
                    <span
                        style={{
                            fontSize: 16,
                            fontWeight: 600,
                            paddingTop: 10
                        }}>
                        {e.name}
                    </span>
                    {
                        e?.added && (e?.identifier === PlatformIdentifier.LINKED_IN || e?.identifier === PlatformIdentifier.FACEBOOK || e?.identifier === PlatformIdentifier.TIKTOK) &&
                        <span
                            style={{
                                fontSize: 11,
                                textAlign: 'center',
                                fontWeight: 600,
                                paddingTop: 10,
                                color: `${dayToExpire?.expired ? '#ff6f44' : '#318d66'}`
                            }}>
                            {
                                dayToExpire?.expired ? `Connection expired` : `Connection expires in: ${dayToExpire?.daysLeft} days`
                            }
                        </span>
                    }
                    {
                        stamp_PRO_OnPlatform(e?.identifier) &&
                        <span
                            style={{
                                fontSize: 14,
                                textAlign: 'center',
                                fontWeight: 600,
                                paddingTop: 10,
                                // color: `${dayToExpire?.expired ? '#ff6f44' : '#318d66'}`
                            }}>
                            <FontAwesomeIcon
                                style={{
                                    marginLeft: 5
                                }}
                                icon="crown"
                                color='orange'
                            /> PRO
                        </span>
                    }


                </Card>
            </Link>
        );
    });

    useEffect(() => {
        if (changedPlatform) {
            let changedPlatformIdentifier = changedPlatform['identifier'];
            let added = changedPlatform['added'];
            if (changedPlatformIdentifier) {
                setSocialPlatforms(p => {
                    let platform = p.find((x) => x.identifier === changedPlatformIdentifier);
                    if (platform) {
                        platform = { ...platform };
                        let indexOfPlatform = p.indexOf(platform);
                        let colorInInitialPlatform = platform['color'];
                        platform = changedPlatform['data'] ?? platform;
                        platform['added'] = added;
                        platform['color'] = colorInInitialPlatform;
                        p[indexOfPlatform] = platform;
                    }
                    setOriginalSocialPlatforms(p);
                    return p;
                });
            }
        }
    }, [changedPlatform]);

    const deleteCorruptedPlatforms = (corruptedPlatforms) => {
        if (corruptedPlatforms.length > 0) {
            APIService.removeCorruptedPlatforms(workSpace['_id'], corruptedPlatforms);
        }
    }

    useEffect(() => {
        if (!platformsFetched) {
            APIService.fetchWorkSpacePlatforms(workSpace['_id'], (response, error) => {
                if (error) {
                    toast.error(error, { theme: 'colored' });
                    return;
                }
                if (response) {
                    let platformsInWorkSpace = response['data'];
                    if (platformsInWorkSpace) {
                        platformsInWorkSpace = platformsInWorkSpace.filter(x => x.user_id);
                        let corruptedPlatforms = response['data'].filter(x => !x.user_id);
                        if (corruptedPlatforms) {
                            deleteCorruptedPlatforms(corruptedPlatforms);
                        }
                        setSocialPlatforms(p => {
                            p = p.map((existingEntry) => {
                                delete existingEntry.added;
                                return existingEntry;
                            });
                            for (let platformInWorkSpace of platformsInWorkSpace) {
                                let existingPlatform = p.find((x) => x['identifier'] === platformInWorkSpace['identifier']);
                                if (existingPlatform) {
                                    let indexOfExistingPlatform = p.indexOf(existingPlatform);
                                    let colorInInitialPlatform = existingPlatform['color'];
                                    existingPlatform['added'] = true;
                                    existingPlatform['last_possible_connection_date'] = platformInWorkSpace['updated_at'];
                                    existingPlatform['is_platform_connection_active'] = platformInWorkSpace['is_platform_connection_active'];
                                    existingPlatform['user_id'] = platformInWorkSpace['user_id'];
                                    existingPlatform['user_name'] = platformInWorkSpace['user_name'];
                                    existingPlatform['user_photo'] = platformInWorkSpace['user_photo'];
                                    existingPlatform['expires_at'] = platformInWorkSpace['expires_at'];
                                    existingPlatform['defaultExpireWindow'] = platformInWorkSpace['defaultExpireWindow'];
                                    existingPlatform['color'] = colorInInitialPlatform;
                                    p[indexOfExistingPlatform] = existingPlatform;
                                }
                            }
                            p.sort((a, b) => {
                                return b['added'] ?? false - a['added'] ?? false;
                            });
                            setOriginalSocialPlatforms(p);
                            return p;
                        });
                    }
                }
                setLoadingData(false);
                setPlatformsFetched(true);
            });
        }
    }, []);

    return (
        <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }}>
            {
                loadingData &&
                <div style={{
                    display: 'flex',
                    height: '20vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Spinner
                        animation={'border'}
                        size={40}
                    />
                </div>
            }
            {
                !loadingData &&
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: 20
                }}>
                    {
                        !userCanPerformActionInWorkspace(workSpace, PERMISSION_VIEW_AND_MANAGE_PLATFORMS) &&
                        <span>You do not have access to this page. Please contact the account owner to upgrade your role to Owner or Admin</span>
                    }
                    {userCanPerformActionInWorkspace(workSpace, PERMISSION_VIEW_AND_MANAGE_PLATFORMS) &&
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <div
                                style={{
                                    display: 'flex',
                                    gap: 20,
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                {
                                    socialPlatforms.map((e, index) => {
                                        return <div key={e.identifier}>
                                            <PlatformGridItem
                                                index={index}
                                                e={e}
                                                style={{
                                                    height: 199,
                                                    width: 199,
                                                    padding: 5,
                                                    borderRadius: 10
                                                }}
                                            />
                                        </div>
                                    })
                                }
                                {/* <div style={{
                                    marginTop: 20,
                                }}>
                                    <h6>Platform disconnection happens for any of the following reasons:</h6>
                                    <li style={{ fontSize: 11 }} >
                                        Not reconnecting once a month – some platforms are connected via API tokens which expire once in 60 days.
                                    </li>
                                    <li style={{ fontSize: 11 }} >
                                        Signing out of your socials.
                                    </li>
                                    <li style={{ fontSize: 11 }} >
                                        Not reconnecting after changing the password of your socials.
                                    </li>
                                    <li style={{ fontSize: 11 }} >
                                        Getting suspensions.
                                    </li>
                                    <li style={{ fontSize: 11 }} >
                                        Exceeding the daily post limit
                                    </li>
                                </div> */}
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
}