import Flex from 'components/common/Flex';
import React, { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import APIService from '../../../../http/api_service';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { useSelector } from 'react-redux';

export default function AddNewPopularDayLocation(props) {

    const {
        multiChoice = false,
        modalClose,
        doneButtonText = "ADD",
        dialogTitle = "Add Location(s)",
        selectionDoneHandler,
        maxHeight = 400
    } = props;
    const workSpace = useSelector(getActiveWorkSpace);
    const [locations, setLocations] = useState([]);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [originalLocations, setOriginalLocations] = useState([]);
    const [selections, setSelections] = useState([]);

    const PopularDayLocation = React.forwardRef((props, ref) => {
        const { location } = props;
        return (
            <Form.Check
                id={location.name}
                type='checkbox'
                checked={selections.includes(location.name)}
                label={location.name}
                onChange={(e) => {
                    if (!selections.includes(location.name)) {
                        if (multiChoice) {
                            selections.push(location.name);
                            setSelections([...selections]);
                        } else {
                            if (selections.length < 1) {
                                selections.push(location.name);
                                setSelections([...selections]);
                            }
                        }
                    } else {
                        let existingSelection = selections.find(x => x === location.name);
                        let indexOfSelection = selections.indexOf(existingSelection);
                        selections.splice(indexOfSelection, 1);
                        setSelections([...selections]);
                    }
                }}
            />
        );
    });

    useEffect(() => {
        if (loading) {
            APIService.fetchSupportedCountries(workSpace._id, (response, err) => {
                setLoading(false);
                if (err) {
                    setError(err);
                    return;
                }
                let { data } = response;
                data = data.sort((a, b) => {
                    if (a.name > b.name) {
                        return 1;
                    }
                    if (b.name > a.name) {
                        return -1;
                    }
                    return 0;
                })
                let refined = [];
                for (let dataEntry of data) {
                    let existingDataEntryWithName = refined.find(x => x.name === dataEntry.name);
                    if (!existingDataEntryWithName) {
                        refined.push(dataEntry);
                    }
                }
                let alreadyExistingLocations = workSpace.days_locations ?? [];
                if (alreadyExistingLocations) {
                    refined = refined.reduce((entries, x) => {
                        let alreadySelected = alreadyExistingLocations.find(existingLoc => existingLoc.name === x.name);
                        if (alreadySelected) {
                            if (alreadySelected.excluded) {
                                entries.push(x);
                            }
                        } else {
                            entries.push(x);
                        }
                        return entries;
                    }, []);
                }
                setLocations(refined);
                setOriginalLocations(refined);
            });
        }
    }, [loading]);

    const searchLocations = (e) => {
        let value = e.target.value;
        if (value) {
            let matches = originalLocations.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
            setLocations([...matches]);
        } else {
            setLocations([...originalLocations]);
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 10,
                gap: 10,
                marginTop: 15,
                justifyContent: 'center'
            }}>
            <h5 type={'h3'}>{dialogTitle}</h5>
            <Form.Control
                type='text'
                placeholder={"Search Locations"}
                onChange={searchLocations}
            />
            {
                loading && !error &&
                <Flex alignItems={'center'} justifyContent={'center'}>
                    <Flex alignItems={'center'} justifyContent={'center'}>
                        <Spinner animation='border' />
                    </Flex>
                </Flex>
            }
            {
                !loading && !error && locations &&
                <Scrollbars
                    style={{
                        height: maxHeight
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxHeight: maxHeight,
                            gap: 10
                        }}>
                        {
                            locations.map(x => <PopularDayLocation key={x.name} location={x} />)
                        }
                    </div>
                </Scrollbars>
            }
            <div
                style={{
                    display: 'flex',
                    gap: 10,
                    alignSelf: 'flex-end'
                }}>
                <Button
                    variant={'light'}
                    type={'button'}
                    onClick={modalClose}>CANCEL
                </Button>
                <Button
                    type={'submit'}
                    variant={'primary'}
                    onClick={() => {
                        if (selectionDoneHandler) {
                            selectionDoneHandler(selections);
                        }
                        modalClose();
                    }}>{doneButtonText}
                </Button>
            </div>
        </div>
    );
}