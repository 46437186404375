import ResultsPreview from './results_preview';
import { useSelector, useDispatch } from 'react-redux';
import { getAIArtResults, setAiArtResults } from '../../../redux/slices/ai_art_slice';
import StringUtils from "../../../utils/string";
import APIService from "../../../http/api_service";
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';

export const fetchAllSavedLocalCopies = () => {
    const localCopies = [];
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        let referenceKeyStart = 'ai_writer_recent_copies_for_';
        if (key.startsWith(referenceKeyStart)) {
            let value = localStorage.getItem(key);
            if (value) {
                try {
                    let texts = [...JSON.parse(value)].map(x => {
                        return {
                            ...x,
                            ref: StringUtils.substringAfter(key, referenceKeyStart)
                        }
                    });
                    localCopies.push(texts)
                } catch (e) {
                }
            }
        }
    }
    return localCopies.flat();
}

export const fetchAllSavedArtCopies = (workSpace, results, dispatch) => {
    APIService.fetchAllSavedArtCopies(workSpace._id, (response, _) => {
        if (response) {
            let justSaved = response.data;
            let resultsCopy = [...results];
            if (justSaved.length > 0) {
                for (let savedCopy of justSaved) {
                    let resultCopy = resultsCopy.find(x => x.art_url === savedCopy.art_url);
                    if (!resultCopy) {
                        resultsCopy.push({
                            ...savedCopy,
                            saved: true,
                            _id: savedCopy._id,
                        })
                    }
                }
                dispatch(setAiArtResults(resultsCopy));
            }
        }
    });
}

export default function AllSavedCopies() {

    const dispatch = useDispatch();
    const results = useSelector(getAIArtResults);
    const workSpace = useSelector(getActiveWorkSpace);
    const [deletingAllCopies, setDeletingAllCopies] = useState(false);

    const deleteAllSavedCopies = () => {
        setDeletingAllCopies(true);
        APIService.deleteAllSavedCopies(workSpace._id, (response, error) => {
            setDeletingAllCopies(false);
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { message } = response;
            toast.success(message, { theme: 'colored' });
            dispatch(setAiArtResults([]));
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                let referenceKeyStart = 'ai_writer_recent_copies_for_';
                if (key.startsWith(referenceKeyStart)) {
                    localStorage.removeItem(key);
                }
            }
        })
    }

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 20,
                paddingLeft: '20%',
                paddingRight: '20%',
                flexDirection: 'column'
            }}>
            {
                results.length > 0 &&
                <ResultsPreview
                    allMode
                    fetchAllSavedArtCopies={() => {
                        fetchAllSavedArtCopies(workSpace, [], dispatch);
                    }}
                    workSpace={workSpace}
                    results={results}
                    canShowTool
                />
            }
            {
                (results ?? []).length < 1 &&
                <span>You've not saved any copies yet</span>
            }
            {
                results.length > 0 &&
                <Button
                    className='mt-3'
                    variant={'outline-danger'}
                    icon={'trash'}
                    isWorking={deletingAllCopies}
                    disabled={deletingAllCopies}
                    onClick={() => {
                        deleteAllSavedCopies();
                    }}>
                    {
                        deletingAllCopies ? "Please wait..." : "Delete All Saved Copies"
                    }
                </Button>
            }
        </div>
    );
}