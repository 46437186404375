import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getSelectedMediaOption,
    setOpenPixelFilePickerDialog,
    setOpenPixelFilePickerDialogForWriteTab,
    setShowMediaOptions,
} from '../../redux/slices/postslice';
import { CgPexels } from 'react-icons/cg'
import ConfirmModal from './ConfirmModal';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'constants';
import { useState } from 'react';
import { SiPexels } from 'react-icons/si';
// import Pexels from '../../assets/'

export default function PixelImagePicker(props) {
    const { fileType } = props;
    const navigate = useNavigate();
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const dispatch = useDispatch();
    const selectedMediaOption = useSelector(getSelectedMediaOption);

    return (
        <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            // onClick={(e) => {
            //     setTimeout(() => {
            //         localStorage.setItem('fileType', fileType);
            //         props?.fromWriteTab ? dispatch(setOpenPixelFilePickerDialogForWriteTab(true)) : dispatch(setOpenPixelFilePickerDialog(true));
            //     }, 100);
            // }}
            onClick={() => {
                navigate(`${RoutePaths.PEXELS_CONTENTS}`)
                dispatch(setShowMediaOptions(false))
            }}
            >
            <div
                style={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: 'center',
                    // marginLeft: 8,
                    cursor: 'pointer',
                    gap: "5px"
                }}>
                {/* <CgPexels size={20} /> */}
                {/* <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJMiiZpCB0XU3p05kkxwPg2I6dtnIZ23QUNRYTTf6mJoKbVwC8"
                    style={{ width: 50, height: 50 }}
                    alt="Pexels Icon From Postly"
                /> */}
                <SiPexels size={50}/>
                <span
                    style={{
                        fontSize: "10px",
                        // flex: 1,
                        width: 'auto',
                        // fontWeight: 500,
                        // paddingLeft: 20,
                        // textAlign: 'center',
                        color: 'inherit'
                    }}>Pexels
                </span>
            </div>
            <ConfirmModal
                open={openUpgradeDialog}
                title={"Action requires Upgrade"}
                message={"Sorry, Pexels is not avaliable to free users. Upgrade your plan to use this feature. Thank you!"}
                confirmText={"UPGRADE"}
                cancelText={"CANCEL"}
                onCancel={() => {
                    setOpenUpgradeDialog(false);
                }}
                onConfirm={() => {
                    setOpenUpgradeDialog(false);
                    navigate(`${RoutePaths.BILLING_STRIPE}`);
                }}
            />
        </div>
    );
}
