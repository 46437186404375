export const selectChannel = `<div>
    <p class="guide-description">Select social media channels</p>
  </div>`;

export const createPost = `<div>
<p class="guide-description">Click here to create new post</p>
</div>`;

export const createContent = `<div>
    <p class="guide-description">Create your content here</p>
</div>`;

export const previewPost = `<div>
    <p class="guide-description">Preview post on your selected social media</p>
</div>`;

export const addTeam = `<div>
    <p class="guide-description">Click here to add new team</p>
</div>`;

export const connectPlatforms = `<div class="guide-description">
<p>Click here to connect your platforms</p>
<div>
<a href="https://postly.ai/how-it-works" target="_blank" rel="noopener"></a></div>
</div>`;

export const billingService = ` <div>
    <p class="guide-description">Sign up for trial to enjoy full access</p>
  </div>`;

export const AIService = `  <div>
    <p class="guide-description">
      Create quality content for your social media posts effortlessly with the
      AI features
    </p>
  </div>`;
