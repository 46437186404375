import React from 'react'
import { useState } from 'react';
import { useDispatch } from "react-redux";
import { CiteDrop, EachImageBox } from './ExtrernalImageSearchDialogueStyle';
import ConfirmModal from 'components/common/ConfirmModal';
import { setShowMediaOptions } from 'redux/slices/postslice';


const EachExternalSutterstockImageDislplay = (props) => {
    const [displayCitation, setDisplayCitation] = useState(null);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [src, setSrc] = useState(null);
    const [photographer, setPhotographer] = useState(null);
    const dispatch = useDispatch();


    const onChangeHandler = (e) => {
      let newObj;
        if(e.target.checked === true) {
          newObj = {
            external: true,
            file: null,
            name: props?.x?.assets?.huge_thumb?.url,
            optimized: true,
            uploaded: true,
            url: props?.x?.assets?.huge_thumb?.url
          };
          props?.setSelectedObjects([...props?.selectedObjects, newObj])
          return;
        }
       let newSelectedObjects = props?.selectedObjects?.filter(obj => obj.name !== props?.x?.assets?.huge_thumb?.url)
       props?.setSelectedObjects(newSelectedObjects)
    }
  return (
    <EachImageBox
      // onClick={() => {
      //   setOpenConfirmation(true);
      //   {props?.x?.media_type !== 'video'? setSrc(props?.x?.assets?.huge_thumb?.url): setSrc(props?.x?.assets?.preview_mp4) };
      //   {props?.x?.photographer && setPhotographer((props?.x?.photographer) ? props?.x?.photographer : 'Shutterstock')};
      // }}
      onMouseOver={() => {
        setDisplayCitation(true);
      }}
      onMouseLeave={() => {
        setDisplayCitation(false);
      }}
    >
      {displayCitation && (
        <CiteDrop>
          { props?.x?.photographer && (<><p style={{ textAlign: "center" }}>
            Photographed by: <b>{props?.x?.photographer}</b>
          </p>
          <p style={{ textAlign: "center", wordBreak: "break-word" }}>
            Photographer url: <b>{props?.x?.photographer_url}</b>
          </p></>)}
          <p style={{ textAlign: "center", wordBreak: "break-word" }}>
            Photo description: <b>{props?.x?.description}</b>
          </p>
        </CiteDrop>
      )}
      <img
        src={props?.x?.assets?.huge_thumb?.url}
        alt=""
        height="100%"
        width="100%"
        style={{ objectFit: "fill" }}
      />
      <ConfirmModal
        open={openConfirmation}
        title={"Confirm Citation"}
        message={
          "Using this image will attach a citation to the photographer to your final post. Do you still wish to proceed?"
        }
        confirmText={"PROCEED"}
        cancelText={"CANCEL"}
        showDialoguePrompt
        onCancel={() => {
          setOpenConfirmation(false);
        }}
        onConfirm={() => {
          setOpenConfirmation(false);
          props?.handleClick(src, (photographer || 'shutterstock'));
          dispatch(setShowMediaOptions(false));
        }}
      />
      <input type="checkbox" style={{zIndex: "2", position: "absolute", top: "0px", left: "0px"}} onChange={onChangeHandler} />
    </EachImageBox>
  )
}

export default EachExternalSutterstockImageDislplay