import InfoBar from "components/common/InfoBar"
import RichInfoBar from "components/common/RichInfoBar"
import React, { useEffect, useRef } from "react"
import sha256 from 'crypto-js/sha256';

const PrePublishErrorResolver = ({ variant = 'warning', title, error, actionMessage, secondaryActionMessage, action, secondaryAction }) => {

    const resolver = useRef();

    useEffect(() => {
        if (error) {
            resolver.current.scrollIntoView();
        }
    }, [error]);

    return (
        <div id={`${sha256(error).toString()}-container`}
            ref={resolver}>
            {
                (action || secondaryAction) ?
                    <RichInfoBar
                        variant={variant}
                        title={title}
                        message={error}
                        actionMessage={actionMessage}
                        secondaryActionMessage={secondaryActionMessage}
                        secondaryAction={secondaryAction}
                        action={action}
                    /> :
                    <InfoBar
                        variant={'warning'}
                        info={error}
                    />
            }

        </div>
    )
}
export default PrePublishErrorResolver;