import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Nav, Collapse, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import classNames from 'classnames';
import AppContext from 'context/Context';
import { useDispatch } from 'react-redux';
import { searchWorkSpace } from 'redux/slices/workspaceslice';
import { RoutePaths } from 'constants';

const CollapseItems = ({ canViewMore, route }) => {
  const { pathname } = useLocation();

  const openCollapse = childrens => {
    const checkLink = children => {
      if (children.to === pathname) {
        return true;
      }
      return (
        Object.prototype.hasOwnProperty.call(children, 'children') &&
        children.children.some(checkLink)
      );
    };
    return childrens.some(checkLink);
  };

  const [open, setOpen] = useState(openCollapse(route.children));
  const dispatch = useDispatch();

  return (
    <Nav.Item as="li">
      {
        route.toolTip ?
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip id="tooltip--trigger">
                {route.toolTip}
              </Tooltip>
            }
          >
            <Nav.Link
              onClick={() => {
                setOpen(!open);
              }}
              className={classNames('dropdown-indicator cursor-pointer', {
                'text-500': !route.active
              })}
              aria-expanded={open}
            >
              <NavbarVerticalMenuItem route={route} />
            </Nav.Link>
          </OverlayTrigger>
          : <Nav.Link
            onClick={() => {
              setOpen(!open);
            }}
            className={classNames('dropdown-indicator cursor-pointer', {
              'text-500': !route.active
            })}
            aria-expanded={open}
          >
            <NavbarVerticalMenuItem route={route} />
          </Nav.Link>
      }
      {
        route.subText &&
        <span style={{ fontSize: 11, marginTop: -20 }}>{route.subText}</span>
      }
      <Collapse in={open}>
        <Nav className={`flex-column nav gap-${route.vGap ?? 0}`} as="ul">
          <NavbarVerticalMenu routes={route.children} />
          {
            canViewMore &&
            <div
              className="p-0 m-0 text-end align-self-end"
            >
              <Button
                variant='link'
                as='span'
                className="d-block fs--1 text-decoration-none p-0 m-0 me-2"
                onClick={() => {
                  dispatch(searchWorkSpace(true));
                }}>
                See All Workspaces
              </Button>
            </div>
          }
        </Nav>
      </Collapse>
    </Nav.Item>
  );
};

CollapseItems.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string,
    icon: PropTypes.any,
    children: PropTypes.array.isRequired,
    active: PropTypes.bool
  }).isRequired
};

const NavbarVerticalMenu = ({ canViewMore, routes }) => {
  const {
    config: { showBurgerMenu },
    setConfig
  } = useContext(AppContext);

  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };

  // const handleRouting = (route) => {
  //   return route.to;
  // }

  return routes.map(route => {
    // if (route?.demo) return <></>
    if (!route.children) {
      return (
        route.toolTip ?
          <OverlayTrigger
            placement='left'
            overlay={
              <Tooltip id="tooltip--trigger">
                {route.toolTip}
              </Tooltip>
            }
          >
            <Nav.Item
              as="li"
              key={route.name}
              onClick={handleNavItemClick}
              className='ms-1'>
              <NavLink
                end={route.exact}
                to={route.to}
                state={{ open: route.to === '/' }}
                className={({ isActive }) =>
                  isActive ? 'active nav-link' : 'nav-link'
                }
              >
                <NavbarVerticalMenuItem route={route} />
              </NavLink>
            </Nav.Item>
          </OverlayTrigger>
          :
          <Nav.Item
            as="li"
            key={route.name}
            onClick={handleNavItemClick}
            className='ms-1'>
            <NavLink
              end={route.exact}
              to={route.to}
              state={{ open: route.to === '/' }}
              className={({ isActive }) =>
                isActive ? 'active nav-link' : 'nav-link'
              }
            >
              <NavbarVerticalMenuItem route={route} />
            </NavLink>
          </Nav.Item>
      );
    }
    return <CollapseItems canViewMore={route.canViewMore} route={route} key={route.name} />;
  });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape(NavbarVerticalMenuItem.propTypes))
    .isRequired
};

export default NavbarVerticalMenu;
