import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyD4YyT9gwTD0qio57iiieYIpwEwVsuNrE4",
    authDomain: "postly-548c1.firebaseapp.com",
    projectId: "postly-548c1",
    storageBucket: "postly-548c1.appspot.com",
    messagingSenderId: "111332725522",
    appId: "1:111332725522:web:73272decf2a464822199cb",
    measurementId: "G-9MV25DJY10"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export default firebase;