import React, { useEffect, useState } from 'react';
import { findPlatformByName } from '../../../../app/platforms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { PlatformIdentifier, RoutePaths } from '../../../../../constants';
import { useNavigate } from 'react-router-dom';
import { Button, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import Flex from 'components/common/Flex';

export default function BulkContentPostPlatformSelectionDropdown(props) {

    let { closeModal, platformChangedHandler } = props;
    const [platform, setPlatform] = useState(props.platform);
    const [existingPlatform, setExistingPlatform] = useState(findPlatformByName(platform.name));
    const navigate = useNavigate();
    const [connectedPages, setConnectedPages] = useState([...platform.connected_pages_and_groups ?? []]);

    useEffect(() => {
        setPlatform(props.platform);
        setConnectedPages([...props.platform.connected_pages_and_groups ?? []]);
        if (props.platform) {
            setExistingPlatform(findPlatformByName(props.platform.name));
        }
    }, [props.platform]);

    useEffect(() => {
        if (platformChangedHandler) {
            platformChangedHandler(platform);
        }
    }, [platform, platformChangedHandler]);

    const oneOfPageOwners = () => {
        return (
            platform.identifier === PlatformIdentifier.INSTAGRAM
            || platform.identifier === PlatformIdentifier.FACEBOOK
            || platform.identifier === PlatformIdentifier.LINKED_IN
            || platform.identifier === PlatformIdentifier.YOUTUBE
            || platform.identifier === PlatformIdentifier.PINTEREST
            || platform.identifier === PlatformIdentifier.REDDIT
            || platform.identifier === PlatformIdentifier.TELEGRAM
            || platform.identifier === PlatformIdentifier.GOOGLE_MY_BUSINESS
        );
    }

    const ConnectedPagesDropDown = React.forwardRef((props, ref) => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                <p
                    style={{
                        paddingLeft: 10
                    }}><b>{platform.user_name}</b> on <b>{platform.name}</b>
                </p>
                <h6
                    type={'h5'}
                    style={{
                        padding: 10,
                        paddingTop: 1,
                        paddingLeft: 10
                    }}>Select Target Entries
                </h6>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 10,
                        gap: 10
                    }}>
                    <div style={{
                        marginLeft: 10,
                    }}>
                        <Form.Check
                            id={`check-all-${platform.user_id}`}
                            type={'checkbox'}
                            label={'Select All'}
                            checked={connectedPages.length > 0 && connectedPages.every((x) => x.selected)}
                            onChange={(e) => {
                                let value = e.target.checked;
                                for (let entry of connectedPages) {
                                    entry.selected = value;
                                }
                                setPlatform({ ...platform });
                            }}
                        />
                    </div>
                    <div
                        style={{
                            maxHeight: 200,
                            overflowY: 'auto',
                            overflowX: 'hidden'
                        }}>
                        {
                            connectedPages.length > 0 &&
                            connectedPages.map((entry) => {
                                return (
                                    <Flex
                                        className={'p-2 gap-2'}
                                        alignItems={'center'}
                                        key={entry.id}
                                        onClick={() => {
                                            entry.selected = !entry.selected;
                                            setPlatform({ ...platform });
                                        }}
                                        title={entry.name}>
                                        <div
                                            style={{
                                                width: 30,
                                                height: 30,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderRadius: '100%',
                                                background: entry.picture ? 'white' : '#CFD8DC'
                                            }}>
                                            {
                                                entry.picture ? <img src={entry.picture}
                                                    width={30}
                                                    height={30}
                                                    alt=' '
                                                    style={{
                                                        borderRadius: '100%'
                                                    }}
                                                />
                                                    : <span style={{
                                                        textAlign: 'center',
                                                        fontWeight: 'bold',
                                                        color: 'black'
                                                    }}>{entry.name.charAt(0).toUpperCase()}
                                                    </span>
                                            }
                                        </div>
                                        <h6 style={{
                                            flex: 1,
                                            display: 'inline-flex',
                                            whiteSpace: 'nowrap',
                                            padding: 0,
                                            margin: 0,
                                            cursor: 'pointer',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}>{entry.name}</h6>
                                        <Form.Check
                                            type="checkbox"
                                            checked={entry.selected}
                                            onChange={(e) => { }}
                                        />
                                    </Flex>
                                );
                            })
                        }
                    </div>
                    {
                        connectedPages.length < 1 &&
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <span style={{
                                textAlign: 'center',
                                padding: 10,
                            }}>No connected entries found. Connect entries
                            </span>
                            <Button
                                variant={'primary'}
                                onClick={() => {
                                    closeModal(true);
                                    navigate(`${RoutePaths.PLATFORMS}/${platform.target ?? platform.identifier}`);
                                }}>Connect Entries
                            </Button>
                        </div>
                    }
                </div>
            </div >
        );
    });

    return (
        <div>
            {
                !existingPlatform &&
                <div></div>
            }
            {
                oneOfPageOwners() ?
                    <div
                        title={`${platform.user_name} on ${platform.name}`}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: 15,
                            paddingRight: 5,
                            paddingTop: 5,
                            paddingBottom: 5,
                            gap: 10,
                            borderRadius: 10
                        }}>
                        <OverlayTrigger
                            trigger={'click'}
                            rootClose
                            overlay={
                                <Popover>
                                    <Popover.Body>
                                        <ConnectedPagesDropDown />
                                    </Popover.Body>
                                </Popover>
                            }>
                            <span>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2
                                    }}>
                                    <div
                                        style={{
                                            width: 30,
                                            height: 30,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: '100%',
                                            background: existingPlatform.color
                                        }}>
                                        <FontAwesomeIcon
                                            icon={existingPlatform.icon}
                                            style={{
                                                width: 15,
                                                height: 15,
                                                color: 'white'
                                            }}
                                        />
                                    </div>
                                    {
                                        !platform.connected &&
                                        <Button
                                            style={{
                                                flex: 1,
                                                width: 110
                                            }}
                                            onClick={(e) => {
                                                closeModal();
                                                navigate(`${RoutePaths.PLATFORMS}/${platform.target ?? platform.identifier}`);
                                            }} variant={'primary'}>Connect
                                        </Button>
                                    }
                                    {
                                        platform.connected &&
                                        <span
                                            style={{
                                                flex: 1,
                                                maxWidth: 70,
                                                display: 'inline-block',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}>{platform.user_name}
                                        </span>
                                    }
                                    {
                                        platform.connected &&
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                        />
                                    }
                                </div>
                            </span>
                        </OverlayTrigger>
                        {
                            platform.connected &&
                            <Form.Check
                                type="checkbox"
                                id={`${platform.user_id}-platform-check`}
                                checked={connectedPages.some((x) => x.selected)}
                                onChange={(e) => {
                                    for (let page of connectedPages) {
                                        page.selected = e.target.checked;
                                    }
                                    setPlatform({ ...platform });
                                }}
                            />
                        }
                    </div>
                    :
                    <div
                        onClick={() => {
                            let newPlatformSelectionState = !platform.selected;
                            platform.selected = newPlatformSelectionState;
                            setPlatform({ ...platform });
                        }}
                        title={`${platform.user_name} on ${platform.name}`}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: 15,
                            paddingRight: 5,
                            paddingTop: 5,
                            paddingBottom: 5,
                            gap: 10,
                            borderRadius: 10
                        }}>
                        <div
                            style={{
                                width: 30,
                                height: 30,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '100%',
                                background: existingPlatform.color
                            }}>
                            <FontAwesomeIcon
                                icon={existingPlatform.icon}
                                style={{
                                    width: 15,
                                    height: 15,
                                    color: 'white'
                                }}
                            />
                        </div>
                        {
                            platform.connected &&
                            <span
                                style={{
                                    flex: 1,
                                    maxWidth: 70,
                                    display: 'inline-block',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>{platform.user_name}
                            </span>
                        }
                        {
                            !platform.connected &&
                            <Button
                                style={{
                                    width: 110
                                }}
                                onClick={(e) => {
                                    closeModal();
                                    navigate(`${RoutePaths.PLATFORMS}/${platform.target ?? platform.identifier}`);
                                }} variant={'primary'}>Connect
                            </Button>
                        }
                        {
                            platform.connected &&
                            <Form.Check
                                type="checkbox"
                                id={`account-check-${platform.user_id}`}
                                checked={platform.selected}
                                onChange={(e) => {
                                    platform.selected = e.target.checked;
                                    setPlatform({ ...platform });
                                }}
                            />
                        }
                    </div>
            }
        </div>
    );
}