import Divider from 'components/common/Divider';
import React, { useState, useEffect } from 'react';
import TeamFeedbackEntry from './team_feedback_entry';

export default function TeamFeedbackView(props) {

    const { post, style = {} } = props;
    const [feedbacks, setFeedbacks] = useState();

    useEffect(() => {
        let disapprovals = post?.data?.disapprovals ?? {};
        let approvals = post?.data?.approvals ?? {};
        let feedbackData = [];
        if (disapprovals) {
            disapprovals = Object.entries(disapprovals);
            for (let disapproval of disapprovals) {
                feedbackData.push({
                    disapproved: true,
                    ...disapproval[1]
                });
            }
        }
        if (approvals) {
            approvals = Object.entries(approvals);
            for (let approval of approvals) {
                feedbackData.push({
                    disapproved: false,
                    ...approval[1]
                });
            }
        }
        if (feedbackData.length > 0) {
            setFeedbacks([...feedbackData]);
        }
    }, [post]);

    return (
        <div style={{ ...style }}>
            {
                feedbacks &&
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 10,
                        paddingTop: 0
                    }}>
                    <h5 type={'h3'}>Team Feedback(s)</h5>
                    <Divider />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxHeight: 800,
                            gap: 10,
                            overflowY: 'auto'
                        }}>
                        {
                            feedbacks.map((feedback) => {
                                return (
                                    <TeamFeedbackEntry
                                        key={feedback._id}
                                        feedback={feedback}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            }
        </div>
    );
}