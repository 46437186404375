import APIService from "http/api_service";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Dropdown, Form, Modal } from "react-bootstrap";
import { FaFolder } from "react-icons/fa";
import { IoIosLink } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setLibraryItems } from "redux/slices/links_shortener_slice";
import { BsThreeDotsVertical } from "react-icons/bs";

const Item = ({ data, setViewHandler, setLibraryState, selectedItems, setSelectedItems }) => {
  const { name, type, _id, owner, links, url } = data;
  const linkRef = useRef();
  const cardRef = useRef();
  const dispatch = useDispatch();
  const [content, setContent] = useState(name);
  const [renameState, setRenameState] = useState("Rename");
  const [deleteState, setDeleteState] = useState("Delete");
  const [dropDown, setDropDown] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const getLibraryHandler = async () => {
    APIService.fecthMyLibrary((response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      let { data } = response;
      //   toast.success(message);
      dispatch(setLibraryItems(data));
    });
  };

  const handleRename = async () => {
    setRenameState("renaming...");
    let requestBody = {
      name: content,
    };
    await APIService.renameItem(requestBody, _id, (response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        setRenameState("Re-name");

        return;
      }
      let { message, data } = response;
      setRenameState("Re-name");
      setContent(data);
      toast.success(message);
      setShowRenameModal(false)
      setDropDown(false)
      getLibraryHandler();
    });
  };

  const handleDeleteOneItem = async () => {
    setDeleteState("Deleting...");
    await APIService.deleteItem( _id, (response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        setDeleteState("Delete");

        return;
      }
      let { message } = response;
      setDeleteState("Delete");
      toast.success(message);
      setShowDeleteModal(false)
      setDropDown(false)
      getLibraryHandler();
    });
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setDropDown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const openFolderHandler = () => {
    setLibraryState(links);
    setViewHandler(name);
  };


  const checkerHandler = async (e) => {
    if (e.target.checked) {
      setSelectedItems([...selectedItems, data])
    } else {
      setSelectedItems(selectedItems.filter((item) => item._id !== data._id));
    }
  }

  return (
    <>
      <Card
        style={{
          maxWidth: "100%",
          //   height: "14rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "20px",
          padding: "20px",
          // cursor: "pointer",
          boxSizing: "border-box",
          position: "relative",
        }}
        ref={cardRef}
      >
        {type === "link" ? (
          <IoIosLink onClick={() => linkRef.current.click()} cursor={"pointer"} color="#8fd9ff" size={50} />
        ) : (
          <FaFolder  onClick={openFolderHandler} cursor={"pointer"} color="#0eadff" size={50} />
        )}
        <Card.Text  onClick={ type === "link" ? () => linkRef?.current?.click() : openFolderHandler} style={{cursor: "pointer"}}>{name}</Card.Text>
        {url ? (
          <Card.Link
            href={url}
            target="_blank"
            ref={linkRef}
            style={{ textDecoration: "underline", fontSize: "12px" }}
          >
            {url}
          </Card.Link>
        ) : (
          <Card.Text
           
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: "12px",
            }}
          >
          </Card.Text>
        )}
        <Form.Check
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "0px",
            left: "5px",
            transform: "scale(1.0)",
          }}
          // id="auto-shorten-link-settings"
          type="checkbox"
          onChange={checkerHandler}
          // checked={status}
        />
        <div
          style={{
            width: "fit-content",
            position: "absolute",
            top: "0px",
            right: "5px",
            zIndex: "5"
          }}
        >
          <BsThreeDotsVertical
            size={20}
            cursor={"pointer"}
            onClick={() => setDropDown(!dropDown)}
          />
          <Dropdown.Menu show={dropDown}>
            <Dropdown.Item eventKey="1" onClick={() => setShowRenameModal(true)}>
              Rename
            </Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={() => setShowDeleteModal(true)}>Delete</Dropdown.Item>
          </Dropdown.Menu>
        </div>
      </Card>
      <Modal
        size="sm"
        centered
        show={showRenameModal}
        onHide={() => setShowRenameModal(false)}
        onExit={() => setShowRenameModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Rename Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            placeholder="Enter new name"
            aria-label="Long Link Text box"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            // style={{width: "100%"}}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowRenameModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleRename}>
            {renameState}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="sm"
        centered
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onExit={() => setShowDeleteModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Item</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{fontWeight: "bold"}}>
          Are you sure you want to Delete this item?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDeleteOneItem}>
            {deleteState}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Item;
