import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { oneOfPageOwners } from './accounts_list';
import { SectionHeaderBase } from './styles';
import { PlatformIdentifier } from '../../../../../constants';
import { findPlatformByIdentifier } from 'components/app/platforms';
import { Form } from 'react-bootstrap';
import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import { getPlatformChangeCounter } from 'redux/slices/platformSlice';
import { useSelector } from 'react-redux';

export const timer = ms => new Promise(res => setTimeout(res, ms))

export const svgWhiteList = (existingPlatform) => {
    let whiteList = [PlatformIdentifier.GOOGLE_MY_BUSINESS];
    return whiteList.includes(existingPlatform?.identifier);
}

export default function SectionHeader(props) {

    const { id, handleSelectAll, handleExpansion } = props;
    const [platform, setPlatform] = useState(props.platform);
    let existingPlatform = findPlatformByIdentifier(platform.identifier);
    const [checked, setChecked] = useState(false);
    const { config } = useContext(AppContext);
    const platformChangeCounter = useSelector(getPlatformChangeCounter)

    useEffect(() => {
        if(platform.accounts.every(item => item.connected_pages_and_groups?.length < 1)){
            return;
        }
        setChecked(platform.accounts.every(item => item.selected))
    }, [platform, platformChangeCounter])

    useEffect(() => {
        let changedPlatform = props.platform;
        setPlatform(changedPlatform);
    }, [props.platform]);

    const canDisplayCheckBox = () => {
        let canDisplay = true;
        for (let account of platform.accounts) {
            if (oneOfPageOwners(account)) {
                // if ((account.connected_pages_and_groups ?? []).length < 1) {
                //     canDisplay = false;
                //     break;
                // }
            }
        }
        return canDisplay
    }

    return (
        <SectionHeaderBase
            className={config.isDark ? 'bg-100' : ''}
            id={id}
            onClick={() => handleExpansion()}
            // onClick={async (e) => {
            //     let newCheckState = !checked;
            //     if (platform.accounts) {
            //         for (let account of platform.accounts) {
            //             let pages = account.connected_pages_and_groups;
            //             if (pages) {
            //                 for (let page of pages) {
            //                     if (page.selected && newCheckState) {
            //                         continue;
            //                     } else {
            //                         simulateMouseClick(document.getElementById(`${page.id}${page.target}`));
            //                     }
            //                     await timer(5);
            //                 }
            //             } else {
            //                 if (account.selected && newCheckState) {
            //                     continue;
            //                 } else {
            //                     simulateMouseClick(document.getElementById(account._id));
            //                 }
            //                 await timer(5);
            //             }
            //         }
            //     }
            // }}
            style={{
                padding: 5,
                // paddingLeft: 5,
                borderRadius: 2,
                marginRight: 11,
                display: 'flex',
                alignItems: 'center',
                position: 'sticky',
                top: -14,
                zIndex: 1,
                border: "2px solid #edf2f9",
                // backgroundColor: config.isDark ? '' : '#EEEEEE',
                cursor: 'pointer'
            }}>
            <div
                style={{
                    width: 22,
                    height: 22,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // marginRight: 10,
                    // borderRadius: '100%',
                    // background: svgWhiteList(existingPlatform) ? '#263238' : existingPlatform?.color
                }}>
                {
                    svgWhiteList(existingPlatform) ?
                        <img 
                            src={existingPlatform?.svg}
                            alt=''
                            style={{
                                width: 15,
                                height: 15,
                            }}
                        />
                        : <FontAwesomeIcon
                            icon={existingPlatform?.icon}
                            style={{
                                fontSize: 15,
                                color: existingPlatform?.color
                            }}
                        />
                }
            </div>
            <h6 className='m-0'
                style={{
                    flex: 1,
                    fontSize: 13
                }}>{existingPlatform?.name}
            </h6>
            {
                canDisplayCheckBox() &&
                <Flex alignItems={'center'} className={'gap-2'}>
                    <Form.Check
                        type='checkbox'
                        id={`${platform.name}-check`}
                        checked={checked}
                        onChange={async (_value) => {
                            handleSelectAll(platform);
                        }}
                    // onClick={async () => {
                    //     handleSelectAll(platform);
                    // }}
                    />
                    {/* <h5 className='fs--1 pt-1'>Select All</h5> */}
                </Flex>
            }
        </SectionHeaderBase>
    );
}