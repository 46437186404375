import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    links: [],
    folders: [],
    autoShortenedStatus: null,
    libraryItems: null,
    domains: null,
    isShortening: false,
    bitlyToken: "",
    bitlyTokenArray: [],
    qrcodeArray: null
}
export const linksSlice = createSlice({
    name: 'linkShortener',
    initialState,
    reducers: {
        setLinks: (state, action) => {
            state.links = action.payload;
        },
        setLinksFolders: (state, action) => {
            state.folders = action.payload;
        },
        setAutoShortenerStatus: (state, action) => {
            state.autoShortenedStatus = action.payload;
        },
        setLibraryItems: (state, action) => {
            state.libraryItems = action.payload;
        },
        setDomains: (state, action) => {
            state.domains = action.payload;
        },
        setIsShortening: (state, action) => {
            state.isShortening = action.payload;
        },
        setBitlyToken: (state, action) => {
            state.bitlyToken = action.payload;
        },
        setBitlyTokenArray: (state, action) => {
            state.bitlyTokenArray = action.payload;
        },
        setQrcodeArray: (state, action) => {
            state.qrcodeArray = action.payload;
        },
    }
});
export let getLinks = (state) => state.linkShortener.links;
export let getLinksFolders = (state) => state.linkShortener.folders;
export let getAutoShortenerStatus = (state) => state.linkShortener.autoShortenedStatus;
export let getLibraryItems = (state) => state.linkShortener.libraryItems;
export let getDomains = (state) => state.linkShortener.domains;
export let getIsShortening = (state) => state.linkShortener.isShortening;
export let getBitlyToken = (state) => state.linkShortener.bitlyToken;
export let getBitlyTokenArray = (state) => state.linkShortener.bitlyTokenArray;
export let getQrcodeArray = (state) => state.linkShortener.qrcodeArray;
export let {
    setLinks,
    setLinksFolders,
    setAutoShortenerStatus,
    setLibraryItems,
    setDomains,
    setIsShortening,
    setBitlyToken,
    setBitlyTokenArray,
    setQrcodeArray,
} = linksSlice.actions;
export default linksSlice.reducer;