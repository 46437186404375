import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Row, Col } from 'react-bootstrap';
import Sleeping404 from 'assets/img/animated-icons/sleeping-404.json';
import Flex from 'components/common/Flex';
import { useDispatch } from 'react-redux';
import { setSearchHint } from 'redux/slices/workspaceslice';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'constants';

const ImageEditorPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSearchHint("Search..."));
    }, [])

    const maintainanceDisplay = (
        <>
            <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
                Under maintenance
            </p>
            <hr />
            <p>
                Sorry, this page may not be accessible to you at the moment, please check back later.
            </p>
        </>
    )

    const introArea = (
        <>
            <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
                Kindly select any of the following to continue.
            </p>
            <hr />
            {/* <p>
                <Button
                    type={'submit'}
                    variant={'falcon-primary'}
                    onClick={() => {
                        navigate(RoutePaths.IMAGE_EDITOR_FULLSCREEN)
                    }}>{'Go to editor'}
                </Button>
            </p> */}
        </>
    )

    return (
        <>
            <Container className="mt-5">
                <Row className="mt-3">
                    <Col>
                        <Card onClick={() => {
                            navigate(RoutePaths.IMAGE_EDITOR_FULLSCREEN)
                        }} className="text-center cursor-pointer">
                            <Card.Body className="">
                                <img style={{ maxHeight: '130px' }} src={process.env.PUBLIC_URL + '/crop_resize.png'} height="100%" width="100%" alt="" />
                                <p className="mt-3">
                                    Resize or Crop
                                </p>
                                <p style={{ fontSize: 14 }}>
                                    Resize or Crop all images to the desired outcome.
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card onClick={() => {
                            navigate(RoutePaths.IMAGE_BACKGROUND_REMOVAL)
                        }} className="text-center cursor-pointer">
                            <Card.Body className="">
                                <img style={{ maxHeight: '130px' }} src={process.env.PUBLIC_URL + '/remove_bg.png'} height="100%" width="100%" alt="" />
                                <p className="mt-3">
                                    Background Remover
                                </p>
                                <p style={{ fontSize: 14 }}>
                                    Remove image background with additional features.
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                        <Card onClick={() => {
                            navigate(RoutePaths.IMAGE_EDITOR_FULLSCREEN)
                        }} className="text-center cursor-pointer">
                            <Card.Body className="">
                                <img style={{ maxHeight: '130px' }} src={process.env.PUBLIC_URL + '/photo_enhancer.png'} height="100%" width="100%" alt="" />
                                <p className="mt-3">
                                    Photo enhancer
                                </p>
                                <p style={{ fontSize: 14 }}>
                                    Create your perfect images and photos in a few clicks.
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Card onClick={() => {
                            navigate(RoutePaths.IMAGE_EDITOR_FULLSCREEN)
                        }} className="text-center cursor-pointer">
                            <Card.Body className="">
                                <img style={{ maxHeight: '130px' }} src={process.env.PUBLIC_URL + '/blemish_remover.png'} height="100%" width="100%" alt="" />
                                <p className="mt-3">
                                    Blemish Remover
                                </p>
                                <p style={{ fontSize: 14 }}>
                                    Remove blemishes from your Images and Photos.
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card onClick={() => {
                            navigate(RoutePaths.IMAGE_EDITOR_FULLSCREEN)
                        }} className="text-center cursor-pointer">
                            <Card.Body className="">
                                <img style={{ maxHeight: '130px' }} src={process.env.PUBLIC_URL + '/wrinkle_remover.png'} height="100%" width="100%" alt="" />
                                <p className="mt-3">
                                    Wrinkle Remover
                                </p>
                                <p style={{ fontSize: 14 }}>
                                    Keep that youthful looks with the Wrinkel Remover
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card onClick={() => {
                            navigate(RoutePaths.IMAGE_EDITOR_FULLSCREEN)
                        }} className="text-center cursor-pointer">
                            <Card.Body className="">
                                <img style={{ maxHeight: '130px' }} src={process.env.PUBLIC_URL + '/photos_effect.png'} height="100%" width="100%" alt="" />
                                <p className="mt-3">
                                    Photo Effects
                                </p>
                                <p style={{ fontSize: 14 }}>
                                    Spice up your photos with different visual effects.
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card onClick={() => {
                            navigate(RoutePaths.IMAGE_EDITOR_FULLSCREEN)
                        }} className="text-center cursor-pointer">
                            <Card.Body className="">
                                <img style={{ maxHeight: '130px' }} src={process.env.PUBLIC_URL + '/img_watermark.png'} height="100%" width="100%" alt="" />
                                <p className="mt-3">
                                    Image Watermark
                                </p>
                                <p style={{ fontSize: 14 }}>
                                    Add a custom watermark to images and photos.
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ImageEditorPage;
