import React from 'react';
import { CardBody } from 'reactstrap';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../../../constants';
import Flex from 'components/common/Flex';
import { ltdUpgradePricing } from '../account/pricing/pricingDataNew';
import { useSelector } from 'react-redux';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { getLoggedInUser, isLTDUserAndRequiresUpgrade } from 'redux/slices/user_slice';
import { isFreeUser } from 'utils/user_utils';

const Help = ({ match, history }) => {

  const user = useSelector(getLoggedInUser);
  const workspace = useSelector(getActiveWorkSpace);
  const ltdUserAndRequiresUpgrade = useSelector(isLTDUserAndRequiresUpgrade);


  const upgradeParams = () => {
    if (ltdUserAndRequiresUpgrade) {
      return `&payment_for=${encodeURIComponent(JSON.stringify({
        pricingData: { ...ltdUpgradePricing },
        user: {
          _id: user._id,
          email: user.email,
          workspaceId: workspace._id
        }
      }))}`;
    }
    return '';
  }

  return (
    <div className="bg-light col-md-12 mb-3 border">
      <CardBody className="p-3">
        {
          ltdUserAndRequiresUpgrade() &&
          <Flex direction={'column'}>
            <Flex direction={'column'} >
              <span className='pt-3'>
                Enjoy a lifetime access to 200 workspaces and unlimited Social Accounts for only $999
              </span>
              <br />
              <p>
                <Button as={Link} to={`${RoutePaths.SCHEDULER_BILLING} ${upgradeParams()}`}>Upgrade NOW to enjoy full features. Limited time offer!</Button>
              </p>
            </Flex>
          </Flex>
        }
        {
          isFreeUser(user) &&
          <Flex direction={'column'} >
            <h1 className="mt-4 mb-2 lead text-800 text-sans-serif font-weight-semi-bold">
              There are no deals at the moment, please check back later
            </h1>
          </Flex>
        }
        {
          !isFreeUser(user) && !ltdUserAndRequiresUpgrade &&
          <Flex direction={'column'} >
            <h1 className="mt-4 mb-2 lead text-800 text-sans-serif font-weight-semi-bold">
              Ooops! no deal available now. Check back later
            </h1>
          </Flex>
        }
      </CardBody>
    </div>
  );
};

export default Help;
