import Flex from "components/common/Flex";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NewOrganizationSetup from "./new_organization";
import OrganizationSelectionDropdown from "../organization_selection_dropdown";
import useOrganizationStore from "state/organization_store";
import { useSearchParams } from "react-router-dom";

export default function OrganizationSetup({ attributes, handleNext, initialOrganizationName }) {

    const { t } = useTranslation();
    const organizations = useOrganizationStore(state => state.organizationList);
    const organizationBeingCreated = useOrganizationStore(state => state.organizationBeingCreated);
    const setOrganizationBeingCreated = useOrganizationStore(state => state.setOrganizationBeingCreated);
    const [previouslyActiveOrganization, setPreviouslyActiveOrganization] = useState();
    const [openOrganizationCreationPanel, setOpenOrganizationCreationPanel] = useState(false);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (organizations.length > 0 && !searchParams.get('new-space')) {
            setOpenOrganizationCreationPanel(true);
        }
    }, []);

    const openNewOrganizationCreationPanel = () => {
        setPreviouslyActiveOrganization(organizationBeingCreated);
        setOrganizationBeingCreated(undefined);
        setOpenOrganizationCreationPanel(true)
    }

    return (
        <Flex>
            {
                organizations.length === 0 && !openOrganizationCreationPanel &&
                <Flex direction={'column'} className={'mb-2'}>
                    <span className="text-center py-3">{t('you_have_no_organization_set_up')}</span>
                    <Button
                        onClick={openNewOrganizationCreationPanel}
                        size="sm"
                        className="rounded-pill">{t('get_started')}
                    </Button>
                </Flex>
            }
            {
                organizations.length !== 0 && !openOrganizationCreationPanel &&
                <Flex direction={'column'} className={'gap-2'}>
                    <OrganizationSelectionDropdown
                        organizations={organizations.filter(org => org)}
                        onSelect={(selection) => {
                            setOrganizationBeingCreated(selection);
                        }}
                        onCreateNewOrganization={openNewOrganizationCreationPanel}
                    />
                    <Button
                        onClick={() => {
                            if (!organizationBeingCreated) {
                                setOrganizationBeingCreated(organizations[0]);
                            }
                            handleNext();
                        }} size="sm" className="rounded-pill">{t('next')}
                    </Button>
                </Flex>
            }
            {
                openOrganizationCreationPanel &&
                <NewOrganizationSetup
                    attributes={attributes}
                    initialOrganizationName={initialOrganizationName}
                    previouslyActiveOrganization={previouslyActiveOrganization}
                    handleNext={() => {
                        setOpenOrganizationCreationPanel(false);
                        handleNext();
                    }}
                    handleOnClose={() => setOpenOrganizationCreationPanel(false)}
                />
            }
        </Flex>
    )
}