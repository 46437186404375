/* eslint-disable react/react-in-jsx-scope */
import img1 from 'assets/img/support-desk/reports/1.png';
import img2 from 'assets/img/support-desk/reports/2.png';
import img3 from 'assets/img/support-desk/reports/3.png';
import img4 from 'assets/img/support-desk/reports/4.png';
import img5 from 'assets/img/support-desk/reports/5.png';
import img6 from 'assets/img/support-desk/reports/6.png';
import img7 from 'assets/img/support-desk/reports/7.png';
import {
  FiAlertCircle,
  FiBriefcase,
  FiClock,
  FiFileText,
  FiGitPullRequest,
  FiHexagon,
  FiLock,
  FiRepeat,
  FiSunrise,
  FiUser,
  FiUserCheck,
  FiUsers
} from 'react-icons/fi';

export const reports = {
  general_support: [
    {
      title: 'FAQs',
      description: 'Learn from frequently asked questions',
      img: img1,
      link: "https://help.postly.ai/article/9-faq"
    },
    {
      title: 'Content Format',
      description: 'Learn about image and video formats',
      img: img2,
      link: "https://postly.ai/content-format/"
    },
    {
      title: 'Product Features',
      description: 'Postly has the best tools to manage your campaigns',
      img: img2,
      link: "https://postly.ai/features/"
    },
    {
      title: 'Bulk Upload',
      description: 'Learn how to use the Bulk Upload feature',
      img: img1,
      link: "https://postly.ai/bulk-post"
    },
    {
      title: 'Approval Workflow',
      description: 'Learn how to manage your teams and clients',
      img: img2,
      link: "https://postly.ai/approval-workflow/"
    },
    {
      title: 'Demo Videos',
      description: 'Learn how to connect platforms and publish',
      img: img2,
      link: "https://postly.ai/demo-videos/"
    }
  ],
  accountConnection: [
    {
      title: 'Facebook',
      description: 'Learn how to connect your Facebook accounts',
      img: img3,
      link: "https://postly.ai/facebook/"
    },
    {
      title: 'Instagram',
      description: 'Learn how to connect your Instagram accounts',
      img: img4,
      link: "https://postly.ai/instagram/"
    },
    {
      title: 'Twitter',
      description: 'Learn how to connect your Twitter accounts',
      img: img3,
      link: "https://postly.ai/twitter/"
    },
    {
      title: 'LinkedIn',
      description: 'Learn how to connect your LinkedIn accounts',
      img: img4,
      link: "https://postly.ai/linkedin/"
    },
    {
      title: 'YouTube',
      description: 'Learn how to connect your YouTube accounts',
      img: img3,
      link: "https://postly.ai/youtube/"
    },
    {
      title: 'Google My Business',
      description: 'Learn how to connect your GMB accounts',
      img: img4,
      link: "https://postly.ai/google-my-business/"
    },
    {
      title: 'Pinterest',
      description: 'Learn how to connect your Pinterest accounts',
      img: img3,
      link: "https://postly.ai/pinterest/"
    },
    {
      title: 'Reddit',
      description: 'Learn how to connect your Reddit accounts',
      img: img4,
      link: "https://postly.ai/reddit/"
    },
    {
      title: 'TikTok',
      description: 'Learn how to connect your TikTok accounts',
      img: img4,
      link: "https://postly.ai/tiktok/"
    },
    {
      title: 'WordPress',
      description: 'Learn how to connect your WordPress accounts',
      img: img4,
      link: "https://postly.ai/wordpress/"
    },
    {
      title: 'Telegram',
      description: 'Learn how to connect your Telegram accounts',
      img: img4,
      link: "https://postly.ai/telegram/"
    }
  ],
  productivity: [
    {
      title: 'Performance of Agents',
      description: 'Check out the report details',
      img: img5
    },
    {
      title: 'Performance in a Group',
      description: 'Check out the report details',
      img: img6
    },
    {
      title: 'Distribution of Performance',
      description: 'Check out the report details',
      img: img7
    }
  ]
};

export const team = [
  {
    icon: <FiUser className="text-primary fs-2" />,
    color: 'primary',
    title: 'Agents',
    description:
      "Define agents' scope of work, type, language, and other details."
  },
  {
    icon: <FiUsers className="text-info fs-2" />,
    color: 'info',
    title: 'Groups',
    description:
      'Agents can be organized and unattended tickets can be notified.'
  },
  {
    icon: <FiGitPullRequest className="text-warning fs-2" />,
    color: 'warning',
    title: 'Roles',
    description: 'Provide agents with fine-grained access and privileges.'
  },
  {
    icon: <FiClock className="text-success fs-2" />,
    color: 'success',
    title: 'Working Hours',
    description:
      'To set client expectations, define operating hours and holidays.'
  },
  {
    icon: <FiBriefcase className="text-primary fs-2" />,
    color: 'primary',
    title: 'Skills',
    description: 'Assign kinds of tickets to agents based on their expertise.'
  },
  {
    icon: <FiRepeat className="text-info fs-2" />,
    color: 'info',
    title: 'Agent Changes',
    description: 'Create and manage agent schedules all in one spot.'
  }
];

export const account = [
  {
    icon: <FiUserCheck className="text-primary fs-2" />,
    color: 'primary',
    title: 'Account Information',
    description:
      'View the status of your account as well as your invoice email address.'
  },
  {
    icon: <FiFileText className="text-info fs-2" />,
    color: 'info',
    title: 'Billing & Plans',
    description:
      'Plan, add-ons, team size, and billing cycle are all under your control.'
  },
  {
    icon: <FiSunrise className="text-warning fs-2" />,
    color: 'warning',
    title: 'Passes for the day',
    description: 'Part-time agents can purchase on-demand licenses.'
  },
  {
    icon: <FiLock className="text-success fs-2" />,
    color: 'success',
    title: 'Security',
    description:
      'Advanced SSO settings, password policy, and domain restriction.'
  },
  {
    icon: <FiHexagon className="text-primary fs-2" />,
    color: 'primary',
    title: 'Log of Audits',
    description:
      'All of the changes made to your falcon Support tickets account.'
  },
  {
    icon: <FiAlertCircle className="text-info fs-2" />,
    color: 'info',
    title: 'Configure the Helpdesk',
    description: 'Your Falcon Support Tickets will be personalized.'
  }
];
