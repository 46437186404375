import AppContext from "context/Context";
import Fraction from 'fraction.js';
import APIService from "http/api_service";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Modal, ProgressBar, Spinner } from "react-bootstrap";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { darkTheme, lightTheme } from "utils/theme";
import { EditPopUp } from "./styles/facebook";

import {
  getManuallyEditedPlatforms,
  setManuallyEditedPlatforms,
  setTriggerComponentRemount
} from "redux/slices/postslice";

import { getSelectedPlatformForPreview, setSelectedPlatformForPreview } from "redux/slices/platformSlice";
import { getMediaArrangement, setMediaArrangement } from "redux/slices/publishable_media_arrangement_slice";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { PlatformData, PlatformIdentifier } from "../../../../../constants";

const PlayMediaFb = (props) => {
  const {
    fullFile: item,
    file,
    editVideoFormat,
    platform,
    fileSizeError,
    viewOnly
  } = props;
  const videoRef = useRef(null);
  const dispatch = useDispatch();
  const video = document.getElementById("video");
  const [playing, setPlaying] = useState(false);
  const [comparableUrl, setComparableUrl] = useState("");
  const [validVideoType, setValidVideoType] = useState(true);
  const [videoInvalid, setVideoInvalid] = useState(false);
  const [videoMedia, setVideoMedia] = useState([]);
  const [formData, setFormData] = useState(null);
  const [videoSize, setVideoSize] = useState(null);
  const [progress, setProgress] = useState(0);
  const [fileTempUrl, setFileTempUrl] = useState("");
  const [transcodeText, setTranscodeText] = useState("");
  const [videoDuration, setVideoDuration] = useState(0);
  const [loadTranscoder, setLoadTranscoder] = useState(false);
  const [mediaFileError, setFileError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const linkedinVideoDuration = 1800;
  const { config } = useContext(AppContext);
  const manuallyEditedPlatforms = useSelector(getManuallyEditedPlatforms);
  const media_items_arrangement = useSelector(getMediaArrangement);
  const activePlatformTab = useSelector(getSelectedPlatformForPreview);

  const socialPlatformVideoType = ["mp4", "mov"];
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true)
  let activeWorkspace = useSelector(getActiveWorkSpace);

  useEffect(() => {
    if (platform === PlatformIdentifier.FACEBOOK) {
      setVideoMedia(media_items_arrangement?.facebook?.media)
    }
    if (platform === PlatformIdentifier.LINKED_IN) {
      setVideoMedia(media_items_arrangement?.linked_in?.media);
    }
    if (platform === PlatformIdentifier.TELEGRAM) {
      setVideoMedia(media_items_arrangement?.telegram?.media)
    }
  }, [platform, media_items_arrangement]);

  useEffect(() => {
    setTimeout(() => {
      const extension = file?.name?.split(".").pop().toLowerCase()
      const foundExtensionType = socialPlatformVideoType.find(element => element === extension);
      if (!foundExtensionType && file) {
        setValidVideoType(false);
      }
      if (!item?.url?.startsWith('https://firebasestorage.googleapis.com/v0/b/postly') && !file) {
        setValidVideoType(false);
      }
      checkVideoDimension(videoRef.current?.videoHeight, videoRef.current?.videoWidth);
    }, 1000);
    if (platform === 'linkedin') {
      if (videoDuration > linkedinVideoDuration || videoDuration < 3) {
        toast.error(`${platform} video length max is 1800 seconds`, {
          theme: "colored",
        });
      }
    }
    setTranscodeText('Getting Started')
  }, []);

  useEffect(() => {
    setFormData(item);
    setVideoSize(formData?.size / 1e6);
    setComparableUrl(item?.url)
    setFileTempUrl(item?.url);
    setTimeout(() => {
      if (!props?.checkingVideo) {
        setVideoInvalid(true);
        return;
      }
      switch (platform) {
        case PlatformIdentifier.LINKED_IN:
          if (file?.size > PlatformData.LINKEDIN_MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE) {
            setFileError(true);
          }
          break
        default:
          return
      }
    }, 1000);
  }, []);

  const checkVideoDimension = (height, width) => {
    let minVideoDimension = "9/16";
    let maxVideoDimension = "16/9";
    let decimalToConvert = new Fraction(
      width / height
    );
    let decimalToFraction = decimalToConvert.toFraction(true);
    const fractionStrToDecimal = str => str.split('/').reduce((p, c) => p / c);
    const mediaDimension = fractionStrToDecimal(decimalToFraction).toFixed(2);
    const minDimension = fractionStrToDecimal(minVideoDimension).toFixed(2)
    const maxDimension = fractionStrToDecimal(maxVideoDimension).toFixed(2);

    if (mediaDimension < minDimension || mediaDimension > maxDimension) {
      setVideoInvalid(true);
      return false;
    }
    return true;
  }

  function refreshTab() {
    const activeTab = activePlatformTab;
    dispatch(setTriggerComponentRemount('refresh'));
    setTimeout(() => {
      dispatch(setTriggerComponentRemount(activeTab ?? PlatformIdentifier.FACEBOOK))
    }, 2000);
  }
  const replaceMedia = (newMedia) => {
    const oldData = { ...media_items_arrangement };
    switch (platform) {
      case PlatformIdentifier.FACEBOOK:
        let dispatchPlatform = {
          ...oldData,
          facebook: {
            ...oldData.facebook, media: newMedia
          }
        }
        dispatch(setMediaArrangement(dispatchPlatform))
        break;
      case PlatformIdentifier.LINKED_IN:
        dispatchPlatform = {
          ...oldData,
          linked_in: {
            ...oldData.linked_in, media: newMedia
          }
        }
        dispatch(setMediaArrangement(dispatchPlatform))
        break;
      case PlatformIdentifier.TELEGRAM:
        dispatchPlatform = {
          ...oldData,
          telegram: {
            ...oldData.telegram, media: newMedia
          }
        }
        dispatch(setMediaArrangement(dispatchPlatform))
        break;
      default:
        break;
    }

    if (!manuallyEditedPlatforms[platform]) {
      if (platform === PlatformIdentifier.FACEBOOK) {
        const newData = { ...manuallyEditedPlatforms, facebook: true };
        dispatch(setManuallyEditedPlatforms(newData || {}));
      }
      if (platform === PlatformIdentifier.LINKED_IN) {
        const newData = { ...manuallyEditedPlatforms, linked_in: true };
        dispatch(setManuallyEditedPlatforms(newData || {}));
      }
      if (platform === PlatformIdentifier.TELEGRAM) {
        const newData = { ...manuallyEditedPlatforms, telegram: true };
        dispatch(setManuallyEditedPlatforms(newData || {}));
      }
    }
    setTimeout(() => {
      dispatch(setSelectedPlatformForPreview(platform));
    }, 1000);
  }


  const handleFaultyFileSize = () => {

    setShowModal(false);
    try {
      setLoadTranscoder(true);
      setProgress(0);
      const videoRequestBody = new FormData();
      let metaData = {
        PlatformIdentifier: platform,
      };

      if (!item?.external && item?.is_video) {
        videoRequestBody.append("file", formData?.file);
      }

      if (item?.external && item?.is_video) {
        let externalFileName = item?.url;
        metaData.video_url = externalFileName;
        videoRequestBody.append("data", JSON.stringify(metaData));
      }
      APIService.compressVideo(
        activeWorkspace._id,
        videoRequestBody,
        platform,
        !item?.external && videoSize, {
        uploadProgressListener: (uploadProgress) => {
          setProgress(uploadProgress);
          setTranscodeText("Uploading");
        },
        compressProgressListener: (transcodeProgress) => {
          setTranscodeText("Compressing");
          const loadCompressProgress = transcodeProgress.toFixed(2)
          const setCompressProgress = loadCompressProgress.split(".")[1];
          setProgress(setCompressProgress);
        },
        completionListener: async (result) => {
          setLoadTranscoder(false);
          setTranscodeText("Complete");
          setTimeout(() => {
            setLoadTranscoder(false);
          }, 2000);
          const newState = videoMedia.map((item) => {
            const newItem = (item.url === comparableUrl) ?
              { ...item, url: result?.data, optimized: true, external: true, uploaded: true, file: undefined } :
              { ...item, url: item.url, optimized: false };
            return newItem;
          })
          console.log(newState, "newState")
          setFileError(false);
          await replaceMedia(newState);
          await refreshTab();
          console.log(media_items_arrangement, 'new media')
        }
      })
    }
    catch (err) {
      console.log(err)
    }
  }

  const handlePlayPause = () => {
    if (video.paused) {
      video.play();
      setPlaying(true);
    } else {
      video.pause();
      setPlaying(false);
    }
  };

  let visibilityHandler = !playing ? "visible" : "hidden";

  const play = (
    <svg
      style={{ visibility: `${visibilityHandler}` }}
      xmlns="http://www.w3.org/2000/svg"
      fill="#dbdbdb"
      width="88"
      height="88"
      viewBox="0 0 24 24"
    >
      <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z" />
    </svg>
  );

  const promptCompressModal = showModal && (
    <>
      <Modal show={showModal} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Compress Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to compress video?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleFaultyFileSize}>
            Compress
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )

  const loadTranscodeProgress = (
    <div style={{
      backgroundColor: '#ffffff',
      position: 'absolute',
      zIndex: 10,
      top: '50%',
      width: "80%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: 'column',
      padding: "0.5em 1em",
      transform: "translate(35px, 10px)",
    }}>
      <div style={{ fontSize: '13px', marginBottom: '10px' }}>
        <span style={{ marginRight: '10px' }}>{`${transcodeText}`}</span>
        {(progress === 0 || isNaN(progress) || progress == null) ?
          <Spinner animation="border" size="sm" variant="success" /> :
          <span>{`${progress}%`}</span>}
      </div>
      <ProgressBar
        style={{
          width: '100%'
        }}
        striped
        variant="success"
        now={`${progress || 0}`}
      />
    </div>
  );

  const fixVideoFormatDialog = item?.is_video &&
    !validVideoType && (
      <div style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        backgroundColor: "#949ca4AA",
        zIndex: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "80%",
            backgroundColor: `${config.isDark
              ? darkTheme.workSpaceSettingsHeaderBar
              : lightTheme.emptyButtonActive
              }`,
            padding: 10,
            borderRadius: 10,
          }}
        >
          <EditPopUp
            style={{
              color: `${!config.isDark && "black"}`,
              fontSize: 13,
            }}
          >
            {editVideoFormat}
          </EditPopUp>
        </div>
      </div>
    );

  const fixVideoSize = item?.is_video && mediaFileError && (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        backgroundColor: "#949ca4AA",
        zIndex: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "80%",
            backgroundColor: `${config.isDark
              ? darkTheme.workSpaceSettingsHeaderBar
              : lightTheme.emptyButtonActive
              }`,
            padding: 10,
            borderRadius: 10,
          }}
        >
          <EditPopUp
            style={{
              color: `${!config.isDark && "black"}`,
              fontSize: 13,
            }}
          >
            {fileSizeError}
          </EditPopUp>
        </div>
      }
    </div>
  );

  return (
    <>
      <div>{!viewOnly && fixVideoSize}</div>
      <div>{!viewOnly && loadTranscoder && loadTranscodeProgress}</div>
      <div>{!viewOnly && fixVideoFormatDialog}</div>
      <div>{!viewOnly && promptCompressModal}</div>
      <div
        onClick={!props?.fullFile?.uploaded && handlePlayPause}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: 10,
          position: "relative",
          maxHeight: "350px",
        }}
      >
        {props?.fullFile?.uploaded ? (
          <ReactPlayer
            id="video"
            ref={videoRef}
            controls
            muted
            config={{
              file: {
                attributes: {
                  autoPlay: false,
                },
              },
            }}
            url={props?.url}
            width={"100%"}
            height={"100%"}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        ) : (
          <video
            ref={videoRef}
            onContextMenu={false}
            controls={video?.paused ? false : true}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 10,
              maxHeight: "350px",
              position: "relative",
              zIndex: `${video?.paused ? 0 : 2}`,
            }}
            id="video"
          >
            <source src={props?.url} type="video/mp4" />
          </video>
        )}
        {
          <div
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 10,
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: `${video?.paused ? 2 : -1}`,
            }}
          >
            {!props?.fullFile?.uploaded && play}
          </div>
        }
      </div>

      <div></div>
    </>
  );
};
export default PlayMediaFb;