import styled, { keyframes } from "styled-components";

const fadeInAnimation = keyframes`
from {
  opacity: 0;
  margin-left:-10%;
}
to {
  opacity: 1;
  margin-left:0%;
}
`;

export const Wrapper = styled.div`
  /* flex: 9; */
  /* height: fit-content; */
  width: 100%;
  position: relative;
`;


export const EditPopUp = styled.div`
  height:auto;
  padding:2%;
  width:100%;
  color:white;
  cursor:pointer;
  font-size:12px;
`;


export const ParentSection = styled.div`
  position: relative;
  display: flex;
  /* column-gap: 10px; */
  height: 100%;
  width: 100%;
  animation: ${fadeInAnimation} 0.3s linear;
  cursor: auto;
  justify-content: center;
  align-items: flex-start;
`;
export const PreviewContentArea = styled.div`
  /* border-style:solid;
  border-width:1px; */
  width: 100%;
  background-color: ${(props) => props.mainBgColor};
  /* z-index: 2; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-top: 10px;
  padding-top: 8px;
  /* margin-bottom: -60px; */
  animation: ${fadeInAnimation} 0.4s linear;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0 !important;
  }
`;

export const FirstLayer = styled.div`
  height: auto;
  width: 100%;
  min-width: 0px;
  display: flex;
  column-gap: 10px;
    padding:2px 5px;
`;

export const ImageHolder = styled.div`
  min-height: 25px;
  min-width: 25px;
  max-height: 25px;
  max-width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0px;
  border-style: solid;
  border-width: 2px;
  border-color: pink;
  padding: 2px;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
`;

export const Info = styled.div`
  height: auto;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 0px;
  font-size: 15px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const DotsBox = styled.div`
  height: 70px;
  padding: 0 2%;
  overflow: hidden;
  display: flex;
  min-width: 0px;
  align-items: flex-start;
  justify-content: center;
  position: relative;
`;

export const SecondLayer = styled.div`
  height: auto;
  width: 100%;
  margin-top: -20px;
  min-width: 0px;
  font-size: 16px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
export const BorrowedLayer = styled.div`
  height: auto;
  width: 100%;
  min-width: 0px;
  font-size: 16px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const ThirdLayer = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 0px;
  column-gap: 12px;
`;

export const LeftAction = styled.div`
  flex: 3;
  height: 100%;
  min-width: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  padding: 2%;
  font-size: 15px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const RightAction = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  min-width: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 15px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const FourthLayer = styled.div`
  height: auto;
  width: 100%;
  min-width: 0px;
`;

export const PostImage = styled.img`
  max-height: 200px;
  width: 100%;
  min-width: 0px;
`;
export const PencilEdit = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  min-width: 0px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
  
  :active {
    opacity:0;
  }
`;

export const VideoEdit = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: white;
  min-width: 0px;
  cursor: pointer;
  transition: transform 0.2s;
  
  :active {
    opacity:0;
  }
`;

export const ImageDelete = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  min-width: 0px;
  background-color: ${(props) => props.editBgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  color: white;
  right: 5%;
  top: 0%;
  cursor: pointer;
  transition: transform 0.2s;
  :active {
    opacity:0;
  }
`;

export const TextEdit = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0px;
  z-index: 10;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
  
  :active {
    opacity:0;
  }
`;

export const EditContainer = styled.div`
  min-height: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: center;
  gap:30px;
  border-radius: 8px;
  margin-top: 10px;
  flex: 1;
`;

// export const EditContainer = styled.div`
//   min-height: 150px;
//   min-width: 0px;
//   width: 100%;
//   background-color: ${(props) => props.editBgColor};
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   color: white;
//   border-radius: 8px;
//   margin-top: 10px;
//   flex: 1;
// `;
