import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import ReportExportDropdown from '../ReportExportDropdown';
import UsersByCountryChart from './UsersByCountryChart';

const UsersByCountry = (props) => {
  const {
    chartData,
    regionLoading,
    emptyDataMessage,
    title,
    tempData,
    reportData,
  } = props
  const [isDataEmpty, setDataEmpty] = useState(false);
  const [audienceTotalValue, setAudienceTotalValue] = useState(0);
  const chartRef = useRef(null);

  useEffect(() => {
    getChartDataLength();
  }, [chartData, isDataEmpty]);

  const getChartDataLength = () => {
    if (!regionLoading) {
      if (chartData?.length === 0) {
        setDataEmpty(true);
        return;
      }

      chartData?.forEach((item) => {
        if (!item.length) {
          setDataEmpty(true);
          return;
        }
        setDataEmpty(false);
      });
    }
  }

  useEffect(() => {
    const getAudienceTotalValue = !isDataEmpty && (chartData[1]?.reduce((acc, curr) => acc + curr, 0) ?? 1);
    setAudienceTotalValue(getAudienceTotalValue);
  }, [chartData, isDataEmpty]);

  return (
    <>
      <Card className="h-100">
        <FalconCardHeader
          title={title}
          titleTag="h6"
          className="py-2"
          light
          endEl={
            <ReportExportDropdown
              reportData={reportData}
              reportTitle={title}
              ref={chartRef}
            />}
        />
        <Card.Body className='py-5' style={{ backgroundColor: "#fff" }}>
          {regionLoading && <Flex
            alignItems={'center'}
            justifyContent={'center'}
            className={'vh-50'}
          >
            <Spinner animation='border' size={40} />
          </Flex>}
          {
            !regionLoading && (
              isDataEmpty ?
                <Flex alignItems={'center'} direction={'column'} justifyContent={'center'} className="py-5">
                  <h5>{emptyDataMessage}</h5>
                </Flex> :
                <div ref={chartRef}>
                  <UsersByCountryChart data={chartData} total={audienceTotalValue} />
                </div>
            )}
        </Card.Body>
      </Card>
    </>
  );
};

UsersByCountry.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.array).isRequired,
};

export default UsersByCountry;
