import React, { useState } from "react";
import WorkSpaceApprovalWorkFlow from "components/app/new-workspace/workspace_approval_setup";
import FalconCardHeader from "components/common/FalconCardHeader";
import {
  PERMISSION_UPDATE_WORKSPACE_APPROVAL_WORKFLOW,
  WorkSpaceContentApprovalWorkFlow,
} from "../../../../constants";
import APIService from "http/api_service";
import { Button, Card, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import Flex from "components/common/Flex";
import TooltipBadge from "components/common/TooltipBadge";
import {
  getActiveWorkSpace,
  setActiveWorkspace,
} from "redux/slices/workspaceslice";
import { useSelector, useDispatch } from "react-redux";
import { userCanPerformActionInWorkspace } from "utils/workspace_utils";
import Divider from "components/common/Divider";

const ApprovalSettings = (props) => {

  const dispatch = useDispatch();
  const { userRoleInWorkspace } = props;
  const workSpace = useSelector(getActiveWorkSpace);
  let workSpaceSettings = workSpace.settings ?? {};
  const [savingChanges, setSavingChanges] = useState(false);
  const [changesSaved, setChangesSaved] = useState(false);
  const lockContentAfterApproval = workSpaceSettings[WorkSpaceContentApprovalWorkFlow.LOCK_CONTENT_AFTER_APPROVAL_ON_ACTIVE_WORKSPACE] ?? false;
  const schedulePostsAutomaticallyOnApproval = workSpaceSettings[WorkSpaceContentApprovalWorkFlow.SCHEDULE_POSTS_AFTER_APPROVAL_ON_ACTIVE_WORKSPACE] ?? false;
  const approvalOption = workSpaceSettings[WorkSpaceContentApprovalWorkFlow.ACTIVE_WORKSPACE_APPROVAL_OPTION] ?? -1;
  const thirdApprovalOptionValue = workSpaceSettings[WorkSpaceContentApprovalWorkFlow.THIRD_APPROVAL_OPTION_VALUE];
  const passOwnersPostsThroughApproval = workSpaceSettings[WorkSpaceContentApprovalWorkFlow.ALLOW_OWNERS_POST_GO_THROUGH_APPROVAL] ?? false;
  const passAdminsPostsThroughApproval = workSpaceSettings[WorkSpaceContentApprovalWorkFlow.ALLOW_ADMINS_POST_GO_THROUGH_APPROVAL] ?? false;

  const handleApprovalOptionSelectionHandler = ({ index, approvalCountValue, variable, allowToGoThroughApproval }) => {
   console.log("🚀 ~ handleApprovalOptionSelectionHandler ~ variable:", variable)
   
    console.log("executing...");
    if (!variable) return;
    console.log("starting...");
    setSavingChanges(true);
    setChangesSaved(false);
    workSpaceSettings[
      WorkSpaceContentApprovalWorkFlow.ACTIVE_WORKSPACE_APPROVAL_OPTION
    ] = index;
    workSpaceSettings[variable] = allowToGoThroughApproval;
    if (approvalCountValue) {
      workSpaceSettings[
        WorkSpaceContentApprovalWorkFlow.THIRD_APPROVAL_OPTION_VALUE
      ] = approvalCountValue;
    }
      console.log("🚀 ~ handleApprovalOptionSelectionHandler ~ workSpaceSettings:", workSpaceSettings)
    APIService.updateWorkSpaceSettings(
      workSpace["_id"],
      workSpaceSettings,
      (response, error) => {
        if (error) {
          toast.error(error, {
            theme: "colored",
          });
          return;
        }
        toast.success("Changes saved", { theme: "colored" });
        let { data } = response;
        dispatch(setActiveWorkspace({ ...data }));
        setSavingChanges(false);
        setChangesSaved(true);
      }
    );
  }

  const applyToAllWorkspaceSettingsHandler = () => {
    setSavingChanges(true);
    setChangesSaved(false);
    APIService.applyToAllWorkspaces(workSpace?._id, workSpace.settings, "approval_workflow_settings", async (response, error) => {
      if (error) {
        toast.error(error, { theme: 'colored' });
        setSavingChanges(false);
        return;
      }
      const { message, data } = response;
      APIService.fetchWorkSpace(workSpace?._id, (response, error) => {
        if (error) {
          return;
        }
        if (response) {
          let responseData = response['data'];
          let workSpace = responseData['workspace'];
          workSpace['role'] = responseData['role'];
          workSpace['value'] = workSpace['name'];
          workSpace['title'] = responseData['title'];
          workSpace['label'] = workSpace['name'];
          workSpace['workspace_owner'] = responseData['workspace_owner'];
          dispatch(setActiveWorkspace({ ...workSpace }));
          setSavingChanges(false);
          setChangesSaved(true);
          toast.success(message, { theme: "colored" })
        }
      });
    })
  }

  return (
    <Card className="mb-3 mt-3">
      <FalconCardHeader
        title={
          <h5>
            Approval workflow
            <TooltipBadge
              tooltip="This settings would be applied when you schedule or publish posts under this workspace"
              icon="question-circle"
            />
          </h5>
        }
        endEl={
          <>
            {savingChanges && (
              <Flex className={"gap-2"}>
                <Spinner animation="border" size={"sm"} variant={"primary"} />
                <p
                  style={{
                    fontSize: 12,
                    color: "#B0BEC5",
                  }}
                >
                  Saving changes...
                </p>
              </Flex>
            )}
            {changesSaved && (
              <p
                style={{
                  fontSize: 12,
                  color: "#9CCC65",
                }}
              >
                Changes Saved
              </p>
            )}
          </>
        }
      />
      <Card.Body className="bg-light">
        <WorkSpaceApprovalWorkFlow
          userRoleInWorkspace={userRoleInWorkspace}
          editable={userCanPerformActionInWorkspace(
            workSpace,
            PERMISSION_UPDATE_WORKSPACE_APPROVAL_WORKFLOW
          )}
          approvalOption={approvalOption}
          thirdApprovalOptionValue={thirdApprovalOptionValue}
          passOwnersPostsThroughApproval={passOwnersPostsThroughApproval}
          passAdminsPostsThroughApproval={passAdminsPostsThroughApproval}
          lockContentAfterApproval={lockContentAfterApproval}
          schedulePostsAfterApproval={schedulePostsAutomaticallyOnApproval}
          titleFontSize={20}
          handleApprovalOptionSelectionHandler={
            handleApprovalOptionSelectionHandler
          }
          desc={"Choose a suitable approval workflow for this workspace."}
          postsApprovalHandler={(variable, allow) => {
            handleApprovalOptionSelectionHandler({
              index: approvalOption,
              approvalCountValue: thirdApprovalOptionValue || null,
              variable: variable,
              allowToGoThroughApproval: allow
          });
          }}
        />
        <Divider />
        <Flex
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p>Apply this change to all workspaces</p>
          <Button
            onClick={applyToAllWorkspaceSettingsHandler}
            disabled={savingChanges}
          >
            {"Apply"}
          </Button>
        </Flex>
      </Card.Body>
    </Card>
  );
}

export default ApprovalSettings;
