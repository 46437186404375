import React from 'react';
import NavbarDropdown from './NavbarDropdown';
import {
  settingsRoutes,
  toolsRoute,
  accountRoutes,
} from 'routes/routes';
import NavbarDropdownApp from './NavbarDropdownApp';
import NavbarDropdownPages from './NavbarDropdownPages';
import NavbarDropdownModules from './NavbarDropdownModules';

const NavbarTopDropDownMenus = () => {

  return (
    <>
      <NavbarDropdown title="workspace">
        <NavbarDropdownApp items={settingsRoutes.children} />
      </NavbarDropdown>

      <NavbarDropdown title="tools">
        <NavbarDropdownPages items={toolsRoute.children} />
      </NavbarDropdown>

      <NavbarDropdown title="account">
        <NavbarDropdownModules items={accountRoutes.children} />
      </NavbarDropdown>
      <NavbarDropdown title="account">
        <p>Hi</p>
      </NavbarDropdown>
    </>
  );

};

export default NavbarTopDropDownMenus;
