import React from 'react';
import ResultPreview from "./result_preview";

const ResultsPreview = React.forwardRef((props, ref) => {

    const {
        generatedCopiesTitle,
        allMode = false,
        referenceTag,
        results,
        fetchSavedCopies,
        referenceTool,
        workSpace,
        selectedTheme,
        canShowTool = false
    } = props;

    return (
        <div
            ref={ref}
            style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
            {
                !allMode &&
                <h2
                    style={{
                        textAlign: 'center',
                        padding: 10,
                        fontSize: 20
                    }}>{generatedCopiesTitle}
                </h2>
            }
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 15
                }}>
                {
                    results.map((x) => {
                        let { ref, ...others } = x;
                        return (
                            <ResultPreview
                                key={x.text} {...others}
                                selectedTheme={selectedTheme}
                                fetchSavedCopies={fetchSavedCopies}
                                referenceTag={x.ref ?? referenceTag}
                                referenceTool={x.ref ?? referenceTool}
                                workSpace={workSpace}
                                canShowTool={canShowTool}
                            />
                        );
                    })
                }
            </div>
        </div>
    )
});
export default ResultsPreview;