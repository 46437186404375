import React from 'react';
import { PlatformIdentifier } from 'constants';
import { useEffect, useState } from 'react';
import TelegramLoginButton from 'react-telegram-login';
import { toast } from 'react-toastify';
import appLogo from 'assets/img/logos/postly_logo.png';
import ReactLoading from 'react-loading';
import { lightTheme } from '../utils/theme';
import classNames from 'classnames';
import EndPoints from '../http/endpoints';
import HttpErrorHandler from '../utils/http_error_handler';
import axios from "axios";

export default function TelegramLogInProxy() {

    const [successMessage, setSuccessMessage] = useState();
    const [processing, setProcessing] = useState(false);
    const [darkMode, setDarkMode] = useState(false);
    const darkColor = '#509BFC'; 
    const lightColor = '#060606';
    const fontSize = 22;
    const name = 'POSTLY';

    useEffect(() => {
        const workspaceId = localStorage.getItem("tele_workspace_id");
        const apiKey = localStorage.getItem("tele_api_key");
        const queryParams = new URLSearchParams(window.location.search.replace("amp;", ""));
        if (!workspaceId || !apiKey) {
            let workSpace = queryParams.get('workspaceId');
            let key = queryParams.get('key');
            if (workSpace) {
                localStorage.setItem("tele_workspace_id", decodeURIComponent(workSpace));
            }
            if (key) {
                localStorage.setItem("tele_api_key", decodeURIComponent(key));
            }
        }
        const theme = queryParams.get("theme");
        if (theme) {
            if (theme === 'dark') {
                setDarkMode(true);
            } else {
                setDarkMode(false);
            }
        }
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search.replace("amp;", ""));
        const success = queryParams.get("success");
        if (success) {
            setSuccessMessage(decodeURIComponent(success));
            localStorage.removeItem("tele_workspace_id");
            localStorage.removeItem("tele_api_key");
        }
    }, []);

    const handleTelegramResponse = response => {
        console.log("🚀 ~ handleTelegramResponse ~ response:", response)
        let { id: userId, photo_url: userPhoto, username } = response;
        const workSpace = localStorage.getItem("tele_workspace_id");
        const key = localStorage.getItem("tele_api_key");
        if (!workSpace || !key) {
            toast.error("Sorry, unable to validate workspace/key", { theme: 'colored' });
            return;
        }
        setProcessing(true);
        const requestBody = {
            name: "Telegram",
            identifier: PlatformIdentifier.TELEGRAM,
            user_id: userId,
            username,
            user_photo: userPhoto,
        };
        axios.post(EndPoints.ADD_NEW_WORKSPACE_PLATFORM.replace(":workSpaceId", workSpace), requestBody, {
            headers: { "X-API-Key": key },
        }).then((_response) => {
            console.log("🚀 ~ axios.post ~ success:", _response)
            setProcessing(false);
            const successMsg = "Connected successfully! You may close this window now";
            setSuccessMessage(successMsg);
            window.location = `${window.location.href}&success=${encodeURIComponent(successMsg)}`
        }).catch((e) => {
            console.log("🚀 ~ axios.post ~ e:", e)
            setProcessing(false);
            toast.error(HttpErrorHandler.spitHttpErrorMsg(e), { theme: 'colored' });
        });
    }

    return (
        <div
            style={{
                flexDirection: 'column',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                background: darkMode ? "#0b1727" : 'white',
                paddingTop: 100
            }}>
            {
                successMessage ? successMessage :
                    processing ? <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}>
                        <ReactLoading type='bubbles' color={lightTheme.primary} width={30} height={30} />
                        <span>Please wait...</span>
                    </div> :
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                gap: 8
                            }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 3
                                }}>
                                <img
                                    className="me-2"
                                    src={appLogo}
                                    alt="Logo"
                                    width={60}
                                    height={50}
                                />
                                <span
                                    style={{
                                        color: darkMode ? darkColor : lightColor,
                                        fontSize: fontSize,
                                        textAlign: 'center',
                                        fontWeight: 'bold'
                                    }}
                                    className={classNames('font-sans-serif')}>{name}
                                </span>
                            </div>
                            <h5 style={{
                                padding: 18,
                                color: darkMode ? 'white' : 'black'
                            }}>Click button below to continue</h5>
                            <TelegramLoginButton
                                dataOnauth={handleTelegramResponse}
                                botName="postly_bot"
                                widgetVersion={22}
                            />
                        </div>
            }
        </div>
    );

}