import styled, { keyframes } from "styled-components";

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
    margin-left:-10%;
  }
  to {
    opacity: 1;
    margin-left:0%;
  }
`;

export const ParentSection = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  animation: ${fadeInAnimation} 0.3s linear;
  cursor: auto;
  justify-content: center;
  align-items: flex-start;
`;

export const Wrapper = styled.div`
  /* flex: 9; */
  /* height: fit-content; */
  width: 100%;
  position: relative;
`;

export const PreviewContentArea = styled.div`
  /* border-style:solid;
  border-width:1px; */
  width: 100%;
  background-color: ${(props) => props.mainBgColor};
  /* z-index: 2; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-top: 10px;
  padding-top: 8px;
  /* margin-bottom: -60px; */
  animation: ${fadeInAnimation} 0.4s linear;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0 !important;
  }
`;

export const SecondLayer = styled.div`
  height: fit-content;
  width: 100%;
  max-width: 100%;
  position: relative;
  padding: 1% 0 30px;
  font-size: 11px;
  @media only screen and (max-width: 768px) {
    font-size: 9px;
  }
`;

export const ThirdLayer = styled.div`
  min-height: 60px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 12px;
`;

export const LeftAction = styled.div`
  flex: 3;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  padding: 2%;
  font-size: 15px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const RightAction = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
  align-items: center;
  font-size: 18px;
  @media only screen and (max-width: 768px) {
    font-size: 15px;
  }
`;

export const FourthLayer = styled.div`
  height: auto;
  width: 100%;
`;

export const ImageDelete = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  color: white;
  right: 5%;
  top: -5%;
  cursor: pointer;
  transition: transform 0.2s;
  
  :active {
    opacity:0;
  }
`;

export const PencilEdit = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
  
  :active {
    opacity:0;
  }
`;

export const VideoEdit = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
  
  :active {
    opacity:0;
  }
`;
export const PostText = styled.div`
  font-size: 16px;
  @media only screen and (max-width: 768px) {
    font-size: 13px;
  }
`;

export const ParentGallary = styled.ul`
  margin: 0;
  padding: 0;
  height: fit-content;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  column-gap: 3px;
  row-gap: 3px;
`;

export const ChildGallary = styled.li`
  min-height: 20vh;
  flex-grow: 1;
  flex: 25%;
  position: relative;
`;
export const PostImage = styled.img`
  max-width: 100%;
  min-height: 100%;
  display: block;
  object-fit: cover;
  vertical-align: top;
`;

export const TextEdit = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
  
  :active {
    opacity:0;
  }
`;

export const EditContainer = styled.div`
  min-height: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: center;
  gap:10px;
  border-radius: 8px;
  margin-top: 10px;
  flex: 1;
`;