import styled, { css } from 'styled-components';

export const PollItemLegend = styled.legend`
border:none;
`;

export const PollItemFieldSet = styled.fieldset`
border-color:#2c7be5;
border-style:solid;
border-width:1px;
border-radius:5px;
border:none;
`;

export const PollFeedItemOption = styled.div`
color:#2c7be5;
border: 1px solid #2c7be5;
border-radius:100px;
`;
