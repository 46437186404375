import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    pageId: null,
    postId: null,
    userId: null,
    period: {
        since: null,
        until: null
    },
    report: {
        title: null,
        data: []
    },
    platformIdentifier: null,
    selectedPage: {},
    pdfClicked: false,
    canvasData: [],
    canvasComplete: false,
    overviewData: [],
    insightData: [],
    audienceData: [],
    openExportModal: false,
    pdfLoading: false,
    activeMetric: null,
    collectMetric: null
}
export const analyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        setPageId: (state, action) => {
            state.pageId = action.payload;
        },
        setTopPostId: (state, action) => {
            state.postId = action.payload;
        },
        setAccountUserId: (state, action) => {
            state.userId = action.payload;
        },
        setDatePeriod: (state, action) => {
            state.period = action.payload;
        },
        setReport: (state, action) => {
            state.report = action.payload;
        },
        setPlatformIdentifier: (state, action) => {
            state.platformIdentifier = action.payload;
        },
        setSelectedPageForAnalytics: (state, action) => {
            state.selectedPage = action.payload;
        },
        setPdfClicked: (state, action) => {
            state.pdfClicked = action.payload;
        },
        setCanvasData: (state, action) => {
            state.canvasData = action.payload;
        },
        setCanvasComplete: (state, action) => {
            state.canvasComplete = action.payload;
        },
        setOverviewData: (state, action) => {
            state.overviewData = action.payload;
        },
        setInsightData: (state, action) => {
            state.insightData = action.payload;
        },
        setAudienceData: (state, action) => {
            state.audienceData = action.payload;
        },
        setOpenExportModal: (state, action) => {
            state.openExportModal = action.payload;
        },
        setPDFLoading: (state, action) => {
            state.pdfLoading = action.payload;
        },
        setActiveMetric: (state, action) => {
            state.activeMetric = action.payload;
        },
        setCollectMetric: (state, action) => {
            state.collectMetric = action.payload;
        },
    }
});
export const getPageId = (state) => state.analytics.pageId;
export const getAccountUserId = (state) => state.analytics.userId;
export const getDatePeriod = (state) => state.analytics.period;
export const getTopPostId = (state) => state.analytics.postId;
export const getReport = (state) => state.analytics.report;
export const getPlatformIdentifier = (state) => state.analytics.platformIdentifier;
export const getSelectedPageForAnalytics = (state) => state.analytics.selectedPage;
export const getPdfClicked = (state) => state.analytics.pdfClicked;
export const getCanvasData = (state) => state.analytics.canvasData;
export const getCanvasComplete = (state) => state.analytics.canvasComplete;
export const getOverviewData = (state) => state.analytics.overviewData;
export const getInsightData = (state) => state.analytics.insightData;
export const getAudienceData = (state) => state.analytics.audienceData;
export const getOpenExportModal = (state) => state.analytics.openExportModal;
export const getPDFLoading = (state) => state.analytics.pdfLoading;
export const getActiveMetric = (state) => state.analytics.activeMetric;
export const getCollectMetric = (state) => state.analytics.collectMetric;
export const {
    setPageId,
    setTopPostId,
    setAccountUserId,
    setDatePeriod,
    setReport,
    setPlatformIdentifier,
    setSelectedPageForAnalytics,
    setPdfClicked,
    setCanvasData,
    setCanvasComplete,
    setOverviewData,
    setInsightData,
    setAudienceData,
    setOpenExportModal,
    setPDFLoading,
    setActiveMetric,
    setCollectMetric
} = analyticsSlice.actions;

export default analyticsSlice.reducer;