import RichInfoBar from 'components/common/RichInfoBar'
import React from 'react'
import { Card } from 'react-bootstrap'

const CustomTelegramBotComponent = (props) => {
    const { initCustomization, workSpaceTelegramBotData } = props

    if (workSpaceTelegramBotData?.availableBots) {
        return <Card style={{ paddingTop: 15 }}>
            <RichInfoBar
                title={`${workSpaceTelegramBotData.availableBots} custom Telegram bot${workSpaceTelegramBotData.availableBots > 1 ? "s" : ""} available`}
                message={
                    `You have ${workSpaceTelegramBotData.availableBots} custom Telegram bot${workSpaceTelegramBotData.availableBots > 1 ? "s" : ""} available. Do you want a customized bot with your logo and custom name for your business?`
                }
                secondaryActionMessage={"CLICK HERE TO GET A NEW BOT SLOT"}
                secondaryAction={() => {
                    initCustomization(true);
                }}
                actionMessage={"CLICK HERE TO CUSTOMIZE"}
                action={initCustomization}
            />
        </Card>
    }

    return (
        <Card style={{ paddingTop: 15 }}>
            <RichInfoBar
                title="Get a custom Telegram Bot"
                message={
                    "Do you want a customized bot with your logo and custom name for your business?"
                }
                actionMessage={"CLICK HERE TO CUSTOMIZE"}
                action={initCustomization}
            />
        </Card>
    )
}

export default CustomTelegramBotComponent