import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import AppContext from 'context/Context';
import CreatePost from '../social/feed/CreatePost';
import { useDispatch } from 'react-redux';
import { setCalendarPostTime, setCanExpandPublisherActions, setEditablePost, setPostNow, setSelectedPlatforms } from 'redux/slices/postslice';
import { setPublishablePostTextBody } from 'redux/slices/post_text_body_slice';

const ScheduleNewPostModal = ({
  setIsOpenCreatePostModal,
  isOpenCreatePostModal,
  scheduleStartDate,
  setScheduleStartDate,
  scheduleEndDate,
  setScheduleEndDate,
  publishFeedBack,
  ...others
}) => {

  const {
    config: { isDark }
  } = useContext(AppContext);

  const dispatch = useDispatch();

  const handleClose = () => {
    setIsOpenCreatePostModal(!isOpenCreatePostModal);
    dispatch(setCalendarPostTime(null))
    dispatch(setEditablePost(null));
    dispatch(setPostNow(true));
    dispatch(setPublishablePostTextBody(""));
    dispatch(setSelectedPlatforms([]));
    dispatch(setCanExpandPublisherActions(false));
    localStorage.removeItem("updatable_post_defaults_set");
    localStorage.removeItem("post_to_republish_defaults_set");
  };

  return (
    <Modal
      show={isOpenCreatePostModal}
      onHide={handleClose}
      size={'lg'}
      centered
      contentClassName="border"
    >
      <Modal.Header
        closeButton
        closeVariant={isDark ? 'white' : undefined}
        className="bg-light px-card border-bottom-0"
      >
        <Modal.Title as="h5">Schedule New Post</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-card">
        <CreatePost {...others} publishFeedBack={publishFeedBack} />
      </Modal.Body>
    </Modal>
  );
};

ScheduleNewPostModal.propTypes = {
  setIsOpenCreatePostModal: PropTypes.func.isRequired,
  isOpenCreatePostModal: PropTypes.bool.isRequired,
  setInitialEvents: PropTypes.func,
  initialEvents: PropTypes.array,
  scheduleStartDate: PropTypes.instanceOf(Date),
  setScheduleStartDate: PropTypes.func.isRequired,
  scheduleEndDate: PropTypes.instanceOf(Date),
  setScheduleEndDate: PropTypes.func.isRequired
};

export default ScheduleNewPostModal;
