import React from 'react';
import PropTypes from 'prop-types';
import { Card, Image } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import classNames from 'classnames';
import FeedGallery from 'components/app/social/feed/FeedGallery';
import FalconLightBox from 'components/common/FalconLightBox';
import { isPhoto, isVideo } from 'utils/file_utils';
import ContinousExpansionTextView from './ContinuousExpansionTextView';
import FeedCardPoll from './poll/FeedCardPoll';
import FeedCategoryView from './FeedCategoryView';

const FeedCardContent = ({ data, fromPostDetailsDialog }) => {

  const { body, mediaUrls = [], search, poll_data, ...others } = data;

  const getSingleImage = () => {
    if (mediaUrls) {
      let mediaLength = mediaUrls.length;
      if (mediaLength > 0) {
        let firstImage = mediaUrls.find(mediaUrl => isPhoto(mediaUrl));
        if (firstImage && mediaUrls.length === 1) {
          return firstImage;
        }
      }
    }
    return null;
  }

  const getSingleVideo = () => {
    if (mediaUrls) {
      let mediaLength = mediaUrls.length;
      if (mediaLength > 0) {
        if (mediaLength === 1) {
          let firstVideo = mediaUrls.find(mediaUrl => isVideo(mediaUrl));
          return firstVideo;
        }
      }
    }
    return null;
  }

  const getAllFiles = () => {
    if (mediaUrls) {
      let mediaLength = mediaUrls.length;
      if (mediaLength > 0) {
        let images = mediaUrls.filter(mediaUrl => isPhoto(mediaUrl));
        let videos = mediaUrls.filter(mediaUrl => isVideo(mediaUrl));
        return { images, videos };
      }
    }
    return null
  }

  const imgSrc = getSingleImage();
  const video = getSingleVideo();
  const gallery = getAllFiles();

  if (fromPostDetailsDialog) {
    return (
      <div class="parent_constraint">
        <Card.Body className={classNames({ 'p-0': false })}>
          <FeedCategoryView
            post={others}
            fromPostDetailsDialog={fromPostDetailsDialog}
          />
          {
            body && <ContinousExpansionTextView
              search={search ?? ""}
              text={(poll_data ? (poll_data.question) : body) ?? ""}
              inputStyle={{
                width: '100%'
              }}
              fromPostDetailsDialog={fromPostDetailsDialog}
            />
          }
          {
            poll_data &&
            <FeedCardPoll polls={poll_data.choices} />
          }
          {
            imgSrc && (
              <FalconLightBox image={imgSrc}>
                <Image src={imgSrc} fluid rounded />
              </FalconLightBox>
            )
          }
          {
            !imgSrc && !video && gallery && <FeedGallery 
            files={gallery} 
            fromPostDetailsDialog={fromPostDetailsDialog}
            />
          }
          {
            video && (
              <ReactPlayer url={video} controls={true} className="react-player" />
            )
          }
        </Card.Body>
      </div>
    );
  }

  return (
    <>
    <div class="parent_constraint"
      style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        width: '100%',
        height: '100%',
      }}
    >
      <Card.Body

        className={classNames({ 'p-0': false })}
      >
        <FeedCategoryView post={others} />
        {
          body && <ContinousExpansionTextView
            search={search ?? ""}
            text={(poll_data ? (poll_data.question) : body) ?? ""}
            inputStyle={{
              width: '100%'
            }}
          // style={{
          //   overflowY:'auto'
          // }}
          />
        }
        {
          poll_data &&
          <FeedCardPoll polls={poll_data.choices} />
        }
        {
          imgSrc && (
            <FalconLightBox image={imgSrc}>
              <Image src={imgSrc} fluid rounded style={{
                maxWidth: 200,
                maxHeight: 130
              }} />
            </FalconLightBox>
          )
        }
        {
          !imgSrc && !video && gallery && <FeedGallery files={gallery} />
        }
        {
          video && (
            <ReactPlayer url={video} controls={true} className="react-player" style={{
              maxWidth: 200,
              maxHeight: 130
            }} />
          )
        }
      </Card.Body>
    </div>
    </>
  );
};

FeedCardContent.propTypes = {
  status: PropTypes.string,
  imgSrc: PropTypes.string,
  gallery: PropTypes.array,
  feedEvent: PropTypes.object,
  url: PropTypes.object,
  video: PropTypes.object
};

export default FeedCardContent;
