export default function PollItem(props) {
    const { question = "Poll", polls } = props;
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: 10,
            position: 'relative'
        }}>
            <fieldset>
                <legend
                    style={{
                        padding: 10,
                    }}>{question}</legend>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    gap: 10,
                }}>
                    {
                        polls.map((poll) => {
                            return (
                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    padding: 5,
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}><span>{poll}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </fieldset>
        </div>
    );
}