import FalconDropzone from 'components/common/FalconDropzone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import Flex from 'components/common/Flex';
import { isIterableArray } from 'helpers/utils';
import Avatar from 'components/common/Avatar';
import useOrganizationStore from 'state/organization_store';

export default function WorkspaceLogoPicker() {

    const { t } = useTranslation();
    const inputId = 'workspace-being-created-logo-picker';
    const workspace = useOrganizationStore(state => state.workspaceForOrganizationBeingCreated);
    const setWorkspaceForOrganizationBeingCreated = useOrganizationStore(state => state.setWorkspaceForOrganizationBeingCreated);

    const handleAvatarClick = () => {
        let element = document.getElementById(inputId);
        if (element) {
            element.click();
        }
    }

    return (
        <Flex alignItems={'center'} className={'flex-wrap gap-2'}>
            {
                workspace?.avatar &&
                <Flex
                    className='w-100'
                    alignItems={'center'}
                    justifyContent={'center'}
                    onClick={handleAvatarClick}
                >
                    <Avatar
                        size="4xl"
                        rounded='2'
                        className='flex-grow-1'
                        src={
                            typeof workspace?.avatar === 'string' ? workspace.avatar : isIterableArray(workspace?.avatar) ? workspace?.avatar[0]?.base64 || workspace?.avatar[0]?.src : ''
                        }
                    />
                </Flex>
            }
            <FalconDropzone
                inputId={inputId}
                className={'flex-grow-1'}
                files={workspace?.avatar}
                onChange={files => {
                    let workSpaceCpy = workspace ?? {};
                    setWorkspaceForOrganizationBeingCreated({ ...workSpaceCpy, avatar: files });
                }}
                multiple={false}
                accept="image/*"
                placeholder={
                    <>
                        <Flex justifyContent="center">
                            <img src={cloudUpload} alt="" width={25} className="me-2" />
                            <p className="fs-9 mb-0 text-700">
                                {t('optional_workspace_logo')}
                            </p>
                        </Flex>
                        <p className="mb-0 w-75 mx-auto text-400">
                            {t('image_min_300x300', { file: t('logo').toLowerCase() })}
                        </p>
                    </>
                }
            />
        </Flex>
    )
}
