import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
  Tab,
  TabPane,
  TabsContainer,
  Tabs,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Shortener from "./link_shortener/Shortener";
import Flex from "components/common/Flex";
import APIService from "http/api_service";
import { getActiveWorkSpace, setSearchHint } from "redux/slices/workspaceslice";
import FreePageHeader from "components/common/FreePageHeader";
import { toast } from "react-toastify";
import { getDomains, setAutoShortenerStatus, setBitlyToken, setBitlyTokenArray, setDomains, setLinksFolders } from "redux/slices/links_shortener_slice";
import Settings from "./link_shortener/Settings";
import CustomDomain from "./link_shortener/CustomDomain";
import Library from "./link_shortener/Library";
import Integrations from "./link_shortener/Integrations";
import ConfirmModal from "components/common/ConfirmModal";
import { RoutePaths } from "constants";
import { useRef } from "react";
import useProtectedRoute from "hooks/useProtectedRoute";
import { setPopupUpgradeAlert } from "redux/slices/alertSlice";

const LinkShortener = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tabRef = useRef();
  const domains = useSelector(getDomains);
  console.log("🚀 ~ LinkShortener ~ domains:", domains)


  const [workSpaceOwner, setWorkspaceOwner] = useState();
  const [tab, setTab] = useState(1);
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const workSpace = useSelector(getActiveWorkSpace);
  const { status } = useProtectedRoute(['free'], `${RoutePaths.BILLING_STRIPE}/#schedulerSection001`)


  const fetchWorkspaceOwner = () => {
    APIService.fetchWorkSpaceOwner(workSpace._id, (response, error) => {
      if(error){
        toast.error(error, {theme: "colored"})
        console.log("🚀 ~ APIService.fetchWorkSpaceOwner ~ error:", error)
      }
      if (response) {
        let { data } = response;
        setWorkspaceOwner(data);
      }
    });
  };

  const fetchFoldersHandler = async (e) => {
    APIService.fetchFolders((response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      let { data } = response;
      dispatch(setLinksFolders(data));
    });
  };

  const getStatusHandler = async () => {
    APIService.getAutoShortenedLinkStatus((response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      let { data } = response;
      //   toast.success(message);
      dispatch(setAutoShortenerStatus(data));
    });
  };

  const getAllDomain = async () => {
    // setOnloading(true);
    APIService.fetchDomains((response, error) => {
      if (error) {
        console.log(error);
        // setOnloading(false);
        toast.error(error, { theme: "colored" });
        return;
      }
      // setOnloading(false);
      const { data, message } = response;
      if (!data) {
        if (message !== "No domain available") {
          toast.error(message, { theme: "colored" });
        }
        return;
      }
      dispatch(setDomains(data));
      // setCustomDomains(data);
    });
  };


  const getToken = async () => {
    APIService.getBitlyToken((response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      let { data, dataArray } = response;
      //   toast.success(message);
      dispatch(setBitlyToken(data));
      dispatch(setBitlyTokenArray(dataArray));
    });
  };

  useEffect(() => {
    dispatch(setSearchHint("Search..."));
    getToken()
    getAllDomain()
    fetchWorkspaceOwner();
    fetchFoldersHandler();
    getStatusHandler()
  }, []);

  const linkShortenerTabs = [
    { tab: 1, name: "Shortener" },
    { tab: 2, name: "My library" },
    { tab: 3, name: "Integrations" },
    { tab: 4, name: "Settings" },
  ];

  const toggleTab = (tab) => () => {
    toast.success(typeof tab);
    switch (tab) {
      case 1:
        setTab(1);
        break;
      case 2:
        setTab(2);
        break;
      case 3:
        setTab(3);
        break;
      case 4:
        setTab(4);
        break;
      default:
        break;
    }
  };

  const TabComponent = () => {
    switch (tab) {
      case 1:
        return <Shortener tabRef={tabRef} setTab={setTab} />;
        // return <Shortener tabRef={tabRef} setTab={setTab} />;
      case 2:
        return <Library />;
      // case 3:
      //   return <Integrations />;
      // case 4:
      //   return <CustomDomain />;
      // case 5:
      //   return <CustomDomain />;
      default:
        return;
    }
  };

  const userOnFreePlan = () => {
    let activePlan = workSpaceOwner?.active_plan;
    if (
      activePlan?.Name?.toLowerCase() === "free" ||
      activePlan?.Key?.toLowerCase() === "free"
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      {/* {!workSpaceOwner || domains === null && (
        <Flex
          alignItems={"center"}
          style={{
            height: "100vh",
          }}
          justifyContent={"center"}
        >
          <Spinner animation="border" />
        </Flex>
      )} */}

      {!workSpaceOwner && (
        <Flex
          alignItems={"center"}
          style={{
            height: "100vh",
          }}
          justifyContent={"center"}
        >
          <Spinner animation="border" />
        </Flex>
      )}
      
      
      {workSpaceOwner && (
        <Flex direction={"column"}>
          <FreePageHeader titleTag="h5" className="mb-1">
            <Flex style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <h5>Link Shortener</h5>
                
              </div>
            </Flex>
            <p className="fs--1 mt-1">
              Shorter links are usually better to work with
            </p>
            <Tabs
              activeKey={tab}
              id="uncontrolled-tab-example"
              transition={true}
              className="mb-3"
              onSelect={(tab) => {
                  if (Number(tab) > 1 && userOnFreePlan() === true) {
                    dispatch(setPopupUpgradeAlert(true))
                    return;
                  }
                setTab(Number(tab))
              }}
            >
              <Tab eventKey={1} title="Shortener" />
              <Tab
                eventKey={2}
                title="My Library"
              />
              {/* <Tab
                eventKey={3}
                title="Integrations"
                
              />
              <Tab
                eventKey={4}
                ref={tabRef}
                title="Custom domain"
                
              /> */}
            </Tabs>
          </FreePageHeader>
          <Card
            className="mt-2"
            style={{
              minHeight: "80vh",
            }}
          >
            <div>{TabComponent()}</div>
          </Card>
        </Flex>
      )}

      <ConfirmModal
        open={openUpgradeDialog}
        title={"Action requires Upgrade"}
        message={
          "This feature requires that you are on a premium account"
        }
        confirmText={"UPGRADE"}
        cancelText={"CANCEL"}
        onCancel={() => {
          setOpenUpgradeDialog(false);
        }}
        onConfirm={() => {
          setOpenUpgradeDialog(false);
          navigate(`${RoutePaths.BILLING_STRIPE}`);
        }}
      />
    </>
  );
};

export default LinkShortener;
