import React, { useEffect, useState } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  cachePosts, getCachedPosts, getMunchedPostTargetIds, setAboutRepublishingPost, setActiveBatchDelete,
  setCurrentDraft,
  setEditablePost, setFacebookCarouselLinks, setFirstComment, setGMBCTAActionType, setGMBCTAActionURL, setGMBCouponCode, setGMBEventSchedule, setGMBEventTitle, setGMBPostType, setGMBRedeemURL, setGMBTermsAndConditions, setIsFacebookPostCarousel, setIsFacebookPostReel, setIsFacebookPostStory, setIsYouTubeVideoMadeForKids, setManuallyEditedPlatforms, setOpenPostDetailsDialog, setPinterestAltText, setPinterestDestinationLink, setPinterestTitle, setPinterestVideoThumbnail, setPlatformFirstComment, setPostToRepublish, setPublishIGImageAsStories, setPublishIGVideoAsReel, setPublishIGVideoAsStories, setRedditTitle, setSelectedPlatforms, setShouldNotifyYouTubeSubscribers, setStitchAllowTiktok, setThumbnailPreviewData, setTiktokAllowComments, setTiktokAllowDuet, setTiktokSelectedViewSetting, setTiktokVideoTitle, setYouTubeVideoComment, setYouTubeVideoEmbeddable, setYouTubeVideoLicense, setYouTubeVideoPrivacy, setYouTubeVideoTags, setYouTubeVideoTitle
} from 'redux/slices/postslice';
import { useSelector, useDispatch } from 'react-redux';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import { faEdit, faPause, faPlay, faTrashArrowUp } from '@fortawesome/free-solid-svg-icons';
import ConfirmModal from 'components/common/ConfirmModal';
import PostDeletePrompt from './PostDeletePrompt';
import { copyToClipboard } from 'utils/browser';
import Divider from 'components/common/Divider';
import { PlatformIdentifier, RoutePaths } from '../../../../constants';
import { faPushed } from '@fortawesome/free-brands-svg-icons';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { useNavigate } from 'react-router-dom';
import { setMediaArrangement } from 'redux/slices/publishable_media_arrangement_slice';
import { setPublishablePostMedia } from 'redux/slices/post_media_slice';
import { arrangeSelectPlatforms } from 'utils/platform_utils';
import { preparePostToEdit } from 'utils/post_utils';
import { isFreeWorkspace } from 'utils/workspace_utils';
import { setPopupUpgradeAlert } from 'redux/slices/alertSlice';
import { setPublishablePostTextBody } from 'redux/slices/post_text_body_slice';

export const getLengthOfItemsInObject = (obj) => {
  // check if obj is an object
  if (typeof obj !== 'object') {
    return 0;
  }
  return Object.keys(obj).length;
}

export const isEditingDisabled = (post) => {
  if (post?.data?.disapprovals) {
    return false;
  }
  if (post?.data?.approvals) {
    return `This post have been approved by ${getLengthOfItemsInObject(post?.data?.approvals)} user${getLengthOfItemsInObject(post?.data?.approvals) > 1 ? 's' : ''}. So you can't edit it.`;
  }
  if (post?.recurring || post?.data?.repeat_option) {
    return false;
  }
  if (post.drafted) {
    return false;
  }
  if (post.posted) {
    return `This post has been posted and can't be edited.`;
  }
  return false;
}

const FeedDropdown = ({ newUpdateHandler, align = "end", enableView = true, openDraft, ...others }) => {

  const [post, setPost] = useState({ ...others });
  const [openBatchDeleteConfirmationDialog, setOpenBatchDeleteConfirmationDialog] = useState(false);
  const cachedPosts = [...useSelector(getCachedPosts)];
  const workSpace = useSelector(getActiveWorkSpace);
  const [deletingPost, setDeletingPost] = useState(false);
  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);
  const [openDeleteFromPostlyPrompt, setOpenDeleteFromPostlyPrompt] = useState(false);
  const [openDeleteFromPlatformsPrompt, setOpenDeleteFromPlatformsPrompt] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const munchedTargetIds = [...useSelector(getMunchedPostTargetIds)];

  const pausePost = (pause) => {
    post.paused = pause;
    APIService.pausePost(workSpace._id, post._id, pause ? "yes" : "no", (response, error) => {
      if (error) {
        toast.error(error, { theme: 'colored' });
        return;
      }
      let { message } = response;
      toast.success(message, { theme: 'colored' });
      setPost({ ...post });
      let tempPosts = localStorage.getItem("cached_posts") ? JSON.parse(localStorage.getItem("cached_posts")) : [];
      let existingPostsCopy = [...tempPosts];
      let existingPost = existingPostsCopy.find((x) => x.data.post_group_identifier === post.data.post_group_identifier);
      if (!existingPost) {
        if (existingPostsCopy.length > 0) {
          existingPostsCopy.unshift(post);
        } else {
          existingPostsCopy.push(post);
        }
      } else {
        let indexOfPost = existingPostsCopy.indexOf(existingPost);
        if (indexOfPost !== -1) {
          existingPostsCopy[indexOfPost] = { ...post };
        }
      }
      dispatch(cachePosts([]));
      dispatch(cachePosts([...existingPostsCopy]));
    });
  }

  const openDraftPost = () => {
    dispatch(setOpenPostDetailsDialog({ open: false, data: null }));
    post?.data?.manuallyEditedPlatforms && dispatch(setManuallyEditedPlatforms(post?.data?.manuallyEditedPlatforms || {}));
    dispatch(setCurrentDraft(post));
    dispatch(setPublishablePostMedia(post?.data?.mediaObjects));
    dispatch(setPlatformFirstComment(post?.data?.platformFirstComment));
    dispatch(setMediaArrangement(post?.data?.mediaItemsArrangement));
    dispatch(setIsFacebookPostCarousel(post?.data?.isFacebookPostCarousel));
    dispatch(setFacebookCarouselLinks(post?.data?.facebookCarouselLinks));
    dispatch(setPublishIGImageAsStories(post.data.canImageBePublishedAsStory));
    dispatch(setPublishIGVideoAsStories(post.data.canVideoBePublishedAsStory));
    dispatch(setPublishIGVideoAsReel(post.data.canVideoBePublishedAsReel));
    dispatch(setFirstComment(post?.data?.firstComment));
    dispatch(setPublishablePostTextBody(post?.data?.body));
    // extras
    dispatch(setGMBPostType(post?.data?.gmbPostType));
    dispatch(setGMBCTAActionURL(post?.data?.gmbCtaActionURL))
    dispatch(setGMBCTAActionType(post?.data?.gmbCtaActionType));
    dispatch(setGMBCouponCode(post?.data?.gmbCouponCode));
    dispatch(setGMBRedeemURL(post?.data?.gmbRedeemURL));
    dispatch(setGMBTermsAndConditions(post?.data?.gmbTermsAndConditions));
    dispatch(setGMBEventSchedule(post?.data?.gmbEventSchedule));
    dispatch(setGMBEventTitle(post?.data?.gmbEventTitle));
    dispatch(setYouTubeVideoTitle(post?.data?.youtubeVideoTitle));
    dispatch(setYouTubeVideoComment(post?.data?.youtubeVideoComment));
    dispatch(setShouldNotifyYouTubeSubscribers(post?.data?.notifyYouTubeSubscribers));
    dispatch(setYouTubeVideoPrivacy(post?.data?.youtubeVideoPrivacy));
    dispatch(setThumbnailPreviewData(post?.data?.youTubeVideoThumbnail));
    dispatch(setYouTubeVideoLicense(post?.data?.youTubeVideoLicense));
    dispatch(setYouTubeVideoTags(post?.data?.youTubeVideoTags));
    dispatch(setIsYouTubeVideoMadeForKids(post?.data?.madeForKids));
    dispatch(setYouTubeVideoEmbeddable(post?.data?.YouTubeVideoEmbeddable));
    dispatch(setRedditTitle(post?.data?.redditTitle));
    dispatch(setPinterestTitle(post?.data?.pinterestTitle));
    dispatch(setPinterestAltText(post?.data?.pinterestAltText));
    dispatch(setPinterestDestinationLink(post?.data?.pinterestDestinationLink));
    dispatch(setPinterestVideoThumbnail(post?.data?.pinterestVideoThumbnail));
    dispatch(setTiktokVideoTitle(post?.data?.tiktokVideoTitle));
    dispatch(setTiktokAllowComments(post?.data?.tiktokAllowComment));
    dispatch(setTiktokAllowDuet(post?.data?.tiktokAllowDuet));
    dispatch(setStitchAllowTiktok(post?.data?.stitchAllowTiktok));
    dispatch(setTiktokSelectedViewSetting(post?.data?.selectedViewSetting));
    dispatch(setIsFacebookPostReel(post?.data?.isFacebookPostReel));
    dispatch(setIsFacebookPostStory(post?.data?.isFacebookPostStory));
    // dispatch(setSelectedPlatforms(post?.data?.selectedPlatforms));
    arrangeSelectPlatforms(post?.data?.selectedPlatforms, munchedTargetIds, dispatch)
    if (post?.data?.poll_data) {
      navigate(RoutePaths.SCHEDULE_A_POLL);
    } else {
      navigate(RoutePaths.POSTS);
    }
    dispatch(setEditablePost(post));
    dispatch(setAboutRepublishingPost(false));
  }

  useEffect(() => {
    if (openDraft) {
      openDraftPost()
    }
  }, [openDraft])


  const deleteFromPostly = () => {
    setDeletingPost(true);
    APIService.deleteWorkSpacePostFromOnlyPostly(workSpace._id, post._id, (response, error) => {
      setDeletingPost(false);
      if (error) {
        toast.error(error, { theme: 'colored' });
        return;
      }
      let postMark = cachedPosts.find((x) => x?._id?.toString() === post?._id?.toString());
      if (postMark) {
        let indexOfPostMark = cachedPosts.indexOf(postMark);
        if (indexOfPostMark !== -1) {
          let postsClone = [...cachedPosts];
          postsClone.splice(indexOfPostMark, 1);
          dispatch(cachePosts(postsClone));
          toast.success(`${post.drafted ? 'Draft' : 'Post'} deleted from only Postly`, { theme: 'colored' });
          if (others.onDeleteDoneCallback) {
            others.onDeleteDoneCallback(postMark);
          }
        }
      } else {
        toast.success(`${post.drafted ? 'Draft' : 'Post'} deleted from only Postly, refreshing page.`, { theme: 'colored' });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    });
  }

  const deleteFromPublishedPlatforms = () => {
    setDeletingPost(true);
    APIService.deleteWorkSpacePostFromOnlyPlatformsItWasPublishedTo(workSpace._id, post._id, (response, error) => {
      setDeletingPost(false);
      if (error) {
        toast.error(error, { theme: 'colored' });
        return;
      }
      let { message } = response;
      toast.success(message, { theme: 'colored' });
    });
  }

  const deletePost = (isDraft) => {
    setDeletingPost(true);
    if (isDraft) {
      APIService.deleteDraftedPost(workSpace._id, post?.uniqueContentDraftId, (response, error) => {
        setDeletingPost(false);
        if (error) {
          toast.error(error, { theme: 'colored' });
          return;
        }
        let postMark = cachedPosts.find((x) => x._id === post._id);
        setDeletingPost(false);
        if (postMark) {
          let indexOfPostMark = cachedPosts.indexOf(postMark);
          if (indexOfPostMark !== -1) {
            let postsClone = [...cachedPosts];
            postsClone.splice(indexOfPostMark, 1);
            dispatch(cachePosts(postsClone));
            toast.success(`${post.drafted ? 'Draft' : 'Post'} deleted`, { theme: 'colored' });
            if (others.onDeleteDoneCallback) {
              others.onDeleteDoneCallback(postMark);
            }
          }
        }
        toast.success('Operation successful', {
          theme: 'coloured'
        })
        window.location.reload();
      });
      return;
    }
    APIService.deletePost(workSpace._id, post._id, (response, error) => {
      setDeletingPost(false);
      if (error) {
        toast.error(error, { theme: 'colored' });
        return;
      }
      let postMark = cachedPosts.find((x) => x._id === post._id);
      if (postMark) {
        let indexOfPostMark = cachedPosts.indexOf(postMark);
        if (indexOfPostMark !== -1) {
          let postsClone = [...cachedPosts];
          postsClone.splice(indexOfPostMark, 1);
          dispatch(cachePosts(postsClone));
          toast.success(`${post.drafted ? 'Draft' : 'Post'} deleted`, { theme: 'colored' });
          if (others.onDeleteDoneCallback) {
            others.onDeleteDoneCallback(postMark);
          }
        }
      }
      toast.success('Operation successful', {
        theme: 'coloured'
      })
      window.location.reload();
    });
  }

  const deletePostBatch = (batch_id) => {
    toast.info("Deleting your bulk posts may take a while. Please don't refresh the page until some are deleted", { theme: 'colored', position: 'bottom-center' });
    dispatch(setActiveBatchDelete({ [batch_id]: true }));
    APIService.deletePostBatch(workSpace._id, batch_id, (response, error) => {
      if (error) {
        dispatch(setActiveBatchDelete({}));
        toast.error(error, { theme: 'colored' });
        return;
      }
      let deletedPosts = cachedPosts.filter(x => x?.data?.batch_id !== batch_id);
      if (deletedPosts) {
        dispatch(setActiveBatchDelete({}));
        dispatch(cachePosts(deletedPosts));
        toast.success("Post deleted successfully with siblings!", { theme: 'colored' });
      }
    });
  }

  const displayEditButtonLogic = () => {

  }

  return (
    <Dropdown className="font-sans-serif btn-reveal-trigger">
      <Dropdown.Toggle variant="link" size="sm" className="px-1 text-600">
        <FontAwesomeIcon icon="ellipsis-h" />
      </Dropdown.Toggle>
      <Dropdown.Menu align={align}>
        {
          enableView &&
          <Dropdown.Item
            as={'span'}
            className="cursor-pointer"
            onClick={() => {
              let location = window.location;
              let postDetailsPath = `${location.origin}/${workSpace._id}/posts/${post._id}`;
              window.history.pushState("", "", postDetailsPath);
              dispatch(setOpenPostDetailsDialog({ open: true, data: post._id }));
            }}>View
          </Dropdown.Item>
        }
        {
          // !isEditingDisabled(post) && 
          <Dropdown.Item
            as={'span'}
            className="cursor-pointer"
            title={isEditingDisabled(post) ? isEditingDisabled(post) : ''}
          >
            <span
              onClick={() => {
                if (isFreeWorkspace(workSpace)) {
                  dispatch(setPopupUpgradeAlert(true))
                  return;
                }
                if (isEditingDisabled(post)) {
                  return;
                }
                if (post.drafted) {
                  openDraftPost();
                } else {
                  dispatch(setOpenPostDetailsDialog({ open: false, data: null }));
                  const approval_option = workSpace?.settings?.approval_option;
                  if (approval_option !== 0 && post?.pending_approval && !post?.data?.disapprovals) {
                    toast.info("You cannot edit a post that is currently undergoing approval", { theme: 'colored' });
                    return;
                  }
                  if (post?.data?.poll_data) {
                    navigate(RoutePaths.SCHEDULE_A_POLL);
                  } else {
                    dispatch(setEditablePost(post));
                    dispatch(setAboutRepublishingPost(false));
                    preparePostToEdit(post)
                  }
                }
              }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 4,
                  color: isEditingDisabled(post) ? '#bcc0c5' : ''
                }}>
                <FontAwesomeIcon icon={faEdit} />
                <span>{post.drafted ? 'Edit Draft' : post?.data?.poll_data ? "Edit Poll" : "Edit Post"}</span>
              </div>
            </span>
          </Dropdown.Item>
        }
        {
          post.posted && <Dropdown.Item as={'span'} className="cursor-pointer">
            <span
              onClick={() => {
                if (post?.data?.poll_data) {
                  navigate(RoutePaths.SCHEDULE_A_POLL);
                } else {
                  // navigate(RoutePaths.POSTS);
                }
                dispatch(setOpenPostDetailsDialog({ open: false, data: null }));
                // dispatch(setEditablePost(post));
                dispatch(setAboutRepublishingPost(true));
                preparePostToEdit(post)
              }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 4,
                }}>
                <FontAwesomeIcon icon={faPushed} />
                <span>{"Republish post"}</span>
              </div>
            </span>
          </Dropdown.Item>
        }
        <Dropdown.Divider />
        <Dropdown.Item as={'span'} className="cursor-pointer text-danger">
          <span onClick={(e) => {
            if (post.posted) {
              let canAutoDeletePost = localStorage.getItem("can_auto_delete_post") ?? 'false';
              if (canAutoDeletePost === 'false') {
                setOpenDeletePrompt(true);
              } else {
                deletePost(false);
              }
            } else if (post.drafted) {
              deletePost(true);
            } else {
              deletePost(false);
            }
          }}>
            {deletingPost ? 'Deleting...' : `${post.drafted ? 'Delete draft' : `${post.posted ? 'Delete everywhere' : 'Delete post'}`}`}
          </span>
        </Dropdown.Item>
        {
          post.posted &&
          <Dropdown.Item as={'span'} className="cursor-pointer text-warning">
            <span
              onClick={() => {
                let canAutoDeletePost = localStorage.getItem("postly_can_auto_delete_post") ?? 'false';
                if (canAutoDeletePost === 'false') {
                  setOpenDeleteFromPostlyPrompt(true);
                } else {
                  deleteFromPostly();
                }
              }}>
              {deletingPost ? "Deleting..." : "Delete from Postly"}
            </span>
          </Dropdown.Item>
        }
        {
          post.posted &&
          <Dropdown.Item as={'span'} className="cursor-pointer text-info">
            <span onClick={() => {
              let canAutoDeletePost = localStorage.getItem("platforms_can_auto_delete_post") ?? 'false';
              if (canAutoDeletePost === 'false') {
                setOpenDeleteFromPlatformsPrompt(true);
              } else {
                deleteFromPublishedPlatforms();
              }
            }}>
              {deletingPost ? "Deleting..." : "Delete from platforms"}
            </span>
          </Dropdown.Item>
        }
        {
          (post.recurring || post?.data?.repeat_option) && !post.paused &&
          <Dropdown.Item as={'span'} className="cursor-pointer">
            <span
              onClick={(e) => {
                pausePost(true);
              }}>
              {deletingPost ? 'Removing...' : `Pause recurring`}
            </span>
          </Dropdown.Item>
        }
        {
          post?.data?.batch_id &&
          <Dropdown.Item as={'span'} className="cursor-pointer text-danger">
            <span
              onClick={() => {
                setOpenBatchDeleteConfirmationDialog(true);
              }}>
              <div
                style={{
                  dosplay: 'flex',
                  alignItems: 'center',
                }}>
                <FontAwesomeIcon icon={faTrashArrowUp} />
                <span
                  style={{
                    paddingLeft: 10
                  }}>
                  Delete with Bulk Upload Siblings
                </span>
              </div>
            </span>
          </Dropdown.Item>
        }
        {
          post.paused &&
          <Dropdown.Item as={'span'} className="cursor-pointer text-danger">
            <span
              onClick={() => {
                pausePost(false);
              }}>
              <div
                style={{
                  dosplay: 'flex',
                  alignItems: 'center',
                }}>
                <FontAwesomeIcon icon={faPlay} />
                <span
                  style={{
                    paddingLeft: 10
                  }}>
                  Resume
                </span>
              </div>
            </span>
          </Dropdown.Item>
        }
        {
          !post.paused && !post.posted && !post.drafted &&
          <Dropdown.Item as={'span'} className="cursor-pointer">
            <span
              onClick={() => {
                pausePost(true);
              }}>
              <div
                style={{
                  dosplay: 'flex',
                  alignItems: 'center',
                }}>
                <FontAwesomeIcon icon={faPause} />
                <span
                  style={{
                    paddingLeft: 10
                  }}>
                  Pause
                </span>
              </div>
            </span>
          </Dropdown.Item>
        }
        <Divider />
        <Dropdown.Item as={'span'} className="cursor-pointer">
          <span
            onClick={() => {
              let location = window.location;
              copyToClipboard(`${location.origin}/${workSpace._id}/posts/${post._id}`);
              toast.success("Link to post copied", { theme: "colored" });
            }}>
            Copy Link
          </span>
        </Dropdown.Item>
        <ConfirmModal
          open={openBatchDeleteConfirmationDialog}
          title={"Delete with siblings?"}
          message={`This post is part of a group uploaded as bulk posts. When you hit the <b>YES, DELETE THEM ALL</b> button, we will delete all the posts it was pushed out together with.`}
          confirmText={"YES, DELETE THEM ALL"}
          cancelText={"CANCEL"}
          onCancel={() => {
            setOpenBatchDeleteConfirmationDialog(false);
          }}
          onConfirm={() => {
            setOpenBatchDeleteConfirmationDialog(false);
            deletePostBatch(post?.data?.batch_id);
          }}
        />
        <Modal
          centered={false}
          show={openDeletePrompt}
          onClose={() => {
            setOpenDeletePrompt(false);
          }}
          onHide={() => {
            setOpenDeletePrompt(false);
          }}
          onExit={() => {
            setOpenDeletePrompt(false);
          }}
        >
          <Modal.Body>
            <PostDeletePrompt
              prompt={"Deleting this post will delete it from both Postly Servers and the platforms it was published to."}
              canDeleteCallBack={() => {
                setOpenDeletePrompt(false);
                deletePost(false);
              }}
              showExtraInfoDialogue={true}
              cancelledCallback={() => {
                setOpenDeletePrompt(false);
              }}
              igIncluded={post?.data?.targets?.find(x => x.identifier === PlatformIdentifier.INSTAGRAM)}
            />
          </Modal.Body>
        </Modal>
        <Modal
          centered={false}
          show={openDeleteFromPostlyPrompt}
          onHide={() => {
            setOpenDeleteFromPostlyPrompt(false);
          }}
          onExit={() => {
            setOpenDeleteFromPostlyPrompt(false);
          }}
        >
          <Modal.Body>
            <PostDeletePrompt
              prompt={"This post will be deleted from only Postly servers and not from the platforms it was published to."}
              promptIdentifier={"postly_"}
              showExtraInfoDialogue={false}
              canDeleteCallBack={() => {
                setOpenDeleteFromPostlyPrompt(false);
                deleteFromPostly();
              }}
              cancelledCallback={() => {
                setOpenDeleteFromPostlyPrompt(false);
              }}
            />
          </Modal.Body>
        </Modal>
        <Modal
          centered={false}
          show={openDeleteFromPlatformsPrompt}
          onHide={() => {
            setOpenDeleteFromPlatformsPrompt(false);
          }}
          onExit={() => {
            setOpenDeleteFromPlatformsPrompt(false);
          }}
        >
          <Modal.Body>
            <PostDeletePrompt
              prompt={"This post will be deleted from only the Platforms it was published to and not from Postly servers."}
              promptIdentifier={"platforms_"}
              showExtraInfoDialogue={true}
              canDeleteCallBack={() => {
                setOpenDeleteFromPlatformsPrompt(false);
                deleteFromPublishedPlatforms();
              }}
              igIncluded={post?.data?.targets?.find(x => x.identifier === PlatformIdentifier.INSTAGRAM)}
              cancelledCallback={() => {
                setOpenDeleteFromPlatformsPrompt(false);
              }}
            />
          </Modal.Body>
        </Modal>
      </Dropdown.Menu>
    </Dropdown >
  );
};

FeedDropdown.propTypes = {};

export default FeedDropdown;
