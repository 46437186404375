/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Flex from './Flex';

export default function SubmitButton({
  className,
  disabled,
  processing,
  processingView,
  label,
  ...others
}) {
  const { t } = useTranslation();
  return (
    <Button
      className={className}
      type="submit"
      disabled={disabled || processing}
      {...others}
    >
      <Flex alignItems={'center'} justifyContent={'center'} className={'gap-2'}>
        {processing ? (processingView ?? <Spinner size="sm" animation="border" role="status" />) : <></>}
        {processing ? processingView ? <></> : t('please_wait') : label}
      </Flex>
    </Button>
  );
}
