import Flex from 'components/common/Flex'
import React from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import { IoMdAdd } from 'react-icons/io'
import { getActiveWorkSpace } from 'redux/slices/workspaceslice'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

const AddPrompt = (props) => {

    const {
        setTitle,
        title,
        description,
        setDescription,
        source,
        setSource,
        prompt,
        setPrompt,
        ispublic,
        setIspublic,
        isAdding,
        savePrompt
    } = props

    return (
        <Flex
            direction={'column'}
            width="100%"
            gap={3}
            className="mt-4"
        >
            <Form.Group>
                <Form.Label>
                    Enter Title
                </Form.Label>
                <Form.Control
                    type={'text'}
                    value={title}
                    onChange={e => {
                        setTitle(e.target.value);
                    }}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    Enter Description
                </Form.Label>
                <Form.Control
                    type={'text'}
                    as={'textarea'}
                    value={description}
                    onChange={e => {
                        setDescription(e.target.value);
                    }}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    Enter Prompt
                </Form.Label>
                <Form.Control
                    type={'text'}
                    as={'textarea'}
                    value={prompt}
                    onChange={e => {
                        setPrompt(e.target.value);
                    }}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    Enter Source
                </Form.Label>
                <Form.Control
                    type={'text'}
                    value={source}
                    onChange={e => {
                        setSource(e.target.value);
                    }}
                />
            </Form.Group>

            <Form.Group>
                <Form.Label>
                    Make public
                </Form.Label>
                <Form.Select
                    value={ispublic}
                    onChange={(e) =>
                        setIspublic(e.target.value)
                    }>
                    <option value={'true'}>Yes</option>
                    <option value={'false'}>No</option>
                </Form.Select>
            </Form.Group>
            <Button
                className="mb-3 mt-2"
                variant={'primary'}
                disabled={isAdding}
                onClick={savePrompt}
            >
                {
                    isAdding ? <Spinner animation='border' size={30} /> :
                        <><IoMdAdd />  Add prompt</>
                }
            </Button>
        </Flex>
    )
}

export default AddPrompt