import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useRef } from 'react'
import { useEffect } from 'react';

const PopDrop = (props) => {
    const ref = useRef(null)

    useEffect(() => {
        if(!props?.close) return;
        ref.current.click();
        props?.setPopDropClose(false);
    }, [props?.close])

    const {
        dropDownAnchor,
        PopContainer,
        width = 450,
        alignment = "end",
    } = props;

    return (
        <Dropdown className="btn-reveal-trigger p-0">
            <Dropdown.Toggle
                ref={ref}
                variant="link"
                size="sm"
                className="p-0 m-0 text-decoration-none text-600"
            >
                {dropDownAnchor}
            </Dropdown.Toggle>
            <Dropdown.Menu className="text-600 p-0 m-0 dropdown-menu-end dropdown-caret" align={alignment}>
                <div style={{ width: width }}>
                    {PopContainer}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );

}

PopDrop.propTypes = {
    dropDownHeaderTitle: PropTypes.string,
    prompt: PropTypes.node
};
export default PopDrop;
