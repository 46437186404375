import React, { useState } from 'react';
import { Button, Card, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import StringUtils from 'utils/string';
import { toast } from 'react-toastify';
import APIService from 'http/api_service';
import { PERMISSION_UPDATE_WORKSPACE_NAME } from '../../../../constants';
import CopyLinkButton from 'components/common/copylinkbutton';
import Flex from 'components/common/Flex';
import { copyToClipboard } from 'utils/browser';
import { getActiveWorkSpace, setActiveWorkspace } from 'redux/slices/workspaceslice';
import { useSelector, useDispatch } from 'react-redux';
import { userCanPerformActionInWorkspace } from 'utils/workspace_utils';
import WorkspaceBanner from '../WorkspaceBanner';
import coverSrc from 'assets/img/icons/spot-illustrations/authentication-corner.png';

const WorkspaceSettings = () => {

  const dispatch = useDispatch();
  const activeWorkspace = useSelector(getActiveWorkSpace);
  const [updatingWorkSpaceName, setUpdatingWorkspaceName] = useState(false);
  const [cloneIcon, setCloneIcon] = useState('clone');
  const [copyWorkspaceIdTip, setCopyWorkspaceIdPrompt] = useState("Copy Workspace Id");

  const [formData, setFormData] = useState({
    name: activeWorkspace?.name,
    id: activeWorkspace?._id,
    role: StringUtils.capitalizeString(activeWorkspace?.role)
  });

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    updateWorkSpaceName(formData);
  };

  const updateWorkSpaceName = (values) => {
    const workSpaceName = values.name;
    if (!workSpaceName) {
      toast.error('Enter a new name for your workspace', { theme: 'colored' });
      return;
    }
    setUpdatingWorkspaceName(true);
    APIService.updateWorkSpaceName(activeWorkspace['_id'], workSpaceName, (response, error) => {
      setUpdatingWorkspaceName(false);
      if (error) {
        toast.error(error, { theme: 'colored' });
        return;
      }
      toast.success(response['message'], { theme: 'colored' });
      dispatch(setActiveWorkspace({
        ...activeWorkspace,
        name: workSpaceName
      }));
    });
  }

  return (
    <Card>
      <FalconCardHeader title="Workspace details" />
      <Card.Body >

      <WorkspaceBanner>
          <WorkspaceBanner.Header
              coverSrc={coverSrc}
          />
        </WorkspaceBanner>      
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Workspace name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Workspace name"
              value={formData.name}
              name="name"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="id">
            <Form.Label>Workspace ID</Form.Label>
            <Flex className={'position-relative'}>
              <Form.Control
                type="text"
                placeholder="Workspace ID"
                value={formData.id}
                name="id"
                contentEditable={false}
              />
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip id="workspace-overlay-trigger">
                    {copyWorkspaceIdTip}
                  </Tooltip>
                }
              >
                <span
                  style={{
                    right: 10,
                    top: 5,
                    position: 'absolute',
                    outline: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    display: 'block',
                  }}>
                  <CopyLinkButton
                    iconButton
                    icon={cloneIcon}
                    handleCopy={() => {
                      copyToClipboard(activeWorkspace?._id);
                      setCloneIcon('check-circle');
                      setCopyWorkspaceIdPrompt("Workspace Id Copied!");
                      setTimeout(() => {
                        setCloneIcon('clone');
                        setCopyWorkspaceIdPrompt("Copy Workspace ID");
                      }, 2000);
                    }}
                  />
                </span>
              </OverlayTrigger>
            </Flex>
          </Form.Group>
          <Form.Group className="mb-3" controlId="role">
            <Form.Label>Your role</Form.Label>
            <Form.Control
              type="text"
              disabled
              placeholder="Workspace role"
              value={formData.role}
              name="role"
              contentEditable={false}
            />
          </Form.Group>
          <div className="text-end">
            {
              userCanPerformActionInWorkspace(activeWorkspace, PERMISSION_UPDATE_WORKSPACE_NAME) &&
              <Button variant="primary"
                type="submit"
                disabled={updatingWorkSpaceName || !formData.name}>
                {updatingWorkSpaceName ? "Please wait..." : "Update"}
              </Button>
            }
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default WorkspaceSettings;
