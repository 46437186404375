import React, { useState } from 'react';
import { munchTarget } from '../audience';
import {
    getMunchedPostTargetIds,
    setMunchedPostTargetIds,
} from '../../../../../redux/slices/postslice';
import { useSelector, useDispatch } from 'react-redux';
import { BoardSectionEntryContainer } from './styles';

export default function BoardSectionsPage(props) {

    const dispatch = useDispatch();
    const { sections, target } = props;
    const [selectedSection, setSelectedSection] = useState(sections.find(section => section.selected) ?? {});
    const sectionChangeHandler = props.sectionChangeHandler;
    const munchedTargetIds = [...useSelector(getMunchedPostTargetIds)];

    const handleSectionSelection = (section) => {
        let munchedTarget = munchTarget(section.id, target);
        let indexOfMunchedTarget = munchedTargetIds.indexOf(munchedTarget);
        if (selectedSection['id'] === section['id']) {
            setSelectedSection({});
            section['selected'] = false;
            if (indexOfMunchedTarget !== -1) {
                munchedTargetIds.splice(indexOfMunchedTarget, 1);
            }
            if (sectionChangeHandler) {
                sectionChangeHandler(section);
            }
        } else {
            setSelectedSection(section);
            section['selected'] = true;
            if (!munchedTargetIds.includes(munchedTarget)) {
                munchedTargetIds.push(munchedTarget);
            }
            if (sectionChangeHandler) {
                sectionChangeHandler(section);
            }
        }
        dispatch(setMunchedPostTargetIds([...munchedTargetIds]));
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
            <h6
                style={{
                    flex: 1,
                    paddingTop: 20,
                }}
                variant={'h6'}>Pick a board section(Optional)
            </h6>
            <div
                style={{
                    display: 'inline-flex',
                    marginTop: 10
                }}>
                {
                    sections.map((section) => {
                        return (
                            <BoardSectionEntryContainer
                                key={section['id']}
                                selected={selectedSection['id'] === section['id']}
                                style={{
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    borderRadius: 100,
                                    fontSize: 14,
                                    cursor: 'pointer',
                                    color: 'black'
                                }}
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    handleSectionSelection(section)
                                }}
                            ><span>{section['name']}</span></BoardSectionEntryContainer>
                        )
                    })
                }
            </div>
        </div>
    );
}