import {
    faCancel,
    faCheck, faCheckDouble,
    faPause, faStopwatch, faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import React from "react";
import { CircularProgressbar } from 'react-circular-progressbar';
import { checkIfPostWasPublishedWithError } from "utils/post_utils";

export const FeedStatus = React.forwardRef((props) => {
    const { post, fromPostDetailsDialog } = props;
    const percentage = props.percentage;
    const skipLabel = props.skipLabel ?? false;
    const url = window.location.href;
    const isPublished = url.includes(`/published`);

    if (checkIfPostWasPublishedWithError(post)) {
        return (
            <Flex
                style={{
                    gap: 2,
                    fontSize: fromPostDetailsDialog ? 16 : 10,
                    alignItems: 'center',
                }}>
                <FontAwesomeIcon
                    className="text-success"
                    title='Post was published but there were failed occurrences'
                    icon={faCheckDouble}
                />
                {
                    !skipLabel &&
                    <span
                        className="text-danger"
                    >Published with errors
                    </span>
                }
            </Flex>
        );
    }
    if (post.failed) {
        if (isPublished) return <></>
        return (
            <Flex
                style={{
                    gap: 2,
                    fontSize: fromPostDetailsDialog ? 16 : 10,
                    alignItems: 'center',
                }}>
                <FontAwesomeIcon
                    className="text-danger"
                    title='Failed'
                    icon={faTimes}
                />
                {
                    !skipLabel &&
                    <span className="text-danger">Failed
                    </span>
                }
            </Flex>
        );
    }
    if (post.paused) {
        return (
            <Flex
                style={{
                    gap: 2,
                    fontSize: fromPostDetailsDialog ? 16 : 10,
                    alignItems: 'center',
                }}>
                <FontAwesomeIcon
                    title='Paused'
                    icon={faPause}
                />
                {
                    !skipLabel &&
                    <span style={{}}>Paused
                    </span>
                }
            </Flex>
        );
    }
    if (post.data?.processing) {
        return (
            <Flex
                style={{
                    display: 'flex',
                    gap: 2,
                    fontSize: fromPostDetailsDialog ? 16 : 10,
                    alignItems: 'center',
                }}>
                <div style={{ width: 20, height: 20 }} >
                    <CircularProgressbar
                        strokeWidth={10}
                        value={percentage}
                        text={`${percentage}%`}
                        styles={{
                            path: {
                                stroke: '#F57C00'
                            },
                            text: {
                                fontWeight: 'bold',
                                fontSize: 20
                            }
                        }}
                    />
                </div>
                <span style={{}}>Processing</span>
            </Flex>
        );
    }
    if (post.posted) {
        return (
            <Flex
                style={{
                    display: 'flex',
                    gap: 2,
                    fontSize: fromPostDetailsDialog ? 16 : 10,
                    alignItems: 'center',
                }}>
                <FontAwesomeIcon
                    className="text-success"
                    title='Published'
                    icon={faCheckDouble}
                />
                {
                    !skipLabel &&
                    <span style={{}}>{post?.data?.approvals ? "Approved. Published" : "Published"}
                    </span>
                }
            </Flex>
        );
    }
    if (post.drafted) {
        return (
            <Flex
                style={{
                    gap: 2,
                    fontSize: fromPostDetailsDialog ? 16 : 10,
                    alignItems: 'center',
                }}>
                <FontAwesomeIcon
                    title="Drafted"
                    icon={faCheck}
                />
                {
                    !skipLabel &&
                    <span
                        style={{}}>Drafted
                    </span>
                }

            </Flex>
        );
    }
    if (post.pending_approval) {
        return (
            <Flex
                style={{
                    display: 'flex',
                    gap: 2,
                    fontSize: fromPostDetailsDialog ? 16 : 10,
                    alignItems: 'center',
                }}>
                <FontAwesomeIcon
                    title={post?.data?.disapprovals ? 'Approval Rejected' : 'Pending Approval'}
                    icon={faStopwatch}
                />
                {
                    !skipLabel &&
                    <span>
                        {post?.data?.disapprovals ? 'Approval Rejected' : 'Pending Approval'}
                    </span>
                }
            </Flex>
        );
    }
    return (
        <Flex
            style={{
                gap: 2,
                fontSize: fromPostDetailsDialog ? 16 : 10,
                alignItems: 'center',
            }}>
            <FontAwesomeIcon
                title="Scheduled"
                icon={faCheckDouble}
            />
            {
                !skipLabel &&
                <span>{post?.data?.approvals ? "Approved. Scheduled" : "Scheduled"}</span>
            }
        </Flex>
    );
});
