import moment from 'moment';
import React from 'react';
import { Modal, Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';

function PostOverviewModal(props) {
    const { post } = props;
    const formattedDate = moment(post.created).format("MMMM D, YYYY");
    const { impressions, reach, engagement } = post;
    const postInsight = [
        { title: 'Impressions', value: impressions },
        { title: 'Reach', value: reach },
        // { title: 'Engagement', value: engagement } // Removed due to deprecated metric...
    ]
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className='text-center'>
                    Post insights
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>{post.title}</h5>
                <div style={{ fontSize: '12px' }}>
                    <div className='d-flex flex-row gap-3'>
                        <p>{`Published ${formattedDate}`}</p>
                        <a href={post.url ?? "#"} target="_blank" rel="noopener noreferrer">
                            Post link
                        </a>
                    </div>
                    <p>{post.content}</p>
                </div>

                <div className="d-flex flex-col mt-2 mb-2 fw-bold" style={{ fontSize: '12px' }}>
                </div>
                <Flex
                    className={'gap-3'}
                    alignItems={'center'}
                    wrap={'wrap'}
                >
                    {postInsight.map(({ title, value }) => {
                        return (
                            <Card
                                key={title}
                                className="mb-2"
                                style={{ width: '8rem' }}>
                                <Card.Body>
                                    <Card.Title style={{ fontSize: '0.75rem' }}>{title}</Card.Title>
                                    <Card.Text className="fw-bold">
                                        {value ?? '--'}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        )
                    })}
                </Flex>
            </Modal.Body>
        </Modal>
    );
}


export default PostOverviewModal;