import styled from "styled-components";

export const MainArena = styled.div`
  height:auto;
  min-width:200px;
  margin-bottom:20px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  border-bottom-color: ${({ bg }) => bg};
`;

export const Header = styled.div`
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 0 10px;
    font-size: 15px;
`
export const EachMetaDataSlide = styled.div`
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 0 10px;
    font-size: 13px;
    /* border-bottom: solid #606060 1px; */
`