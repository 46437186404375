import styled, { keyframes } from "styled-components";

const fadeInAnimation = keyframes`
from {
  opacity: 0;
  margin-left:-10%;
}
to {
  opacity: 1;
  margin-left:0%;
}
`;
export const ParentSection = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  animation: ${fadeInAnimation} 0.3s linear;
  cursor: auto;
  justify-content: center;
  align-items: flex-start;
`;

export const Wrapper = styled.div`
  /* flex: 9; */
  /* height: fit-content; */
  width: 100%;
  position: relative;
`;

export const PreviewContentArea = styled.div`
  /* border-style:solid;
  border-width:1px; */
  width: 100%;
  background-color: ${(props) => props.mainBgColor};
  /* z-index: 2; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-top: 10px;
  padding-top: 8px;
  /* margin-bottom: -60px; */
  animation: ${fadeInAnimation} 0.4s linear;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0 !important;
  }
`;

export const EditContainer = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: flex-start;
  gap:10px;
  border-radius: 8px;
  margin-top: 10px;
  flex: 1;
`;

export const BodyArea = styled.div`
   height: auto;
   width: 100%;
   max-width: 100% !important;
   overflow: hidden;
   padding: 10px;
   img{
      max-width: 100% !important;
      max-height: 400px;
   }
`;

export const MainHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
`;

export const ImageSection = styled.div`
  margin: 10px 0;
  padding: 10px;
`;

export const ContentHeader = styled.div`
  display: flex; 
  align-items: center; 
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 3px;
  row-gap: 3px;
  margin: 0 2px;
`;

export const ContentHeaderTitle = styled.span`
  font-size: 9px;
  margin: 0 2px;
`;

export const ContentHeaderItem = styled.span`
  font-weight: 700;
  margin: 0 2px;
  text-decoration: underline;
  font-size: 9px;
`;

export const CategoryItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start; 
  flex-wrap: wrap;
  gap: 5px;
  margin: 10px 0;
`;

export const CategoryItem = styled.div`
  background-color: #01579B;
  color: #ffffff;
  padding: 5px 8px;
  font-size: 13px;
  border-radius: 15px;
`;