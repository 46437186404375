import { Button } from "react-bootstrap";

export default function ImageEditButton(props) {
    const { style = {}, ...others } = props;
    return (
        <Button
            {...others}
            style={{
                borderRadius: 100,
                fontSize: 9,
                padding:3,
                ...style
            }}
        >
            Edit
        </Button>
    );
}