import React, { useContext } from 'react'
import InstaFeedCard from './InstaFeedCard'
import classNames from 'classnames'
import { InstaFeedContext } from 'context/Context';

const InstaFeedContent = () => {
    const { feeds } = useContext(InstaFeedContext);
    console.log("🚀 ~ InstaFeedContent ~ feeds:", feeds)

  return (
    <>
      {/* <CreatePost className="mb-3" /> */}
      {feeds.map((feed, index) => (
        <InstaFeedCard
          key={feed.id}
          feed={feed}
          className={classNames({ 'mb-3': index + 1 !== feeds.length })}
        />
      ))}
    </>
  )
}

export default InstaFeedContent