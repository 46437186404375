import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import {
    getUploadableBulkContent,
    setUploadableBulkContent
} from "../../../../../redux/slices/postslice";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";

export default function BulkContentDatePreview(props) {

    let {
        rowIndex = 0,
    } = props;
    const dispatch = useDispatch();
    const workSpace = useSelector(getActiveWorkSpace);
    const uploadableBulkContent = useSelector(getUploadableBulkContent);
    const bulkContentRow = uploadableBulkContent[rowIndex] ?? {};
    const dateVal = new Date() >= new Date(bulkContentRow.due_date) ? new Date() : new Date(bulkContentRow.due_date);
    const [date, setDate] = useState(isNaN(parseInt(dateVal.getTime())) ? new Date() : dateVal);

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime();
    };

    const dateFormatHandler = () => {
        switch (workSpace?.languageAndRegionSettings?.dateFormat) {
            case "Backward":
                return "yyyy-MM-dd"; // Ensure this matches your date library's format
            case "American":
                return "MM/dd/yyyy"; // Use lowercase 'dd' for date
            default:
                return "dd/MM/yyyy"; // Use lowercase 'dd' for date
        }
    };

    const timeFormatHandler = () => {
        switch (workSpace?.languageAndRegionSettings?.timeFormat) {
            case "24-hour":
                return "HH:mm";
            default:
                return "h:mm aa";
        }
    }

    return (
        <div
            style={{
                width: 200
            }}>
            {/* <DatePicker
                minDate={new Date()}
                onChange={(e) => {
                    let newDate = new Date(e);
                    setDate(newDate);
                    let uploadableBulkContentCopy = [...uploadableBulkContent];
                    let updatedRow = { ...bulkContentRow };
                    updatedRow.due_date = newDate;
                    uploadableBulkContentCopy[rowIndex] = updatedRow;
                    dispatch(setUploadableBulkContent(uploadableBulkContentCopy));
                }}
                selected={date}
                className={'form-control'}
            /> */}

            <DatePicker
                minDate={new Date()}
                filterTime={filterPassedTime}
                selected={date}
                onChange={(e) => {
                    let newDate = new Date(e);
                    setDate(newDate);
                    let uploadableBulkContentCopy = [...uploadableBulkContent];
                    let updatedRow = { ...bulkContentRow };
                    updatedRow.due_date = newDate;
                    uploadableBulkContentCopy[rowIndex] = updatedRow;
                    dispatch(setUploadableBulkContent(uploadableBulkContentCopy));
                }}
                formatWeekDay={day => day.slice(0, 3)}
                className='form-control'
                timeIntervals={1}
                dateFormat={`${dateFormatHandler()} ${timeFormatHandler()}`}
                // dateFormat={`yyyy-MM-dd HH:mm`}
                showTimeSelect
                fixedHeight
                timeFormat={workSpace?.languageAndRegionSettings?.timeFormat === "24-hour" ? "HH:mm" : "h:mm aa"}
            />
        </div>
    );
}
