import geminiLogo from "assets/img/logos/gemini.png";
import claudeIcon from "assets/svgs/claudeIcon.svg";
import openAIIcon from "assets/svgs/openAIIcon.svg";
import APIService from "http/api_service";
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from "react-toastify";

const ApiKeyManagement = (props) => {
    const { isApiKeyModel, setApiKeyModal, apiKey, checkAIWriterUsageResumption, workSpace } = props
    const [geminiKey, setGeminiKey] = useState("");
    const [claudeKey, setClaudeKey] = useState("");
    const [openaiKey, setOpenAIKey] = useState("");
    const [isSaveKeyGemini, setIsSaveKeyGemini] = useState(false)
    const [isSaveKeyClaude, setIsSaveKeyClaude] = useState(false)
    const [isSaveKeyOpenAI, setIsSaveKeyOpenAI] = useState(false)
    const [loading, setLoading] = useState(false);

    const handleSaveKey = (val, removeKey = false) => {
        setLoading(true)
        if (val == "GEMINI") {
            setIsSaveKeyGemini(true)
            if (!geminiKey && !removeKey) {
                toast.error("Please enter Gemini api key", { theme: 'colored' })
                setLoading(false)
                return
            }
            APIService.saveApiKey(workSpace["_id"], "geminiKey", geminiKey, removeKey, (res, err) => {
                setLoading(false)
                if (err) {
                    setApiKeyModal(false);
                    toast.error(err, { theme: 'colored' })
                    return
                }
                setApiKeyModal(false);
                if (removeKey) {
                    setGeminiKey("")
                }
                toast.success(res?.message, { theme: 'colored' })
                checkAIWriterUsageResumption();
            })
            setIsSaveKeyGemini(false)
            return;
        }
        if (val == "CLAUDE") {
            setIsSaveKeyClaude(true)
            if (!claudeKey && !removeKey) {
                toast.error("Please enter Claude api key", { theme: 'colored' })
                setLoading(false)
                return
            }
            APIService.saveApiKey(workSpace["_id"], "claudeAIKey", claudeKey, removeKey, (res, err) => {
                setLoading(false)
                if (err) {
                    setApiKeyModal(false);
                    setIsSaveKeyClaude(false)
                    toast.error(err, { theme: 'colored' })
                    return
                }
                setApiKeyModal(false);
                if (removeKey) {
                    setClaudeKey("")
                }
                toast.success(res?.message, { theme: 'colored' })
                checkAIWriterUsageResumption();
            })
            return
        }
        if (val == "OpenAI") {
            setIsSaveKeyOpenAI(true)
            if (!openaiKey && !removeKey) {
                toast.error("Please enter OpenAI api key", { theme: 'colored' })
                setLoading(false)
                return
            }
            APIService.saveApiKey(workSpace["_id"], "openAIKey", openaiKey, removeKey, (res, err) => {
                setLoading(false)
                if (err) {
                    setApiKeyModal(false);
                    setIsSaveKeyOpenAI(false)
                    toast.error(err, { theme: 'colored' })
                    return
                }
                setApiKeyModal(false);
                if (removeKey) {
                    setOpenAIKey("")
                }
                toast.success(res?.message, { theme: 'colored' })
                checkAIWriterUsageResumption();
            })

        }
    }


    return (
        <Modal
            show={isApiKeyModel}
            onHide={() => {
                setApiKeyModal(false);
            }}
            onExit={() => {
                setApiKeyModal(false);
            }}
            centered
            dialogClassName="modal-90w"
        >
            <Modal.Header closeButton>
                <Modal.Title style={{ marginLeft: "8.5rem" }}>🔑 Enter API Key</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="api-key-input">
                    <p><div style={{ width: "25px", height: "25px", display: "inline-block" }}>
                        <img src={openAIIcon} alt="copy" height="25px" width="25px" />
                    </div>&nbsp;OpenAI:</p>
                    {
                        apiKey?.openAIKey ?

                            <Form.Control
                                type="password"
                                defaultValue={apiKey?.openAIKey}
                                onChange={(e) => setOpenAIKey(e.target.value)}
                            />
                            :
                            <Form.Control
                                type="text"
                                value={openaiKey}
                                onChange={(e) => setOpenAIKey(e.target.value)}
                            />
                    }
                    &nbsp;<Button onClick={() => handleSaveKey("OpenAI")} disabled={loading}>Update</Button>
                    &nbsp;<Button style={{
                        backgroundColor: '#b20000'
                    }} variant={'danger'} onClick={() => handleSaveKey("OpenAI", true)} disabled={loading}>Remove</Button>

                </div>
                <div className="api-key-input">
                    <p><div style={{ width: "40px", height: "40px", display: "inline-block", marginLeft: "-0.5rem" }}><img src={geminiLogo} width="40" height="40" class="object-cover w-full" /></div>&nbsp;Gemini:</p>
                    {
                        apiKey?.geminiKey ?
                            <Form.Control
                                type="password"
                                defaultValue={apiKey?.geminiKey}
                                onChange={(e) => setGeminiKey(e.target.value)}
                            />
                            :
                            <Form.Control
                                type="text"
                                value={geminiKey}
                                onChange={(e) => setGeminiKey(e.target.value)}
                            />
                    }
                    &nbsp;
                    <Button onClick={() => handleSaveKey("GEMINI")} disabled={loading}>Update</Button>
                    &nbsp;<Button style={{
                        backgroundColor: '#b20000'
                    }} variant={'danger'} onClick={() => handleSaveKey("GEMINI", true)} disabled={loading}>Remove</Button>
                </div>
                <div className="api-key-input">
                    <p><div style={{ width: "25px", height: "25px", display: "inline-block" }}>
                        <img src={claudeIcon} alt="copy" height="25px" width="25px" />
                    </div>&nbsp;Claude:</p>
                    {
                        apiKey?.claudeAIKey ?
                            <Form.Control
                                type="password"
                                defaultValue={apiKey?.claudeAIKey}
                                onChange={(e) => setClaudeKey(e.target.value)}
                            />
                            :
                            <Form.Control
                                type="text"
                                value={claudeKey}
                                onChange={(e) => setClaudeKey(e.target.value)}
                            />
                    }
                    &nbsp;
                    <Button onClick={() => handleSaveKey("CLAUDE")} disabled={loading}>Update</Button>
                    &nbsp;<Button style={{
                        backgroundColor: '#b20000'
                    }} variant={'danger'} onClick={() => handleSaveKey("CLAUDE", true)} disabled={loading}>Remove</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ApiKeyManagement