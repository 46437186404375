import React, { useState, useEffect } from 'react';
import AuthCardLayout from 'layouts/AuthCardLayout';
import { useNavigate } from 'react-router-dom';
import APIService from 'http/api_service';
import FadeLoader from 'react-spinners/FadeLoader';
import { AuthMethod, RoutePaths } from '../../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setActiveWorkspace } from 'redux/slices/workspaceslice';
import { setLoggedInUser } from 'redux/slices/user_slice';

const WorkspaceInvitationPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [fetchingWorkSpaceDetails, setFetchingWorkSpaceDetails] = useState(true);
    const [workSpaceResolutionError, setWorkSpaceResolutionError] = useState();
    const [feedBackMessage, setFeedbackMessage] = useState();
    const [acceptingInvite, setAcceptingInvite] = useState(false);
    const [invitationErrorEncountered, setInvitationErrorEncountered] = useState(false);
    const [token, setToken] = useState();
    const [joinedWorkSpace, setJoinedWorkspace] = useState(false);
    const [workSpaceData, setWorkSpaceData] = useState();
    const [acceptedWorkspace, setAcceptedWorkspace] = useState();

    useEffect(() => {
        let params = (new URL(document.location)).searchParams;
        let receivedToken = params.get("token");
        if (receivedToken) {
            setToken(receivedToken);
        }
    }, []);

    useEffect(() => {
        if (token) {
            fetchWorkSpaceDetailsFromToken();
        }
    }, [token]);

    const fetchWorkSpaceDetailsFromToken = () => {
        setFetchingWorkSpaceDetails(true);
        APIService.fetchWorkSpaceDetailsFromInviteToken(encodeURIComponent(token), (response, error) => {
            setFetchingWorkSpaceDetails(false);
            if (error) {
                setWorkSpaceResolutionError(error);
                return;
            }
            setWorkSpaceResolutionError(null);
            let { data } = response;
            setWorkSpaceData(data);
        });
    }

    const processInvite = () => {
        if (!token) {
            setInvitationErrorEncountered(true);
            setFeedbackMessage("No valid token in path");
            return;
        }
        setAcceptingInvite(true);
        APIService.acceptTeamInvite(token, (response, error) => {
            setAcceptingInvite(false);
            if (error) {
                setInvitationErrorEncountered(true);
                setFeedbackMessage(error);
                return;
            }
            let message = response['message'];
            let data = response['data'];
            if (data['auth_type'] === AuthMethod.PASSWORD_LESS) {
                message += '<br/>When logging in you may use the <b>Password-less</b> option'
            }
            message += "<br/>";
            data['has_workspaces'] = true;
            let workSpace = data.active_workspace ?? {};
            setAcceptedWorkspace(workSpace);
            dispatch(setLoggedInUser({ ...data }));
            dispatch(setActiveWorkspace({ ...workSpace }));
            setInvitationErrorEncountered(false);
            setFeedbackMessage(message);
            setJoinedWorkspace(true);
        });
    }

    return (
        <AuthCardLayout>
            <div>
                {
                    fetchingWorkSpaceDetails &&
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <FadeLoader
                            style={{
                                position: 'relative',
                            }}
                        />
                    </div>
                }
                {
                    workSpaceResolutionError &&
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <span className='danger'>{workSpaceResolutionError}</span>
                    </div>
                }
                {
                    workSpaceData &&
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            background: 'white',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <h4 style={{ fontSize: 25 }}>New Workspace Invite</h4>
                        <p>You were invited to the following workspace</p>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 15,
                                justifyContent: 'center',
                                background: 'white'
                            }}>
                            <div style={{
                                width: 150,
                                height: 150,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '100%',
                                backgroundColor: '#E0E0E0'
                            }}>
                                {
                                    workSpaceData.avatar
                                        ? <img
                                            src={workSpaceData.avatar}
                                            alt=' '
                                            style={{
                                                width: '95%',
                                                height: '95%',
                                                borderRadius: '100%',
                                            }}
                                        />
                                        : <FontAwesomeIcon
                                            icon={faUsers}
                                            style={{
                                                width: '50%',
                                                height: '50%'
                                            }}
                                        />
                                }
                            </div>
                            <h4
                                style={{
                                    fontSize: 18
                                }}>{workSpaceData['name']}
                            </h4>
                            {
                                invitationErrorEncountered &&
                                <span>{feedBackMessage}
                                </span>
                            }
                            {
                                !invitationErrorEncountered &&
                                <span
                                    dangerouslySetInnerHTML={{ __html: feedBackMessage }}>
                                </span>
                            }
                            <Button
                                onClick={() => {
                                    if (joinedWorkSpace) {
                                        navigate(`${RoutePaths.SWITCH_WORKSPACE}?to=${acceptedWorkspace?._id}`);
                                    } else {
                                        processInvite();
                                    }
                                }}
                                disabled={acceptingInvite}
                                variant={'primary'}
                                className={joinedWorkSpace ? 'primary' : 'success'}
                                style={{
                                    minWidth: 200,
                                }}>{joinedWorkSpace ? 'Continue to Workspace' : `${acceptingInvite ? 'Please wait...' : 'Join Workspace'}`}
                            </Button>
                            <p style={{ fontSize: 10 }}>Only join workspaces you know</p>
                        </div>
                    </div>
                }
            </div>
        </AuthCardLayout>
    )
}

export default WorkspaceInvitationPage;
