import React from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery';
import ReactPlayer from 'react-player';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDeleteLeft, faPlus, faSubtract } from '@fortawesome/free-solid-svg-icons';
import MultimediaSelectionDropdown from 'components/common/MultimediaSelectionDropdown';
import { getPublishablePostMedia, setPublishablePostMedia } from 'redux/slices/post_media_slice';
import { setShowMediaOptions } from 'redux/slices/postslice';
import { MdDelete, MdDeleteForever } from 'react-icons/md';
import { BsBasket } from 'react-icons/bs';

const PostAttachmentsPreviewContainer = (props) => {

    const { updateMediaObjects } = props;
    const mediaObjects = useSelector(getPublishablePostMedia);
    const images = mediaObjects.filter(x => !x.is_video);
    const videos = mediaObjects.filter(x => x.is_video);
    const dispatch = useDispatch();

    const imagePlacementsMapping = {
        1: [6],
        2: [4, 4],
        3: [4, 4, 4],
        4: [4, 4, 4, 4],
        5: [4, 4, 3, 3, 3],
        6: [3, 3, 3, 3, 3, 3],
        "above": [3, 3, 3, 3, 3, 3]
    }
    // const imagePlacementsMapping = {
    //     1: [12],
    //     2: [6, 6],
    //     3: [6, 6, 12],
    //     4: [6, 6, 6, 6],
    //     5: [6, 6, 4, 4, 4],
    //     6: [4, 4, 4, 4, 4, 4],
    //     "above": [4, 4, 4, 4, 4, 4]
    // }

    const RemoveButton = ({ file }) => {
        return (
            <div
                style={{
                    display: 'flex',
                    gap: 10,
                    justifyContent: 'center',
                    position: 'absolute',
                    bottom: 10,
                    left: 0,
                    right: 0,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '100%'
                }}>
                <Button
                    variant="text"
                    size='sm'
                    style={{
                        backgroundColor: 'rgba(0,0,0,0.9)',
                        color: 'white',
                    }}
                    onClick={() => {
                        let realFile = mediaObjects.find(entry => entry.url === file.url);
                        let mediaObjectsCopy = [...mediaObjects];
                        let index = mediaObjectsCopy.indexOf(realFile);
                        if (index !== -1) {
                            mediaObjectsCopy.splice(index, 1);
                            dispatch(setPublishablePostMedia(mediaObjectsCopy));
                        }
                    }}
                >
                    {
                        mediaObjects.length < 5 ? 'Remove' :
                            <FontAwesomeIcon icon={faSubtract} />
                    }
                </Button>
                {
                    !file.is_video &&
                    <MultimediaSelectionDropdown
                        dropDownAnchor={
                            <span id='photo'>
                                <Button
                                    variant='falcon-info'
                                    className='fs--1'
                                    size='sm'
                                    onClick={() => { dispatch(setShowMediaOptions(true)) }}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                    {
                                        mediaObjects.length < 5 &&
                                        <span> Add More</span>
                                    }
                                </Button>
                            </span>
                        }
                        alignment={"start"}
                        initiatorId={'global-photo-chooser'}
                        externalFilePickerTitle={"Enter Photo Location"}
                        prompt={"Add Photo From"}
                        fileType={"image"}
                        externalPickerPromptLabel="Photo"
                        multiple={true}
                        fileSelectionHandler={(response) => {
                            if (response) {
                                let { files, from_local, ...others } = response;
                                updateMediaObjects(files, from_local, others);
                            }
                        }}
                    />
                }
            </div>
        )
    }

    return (
        <div className='mb-3' >
            <FalconLightBoxGallery images={images.map(x => x.url)}>
                {
                    setImgIndex => (
                        <Row className="g-2">
                            {
                                images.map((image, index) => {
                                    let mapping = imagePlacementsMapping[images.length];
                                    if (!mapping) {
                                        mapping = "above";
                                        mapping = imagePlacementsMapping[mapping];
                                    }
                                    return (
                                        // <Col xs={2}>
                                        <Col xs={mapping[index]}>
                                            <div
                                                style={{
                                                    height: '100%',
                                                    position: 'relative',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 5
                                                }}>
                                                <Image
                                                    src={image.url}
                                                    fluid
                                                    rounded
                                                    style={{
                                                        cursor: 'pointer',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                    }}
                                                    onClick={() => setImgIndex(index)}
                                                />
                                                <MdDeleteForever
                                                    style={{
                                                        width: 24,
                                                        height: 24,
                                                        cursor: 'pointer',
                                                        color: 'brown'
                                                    }}
                                                    onClick={() => {
                                                        let realFile = mediaObjects.find(entry => entry.url === image.url);
                                                        let mediaObjectsCopy = [...mediaObjects];
                                                        let index = mediaObjectsCopy.indexOf(realFile);
                                                        if (index !== -1) {
                                                            mediaObjectsCopy.splice(index, 1);
                                                            dispatch(setPublishablePostMedia(mediaObjectsCopy));
                                                        }
                                                    }}
                                                />
                                                {/* <FontAwesomeIcon
                                                    icon={faDeleteLeft}
                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                        cursor: 'pointer',
                                                    }}
                                                /> */}
                                                {/* <RemoveButton file={image} /> */}
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                            {
                                videos && videos.map((video) => {
                                    return (
                                        <Col xs={12}>
                                            <div
                                                style={{
                                                    height: '100%',
                                                    position: 'relative'
                                                }}>
                                                <ReactPlayer url={video.url} controls={true} className="react-player" />
                                                <RemoveButton file={video} />
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    )}
            </FalconLightBoxGallery>
        </div>
    );
};

export default PostAttachmentsPreviewContainer;
