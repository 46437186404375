import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Card, Dropdown, Modal } from 'react-bootstrap';
import Event from './Event';
import ConfirmModal from 'components/common/ConfirmModal';
import { useSelector, useDispatch } from 'react-redux';
import {
  canOpenCustomDayCreator,
  canOpenPopularDays,
  getActiveWorkSpace,
  getRightBarSectionsPositions,
  setActiveWorkspace,
  setOpenCustomDayCreator,
  setOpenPopularDays,
  setPanelVisibilityChoices,
  setRightBarItemPositions
} from 'redux/slices/workspaceslice';
import Divider from 'components/common/Divider';
import CustomEventDayCreator from './CustomEventDayCreator';
import PopularDaySourcesOptionView from './PopularDaysSourcesPrompt';
import { toast } from 'react-toastify';
import APIService from 'http/api_service';
import { setRefreshPopularDays } from 'redux/slices/postslice';
import "./events.css";

const Events = ({ cardTitle, events, ...rest }) => {

  const [openHideConsent, setOpenHideConsent] = useState(false);
  const openCustomDayEventCreator = useSelector(canOpenCustomDayCreator);
  const openPopularDaysDialog = useSelector(canOpenPopularDays);
  const rightBarPositions = useSelector(getRightBarSectionsPositions);
  const dispatch = useDispatch();
  const workSpace = useSelector(getActiveWorkSpace);

  const CustomizationOptionsDropdown = () => {
    return (
      <Dropdown className="font-sans-serif btn-reveal-trigger">
        <Dropdown.Toggle variant="span" size="sm" className="px-1 text-600">
          <span className='text-primary'>Customize</span>
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Item
            className='cursor-pointer'
            as={'span'}
            onClick={() => {
              setOpenHideConsent(true);
            }}>Hide Events
          </Dropdown.Item>
          <Dropdown.Item
            className='cursor-pointer'
            as={'span'}
            onClick={() => {
              let rightBarPositionsCopy = { ...rightBarPositions };
              if (rightBarPositionsCopy.EVENTS === 0) {
                rightBarPositionsCopy["EVENTS"] = 1;
                rightBarPositionsCopy["TRENDS"] = 0;
              } else {
                rightBarPositionsCopy["EVENTS"] = 0;
                rightBarPositionsCopy["TRENDS"] = 1;
              }
              dispatch(setRightBarItemPositions(rightBarPositionsCopy));
            }}>{rightBarPositions.EVENTS === 0 ? "Move Down" : "Move Up"}
          </Dropdown.Item>
          <Dropdown.Item className='cursor-pointer'
            as={'span'} onClick={() => dispatch(setOpenPopularDays(true))}>Edit Locations</Dropdown.Item>
          <Divider />
          <Dropdown.Item className='cursor-pointer'
            as={'span'} onClick={() => dispatch(setOpenCustomDayCreator(true))}>Add a Custom Day</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <Card {...rest} className='mb-3'>
      <FalconCardHeader title={cardTitle}
        light
        endEl={
          <CustomizationOptionsDropdown />
        }
      />
      <Card.Body className="fs--1 border-bottom">
        {
          events.map((event, index) => (
            <Event
              key={event.name + event.location}
              details={event}
              isLast={index === events.length - 1}
            />
          ))
        }
      </Card.Body>
      <ConfirmModal
        open={openHideConsent}
        title="Hide Events?"
        message="When you click the <b>HIDE</b> button below, we will hide the Events section from showing up. You can however, un-hide this at any time from the Workspace Settings page."
        confirmText="HIDE"
        cancelText="NO"
        onConfirm={() => {
          setOpenHideConsent(false);
          toast.info("Updating Preference...");
          APIService.togglePanelData(workSpace._id, { "EVENTS": false }, (response, error) => {
            if (error) {
              toast.error(error, { theme: 'colored' });
              return;
            }
            let { message, data } = response;
            toast.success(message, { theme: 'colored' });
            dispatch(setPanelVisibilityChoices(data.panel_visibility_choices));
            dispatch(setActiveWorkspace({
              ...workSpace,
              panel_visibility_choices: data.panel_visibility_choices
            }));
            dispatch(setRefreshPopularDays(true));
          })
        }}
        onCancel={() => {
          setOpenHideConsent(false);
        }}
      />
      <Modal
        show={openCustomDayEventCreator}
        onHide={() => dispatch(setOpenCustomDayCreator(false))}
        onExit={() => dispatch(setOpenCustomDayCreator(false))}
      >
        <Modal.Body>
          <CustomEventDayCreator modalClose={() => {
            dispatch(setOpenCustomDayCreator(false))
          }}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={openPopularDaysDialog}
        onHide={() => dispatch(setOpenPopularDays(false))}
        onExit={() => dispatch(setOpenPopularDays(false))}
      >
        <Modal.Body>
          <PopularDaySourcesOptionView modalClose={() => {
            dispatch(setOpenPopularDays(false))
          }}
          />
        </Modal.Body>
      </Modal>
    </Card>
  );
};

Events.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(PropTypes.shape(Event.propTypes))
};

export default Events;
