import React, { useEffect, useState } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { GridContainer } from "./link_shortener_style";
import { FaFolder } from "react-icons/fa6";
import { IoIosLink } from "react-icons/io";
import { useRef } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import APIService from "http/api_service";
import {
  getLibraryItems,
  setLibraryItems,
} from "redux/slices/links_shortener_slice";
import { IoMdArrowRoundBack } from "react-icons/io";
import Item from "./Item";
import Flex from "components/common/Flex";

const Library = () => {
  const linkRef = useRef();
  const dispatch = useDispatch();
  const [viewHandler, setViewHandler] = useState("Home");
  const library = useSelector(getLibraryItems);
  const [libraryState, setLibraryState] = useState(library);
  const [selectedItems, setSelectedItems] = useState([]);
  const [deleteState, setDeleteState] = useState("Delete");


  const getLibraryHandler = async () => {
    APIService.fecthMyLibrary((response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      let { data } = response;
      //   toast.success(message);
      dispatch(setLibraryItems(data));
    });
  };

  useEffect(() => {
    getLibraryHandler();
  }, []);

  useEffect(() => {
    setLibraryState(library);
  }, [library]);

  const handleDeleteMultipleItem = async () => {
    setDeleteState("Deleting...");
    let requestBody = {
      items: selectedItems
    }

    await APIService.deleteMultipleItems( requestBody, (response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        setDeleteState("Delete");

        return;
      }
      let { message } = response;
      setDeleteState("Delete");
      toast.success(message)
      setSelectedItems([]);
      getLibraryHandler();
    });
  };

  const ascendingFunction = () => {
    let sortedList = [...libraryState].sort((a, b) => {
      const nameA = a.name.toLowerCase();
  const nameB = b.name.toLowerCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
    });
    setLibraryState(sortedList)
  }
  const descendingFunction = () => {
    let sortedList = [...libraryState].sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    });
    setLibraryState(sortedList)
  }
  const sortByDateFunction = () => {
    let sortedList = [...libraryState].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateA - dateB;
    });
    setLibraryState(sortedList)
  }



  const sortFunctionHandler = async (e) => {
    switch (e.target.value) {
      case "name_ascend":
        ascendingFunction();
        break;
      case "name_descend":
        descendingFunction();
        break;
      case "date":
        sortByDateFunction();
        break;
      default:
        setLibraryState(library)
        break;
    }
  }
  return (
    <Card.Body>
      <div
        style={{
          maxWidth: "100%",
          minHeight: "70vh",
          padding: "10px",
          boxSizing: "border-box",
          //   backgroundColor: "red",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <Card.Title>My library</Card.Title> */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              // gap: "10px",
              alignItems: "center",
            }}
          >
            <Form.Select
              placeholder="Select sort type"
              style={{ transform: "scale(0.8)" }}
              onChange={sortFunctionHandler}
            >
              <option style={{ cursor: "pointer", fontSize: "12px" }} value="">
                Select Sort type
              </option>
              <option
                style={{ cursor: "pointer", fontSize: "12px" }}
                value="date"
              >
                Sort by Date
              </option>
              <option
                style={{ cursor: "pointer", fontSize: "12px" }}
                value="name_ascend"
              >
                Sort by name {"(ascending)"}
              </option>
              <option
                style={{ cursor: "pointer", fontSize: "12px" }}
                value="name_descend"
              >
                Sort by name {"(descending)"}
              </option>
            </Form.Select>
          </div>
          {selectedItems?.length > 0 && <Button variant="danger" onClick={handleDeleteMultipleItem}>
            {deleteState}
          </Button>}
        </div>
        <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px", marginTop: "10px"}}>
          {viewHandler !== "Home" && <IoMdArrowRoundBack size={20} cursor={"pointer"} onClick={() =>{ setLibraryState(library); setViewHandler("Home")}}/>}
          <Card.Subtitle className="text-muted">
            {viewHandler}
          </Card.Subtitle>
        </div>

        {libraryState === null ? (
          <div style={{width: "100%", height: "70vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Spinner animation="border" />
          </div>
        ) : (
          <GridContainer>
            {libraryState?.map((data, index) => (
              <Item
                key={index}
                data={data}
                setViewHandler={setViewHandler}
                setLibraryState={setLibraryState}
                setSelectedItems={setSelectedItems}
                selectedItems={selectedItems}
              />
            ))}
          </GridContainer>
        )}
      </div>
    </Card.Body>
  );
};

export default Library;
