import React from 'react'
import { Button } from 'react-bootstrap'
import { Table, TableBody, TableHead, Td, Th, Tr } from './image_bg_biller_styles'
import { imageEditorBackgroundRemovalCreditPricings } from 'components/app/account/pricing/pricingDataNew'
import BuyImageCreditModal from './buyImageCreditModal'

const ImageBackgroundBiller = (props) => {

    const displayContacts = imageEditorBackgroundRemovalCreditPricings?.map((x, i) => {
        return <Tr key={i}>
            <Td>
                {x?.credits} Credits
            </Td>
            <Td>
                {x?.price} USD
            </Td>
            <Td>
                <Button
                    variant="primary"
                    size="sm"
                    style={{
                        width: '100%',
                        marginTop: -5
                    }}
                    transform="shrink-3"
                    onClick={() => {
                        props?.setSelectedCreditPlanToPurchase(x);
                        props?.setShowBiller(false);
                    }}
                >
                    Buy Credits
                </Button>
            </Td>
        </Tr>
    })

    return (
        <>
            <BuyImageCreditModal
                open={props?.showBiller}
                title={"Buy Credits"}
                body={<Table>
                    <TableHead>
                        <Tr>
                            <Th>Plans</Th>
                            <Th>Amount</Th>
                        </Tr>
                    </TableHead>
                    <TableBody>
                        {displayContacts}
                    </TableBody>
                </Table>}
                onCancel={() => {
                    props?.setShowBiller(false);
                }}
            />
        </>
    )
}

export default ImageBackgroundBiller