import IconAlert from "./IconAlert";

const InfoBar = (props) => {
    const { info, variant = 'info', iconVariant, customStyles, iconContainerStyles, innerIconStyles, secondaryIconStyles, otherElements } = props;
    return (
        <IconAlert
            iconContainerStyles={iconContainerStyles}
            innerIconStyles={innerIconStyles}
            iconVariant={iconVariant}
            style={{ ...secondaryIconStyles }}
            otherElements={otherElements}
            variant={variant} className={'mt-3'}>
            <span style={{ ...customStyles }} dangerouslySetInnerHTML={{ __html: info }}>
            </span>
        </IconAlert>
    )
}

export default InfoBar;