import Flex from "components/common/Flex";
import AppContext from "context/Context";
import { useContext, useState } from "react";
import TextareaAutoSize from 'react-textarea-autosize';

const FeedbackField = ({ onChange, incomingValue, placeholder }) => {

    const { config } = useContext(AppContext);
    const [value, setValue] = useState(incomingValue);

    return (
        <Flex>
            <TextareaAutoSize
                id='feed-back-compose-box'
                minRows={4}
                as="textarea"
                placeholder={placeholder}
                className={`shadow-none resize-none border-1 ${config.isDark ? 'border-x' : ''} px-card border-200`}
                value={value}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                style={{
                    width: '100%',
                    outline: 'none',
                    whiteSpace: 'pre-wrap',
                    display: 'inherit',
                    background: config.isDark ? "#0b1727" : "white",
                    color: 'inherit',
                    fontSize: 'inherit'
                }}
                maxRows={20}
            />
        </Flex>
    )
}
export default FeedbackField;