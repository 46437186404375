import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Dropdown,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import Avatar from "components/common/Avatar";
import { isIterableArray } from "helpers/utils";
import Flex from "components/common/Flex";
import FalconCloseButton from "components/common/FalconCloseButton";
import { useSelector, useDispatch } from "react-redux";
import { getSearchHint, setSearchTerm } from "redux/slices/workspaceslice";
import { platforms } from "../../app/platforms";
import invite from "assets/img/illustrations/ecommerce-bg.png";
import {
  getPostCategories,
  getPostFilters,
  setForceFetchPosts,
  setPostCategoryType,
  setPostsFilters,
} from "redux/slices/postslice";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  cachePosts,
  getSelectedPostType,
  setFetchRounds,
  setSelectedPostType,
  setSinglePostType,
} from "../../../redux/slices/postslice";

const MediaSearchContent = ({ item }) => {
  return (
    <Dropdown.Item className="px-card py-2" as={Link} to={item.url}>
      <Flex alignItems="center">
        {item.file && (
          <div className="file-thumbnail">
            <img src={item.img} alt="" className={item.imgAttrs.class} />
          </div>
        )}
        {item.icon && (
          <Avatar src={item.icon.img} size="l" className={item.icon.status} />
        )}
        <div className="ms-2">
          <h6 className="mb-0">{item.title}</h6>
          <p
            className="fs--2 mb-0"
            dangerouslySetInnerHTML={{ __html: item.text || item.time }}
          />
        </div>
      </Flex>
    </Dropdown.Item>
  );
};

const SearchBox = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const searchHint = useSelector(getSearchHint);
  const currentLocation = useLocation();
  const dispatch = useDispatch();
  const postFilters = useSelector(getPostFilters);
  const [filterData, setFilterData] = useState({ ...postFilters });
  const postCategories = useSelector(getPostCategories);
  const selectedPostType = useSelector(getSelectedPostType);
  const [openCategoriesPopup, setOpenPostCategoriesPopup] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const sortOptions = [
    {
      label: "From Newest to Oldest",
      value: "desc",
    },
    {
      label: "From Oldest to Newest",
      value: "asc",
    },
  ];

  const mediaOptions = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Videos Only",
      value: "videos_only",
    },
    {
      label: "Photos Only",
      value: "photos_only",
    },
  ];

  const postTypes = [
    {
      name: "All posts",
      value: "all",
    },
    {
      name: "Published posts",
      value: "posted",
    },
    {
      name: "Pending approval",
      value: "pending_approval",
    },
    {
      name: "Scheduled posts",
      value: "scheduled",
    },
    {
      name: "Recurring posts",
      value: "recurring",
    },
    {
      name: "Failed posts",
      value: "failed",
    },
    {
      name: "Drafts",
      value: "draft",
    },
    {
      name: "Campaigns",
      value: "campaigns",
    },
  ];

  const toggle = () => setDropdownOpen(!dropdownOpen);

  useEffect(() => {
    dispatch(setSearchTerm(searchInputValue));
  }, [searchInputValue]);

  const nonPostSearch = () => {
    return (
      !currentLocation.pathname.includes("/posts") &&
      !currentLocation.pathname.includes("planner")
    );
  };

  const contentPlannerInPath = () => {
    if (
      currentLocation.pathname.includes("ontent-planner") ||
      currentLocation.pathname.includes("ontent-calendar")
    ) {
      return true;
    }
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDropdownOpen(false);
    dispatch(setSearchTerm(searchInputValue));
  };

  if (searchHint === "remove the search hint") {
    return <></>;
  }

  const PostCategoryListItem = ({ name, _id, ...others }) => {
    return (
      <Flex alignItems={"center"}>
        <Form.Check
          id={`${name}-checkbox`}
          type="radio"
          name="campaigns"
          label={name}
          value={name}
          checked={selectedCategory === name}
          className={"mt-2"}
        />
      </Flex>
    );
  };

  const NoPostCategoriesView = () => {
    return (
      <Flex
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <img className="img-fluid" src={invite} alt="image" width="28%" />
        <h3 className="mt-3 fw-normal fs-2 mt-md-4 fs-md-3">
          No post Campaigns for filtering
        </h3>
      </Flex>
    );
  };

  return (
    <>
      <Flex>
        <Dropdown
          show={dropdownOpen}
          autoClose={!nonPostSearch() ? true : false}
          onToggle={toggle}
          className="search-box"
        >
          <Dropdown.Toggle
            as="div"
            data-toggle="dropdown"
            aria-expanded={dropdownOpen}
            bsPrefix="toggle"
          >
            <Form
              className="position-relative"
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.key.toLowerCase() === "enter") {
                  if (!searchInputValue) {
                    dispatch(setPostsFilters({}));
                    dispatch(setForceFetchPosts(true));
                  }
                }
              }}
            >
              <Form.Control
                type="search"
                placeholder={searchHint}
                aria-label="Search"
                className="rounded-pill search-input"
                value={searchInputValue}
                onChange={({ target }) => {
                  setSearchInputValue(target.value);
                  if (!target.value) {
                    setDropdownOpen(false);
                  }
                }}
                onClick={() => setDropdownOpen(false)}
              />
              <FontAwesomeIcon
                icon="search"
                className="position-absolute text-400 search-box-icon"
              />
              {searchInputValue && (
                <div className="search-box-close-btn-container">
                  <FalconCloseButton
                    size="sm"
                    noOutline
                    onClick={() => {
                      setSearchInputValue("");
                      dispatch(setPostsFilters({}));
                      dispatch(setForceFetchPosts(true));
                    }}
                  />
                </div>
              )}
            </Form>
          </Dropdown.Toggle>
          {!nonPostSearch() && (
            <Dropdown.Menu>
              <div className="scrollbar py-3 px-2">
                {isIterableArray(platforms) && (
                  <>
                    <Flex
                      gap={2}
                      style={{
                        marginTop: 10,
                      }}
                    >
                      <Flex direction={"column"}>
                        <Dropdown.Header
                          as="h6"
                          className="px-card pt-0 pb-2 fw-medium"
                        >
                          From
                        </Dropdown.Header>
                        <DatePicker
                          className="form-control"
                          selected={
                            filterData?.from_time_range ??
                            moment().subtract(7, "d").toDate()
                          }
                          onChange={(newDate) => {
                            setFilterData((prevFilter) => {
                              prevFilter = { ...prevFilter };
                              prevFilter.from_time_range = newDate;
                              return { ...prevFilter };
                            });
                          }}
                        />
                      </Flex>
                      <Flex direction={"column"}>
                        <Dropdown.Header
                          as="h6"
                          className="px-card pt-0 pb-2 fw-medium"
                        >
                          To
                        </Dropdown.Header>
                        <DatePicker
                          className="form-control"
                          selected={filterData?.to_time_range ?? new Date()}
                          onChange={(newDate) => {
                            setFilterData((prevFilter) => {
                              prevFilter = { ...prevFilter };
                              prevFilter.to_time_range = newDate;
                              return { ...prevFilter };
                            });
                          }}
                        />
                      </Flex>
                    </Flex>
                    <div style={{ height: 25 }}></div>
                    <Dropdown.Header
                      as="h6"
                      className="px-card pt-0 pb-2 fw-medium"
                    >
                      Platform Type
                    </Dropdown.Header>
                    <Form>
                      <Form.Select
                        alignItems={"center"}
                        value={filterData?.platform_post_type}
                        onChange={(e) => {
                          let newValue = e.target.value;
                          setFilterData((prevFilter) => {
                            prevFilter = { ...prevFilter };
                            if (newValue === "all") {
                              delete prevFilter.platform_post_type;
                            } else {
                              prevFilter.platform_post_type = newValue;
                            }
                            return { ...prevFilter };
                          });
                        }}
                      >
                        <option value={"all"}>All</option>
                        {platforms.map((item) => (
                          <option value={item.identifier}>{item.name}</option>
                        ))}
                      </Form.Select>
                    </Form>
                  </>
                )}
                <div style={{ height: 25 }}></div>
                <Dropdown.Header
                  as="h6"
                  className="px-card pt-0 pb-2 fw-medium"
                >
                  Sort Order
                </Dropdown.Header>
                <Form.Select
                  value={filterData?.sort}
                  onChange={(e) => {
                    let changedValue = e.target.value;
                    setFilterData((prevFilter) => {
                      prevFilter = { ...prevFilter };
                      prevFilter.sort = changedValue;
                      return { ...prevFilter };
                    });
                  }}
                >
                  {sortOptions.map((x) => {
                    return <option value={x.value}>{x.label}</option>;
                  })}
                </Form.Select>
                <div style={{ height: 25 }}></div>
                <Dropdown.Header
                  as="h6"
                  className="px-card pt-0 pb-2 fw-medium"
                >
                  Media Type
                </Dropdown.Header>
                <Form.Select
                  value={filterData?.media_type}
                  onChange={(e) => {
                    let changedValue = e.target.value;
                    setFilterData((prevFilter) => {
                      prevFilter = { ...prevFilter };
                      prevFilter.media_type = changedValue;
                      return { ...prevFilter };
                    });
                  }}
                >
                  {mediaOptions.map((x) => {
                    return <option value={x.value}>{x.label}</option>;
                  })}
                </Form.Select>
                {postCategories.length > 0 && (
                  <div style={{ height: 25 }}></div>
                )}
                {postCategories.length > 0 && (
                  <Dropdown.Header
                    as="h6"
                    className="px-card pt-0 pb-2 fw-medium"
                  >
                    Post Category
                  </Dropdown.Header>
                )}
                {postCategories.length > 0 && (
                  <Form.Select
                    value={filterData?.post_category}
                    onChange={(e) => {
                      let changedValue = e.target.value;
                      setFilterData((prevFilter) => {
                        prevFilter = { ...prevFilter };
                        prevFilter.post_category = changedValue;
                        return { ...prevFilter };
                      });
                    }}
                  >
                    {postCategories.map((x) => {
                      return <option value={x.name}>{x.name}</option>;
                    })}
                  </Form.Select>
                )}
                <div style={{ height: 25 }}></div>
                {/* <Dropdown.Header as="h6" className="px-card pt-0 pb-2 fw-medium">
                From
              </Dropdown.Header>
              <DatePicker
                className='form-control'
                selected={filterData?.from_time_range ?? moment().subtract(7, 'd').toDate()}
                onChange={(newDate) => {
                  setFilterData((prevFilter) => {
                    prevFilter = { ...prevFilter };
                    prevFilter.from_time_range = newDate;
                    return { ...prevFilter };
                  });
                }}
              />
              <div style={{ height: 25 }}></div>
              <Dropdown.Header as="h6" className="px-card pt-0 pb-2 fw-medium">
                To
              </Dropdown.Header>
              <DatePicker
                className='form-control'
                selected={filterData?.to_time_range ?? new Date()}
                onChange={(newDate) => {
                  setFilterData((prevFilter) => {
                    prevFilter = { ...prevFilter };
                    prevFilter.to_time_range = newDate;
                    return { ...prevFilter };
                  });
                }}
              /> */}
                {Object.entries(filterData).length > 0 && (
                  <Flex
                    className={"gap-2 mt-2"}
                    alignItems={"end"}
                    justifyContent={"end"}
                  >
                    <Button
                      onClick={() => {
                        setDropdownOpen(false);
                        setFilterData({});
                      }}
                      size="sm"
                      variant={"default"}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        setDropdownOpen(false);
                        dispatch(setPostsFilters(filterData));
                      }}
                      size="sm"
                      variant={"primary"}
                    >
                      Apply Filters
                    </Button>
                  </Flex>
                )}
              </div>
            </Dropdown.Menu>
          )}
        </Dropdown>
        {contentPlannerInPath() && (
          <Flex
            alignItems={"center"}
            style={{
              marginLeft: "1rem",
            }}
          >
            <span>Filter By:</span>
            <Form.Select
              value={selectedPostType}
              style={{
                width: 200,
                marginLeft: "1rem",
              }}
              onChange={(e) => {
                if (e.target.value === "campaigns") {
                  dispatch(setSelectedPostType(e.target.value));
                  setOpenPostCategoriesPopup(true);
                  return;
                }
                dispatch(cachePosts([]));
                dispatch(setFetchRounds(0));
                dispatch(setSinglePostType(true));
                dispatch(setSelectedPostType(e.target.value));
              }}
            >
              {postTypes.map((x) => {
                return <option value={x.value}>{x.name}</option>;
              })}
            </Form.Select>
          </Flex>
        )}
      </Flex>
      <Modal
        size="lg"
        centered
        show={openCategoriesPopup}
        onHide={() => {
          setOpenPostCategoriesPopup(false)
          // setOpenPostCategoriesPopup(false);

        }}
        onExit={() => {
          setOpenPostCategoriesPopup(false)

          // setOpenPostCategoriesPopup(false);

        }}
      >
        <Modal.Body>
          {/* <PostCategoriesPopup
              initialValue={selectedPostCategory}
              modalClose={() => {
                setOpenPostCategoriesPopup(false);
              }}
            /> */}
          <Flex
            className={"p-5"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Flex direction={"column"}>
              <Form
                style={{
                  overflowY: "auto",
                  maxHeight: 400,
                }}
                onChange={(e) => {
                  setSelectedCategory(e.target.value);
                }}
              >
                <Flex justifyContent={"center"}><h3>Your campaign list</h3></Flex>
                {!postCategories.length && <NoPostCategoriesView />}
                {postCategories.map((x, index) => {
                  return <Flex alignItems={"center"}>
                  <Form.Check
                    id={`${x?.name}-checkbox`}
                    type="radio"
                    name="campaigns"
                    label={x?.name}
                    value={x?.name}
                    checked={selectedCategory === x?.name}
                    className={"mt-2"}
                  />
                </Flex>;
                })}
              </Form>
              {selectedCategory && (
                <Button
                  onClick={() => {
                    // let postsByCategory = postCategories.find(
                    //   (x) => x.name === selectedCategory
                    // );
                    // dispatch(setSelectedPostCategory(realCategory));
                    dispatch(setPostCategoryType(selectedCategory))
                    setOpenPostCategoriesPopup(false);
                  }}
                >
                  Continue
                </Button>
              )}
            </Flex>
          </Flex>
        </Modal.Body>
      </Modal>
    </>
  );
};

MediaSearchContent.propTypes = {
  item: PropTypes.shape({
    catagories: PropTypes.string,
    url: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      img: PropTypes.string.isRequired,
      size: PropTypes.string,
      status: PropTypes.string,
    }),
    title: PropTypes.string,
    text: PropTypes.string,
    img: PropTypes.string,
    time: PropTypes.string,
    file: PropTypes.bool,
    imgAttrs: PropTypes.shape({
      class: PropTypes.string,
    }),
  }).isRequired,
};

SearchBox.propTypes = {
  autoCompleteItem: PropTypes.arrayOf(
    PropTypes.shape(MediaSearchContent.propTypes.item)
  ),
};

export default SearchBox;
