import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullCalendar from "@fullcalendar/react";
import DropdownFilter from "components/common/DropdownFilter";
import Flex from "components/common/Flex";
import TooltipBadge from "components/common/TooltipBadge";
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import ReactLoading from "react-loading";
import dayGridPlugin from '@fullcalendar/daygrid'
import { canOpenCustomDayCreator, canOpenPopularDays, getActiveWorkSpace, setOpenCustomDayCreator, setOpenPopularDays, setSearchHint } from "redux/slices/workspaceslice";
import { canRefreshPopularDays, setComposerFocused, setDisplayGetStarted, setOpenComposer, setPostsFilters, setRefreshPopularDays } from "redux/slices/postslice";
import { useDispatch, useSelector } from "react-redux";
import APIService from "http/api_service";
import FalconCardHeader from "components/common/FalconCardHeader";
import Divider from "components/common/Divider";
import PopularDaySourcesOptionView from "components/pages/workspace/events/PopularDaysSourcesPrompt";
import { getLoggedInUser } from "redux/slices/user_slice";
import CustomEventDayCreator from "components/pages/workspace/events/CustomEventDayCreator";
import IconButton from "components/common/IconButton";
import { faCalendarDay, faComment } from "@fortawesome/free-solid-svg-icons";
import { RoutePaths } from "constants";
import { setPublishablePostTextBody } from "redux/slices/post_text_body_slice";
import ContentTips from "utils/content_tips";
import DatePicker from 'react-datepicker';
import CustomDateInput from "components/common/CustomDateInput";
import { toast } from "react-toastify";
import ConfirmModal from "components/common/ConfirmModal";


// const events = [
//   { title: 'Meeting', start: new Date() }
// ]



const EventCalendar = () => {
  const calendarRef = useRef();
  const dispatch = useDispatch()
  const navigate = useDispatch()
  const workSpace = useSelector(getActiveWorkSpace);
  const openPopularDaysDialog = useSelector(canOpenPopularDays);
  const loggedInUser = useSelector(getLoggedInUser);
  const loadingEvents = useSelector(canRefreshPopularDays);
  const openCustomDayEventCreator = useSelector(canOpenCustomDayCreator);
  const [title, setTitle] = useState('');
  const [events, setEvents] = useState([]);
  console.log("🚀 ~ EventCalendar ~ events:", events)
  const [calendarApi, setCalendarApi] = useState({});
  const [visibilityOptions, setVisibilityOptions] = useState({});
  const [displayEventDetailsModal, setDisplayEventDetailsModal] = useState(false);
  const [fetchingEvents, setFetchingEvents] = useState(false);
  const [updatableDay, setUpdatableDay] = useState(null);
  const [editEvent, setEditEvent] = useState(false);
  const [eventDetails, setEventDetails] = useState({
    date: "",
    start: "",
    end: "",
    name: "",
    type: "",
    location: "",
    userCountryCode: ""
  });
  const [openEventDeletePrompt, setOpenEventDeletePrompt] = useState(false);
  const [isUpdatingEvent, setIsUpdatingEvent] = useState(false)





  const componentEnabled = (name) => {
    let panelChoices = workSpace?.panel_visibility_choices ?? {};
    let userPanelChoices = panelChoices[loggedInUser._id] ?? { [name]: true };
    if (userPanelChoices.hasOwnProperty(name)) {
      return userPanelChoices[name]
    } else {
      return true;
    }
  }

  const updateVisibilityOptions = (option) => {
    setVisibilityOptions((prevOptions) => {
      prevOptions = prevOptions ?? {};
      prevOptions[option] = true;
      return { ...prevOptions };
    });
  }


  //function to call events
  const fetchUpcomingAndPopularDays = () => {
    setFetchingEvents(true)
    APIService.fetchUpcomingPopularEvents(workSpace._id, (response, error) => {
      dispatch(setRefreshPopularDays(false));
      updateVisibilityOptions("EVENTS");
      if (response) {
        let { data } = response;
        if (data) {
          data = data.sort((a, b) => new Date(a.start) - new Date(b.start));
          data = data?.map(d => {
            delete d.rule
            d.title = d.name
            return d;
          })
        }
        setEvents(data);
        setFetchingEvents(false)

      }
      setFetchingEvents(false)
    });
  }
  //ends.


  useEffect(() => {
    fetchUpcomingAndPopularDays()
    return () => { }
  }, []);

  useEffect(() => {
    if (workSpace) {
      if (componentEnabled("EVENTS")) {
        fetchUpcomingAndPopularDays();
      } else {
        updateVisibilityOptions("EVENTS");
      }
    }
  }, [loadingEvents]);



  useEffect(() => {
    dispatch(setSearchHint("Search content planner..."));
    setCalendarApi(calendarRef.current.getApi());
    return () => {
      dispatch(setPostsFilters({}));
    }
  }, []);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // a custom render function
  function renderEventContent(eventInfo) {
    let event = eventInfo?.event?.extendedProps;
    return (
      <div style={{ boxSizing: "border-box", width: "100%", }}>
        {/* Other components like IconButton can go here */}
        <span
          onClick={() => {
            const { name, type, location, userCountryCode } = event;
            const updateData = events?.find((e) => {
              const matches =
                e?.name === name &&
                e.type === type &&
                e.location === location &&
                e.userCountryCode === userCountryCode;
              console.log("🚀 ~ updateData ~ matches:", matches)
              return matches && e;
            });

            setEventDetails(updateData);
            setUpdatableDay(updateData?.updatableEvent)
            setDisplayEventDetailsModal(true);
          }}

          style={{
            fontSize: "12px",
            width: "100%",
            whiteSpace: "nowrap", // Prevents text from wrapping
            overflow: "hidden", // Hides overflowing text
            textOverflow: "ellipsis", // Shows ellipsis for overflowing text
            display: "block", // Ensures the span behaves like a block element
            padding: "0px 5px",
            backgroundColor: "#e6effc",
            color: "#4d86d4",
            cursor: "pointer"
          }}
        >
          {event.location} {event.name}
        </span>
      </div>
    );
  }


  const CustomizationOptionsDropdown = () => {
    return (
      <Dropdown className="font-sans-serif btn-reveal-trigger">
        <Dropdown.Toggle variant="span" size="sm" className="px-1 text-600">
          <span className='text-primary'>Customize</span>
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Item className='cursor-pointer'
            as={'span'} onClick={() => dispatch(setOpenPopularDays(true))}>Edit Locations</Dropdown.Item>
          <Divider />
          <Dropdown.Item className='cursor-pointer'
            as={'span'} onClick={() => dispatch(setOpenCustomDayCreator(true))}>Add a Custom Day</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    // if (addingDay) {
    //     return;
    // }
    // setAddingDay(true);
    // setErrorMessage(null);
    setIsUpdatingEvent(true)
    APIService.upsertCustomEvent(workSpace._id,
      {
        updatableEvent:
        {
          ...updatableDay,
          name: updatableDay.name,
          date: new Date(updatableDay.date).getTime(),
          // startDate: eventDetails.start,
          // startTime: eventDetails.start.getTime(),
          // endDate: eventDetails.end,
          // endTime: eventDetails.end.getTime(),
          location: updatableDay?.location
        }
      },
      (response, error) => {
        if (error) {
          // setAddingDay(false);
          // setErrorMessage(error);
          toast.error(error.message, { theme: "colored" })
          setIsUpdatingEvent(false)
          return;
        }
        let { message } = response;
        // setAddingDay(false);
        toast.success(message, { theme: 'colored' });
        setIsUpdatingEvent(false);
        setDisplayEventDetailsModal(false)
        dispatch(setRefreshPopularDays(true));
        // dispatch(setUpdatableEventDay(null));
        // modalClose();
      });
  }

  const deleteEvent = () => {
    // toast.info("Deleting Event...", { theme: 'colored' });
    APIService.deleteCustomEventDay(workSpace._id, updatableDay._id, (response, error) => {
      if (error) {
        toast.error(error, { theme: 'colored' });
        return;
      }
      let { message } = response;
      toast.success(message, { theme: 'colored' });
      dispatch(setRefreshPopularDays(true));
    });
  }


  const editEventForm = () => {
    let itsdate = new Date("2024-02-14T00:00:00.000Z")
    console.log("🚀 ~ editEventForm ~ itsdate:", itsdate)
    return (
      <Form onSubmit={handleSubmit}>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group controlId="scheduleStartTitle">
              <Form.Label>Event Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter event name E.g Team Bonding Day"
                defaultValue={updatableDay?.name}
                value={updatableDay?.name}
                onChange={(e) => {
                  setUpdatableDay({ ...updatableDay, name: e.target.value })
                }}
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId={`ScheduleStartDate`}>
              <Form.Label>Event Date</Form.Label>
              <DatePicker
                minDate={new Date()}
                // selected={itsdate}
                selected={updatableDay?.date ? new Date(updatableDay?.date) : null}
                onChange={newDate => {
                  console.log("🚀 ~ editEventForm ~ newDate:", newDate)
                  // setStartDate(newDate);
                  setUpdatableDay({ ...updatableDay, date: newDate });
                }}
                customInput={
                  <CustomDateInput
                    formControlProps={{
                      placeholder: 'd/m/y',
                    }}
                  />
                }
              />
            </Form.Group>
          </Col>
          {/* <Col md="6">
                        <Form.Group controlId="scheduleStartTime">
                            <Form.Label>Start Time</Form.Label>
                            <DatePicker
                                selected={eventDetails?.start}
                                defaultValue={eventDetails?.start}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm"
                                onChange={newDate => {
                                    setStartTime(newDate);
                                }}
                                customInput={
                                    <CustomDateInput
                                        formControlProps={{
                                            placeholder: 'H:i',
                                        }}
                                    />
                                }
                            />
                        </Form.Group>
                    </Col> */}
          {/* <Col md="6">
            <Form.Group controlId="scheduleEndDate">
              <Form.Label>End Date</Form.Label>
              <DatePicker
                minDate={new Date()}
                // selected={eventDetails?.end}
                selected={eventDetails?.end ? new Date(eventDetails.end) : null}
                defaultValue={eventDetails?.end}
                onChange={newDate => {
                  // setEndDate(newDate);
                  setEventDetails({ ...eventDetails, end: newDate })
                }}
                customInput={
                  <CustomDateInput
                    formControlProps={{
                      placeholder: 'd/m/y',
                    }}
                  />
                }
              />
            </Form.Group>
          </Col> */}
          {/* <Col md="6">
                        <Form.Group controlId="scheduleEndTime">
                            <Form.Label>End Time</Form.Label>
                            <DatePicker
                                selected={endTime}
                                defaultValue=""
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm"
                                onChange={newDate => {
                                    setEndTime(newDate);
                                }}
                                customInput={
                                    <CustomDateInput
                                        formControlProps={{
                                            placeholder: 'H:i',
                                        }}
                                    />
                                }
                            />
                        </Form.Group>
                    </Col> */}
        </Row>
        <Col md="12" className='mt-3'>
          <Form.Group controlId="scheduleStartTitle">
            <Form.Label>Event Location</Form.Label>
            <Form.Control
              type="text"
              value={updatableDay?.location}
              placeholder="Add event Location (Optional)"
              defaultValue={updatableDay?.location}
              onChange={(e) => {
                // setLocation(e.target.value);
                setUpdatableDay({ ...updatableDay, location: e.target.value })
              }}
            />
          </Form.Group>
        </Col>
        <Divider />
        <Flex style={{ width: "100%", justifyContent: "space-between" }} gap={3} justifyContent={"space-between"} alignItems={"center"}>
          <Button
            style={{
              backgroundColor: "#6C757D",
              minHeight: 35,
            }}
            size="sm"
            className={`px-5 px-sm-4`}
            onClick={() => setEditEvent(false)}
          >Back</Button>
          <Button
            style={{

              minHeight: 35,
            }}
            disabled={isUpdatingEvent || (!updatableDay?.location || !updatableDay?.name || !updatableDay?.date) ? true : false}
            size="sm"
            className={`px-5 px-sm-4`}
            type="submit"
          >{isUpdatingEvent ? "Updating Event" : "Update"}</Button>
        </Flex>
      </Form>
    )
  }


  return (
    <>
      <Card>
        <Card.Header>
          <Row className="justify-content-between align-items-center gx-0">
            <Col xs="auto" className="d-flex order-md-0">
              <TooltipBadge
                tooltip="Click on the date to schedule a post"
                iconSize="26px"
                icon="info-circle"
              />
              <Col xs="auto">
                {fetchingEvents && (
                  <Flex className={"px-2"}>
                    <ReactLoading
                      type={"bubbles"}
                      width={50}
                      height={50}
                      color={"#0052cc"}
                    />
                  </Flex>
                )}
              </Col>
              <Col xs="auto" className="d-flex justify-content-end order-md-1">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="nextTooltip">Previous</Tooltip>}
                >
                  <Button
                    variant="link"
                    className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1 ms-md-2"
                    onClick={() => {
                      calendarApi.prev();
                      setTitle(calendarApi.getCurrentData().viewTitle);
                    }}
                  >
                    <FontAwesomeIcon icon="arrow-left" />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="previousTooltip">Next</Tooltip>}
                >
                  <Button
                    variant="link"
                    className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-lg-2"
                    onClick={() => {
                      calendarApi.next();
                      setTitle(calendarApi.getCurrentData().viewTitle);
                    }}
                  >
                    <FontAwesomeIcon icon="arrow-right" />
                  </Button>
                </OverlayTrigger>
              </Col>
              <Col xs="auto" className="d-flex justify-content-end order-md-2">
                <h4 className="mb-0 fs-0 fs-sm-1 fs-lg-2">
                  {title || `${calendarApi.currentDataManager?.data?.viewTitle}`}
                </h4>
              </Col>
            </Col>
            <Col xs="auto" className="d-flex order-md-0">
              <CustomizationOptionsDropdown />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          {/* <h1>Demo App</h1> */}
          <FullCalendar
            ref={calendarRef}
            headerToolbar={false}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin
            ]}
            themeSystem="bootstrap"
            initialView='dayGridMonth'
            events={events}
            height={800}
            stickyHeaderDates={true}
            editable={false}
            selectable
            eventContent={renderEventContent}
            weekends={workSpace?.schedulingAndCalendarSettings?.enableWeekends}
          />
        </Card.Body>
      </Card>
      <Modal
        show={openCustomDayEventCreator}
        onHide={() => dispatch(setOpenCustomDayCreator(false))}
        onExit={() => dispatch(setOpenCustomDayCreator(false))}
      >
        <Modal.Body>
          <CustomEventDayCreator modalClose={() => {
            dispatch(setOpenCustomDayCreator(false))
          }}
          />
        </Modal.Body>
      </Modal>
      <Modal
        // size="sm"
        centered
        show={displayEventDetailsModal}
        onHide={() => {
          setEditEvent(false)
          setDisplayEventDetailsModal(false)
        }}
        onExit={() => {
          setEditEvent(false)
          setDisplayEventDetailsModal(false)
        }}
      >
        {/* <Modal.Header>Event Details</Modal.Header> */}
        <Modal.Body>
          {/* <p>Event: <strong style={{ color: "#4d86d4", }}>{eventDetails?.name}</strong></p>
          <p>Location: <strong style={{ color: "#4d86d4", }}>{eventDetails?.location}</strong></p> */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 20,
              padding: 20,
              // minHeight: 300
            }}>
            <IconButton
              className={'rounded-circle'}
              iconClassName={'fs-6'}
              icon={faCalendarDay}
              style={{
                width: 100,
                height: 100
              }}
            />
            <h5 style={{ textAlign: 'center' }}>Event Details</h5>
            <Divider />
          </div>
          {!editEvent ? <div>
            <p>Event: <strong style={{ color: "#4d86d4", }}>{eventDetails?.name}</strong></p>
            <p>Location: <strong style={{ color: "#4d86d4", }}>{eventDetails?.location}</strong></p>
          </div> : (editEventForm())}
        </Modal.Body>
        {(!editEvent && eventDetails.hasOwnProperty("updatableEvent")) && <Modal.Footer>
          <Flex style={{ width: "100%", justifyContent: "space-between" }} gap={3} justifyContent={"space-between"} alignItems={"center"}>
            <Button
              style={{
                // backgroundColor: "#1e63fe",
                minHeight: 35,
              }}
              size="sm"
              className={`px-5 px-sm-4`}
              onClick={() => setEditEvent(true)}
            >Edit</Button>
            <Button
              style={{

                minHeight: 35,
              }}
              size="sm"
              className={`px-5 px-sm-4`}
              variant="danger"
              onClick={() => {
                setOpenEventDeletePrompt(true);
                setDisplayEventDetailsModal(false)
              }}
            >Delete</Button>
          </Flex>
        </Modal.Footer>}
      </Modal>
      <ConfirmModal
        open={openEventDeletePrompt}
        title={`Delete Event`}
        message={`Are you sure you want to delete the event <b>${updatableDay?.name}</b>?<br/>There is no going back, so be certain`}
        onCancel={() => {
          setOpenEventDeletePrompt(false)
          setDisplayEventDetailsModal(false)
        }}
        onConfirm={() => {
          setOpenEventDeletePrompt(false);
          deleteEvent();
        }}
        cancelText={"NO"}
        confirmText={"DELETE"}
      />
      <Modal
        show={openPopularDaysDialog}
        onHide={() => dispatch(setOpenPopularDays(false))}
        onExit={() => dispatch(setOpenPopularDays(false))}
      >
        <Modal.Body>
          <PopularDaySourcesOptionView modalClose={() => {
            dispatch(setOpenPopularDays(false))
          }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EventCalendar;
