import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import IconButton from 'components/common/IconButton';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  setComposerFocused, setDisplayGetStarted,
  setOpenComposer, setRefreshTrends
} from 'redux/slices/postslice';
import ContentTips from 'utils/content_tips';
import ConfirmModal from 'components/common/ConfirmModal';
import {
  getActiveWorkSpace,
  getRightBarSectionsPositions,
  setActiveWorkspace,
  setPanelVisibilityChoices,
  setRightBarItemPositions
} from 'redux/slices/workspaceslice';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import { formatTrendVolume } from 'utils/gen';
import { setPublishablePostTextBody } from 'redux/slices/post_text_body_slice';

const Trends = (props) => {

  const workSpace = useSelector(getActiveWorkSpace);
  const { title, trends } = props;
  const [openHideConsent, setOpenHideConsent] = useState(false);
  const rightBarPositions = useSelector(getRightBarSectionsPositions);
  const dispatch = useDispatch();

  const CustomizationOptionsDropdown = () => {
    return (
      <Dropdown className="font-sans-serif btn-reveal-trigger">
        <Dropdown.Toggle variant='span' size="sm" className="px-1 text-600">
          <span className='text-primary'>Customize</span>
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Item
            className='cursor-pointer'
            as={'span'}
            onClick={() => {
              setOpenHideConsent(true);
            }}>Hide Trends
          </Dropdown.Item>
          <Dropdown.Item
            className='cursor-pointer'
            as={'span'}
            onClick={() => {
              let rightBarPositionsCopy = { ...rightBarPositions };
              if (rightBarPositionsCopy.TRENDS === 0) {
                rightBarPositionsCopy["TRENDS"] = 1;
                rightBarPositionsCopy["EVENTS"] = 0;
              } else {
                rightBarPositionsCopy["TRENDS"] = 0;
                rightBarPositionsCopy["EVENTS"] = 1;
              }
              dispatch(setRightBarItemPositions(rightBarPositionsCopy));
            }}>{rightBarPositions.TRENDS === 0 ? "Move Down" : "Move Up"}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <Card className="mb-3">
      <Card.Header
        as={Flex}
        alignItems="center"
        justifyContent="between"
        className="bg-light"
      >
        <h5 className="mb-0">{title}</h5>
        <CustomizationOptionsDropdown />
      </Card.Header>
      <Card.Body>
        {
          trends.map((trend, index) => (
            <SingleItem
              {...trend}
              key={trend.name}
              divider={trends.length === index + 1}
            />
          ))
        }
      </Card.Body>
      <ConfirmModal
        open={openHideConsent}
        title="Hide Trends?"
        message="When you click the <b>HIDE</b> button below, we will hide the Trends section from showing up. You can however, un-hide this at any time from the Workspace Settings page."
        confirmText="HIDE"
        cancelText="NO"
        onConfirm={() => {
          setOpenHideConsent(false);
          toast.info("Updating Preference...");
          APIService.togglePanelData(workSpace._id, { "TRENDS": false }, (response, error) => {
            if (error) {
              toast.error(error, { theme: 'colored' });
              return;
            }
            let { message, data } = response;
            toast.success(message, { theme: 'colored' });
            dispatch(setPanelVisibilityChoices(data.panel_visibility_choices));
            dispatch(setActiveWorkspace({
              ...workSpace,
              panel_visibility_choices: data.panel_visibility_choices
            }));
            dispatch(setRefreshTrends(true));
          })
        }}
        onCancel={() => {
          setOpenHideConsent(false);
        }}
      />
    </Card>
  );
};

Trends.propTypes = {
  trends: PropTypes.number
};

const SingleItem = ({ name, tweet_volume: volume, divider }) => {

  const dispatch = useDispatch();

  return (
    <Flex>
      <Avatar size="2xl" name={name} />
      <div className="flex-1 ms-2">
        <h6 className="mb-0">
          <Link to="#!" style={{ textDecoration: 'none' }}>{name}</Link>
        </h6>
        <p className="fs--1 mb-0">{formatTrendVolume(parseInt(volume))} people are talking about this</p>
        <IconButton
          className="mt-1 py-0 border"
          variant="light"
          size="sm"
          icon={faComment}
          transform="shrink-5"
          onClick={async () => {
            // dispatch(setPublishablePostTextBody(await ContentTips.generateContentFromTagLine(name)))
            // dispatch(setComposerFocused(true));
            // dispatch(setOpenComposer(true));
            // dispatch(setDisplayGetStarted(false));
            // document.getElementById("draft-heads-up")?.scrollIntoView();
          }}
        >
          <span className="fs--1">Make a Post</span>
        </IconButton>
        {!divider && <div className="border-dashed-bottom my-3" />}
      </div>
    </Flex>
  );
}

SingleItem.propTypes = {
  avatarSrc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  mutual: PropTypes.number,
  divider: PropTypes.bool
};

export default Trends;
