import React, { forwardRef, useContext } from 'react'
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import { Editor } from 'react-draft-wysiwyg';
import { useState } from 'react';
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { CloseActionBox, Container } from './rich_editor_style';
import { Button } from 'react-bootstrap';
import AppContext from 'context/Context';

const RichEditor = forwardRef((props, ref) => {
   const { config } = useContext(AppContext);
   const _contentState = ContentState.createFromText("Sample content state");
   const raw = convertToRaw(_contentState); // RawDraftContentState JSON
   const [contentState, setContentState] = useState(raw);
   const [_bodyText, _setBodyText] = useState('<></>') //?? this state is used to clone and update the body text before saving to the main state (props?.bodyText) **It is for performance improvement**
   const [editorState, setEditorState] = useState(() =>
      EditorState.createWithContent(
         ContentState.createFromBlockArray(
            convertFromHTML(props?.bodyText || '<p></p>')
         )
      )
   );

   const onEditorStateChange = (editorState) => {
      let newContent = editorState.getCurrentContent();
      const rawContent = draftToHtml(convertToRaw(newContent));
      _setBodyText(rawContent)
      setEditorState(editorState);
   };

   const uploadFile = (file) => {
      console.log(file, "as file from picker")
      // uploadCallback: (file) => {
      //    return new Promise((resolve, reject) => {
      //       const xhr = new XMLHttpRequest();
      //       xhr.open("POST", "https://api.imgur.com/3/image");
      //       xhr.setRequestHeader("Authorization", "Client-ID 8e6e2c2d0f4f4c2");
      //       const data = new FormData();
      //       data.append("image", file);
      //       xhr.send(data);
      //       xhr.addEventListener("load", () => {
      //          const response = JSON.parse(xhr.responseText);
      //          resolve(response);
      //       });
      //       xhr.addEventListener("error", () => {
      //          const error = JSON.parse(xhr.responseText);
      //          reject(error);
      //       });
      //    });
      // }
   }

   return (
      <Container
         isDark={config.isDark}
      >
         <Editor
            editorStyle={{ height: "200px", border: `1px solid ${config.isDark ? '#232e3c' : ''}`, padding: "10px", backgroundColor: config.isDark ? "#0b1727" : '' }}
            toolbarStyle={{ backgroundColor: config.isDark ? "#0b1727" : '' }}
            // wrapperStyle={{ backgroundColor: "blue" }}
            toolbar={{
               options: ["inline", "blockType", "list", "textAlign", "link", "emoji", "image", "remove", "history"],
               // inline: { inDropdown: true },
               // list: { inDropdown: true },
               // textAlign: { inDropdown: true },
               // link: { inDropdown: true },
               // history: { inDropdown: true },
               blockType: {
                  inDropdown: false,
                  options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                },
               image: {
                  urlEnabled: true,
                  placeholder:"Paste image url here",
                  // uploadEnabled: true,
                  // uploadCallback: uploadFile, TODO: will be implemented later
                  previewImage: true,
                  alt: { present: true, mandatory: false },
                  inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                  defaultSize: {
                     height: 'auto',
                     width: 'auto',
                  },
                  styles: {
                     height: '100px',
                     width: '100px',
                  },
               }
            }}
            image={{
               uploadEnabled: true,


            }}
            hashtag={{
               separator: " ",
               trigger: "#",
            }}
            //   mention={{
            //     separator: ' ',
            //     trigger: '@',
            //     suggestions: [
            //       { text: 'JavaScript', value: 'javascript', url: 'js' },
            //       { text: 'Golang', value: 'golang', url: 'go' },
            //     ],
            //   }}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            placeholder="Enter blog post here."
            // defaultContentState={'Enter descrioption'}
            // onContentStateChange={setContentState}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
         />
         <CloseActionBox>
            <Button
               size={'sm'}
               variant={"primary"}
               onClick={props?.handleCancel}
            >
               Cancel
            </Button>
            <Button
               size={'sm'}
               variant={"primary"}
               onClick={() => props?.handleSave(_bodyText)}
            >
               Save Changes
            </Button>
         </CloseActionBox>
      </Container>
   )
})

export default RichEditor