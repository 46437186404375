import Flex from 'components/common/Flex'
import APIService from 'http/api_service'
import React, { useEffect, useState } from 'react'
import { Button, Card, Spinner } from 'react-bootstrap'
import { ImCompass } from "react-icons/im"
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { setPopupUpgradeAlert } from 'redux/slices/alertSlice'
import { getActiveWorkSpace } from 'redux/slices/workspaceslice'
import { useDispatch } from 'react-redux'
import { setCollectMetric } from 'redux/slices/analytics_slice'
import { MetricsProps } from 'constants'

const CommunityPrompts = (props) => {
    const dispatch = useDispatch();
    const { communityPrompts, setCommunityPrompts, handleUsePrompt, triggerFetchPrompt, setOpenUpgradeDialog, userOnFreePlan } = props
    const workSpace = useSelector(getActiveWorkSpace);
    const workSpaceId = workSpace._id;
    const [loading, setLoading] = useState(true)


    const trimWordsIntoNcharacters = (word, numOfChars) => {
        if (word.length > numOfChars) {
            return word.substring(0, numOfChars) + "..."
        } else {
            return word
        }
    }

    const fetchPrompts = () => {
        setLoading(true);
        APIService.getPrompts(workSpaceId, "", null, (res, err) => {
            setLoading(false);
            if (err) {
                toast.error("Error fetching community prompts", { theme: "colored" })
                return
            }
            setCommunityPrompts(res?.data)
        })
    };

    useEffect(() => {
        fetchPrompts()
    }, [triggerFetchPrompt])


    const displayPrompts = () => {
        return communityPrompts.map((prompt, index) => {
            return (
                <Card
                    style={{
                        width: '100%'
                    }}
                    key={index}
                >
                    <Card.Body>
                        <Flex
                            direction={'column'}
                            style={{
                                width: '100%'
                            }}
                        >
                            <Card.Title>
                                {prompt?.title}
                            </Card.Title>
                            <Card.Text>
                                {trimWordsIntoNcharacters(prompt?.description, 80)}
                            </Card.Text>

                            <Flex
                                justifyContent={'between'}
                                alignItems={'center'}
                            >
                                <p
                                    style={{
                                        width: '70%'
                                    }}
                                    className="fs--1"
                                >
                                    Source: {" "} {trimWordsIntoNcharacters(prompt?.source, 39)}
                                </p>
                                <Button
                                    size='sm'
                                    variant={'primary'}
                                    onClick={() => {
                                        if (userOnFreePlan() === true) {
                                            let metricInitializer = {
                                                action: MetricsProps.AI_CHAT_TOOL,
                                                detailedAction: `Upgrade notice: User clicked on use prompt button`,
                                                timestamp: new Date().toISOString(),
                                                route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
                                              }
                                              dispatch(setCollectMetric(metricInitializer))
                                            dispatch(setPopupUpgradeAlert(true))
                                            return;
                                          }
                                        handleUsePrompt(prompt)
                                    }}
                                >
                                    <ImCompass />  Use this
                                </Button>
                            </Flex>
                        </Flex>
                    </Card.Body>
                </Card>

            )
        })
    }
    if (loading) {
        return <Flex
            width="100%"
            justifyContent={'center'}
            alignItems={'center'}
            className={'mt-3'}
            direction={'column'}
        >
            <Spinner animation='border' size={30} />
            <div
                className="fs--3 mt-3"
                style={{
                    textAlign: 'center'
                }}
            >
                Browsing prompts...
            </div>
        </Flex>
    }

    if (!communityPrompts?.length) {
        return <Flex>
            <div
                className="fs--3 mt-3"
                style={{
                    textAlign: 'center'
                }}
            >
                Community prompts will appear here.
            </div>
        </Flex>
    }

    return (
        <Flex
            direction={'column'}
            width="100%"
            className={'mt-3'}
            gap={3}

        >
            <Flex
                justifyContent={'between'}
                direction={'column'}
                style={{
                    width: '100%'
                }}
                gap={2}
            >
                {displayPrompts()}
            </Flex>
        </Flex>
    )
}


export default CommunityPrompts