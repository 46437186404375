import React, { useEffect, useState } from 'react';
import AuthCardLayout from 'layouts/AuthCardLayout';
import PasswordLessSignInForm from '../PasswordLessSignInForm';
import PasswordLessConfirmation from '../PasswordLessConfirmation';

const PasswordLess = () => {

    const [activeState, setActiveState] = useState(0);

    const passwordLessStateHandler = (newState) => {
        setActiveState(newState);
    }

    useEffect(() => {
        let params = (new URL(document.location)).searchParams;
        let state = params.get("state");
        if (state) {
            setActiveState(parseInt(state));
        }
    }, []);

    return (
        <AuthCardLayout>
            {
                activeState === 0 &&
                <h4 className="mb-0">Password free sign In?</h4>
            }
            {
                activeState === 0 &&
                <p className="mb-0">We'll email you a magic code for a password-free
                    sign in.
                </p>
            }
            {
                activeState === 0 ?
                    <PasswordLessSignInForm stateHandler={passwordLessStateHandler} /> :
                    <PasswordLessConfirmation />
            }
        </AuthCardLayout>
    )
}
export default PasswordLess;
