import styled, { keyframes } from "styled-components";

export const Container = styled.section`
 .rdw-image-modal{
   background-color: ${props => props?.isDark ? '#121e2d !important' : ''};
   color:${props => props?.isDark ? 'white !important' : ''};
   border-color: ${props => props?.isDark ? '#232e3c !important' : ''};
   /* color:white !important; */
   box-shadow: ${props => props?.isDark ? 'none !important' : ''};
   /* box-shadow: none; */
   .rdw-image-modal-url-input{
      border-color: ${props => props?.isDark ? '#232e3c !important' : ''};
      background-color: ${props => props?.isDark ? '#0b1727 !important' : ''};
      color: ${props => props?.isDark ? 'whitesmoke' : ''};
   }
   .rdw-image-modal-alt-input{
      border-color: ${props => props?.isDark ? '#232e3c !important' : ''};
      background-color: ${props => props?.isDark ? '#0b1727 !important' : ''};
      color: ${props => props?.isDark ? 'whitesmoke' : ''};
   }
   .rdw-image-modal-size-input{
      border-color: ${props => props?.isDark ? '#232e3c !important' : ''};
      background-color: ${props => props?.isDark ? '#0b1727 !important' : ''};
      color: ${props => props?.isDark ? 'whitesmoke' : ''};
   }
   .rdw-image-modal-btn{
      border-color: ${props => props?.isDark ? '#232e3c !important' : ''};
      background-color: ${props => props?.isDark ? '#0b1727 !important' : ''};
      color: ${props => props?.isDark ? 'whitesmoke' : ''};
   }
   
 }
 .rdw-editor-toolbar{
      border-color: ${props => props?.isDark ? '#232e3c !important' : ''};
   }
`;
export const CloseActionBox = styled.div`
   height: 80px;
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   align-items: center;
   gap: 20px;
`;