import FalconCardHeader from 'components/common/FalconCardHeader';
import APIService from 'http/api_service';
import React, { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getLoggedInUser, setLoggedInUser } from 'redux/slices/user_slice';
import Flex from "components/common/Flex";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";

const ChangePassword = () => {

  const dispatch = useDispatch();
  const loggedInUser = useSelector(getLoggedInUser);
  const [isPasswordSet] = useState(loggedInUser.has_password);
  const [updating, setUpdating] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false); 

  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    let newPassword = formData.newPassword;
    let oldPassword = formData.oldPassword;
    let confirmPassword = formData.confirmPassword;
    if (newPassword !== confirmPassword) {
      toast.error("Sorry, passwords didn't match", { theme: 'colored' });
      return;
    }
    setUpdating(true);
    APIService.updateSecurityInfo(isPasswordSet ? oldPassword : newPassword, confirmPassword, (response, error) => {
      if (error) {
        toast.error(error, { theme: 'colored' });
        setUpdating(false);
        return;
      }
      let { message } = response;
      APIService.fetchLatestMe((latestMeResponse, latestMeError) => {
        if (latestMeError) {
          setUpdating(false);
          toast.error(latestMeError, { theme: 'colored' });
          return;
        }
        let { data } = latestMeResponse;
        dispatch(setLoggedInUser({ ...data }));
        toast.success(message, { theme: 'colored' });
        setUpdating(false);
        setFormData({ oldPassword: '', newPassword: '', confirmPassword: '' })
      });
    });
  };

  const disabledCriteria = () => {
    if (isPasswordSet) {
      return !formData.oldPassword || !formData.newPassword || !formData.confirmPassword || updating
    }
    return !formData.newPassword || !formData.confirmPassword || updating;
  }

  return (
    <Card className="mb-3">
      <FalconCardHeader title={isPasswordSet ? "Change Password" : "Add Password"} />
      <Card.Body className="bg-light">
        {
          !isPasswordSet &&
          <p className='fs--1'>Add password to your account for extra security</p>
        }
        <Form onSubmit={handleSubmit}>
          {
            isPasswordSet &&
            <Form.Group className="mb-3" controlId="oldPassword">
              <Form.Label>Old Password</Form.Label>
                <Flex>
                  <Form.Control
                      type={oldPasswordVisible ? "text" : "password"}
                      value={formData.oldPassword}
                      name="oldPassword"
                      onChange={handleChange}
                    />
                  <Button
                    type="button"
                    onClick={() => setOldPasswordVisible((prevState) => !prevState)}
                  >
                  {oldPasswordVisible ? <FaEye /> : <FaEyeSlash /> }
              </Button>
                </Flex>
            </Form.Group>
          }
          <Form.Group className="mb-3" controlId="newPassword">
            <Form.Label>New Password</Form.Label>
            <Flex>
              <Form.Control
              type={passwordVisible ? "text" : "password"}
              value={formData.newPassword}
              name="newPassword"
              onChange={handleChange}
              />
              <Button
                    type="button"
                    onClick={() => setPasswordVisible((prevState) => !prevState)}
                  >
                  {passwordVisible ? <FaEye /> : <FaEyeSlash /> }
              </Button>
            </Flex>
          </Form.Group>
          <Form.Group className="mb-3" controlId="confirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Flex>
            <Form.Control
              type={visibleConfirmPassword ? "text" : "password"}
              value={formData.confirmPassword}
              name="confirmPassword"
              onChange={handleChange}
            />
              <Button
                    type="button"
                    onClick={() => setVisibleConfirmPassword((prevState) => !prevState)}
                  >
                  {visibleConfirmPassword ? <FaEye /> : <FaEyeSlash /> }
              </Button>
            </Flex>
          </Form.Group>
          <Button
            className="w-100"
            type="submit" disabled={disabledCriteria()}>
            {
              updating
                ? (isPasswordSet ? "Updating..." : "Adding...")
                : (isPasswordSet ? "Update Password" : "Add Password")
            }
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ChangePassword;
