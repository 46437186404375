import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from "components/common/Flex";
import { useSelector } from "react-redux";
import { getLoggedInUser } from "redux/slices/user_slice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WhiteLabelInfoAlertCard = () => {
  const [show, setShow] = useState(true);
  const loggedInUser = useSelector(getLoggedInUser);
  // const [currentPlan] = useState(loggedInUser.active_plan);

  return (
    show && (
      <div className="settings ">
        <hr />
        <Card className="p-0 rounded-2 position-relative">
          <div
            className="position-absolute"
            style={{ right: '6px', top: '3px' }}
          >
            <FalconCloseButton
              size="sm"
              className="fs--2"
              noOutline
              onClick={() => setShow(false)}
            />
          </div>
          <Card.Body>
          <Flex>
              <FontAwesomeIcon icon="exclamation-triangle" className="fs-2 text-info" />
              <div className="ms-1 flex-1">
                  <h4 className="alert-heading text-info">Please note</h4>
              </div>
          </Flex>
            <ul className="ms-0 ps-card mb-2">
                <li className="py-1" >
                    Share your domain with us after verification. Send it as an email to support@postly.ai with the subject: White-label domain.
                </li>
                <li className="py-1">
                    You can use the Postly domain or your primary domain for your White-label                                                
                </li>
                <li className="py-1">
                    After you verify your domain, subscribe to the White-label plan to get started.                                               
                </li>
                <li className="py-1">
                    Your domain will be active for as long as you have an active subscription.                                               
                </li>
                <li className="py-1">
                    Stripe connect (receive payments from your clients).                                              
                </li>
                <li className="py-1">
                    Email and report customizations are automatically enabled once you set up your branding and custom email address                                               
                </li>
                <li className="py-1">
                    You cannot have 2 root domains at the same time. If you already have one, then you need to add a subdomain                                          
                </li>
            </ul>
          </Card.Body>
        </Card>
      </div>
    )
  );
};

export default WhiteLabelInfoAlertCard;
