import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    billYearlyForWhiteLabelStripeConnect: false,
};

export const whiteLabelSlice = createSlice({
    name: 'whitelabel',
    initialState,
    reducers: {
        setBillYearlyForWhiteLabelStripeConnect: (state, action) => {
            state.billYearlyForWhiteLabelStripeConnect = action.payload;
        }
    }
});

export const getBillYearlyForWhiteLabelStripeConnect = (state) => state.whitelabel.billYearlyForWhiteLabelStripeConnect;

export const {
    setBillYearlyForWhiteLabelStripeConnect
} = whiteLabelSlice.actions;
export default whiteLabelSlice.reducer;