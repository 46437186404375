import Divider from "components/common/Divider";
import Flex from "components/common/Flex";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { ordinalSuffixOf } from "utils/gen";
import SpecificTimeAndExpirationView from "./SpecificTimeAndExpirationView";
import WhenToBeginRepeatInfoBar from "./WhenToBeginRepeatInfoBar";

const MonthlyRepeatOptionView = ({
    presetOptions,
    startFrom,
    commitSelectedRecurringSettings,
    commitStopTime,
    commitSpecificTime,
    ...others
}) => {

    const [dayOfEveryMonthToRepeat, setDayOfEveryMonthToRepeat] = useState(presetOptions['day_of_month_to_repeat']);

    const daysOfMonth = [...[...Array(28).keys()].map(x => {
        let y = x + 1;
        return {
            label: y,
            value: `${y} of the Month`
        }
    }), {
        label: "End",
        value: "End of the Month"
    }];

    const weekDaysOptions = ["1st", "2nd", "3rd", "4th"];
    const weeks = [
        {
            label: 'Sunday',
            value: 'sunday'
        },
        {
            label: 'Monday',
            value: 'monday'
        },
        {
            label: 'Tuesday',
            value: 'tuesday'
        },
        {
            label: 'Wednesday',
            value: 'wednesday'
        },
        {
            label: 'Thursday',
            value: 'thursday'
        },
        {
            label: 'Friday',
            value: 'friday'
        },
        {
            label: 'Saturday',
            value: 'saturday'
        }
    ];

    useEffect(() => {
        if (dayOfEveryMonthToRepeat) {
            commitSelectedRecurringSettings({
                day_of_month_to_repeat: dayOfEveryMonthToRepeat,
                specific_time_to_repeat: presetOptions?.specific_time_to_repeat ?? new Date(),
                stop_time: presetOptions?.stop_time ?? "never"
            })
        }
    }, [dayOfEveryMonthToRepeat]);

    const MonthlyOption = ({ title, options, size = 6 }) => {

        return (
            <Form.Group as={Col} lg={size} className={'mb-3'}>
                <Form.Label>{title}</Form.Label>
                <Form.Select
                    value={dayOfEveryMonthToRepeat}
                    onChange={(e) => {
                        let value = e.target.value;
                        setDayOfEveryMonthToRepeat(value);
                    }}>
                    <option hidden>Pick an option</option>
                    {
                        options.map(x => {
                            return (<option value={x.value}>{x.label}</option>)
                        })
                    }
                </Form.Select>
            </Form.Group>
        )
    }

    const data = [
        // {
        //     title: "Date",
        //     options: daysOfMonth
        // },
        ...weekDaysOptions.map(x => {
            return {
                title: x,
                options: weeks.map(week => {
                    return {
                        label: week.label,
                        value: `${x} ${week.value} of the Month`
                    }
                })
            }
        })
    ];

    const withOrdinalSuffix = (input) => {
        let inputSplit = input.toString().split(" ");
        let first = inputSplit[0];
        if (!isNaN(first)) {
            inputSplit[0] = ordinalSuffixOf(first);
            return inputSplit.join(" ");
        }
        return input;
    }

    return (
        <Flex direction={'column'}>
            {
                dayOfEveryMonthToRepeat &&
                <p className="mb-3"><b>Repeat Every:</b> <span>{withOrdinalSuffix(dayOfEveryMonthToRepeat)}</span></p>
            }
            <Row className="mb-3">
                {
                    data.map((x, index) => {
                        // if (index === 0) {
                        //     x.size = 12
                        // }
                        return (<MonthlyOption {...x} />)
                    })
                }
            </Row>
            <SpecificTimeAndExpirationView
                presetOptions={presetOptions}
                commitStopTime={commitStopTime}
                commitSpecificTime={commitSpecificTime}
            />
            {
                dayOfEveryMonthToRepeat &&
                <WhenToBeginRepeatInfoBar
                    startFrom={startFrom}
                    feedBackData={{
                        day_of_month_to_repeat: dayOfEveryMonthToRepeat,
                        specific_time_to_repeat: presetOptions?.specific_time_to_repeat ?? new Date(),
                        stop_time: presetOptions?.stop_time ?? "never"
                    }}
                    {...others}
                />
            }
            <Divider />
        </Flex>
    );
}
export default MonthlyRepeatOptionView;