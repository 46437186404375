import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import { initFacebookSdk } from './utils/platform_utils';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import './i18n';

initFacebookSdk().then(startApp);

function startApp() {
  const container = document.getElementById('main');
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <Main>
          <App />
        </Main>
      </Provider>
    </React.StrictMode>
  );
}
