import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getBilledYearly } from "redux/slices/workspaceslice";
import { useSelector } from "react-redux";
import {
  faCheckCircle,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import MonthlyYearlySwitch from "./MonthlyYearlySwitch";
import IconButton from "components/common/IconButton";
import { getLoggedInUser } from "redux/slices/user_slice";

const moreItems = [
  {
    id: 1,
    label: "Unlimited AI Writer",
    icon: "infinity",
  },
  {
    id: 2,
    label: "Unlimited AI Art",
    icon: "infinity",
  },
  { id: 3, label: "Unlimited Blog Writer", icon: "infinity" },
  {
    id: 4,
    label: "Unlimited Hashtag Generator",
    icon: "infinity",
  },
  {
    id: 5,
    label: "Unlimited Access to all features",
    icon: "infinity",
  },
];

const AIWriterAddon = ({
  pricing: { buttonText = "Subscribe To AI Writer", isFeatured },
  handleBilling,
  handleAIWriterSubscriptionCancel,
  cancellingAIWriterSubscription,
}) => {

  const loggedInUser = useSelector(getLoggedInUser);
  const billedYearly = useSelector(getBilledYearly);

  const hashUpdatableUrl = () => {
    let activeSubscription = loggedInUser?.active_ai_writer_subscription ?? {};
    let cancelUrl = activeSubscription.cancel_url;
    let updateUrl = activeSubscription.update_url;
    if (updateUrl) {
      return updateUrl;
    }
    if (cancelUrl) {
      return cancelUrl.replace("cancel", "update");
    }
    return null;
  };

  const updatableUrl = hashUpdatableUrl();

  return (
    <Col md>
      <Card className="border rounded-3 overflow-hidden mb-3 mb-md-0">
        {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="position-relative">
            <div className="position-absolute top-0 start-0">
              <span className="bg-primary text-white py-1 px-3">Addon</span>
            </div>
          </div>
          {loggedInUser?.active_ai_writer_subscription ? (
            <span
              style={{
                display: "flex",
                gap: 5,
                alignItems: "center",
              }}
            >
              <span className="text-success">
                Subscribed{" "}
                {loggedInUser?.active_ai_writer_subscription?.subscriptionMode}
              </span>
              <FontAwesomeIcon icon={faCheckCircle} color={"#00d27a"} />
            </span>
          ) : (
            <MonthlyYearlySwitch />
          )}
        </div> */}
        <Card.Body className="bg-white">
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h5 className="mb-3 text-600 mt-0">Do More With Unlimited AI</h5>
            <MonthlyYearlySwitch />
            <h3 className="mb-2 text-800 mt-2 mb-3">
              {billedYearly ? "$288" : "$30"}
              <span className="fs-0 fw-medium font-sans-serif text-600">
                {billedYearly ? "/year" : "/month"}
              </span>
            </h3>
          </div>
          <div className="bg-light">
            <Row className="fs--1 fw-semi-bold text-500 mb-0">
              <ul className="fa-ul ms-4 ps-card mb-2">
                {moreItems.map((item) => (
                  <li key={item.id} className="py-1">
                    <h6 className="text-700">
                      <FontAwesomeIcon
                        icon={item.icon}
                        className="fa-li text-success"
                      />
                      {item.label}
                    </h6>
                  </li>
                ))}
              </ul>
            </Row>
            <IconButton
              icon="robot"
              iconClassName="me-2"
              disabled={cancellingAIWriterSubscription}
              onClick={() => {
                if (loggedInUser?.active_ai_writer_subscription) {
                  handleAIWriterSubscriptionCancel();
                } else {
                  handleBilling();
                }
              }}
              variant={
                loggedInUser?.active_ai_writer_subscription
                  ? "outline-danger"
                  : isFeatured
                    ? "primary"
                    : "success"
              }
              className="d-block w-100"
            >
              {loggedInUser?.active_ai_writer_subscription
                ? cancellingAIWriterSubscription
                  ? "CANCELLING SUBSCRIPTION"
                  : "CANCEL SUBSCRIPTION"
                : buttonText}
            </IconButton>
            {updatableUrl && <div className="mt-3"></div>}
            {updatableUrl && (
              <a
                href={updatableUrl}
                className={"text-decoration-none cursor-pointer"}
              >
                <Flex
                  className={"gap-1 text-warning"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <FontAwesomeIcon
                    style={{ width: 10, height: 10 }}
                    icon={faPen}
                  />
                  <span className="fs--1">Update Payment Details</span>
                </Flex>
              </a>
            )}
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};
export default AIWriterAddon;
