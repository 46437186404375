import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import {
  faCircleExclamation,
  faMarker,
  faRobot,
  faTimes,
  faUserGroup
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import APIService from 'http/api_service';
import { getActiveWorkSpace, getCachedWorkSpaces, setActiveWorkspace, setRefreshNotificationsCount } from 'redux/slices/workspaceslice';
import { useDispatch, useSelector } from 'react-redux';
import { setFailedPostData, setOpenPostDetailsDialog } from 'redux/slices/postslice';
import { findPlatformByIdentifier } from 'components/app/platforms';
import { PlatformIdentifier, RoutePaths } from '../../constants';
import { useNavigate } from 'react-router-dom';
import StringUtils from 'utils/string';
import { getLoggedInUserFullName } from 'redux/slices/user_slice';

const Notification = ({ notification, flush, handleToggle }) => {

  const dispatch = useDispatch();
  let { action } = notification;
  const navigate = useNavigate();
  const fullName = useSelector(getLoggedInUserFullName);
  const activeWorkSpace = useSelector(getActiveWorkSpace);
  let cachedWorkSpaces = useSelector(getCachedWorkSpaces);

  const getNotificationIcon = () => {
    if (!notification.bigIcon) {
      if (action.notification_type === 'join_workspace') {
        return { icon: faUserGroup };
      }
      if (action.notification_type === 'approve_post') {
        return { icon: faMarker };
      }
      if (action.notification_type === 'post_disapproved') {
        return { icon: faTimes, iconColor: 'red' };
      }
      if (action.notification_type === 'post_failed') {
        return { icon: faCircleExclamation };
      }
      if (action.notification_type === "telegram_bot_created") {
        return faRobot;
      }
    }
    return null;
  }

  const truncatedNotificationMessage = () => {
    let message = notification.message;
    if (message > 100) {
      message = `${message.substring(0, 100)}...`;
    }
    return message;
  }

  const resolveNotification = (canClick = true) => {
    APIService.updateNotificationReadStatus(notification._id, true, (response, error) => {
      dispatch(setRefreshNotificationsCount(true));
    });
    handleToggle();
    let {
      canNavigate = true,
      notification_type,
      failedReason,
      postTextBody,
      fullTargetName,
      mediaUrls,
      account
    } = action;
    if (notification_type !== 'join_workspace') {
      let workSpaceInNotificationAction = action.workSpace;
      if (activeWorkSpace._id !== workSpaceInNotificationAction) {
        if (cachedWorkSpaces) {
          let neededWorkSpace = cachedWorkSpaces.find((space) => space._id === workSpaceInNotificationAction);
          if (neededWorkSpace) {
            dispatch(setActiveWorkspace({ ...neededWorkSpace }));
          }
        }
      }
    }
    if (notification_type === 'post_failed') {
      dispatch(setFailedPostData({
        postTextBody, failedReason, mediaUrls, fullTargetName
      }));
    }
    if (notification_type === "telegram_bot_created") {
      account = {
        ...account,
        target: PlatformIdentifier.TELEGRAM,
        ...findPlatformByIdentifier(PlatformIdentifier.TELEGRAM)
      };
      navigate(`${RoutePaths.PLATFORMS}/telegram/${Buffer.from(JSON.stringify(account)).toString('hex')}`)
      return;
    }
    if (canNavigate) {
      navigate(action.routePath);
    }
    if (action?.routePath?.includes("posts/")) {
      let postId = StringUtils.substringAfterLast(action.routePath, "/");
      dispatch(setOpenPostDetailsDialog({ open: true, data: postId }));
      let workSpaceId = StringUtils.substringBeforeLast(StringUtils.substringAfter(action.routePath, "/"), "/posts");
      if (activeWorkSpace?._id !== workSpaceId) {
        navigate(`${RoutePaths.SWITCH_WORKSPACE}?to=${workSpaceId}`);
      }
    }
  }

  return (
    <span
      className={classNames(
        'notification',
        'cursor-pointer',
        { 'notification-unread': !notification.read, 'notification-flush': flush },
      )}
      onClick={() => {
        resolveNotification();
      }}
    >
      <div className="notification-avatar">
        <Avatar icon={getNotificationIcon()?.icon}
          iconColor={getNotificationIcon()?.iconColor}
          className="me-3" />
      </div>
      <div className="notification-body">
        <p className="mb-1" dangerouslySetInnerHTML={{ __html: truncatedNotificationMessage()?.replace("{recipientName}", fullName) }} />
        <span className="notification-time">
          {moment(notification.created_at)?.calendar()}
        </span>
      </div>
    </span>
  )
}

Notification.propTypes = {
  avatar: PropTypes.shape(Avatar.propTypes),
  className: PropTypes.string,
  unread: PropTypes.bool,
  flush: PropTypes.bool,
  emoji: PropTypes.string,
  children: PropTypes.node
};

Notification.defaultProps = { unread: false, flush: false };

export default Notification;
