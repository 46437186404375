import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { WorkSpaceRole } from '../../../constants';
import { useSelector } from 'react-redux';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';

const ApprovalOptionItem = (props) => {

    const { editable = true } = props;
    const [selected, setSelected] = useState(props.selected);
    const currentWorkSpace = useSelector(getActiveWorkSpace);
    const [canSwitchApprovalOption, setCanSwitchApprovalOption] = useState(true);

    useEffect(() => {
        if (currentWorkSpace) {
            if (currentWorkSpace.role === WorkSpaceRole.EDITOR
                || currentWorkSpace.role === WorkSpaceRole.VIEWER) {
                setCanSwitchApprovalOption(false);
            }
        }
    }, []);

    const handleOnCardClick = () => {
        props.selectedApprovalOptionHandler(props.index);
    }

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    return (
        <Form.Check
            type="radio"
            id={`${props.index}`}
            label={props.description}
            className="form-label-nogutter"
            value={props.index}
            name="approval_option"
            onChange={handleOnCardClick}
            checked={selected}
            disabled={!editable || !canSwitchApprovalOption}
        />
    );
}

export default ApprovalOptionItem;