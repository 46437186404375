import React, { useEffect, useState, useRef } from "react";

import { Brush } from "@visx/brush";
import { scaleTime, scaleLinear } from "@visx/scale";
import { Bounds } from "@visx/brush/lib/types";
import BaseBrush, {
  BaseBrushState,
  UpdateBrush,
} from "@visx/brush/lib/BaseBrush";
import { PatternLines } from "@visx/pattern";
// import { BrushHandleRenderProps } from '@visx/brush/src/BrushHandle';
import { Group } from "@visx/group";
import { LinearGradient } from "@visx/gradient";
import appleStock, { AppleStock } from "@visx/mock-data/lib/mocks/appleStock";

export const accentColor = "#f6acc8";
export const background = "#584153";
export const background2 = "#af8baf";

function VideoEditorTimeline({ compact = false, width, height }) {
  const stock = appleStock.slice(1000);
  const brushRef = useRef(null);
  const [filteredVideo, setFilteredVideo] = useState(stock);

  const onBrushChange = (domain) => {
    if (!domain) return;
    const { x0, x1, y0, y1 } = domain;
  };

  return <div>Video Editor Timeline</div>;
}

const BrushHandle = (x, height, isBrushActive) => {
  const pathWidth = 8;
  const pathHeight = 15;
  if (!isBrushActive) {
    return null;
  }
  return (
    <Group left={x + pathWidth / 2} top={(height - pathHeight) / 2}>
      <path
        fill="#f2f2f2"
        d="M -4.5 0.5 L 3.5 0.5 L 3.5 15.5 L -4.5 15.5 L -4.5 0.5 M -1.5 4 L -1.5 12 M 0.5 4 L 0.5 12"
        stroke="#999999"
        strokeWidth="1"
        style={{ cursor: "ew-resize" }}
      />
    </Group>
  );
};
export default VideoEditorTimeline;
