import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getLoggedInUser } from 'redux/slices/user_slice';

export default function DeleteAccountVerification(props) {

    const { modalClose, confirmationDoneCallback } = props;
    const [errorMsg] = useState();
    const [confirmationText, setConfirmationText] = useState();
    const correctText = "DELETE MY ACCOUNT";
    const loggedInUser = useSelector(getLoggedInUser);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (confirmationText === loggedInUser['email']) {
            confirmationDoneCallback(true);
        }
    }

    return (
        <form
            onSubmit={handleSubmit}
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
                padding: 20
            }}>
            <h5 type={'h3'}>Confirm Account Deletion</h5>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 10
            }}>
                <Form.Group>
                    <Form.Label>Type your email to confirm</Form.Label>
                    <Form.Control
                        type='text'
                        required
                        placeholder={loggedInUser['email']}
                        value={confirmationText}
                        onChange={(e) => {
                            setConfirmationText(e.target.value);
                        }}
                    />
                </Form.Group>
            </div>
            {
                errorMsg &&
                <span style={{ textAlign: 'center', color: 'red' }}>{errorMsg}</span>
            }
            <div
                style={{
                    display: 'flex',
                    gap: 10,
                    alignSelf: 'flex-end'
                }}>
                <Button
                    type={'button'}
                    variant={'empty'}
                    onClick={modalClose}>CANCEL
                </Button>
                <Button
                    disabled={confirmationText !== loggedInUser['email']}
                    type={'submit'}
                    variant={'danger'}
                >
                    {correctText}
                </Button>
            </div>
        </form>
    );
}