import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getWordpressCategories, setWordpressCategories } from "redux/slices/postslice";
import { CategoryItemContainer, CategoryItem } from "./previewer/styles/wordpress";
import { convertToTitleFormat } from "utils/platform_utils";

const WordpressCategories = (props) => {
    const {
        category,
        title,
        setCategory,
        categories,
        openCategoryField,
        openCategoryView,
        setOpenCategoryView,
        setOpenCategoryField,
        selectedCategory,
        setSelectedCategory,
    } = props;

    const dispatch = useDispatch();
    const wordpressCategory = useSelector(getWordpressCategories);
    const KeyCodes = {
        comma: 188,
        enter: 13
    };
    const delimiters = [KeyCodes.comma, KeyCodes.enter];
    const [categoryItem, setCategoryItem] = useState('');

    const handleAddCategory = tag => {
        setSelectedCategory([...selectedCategory, tag])
    }

    const handleDeleteCategory = i => {
        let newCategory = wordpressCategory.filter((category, index) => index !== i);
        setSelectedCategory(newCategory);
    }

    useEffect(() => {
        console.log(category, "#categories");
    }, [category]);

    return (
        <>
            <Form.Group>
                <Form.Label>{title}</Form.Label>
                {!categories.length &&
                    <div style={{ fontSize: "13px" }}>
                        (No Categories)
                    </div>
                }
                {categories?.length > 0 &&
                    <>
                        <Form.Select
                            value={categoryItem}
                            placeholder={'Select Category'}
                            onChange={(e) => {
                                let item = e.target.value;
                                setCategoryItem(item);
                                setCategory((prevCategory) => {
                                    let categories = { ...prevCategory };
                                    if (!categories[title]) {
                                        categories[title] = [item];
                                    } else if (!categories[title].includes(item)) {
                                        categories[title] = [...categories[title], item];
                                    }
                                    return categories;
                                });
                                dispatch(setWordpressCategories({ ...category }));
                            }}
                        >
                            {
                                categories?.map((x) => {
                                    return (<option value={x.slug}>{x.name}</option>)
                                })
                            }
                        </Form.Select>
                    </>
                }
                <CategoryItemContainer>
                    {category[title] &&
                        category[title].map(x => {
                            return (
                                <CategoryItem>
                                    {convertToTitleFormat(x)}
                                </CategoryItem>
                            )
                        })}
                </CategoryItemContainer>
            </Form.Group >
        </>
    )
}

export default WordpressCategories;