import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import Flex from 'components/common/Flex';
import APIService from 'http/api_service';
import { toast } from 'react-toastify'
import FreePageHeader from 'components/common/FreePageHeader';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { getInstagramAccountPageId, getInstagramUserId, setInstagramUserId } from 'redux/slices/instagramComments_slice';

const InstaCommentsHeader = ({ platform }) => {

    const dispatch = useDispatch();
    const userId = useSelector(getInstagramUserId);
    const [selectedAccount, setSelectedAcccount] = useState({});
    const workSpace = useSelector(getActiveWorkSpace);
    const identifier = platform.identifier;
    const [connectedPlatforms, setConnectedPlatforms] = useState([]);

    const fetchWorkSpacePlatforms = () => {
        APIService.fetchWorkSpacePlatforms(workSpace['_id'], (response, error) => {
            if (error) {
                if (error?.toLowerCase() === `WorkSpace ID is required`.toLowerCase()) {
                    return;
                  }
                toast.error(error);
                return;
            }
            if (response) {
                let data = response['data'];
                if (data && data.length > 0) {
                    data = data.filter((x) => x.identifier === identifier);
                    if (!data.length) {
                        toast.error(`${platform} platform not found`);
                        return;
                    }
                    setConnectedPlatforms(data);
                }
            }
        })
    }

    useEffect(() => {
        if (platform) {
            fetchWorkSpacePlatforms();
        }
    }, [])

    useEffect(() => {
        const page = connectedPlatforms.find((x) => x.user_id === userId);
        setSelectedAcccount({ ...page });
    }, [connectedPlatforms, userId]);

    useEffect(() => {
        if (platform) {
            const account = connectedPlatforms[0] ?? {};
            dispatch(setInstagramUserId(account?.user_id));
        }
    }, [connectedPlatforms])

    return (
        <FreePageHeader
            titleTag="h5"
            className="mb-3">
            <Flex
                alignItems={'center'}
                justifyContent={'space-between'}
                className={'gap-2 flex-1'}
                wrap={'wrap'}>
                <h5>{platform.name} Account</h5>
                <div style={{ flex: 1 }}></div>
                <div>
                    <Dropdown size="lg">
                        <Dropdown.Toggle
                            variant="primary"
                            id="dropdown-basic"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                minWidth: '150px',
                                padding: '0.5rem',
                            }}>
                            {selectedAccount ? (
                                <>
                                    <Avatar
                                        src={selectedAccount?.user_photo}
                                        alt={selectedAccount?.user_name}
                                        style={{ width: 15, height: 15 }} />
                                    <p className='text-bold' style={{ fontSize: '15px' }}>{selectedAccount?.user_name}</p>
                                </>
                            ) : (
                                'Select Account'
                            )}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {connectedPlatforms && connectedPlatforms.map((page) => (
                                <Dropdown.Item
                                    key={page.id}
                                    href='#'
                                    onClick={() => {
                                        dispatch(setInstagramUserId(page?.user_id))
                                    }}>
                                    <Flex alignItems={'center'} justifyContent={'space-between'} style={{ alignItems: 'center', gap: '10px' }}>
                                        <div>
                                            <Avatar
                                                src={page?.user_photo}
                                                alt={page?.name}
                                                style={{
                                                    width: 20,
                                                    height: 20
                                                }} />
                                        </div>
                                        <p className='text-bold'>{page?.user_name}</p>
                                    </Flex>
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </Flex>
        </FreePageHeader>
    )
}

export default InstaCommentsHeader; 