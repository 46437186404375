import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    results: []
}
export const aiArtSlice = createSlice({
    name: 'aiart',
    initialState,
    reducers: {
        setAiArtResults: (state, action) => {
            state.results = action.payload;
        }
    }
});
export let getAIArtResults = (state) => state.aiart.results;
export let {
   setAiArtResults
} = aiArtSlice.actions;
export default aiArtSlice.reducer;