import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import APIService from 'http/api_service';
import { createDateWithTimeZone } from 'utils/time';
import moment from 'moment';
import Flex from 'components/common/Flex';
import InfoBar from 'components/common/InfoBar';
import Divider from 'components/common/Divider';
import { Col, Row, Spinner, Table } from 'react-bootstrap';
import { ordinalSuffixOf } from 'utils/gen';
import { useSelector } from 'react-redux';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import RecurrenceMapper from 'utils/recurrence_mapper';

const propTypes = {
    post: PropTypes.object.isRequired,
};

export const replaceLast = (parent, search, replace) => {
    return parent.replace(new RegExp(search + "([^" + search + "]*)$"), replace + "$1");
}

const WorkSpacePostDetailsDates = ({ recurringPostHandler, post, timeToPost }) => {

    const workSpace = useSelector(getActiveWorkSpace);
    const [recurrentPost, setRecurrentPost] = useState();
    const [fetchingRecurringPost, setFetchingRecurringPost] = useState(false);

    useEffect(() => {
        if (post?.recurring || post?.data?.repeat_option) {
            setFetchingRecurringPost(true);
            APIService.fetchRecurringPostDetails(workSpace._id, post.data.post_group_identifier, (response, error) => {
                setFetchingRecurringPost(false);
                if (error) {
                    return;
                }
                if (response) {
                    let { data } = response;
                    if (data) {
                        let otherDataInData = data.data;
                        otherDataInData.time_to_post = createDateWithTimeZone(data.scheduled_for, otherDataInData.time_zone);
                        data.data = otherDataInData;
                        setRecurrentPost(data);
                        if (recurringPostHandler) {
                            recurringPostHandler(data);
                        }
                    }
                }
            });
        }
    }, [post?._id]);

    const withOrdinalSuffix = (input) => {
        let inputSplit = input.toString().split(" ");
        let first = inputSplit[0];
        if (!isNaN(first)) {
            inputSplit[0] = ordinalSuffixOf(first);
            return inputSplit.join(" ");
        }
        return input;
    }

    const getRecurringPostEndConclusionStatement = (endObj) => {
        if (endObj?.type === 'never') {
            return `This recurring post will never end.`
        }
        if (endObj?.type === 'after') {
            return `This recurring post end after ${endObj?.occurrences} occurrences`
        }
        if (endObj?.type === 'on') {
            return `This recurring post end on ${new Date(endObj?.date)}`
        }
        return ''
    }

    const generateRecurringInterval = () => {
        let repeatOptions = post?.data?.repeat_option
        if (repeatOptions) {
            const data = repeatOptions?.data;
            if (repeatOptions?.type === 'custom') {
                if (data?.type === '(interval)-weeks') {
                    return `Post will repeat every ${data?.interval} week(s). ${getRecurringPostEndConclusionStatement(data?.end)}`
                }
                if (data?.type === '(interval)-days') {
                    return `Post will repeat every ${data?.interval} day(s). ${getRecurringPostEndConclusionStatement(data?.end)}`
                }
                if (data?.type === '(interval)-months') {
                    return `Post will repeat every ${data?.interval} month(s). ${getRecurringPostEndConclusionStatement(data?.end)}`
                }
                if (data?.type === 'yearly') {
                    return `Post will repeat every ${data?.interval} year(s). ${getRecurringPostEndConclusionStatement(data?.end)}`
                }
            }
            if (repeatOptions?.type === 'daily') {
                return `Post will repeat every day.`
            }
            if (repeatOptions?.type === 'weekly_on_(weekday)') {
                return `Post will repeat every ${data?.weekday}`
            }
            if (repeatOptions?.type === 'monthly_on_the_(ordinal)_(weekday)') {
                return `Post will repeat every ${data?.ordinal} ${data?.weekday} of the month`
            }
            if (repeatOptions?.type === 'annually_on_(month)_(date_of_month)') {
                return `Post will repeat every ${data?.date_of_month} of ${data?.month} of each year.`
            }
        }
        return null;
    }

    const getNextRecurringPostEvent = () => {
        const repeatOptions = post?.data?.repeat_option
        const nextDate = RecurrenceMapper.getNextRecurringDate(new Date(timeToPost), repeatOptions)
        const formattedDate = moment(nextDate).format("LLLL")
        if (formattedDate) return formattedDate
        return null
    }

    const canPost = () => {
        if (recurrentPost && recurrentPost.data) {
            if (recurrentPost.data.hasOwnProperty("can_post")) {
                return recurrentPost.data.can_post;
            }
            return true;
        }
        return false;
    }

    return (
        <Flex direction={'column'}>
            {
                post.drafted &&
                <InfoBar info={"Visible to only you and team members"} />
            }
            {
                post?.data.processing &&
                <span>Processing...</span>
            }
            <Table>
                <tbody>
                    <tr key={0}>
                        <td className="bg-100" style={{ width: '30%' }}>
                            Created
                        </td>
                        <td>{moment(recurrentPost ? recurrentPost.created_at : post.created_at).format("LLLL")}</td>
                    </tr>
                    <tr key={1}>
                        <td className="bg-100" style={{ width: '30%' }}>
                            Last Updated
                        </td>
                        <td>{moment(recurrentPost ? recurrentPost.updated_at : post.updated_at).format("LLLL")}</td>
                    </tr>
                    {
                        post.recurring &&
                        <tr key={2}>
                            <td className="bg-100" style={{ width: '30%' }}>
                                Next post will happen
                            </td>
                            <td>{
                                getNextRecurringPostEvent()
                                // RecurrenceMapper.getNextCustomRecurringDate()
                            }</td>
                        </tr>
                    }
                    {
                        post.recurring &&
                        <tr key={2}>
                            <td className="bg-100" style={{ width: '30%' }}>
                                Recurring Intervals
                            </td>
                            <td>{generateRecurringInterval()}</td>
                        </tr>
                    }
                </tbody>
            </Table>
            {
                fetchingRecurringPost &&
                <Flex className={'p-3'} alignItems={'center'} justifyContent={'center'}>
                    <Spinner animation={'border'} />
                </Flex>
            }
            {
                recurrentPost && canPost() &&
                <Row className='p-2'>
                    <Col lg={6}>
                        <InfoBar
                            info={`Next time to Reoccur: <b>${moment(new Date(recurrentPost.scheduled_for)).format('llll')}</b>`}
                        />
                    </Col>
                    <Col lg={6}>
                        <InfoBar
                            info={`When to Stop: <b>${(recurrentPost.data.advanced_options?.stop_time ?? "never") === "never" ? "Never" : moment(new Date(recurrentPost.data.advanced_options.stop_time)).format('llll')}</b>`}
                        />
                    </Col>
                </Row>
            }
            {
                recurrentPost && !canPost() && !fetchingRecurringPost &&
                <Flex direction={'column'} gap={3} className={'p-3'} alignItems={'center'} justifyContent={'center'}>
                    <Divider />
                    <Spinner animation={'border'} />
                    <span>Recurrence data will refresh when available...</span>
                </Flex>
            }
        </Flex>
    );
}

WorkSpacePostDetailsDates.propTypes = propTypes;
export default WorkSpacePostDetailsDates;
