import React, { useEffect, useState } from 'react';
import APIService from '../../../../../http/api_service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { RoutePaths, PlatformIdentifier } from '../../../../../constants';
import PostAudienceListItem from './audience_list_item';
import {
    AudiencePanelContainer,
    CheckboxContainer
} from './styles';
import { Buffer } from 'buffer';
import {
    getMunchedPostTargetIds,
    setSelectedPlatforms,
    getSelectedPlatforms,
    setMunchedPostTargetIds,
    canSelectAllAudience,
    setSelectAllAudience,
    getEditablePost,
    getAboutRepublishingPost
} from '../../../../../redux/slices/postslice';
import { useSelector, useDispatch } from 'react-redux';
import { timer } from './section_header';
import { Scrollbars } from 'react-custom-scrollbars';
import { Button, Card, Form, Modal, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { simulateMouseClick } from 'utils/gen';
import CircularButton from 'components/common/circularbutton';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faUsers } from '@fortawesome/free-solid-svg-icons';
import Flex from '../../../../common/Flex';
import NewAudienceGroupView from './new_audience_group_view';
import AudienceGroupsView from './audience_groups_view';
import ReactLoading from 'react-loading';
import { platforms as AppPlatforms } from '../../../platforms';
import {
    getPlatformChangeCounter,
    setPlatformChangeCounter
} from 'redux/slices/platformSlice';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { useTranslation } from 'react-i18next';

export const munchTarget = (id, target) => {
    return Buffer.from(`${id}${target}`).toString('hex');
}

export default function PostAudiencePage(props) {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const {
        style = {},
        only = [],
        height = 400,
        prompt = "Who can see your post?",
        goBack,
        handleCancelChanges,
        handleSavedChanges,
        hideShadow = false
    } = props;
    const munchedTargetIds = [...useSelector(getMunchedPostTargetIds)];
    const platformChangeCounter = useSelector(getPlatformChangeCounter)
    const workSpace = useSelector(getActiveWorkSpace);
    const navigate = useNavigate();
    const updatablePost = useSelector(getEditablePost);
    const aboutRePublishingPost = useSelector(getAboutRepublishingPost);
    const platformsWithExclusions = (data) => {
        if (only.length > 0) {
            data = data.filter(x => only.includes(x.identifier));
        }
        return data;
    }
    const [platforms, setPlatforms] = useState(localStorage.getItem("cached_platforms") ? platformsWithExclusions(JSON.parse(localStorage.getItem("cached_platforms"))) : []);
    const [dataFetched, setDataFetched] = useState(false);
    const [searchString] = useState();
    const selectEverything = useSelector(canSelectAllAudience);
    const [platformsBeforeSearch, setPlatformsBeforeSearch] = useState([]);
    const selectedPlatforms = useSelector(getSelectedPlatforms);
    const [activeAudienceGroup, setActiveAudienceGroup] = useState();
    const [lastAudienceGroup, setLastAudienceGroup] = useState({});
    const [activeAudienceTargets, setActiveAudienceTargets] = useState();
    const [openNewAudienceGroupModal, setOpenNewAudienceGroupModal] = useState(false);
    const [openAudienceGroupsView, setOpenAudienceGroupsView] = useState(false);
    const [doneWithCreatingAudienceGroup, setDoneWithCreatingAudienceGroup] = useState(true);
    const [audienceGroupsCount, setAudienceGroupsCount] = useState(0);
    const [loadingAudienceGroupsCount, setLoadingAudienceGroupsCount] = useState(true);
    const [clickableIds, setClickableIds] = useState([]);

    const platformIsDisabled = (platform) => {
        return AppPlatforms.find(x => x?.identifier === platform?.identifier)?.disabled;
    }

    const fetchAudienceGroupCounts = () => {
        APIService.countAudienceGroup(workSpace._id, (response, error) => {
            setLoadingAudienceGroupsCount(false);
            if (error) {
                return;
            }
            if (response?.data) {
                setAudienceGroupsCount(response?.data);
            }
        });
    }

    useEffect(() => {
        if (workSpace) {
            fetchAudienceGroupCounts();
        }
    }, []);

    const processChangedPlatforms = (plats) => {
        negotiatePlatformsAgainstMunches(plats);
        isolateSelectedTargetPlatforms(plats);
        try {
            localStorage.setItem("cached_platforms", JSON.stringify([...plats]));
        } catch (e) { }
    }

    useEffect(() => {
        processChangedPlatforms(platforms);
        dispatch(setPlatformChangeCounter(platformChangeCounter + 1));
    }, [platforms]);

    const isolateSelectedTargetPlatforms = (platforms) => {
        let platformsCopy = JSON.parse(JSON.stringify(platforms));
        let selectedPlatformsCopy = [...selectedPlatforms];
        for (let platform of platformsCopy) {
            let accounts = platform.accounts;
            for (let account of accounts) {
                let alreadyPushedPlatform = selectedPlatformsCopy.find(entry => entry.identifier === platform.identifier);
                let indexOfAlreadyPushedPlatform = selectedPlatformsCopy.indexOf(alreadyPushedPlatform);
                if (account.connected_pages_and_groups) {
                    if ((account.connected_pages_and_groups ?? []).length === 0) {
                        account.selected = false;
                        if (!alreadyPushedPlatform) {
                            selectedPlatformsCopy.push(platform);
                        } else {
                            selectedPlatformsCopy[indexOfAlreadyPushedPlatform] = platform;
                        }
                    } else {
                        if (account.connected_pages_and_groups.some(item => item.selected)) {
                            account.selected = true;
                            platform.selected = true;
                            if (!alreadyPushedPlatform) {
                                selectedPlatformsCopy.push(platform);
                            } else {
                                selectedPlatformsCopy[indexOfAlreadyPushedPlatform] = platform;
                            }
                        }
                        if (account.connected_pages_and_groups.every(item => !item.selected)) {
                            if (alreadyPushedPlatform) {
                                let otherAccounts = alreadyPushedPlatform.accounts.filter((otherAccount) => otherAccount._id !== account._id);
                                if (otherAccounts.length > 0) {
                                    let canBeRemoved = true;
                                    for (let otherAccount of otherAccounts) {
                                        if (otherAccount.connected_pages_and_groups) {
                                            let someItemsWereSelected = otherAccount.connected_pages_and_groups.some(itemInOtherAccount => itemInOtherAccount.selected);
                                            if (someItemsWereSelected) {
                                                canBeRemoved = false;
                                                break;
                                            }
                                        }
                                    }
                                    if (canBeRemoved) {
                                        let indexOfAlreadyPushedPlatform = selectedPlatformsCopy.indexOf(alreadyPushedPlatform);
                                        selectedPlatformsCopy.splice(indexOfAlreadyPushedPlatform, 1);
                                    }
                                } else {
                                    let indexOfAlreadyPushedPlatform = selectedPlatformsCopy.indexOf(alreadyPushedPlatform);
                                    selectedPlatformsCopy.splice(indexOfAlreadyPushedPlatform, 1);
                                }
                            }
                        }
                    }
                } else {
                    if (account.selected) {
                        platform.selected = true;
                        if (!alreadyPushedPlatform) {
                            selectedPlatformsCopy.push(platform);
                        } else {
                            selectedPlatformsCopy[indexOfAlreadyPushedPlatform] = platform;
                        }
                    }
                    if (accounts.every(item => !item.selected)) {
                        platform.selected = false;
                        if (alreadyPushedPlatform) {
                            let indexOfAlreadyPushedPlatform = selectedPlatformsCopy.indexOf(alreadyPushedPlatform);
                            selectedPlatformsCopy.splice(indexOfAlreadyPushedPlatform, 1);
                        }
                    }
                }
            }
        }
        dispatch(setSelectedPlatforms(selectedPlatformsCopy));
    }

    const searchPlatforms = (search) => {
        let searched = platformsBeforeSearch.filter((entry) => {
            if (entry.name.toLowerCase().includes(search.toLowerCase())) {
                return entry;
            }
            if (entry.accounts) {
                for (let account of entry.accounts) {
                    if (account.name.toLowerCase().includes(search.toLowerCase())
                        || account.user_name.toLowerCase().includes(search.toLowerCase())) {
                        return entry;
                    }
                    if (account.connected_pages_and_groups) {
                        for (let page of account.connected_pages_and_groups) {
                            if (page.name.toLowerCase().includes(search.toLowerCase())) {
                                return entry;
                            }
                        }
                    }
                }
            }
            return null;
        });
        if ((searched ?? []).length > 0) {
            searched = JSON.parse(JSON.stringify([...searched]));
            negotiatePlatformsAgainstMunches(searched);
            setPlatforms(platformsWithExclusions(searched));
        } else {
            let originalPlatforms = JSON.parse(JSON.stringify([...platformsBeforeSearch]));
            negotiatePlatformsAgainstMunches(originalPlatforms);
            setPlatforms(platformsWithExclusions(originalPlatforms));
        }
    }

    const organizePlatforms = (connectedAccountsInWorkSpace) => {
        let data = connectedAccountsInWorkSpace.reduce((acc, current) => {
            let platformIdentifier = current.identifier;
            let platform = acc.find((platform) => platform.identifier === platformIdentifier);
            platform = platform ?? { name: current.name, identifier: platformIdentifier };
            let accounts = platform.accounts ?? [];
            if (current.identifier === PlatformIdentifier.FACEBOOK) {
                let connectedPagesUnderCurrent = current.connected_pages_and_groups ?? [];
                current.connected_pages_and_groups = connectedPagesUnderCurrent.filter((x) => x.target === PlatformIdentifier.FACEBOOK);
            }
            if (current.identifier === PlatformIdentifier.TELEGRAM) {
                if (current.connected_pages_and_groups) {
                    for (let connectedPageOrChannel of current.connected_pages_and_groups) {
                        connectedPageOrChannel.target = PlatformIdentifier.TELEGRAM;
                    }
                }
            }
            accounts.push(current);
            platform.accounts = accounts;
            let existingPlatform = acc.find((platform) => platform.identifier === platformIdentifier);
            if (!existingPlatform) {
                acc.push(platform);
            }
            return acc;
        }, []);
        negotiatePlatformsAgainstMunches([...data]);
        setPlatforms(platformsWithExclusions([...data]));
        setPlatformsBeforeSearch([...data]);
    }

    const negotiatePlatformsAgainstMunches = (data) => {
        for (let platform of data) {
            for (let account of platform.accounts) {
                let accountMunch = munchTarget(account._id, account.identifier);
                if (account.connected_pages_and_groups) {
                    for (let page of account.connected_pages_and_groups) {
                        let pageMunch = munchTarget(page.id, page.target);
                        if (munchedTargetIds.includes(pageMunch)) {
                            page.selected = true;
                        }
                        if (page.sections) {
                            for (let section of page.sections) {
                                let sectionMunch = munchTarget(section.id, page.target);
                                if (munchedTargetIds.includes(sectionMunch)) {
                                    section.selected = true;
                                }
                            }
                        }
                    }
                    account.selected = account.connected_pages_and_groups.every(item => item.selected);
                } else {
                    if (munchedTargetIds.includes(accountMunch)) {
                        account.selected = true;
                    }
                }
            }
        }
    }

    const fetchConnectedPlatforms = () => {
        let isOnEditingMode = updatablePost || aboutRePublishingPost
        if (selectedPlatforms?.length && !isOnEditingMode) {
            setDataFetched(true);
            return;
        }
        if (!dataFetched) {
            APIService.fetchWorkSpacePlatforms(workSpace['_id'], (response, error) => {
                if (error) {
                    setDataFetched(true);
                    if (error?.toLowerCase() === `WorkSpace ID is required`.toLowerCase()) {
                        return;
                      }
                    toast.error(error, { theme: 'colored' });
                    return;
                }
                if (response) {
                    let connectedAccountsInWorkSpace = response['data'];
                    organizePlatforms(connectedAccountsInWorkSpace);
                }
                setDataFetched(true);
            });
        }
    }

    useEffect(() => {
        fetchConnectedPlatforms();
    }, []);

    useEffect(() => {
        try {
            localStorage.setItem("cached_platforms", JSON.stringify([...platforms]));
        } catch (e) { }
    }, [selectedPlatforms]);

    useEffect(() => {
        if (activeAudienceGroup) {
            loadTargetsFromAudienceGroup(activeAudienceGroup);
        }
    }, [activeAudienceGroup]);

    useEffect(() => {
        processClickables();
    }, [clickableIds]);

    useEffect(() => {
        if (activeAudienceTargets) {
            dispatch(setSelectedPlatforms([]));
            dispatch(setMunchedPostTargetIds([]));
            createNewClickableTargets(activeAudienceTargets);
        }
    }, [activeAudienceTargets]);

    const processClickables = async () => {
        if (clickableIds.length) {
            dispatch(setMunchedPostTargetIds([]));
            for (let id of clickableIds) {
                simulateMouseClick(document.getElementById(id));
                await timer(5);
            }
        }
    }

    const addEntryToClickableTargets = (clickableId, newClickableIds) => {
        if (!newClickableIds.includes(clickableId)) {
            newClickableIds.push(clickableId);
        }
    }

    const createNewClickableTargets = (targets) => {
        let _platform = [...platforms];
        for (let index = 0; index < platforms.length; index++) {
            let element = platforms[index];
            let platformIdentifier = element.identifier;
            let targetFound = targets.find(x => x.identifier === platformIdentifier);
            if (targetFound) {
                if (targetFound?.selected) {
                    element['selected'] = true;
                }
                if (targetFound?.accounts) {
                    let _accounts = [...element.accounts];
                    for (let accountIndex = 0; accountIndex < element.accounts.length; accountIndex++) {
                        let eachPlatformAccounts = element.accounts[accountIndex];
                        const accountFound = targetFound.accounts.find(x => (x._id === eachPlatformAccounts._id ?? x.user_id === eachPlatformAccounts.user_id));
                        if (accountFound) {
                            eachPlatformAccounts['selected'] = accountFound.selected;
                            _accounts[accountIndex] = eachPlatformAccounts;
                        }
                    }

                    for (let index = 0; index < _accounts.length; index++) {
                        const element_accounts = _accounts[index];
                        if (element_accounts?.connected_pages_and_groups) {
                            let connectedPagesAndGroups = [...element_accounts?.connected_pages_and_groups];
                            for (let pageOrGroupIndex = 0; pageOrGroupIndex < element_accounts?.connected_pages_and_groups.length; pageOrGroupIndex++) {
                                let eachPageOrGroup = element_accounts.connected_pages_and_groups[pageOrGroupIndex];
                                const pageOrGroupFound = targetFound.accounts?.find((e) => (e?._id === element_accounts?._id || e?.user_id === element_accounts?.user_id))?.connected_pages_and_groups?.find((e) => (e?.id === eachPageOrGroup?.id))
                                if (pageOrGroupFound?.selected) {
                                    eachPageOrGroup['selected'] = pageOrGroupFound.selected;
                                    connectedPagesAndGroups[pageOrGroupIndex] = eachPageOrGroup;
                                }
                            }
                            element_accounts.connected_pages_and_groups = connectedPagesAndGroups;
                        }
                        _accounts[index] = element_accounts;
                    }
                    element.accounts = _accounts;
                }
            }
            _platform[index] = element;
        }

        const validTargets = targets.filter(x => x.selected);
        dispatch(setSelectedPlatforms(validTargets));
        setPlatforms(_platform);
        let newClickableIds = [];
        for (let target of targets) {
            for (let account of target.accounts) {
                if (account.connected_pages_and_groups) {
                    for (let page of account.connected_pages_and_groups) {
                        let clickableId = `${page.id}${account.target ?? target.identifier}`;
                        if (page.selected) {
                            addEntryToClickableTargets(clickableId, newClickableIds);
                        }
                    }
                    let areAllPagesSelected = account.connected_pages_and_groups.every(x => x.selected);
                    if (areAllPagesSelected) {
                        for (let page of account.connected_pages_and_groups) {
                            let clickableId = `${page.id}${account.target ?? target.identifier}`;
                            addEntryToClickableTargets(clickableId, newClickableIds);
                        }
                    }
                } else {
                    let clickableId = `${account._id ?? account.id}`;
                    if (account.selected) {
                        addEntryToClickableTargets(clickableId, newClickableIds);
                    }
                }
            }
        }
        setClickableIds(newClickableIds);
        return clickableIds;
    }

    const checkAllCheckBoxes = async () => {
        for (let platform of platforms) {
            let elem = document.getElementById(`${platform.name}-check`);
            if (elem && !elem.checked) {
                simulateMouseClick(elem);
                await timer(5);
            }
        }
    }

    const unCheckAllCheckBoxes = async () => {
        for (let platform of platforms) {
            let elem = document.getElementById(`${platform.name}-check`);
            if (elem && elem.checked) {
                simulateMouseClick(elem);
                await timer(5);
            }
        }
    }

    const loadTargetsFromAudienceGroup = async (input) => {
        if (lastAudienceGroup && lastAudienceGroup._id === input._id) {
            return;
        }
        setLastAudienceGroup(input);
        let targets = input.data;
        unCheckAllCheckBoxes();
        setTimeout(() => {
            setActiveAudienceTargets(targets);
        }, 200);
    }

    const handleAudienceGroupCreation = (name, silence = false) => {
        setDoneWithCreatingAudienceGroup(false);
        APIService.upsertNewAudienceGroup(workSpace._id, { name, selectedPlatforms, activeAudienceGroup }, (response, error) => {
            if (error) {
                setDoneWithCreatingAudienceGroup(true);
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { message, data } = response;
            if (!silence) {
                toast.success(message, { theme: 'colored' });
            }
            setDoneWithCreatingAudienceGroup(true);
            setOpenNewAudienceGroupModal(false);
            setActiveAudienceGroup(data);
        });
    }

    return (
        <Card
            className={`mb-3 ${hideShadow ? 'shadow-none' : ''}`}
            id="select-platform"
            style={{
                height: '87vh',
                overflowY: 'auto',
            }}
        >
            <Card.Body className={`${hideShadow ? 'shadow-none' : ''}`}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: hideShadow ? 0 : 10,
                        marginBottom: hideShadow ? 0 : 10
                    }}>
                    <h6
                        type={'h5'}
                        style={{
                            margin: 0,
                            // paddingLeft: 10,
                            fontSize: 14,
                            textAlign: 'center',
                            padding: 5,
                            flex: 1,
                            alignSelf: 'center'
                        }}>{t('app_pages.create_a_post.select_platform.title')} 
                    </h6>
                </div>
                <AudiencePanelContainer
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: 3,
                        ...style
                    }}>
                    {/* <h6
                        style={{
                            paddingLeft: 10,
                            fontSize: 14,
                            flex: 1
                        }}>{prompt}
                    </h6> */}
                    {
                        loadingAudienceGroupsCount &&
                        <ReactLoading
                            type='bubbles'
                            color='#f5803e'
                            style={{
                                width: 25,
                                height: 25
                            }}
                        />
                    }
                    {
                        audienceGroupsCount > 0 &&
                        <Button
                            size='sm'
                            variant='falcon-warning'
                            className='fs--2'
                            onClick={() => {
                                setOpenAudienceGroupsView(true);
                            }}>
                            <Flex alignItems={'center'}>
                                <FontAwesomeIcon icon={faUsers} />
                                <span className='ms-2'>Select From {audienceGroupsCount} Audience Group(s)</span>
                            </Flex>
                        </Button>
                    }
                    {
                        !dataFetched && platforms.length < 1 &&
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                gap: 2
                            }}>
                            <Spinner
                                animation='border'
                                size={'sm'}
                                message={"Please wait..."}
                            />
                            <span>Please wait...</span>
                        </div>
                    }
                    {
                        dataFetched && platforms.length < 1 &&
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: 20,
                                gap: 10
                            }}>
                            <span
                                textAlign={'center'}
                                style={{
                                    padding: 20,
                                    fontSize: 15,
                                    maxWidth: 400,
                                    textAlign: 'center',
                                }}>{t('app_pages.create_a_post.select_platform.description')} 
                            </span>
                            <Button
                                variant={'primary'}
                                onClick={() => {
                                    navigate(RoutePaths.PLATFORMS);
                                }}
                            >{t('app_pages.create_a_post.select_platform.connect_platform_button')} 
                            </Button>
                        </div>
                    }
                    {
                        platforms.length > 0 &&
                        <Scrollbars
                            style={{
                                height: height,
                            }}>
                            <div
                                id='audience-list-container'
                                style={{
                                    display: 'flex',
                                    position: 'relative',
                                    flexDirection: 'column',
                                    gap: 10,
                                    paddingLeft: 10,
                                    overflowX: 'hidden',
                                    marginTop: 15,
                                    paddingTop: 10,
                                }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        // marginRight: 15,
                                        flexDirection: 'column-reverse',
                                        gap: 10,
                                        width: '90%',
                                    }}>
                                    <Form.Control
                                        type='text'
                                        placeholder={"Search"}
                                        value={searchString}
                                        onChange={(e) => {
                                            searchPlatforms(e.target.value);
                                        }}
                                    />
                                    {
                                        !dataFetched &&
                                        <span>
                                            <Spinner
                                                as={'span'}
                                                role="status"
                                                animation='border'
                                                size="sm"
                                                aria-hidden="true"
                                            />
                                        </span>
                                    }
                                    <CheckboxContainer
                                        style={{
                                            minWidth: 200,
                                            alignSelf: 'flex-end',
                                            alignItems: 'flex-end',
                                            paddingTop: 10,
                                            justifyContent: 'flex-end'
                                        }}>
                                        <Form.Check
                                            type='checkbox'
                                            id='check-everything'
                                            checked={selectEverything}
                                            label={'Select everything'}
                                            onChange={(e) => {
                                                let checked = e.target.checked;
                                                dispatch(setSelectAllAudience(checked));
                                                if (checked) {
                                                    checkAllCheckBoxes();
                                                } else {
                                                    unCheckAllCheckBoxes();
                                                }
                                            }}
                                            style={{
                                                cursor: 'pointer',
                                                marginRight: '1rem'
                                            }}
                                        />
                                    </CheckboxContainer>
                                </div>
                                {
                                    platforms
                                        .filter((x) => x.identifier !== PlatformIdentifier.RSS_FEED)
                                        .map((platform, index) => {
                                            if (!platformIsDisabled(platform)) {
                                                return (
                                                    <PostAudienceListItem
                                                        key={index}
                                                        platform={platform}
                                                        platformChangedHandler={(changedPlatform) => {
                                                            setPlatforms((prevPlatforms) => {
                                                                let existingChangedPlatform = prevPlatforms.find((item) => item.identifier === changedPlatform.identifier);
                                                                let indexOfChangedPlatform = prevPlatforms.indexOf(existingChangedPlatform);
                                                                if (indexOfChangedPlatform !== -1) {
                                                                    prevPlatforms[indexOfChangedPlatform] = JSON.parse(JSON.stringify(changedPlatform));
                                                                }
                                                                return JSON.parse(JSON.stringify([...prevPlatforms]));
                                                            });
                                                        }}
                                                    />
                                                );
                                            }

                                        })
                                }
                            </div>
                        </Scrollbars>
                    }
                </AudiencePanelContainer>
            </Card.Body>
            {
                (platforms.length > 0 || platformsBeforeSearch.length > 0) &&
                <Card.Footer>
                    <Flex
                        alignItems={'center'}
                        wrap
                        direction={'column'}
                    >
                        {
                            (selectedPlatforms ?? []).length > 0 && !activeAudienceGroup &&
                            <Form.Check
                                type='checkbox'
                                id={`collections-check`}
                                checked={false}
                                alignItems={'flex-start'}
                                onChange={(value) => { }}
                                label={"Save to audience group?"}
                                style={{
                                    marginLeft: '1rem',
                                    fontSize: 12
                                }}
                                onClick={() => {
                                    setOpenNewAudienceGroupModal(true);
                                }}
                            />
                        }
                        {
                            activeAudienceGroup &&
                            <Flex
                                direction={'column'}
                                style={{
                                    marginLeft: '1rem',
                                    gap: 5
                                }}
                                justifyContent={'center'}>
                                <h5 className='fs--2'>Active Audience Group:</h5>
                                <Flex
                                    onClick={() => {
                                        setOpenNewAudienceGroupModal(true);
                                    }}>
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={
                                            <Tooltip id="post-time-overlay-trigger">
                                                Click to Change Name
                                            </Tooltip>
                                        }>
                                        <span style={{ cursor: 'pointer' }}>
                                            <SoftBadge
                                                pill
                                                bg='warning'
                                                className='me-2 cursor-pointer px-3'
                                            >
                                                <Flex alignItems={'center'}>
                                                    <FontAwesomeIcon icon={faUsers} />
                                                    <span className='ps-2'>
                                                        {activeAudienceGroup.name}
                                                    </span>
                                                </Flex>
                                            </SoftBadge>
                                        </span>
                                    </OverlayTrigger>
                                </Flex>
                            </Flex>

                        }
                        {/* <div
                            style={{
                                padding: 10,
                                display: 'flex',
                                flex: 1,
                                justifyContent: 'space-between',
                                gap: 10,
                                // flexWrap: 'wrap'
                            }}>
                            <Button
                                variant={'default'}
                                onClick={handleCancelChanges}
                                style={{
                                    fontSize: 12
                                }}>
                                CANCEL
                            </Button>
                            <Button
                                onClick={() => {
                                    if (activeAudienceGroup) {
                                        handleAudienceGroupCreation(activeAudienceGroup.name, true);
                                    }
                                    handleSavedChanges();
                                }}
                                autoFocus
                                variant={'primary'}
                                style={{
                                    fontSize: 12
                                }}
                            >
                                CONTINUE
                            </Button>
                        </div> */}
                    </Flex>
                </Card.Footer>
            }
            <Modal
                show={openNewAudienceGroupModal}
                size={'lg'}
                centered
                onHide={() => {
                    setOpenNewAudienceGroupModal(false);
                }}>
                <Modal.Header>
                    <h4>{activeAudienceGroup ? "Update Audience Group" : "Create a New Audience Group"}</h4>
                </Modal.Header>
                <Modal.Body>
                    <NewAudienceGroupView
                        handleAudienceGroupCreation={handleAudienceGroupCreation}
                        done={doneWithCreatingAudienceGroup}
                        activeAudienceGroup={activeAudienceGroup}
                    />
                </Modal.Body>
            </Modal>
            <Modal
                show={openAudienceGroupsView}
                centered
                onHide={() => {
                    setOpenAudienceGroupsView(false);
                }}>
                <Modal.Body>
                    <AudienceGroupsView
                        modalClose={() => {
                            setOpenAudienceGroupsView(false);
                        }}
                        initialValue={activeAudienceGroup}
                        handleSelectedAudienceGroup={(selection) => {
                            setActiveAudienceGroup(selection);
                            fetchAudienceGroupCounts();
                        }}
                        setOpenAudienceGroupsView={setOpenAudienceGroupsView}
                        platforms={platforms}
                    />
                </Modal.Body>
            </Modal>
        </Card>
    );
}


