import Flex from 'components/common/Flex';
import moment from 'moment';
import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

const SpecificTimeAndExpirationView = ({
    presetOptions,
    commitStopTime,
    commitSpecificTime
}) => {

    const [stopTime, setStopTime] = useState(presetOptions["stop_time"] ?? "never");
    const defaultValue = presetOptions['specific_time_to_repeat'];
    const [selectedTimeToReoccur, setSelectedTimeToRecur] = useState(defaultValue ? defaultValue : new Date());

    const SpecificTimeView = () => {
        return (
            <Col lg={6}>
                <Flex direction={'column'} className={'mb-3'}>
                    <h6>Time</h6>
                    <DatePicker
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="h:mm aa"
                        onChange={(e) => {
                            setSelectedTimeToRecur(e);
                            commitSpecificTime(e);
                        }}
                        className='form-control'
                        selected={new Date(selectedTimeToReoccur)}
                    />
                </Flex>
            </Col>
        )
    }

    const StopTimeView = () => {
        return (
            <Col lg={6}>
                <Flex
                    direction={'column'}
                    justifyContent={'flex-end'}
                    alignItems={'flex-end'}
                    alignContent={'flex-end'}
                    className={'mt-3'}>
                    <h6>When to stop</h6>
                    <Form
                        as={Flex}
                        alignItems={'center'}
                        justifyContent={'center'}
                        className={'gap-3'}>
                        <Form.Check
                            className='mb-0'
                            type='radio'
                            value="never"
                            name="stopTime"
                            checked={stopTime === "never"}
                            onChange={(e) => {
                                let checked = e.target.checked;
                                if (checked) {
                                    setStopTime("never")
                                    commitStopTime("never");
                                } else {
                                    setStopTime(moment(new Date()).add(60, 'days').toDate());
                                    commitStopTime(moment(new Date()).add(60, 'days').toDate());
                                }
                            }}
                            label={"Never"}
                        />
                        <Form.Check
                            className='mb-0'
                            type='radio'
                            value="stopTime"
                            name="stopTime"
                            checked={stopTime !== "never"}
                            onChange={(checked) => {
                                setStopTime(moment(new Date()).add(60, 'days').toDate());
                                commitStopTime(moment(new Date()).add(60, 'days').toDate());
                            }}
                            label={"On"}
                        />
                        <DatePicker
                            minDate={moment(new Date()).add(1, 'days').toDate()}
                            onChange={(e) => {
                                setStopTime(new Date(e));
                                commitStopTime(new Date(e));
                            }}
                            disabled={stopTime == "never"}
                            selected={stopTime === "never" ? moment(new Date()).add(60, 'days').toDate() : new Date(stopTime)}
                            className='form-control mb-2'
                        />
                    </Form>
                </Flex>
            </Col>
        )
    }

    return (
        <Row alignItems={'center mt-3'}>
            <SpecificTimeView />
            <StopTimeView />
        </Row>
    )
}
export default SpecificTimeAndExpirationView;