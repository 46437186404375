/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import {
  faEarth,
  faEllipsis,
  faImage,
  faPencil,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmModal from "components/common/ConfirmModal";
import MultimediaSelectionDropdown from "components/common/MultimediaSelectionDropdown";
import CircularButton from "components/common/circularbutton";
import GDriveDashboard from "components/common/g-drive/dashboard";
import AppContext from "context/Context";
import parse from "html-react-parser";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";
import { RiDragDropLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setSelectedPlatformForPreview } from "redux/slices/platformSlice";
import { getMediaArrangement, setMediaArrangement } from "redux/slices/publishable_media_arrangement_slice";
import recursiveAspectFinder from "utils/recursiveAspectFinder";
import { lightTheme } from "utils/theme";
import {
  extractLinksFromTweet,
  getPreviewFromUrl,
  url_replacer,
} from "utils/url";
import { PlatformData, PlatformIdentifier } from "../../../../../constants";
import {
  canOpenPixelFilePickerDialog,
  getFacebookMediaRemovalObserver,
  getForceUpdateUseableMediaOnPlatformPreviews,
  getGoogleDriveManager,
  getIsFacebookPostCarousel,
  getManuallyEditedPlatforms,
  getMediaAddedViaSpecificPlaformUrlMethod,
  getNotifyPreviewsOfLiveMediaChanges,
  getSpecificMediaPlatformTied,
  getTimeToPostContent,
  setFacebookMediaRemovalObserver,
  setForceUpdateUseableMediaOnPlatformPreviews,
  setGoogleDriveManager,
  setManuallyEditedPlatforms,
  setOpenPixelFilePickerDialog,
  setSpecificMediaPlatformTied,
} from "../../../../../redux/slices/postslice";
import { findPlatformByIdentifier, platforms } from "../../../platforms";
import DragToPositionImage from "./DragToPositionImage";
import ExtrernalImageSearchDialogue from "./ExtrernalImageSearchDialogue";
import GDriveConnectionRequestListener from "./GDriveConnectionRequestListener";
import './addOnsStyles.css';
import EditTextBox from "./editTextBox.js";
import FacebookMediaDisplay from "./facebook_media_display";
import LinkPreviewer from "./link_previewer";
import { scanImages } from "./scanImages";
import TextareaAutoSize from "react-textarea-autosize";

import {
  DotsBox,
  EachBtn,
  EditContainer,
  FirstLayer,
  GridImage,
  GridImageOne,
  ImageHolder,
  Info,
  ParentSection,
  PreviewContentArea,
  SecondLayer,
  ThirdLayer,
  Wrapper,
} from "./styles/facebook";
import Flex from "components/common/Flex";
import { displayPlatformIcon } from "utils/platform_utils";
import { getPlatformVideoUploadLimit } from "redux/slices/workspaceslice";
import FigureUtils from "utils/figures";


const Facebook = (props) => {
  const { viewOnly } = props;
  const googleDriveManager = useSelector(getGoogleDriveManager)
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const googleDriveRef = useRef(null);
  const dispatch = useDispatch();
  const [toggleEditTextInput, setToggleEditTextInput] = useState(false);
  const media_items_arrangement = useSelector(getMediaArrangement);
  const openPixelDialoguePicker = useSelector(canOpenPixelFilePickerDialog);
  const mediaAddedViaSpecificPlaformUrlMethod = useSelector(
    getMediaAddedViaSpecificPlaformUrlMethod
  );

  const specificMediaPlatformTied = useSelector(getSpecificMediaPlatformTied);
  const [limitedPostTextBody, setLimitedPostTextBody] = useState("");
  const [storedIndex, setStoredIndex] = useState(0);
  const [expandMedias, setExpandMedias] = useState(false);
  const [openMediaSelction, setOpenMediaSelection] = useState(false);
  const mediaObjectsTotalLength = 10;
  const timeToPost = useSelector(getTimeToPostContent);
  const [useableMedia, setUseableMedia] = useState([]);
  const focusImageRef = useRef(null);
  const textEditRef = useRef(null);
  const [timer, setTimer] = useState();
  const [openEditArea, setOpenEditArea] = useState(false);
  const [imageToEditUrl, setImageToEditUrl] = useState("");
  const [comparebleUrl, setComparebleUrl] = useState("");
  const [canShowMedia, setCanShowMedia] = useState(false);
  const [canOPenPopOver, setCanOpenPopOver] = useState(true);
  const [openVideoEditArea, setOpenVideoEditArea] = useState(false);
  const [formattedPostBodyForLinks, setFormattedPostBodyForLinks] =
    useState("");
  const [repostionImages, setRepositionImages] = useState(false)
  const [linkExtracted, setLinkExtracted] = useState();
  const [previewerData, setPreviewerData] = useState(null);
  const [blockMediaItemsUpdate, setBlockMediaItemsUpdate] = useState(true);
  const manuallyEditedPlatforms = useSelector(getManuallyEditedPlatforms);
  const platformsWithLimits = [...platforms];
  const { config } = useContext(AppContext);
  const forceUpdateUseableMediaOnPlatformPreviews = useSelector(getForceUpdateUseableMediaOnPlatformPreviews);
  const facebookMediaRemovalObserver = useSelector(getFacebookMediaRemovalObserver);
  const isFacebookPostCarousel = useSelector(getIsFacebookPostCarousel)
  const notifyPreviewsOfLiveMediaChanges = useSelector(getNotifyPreviewsOfLiveMediaChanges);
  const textRef = useRef(null);
  const [textToDisplay, setTextToDisplay] = useState('');
  const [platform] = useState(PlatformIdentifier.FACEBOOK)
  const platformVideoUploadLimit = useSelector(getPlatformVideoUploadLimit);
  let typingTimeout;


  useEffect(() => {
    setTimeout(() => {
      setCanShowMedia(true);
    }, 500);
  }, []);

  useEffect(() => {
    if (forceUpdateUseableMediaOnPlatformPreviews?.includes(PlatformIdentifier.FACEBOOK)) {
      setUseableMedia(media_items_arrangement?.facebook?.media);
      // textRef.current.value = media_items_arrangement?.facebook?.text;
      const filtered = forceUpdateUseableMediaOnPlatformPreviews?.filter(x => x !== PlatformIdentifier.FACEBOOK);
      dispatch(setForceUpdateUseableMediaOnPlatformPreviews(filtered));
    }
  }, [forceUpdateUseableMediaOnPlatformPreviews])


  useEffect(() => {
    const result = url_replacer(limitedPostTextBody ?? "");
    setFormattedPostBodyForLinks(result);
  }, [limitedPostTextBody]);

  useEffect(() => {
    const result = extractLinksFromTweet(limitedPostTextBody ?? "");
    setLinkExtracted(result[0]);
  }, [limitedPostTextBody]);

  const notifyPreviewsOfLiveMediaChangesFn = async () => {
    const imagesMedia = media_items_arrangement?.facebook?.media?.filter(
      (x) => x.is_video === false
    );
    await scanImages(imagesMedia);
    setUseableMedia(media_items_arrangement?.facebook?.media);
  }

  useEffect(() => {
    notifyPreviewsOfLiveMediaChangesFn()
  }, [notifyPreviewsOfLiveMediaChanges]);

  const getPreviewLinkDetails = async (url) => {
    try {
      const result = await getPreviewFromUrl(url);
      setPreviewerData(result?.data);
    } catch (error) { }
  };

  useEffect(() => {
    if (!linkExtracted) {
      return;
    }
    getPreviewLinkDetails(linkExtracted);
  }, [linkExtracted]);

  const closeImgEditor = () => {
    setOpenEditArea(false);
  };

  const handleExpansion = () => {
    setExpandMedias(!expandMedias);
  };

  const syncNewThread = async () => {
    let syncingThread = [];
    try {
      const mappedThread = useableMedia?.map((thread, index) => {
        const _carousel = thread?.carousel;
        const newThreadEntry = {
          name: thread?.name,
          url: thread?.url,
          is_video: thread?.is_video,
          optimized: thread?.optimized ?? false,
          external: thread?.external ?? false,
          uploaded: thread?.uploaded ?? false,
          file: thread?.file,
          carousel: thread?.carousel ?? {},
        };
        return newThreadEntry;
      });
      const oldData = { ...media_items_arrangement };
      const diapatchableData = {
        ...oldData,
        facebook: { ...oldData.facebook, media: mappedThread },
      };
      dispatch(setMediaArrangement(diapatchableData));
    } catch (error) { }
    return syncingThread;
  };



  const handleUpdateTextFromEditBox = (text) => {
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {
      if (!manuallyEditedPlatforms[PlatformIdentifier.FACEBOOK]) {
        const newData = { ...manuallyEditedPlatforms, facebook: true };
        dispatch(setManuallyEditedPlatforms(newData || {}));
      }
      const oldData = { ...media_items_arrangement };
      const getTextLimit = platformsWithLimits.find(
        (x) => x.identifier === PlatformIdentifier.FACEBOOK
      );
      const diapatchableData = {
        ...oldData,
        facebook: {
          ...oldData.facebook,
          text: text.substring(0, getTextLimit.textLimit),
        },
      };
      dispatch(setMediaArrangement(diapatchableData));
    }, 1000);
  };

  useEffect(() => {
    syncNewThread();
  }, [useableMedia]);


  useEffect(() => {
    const shouldOpenTextEdit = localStorage.getItem('open_hashtag_pop');
    if (shouldOpenTextEdit === 'true') {
      handleEditClick();
    }
  }, [])

  const updateMediaItems = async (
    files,
    canCreateObjectURL = true,
    moreProps = {}
  ) => {
    let expectedFilesLength = files.length + useableMedia.length;
    if (
      files.length > mediaObjectsTotalLength ||
      useableMedia.length > mediaObjectsTotalLength || expectedFilesLength > mediaObjectsTotalLength
    ) {
      toast.error(
        `Sorry, you can only upload a maximum of ${mediaObjectsTotalLength} files at a time`,
        { theme: "colored" }
      );
      return;
    }


    let mo = [];
    for (let file of files) {
      if (canCreateObjectURL) {
        let fileUrl = URL.createObjectURL(file, { autoRevoke: false });
        let fileName = file.name;
        let existingFile = mo.find((x) => x.url === fileUrl);
        if (!existingFile) {
          let fileExtension = `.${fileName.split(".").pop()}`;
          let newThreadObject = {
            name: fileName,
            url: fileUrl,
            extension: fileExtension,
            file: file,
            carousel: {
              link: "",
              picture: fileUrl,
              name: "",
              description: "",
            },
            ...moreProps,
          };
          if (platformVideoUploadLimit[platform] && file.size > platformVideoUploadLimit[platform]) {
            let errorMsg = `${file?.name} have been removed from ${platform} because it exceeds the allowed sized limit of ${FigureUtils.convertBytes(platformVideoUploadLimit[platform])?.value}.`
            if (platformVideoUploadLimit['canUpgrade']) {
              errorMsg = errorMsg + ` Upgrade your plan to get more upload limits.`;
            }
            toast.error(errorMsg, { theme: "colored" });
          } else {
            mo.push(newThreadObject);
          }
        }
      } else {
        let fileWithName = mo.find((item) => item.name === file.name);
        if (!fileWithName) {
          mo.push(file);
        }
      }
    }
    const imagesMedia = mo?.filter((x) => x.is_video === false);
    await scanImages(imagesMedia);
    mo?.map(async (item) => {
      setBlockMediaItemsUpdate(false);
      setUseableMedia((oldArray) => [...oldArray, item]);
    });
    if (!manuallyEditedPlatforms[PlatformIdentifier.FACEBOOK]) {
      const newData = { ...manuallyEditedPlatforms, facebook: true };
      dispatch(setManuallyEditedPlatforms(newData || {}));
    }
    setOpenMediaSelection(false);
    return;
  };

  const mediaRemoveHandler = (removableMediaObject) => {
    try {
      let cachedArrayMedia = structuredClone(useableMedia);
      const filterMedia = cachedArrayMedia?.filter(
        (item) => item.url !== removableMediaObject.url
      );
      setBlockMediaItemsUpdate(false);
      setUseableMedia(filterMedia);
      let newFacebookMediaRemovalObserver = facebookMediaRemovalObserver + 1;
      dispatch(setFacebookMediaRemovalObserver(newFacebookMediaRemovalObserver))
    } catch (error) {
      console.log(error)
    }
  };

  const handleDeleteMedia = (data) => {
    if (!manuallyEditedPlatforms[PlatformIdentifier.FACEBOOK]) {
      const newData = { ...manuallyEditedPlatforms, facebook: true };
      dispatch(setManuallyEditedPlatforms(newData || {}));
    }
    mediaRemoveHandler(data);
  };

  const handleOpeningEditArea = async (source, width, height) => {
    const { new_width, new_height } = await recursiveAspectFinder(
      600,
      400,
      width,
      height,
      1
    );
    try {
      const image = new Image();
      image.src = source;
      const canvas = document.createElement("canvas");
      canvas.width = new_width;
      canvas.height = new_height;
      let scale = Math.max(
        canvas.width / image.naturalWidth,
        canvas.height / image.naturalHeight
      );
      let x = canvas.width / 2 - (image.naturalWidth / 2) * scale;
      let y = canvas.height / 2 - (image.naturalHeight / 2) * scale;
      canvas
        .getContext("2d")
        .drawImage(
          image,
          x,
          y,
          image.naturalWidth * scale,
          image.naturalHeight * scale
        );
      const dataURL = canvas.toDataURL();
      setImageToEditUrl(dataURL);
      setComparebleUrl(source);
      setOpenEditArea(!openEditArea);
    } catch (error) {
      const image = new Image();
      image.src = source;
      const canvas = document.createElement("canvas");
      setTimeout(() => {
        canvas.width = 600;
        canvas.height = 400;
        let scale = Math.max(
          canvas.width / image.naturalWidth,
          canvas.height / image.naturalHeight
        );
        let x = canvas.width / 2 - (image.naturalWidth / 2) * scale;
        let y = canvas.height / 2 - (image.naturalHeight / 2) * scale;
        canvas
          .getContext("2d")
          .drawImage(
            image,
            x,
            y,
            image.naturalWidth * scale,
            image.naturalHeight * scale
          );
        const dataURL = canvas.toDataURL();
        setImageToEditUrl(dataURL);
        setComparebleUrl(source);
        setOpenEditArea(!openEditArea);
      }, 1000);
    }
  };

  const handleConnect = async () => {
    try {
      googleDriveRef.current.click();
    } catch (error) {

    }
  }

  useEffect(() => {
    if (googleDriveManager['fromPreviews'] && googleDriveManager['promptConnect']) {
      setOpenConfirmation(true)
    }
  }, [googleDriveManager])

  const displayMedias = useableMedia?.map((item, index) => {
    return <FacebookMediaDisplay
      item={item}
      useableMedia={useableMedia}
      index={index}
      handleOpeningEditArea={handleOpeningEditArea}
      handleDeleteMedia={handleDeleteMedia}
      setOpenVideoEditArea={setOpenVideoEditArea}
      openVideoEditArea={openVideoEditArea}
      canOPenPopOver={canOPenPopOver}
      expandMedias={expandMedias}
      handleExpansion={handleExpansion}
      setUseableMedia={setUseableMedia}
      viewOnly={viewOnly}
    />
  });

  const limitChars = (text, limit) => {
    setLimitedPostTextBody(text?.substring(0, limit));
  };
  const waitTime = 1000;

  function runWhenUserStopTying() {
    window.clearTimeout(timer);
    setTimer(
      window.setTimeout(() => {
        if (!manuallyEditedPlatforms[PlatformIdentifier.FACEBOOK]) {
          limitChars(media_items_arrangement?.facebook?.text, 63206);
        }
      }, waitTime)
    );
  }

  useEffect(() => {
    runWhenUserStopTying();
  }, [media_items_arrangement?.facebook?.text]);

  useEffect(() => {
    limitChars(media_items_arrangement?.facebook?.text, 63206);
  }, []);

  const handleEditClick = () => {
    setToggleEditTextInput(true);
  };
  const addMediaFromUrl = (obj = mediaAddedViaSpecificPlaformUrlMethod[0]) => {
    const { extension, thumbnail, ...rest } = obj;
    setBlockMediaItemsUpdate(false);
    media_items_arrangement?.facebook?.media.length
      ? setUseableMedia((oldArray) => [...oldArray, rest])
      : setUseableMedia([rest]);

    if (!manuallyEditedPlatforms[PlatformIdentifier.FACEBOOK]) {
      const newData = { ...manuallyEditedPlatforms, facebook: true };
      dispatch(setManuallyEditedPlatforms(newData || {}));
    }
    dispatch(setSpecificMediaPlatformTied("global"));
    if (googleDriveManager?.docs) {
      dispatch(setGoogleDriveManager({ ...googleDriveManager, docs: null }))
    }
  };

  useEffect(() => {
    if (!googleDriveManager?.docs || localStorage.getItem('gdrivePlatform') !== PlatformIdentifier.FACEBOOK) return;
    addMediaFromUrl(googleDriveManager?.docs);
    immediateActionWhenClieked(true)
  }, [googleDriveManager])


  useEffect(() => {
    specificMediaPlatformTied === PlatformIdentifier.FACEBOOK &&
      mediaAddedViaSpecificPlaformUrlMethod.length &&
      addMediaFromUrl();
  }, [mediaAddedViaSpecificPlaformUrlMethod]);

  const saveDesignStateImage = async (newUrl) => {
    closeImgEditor();
    setUseableMedia((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.url === comparebleUrl) {
          setBlockMediaItemsUpdate(false);
          return { ...obj, url: newUrl, optimized: true };
        }
        return obj;
      });
      if (!manuallyEditedPlatforms[PlatformIdentifier.FACEBOOK]) {
        const newData = { ...manuallyEditedPlatforms, facebook: true };
        dispatch(setManuallyEditedPlatforms(newData || {}));
      }
      dispatch(setSelectedPlatformForPreview("refresh"));
      setTimeout(() => {
        dispatch(setSelectedPlatformForPreview(PlatformIdentifier.FACEBOOK));
      }, 1000);
      return newState;
    });
  };

  useEffect(() => {
    !openEditArea ? setCanOpenPopOver(true) : setCanOpenPopOver(false);
  }, [openEditArea]);


  const immediateActionWhenClieked = (remove) => {
    if (remove) {
      localStorage.removeItem("gdrivePlatform");
      return;
    }
    localStorage.setItem("gdrivePlatform", PlatformIdentifier.FACEBOOK);
  }

  const imagePopulateFunction = () => {
    if (previewerData && !useableMedia.length) {
      return <GridImageOne>
        {
          <LinkPreviewer
            domain={previewerData?.siteName}
            img={previewerData?.images?.length ? previewerData?.images[0] : null}
            requestUrl={previewerData?.url}
            title={previewerData?.title}
            favicon={previewerData?.favicons?.length ? previewerData?.favicons[0] : null}
          />
        }
      </GridImageOne>
    }
    if (useableMedia.length === 1) {
      return <GridImageOne>{displayMedias}</GridImageOne>
    }
    if (useableMedia.length > 1) {
      return <GridImage
        gridRow={useableMedia.length < 3 ? "150px" : "100px"}
        gridColumn={
          useableMedia.length === 1
            ? "1fr"
            : useableMedia.length < 5
              ? "1fr 1fr"
              : "1fr 1fr"
        }
        gridOverFiveShow={
          useableMedia.length < 5 ? "repeat(2, 1fr)" : null
        }
        style={{
          gridTemplateAreas:
            !expandMedias &&
            useableMedia.length > 4 &&
            `'header header header menu menu menu'
          'main main right right footer footer'`,
          gridTemplateColumns:
            useableMedia.length < 5
              ? "repeat(2, 1fr)"
              : expandMedias
                ? "repeat(3, 1fr)"
                : null,
        }}
      >
        {canShowMedia && displayMedias}
      </GridImage>
    }
    return null
  }

  // if (!useableMedia.length && !limitedPostTextBody && !manuallyEditedPlatforms[PlatformIdentifier.FACEBOOK]) {
  //   return <></>
  // }


  return (
    <>
      {
        googleDriveManager?.openPicker ? <GDriveDashboard platform={PlatformIdentifier.FACEBOOK} /> : null
      }
      {openEditArea && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vh",
            width: "100%",
            zIndex: 10000,
          }}
        >
          <FilerobotImageEditor
            source={imageToEditUrl}
            theme={{
              palette: {
                "bg-secondary": !config.isDark
                  ? lightTheme?.rootBackground
                  : "#0b1727",
                "txt-primary": !config.isDark ? "#2e2e2e" : "#919090",
              },
              p: {
                fontFamily: "Roboto, Arial",
              },
            }}
            onBeforeSave={() => false}
            onSave={async (editedImageObject, designState) => {
              let canvas = editedImageObject.imageCanvas;
              saveDesignStateImage(canvas.toDataURL());
            }}
            onClose={closeImgEditor}
            annotationsCommon={{
              fill: "#ff0000",
            }}
            Text={{ text: "Postly..." }}
            tabsIds={[
              TABS.ADJUST,
              TABS.ANNOTATE,
              TABS.WATERMARK,
              TABS.FILTERS,
              TABS.FINETUNE,
              TABS.RESIZE,
            ]}
            defaultTabId={TABS.ANNOTATE} // or 'Annotate'
            defaultToolId={TOOLS.TEXT} // or 'Text'
          />
        </div>
      )}
      {
        !viewOnly &&
        <Flex
          gap={3}
          className={`mt-2`}
        >
          <MultimediaSelectionDropdown
            dropDownAnchor={
              <CircularButton title="Add Image">
                <FontAwesomeIcon onClick={() => immediateActionWhenClieked(false)} icon={faImage} />
              </CircularButton>
            }
            specificPlatformTied={PlatformIdentifier.FACEBOOK}
            fromPreview={true}
            initiatorId={`${PlatformIdentifier.FACEBOOK}-photo-chooser`}
            externalFilePickerTitle={"Enter Photo Location"}
            prompt={"Add Photo From"}
            fileType={"image"}
            externalPickerPromptLabel={"Photo"}
            multiple={true}
            fileSelectionHandler={(response) => {
              if (response) {
                let { files, from_local, ...others } = response;
                updateMediaItems(files, from_local, others);
              }
            }}
          />
          {
            !isFacebookPostCarousel &&
            <MultimediaSelectionDropdown
              dropDownAnchor={
                <CircularButton
                  title="Add Video">
                  <FontAwesomeIcon icon={faVideo} />
                </CircularButton>
              }
              specificPlatformTied={PlatformIdentifier.FACEBOOK}
              fromPreview={true}
              initiatorId={`${PlatformIdentifier.FACEBOOK}-video-chooser`}
              externalFilePickerTitle={"Enter Video Location"}
              prompt={"Add Video From"}
              fileType={"video"}
              externalPickerPromptLabel={"Video"}
              multiple={false}
              fileSelectionHandler={(response) => {
                if (response) {
                  let { files, from_local, ...others } = response;
                  updateMediaItems(files, from_local, others);
                }
              }}
            />
          }
          {
            isFacebookPostCarousel && <CircularButton
              disabled={true}
              title="To upload video, you need to disable Facebook carousel">
              <FontAwesomeIcon
                icon={faVideo}
                color={config.isDark ? '#5b6473' : '#ECEFF1'}
              // color="#ECEFF1"
              />
            </CircularButton>
          }
          {!toggleEditTextInput && (
            <CircularButton
              editBgColor={!config.isDark ? "#e0e0e0" : "#333333"}
              onClick={() => handleEditClick()}
              title="Edit Text"
            >
              <FontAwesomeIcon icon={faPencil} />
            </CircularButton>
          )}
          <CircularButton
            editBgColor={!config.isDark ? "#e0e0e0" : "#333333"}
            onClick={() => setRepositionImages(!repostionImages)}
            title="re-position images"
          >
            <RiDragDropLine size={20} />
          </CircularButton>
        </Flex>
      }
      <ParentSection>
        <GDriveConnectionRequestListener
          googleDriveRef={googleDriveRef}
        />
        <Wrapper>
          {toggleEditTextInput && (
            <EditTextBox
              ref={textEditRef}
              isPlatformEdited={PlatformIdentifier.FACEBOOK}
              incomingRef={textEditRef}
              value={limitedPostTextBody}
              setValue={setLimitedPostTextBody}
              setToggleEditTextInput={setToggleEditTextInput}
              platform={PlatformIdentifier.FACEBOOK}
              setter={{ ...manuallyEditedPlatforms, facebook: true }}
              updateText={handleUpdateTextFromEditBox}
            />
          )}
          <PreviewContentArea
            style={{
              borderColor: `${!config.isDark ? "#ededed" : "transparent"}`,
            }}
            mainBgColor={!config.isDark ? "white" : "#0b1727"}
            theme={!config.isDark ? "1px 1px 3px #949494" : "none"}
            ref={focusImageRef}
          >
            <div style={{ position: 'absolute', bottom: 0, right: 5, fontSize: 11, }}>
              Character Count: {formattedPostBodyForLinks?.length} / 62206
            </div>
            {(!viewOnly && manuallyEditedPlatforms && manuallyEditedPlatforms[PlatformIdentifier.FACEBOOK]) && (
              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  fontStyle: "italic",
                  position: "absolute",
                  top: 0,
                  right: 5,
                  fontSize: 11,
                }}
              >
                <p style={{ color: !config.isDark ? "grey" : "grey" }}>
                  Edited
                </p>
              </div>
            )}
            <FirstLayer>
              {displayPlatformIcon(PlatformIdentifier.FACEBOOK)}
              <Info>
                <span
                  type={"p"}
                  style={{
                    fontSize: 11,
                    fontWeight: "600",
                    color: `${!config.isDark ? "#050505" : "#E4E6EB"}`,
                  }}
                >
                  Facebook Preview
                </span>
                <span
                  type={"p"}
                  style={{
                    fontSize: 9,
                    color: "#65676B",
                  }}
                >
                  {moment(timeToPost).format("MMM D")}{" "}
                  {moment(timeToPost).format("h:mm a")} -{" "}
                  <FontAwesomeIcon
                    style={{
                      color: `${!config.isDark ? "#b0b3b8" : "#b0b3b8"}`,
                    }}
                    icon={faEarth}
                  />
                </span>
              </Info>

              <DotsBox>
                <FontAwesomeIcon
                  style={{
                    color: "#78909C",
                    fontSize: 9
                  }}
                  icon={faEllipsis}
                />
              </DotsBox>
            </FirstLayer>
            {
              repostionImages ? <DragToPositionImage useableMedia={useableMedia} setter={setUseableMedia} platform={PlatformIdentifier.FACEBOOK} /> : <SecondLayer>

                {/* <TextareaAutoSize
                  id="compose-box"
                  minRows={2}
                  ref={textRef}
                  as="textarea"
                  placeholder={''}
                  className={`shadow-none resize-none border-0 ${config.isDark ? "border-x" : ""
                    } px-2 border-200`}
                  onChange={(e) => {
                    let value = e.target.value;
                    handleUpdateTextFromEditBox(value);
                  }}
                  style={{
                    width: "100%",
                    outline: "none",
                    whiteSpace: "pre-wrap",
                    display: "inherit",
                    background: config.isDark ? "#0b1727" : "#fff",
                    color: "inherit",
                    fontSize: 11,
                  }}
                  maxRows={20}
                /> */}
                <pre
                  style={{
                    margin: "1%",
                    marginTop: 10,
                    marginBottom: 10,
                    color: `${!config.isDark ? "black" : "#b3b3b3"}`,
                    fontFamily:
                      'Roboto, -apple-system, "apple color emoji", BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
                    lineHeight: "21px",
                    fontWeight: 400,
                    maxWidth: "100%",
                    width: "100%",
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {parse(formattedPostBodyForLinks)}
                </pre>
                {imagePopulateFunction()}
                {expandMedias && (
                  <button
                    onClick={handleExpansion}
                    style={{
                      margin: 20,
                      padding: "1%",
                      height: 40,
                      width: "90%",
                      backgroundColor: `${!config.isDark ? "#dcdedc" : "#dcdedc"
                        }`,
                      borderRadius: "10px",
                    }}
                  >
                    Collapse Medias
                  </button>
                )}
              </SecondLayer>
            }

            <ThirdLayer
              borderLineColor={
                !config.isDark ? "#f7f7f7 transparent" : "#0b1727 transparent"
              }
            >
              <EachBtn>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${!config.isDark ? "#65676B" : "#B0B3B8"}`}
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="M21.406 9.558c-1.21-.051-2.87-.278-3.977-.744.809-3.283 1.253-8.814-2.196-8.814-1.861 0-2.351 1.668-2.833 3.329-1.548 5.336-3.946 6.816-6.4 7.401v-.73h-6v12h6v-.904c2.378.228 4.119.864 6.169 1.746 1.257.541 3.053 1.158 5.336 1.158 2.538 0 4.295-.997 5.009-3.686.5-1.877 1.486-7.25 1.486-8.25 0-1.648-1.168-2.446-2.594-2.506zm-17.406 10.442h-2v-8h2v8zm15.896-5.583s.201.01 1.069-.027c1.082-.046 1.051 1.469.004 1.563l-1.761.099c-.734.094-.656 1.203.141 1.172 0 0 .686-.017 1.143-.041 1.068-.056 1.016 1.429.04 1.551-.424.053-1.745.115-1.745.115-.811.072-.706 1.235.109 1.141l.771-.031c.822-.074 1.003.825-.292 1.661-1.567.881-4.685.131-6.416-.614-2.239-.965-4.438-1.934-6.959-2.006v-6c3.264-.749 6.328-2.254 8.321-9.113.898-3.092 1.679-1.931 1.679.574 0 2.071-.49 3.786-.921 5.533 1.061.543 3.371 1.402 6.12 1.556 1.055.059 1.024 1.455-.051 1.584l-1.394.167s-.608 1.111.142 1.116z" />
                </svg>{" "}
                <span
                  type={"p"}
                  style={{
                    color: `${!config.isDark ? "#78909C" : "#B0B3B8"}`,
                  }}
                >
                  Like
                </span>
              </EachBtn>
              <EachBtn>
                <svg
                  style={{ marginTop: 5 }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${!config.isDark ? "#65676B" : "#B0B3B8"}`}
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                >
                  <path d="M22 3v13h-11.643l-4.357 3.105v-3.105h-4v-13h20zm2-2h-24v16.981h4v5.019l7-5.019h13v-16.981z" />
                </svg>{" "}
                <span
                  type={"p"}
                  style={{
                    color: `${!config.isDark ? "#78909C" : "#B0B3B8"}`,
                  }}
                >
                  Comment
                </span>
              </EachBtn>
              {/* <EachBtn>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 24 24"
                  id="share"
                >
                  <rect
                    id="Rectangle_3"
                    data-name="Rectangle 3"
                    width="24"
                    height="24"
                    fill="none"
                  />
                  <path
                    id="Rectangle"
                    d="M12,4V0l8,7-8,7V10S0,9.069,0,14.737C0,3.4,12,4,12,4Z"
                    transform="translate(2 5)"
                    fill="none"
                    stroke={`${!config.isDark ? "#65676B" : "#B0B3B8"}`}
                    stroke-miterlimit="10"
                    stroke-width="1.5"
                  />
                </svg>{" "}
                <span
                  type={"p"}
                  style={{
                    color: `${!config.isDark ? "#78909C" : "#B0B3B8"}`,
                  }}
                >
                  Share
                </span>
              </EachBtn> */}
              <ImageHolder>
                <img
                  style={{
                    minHeight: "20px",
                    minWidth: "20px",
                    maxHeight: "20px",
                    maxWidth: "20px",
                    borderRadius: "100%",
                    backgroundColor: "white",
                  }}
                  src="https://app.postly.ai/static/media/postly_logo.2e3243cd201fdf7e1b5c.png"
                  alt="dp"
                />
              </ImageHolder>
            </ThirdLayer>
          </PreviewContentArea>
        </Wrapper>

        {/* <EditContainer style={{ justifyContent: "space-around" }}>
        
         
        </EditContainer> */}
        <Modal
          show={openPixelDialoguePicker}
          onHide={() => {
            dispatch(setOpenPixelFilePickerDialog(false));
          }}
          onExit={() => {
            dispatch(setOpenPixelFilePickerDialog(false));
          }}
        >
          <Modal.Header>
            <Modal.Title>Pexels.com</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ExtrernalImageSearchDialogue
              setUseableMedia={setUseableMedia}
              determiner={PlatformIdentifier.FACEBOOK}
              modalClose={() => {
              }}
            />
          </Modal.Body>
        </Modal>
        <ConfirmModal
          open={openConfirmation}
          title={"Connect Your Google Drive Account"}
          message={'Your google drive account is not connected or has been disconnected. Do you wish to connect?'}
          confirmText={"PROCEED"}
          cancelText={"CANCEL"}
          showDialoguePrompt
          onCancel={() => {
            setOpenConfirmation(false);
            dispatch(setGoogleDriveManager({
              ...googleDriveManager,
              promptConnect: false,
            }))
          }}
          onConfirm={() => {
            handleConnect();
            dispatch(setGoogleDriveManager({ ...googleDriveManager, promptConnect: false, fromPreviews: false }))
          }}
        />
      </ParentSection>
    </>
  );
};

export default Facebook;