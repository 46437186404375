export const pricingDataNew = [
  {
    id: 0,
    title: 'Scheduler Pro',
    plan: "SchedulerPro",
    quantity: 5,
    trial_period_days: 7,
    subTitle: 'For creators and influencers',
    price: {
      Month: 10,
      Year: 96
    },
    extraPrice: {
      Month: 2,
      Year: 19.2
    },
    extraUserPrice: {
      Month: 10,
      Year: 96
    },
    currencySign: '$',
    url: '/pro',
    buttonText: 'Start 7-day trial',
    buttonTextForPayingCustomer: 'Switch to Pro',
    cancelText: 'Cancel subscription',
    activePlanButtonText: 'Add Social Accounts',
    badge: 'Scheduler',
    isFeatured: false,
    periodical: true,
    billingFor: '/social account',
    // featureTitle: 'Everything in Solo, plus:',
    features: [
      { id: 1, title: 'Unlimited Workspaces & Posts' },
      { id: 2, title: '5 social accounts | $2 per extra/mon' },
      { id: 4, title: '1 user | $10 per extra user' },
      { id: 5, title: 'Manage 1 brand/client' },
      { id: 6, title: '100k AI credits per month' },
      { id: 6, title: 'No white-label' },
      { id: 6, title: '10GB cloud storage' },
      // { id: 7, title: `500 bulk posts/month` },
      { id: 8, title: `Custom Telegram Bot Addon` },
      { id: 9, title: `No access to automations` },
      { id: 10, title: `Single approvals` },
    ],
    one_off: false,
    "revision": 2,
    depreciated: false,
    offScreen: false,
  },
  {
    id: 1,
    title: 'Scheduler Premium',
    plan: "SchedulerPremium",
    quantity: 15,
    trial_period_days: 7,
    one_off: false,
    subTitle: 'For businesses',
    price: {
      Month: 30,
      Year: 288
    },
    extraPrice: {
      Month: 2,
      Year: 19.2
    },
    extraUserPrice: {
      Month: 10,
      Year: 96
    },
    currencySign: '$',
    isFeatured: true,
    periodical: true,
    url: '/premium',
    buttonText: 'Start 7-day trial',
    buttonTextForPayingCustomer: 'Switch to Premium',
    cancelText: 'Cancel subscription',
    activePlanButtonText: 'Add Social Accounts',
    badge: 'Scheduler',
    billingFor: '/social account',
    // featureTitle: 'Everything in Basic, plus:',
    features: [
      { id: 1, title: 'Unlimited Workspaces & Posts' },
      { id: 2, title: '15 social accounts | $2 per extra/mon' },
      { id: 4, title: '2 users | $10 per extra user' },
      { id: 5, title: 'Manage 3 Brands/Clients' },
      { id: 6, title: 'No white-label' },
      { id: 6, title: '30GB cloud storage' },
      { id: 6, title: '300k AI credits per month' },
      { id: 7, title: `Unlimited Bulk posts` },
      { id: 8, title: `Custom Telegram Bot Addon` },
      { id: 9, title: `Access to automations` },
      { id: 10, title: `Multiple approvals` },

    ],
    "revision": 2
  },
  {
    id: 2,
    title: 'Scheduler Growth',
    plan: "SchedulerGrowth",
    quantity: 50,
    trial_period_days: 7,
    one_off: false,
    MonthlySub: "882119",
    AnnualSub: "882117",
    subTitle: 'For large businesses',
    price: {
      Month: 100,
      Year: 960
    },
    extraPrice: {
      Month: 2,
      Year: 19.2
    },
    extraUserPrice: {
      Month: 10,
      Year: 96
    },
    currencySign: '$',
    periodical: true,
    url: '/agency',
    buttonText: 'Start 7-day trial',
    buttonTextForPayingCustomer: 'Switch to Growth',
    cancelText: 'Cancel subscription',
    activePlanButtonText: 'Add Social Accounts',
    badge: 'Scheduler',
    isFeatured: false,
    billingFor: '/social account',
    // featureTitle: 'Everything in Basic, plus:',
    features: [
      { id: 1, title: 'Unlimited Workspaces & Posts' },
      { id: 2, title: '50 social accounts | $2 per extra/mon' },
      { id: 4, title: '5 users | $10 per extra user' },
      { id: 5, title: 'Manage 10 Brands/Clients' },
      { id: 6, title: 'No white-label' },
      { id: 7, title: '50GB cloud storage' },
      { id: 8, title: 'Unlimited AI credits' },
      { id: 9, title: 'Unlimited bulk post' },
      { id: 10, title: `Custom Telegram Bot Addon` },
      { id: 11, title: `Access to automations` },
      { id: 12, title: `Multiple approvals` },
    ],
    "revision": 2,

  },
  {
    id: 3,
    title: 'Scheduler Agency',
    plan: "Agency",
    quantity: 250,
    trial_period_days: 7,
    one_off: false,
    MonthlySub: "882112",
    AnnualSub: "882111",
    subTitle: 'For agencies',
    price: {
      Month: 375,
      Year: 3600
    },
    extraPrice: {
      Month: 1.5,
      Year: 14.4
    },
    extraUserPrice: {
      Month: 10,
      Year: 96
    },
    currencySign: '$',
    periodical: true,
    url: '/agency',
    buttonText: 'Start 7-day trial',
    buttonTextForPayingCustomer: 'Switch to Agency',
    cancelText: 'Cancel subscription',
    activePlanButtonText: 'Add Social Accounts',
    badge: 'Scheduler',
    isFeatured: false,
    billingFor: '/social account',
    // featureTitle: 'Everything in Basic, plus:',
    features: [
      { id: 1, title: 'Unlimited Workspaces & Posts' },
      { id: 2, title: '250 social accounts | $1.5 per extra/mon' },
      { id: 4, title: '25 users | $10 per extra user' },
      { id: 6, title: 'Manage 25 Brands/Clients' },
      { id: 6, title: 'White-label included' },
      { id: 6, title: '60GB cloud storage' },
      { id: 6, title: 'Unlimited AI credits' },
      { id: 7, title: `Unlimited Bulk posts` },
      { id: 8, title: `Custom Telegram Bot Addon` },
      { id: 9, title: `Access to automations` },
      { id: 10, title: `Multiple approvals` },
    ],
    "revision": 2
  },
  {
    id: 5,
    title: 'Scheduler Enterprise',
    plan: "Enterprise",
    quantity: 500,
    trial_period_days: 7,
    one_off: false,
    MonthlySub: "882104",
    AnnualSub: "882103",
    subTitle: 'For agencies and enterprises',
    price: {
      Month: 750,
      Year: 7200
    },
    extraPrice: {
      Month: 1.5,
      Year: 14.4
    },
    extraUserPrice: {
      Month: 10,
      Year: 96
    },
    currencySign: '$',
    periodical: true,
    url: '/enterprise',
    buttonText: 'Start 7-day trial',
    buttonTextForPayingCustomer: 'Switch to Enterprise',
    cancelText: 'Cancel subscription',
    activePlanButtonText: 'Add Social Accounts',
    badge: 'Scheduler',
    isFeatured: false,
    billingFor: '/social account',
    // featureTitle: 'Everything in Basic, plus:',
    features: [
      { id: 1, title: 'Unlimited Workspaces & Posts' },
      { id: 2, title: '500 social accounts | $1.5 per extra/mon' },
      { id: 4, title: '50 Users |  $10 per extra user' },
      { id: 6, title: 'Manage 100 Brands/Clients' },
      { id: 6, title: 'White-label included' },
      { id: 6, title: '100GB cloud storage' },
      { id: 6, title: 'Unlimited AI credits' },
      { id: 7, title: `Unlimited Bulk posts` },
      { id: 8, title: `Custom Telegram Bot Addon` },
      { id: 9, title: `Access to automations` },
      { id: 10, title: `Multiple approvals` },
      // { id: 10, title: `Unlimited active recurring posts` },
    ],
    "revision": 2
  },
];

export const getSchedulerPricingData = (userPlan) => {
  const revisionNumber = userPlan?.revision
  let freePlan = userPlan?.Name?.toLowerCase() === "free" || userPlan?.Key?.toLowerCase() === "Free"
  if (revisionNumber === 3 || freePlan) {
    return [
      {
        id: 0,
        title: 'Professional',
        plan: "Starter_v3",
        quantity: 1,
        trial_period_days: 7,
        subTitle: 'For freelancers & creators',
        price: {
          Month: 10,
          Year: 96
        },
        extraPrice: {
          Month: 2,
          Year: 19.2
        },
        extraUserPrice: {
          Month: 10,
          Year: 96
        },
        currencySign: '$',
        url: '/pro',
        buttonText: 'Get Professional',
        buttonTextForPayingCustomer: 'Switch to Professional',
        cancelText: 'Cancel subscription',
        activePlanButtonText: 'Add Social Accounts',
        badge: 'Scheduler',
        isFeatured: false,
        periodical: true,
        billingFor: '/social account',
        // featureTitle: 'Everything in Solo, plus:',
        features: [
          { id: 1, title: '10 channels' },
          { id: 2, title: '5 GB cloud storage' },
          { id: 3, title: '20,000 AI credits/mon' },
          { id: 4, title: 'Unlimited Workspaces' },
          { id: 5, title: 'Unlimited users' },
          { id: 6, title: 'Unlimited scheduled posts' },
          { id: 7, title: 'White-label add-on' },
          { id: 8, title: `Custom Telegram Bot add-on` },
        ],
        one_off: false,
        "revision": 3,
        depreciated: false,
        offScreen: false,
      },
      {
        id: 1,
        title: 'Essential',
        plan: "Essential_v3",
        quantity: 1,
        trial_period_days: 7,
        one_off: false,
        subTitle: 'For startups',
        price: {
          Month: 60,
          Year: 576
        },
        extraPrice: {
          Month: 2,
          Year: 19.2
        },
        extraUserPrice: {
          Month: 10,
          Year: 96
        },
        currencySign: '$',
        isFeatured: true,
        periodical: true,
        url: '/premium',
        buttonText: 'Get Essential',
        buttonTextForPayingCustomer: 'Switch to Essential',
        cancelText: 'Cancel subscription',
        activePlanButtonText: 'Add Social Accounts',
        badge: 'Scheduler',
        billingFor: '/social account',
        // featureTitle: 'Everything in Basic, plus:',
        features: [
          { id: 1, title: '60 channels' },
          { id: 2, title: '15 GB cloud storage' },
          { id: 3, title: '50,000 AI credits/mon' },
          { id: 4, title: 'Unlimited Workspaces' },
          { id: 5, title: 'Unlimited users' },
          { id: 6, title: 'Unlimited scheduled posts' },
          { id: 7, title: 'White-label add-on' },
          { id: 8, title: `Custom Telegram Bot add-on` },
        ],
        "revision": 3
      },
      {
        id: 2,
        title: 'Business',
        plan: "Business_v3",
        quantity: 1,
        trial_period_days: 7,
        one_off: false,
        MonthlySub: "882119",
        AnnualSub: "882117",
        subTitle: 'For businesses',
        price: {
          Month: 150,
          Year: 1440
        },
        extraPrice: {
          Month: 2,
          Year: 19.2
        },
        extraUserPrice: {
          Month: 10,
          Year: 96
        },
        currencySign: '$',
        periodical: true,
        url: '/agency',
        buttonText: 'Get Business',
        buttonTextForPayingCustomer: 'Switch to Business',
        cancelText: 'Cancel subscription',
        activePlanButtonText: 'Add Social Accounts',
        badge: 'Scheduler',
        isFeatured: false,
        billingFor: '/social account',
        // featureTitle: 'Everything in Basic, plus:',
        features: [
          { id: 1, title: '150 channels' },
          { id: 2, title: '25 GB cloud storage' },
          { id: 3, title: '100,000 AI credits/mon' },
          { id: 4, title: 'Unlimited Workspaces' },
          { id: 5, title: 'Unlimited users' },
          { id: 6, title: 'Unlimited scheduled posts' },
          { id: 7, title: 'White-label add-on' },
          { id: 8, title: `Custom Telegram Bot add-on` },
        ],
        "revision": 3,

      },
      {
        id: 3,
        title: 'Agency',
        plan: "Agency_v3",
        quantity: 1,
        trial_period_days: 7,
        one_off: false,
        MonthlySub: "882112",
        AnnualSub: "882111",
        subTitle: 'For agencies',
        price: {
          Month: 375,
          Year: 3600
        },
        extraPrice: {
          Month: 1.5,
          Year: 14.4
        },
        extraUserPrice: {
          Month: 10,
          Year: 96
        },
        currencySign: '$',
        periodical: true,
        url: '/agency',
        buttonText: 'Get Agency',
        buttonTextForPayingCustomer: 'Switch to Agency',
        cancelText: 'Cancel subscription',
        activePlanButtonText: 'Add Social Accounts',
        badge: 'Scheduler',
        isFeatured: false,
        billingFor: '/social account',
        // featureTitle: 'Everything in Basic, plus:',
        features: [
          { id: 1, title: 'Unlimited channels' },
          { id: 2, title: '50 GB cloud storage' },
          { id: 3, title: 'Unlimited AI credits/mon' },
          { id: 4, title: 'Unlimited Workspaces' },
          { id: 5, title: 'Unlimited users' },
          { id: 6, title: 'Unlimited scheduled posts' },
          { id: 7, title: 'White-label add-on' },
          { id: 8, title: `Custom Telegram Bot add-on` },
        ],
        "revision": 3
      },
    ]
  } else {
    return [
      {
        id: 0,
        title: 'Scheduler Pro',
        plan: "SchedulerPro",
        quantity: 5,
        trial_period_days: 7,
        subTitle: 'For creators and influencers',
        price: {
          Month: 10,
          Year: 96
        },
        extraPrice: {
          Month: 2,
          Year: 19.2
        },
        extraUserPrice: {
          Month: 10,
          Year: 96
        },
        currencySign: '$',
        url: '/pro',
        buttonText: 'Get Pro',
        buttonTextForPayingCustomer: 'Switch to Pro',
        cancelText: 'Cancel subscription',
        activePlanButtonText: 'Add Social Accounts',
        badge: 'Scheduler',
        isFeatured: false,
        periodical: true,
        billingFor: '/social account',
        // featureTitle: 'Everything in Solo, plus:',
        features: [
          { id: 1, title: 'Unlimited Workspaces & Posts' },
          { id: 2, title: '5 social accounts | $2 per extra/mon' },
          { id: 4, title: '1 user | $10 per extra user' },
          { id: 5, title: 'Manage 1 brand/client' },
          { id: 6, title: '100k AI credits per month' },
          { id: 6, title: 'No white-label' },
          { id: 6, title: '10GB cloud storage' },
          // { id: 7, title: `500 bulk posts/month` },
          { id: 8, title: `Custom Telegram Bot Addon` },
          { id: 9, title: `No access to automations` },
          { id: 10, title: `Single approvals` },
        ],
        one_off: false,
        "revision": 2,
        depreciated: false,
        offScreen: false,
      },
      {
        id: 1,
        title: 'Scheduler Premium',
        plan: "SchedulerPremium",
        quantity: 15,
        trial_period_days: 7,
        one_off: false,
        subTitle: 'For businesses',
        price: {
          Month: 30,
          Year: 288
        },
        extraPrice: {
          Month: 2,
          Year: 19.2
        },
        extraUserPrice: {
          Month: 10,
          Year: 96
        },
        currencySign: '$',
        isFeatured: true,
        periodical: true,
        url: '/premium',
        buttonText: 'Get Premium',
        buttonTextForPayingCustomer: 'Switch to Premium',
        cancelText: 'Cancel subscription',
        activePlanButtonText: 'Add Social Accounts',
        badge: 'Scheduler',
        billingFor: '/social account',
        // featureTitle: 'Everything in Basic, plus:',
        features: [
          { id: 1, title: 'Unlimited Workspaces & Posts' },
          { id: 2, title: '15 social accounts | $2 per extra/mon' },
          { id: 4, title: '2 users | $10 per extra user' },
          { id: 5, title: 'Manage 3 Brands/Clients' },
          { id: 6, title: 'No white-label' },
          { id: 6, title: '30GB cloud storage' },
          { id: 6, title: '300k AI credits per month' },
          { id: 7, title: `Unlimited Bulk posts` },
          { id: 8, title: `Custom Telegram Bot Addon` },
          { id: 9, title: `Access to automations` },
          { id: 10, title: `Multiple approvals` },

        ],
        "revision": 2
      },
      {
        id: 2,
        title: 'Scheduler Growth',
        plan: "SchedulerGrowth",
        quantity: 50,
        trial_period_days: 7,
        one_off: false,
        MonthlySub: "882119",
        AnnualSub: "882117",
        subTitle: 'For large businesses',
        price: {
          Month: 100,
          Year: 960
        },
        extraPrice: {
          Month: 2,
          Year: 19.2
        },
        extraUserPrice: {
          Month: 10,
          Year: 96
        },
        currencySign: '$',
        periodical: true,
        url: '/agency',
        buttonText: 'Get Growth',
        buttonTextForPayingCustomer: 'Switch to Growth',
        cancelText: 'Cancel subscription',
        activePlanButtonText: 'Add Social Accounts',
        badge: 'Scheduler',
        isFeatured: false,
        billingFor: '/social account',
        // featureTitle: 'Everything in Basic, plus:',
        features: [
          { id: 1, title: 'Unlimited Workspaces & Posts' },
          { id: 2, title: '50 social accounts | $2 per extra/mon' },
          { id: 4, title: '5 users | $10 per extra user' },
          { id: 5, title: 'Manage 10 Brands/Clients' },
          { id: 6, title: 'No white-label' },
          { id: 7, title: '50GB cloud storage' },
          { id: 8, title: 'Unlimited AI credits' },
          { id: 9, title: 'Unlimited bulk post' },
          { id: 10, title: `Custom Telegram Bot Addon` },
          { id: 11, title: `Access to automations` },
          { id: 12, title: `Multiple approvals` },
        ],
        "revision": 2,

      },
      {
        id: 3,
        title: 'Scheduler Agency',
        plan: "Agency",
        quantity: 250,
        trial_period_days: 7,
        one_off: false,
        MonthlySub: "882112",
        AnnualSub: "882111",
        subTitle: 'For agencies',
        price: {
          Month: 375,
          Year: 3600
        },
        extraPrice: {
          Month: 1.5,
          Year: 14.4
        },
        extraUserPrice: {
          Month: 10,
          Year: 96
        },
        currencySign: '$',
        periodical: true,
        url: '/agency',
        buttonText: 'Get Agency',
        buttonTextForPayingCustomer: 'Switch to Agency',
        cancelText: 'Cancel subscription',
        activePlanButtonText: 'Add Social Accounts',
        badge: 'Scheduler',
        isFeatured: false,
        billingFor: '/social account',
        // featureTitle: 'Everything in Basic, plus:',
        features: [
          { id: 1, title: 'Unlimited Workspaces & Posts' },
          { id: 2, title: '250 social accounts | $1.5 per extra/mon' },
          { id: 4, title: '25 users | $10 per extra user' },
          { id: 6, title: 'Manage 25 Brands/Clients' },
          { id: 6, title: 'White-label included' },
          { id: 6, title: '60GB cloud storage' },
          { id: 6, title: 'Unlimited AI credits' },
          { id: 7, title: `Unlimited Bulk posts` },
          { id: 8, title: `Custom Telegram Bot Addon` },
          { id: 9, title: `Access to automations` },
          { id: 10, title: `Multiple approvals` },
        ],
        "revision": 2
      },
      {
        id: 5,
        title: 'Scheduler Enterprise',
        plan: "Enterprise",
        quantity: 500,
        trial_period_days: 7,
        one_off: false,
        MonthlySub: "882104",
        AnnualSub: "882103",
        subTitle: 'For agencies and enterprises',
        price: {
          Month: 750,
          Year: 7200
        },
        extraPrice: {
          Month: 1.5,
          Year: 14.4
        },
        extraUserPrice: {
          Month: 10,
          Year: 96
        },
        currencySign: '$',
        periodical: true,
        url: '/enterprise',
        buttonText: 'Get Enterprise',
        buttonTextForPayingCustomer: 'Switch to Enterprise',
        cancelText: 'Cancel subscription',
        activePlanButtonText: 'Add Social Accounts',
        badge: 'Scheduler',
        isFeatured: false,
        billingFor: '/social account',
        // featureTitle: 'Everything in Basic, plus:',
        features: [
          { id: 1, title: 'Unlimited Workspaces & Posts' },
          { id: 2, title: '500 social accounts | $1.5 per extra/mon' },
          { id: 4, title: '50 Users |  $10 per extra user' },
          { id: 6, title: 'Manage 100 Brands/Clients' },
          { id: 6, title: 'White-label included' },
          { id: 6, title: '100GB cloud storage' },
          { id: 6, title: 'Unlimited AI credits' },
          { id: 7, title: `Unlimited Bulk posts` },
          { id: 8, title: `Custom Telegram Bot Addon` },
          { id: 9, title: `Access to automations` },
          { id: 10, title: `Multiple approvals` },
          // { id: 10, title: `Unlimited active recurring posts` },
        ],
        "revision": 2
      },
    ]
  }
}

export const AIWriterPricing = [
  {
    id: 1,
    plan: 'UnlimitedAI',
    title: 'Unlimited AI',
    MonthlySub: "price_1PNZvqI8CUxOnAX43NE9apjp",
    AnnualSub: "price_1PNZwtI8CUxOnAX4vVKTdOgM",
    quantity: 1,
    subTitle: 'Do more with AI',
    price: {
      Month: 30,
      Year: 288
    },
    currencySign: '$',
    url: '/agency',
    buttonText: 'GET NOW',
    buttonTextForPayingCustomer: 'GET NOW',
    cancelText: 'Cancel subscription',
    badge: 'AI',
    isFeatured: false,
    billingFor: '/social account',
    // featureTitle: 'Everything in Basic, plus:',
    features: [
      { id: 1, title: 'Unlimited AI characters', icon: 'check' },
      { id: 2, title: 'Access to all AI tools', icon: 'check' },
      { id: 3, title: 'Access 40+ use-cases', icon: 'check' },
      { id: 4, title: 'Prompt library', icon: 'check' },
      { id: 5, title: '⁠Language support', icon: 'check' },
    ],
    "revision": 1
  }
]

export const postlyCloudPricing = [
  {
    id: 0,
    title: 'Postly Cloud Pro',
    plan: "PostlyCloudPro",
    quantity: 1,
    MonthlySub: "864466",
    AnnualSub: "864467",
    // subTitle: 'For small businesses',
    price: {
      Month: 5,
      Year: 48
    },
    extraPrice: {
      Month: 3,
      Year: 29
    },
    currencySign: '$',
    url: '/postly-cloud-pro',
    buttonText: 'Get Postly Cloud Pro',
    buttonTextForPayingCustomer: 'Get Postly Cloud Pro',
    cancelText: 'Cancel subscription',
    activePlanButtonText: '',
    badge: 'Cloud',
    isFeatured: true,
    periodical: true,
    billingFor: '/postly cloud',
    // featureTitle: 'Everything in Solo, plus:',
    features: [
      { id: 1, title: '20GB Storage limit' },
      { id: 2, title: '20GB Transfer limit per month' },
    ],
    one_off: false,
    "revision": 1,
    passthrough: {
      for_postly_cloud: true
    }
  },
  {
    id: 1,
    title: 'Postly Cloud Premium',
    plan: "PostlyCloudPremium",
    quantity: 1,
    one_off: false,
    MonthlySub: "864468",
    AnnualSub: "864469",
    // subTitle: 'For large businesses',
    price: {
      Month: 10,
      Year: 96,
    },
    extraPrice: {
      Month: 5,
      Year: 48
    },
    currencySign: '$',
    periodical: true,
    url: '/postly-cloud-premium',
    buttonText: 'Get Postly Cloud Premium',
    buttonTextForPayingCustomer: 'Get Postly Cloud Premium',
    cancelText: '',
    activePlanButtonText: '',
    badge: 'Cloud',
    isFeatured: false,
    billingFor: '/postly cloud',
    // featureTitle: 'Everything in Basic, plus:',
    features: [
      { id: 1, title: '50GB Storage limit' },
      { id: 2, title: '50GB Transfer limit per month' },
    ],
    "revision": 1,
    passthrough: {
      for_postly_cloud: true
    }
  },

  {
    id: 2,
    title: 'Postly Cloud Agency',
    plan: "PostlyCloudAgency",
    quantity: 1,
    one_off: false,
    MonthlySub: "864470",
    AnnualSub: "864471",
    // subTitle: 'For agencies',
    price: {
      Month: 20,
      Year: 192
    },
    extraPrice: {
      Month: 2,
      Year: 19.2
    },
    currencySign: '$',
    periodical: true,
    url: '/postly-cloud-agency',
    buttonText: 'Get Postly Cloud Agency',
    buttonTextForPayingCustomer: 'Get Postly Cloud Agency',
    cancelText: '',
    activePlanButtonText: '',
    badge: 'Cloud',
    isFeatured: false,
    billingFor: '/postly cloud',
    // featureTitle: 'Everything in Basic, plus:',
    features: [
      { id: 1, title: '100GB Storage limit' },
      { id: 2, title: '100GB Transfer limit per month' },
    ],
    "revision": 1,
    highlighted: false,
    passthrough: {
      for_postly_cloud: true
    }
  }

];
export const postlyExtraUsers = [
  {
    id: 0,
    title: 'Extra Users',
    plan: "ExtraUsers",
    Users: 0,
    MonthlySub: "876613",
    AnnualSub: "876614",

    // Plans for testing
    // MonthlySub: "68426",
    // AnnualSub: "68426",
    price: {
      Month: 10,
      Year: 96
    },
    currencySign: '$',
    url: '/postly-extra-users',
    buttonText: 'Get Extra Users',
    badge: 'Extra Users',
    isFeatured: true,
    periodical: true,
    billingFor: '/extra-users',
    // featureTitle: 'Everything in Solo, plus:',
    one_off: false,
    "revision": 1,
    passthrough: {
      for_postly_extra_user: true
    }
  },
];
export const extraSocialAccounts = [
  {
    LinkedPlan: "SchedulerPro",
    MonthlySub: "834895",
    AnnualSub: "834896",
    plan: "Extra Scheduler Pro",
    title: "Extra Scheduler Pro",
    passthrough: {
      linkedToExistingPlan: true,
      linkedInPlan: "SchedulerPro",
      downgradable: false,
      assignable: false,
      additional_social_accounts: true
    }
  },
  {
    LinkedPlan: "SchedulerPremium",
    MonthlySub: "834897",
    AnnualSub: "834898",
    plan: "Extra Scheduler Premium",
    title: "Extra Scheduler Premium",
    passthrough: {
      linkedToExistingPlan: true,
      linkedInPlan: "SchedulerPremium",
      downgradable: false,
      assignable: false,
      additional_social_accounts: true
    }
  },
  {
    LinkedPlan: "Agency",
    MonthlySub: "838168",
    AnnualSub: "838169",
    plan: "Extra Agency",
    title: "Extra Agency",
    passthrough: {
      linkedToExistingPlan: true,
      linkedInPlan: "Agency",
      downgradable: false,
      assignable: false,
      additional_social_accounts: true
    }
  },
  {
    LinkedPlan: "SchedulerGrowth",
    MonthlySub: "870209",
    AnnualSub: "870210",
    plan: "Extra Scheduler Growth",
    title: "Extra Scheduler Growth",
    passthrough: {
      linkedToExistingPlan: true,
      linkedInPlan: "SchedulerGrowth",
      downgradable: false,
      assignable: false,
      additional_social_accounts: true
    }
  },
  {
    LinkedPlan: "SchedulerExpansion",
    MonthlySub: "870211",
    AnnualSub: "870212",
    plan: "Extra Scheduler Expansion",
    title: "Extra Scheduler Expansion",
    passthrough: {
      linkedToExistingPlan: true,
      linkedInPlan: "SchedulerExpansion",
      downgradable: false,
      assignable: false,
      additional_social_accounts: true
    }
  },
  {
    LinkedPlan: "SchedulerScale",
    MonthlySub: "870213",
    AnnualSub: "870214",
    plan: "Extra Scheduler Scale",
    title: "Extra Scheduler Scale",
    passthrough: {
      linkedToExistingPlan: true,
      linkedInPlan: "Scheduler Scale",
      downgradable: false,
      assignable: false,
      additional_social_accounts: true
    }
  }
]

export const whiteLabelPricing = {
  plan: 'PostlyWhiteLabelPremium',
  title: 'Postly White Label Premium',

  MonthlySub: "882102",
  AnnualSub: "877860",
  features: [
    { id: 1, title: 'Make Postly your own' },
    { id: 2, title: 'Domain customization' },
    { id: 3, title: 'Email customization' },
    { id: 4, title: 'Brand customization' },
    { id: 5, title: 'Report customization' },
    { id: 6, title: 'Stripe connect' },
  ],

  // Plans for Paddle sandbox testing
  // MonthlySub: "68426",
  // AnnualSub: "68426",

  one_off: false,
  passthrough: {
    for_postly_white_label: true
  }
}


export const extraAIWriterPricing = {
  plan: 'Unlimited AI',
  title: 'Unlimited AI',
  quantity: 1,
  MonthlySub: "790312",
  AnnualSub: "790313",

  // Plans for testing
  // MonthlySub: "68426",
  // AnnualSub: "68426",

  one_off: false,
  passthrough: {
    for_ai_writer: true
  }
}

export const customTelegramBotPricing = {
  plan: 'Custom Telegram Bot',
  title: 'Custom Telegram Bot',

  MonthlySub: "790556",
  AnnualSub: "790556",
  one_off: true,
  passthrough: {
    for_telegram_bots: true
  }
}

export const postlyLTDUpgradePricing = {
  plan: 'SIX_MONTHS_OLD_FREE_USER_UPGRADE',
  title: 'Postly LTD',

  MonthlySub: "810743",
  AnnualSub: "810743",
  "Workspace": 5,
  "Name": "Postly LTD",
  "Key": "SIX_MONTHS_OLD_FREE_USER_UPGRADE",
  "Users": "Unlimited",
  "SocialAccounts": 30,
  "ScheduledPosts": "Unlimited",
  "Price": {
    "Monthly": "$99/month",
    "Yearly": "$99/year"
  },
  "MetaData": "Unlimited Scheduled Posts Monthly",
  "Upgradable": false,
  "Upgraded": true,
  "Priority": 1,
  "LTD": true,
  "AI_Writer_Words": 4000,
  "AI_Writer_Character_Validity": "1month",
  "ContentLimits": {
    "facebookpage": 24,
    "facebookgroup": 20,
    "facebookgrouplinks": 7,
    "instagram": 20,
    "tiktok": 20,
    "twitter": 300,
    "linked_in_profile": 12,
    "linked_in_page": 18,
    "pinterest": 50,
    "google_my_business": 10,
    "youtube": 10,
    "telegram": 10
  },
  "revision": 1,
}

export const ltdUpgradePricing = {
  plan: 'LTD UPGRADE',
  title: 'LTD UPGRADE',
  quantity: 1,
  MonthlySub: "790756",
  AnnualSub: "790756",
  price: '$999',
  "Workspace": 200,
  "Name": "LTD UPGRADE",
  "Key": "LTD UPGRADE",
  "Users": "Unlimited",
  "SocialAccounts": "Unlimited",
  "ScheduledPosts": "Unlimited",
  "Price": {
    "Monthly": "$999/month",
    "Yearly": "$999/year"
  },
  "MetaData": "Unlimited Scheduled Posts Monthly",
  "Upgradable": false,
  "Upgraded": true,
  "Priority": 1,
  "LTD": true,
  "AI_Writer_Words": 4000,
  "AI_Writer_Character_Validity": "1month",
  "ContentLimits": {
    "facebookpage": 24,
    "facebookgroup": 20,
    "facebookgrouplinks": 7,
    "instagram": 20,
    "tiktok": 20,
    "twitter": 300,
    "linked_in_profile": 12,
    "linked_in_page": 18,
    "pinterest": 50,
    "google_my_business": 10,
    "youtube": 10,
    "telegram": 10
  },
  "revision": 1
}

export const imageEditorBackgroundRemovalCreditPricings = [
  {
    plan: "Background Remover 10 Credits",
    product: "801708",
    price: "$9",
    credits: "10"
  },
  {
    plan: "Background Remover 50 Credits",
    product: "801709",
    price: "$40",
    credits: "50"
  },
  {
    plan: "Background Remover 100 Credits",
    product: "801710",
    price: "$70",
    credits: "100"
  },
  {
    plan: "Background Remover 200 Credits",
    product: "801711",
    price: "$120",
    credits: "200"
  },
  {
    plan: "Background Remover 500 Credits",
    product: "801712",
    price: "$250",
    credits: "500"
  },
  {
    plan: "Background Remover 1000 Credits",
    product: "801713",
    price: "$400",
    credits: "1000"
  }
]

export const AddOnsPlans = [
  // {
  //   id: 0,
  //   title: 'Extra Social Accounts',
  //   plan: "AddOrRemoveSocialAccount",
  //   MonthlySub: "876849",
  //   AnnualSub: "876850",
  //   subTitle: 'prepare social account details',
  //   disableMonthYearSwitch: true,
  //   price: {
  //     Month: 2,
  //     Year: 2
  //   },
  //   disablePrice: true,
  //   currencySign: '$',
  //   url: '/pro',
  //   buttonText: 'Add Extra Social Accounts',
  //   activePlanButtonText: 'Add Social Accounts',
  //   badge: 'Scheduler',
  //   isFeatured: true,
  //   periodical: true,
  //   billingFor: '/social account',
  //   featureTitle: 'Everything in Solo, plus:',
  //   features: [],
  //   one_off: true,
  //   "revision": 1,
  // },
  {
    id: 0,
    title: 'Monetize',
    plan: "Monetization",
    quantity: 1,
    MonthlySub: "",
    AnnualSub: "",
    subTitle: 'Monetize with Postly White-label & Stripe Connect',
    disableMonthYearSwitch: true,
    price: {
      Month: 0,
      Year: 0
    },
    disablePrice: false,
    currencySign: '$',
    url: '/pro',
    buttonText: 'Collect payments',
    activeButtonText: 'Monetization active',
    activePlanButtonText: 'Monetization active',
    badge: 'Scheduler',
    isFeatured: false,
    periodical: true,
    billingFor: '/social account',
    features: [
      { id: 1, title: 'Payment collection with Stripe connect' },
      { id: 2, title: 'Stripe fee: 2.9%' },
      { id: 3, title: 'Platform fee 2.0%' },
    ],
    one_off: false,
    "revision": 3,
  },
  {
    id: 1,
    title: 'White Label',
    plan: "WhiteLabel_v3",
    MonthlySub: "price_1Q9qoqI8CUxOnAX4KbSpNjDn",
    AnnualSub: "price_1Q9qoqI8CUxOnAX44yyzUm6i",
    subTitle: 'White label solution',
    disableMonthYearSwitch: true,
    price: {
      Month: 150,
      Year: 1440
    },
    currencySign: '$',
    url: '/white-label',
    buttonText: 'Customize your brand',
    cancelText: 'Cancel subscription',
    activePlanButtonText: 'Add Social Accounts',
    badge: 'Scheduler',
    isFeatured: false,
    periodical: true,
    features: [
      { id: 1, title: 'Domain customization' },
      { id: 2, title: 'Brand customization' },
      { id: 3, title: 'Email customization' },
      { id: 4, title: 'Branded PDF Reports' },
      { id: 5, title: 'Stripe connect' },
    ],
    one_off: false,
    highlighted: false,
    "revision": 3,
  },
  {
    id: 1,
    title: 'LTD Extra Code',
    plan: "LTDExtraCode",
    OnetimeSub: "price_1PNZjnI8CUxOnAX49xP72ttt",
    subTitle: `Get extra code for your LTD`,
    disableMonthYearSwitch: true,
    price: {
      Month: 49,
      Year: 49
    },
    currencySign: '$',
    url: '/white-label',
    buttonText: 'Get extra LTD code',
    cancelText: 'Cancel subscription',
    activePlanButtonText: '',
    badge: 'Scheduler',
    isFeatured: false,
    periodical: true,
    hideActiveDisplay: true,
    ltdOnly: true,
    features: [
      { id: 1, title: '5 workspaces' },
      { id: 2, title: '5 users' },
      { id: 3, title: '30 social accounts' },
    ],
    one_off: true,
    highlighted: false,
    "revision": 3,
  },
  // {
  //   id: 4,
  //   title: 'Unlimited Postly AI',
  //   plan: "PostlyAI_v3",
  //   MonthlySub: "price_1QHjc8I8CUxOnAX4jsSf9HzM",
  //   AnnualSub: "price_1QHjc8I8CUxOnAX41hPxLLRW",
  //   subTitle: 'Store media content',
  //   // disableMonthYearSwitch: true,
  //   price: {
  //     Month: 30,
  //     Year: 288
  //   },
  //   currencySign: '$',
  //   url: '/postly-cloud',
  //   buttonText: 'Do more with unlimited AI',
  //   cancelText: 'Cancel subscription',
  //   activePlanButtonText: 'Active',
  //   badge: 'Scheduler',
  //   isFeatured: false,
  //   periodical: true,
  //   features: [
  //     { id: 1, title: 'Unlimited AI credits' },
  //     { id: 2, title: 'Prompt library' },
  //     { id: 3, title: 'Community prompts' },
  //     { id: 4, title: 'Multiple use cases, tones & languages' },
  //     // { id: 5, title: 'Stripe connect' },
  //   ],
  //   one_off: false,
  //   highlighted: false,
  //   "revision": 3,
  // },
  {
    id: 3,
    title: 'Postly Cloud',
    plan: "PostlyCloud_v3",
    MonthlySub: "price_1QHlM8I8CUxOnAX42F9b8TA3",
    AnnualSub: "price_1QHlM8I8CUxOnAX4GQWxMDg6",
    subTitle: 'Store media content',
    // disableMonthYearSwitch: true,
    price: {
      Month: 20,
      Year: 192
    },
    currencySign: '$',
    url: '/postly-cloud',
    buttonText: 'Get more storage',
    cancelText: 'Cancel subscription',
    activePlanButtonText: 'Active',
    badge: 'Scheduler',
    isFeatured: false,
    periodical: true,
    features: [
      { id: 1, title: '100GB storage' },
      // { id: 2, title: 'Brand customization' },
      // { id: 3, title: 'Email customization' },
      // { id: 4, title: 'Branded PDF Reports' },
      // { id: 5, title: 'Stripe connect' },
    ],
    one_off: false,
    highlighted: false,
    "revision": 3,
  },
]

export const WhiteLabelPlan = [
  {
    id: 0,
    title: 'Postly White Label Premium',
    plan: "PostlyWhiteLabelPremium",

    MonthlySub: "882102",
    AnnualSub: "877860",
    subTitle: 'Make Postly your own',
    disableMonthYearSwitch: true,
    price: {
      Month: 100,
      Year: 960
    },
    currencySign: '$',
    url: '/white-label',
    buttonText: 'Upgrade',
    cancelText: 'Cancel subscription',
    activePlanButtonText: 'Add Social Accounts',
    badge: 'Scheduler',
    isFeatured: false,
    periodical: true,
    features: [
      { id: 1, title: 'Domain customization' },
      { id: 2, title: 'Email customization' },
      { id: 3, title: 'Brand customization' },
      { id: 4, title: 'Report customization' },
      { id: 5, title: 'Stripe connect' },
    ],
    one_off: true,
    highlighted: false,
    "revision": 1,
  }
]

export const allPlans = [
  ...pricingDataNew,
  getSchedulerPricingData,
  ...extraSocialAccounts,
  extraAIWriterPricing,
  customTelegramBotPricing,
  postlyLTDUpgradePricing,
  ltdUpgradePricing,
  ...imageEditorBackgroundRemovalCreditPricings
];

