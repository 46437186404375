import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { ImageContainer } from './styles/drag_to_position_image'

const DragDropRenderer = ({ item, index, dragEnd, dragStart, dragEnter }) => {
    const [thumbnail, setThumbNail] = useState('');
    const [activated, setActivated] = useState(false)
    const [backupUrl, setBackupUrl] = useState(null);

    function getVideoCover(file, seekTo = 0.0) {
        // console.log("getting video cover for file: ", file);
        return new Promise((resolve, reject) => {
            // load the file to a video player
            const videoPlayer = document.createElement('video');
            videoPlayer.setAttribute('src', URL.createObjectURL(file));
            videoPlayer.load();
            videoPlayer.addEventListener('error', (ex) => {
                reject("error when loading video file", ex);
            });
            // load metadata of the video to get video duration and dimensions
            videoPlayer.addEventListener('loadedmetadata', () => {
                // seek to user defined timestamp (in seconds) if possible
                if (videoPlayer.duration < seekTo) {
                    reject("video is too short.");
                    return;
                }
                // delay seeking or else 'seeked' event won't fire on Safari
                setTimeout(() => {
                    videoPlayer.currentTime = seekTo;
                }, 200);
                // extract video thumbnail once seeking is complete
                videoPlayer.addEventListener('seeked', () => {
                    // console.log('video is now paused at %ss.', seekTo);
                    // define a canvas to have the same dimension as the video
                    const canvas = document.createElement("canvas");
                    canvas.width = videoPlayer.videoWidth;
                    canvas.height = videoPlayer.videoHeight;
                    // draw the video frame to canvas
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
                    // return the canvas image as a blob
                    resolve(canvas.toDataURL("image/png"));
                });
            });
        });
    }


    const getVideoTb = async () => {
        const thumbnailGotten = await getVideoCover(item.file, 4.0);
        if (thumbnail !== thumbnailGotten) {
            setActivated(true);
            setThumbNail(thumbnailGotten);
            setBackupUrl(null)
        }
    }
    useEffect(() => {
        if (item?.is_video) {
            setBackupUrl(thumbnail);
            getVideoTb()
        }
    }, [item])

    if (backupUrl) {

        return <ImageContainer
            draggable
            bgUrl={item?.is_video ? backupUrl : item?.url}
            onDragStart={(e) => dragStart(e, index)}
            onDragEnter={(e) => dragEnter(e, index)}
            onDragEnd={(e) => dragEnd(e, index)}
            onDragOver={(e) => e.preventDefault()}
        />
    }

    return <ImageContainer
        draggable
        bgUrl={item?.is_video ? thumbnail : item?.url}
        onDragStart={(e) => dragStart(e, index)}
        onDragEnter={(e) => dragEnter(e, index)}
        onDragEnd={(e) => dragEnd(e, index)}
        onDragOver={(e) => e.preventDefault()}
    />
}

export default DragDropRenderer