import APIService from 'http/api_service';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../../constants';
import { useDispatch } from 'react-redux';
import { setLoggedInUser } from 'redux/slices/user_slice';

const VerifyEmail = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [token, setToken] = useState();
    const [verifyingEmail, setVerifyingEmail] = useState(true);
    const [verificationError, setVerificationError] = useState();

    useEffect(() => {
        let params = (new URL(document.location)).searchParams;
        let receivedToken = params.get("token");
        if (receivedToken) {
            setToken(receivedToken);
        }
    }, []);

    useEffect(() => {
        verifyEmail();
    }, [token]);

    const verifyEmail = () => {
        if (token) {
            setVerifyingEmail(true);
            APIService.verifyNewEmailAddress(encodeURIComponent(token), (response, error) => {
                if (error) {
                    setVerifyingEmail(false);
                    setVerificationError(error);
                    return;
                }
                setVerificationError(null);
                let { message, data } = response;
                dispatch(setLoggedInUser({ ...data }));
                toast.success(message, { theme: 'colored' });
                setTimeout(() => {
                    navigate(RoutePaths.DEFAULT);
                    window.location.reload();
                }, 1000);
            });
        }
    }

    return (
        <div>
            {
                verifyingEmail &&
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100vh',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        gap: 10
                    }}>
                    <Spinner animation='border' size={40} />
                    <span>Verifying Change...</span>
                </div>
            }
            {
                verificationError &&
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100vh',
                    justifyContent: 'center',
                    color: 'red'
                }}>
                    <span>{verificationError}</span>
                </div>
            }
        </div>
    );
}
export default VerifyEmail;
