import styled from "styled-components";

export const MainArena = styled.div`
  height:auto;
  width:${(props) => props.mainWidth};
  background-color:${(props) => props.mainBgColor};
  // overflow-y:scroll;
  // overflow-x:hidden;
  margin-bottom:20px;
  padding-left:${(props) => props.padLeft};
  // border: solid #121212 1px;
`;
export const BottomRow = styled.div`
  min-height:60px;
  width:100%;
  padding:0 2%;
  display:flex;
  flex-direction:column;
  justify-content:space-around;
  align-items:center;
  gap:0px;
`;
export const EachLayer = styled.div`
  flex:1;
  height:100%;
  width:100%;
  padding:2%;
  display:flex;
  justify-content:${(props) => props.flexJustify};
  align-items:center;
  gap:10px;
text-align:center;
`;
export const InputContainer = styled.div`
  height:30px;
  width:85%;
  border-radius:5px;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  border-style:solid;
  border-color:grey;
  border-width:1px;
`;
export const SendContainer = styled.div`
  height:30px;
  width:13%;
  border-radius:5px;
  display:flex;
  justify-content:center;
  align-items:center;
  border-style:solid;
  border-color:grey;
  border-width:1px;
  color:${(props) => props.statusTextColor};
  cursor:pointer;
  pointer-events:${(props) => props.sendEvent};
  &:active{
    opacity:0;
  }
`;
export const BeforeInput = styled.div`
  flex:1;
  height:100%;
  width:100%;
  border-radius:5px;
  display:flex;
  justify-content:center;
  align-items:center;
  cursor:pointer;
`;
export const InputBox = styled.input`
  flex:5;
  height:100%;
  width:100%;
  border-radius:5px;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  background-color:transparent;
  padding:5px;
  border-style:solid;
  border-color:transparent;
  outline:none;
  color:${(props) => props.statusTextColor};
`;


export const EachLayerText = styled.span`
 font-size:14px;
 color:grey;
`;
export const ListContainer = styled.div`
  max-height:250px !important;
  width:100%;
  padding:5px;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  gap:5px;
  flex-direction:column;
  overflow-y:scroll;
  overflow:auto;
`;
export const ListItem = styled.div`
  min-height:${(props) => props.listHeight
  };
  width:100%;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  flex-direction:column;
  cursor:pointer;
  padding:10px;
  &:hover{
    background-color:${(props) => props.hoverColor
  };
  }
  &:active{
    opacity:0;
  }
`;
export const SubListItemTop = styled.div`
  height:100%;
  flex:1;
  width:100%;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  gap:5px;
  margin-bottom:5px;
`;
export const SubListItemBottom = styled.div`
  height:100%;
  flex:1;
  width:100%;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  gap:2px;
`;
export const HashText = styled.div`
  font-size:14px;
  color:${(props) => props.statusTextColor};
  font-weight:600;
  display:flex;
  justify-content:center;
  align-items:center;
`;
export const HashTextSecond = styled.div`
  font-size:14px;
  font-weight:400;
  display:flex;
  justify-content:center;
  align-items:center;
  color:grey;
`;
export const HashIcon = styled.div`
  height:auto;
  width:auto;
  display:flex;
  justify-content:center;
  align-items:center;
  margin-left:${(props) => props.leftSide};
`;
