import { useState, useEffect } from 'react';
import io from "socket.io-client";
import { SocketEvents, Strings } from './constants';
import EndPoints from './http/endpoints';
import { useSelector, useDispatch } from 'react-redux';
import {
    isContentPublishingOngoing,
    setContentUploadingProgress
} from './redux/slices/postslice';
import { getLoggedInUser } from './redux/slices/user_slice';

const defaultUrl = EndPoints.SOCKET_BASE;
const defaultOptions = {
    transports: ["websocket"],
    auth: {
        encrypted_token: JSON.parse(localStorage.getItem(Strings.LOGGED_IN_USER))?.e_token
    }
}

let defaultSocketIO;
if (!defaultSocketIO) {
    defaultSocketIO = io(defaultUrl, defaultOptions);
}

const usePubSub = (_url = defaultUrl, _options = defaultOptions) => {

    const loggedInUser = useSelector(getLoggedInUser);
    const [socket] = useState(defaultSocketIO);
    const [pubsubListeners, setPubsubListeners] = useState([]);
    const dispatch = useDispatch();
    const posting = useSelector(isContentPublishingOngoing);
    const [disconnected, setDisconnected] = useState(false);

    const contentUploadListener = () => {
        let contentUploadProgress = localStorage.getItem('content_upload_progress');
        if (contentUploadProgress) {
            let { message, progress } = JSON.parse(contentUploadProgress);
            dispatch(setContentUploadingProgress({
                completed: Math.min(progress, 101),
                message: `${message} ${Math.min(parseInt(`${progress}`), 101)}%`
            }))
            if (progress >= 100) {
                let timeout = setTimeout(() => {
                    dispatch(setContentUploadingProgress(null));
                    localStorage.removeItem("content_upload_progress");
                    clearTimeout(timeout);
                }, 3000);
            }
        }
    }

    const observeLocalStorage = () => {
        window.addEventListener('storage', contentUploadListener);
    }

    const unObserveLocalStorage = () => {
        window.removeEventListener('storage', contentUploadListener);
    }

    const connectWithSingularity = (listener) => {
        if (!socket?.hasListeners(listener.event)) {
            socket?.on(listener.event, listener.callback);
        }
    }

    const connectListeners = () => {
        socket?.offAny();
        pubsubListeners.forEach((listener) => connectWithSingularity(listener));
    }

    useEffect(() => {
        observeLocalStorage();
        connectListeners();
        return () => {
            socket?.offAny();
            unObserveLocalStorage();
        }
    }, [pubsubListeners]);

    useEffect(() => {
        if (!socket?.hasListeners("connect")) {
            socket?.on('connect', () => {
                if (disconnected && !posting) {
                    window.location.reload()
                } else {
                    console.log('===Connected===');
                    connectListeners();
                }
            });
        }
        if (!socket?.hasListeners("disconnect")) {
            socket?.on('disconnect', () => {
                setDisconnected(true);
                console.log('===Disconnected===');
                socket?.offAny();
            });
        }
        if (!socket?.hasListeners("reconnect")) {
            socket?.on('reconnect', () => {
                console.log("===Reconnected===");
                connectListeners();
            });
        }
    }, [socket]);

    const addPubSubEventListener = (event, callback, more) => {
        let draftEventCopies = [];
        let listenersCopy = [...pubsubListeners];
        let existingEvent = pubsubListeners.find(x => x?.event === event);
        if (!existingEvent) {
            draftEventCopies.push({ event, callback });
            if (more) {
                for (let item of more) {
                    let existingItem = pubsubListeners.find(x => x?.event === item.event);
                    if (!existingItem) {
                        draftEventCopies.push(item);
                    }
                }
            }
            setPubsubListeners([...pubsubListeners, ...draftEventCopies]);
        }
    }

    const removePubSubEventListener = (event, callback, more) => {
        socket?.off(event, callback);
        let listenersCopy = [...pubsubListeners];
        let filtered = listenersCopy.filter((listener) => listener.event !== event);
        if (more) {
            for (let item of more) {
                socket?.off(item.event, item.callback);
                filtered = filtered.filter((listener) => listener.event !== item.event);
            }
        }
        setPubsubListeners(filtered);
    }

    const putUserOnlineViaPubSub = () => {
        if (loggedInUser) {
            socket?.emit(SocketEvents.AUTHENTICATION_STATE_CHANGED_EVENT, {
                [Strings.API_KEY]: loggedInUser['api_key'],
                [Strings.LOGGED_IN]: true
            });
        }
    }

    return {
        socket,
        addPubSubEventListener: addPubSubEventListener,
        removePubSubEventListener: removePubSubEventListener,
        putUserOnlineViaPubSub: putUserOnlineViaPubSub
    }

}

export default usePubSub;
