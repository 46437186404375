import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import APIService from 'http/api_service';
import { RoutePaths } from '../../constants';
import { useNavigate } from 'react-router-dom';

const PasswordResetForm = ({ hasLabel }) => {

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });
  const [processing, setProcessing] = useState(false);
  const [token, setToken] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    let params = (new URL(document.location)).searchParams;
    let receivedToken = params.get("token");
    if (receivedToken) {
      setToken(receivedToken);
    }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    if (formData.password.length && formData.confirmPassword.length) {
      if (formData.password !== formData.confirmPassword) {
        toast.error("Sorry, passwords didn't match", {
          theme: 'colored'
        });
        return;
      }
    }
    setProcessing(true);
    APIService.finalizePasswordReset(token, formData.password, (response, error) => {
      if (error) {
        setProcessing(false);
        toast.error(error);
        return;
      }
      let message = response['message'];
      toast.success(message, {
        theme: 'colored'
      });
      setTimeout(() => {
        setProcessing(false);
        navigate(RoutePaths.WORKSPACE);
      }, 2500);
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>New Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'New Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Confirm Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirm Password' : ''}
          value={formData.confirmPassword}
          name="confirmPassword"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Button
        type="submit"
        className="w-100"
        disabled={!formData.password || !formData.confirmPassword || processing}
      >
        {processing ? "Please wait..." : "Set password"}
      </Button>
    </Form>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordResetForm;
