import Divider from "components/common/Divider";
import Flex from "components/common/Flex";
import moment from "moment";
import React, { useState } from "react";
import DatePicker from 'react-datepicker';
import SpecificTimeAndExpirationView from "./SpecificTimeAndExpirationView";
import WhenToBeginRepeatInfoBar from "./WhenToBeginRepeatInfoBar";
import { useSelector } from 'react-redux';
import { getTimeZoneToPostContent } from "../../../../../redux/slices/postslice";
import { createDateWithTimeZone } from '../../../../../utils/time';

const YearlyRepeatOptionView = ({
    presetOptions,
    startFrom,
    commitSelectedRecurringSettings,
    commitStopTime,
    commitSpecificTime,
    ...others
}) => {

    const timeZone = useSelector(getTimeZoneToPostContent);
    const [selectedSpecificDate, setSelectedSpecificDate] = useState(presetOptions['specific_date_to_repeat']);

    return (
        <Flex direction={'column'}>
            {
                selectedSpecificDate &&
                <p className="pb-1"><b>Repeat Every:</b> {moment(selectedSpecificDate).format("Do MMMM")} of the Year</p>
            }
            <span
                style={{
                    fontSize: 14
                }}>Select a Date &amp; Month to repeat every year
            </span>
            <DatePicker
                onChange={(e) => {
                    setSelectedSpecificDate(new Date(e));
                    commitSelectedRecurringSettings({
                        specific_date_to_repeat: createDateWithTimeZone(e, timeZone, true).toString(),
                        specific_time_to_repeat: presetOptions?.specific_time_to_repeat ?? new Date(),
                        stop_time: presetOptions?.stop_time ?? "never"
                    })
                }}
                dateFormat="MMM dd"
                selected={new Date(selectedSpecificDate ?? new Date())}
                startDate={new Date()}
                minDate={new Date()}
                placeholderText={'Select'}
                className='form-control mb-4'
            />
            <SpecificTimeAndExpirationView
                presetOptions={presetOptions}
                commitStopTime={commitStopTime}
                commitSpecificTime={commitSpecificTime}
            />
            {
                selectedSpecificDate &&
                <WhenToBeginRepeatInfoBar
                    startFrom={startFrom}
                    feedBackData={{
                        specific_date_to_repeat: selectedSpecificDate,
                        specific_time_to_repeat: presetOptions?.specific_time_to_repeat ?? new Date(),
                        stop_time: presetOptions?.stop_time ?? "never"
                    }}
                    {...others}
                />
            }
            <Divider />
        </Flex>
    )
}
export default YearlyRepeatOptionView;