import APIService from "http/api_service";
import React, { useEffect, useState } from "react";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { useSelector } from "react-redux";
import Flex from "components/common/Flex";
import { Button, Card, Spinner, Tab, Tabs } from "react-bootstrap";
import FreePageHeader from "components/common/FreePageHeader";
import Builder from "./campaign_builder/Builder";
import Generator from "./review_link_generator/Generator";
import BulkGenerator from "./review_link_generator/BulkGenerator";
import { findPlatformByIdentifier } from "components/app/platforms";
import RichInfoBar from "components/common/RichInfoBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeader from "components/common/PageHeader";
import GoogleBusinessAccount from "components/app/platforms/google_my_business/gmb_account";
import VerifiedBadge from "components/common/VerifiedBadge";
import { svgWhiteList } from "utils/gen";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "constants";
import { toast } from "react-toastify";

const ReviewLinkGenerator = () => {
  const navigate = useNavigate();
  const [workSpaceOwner, setWorkspaceOwner] = useState();
  const workSpace = useSelector(getActiveWorkSpace);
  const [tab, setTab] = useState(1);
  const [fetchingAccounts, setFetchingAccounts] = useState(true);
  const [fetchError, setFetchError] = useState();
  const platform = findPlatformByIdentifier("google_my_business");
  const [connectedAccounts, setConnectedAccounts] = useState();
  const [displayNameAsAvatar, setDisplayNameAsAvatar] = useState(false);

  const fetchWorkspaceOwner = () => {
    APIService.fetchWorkSpaceOwner(workSpace._id, (response, error) => {
      if (response) {
        let { data } = response;
        setWorkspaceOwner(data);
      }
    });
  };

  const TabComponent = () => {
    switch (tab) {
      case 1:
        return <Generator />;
      // return <Shortener tabRef={tabRef} setTab={setTab} />;
      case 2:
        return <BulkGenerator />;
      default:
        return;
    }
  };

  const fetchConnectedAccounts = () => {
    console.log("fecthing conncet acct...");
    setFetchingAccounts(true);
    setFetchError(null);
    APIService.fetchConnectedAccounts(
      workSpace["_id"],
      platform.target ?? platform.identifier,
      (response, error) => {
        setFetchingAccounts(false);
        if (error) {
          setFetchError(error);
          return;
        }
        let { data } = response;
        console.log("🚀 ~ APIService.fetchConnectedAccounts ~ data:", data);
        setFetchError(null);
        if (data && data.length > 0) {
          data = data.map((account, index) => {
            let { user_id, user_name, user_photo, ...platform } =
              findPlatformByIdentifier(account.target);
            let newAccount = { ...account, ...platform };
            return newAccount;
          });
        }
        setConnectedAccounts(data);
        // dispatch(setRefreshConnectedFeed(false));
      }
    );
  };

  const fetchBusinessLocations = async () => {
    APIService.fetchPagesAndGroups(
      workSpace,
      platform.identifier,
      platform.user_id,
      (response, error) => {
        // setFetching(false);
        if (error) {
          console.log("🚀 ~ fetchBusinessLocations ~ error:", error)
          // toast.error(error);
          return;
        }
        let responseData = response["data"];
        console.log("🚀 ~ fetchLocations ~ responseData:", responseData);
      }
    );
  };

  useEffect(() => {
    // fetchBusinessLocations()
    fetchWorkspaceOwner();
    fetchConnectedAccounts();
  }, []);

  console.log(
    "🚀 ~ ReviewLinkGenerator ~ connectedAccounts:",
    connectedAccounts
  );

  return (
    <>
      {!workSpaceOwner && (
        <Flex
          alignItems={"center"}
          style={{
            height: "100vh",
          }}
          justifyContent={"center"}
        >
          <Spinner animation="border" />
        </Flex>
      )}

      {workSpaceOwner && (
        <>
          <div>
            {!platform ? (
              <span>Nothing here</span>
            ) : (
              <Flex direction={"column"} justifyContent={"center"}>
                {/* <ConfirmModal
                            open={popupBlocked}
                            title={"Popup Blocker Detected"}
                            message={`Your browser is blocking our pop-ups. As a result you will not be able to connect your <b>${platform.name}</b> account.\n\n${popUpUnblockHint ? popUpUnblockHint : 'Kindly disable pop-up blocking'} and try again.\n\nYou can re-enable pop-up blocking after connecting ${platform.name} and other relevant platforms.\n\nThank you`}
                            confirmText={"OK"}
                            cancelText={"CLOSE"}
                            onConfirm={() => {
                                setPopupBlocked(false);
                                setProcessing(false);
                            }}
                            onCancel={() => {
                                setPopupBlocked(false);
                                setProcessing(false);
                            }}
                        /> */}
                <PageHeader
                  title={
                    <Flex alignItems={"center"} className={"gap-2"}>
                      {/* <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    color: platform.color,
                                    alignSelf: 'center',
                                    background: '#dde7f5',
                                    marginRight: 5,
                                    width: 40,
                                    height: 40,
                                    borderRadius: 100,
                                    justifyContent: 'center'
                                }}
                                > */}
                      {platform.svg ? (
                        <img
                          src={platform.svg}
                          alt={platform.name}
                          style={{
                            width: 30,
                            height: 30,
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={platform.icon}
                          style={{
                            color: platform.color,
                            fontSize: 30,
                          }}
                        />
                      )}
                      {/* </div> */}
                      {/* <h5>{platform.name}</h5> */}
                    </Flex>
                  }
                  titleTag="h5"
                  className="mb-3"
                >
                  <p className="fs--1 mt-1">
                    {(connectedAccounts ?? []).length > 0
                      ? "Your connected accounts"
                      : "Connect your account(s) to start posting"}
                  </p>
                </PageHeader>
                {/* {handleConnectionViewRendering()} */}
                {/* Adding connection guides here... */}
                <br />
                <br />
                {/* {displayConnectionGuides()} */}

                {fetchError && (
                  <RichInfoBar
                    title={"Oh snap!"}
                    message={fetchError}
                    variant={"danger"}
                    action={fetchConnectedAccounts}
                    actionMessage={"Try Again"}
                  />
                )}

                <Card
                  style={{
                    width: "100%",
                    height: "70vh",
                    // backgroundColor: "red",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <GoogleBusinessAccount/> */}
                  <Flex style={{width: "100%", gap: "20px",}} justifyContent={"center"} alignItems={"center"} wrap={"wrap"}>
                    {/* {connectedAccounts?.length > 0 && (
                        {connectedAccounts?.map((account, index) => {

                        })}
                      )} */}
                    {connectedAccounts?.map((account, index) => {
                      return (
                        <Card
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            position: "relative",
                            justifyContent: "center",
                            minHeight: 200,
                            maxWidth: 200,
                            // minWidth: 200,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate(`${RoutePaths.GOOGLE_MY_BUSINESS_LOCATIONS}/${account.user_id}`)
                          }}
                        >
                          {svgWhiteList(account) ? (
                            <img
                              src={account?.svg}
                              alt=""
                              style={{
                                width: 30,
                                height: 30,
                                position: "absolute",
                                top: 15,
                                left: 15,
                              }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={account.icon}
                              style={{
                                color: account.color,
                                fontSize: 30,
                                position: "absolute",
                                top: 15,
                                left: 15,
                              }}
                            />
                          )}
                          {/* <img
                            src={VerifiedBadge}
                            alt=""
                            style={{
                              position: "absolute",
                              right: 10,
                              top: 10,
                              width: 25,
                              height: 25,
                            }}
                          /> */}
                          <div
                            className="rounded-circle"
                            style={{
                              width: 60,
                              height: 60,
                              borderWidth: 2,
                              borderColor: "#999",
                              borderRadius: 200,
                            }}
                          >
                            <div
                              style={{
                                width: 60,
                                height: 60,
                                backgroundColor: "#EEEEEE",
                                borderRadius: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {displayNameAsAvatar ? (
                                <span
                                  style={{
                                    fontSize: 40,
                                    color: "black",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    alignSelf: "center",
                                  }}
                                >
                                  {account.user_name
                                    .charAt(0)
                                    .toString()
                                    .toUpperCase()}
                                </span>
                              ) : account.user_photo ? (
                                <img
                                  src={account.user_photo}
                                  alt={account.user_name}
                                  onError={() => {
                                    setDisplayNameAsAvatar(true);
                                  }}
                                  style={{
                                    width: 60,
                                    height: 60,
                                    borderRadius: 100,
                                  }}
                                />
                              ) : (
                                <span
                                  style={{
                                    fontSize: 40,
                                    color: "black",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    alignSelf: "center",
                                  }}
                                >
                                  {account.user_name
                                    .charAt(0)
                                    .toString()
                                    .toUpperCase()}
                                </span>
                              )}
                            </div>
                          </div>
                          <span
                            style={{
                              fontSize: 16,
                              maxLines: 1,
                              textOverflow: "ellipsis",
                              padding: 10,
                              fontWeight: "bold",
                            }}
                            textAlign={"center"}
                          >
                            <span>{`${account.user_name ?? ""}`}</span>
                          </span>
                          <Button
                            style={{ fontSize: "12px", margin: "0px 5px" }}
                            onClick={() => {
                              navigate(`${RoutePaths.GOOGLE_MY_BUSINESS_LOCATIONS}/${account.user_id}`)
                            }}
                          >
                            View business locations
                          </Button>
                        </Card>
                      );
                    })}
                  </Flex>
                  {/* <Card
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      position: "relative",
                      justifyContent: "center",
                      height: 200,
                      width: 200,
                      cursor: "pointer",
                      marginTop: "10px",
                      padding: "0px 10px",
                    }}
                  >
                    <Card.Text>
                      Add another Google my business account
                    </Card.Text>
                    <FontAwesomeIcon icon={platform.icon} />
                  </Card> */}
                  <Button
                    style={{ fontSize: "12px", margin: "50px 0px" }}
                    onClick={() => {
                      navigate(`${RoutePaths.PLATFORMS}/google_my_business`);
                      // navigate(`${RoutePaths.google_my_business}`);
                    }}
                  >
                    Add {connectedAccounts?.length > 0 ? "another" : "a"} Google My Business account
                  </Button>
                </Card>
              </Flex>
            )}
          </div>
        </>
        // <Flex direction={"column"}>
        //   <FreePageHeader titleTag="h5" className="mb-1">
        //     <Flex style={{ width: "100%" }}>
        //       <div
        //         style={{
        //           width: "100%",
        //           display: "flex",
        //           justifyContent: "flex-start",
        //           alignItems: "center",
        //         }}
        //       >
        //         <h5>Google LinkPro</h5>
        //       </div>
        //     </Flex>
        //     <p className="fs--1 mt-1">
        //       Use our free tool to generate your unique Google review link, then
        //       share the short URL or QR code with your customers to grow your
        //       Google reviews!
        //     </p>
        //     <Tabs
        //       activeKey={tab}
        //       id="uncontrolled-tab-example"
        //       transition={true}
        //       className="mb-3"
        //       onSelect={(tab) => {
        //         setTab(Number(tab))
        //       }}
        //     >
        //       <Tab eventKey={1} title="Review Link Generator" />
        //       {/* <Tab
        //         eventKey={2}
        //         title="Bulk link generation"
        //       /> */}
        //     </Tabs>
        //   </FreePageHeader>
        //   <Card
        //     className="mt-2"
        //     style={{
        //       minHeight: "80vh",
        //     }}
        //   >
        //     <div>{TabComponent()}</div>
        //   </Card>
        // </Flex>
      )}
    </>
  );
};

export default ReviewLinkGenerator;
