import { findPlatformByIdentifier } from 'components/app/platforms';
import Flex from 'components/common/Flex';
import APIService from 'http/api_service';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAccountUserId, setPageId } from 'redux/slices/analytics_slice';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { PlatformIdentifier, RoutePaths } from '../../../../../constants';
import AccountHeader from '../../AccountHeader';
import YoutubeAnalyticsPage from './youtube_page';
import useProtectedRoute from 'hooks/useProtectedRoute';

const YoutubeAnalytics = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userId = useSelector(getAccountUserId);
    const workSpace = useSelector(getActiveWorkSpace);
    const platform = findPlatformByIdentifier(PlatformIdentifier.YOUTUBE);
    const [fetchingAccounts, setFetchingAccounts] = useState(false);
    const [connectedAccounts, setConnectedAccounts] = useState([]);
    const { status } = useProtectedRoute(['free'], `${RoutePaths.BILLING_STRIPE}/#schedulerSection001`)

    const fetchConnectedAccounts = () => {
        setFetchingAccounts(true);
        APIService.fetchConnectedAccounts(workSpace['_id'], platform.identifier, (response, error) => {
            setFetchingAccounts(false);
            if (error) {
                toast.error(error);
                return;
            }
            let { data } = response;
            if (!data.length) {
                toast.error(`Please connect ${platform.identifier} account to view analytics`, { theme: 'colored' });
                return;
            }
            if (data && data.length > 0) {
                let refinedData = data.filter((x) => x?.user_id === userId);
                refinedData = refinedData.map((account, index) => {
                    let { user_id, user_name, user_photo, ...platform } = findPlatformByIdentifier(account.target);
                    let newAccount = { ...account, ...platform };
                    return newAccount;
                });
                setConnectedAccounts(refinedData);
            }
        })
    }

    useEffect(() => {
        if (platform) {
            fetchConnectedAccounts();
            dispatch(setPageId(null));
        }
    }, [userId]);

    return (
        <>
            <AccountHeader platform={platform} />
            {fetchingAccounts &&
                <Flex
                    alignItems={'center'}
                    justifyContent={'center'}
                    className={'vh-100'}
                >
                    <Spinner animation='border' size={40} />
                </Flex>}
            {connectedAccounts && !fetchingAccounts && connectedAccounts.map((account, index) => {
                return (<div
                    key={account._id}
                    id={account._id}>
                    <YoutubeAnalyticsPage
                        account={account}
                        platform={platform}
                    />
                </div>)
            })}
        </>
    )
}

export default YoutubeAnalytics;