import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { WhiteLabelWizardContext } from 'context/Context';

const WhiteLabelWizardProvider = ({ children }) => {

  const [user, setUser] = useState({});
  const [step, setStep] = useState(1);

  const value = { user, setUser, step, setStep };
  return (
    <WhiteLabelWizardContext.Provider value={value}>
      {children}
    </WhiteLabelWizardContext.Provider>
  );

};

WhiteLabelWizardProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default WhiteLabelWizardProvider;
