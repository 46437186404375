import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmModal from "components/common/ConfirmModal";
import Flex from "components/common/Flex";
import AppContext from "context/Context";
import useProtectedRoute from "hooks/useProtectedRoute";
import APIService from "http/api_service";
import usePubSub from "pubsub";
import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setCollectMetric } from "redux/slices/analytics_slice";
import { setPopupUpgradeAlert } from "redux/slices/alertSlice";
import {
  getOpenFeedSettings,
  getRSSFeedTriggered,
  getRSSFeedUserId,
  setFeedSelectedPlatforms,
  setOpenFeedSettings,
  setTriggerFeedPost
} from "redux/slices/postslice";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { isFreeWorkspace, isWorkspaceUnderAppSumo, isWorkspaceUnderSchedulerPro, workspaceIsOfAnyOfThePlans } from "utils/workspace_utils";
import { MetricsProps, PlatformIdentifier, RoutePaths } from "../../../constants";
import { findPlatformByIdentifier } from "../platforms";
import AddMoreFeeds from "./AddMoreFeeds";
import { feedContentData } from "./data";
import FeedContent from "./feed_content";
import FeedSettings from "./feed_settings";
import RssSelectFeed from "./rss_platform_select";
import { FeedListDisplay } from "./styles/feed";

const RSSFeed = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { config } = useContext(AppContext);
  const { status } = useProtectedRoute(['free'], `${RoutePaths.BILLING_STRIPE}/#schedulerSection001`)
  const workSpace = useSelector(getActiveWorkSpace);
  const platform = findPlatformByIdentifier(PlatformIdentifier.RSS_FEED);
  const feedUserId = useSelector(getRSSFeedUserId);
  const feedTriggered = useSelector(getRSSFeedTriggered);
  const openFeedSettings = useSelector(getOpenFeedSettings);
  const [fetchingFeeds, setFetchingFeeds] = useState(false);
  const [connectedFeeds, setConnectedFeeds] = useState([]);
  const [loadFeed, setLoadFeed] = useState(false);
  const [feedContent, setFeedContent] = useState([]);
  const [showFeedSettings, setShowFeedSettings] = useState(false);
  const [feedData, setFeedData] = useState([]);
  const [deleteFeed, setDeleteFeed] = useState(false);
  const [promptFeedDelete, setPromptFeedDelete] = useState(false);
  const [feedId, setFeedId] = useState();
  const [feedSelected, setFeedSelected] = useState(false);
  const [feedHasImage, setFeedHasImage] = useState(false);
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const [enableDelete, setEnableDelete] = useState(false);
  const [feedsToDelete, setFeedsToDelete] = useState([]);
  const userHasFreeWorkspace = isFreeWorkspace(workSpace);
  const [publishingFeedId, setPublishingFeedId] = useState([]);
  const [publishError, setPublishError] = useState(false);
  const { addPubSubEventListener, removePubSubEventListener } = usePubSub();

  useEffect(() => {
    if (userHasFreeWorkspace) {
      // let metricInitializer = {
      //   action: MetricsProps.RSS_FEED,
      //   detailedAction: `Upgrade notice: Attempted to access RSS feed feature on a free workspace`,
      //   timestamp: new Date().toISOString(),
      //   route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
      // }
      // dispatch(setCollectMetric(metricInitializer))
      
      dispatch(setPopupUpgradeAlert(true));
      return;
    }
    if (platform) {
      fetchConnectedFeeds();
    }
  }, []);

  // useEffect(() => {
  //   const userIsOnSuggestedPlan = workspaceIsOfAnyOfThePlans(['Scheduler Premium', 'Scheduler Growth', 'Agency', 'Agency Plus', 'Scheduler Enterprise', 'Scheduler Expansion', 'Scheduler Scale', 'LTD UPGRADE', 'Premium', 'Growth', 'Enterprise', 'Expansion', 'Scale', 'AgencyTwo', 'Agency Plus'], workSpace);
  //   if (!userIsOnSuggestedPlan) {
  //     if (isWorkspaceUnderAppSumo(workSpace) || isWorkspaceUnderSchedulerPro(workSpace)) {
  //       let metricInitializer = {
  //         action: MetricsProps.RSS_FEED,
  //         detailedAction: `Upgrade notice: Attempted to access RSS feed feature`,
  //         timestamp: new Date().toISOString(),
  //         route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
  //       }
  //       dispatch(setCollectMetric(metricInitializer));
  //       dispatch(setPopupUpgradeAlert(true));
  //       navigate(-1);
  //       return;
  //   }
  //   }
  // }, [])

  const fetchConnectedFeeds = () => {
    setFetchingFeeds(true);
    APIService.fetchConnectedAccounts(workSpace["_id"], platform.identifier, (response, error) => {
      if (error) {
        setFetchingFeeds(false);
        return;
      }
      setFetchingFeeds(false);
      let { data } = response ?? [];
      setConnectedFeeds(data);
    }
    );
  };

  useEffect(() => {
    APIService.fetchPublishingFeed(workSpace._id, (response, error) => {
      if (error) {
        return;
      }
      const publishingFeed = response?.data ?? [];
      if (publishingFeed.length > 0) {
        let feedId = publishingFeed[0]?._id;
        APIService.fetchPublishingFeedStatus(workSpace._id, feedId, (response, error) => {
          if (error) {
            return;
          }
        })
      } else {
        return;
        // postFeedContent(); 
      }
    })
  }, []);

  // useEffect(() => {
  //   APIService.updateFeedContent(workSpace._id, (response, error) => { 
  //     if(error) { 
  //       console.log(error); 
  //     }
  //   })
  // }, []);

  useEffect(() => {
    const publishFeed = (data) => {
      let { feed, message } = data;
      setPublishingFeedId(feed ?? []);
      if (message.includes("Error publishing feed content")) {
        setPublishError(true);
      }
    };

    addPubSubEventListener(`${workSpace?._id}_publish_feed`, publishFeed);
    return () => {
      removePubSubEventListener(`${workSpace?._id}_publish_feed`, publishFeed);
    };
  }, []);

  useEffect(() => {
    if (openFeedSettings) {
      setShowFeedSettings(true);
    }
  }, [openFeedSettings]);

  useEffect(() => {
    if (feedsToDelete?.length > 0) {
      setEnableDelete(true);
    } else {
      setEnableDelete(false);
    }
  }, [feedsToDelete]);

  useEffect(() => {
    if (connectedFeeds?.length) {
      let feedPlatform = connectedFeeds?.filter((x) => x.user_id === feedUserId);
      let _feedId = feedPlatform[0]?._id;
      setFeedId(_feedId);
      if (feedPlatform) {
        setFeedData(feedPlatform);
      }
    }
  }, [connectedFeeds, feedUserId]);

  useEffect(() => {
    if (feedUserId) {
      setFeedSelected(true);
      fetchSelectedFeedContent();
    }
    if (!connectedFeeds?.length) {
      setFeedContent([]);
    }
  }, [feedUserId, connectedFeeds]);

  useEffect(() => {
    if (connectedFeeds?.length) {
      for (let feed of connectedFeeds) {
        const metadata = feed?.metadata;
        const { post_action } = metadata;
        if (post_action === "auto_post" || post_action === "auto_schedule") {
          dispatch(setTriggerFeedPost(true));
          return;
        } else {
          dispatch(setTriggerFeedPost(false));
          return;
        }
      }
    }
  }, [connectedFeeds]);

  // const postFeedContent = () => { 
  //   APIService.postRSSFeedContent(workSpace._id, null, (response, error) => {
  //     if (error) {
  //       return; 
  //     }
  //   });
  // }

  const fetchSelectedFeedContent = () => {
    setLoadFeed(true);
    APIService.fetchRSSFeedContent(workSpace["_id"], feedUserId, (response, error) => {
      if (error) {
        setLoadFeed(false);
        return;
      }
      setLoadFeed(false);
      const data = response?.data ?? [];
      if (data?.length < 1) {
        toast.success("Feed content unavailable", { theme: "colored" });
        return;
      }
      setFeedContent(data);
    }
    );
  };

  const removeExistingFeed = () => {
    setDeleteFeed(true);
    APIService.deleteRSSFeed(workSpace["_id"], feedsToDelete,
      (response, error) => {
        if (error) {
          setDeleteFeed(false);
          toast.error(error, { theme: "colored" });
          return;
        }
        toast.success(response?.message, { theme: "colored" });
        setDeleteFeed(false);
        window.location.reload();
      }
    );
  };

  if (showFeedSettings) {
    return (
      <FeedSettings
        showSettings={showFeedSettings}
        toggleSettings={setShowFeedSettings}
        hideSettings={() => {
          setShowFeedSettings(false);
          dispatch(setOpenFeedSettings(false));
          dispatch(setFeedSelectedPlatforms([]));
        }}
        feedData={feedData[0]}
        updateFeedData={setFeedData}
        refreshFeed={setFetchingFeeds}
        includesImage={feedHasImage}
      />
    );
  }

  const confirmFeedDelete = (
    <Modal
      show={promptFeedDelete}
      onHide={() => {
        setPromptFeedDelete(false);
      }}
      backdrop="static"
    >
      <Modal.Body>
        <p>
          <b>Are you sure you want to delete the selected feed(s)?</b>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setPromptFeedDelete(false);
          }}
        >
          Exit
        </Button>
        <Button variant="danger" onClick={removeExistingFeed}>
          {deleteFeed ? (
            <Spinner
              as={"span"}
              role="status"
              animation="border"
              size="sm"
              aria-hidden="true"
            />
          ) : (
            "Delete"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  if (promptFeedDelete) {
    return confirmFeedDelete;
  }

  const connectMoreFeeds = () =>
    navigate(`${RoutePaths.WORKSPACE}/platforms/rss_feed`);

  const displayFeedMenu = () => {
    let feedMenu = connectedFeeds?.length > 0 && (
      <div
        style={{
          flex: 1,
          position: 'relative'
        }}
      >
        <FeedListDisplay config={config.isDark}>
          <AddMoreFeeds connectFeedUrl={connectMoreFeeds} />
          {connectedFeeds.map((feed) => {
            return (
              <RssSelectFeed
                key={feed.id}
                feed={feed}
                promptDelete={setPromptFeedDelete}
                deleteFeed={deleteFeed}
                feedSelected={feedSelected}
                setFeedsToDelete={setFeedsToDelete}
                feedsToDelete={feedsToDelete}
                feeds={connectedFeeds}
                publishingFeed={publishingFeedId}
                publishError={publishError}
              />
            );
          })}
        </FeedListDisplay>
      </div>
    );
    return feedMenu;
  };

  return (
    <>
      {userHasFreeWorkspace ? (
        <ConfirmModal
          open={openUpgradeDialog}
          title={"Upgrade Account"}
          message={
            "This feature requires an account upgrade. Kindly upgrade to a premium account to continue"
          }
          confirmText={"UPGRADE"}
          cancelText={"CANCEL"}
          onCancel={() => {
            setOpenUpgradeDialog(false);
          }}
          onConfirm={() => {
            setOpenUpgradeDialog(false);
            navigate(`${RoutePaths.BILLING_STRIPE}`);
          }}
        />
      ) : (
        <div>
          {fetchingFeeds && !openUpgradeDialog && (
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              className={"vh-100"}
              style={{ width: "100vh" }}
            >
              <Spinner animation="border" size={40} />
            </Flex>
          )}
          {!fetchingFeeds && connectedFeeds?.length < 1 ? (
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              direction={"column"}
              className={"vh-100"}
            >
              <h5>
                Feed is empty, head over to platform's page to connect feed
              </h5>
              <Button
                className="px-5 py-2 mt-2"
                variant="primary"
                onClick={connectMoreFeeds}
              >
                Create feed
              </Button>
            </Flex>
          ) : (
            <Flex justifyContent={"space-between"} gap={1}>
              {displayFeedMenu()}
              <Row
                style={{
                  width: `calc(100% - 18rem)`,
                }}
              >
                {loadFeed ? (
                  <Flex
                    alignItems={"center"}
                    justifyContent={"center"}
                    className={"vh-100"}
                    style={{ width: "100vh" }}
                  >
                    <Spinner animation="border" size={40} />
                  </Flex>
                ) : (
                  <>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {enableDelete && (
                        <div
                          style={{
                            position: "fixed",
                            bottom: "50px",
                            zIndex: "10",
                          }}
                        >
                          <Alert variant="danger" className="p-3 mb-0">
                            <Flex>
                              <FontAwesomeIcon
                                icon="danger-circle"
                                className="fs-2"
                              />
                              <div className="ms-3 flex-1">
                                <Flex
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                  gap={10}
                                >
                                  Delete selected feed(s)
                                  <Button
                                    variant={"danger"}
                                    onClick={() => setPromptFeedDelete(true)}
                                    style={{
                                      color: "white",
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </Flex>
                              </div>
                            </Flex>
                          </Alert>
                        </div>
                      )}
                    </div>
                    {feedContent?.length > 0 &&
                      feedContent?.map((item) => (
                        <Col lg={4} md={6} sm={6} className="mb-4">
                          <FeedContent
                            feed={item}
                            data={feedContentData}
                            setFeedHasImage={setFeedHasImage}
                          />
                        </Col>
                      ))}
                  </>
                )}
              </Row>
            </Flex>
          )}
        </div>
      )}
    </>
  );
};

export default RSSFeed;
