import Flex from "components/common/Flex";
import APIService from "http/api_service";
import React, { useEffect, useState } from "react";
import { Button, Card, Modal, Spinner } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { BiSolidError } from "react-icons/bi";
import { FaLink, FaRegCalendar } from "react-icons/fa";
import { MdSubdirectoryArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getQrcodeArray,
  setQrcodeArray,
} from "redux/slices/links_shortener_slice";

const CodeLists = () => {
  const qrcodeList = useSelector(getQrcodeArray);
  console.log("🚀 ~ CodeLists ~ qrcodeList:", qrcodeList);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedCode, setSelectedCode] = useState(null);
  const [isError, setIsError] = useState(false);

  const getQrCodesHandler = async () => {
    APIService.getQrcodes((response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        setIsError(true)
        return;
      }
      let { data, message, status } = response;
      console.log("🚀 ~ APIService.getQrcodes ~ data:", data)
      if (status === false) {
        dispatch(setQrcodeArray([]));
        return 
      }
      toast.success(message, { theme: 'colored' });
      dispatch(setQrcodeArray(data));
    });
  };

  useEffect(() => {
    getQrCodesHandler();
  }, []);

  const downloadQRCode = async (imageUrl) => {
    const fileName = `${selectedCode?.title}.png`;
    // fetch(imageUrl)
    //   .then(response => response.blob())
    //   .then(blob => {
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = fileName;
    //     document.body.appendChild(a);
    //     a.click();
    //     window.URL.revokeObjectURL(url);
    //     document.body.removeChild(a);
    //   })
    //   .catch(error => {
    //     console.log('Error:', error);
    //   });
    try {
      const response = await fetch(imageUrl);

      const blobImage = await response.blob();

      const href = URL.createObjectURL(blobImage);

      const anchorElement = document.createElement("a");
      anchorElement.href = href;
      anchorElement.download = fileName;

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    } catch (error) {
      console.log("🚀 ~ downloadQRCode ~ error:", error);
    }
  };

  //




    if(isError){
      toast.error("Unexpected Server Error Occured")
      return (
        <Card
        style={{
          width: "100%",
          minHeight: "85vh",
          padding: "20px 40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BiSolidError size={100} color="red"/>
        <Card.Text style={{color: "red", fontSize: "12px"}}>Network Error: Unable to connect to the server. Please check your internet connection and try again later.</Card.Text>
      </Card>
      )
    }



  if (qrcodeList === null) {
    return (
      <Card
        style={{
          width: "100%",
          minHeight: "85vh",
          padding: "20px 40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="border" />
      </Card>
    );
  }
  if (qrcodeList.length < 1) {
    return (
      <Card
        style={{
          width: "100%",
          minHeight: "85vh",
          padding: "20px 40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card.Header style={{textAlign: "center", fontSize: "20px"}}>Empty QrCode List</Card.Header>
      </Card>
    );
  }

  return (
    <>
      <Card
        style={{
          width: "100%",
          minHeight: "85vh",
          padding: "20px 40px",
        }}
      >
        {qrcodeList?.map((qrcode, index) => {
          const originalDate = new Date(qrcode?.createdAt);
          const formattedDate = originalDate.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          });
          return (
            <Card
              key={qrcode?._id}
              style={{
                width: "100%",
                height: "150px",
                backgroundColor: "inherit",
                borderRadius: "8px",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "flex-start",
                marginBottom: "10px"
              }}
            >
              <Flex
                style={{ width: "100%" }}
                justifyContent="between"
                alignItems="center"
              >
                <Card.Title>{qrcode?.title}</Card.Title>
                <Button
                  onClick={() => {
                    setSelectedCode(qrcode);
                    setShowModal(true);
                  }}
                >
                  View details
                </Button>
              </Flex>
              <div>
                <MdSubdirectoryArrowRight />
                <Card.Link href={qrcode?.fullLink} target="_blank">
                  {qrcode?.fullLink}
                </Card.Link>
              </div>
              <Flex
                style={{ width: "100%", gap: "20px" }}
                justifyContent="flex-start"
                alignItems="center"
                gap={"20px"}
              >
                <span>
                  <FaRegCalendar /> {formattedDate}
                </span>
                <span>
                  <FaLink />{" "}
                  <Card.Link href={qrcode?.shortenedLink} target="_blank">
                    {qrcode?.shortenedLink}
                  </Card.Link>{" "}
                </span>
              </Flex>
            </Card>
          );
        })}
      </Card>
      <Modal
        size="md"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
        onExit={() => setShowModal(false)}
      >
        <Modal.Header>
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Modal.Title>Your QRCode details.</Modal.Title>
            </div>
            <AiOutlineClose
              cursor={"pointer"}
              onClick={() => setShowModal(false)}
            />
          </span>
        </Modal.Header>
        {/* <p className="text-center font-weight-bold">Your QRCode details.</p> */}
        <Modal.Body>
          <div id="qr-code-container" className="d-flex justify-content-center">
              <img style={{width: "100%", height: "100%"}} src={selectedCode?.qrCodeLink} alt="qr-code" />
          </div>
          <p style={{ textAlign: "center" }}>
            {" "}
            <a href={selectedCode?.shortenedLink} target="_blank">
              {selectedCode?.shortenedLink}
            </a>{" "}
          </p>
        </Modal.Body>
        {/* <Modal.Footer style={{ flexDirection: "column" }}>
          <div>
            <Button onClick={() => downloadQRCode(selectedCode?.qrCodeLink)}>
              Download QRCode
            </Button>
          </div>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default CodeLists;
