const recursiveAspectFinder = (
  correctWidth,
  correctHeight,
  incomingWidth,
  incomingHeight,
  multiplier = 1,
  addToMultiplier = 0.2
) => {
  let newWidth = incomingWidth * multiplier;
  let newHeight = incomingHeight * multiplier;
  if (newWidth < correctWidth || newHeight < correctHeight) {
    return recursiveAspectFinder(
      correctWidth,
      correctHeight,
      newWidth,
      newHeight,
      multiplier + addToMultiplier
    );
  }
  return {
    new_width: newWidth,
    new_height: newHeight,
  };
};

export default recursiveAspectFinder;
