import ConfirmModal from "components/common/ConfirmModal";
import React, { useState } from "react";
import { CiteDrop, EachImageBox } from "./ExtrernalImageSearchDialogueStyle";
import { useDispatch } from "react-redux";
import { setShowMediaOptions } from "redux/slices/postslice";

const EachExternalImageDisplay = (props) => {
  const [displayCitation, setDisplayCitation] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [src, setSrc] = useState(null);
  const [photographer, setPhotographer] = useState(null);
  const dispatch = useDispatch();
 
  // console.log("🚀 ~ file: EachExternalImageDisplay.js:15 ~ EachExternalImageDisplay ~ props?.selectedObjects:", props?.selectedObjects)

  const onChangeHandler = (e) => {
    let newObj;
      if(e.target.checked === true) {
        newObj = {
          external: true,
          file: null,
          name: props?.x?.src?.medium,
          optimized: true,
          uploaded: true,
          url: props?.x?.src?.medium,
          cite: props?.x?.photographer
        };
        props?.setSelectedObjects([...props?.selectedObjects, newObj])
        return;
      }
     let newSelectedObjects = props?.selectedObjects?.filter(obj => obj.name !== props?.x?.src?.medium)
     props?.setSelectedObjects(newSelectedObjects)
  }

  return (
    <EachImageBox
      onClick={() => {
        // setOpenConfirmation(true);
        setPhotographer(props?.x?.photographer);
        setSrc(props?.x?.src?.medium);
      }}
      onMouseOver={() => {
        setDisplayCitation(true);
      }}
      onMouseLeave={() => {
        setDisplayCitation(false);
      }}
    >
      {displayCitation && (
        <CiteDrop>
          <p style={{ textAlign: "center" }}>
            Photographed by: <b>{props?.x?.photographer}</b>
          </p>
          <p style={{ textAlign: "center", wordBreak: "break-word" }}>
            Photographer url: <b>{props?.x?.photographer_url}</b>
          </p>
        </CiteDrop>
      )}
      <img
        src={props?.x?.src?.medium}
        alt=""
        height="100%"
        width="100%"
        style={{ objectFit: "fill"}}
        onClick={() => setOpenConfirmation(true)}
      />
      <ConfirmModal
        open={openConfirmation}
        title={"Confirm Citation"}
        message={
          "Using this image will attach a citation to the photographer to your final post. Do you still wish to proceed?"
        }
        confirmText={"PROCEED"}
        cancelText={"CANCEL"}
        showDialoguePrompt
        onCancel={() => {
          setOpenConfirmation(false);
        }}
        onConfirm={() => {
          setOpenConfirmation(false);
          props?.handleClick(src, photographer);
          dispatch(setShowMediaOptions(false));
        }}
      />
      <input type="checkbox" style={{zIndex: "2", position: "absolute", top: "0px", left: "0px"}} onChange={onChangeHandler} />
    </EachImageBox>
  );
};

export default EachExternalImageDisplay;
