import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import React, { useEffect, useState, useContext } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getRSSFeedUserId,
  setOpenFeedSettings,
  setRSSFeedUserId,
} from "redux/slices/postslice";
import { Badge } from "react-bootstrap";
import AppContext from "context/Context";

const RssSelectFeed = (props) => {
  const {
    feed,
    deleteFeed,
    promptDelete,
    feedSelected,
    setFeedsToDelete,
    feedsToDelete,
    feeds,
    publishingFeed,
    publishError,
  } = props;
  const dispatch = useDispatch();
  const { config } = useContext(AppContext);
  const feedUserId = useSelector(getRSSFeedUserId);
  const [displayNameAsAvatar, setDisplayNameAsAvatar] = useState(false);

  const addFeedToDelete = (feedId) => {
    setFeedsToDelete((prev) => {
      let itemExists = prev.includes(feedId);
      return itemExists ? prev.filter((x) => x !== feedId) : [...prev, feedId];
    });
  };

  useEffect(() => {
    if (!feedUserId) {
      let feed = feeds[0] ?? {};
      dispatch(setRSSFeedUserId(feed?.user_id));
    }
  }, [feeds, feedUserId]);

  return (
    <>
      <Flex
        justifyContent={"between"}
        alignItems={"center"}
        gap={3}
        style={{ padding: "12px 5px" }}
      >
        <div key={feed._id} href="#">
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            style={{ gap: "10px" }}
          >
            <div className="mx-1">
              <Form.Check
                value={feed?._id}
                checked={feedsToDelete.includes(feed?._id)}
                onChange={() => {
                  const feed_id = feed?._id;
                  addFeedToDelete(feed_id);
                }}
              />
            </div>
            <div
              onClick={() => {
                dispatch(setRSSFeedUserId(feed?.user_id));
              }}
              style={{
                width: 40,
                height: 40,
                borderRadius: 100,
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
              }}
            >
              {displayNameAsAvatar ? (
                <span
                  style={{
                    fontSize: 40,
                    color: "black",
                    fontWeight: "bold",
                    textAlign: "center",
                    alignSelf: "center",
                  }}
                >
                  {feed.user_name.charAt(0).toString().toUpperCase()}
                </span>
              ) : feed.user_photo ? (
                <img
                  src={feed.user_photo}
                  alt={feed.user_name}
                  onError={() => {
                    setDisplayNameAsAvatar(true);
                  }}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 100,
                  }}
                />
              ) : (
                <span
                  style={{
                    fontSize: 28,
                    color: "black",
                    fontWeight: "bold",
                    textAlign: "center",
                    alignSelf: "center",
                  }}
                >
                  {feed.user_name.charAt(0).toString().toUpperCase()}
                </span>
              )}
            </div>
            <Flex justifyContent={"center"} direction={"column"}>
              <span
                className="text-bold"
                style={{ fontSize: 15, cursor: "pointer" }}
                onClick={() => {
                  dispatch(setRSSFeedUserId(feed?.user_id));
                }}
              >
                {feed?.user_name}
              </span>
              {publishingFeed?.length > 0 &&
                publishingFeed?.map((elem) =>
                  elem?.id === feed?.user_id ? (
                    <Badge bg="primary">Publishing</Badge>
                  ) : (
                    publishError && <Badge bg="danger">Publish Error</Badge>
                  )
                )}
            </Flex>
          </Flex>
        </div>

        <div
          style={{
            display: "flex",
            gap: "15px",
          }}
        >
          <FontAwesomeIcon
            icon={faCog}
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch(setOpenFeedSettings(true));
              dispatch(setRSSFeedUserId(feed?.user_id));
            }}
          />
        </div>
      </Flex>
    </>
  );
};

export default RssSelectFeed;
