import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import { RoutePaths } from '../../../../constants';
import { useNavigate } from 'react-router-dom';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { getLoggedInUser } from 'redux/slices/user_slice';
import { useSelector } from 'react-redux';

const ActivePlanPopup = ({ refPlan }) => {

    const navigate = useNavigate();
    const loggedInUser = useSelector(getLoggedInUser);
    const activePlan = refPlan ?? loggedInUser.active_plan;

    const getFeaturesFromActivePlan = () => {
        return [
            {
                title: "Workspace",
                label: "Workspaces Limit",
            },
            // {
            //     title: "Workspace",
            //     label: "Team Members Limit",
            //     route: RoutePaths.TEAM,
            //     tag: { label: 'Manage', type: 'primary' }
            // },
            {
                title: "SocialAccounts",
                label: "Social Accounts Limit"
            },
            {
                title: "ScheduledPosts",
                label: "Posts Credits",
                route: RoutePaths.POSTS,
                tag: { label: 'Schedule', type: 'primary' }
            },
            {
                title: "AI_Writer_Chars",
                altTitle: "AI_Writer_Words",
                label: "AI Characters Limit",
                altLabel: " Postly AI Characters"
            }
        ]
    }
    const features = getFeaturesFromActivePlan();
    return (
        <div className="">
            <div className="text-start px-sm-2 ">
                <ul className="list-unstyled mt-0">
                    {
                        features?.map(feature => (
                            <li className="py-1" key={feature.title}>
                                <FontAwesomeIcon icon="circle" className="me-2 text-success" />{' '}
                                {activePlan[feature.title] ? feature.label : feature.altLabel}{': '} {isNaN(activePlan[feature.title]) ? activePlan[feature.title] : Number(activePlan[feature.title]).toLocaleString()} {' '}
                                {feature.tag && (
                                    <span
                                        onClick={() => {
                                            if (feature.route) {
                                                navigate(feature.route);
                                            }
                                        }}
                                        style={{
                                            cursor: 'pointer'
                                        }}>
                                        <SoftBadge
                                            pill
                                            bg={feature.tag.type}>
                                            {feature.tag.label}
                                        </SoftBadge>
                                    </span>
                                )}
                            </li>
                        ))
                    }
                </ul>
                <a
                    href={"https://postly.ai/docs/daily-post-limits/"}
                    rel={"noreferrer"}
                    target={"_blank"}
                    style={{
                        textDecoration: 'none',
                        display: 'flex',
                        marginTop: '-12px',
                        gap: 8,
                        alignItems: 'center'
                    }}
                >
                    <span>View the daily post limits</span>
                    <FontAwesomeIcon
                        icon={faLink}
                        style={{
                            width: 15,
                            height: 15,
                        }}
                    />
                </a>
            </div>
        </div>
    );
};

ActivePlanPopup.propTypes = {
    pricing: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subTitle: PropTypes.string.isRequired,
        price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        url: PropTypes.string,
        buttonText: PropTypes.string,
        isFeatured: PropTypes.bool,
        featureTitle: PropTypes.string,
        features: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                tag: PropTypes.shape({
                    label: PropTypes.string,
                    type: PropTypes.string
                })
            })
        )
    })
};

export default ActivePlanPopup;
