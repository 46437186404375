import { RolePermissions, Strings } from "../constants";

export const isFreeWorkspace = (workSpace) => {
    let activePlan = workSpace?.active_plan;
    return activePlan?.Name === "Free" || activePlan?.Key === "Free";
}

export const userCanPerformActionInWorkspace = (workSpace, actionName) => {
    try {
        let userRoleInWorkspace = workSpace.role.toUpperCase();
        return RolePermissions[userRoleInWorkspace][actionName] ?? false;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export const addToDemoPlatforms = (pageOrAccount) => {
    let existingDemoPlatforms = localStorage.getItem("existing_demo_platforms");
    if (existingDemoPlatforms) {
        existingDemoPlatforms = JSON.parse(existingDemoPlatforms);
    } else {
        existingDemoPlatforms = [];
    }
    existingDemoPlatforms.push(pageOrAccount);
    localStorage.setItem('existing_demo_platforms', JSON.stringify(existingDemoPlatforms));
    return existingDemoPlatforms;
}

export const getRandomDemoPageOrAccount = () => {
    let existingDemoPlatforms = localStorage.getItem("existing_demo_platforms");
    if (existingDemoPlatforms) {
        existingDemoPlatforms = JSON.parse(existingDemoPlatforms);
        let randomItem = existingDemoPlatforms[Math.floor(Math.random() * existingDemoPlatforms.length - 1)];
        return randomItem;
    }
    return null;
}

export const getActiveWorkspaceFromLocalStorage = () => {
    let localStorageWorkspaceString = localStorage.getItem(Strings.ACTIVE_WORKSPACE);
    if (localStorageWorkspaceString) {
        return JSON.parse(localStorageWorkspaceString);
    }
    return null;
}

export const workspaceIsOfAnyOfThePlans = (plans = [], workspace) => {
    const userPlanName = workspace?.active_plan?.Name;
    const planFound = plans.find(plan => plan === userPlanName);
    return planFound
}

export const isWorkspaceUnderAppSumo = (workSpace) => {
    const name = workSpace?.active_plan?.Name
    if (!name) return false
    let isLTD = name.toLowerCase().includes('appsumo') || name.toLowerCase().includes('lifetime') || name.toLowerCase().includes('ltd') || name.toLowerCase().includes('deal')
    return isLTD
}

export const isWorkspaceUnderSchedulerPro = (workSpace) => {
    const name = workSpace?.active_plan?.Name
    if (!name) return false
    let isLTD = name.toLowerCase().includes('scheduler') || name.toLowerCase().includes('pro')
    return isLTD
}

export const isWhiteLabel = (workSpace) => {

}