import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import ActiveUsersChart from './ActiveUsersChart';

const ActiveUsers = (props) => {
  const {
    followersData,
    loading,
    ...rest } = props;
  const [activeData, setActiveData] = useState();

  useEffect(() => {
    const activeUsers = followersData?.reduce(
      (acc, { mobile, desktop, allPages }) => ({
        mobile: acc.mobile.concat(mobile),
        desktop: acc.desktop.concat(desktop),
        allPages: acc.allPages.concat(allPages)
      }),
      { mobile: [], desktop: [], allPages: [] }
    );
    setActiveData(activeUsers ?? []);
  }, [followersData])

  const [stats] = useState([
    {
      label: 'Mobile',
      color: 'primary',
    },
    {
      label: 'Desktop',
      color: 'success',
    },
    {
      label: 'All',
      color: 'info',
    }
  ]);

  return (
    <Card {...rest}>
      <FalconCardHeader
        title="Active Followers"
        titleTag="h6"
        className="py-2"
        light
      />
      <Card.Body>
        <Row>
          <Col md="auto" className="mt-md-0">
            <Row className="flex-md-column justify-content-between h-md-100 ms-0">
              {stats.map((stat, index) => (
                <Col
                  key={stat.label}
                  className={classNames('pt-3', {
                    'border-end border-md-end-0 border-md-bottom': index !== 2,
                    'pt-md-4': index !== 0
                  })}
                >
                  <h6 className="fs--2 text-7">
                    <FontAwesomeIcon
                      icon="circle"
                      className={`text-${stat.color} me-2`}
                    />
                    {stat.label}
                  </h6>
                </Col>
              ))}
            </Row>
          </Col>
          <Col md="auto" className="echart-active-users-report-container">
            {loading && <Flex
              alignItems={'center'}
              justifyContent={'center'}
              className={'vh-50'}
            >
              <Spinner animation='border' size={40} />
            </Flex>}
            {!loading && activeData &&
              <ActiveUsersChart data={activeData} />}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ActiveUsers;
