import Flex from 'components/common/Flex';
import { WhiteLabelWizardContext } from 'context/Context';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Success from './Success';

const PreSuccess = ({ reset }) => {

    const { user, setStep, setUser } = useContext(WhiteLabelWizardContext);
    const [customizing, setCustomizing] = useState(false);
    const [doneWithCustomization, setDoneWithCustomization] = useState(false);

    const emptyData = () => {
        setStep(1);
        setUser({});
        reset();
    };

    const finishUp = () => {
        let customizationData = user;
        console.log(customizationData);
        setCustomizing(true);
        setTimeout(() => {
            setCustomizing(false);
            setDoneWithCustomization(true);
        }, 2000);
    }

    return (
        <>
            {
                doneWithCustomization ?
                    <Success /> :

                    <Row>
                        <Col className="text-center">
                            <h4 className="mb-1">You are almost ready!</h4>
                            <p className="fs-0">You can always edit your choices in your Account Settings after now</p>
                            <Flex alignItems={'center'} justifyContent={'center'} className={'gap-2'}>
                                <Button
                                    disabled={customizing}
                                    variant="outline-warning"
                                    className="px-5 my-3"
                                    onClick={emptyData}>
                                    Start Over
                                </Button>
                                <Button
                                    disabled={customizing}
                                    variant="primary"
                                    className="px-5 my-3"
                                    onClick={finishUp}>
                                    {customizing ? "Customizing..." : " FINISH UP!"}
                                </Button>
                            </Flex>
                        </Col>
                    </Row>
            }
        </>
    );
};

PreSuccess.propTypes = {
    reset: PropTypes.func.isRequired
};

export default PreSuccess;
