import { PlatformIdentifier } from "constants";

const TextValidator = async (trueTargetPlatforms, mediaItemsArrangement) => {
    let platformSpecificData = {};

    const checkMissingTextForTwitter = async (obj) => {
        let hasMissingText = false;
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const item = obj[key];
                if (!item.hasOwnProperty("text") || item.text.trim() === "") {
                    console.log(`Item with key ${key} is missing text or has an empty text.`);
                    hasMissingText = true;
                }
            }
        }
        return hasMissingText
    }

    for (const platform in trueTargetPlatforms) {
        if (trueTargetPlatforms[platform].identifier) {
            const platformExtracted = mediaItemsArrangement[trueTargetPlatforms[platform].identifier];
            platformSpecificData[trueTargetPlatforms[platform].identifier] = { ...platformExtracted }
        }
    }

    const platformsWithMissingText = [];

    for (const platform in platformSpecificData) {
        if (platform === PlatformIdentifier.TWITTER || platform === PlatformIdentifier.THREADS) {
            const hasMissingText = await checkMissingTextForTwitter(platformSpecificData[platform]);
            if (hasMissingText) {
                platformsWithMissingText.push(platform.toLocaleUpperCase());
            }
        } else {
            if (!platformSpecificData[platform].text || platformSpecificData[platform].text.trim() === "") {
                platformsWithMissingText.push(platform.toLocaleUpperCase());
            }
        }
    }

    if (platformsWithMissingText.length > 0) {
        return {
            error: `Your post is missing text/caption for ${platformsWithMissingText.length} platform(s): ${platformsWithMissingText.join(", ")}. Please use the Preview to review and edit.`,
            platformsWithMissingText
        }
    }

    return {
        success: true,
        platformsWithMissingText: []
    }

}

export default TextValidator