import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfo,
  faUserGroup,
  faCheckCircle,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import postlyBotLogo from "../../../../assets/images/postly_logo.png";
import APIService from "../../../../http/api_service";
import usePubSub from "../../../../pubsub";
import UpgradePlanDialog from "./upgrade_plan_dialog";
import BotConfigurationDialog from "./configure_bot_dialog";
import StringUtils from "../../../../utils/string";
import jwt_decode from "jwt-decode";
import { CircularProgressbar } from "react-circular-progressbar";
import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import {
  getActiveWorkSpace,
  setActiveWorkspace,
  setTelegramBotLogo,
} from "../../../../redux/slices/workspaceslice";
import Chip from "components/common/chip";
import { Button, Modal, Card, Spinner, Dropdown, Form, Alert } from "react-bootstrap";
import InfoBar from "components/common/InfoBar";
import RichInfoBar from "components/common/RichInfoBar";
import Divider from "components/common/Divider";
import AppContext from "context/Context";
import { lightTheme, darkTheme } from "utils/theme";
import { useDispatch, useSelector } from "react-redux";
import Flex from "components/common/Flex";
import { setPopupCustomAlert, setPopupUpgradeAlert } from "redux/slices/alertSlice";
import { setCollectMetric } from "redux/slices/analytics_slice";
import { MetricsProps } from "constants";
import CustomTelegramBotComponent from "./CustomTelegramBotComponent";
import { hasPopupBlocker, recommendUrlToUnblockPopup } from "utils/url";
import ConfirmModal from "components/common/ConfirmModal";
import { getLoggedInUser, setLoggedInUser } from "redux/slices/user_slice";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "constants";
import PendingBotComponent from "./PendingBotComponent";
import { dispatchHandler } from "utils/post_utils";

export default function TelegramGroupsAndChannels(props) {
  const { config } = useContext(AppContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [platform, setPlatform] = useState(props.platform);
  const workSpace = useSelector(getActiveWorkSpace);
  const loggedInUser = useSelector(getLoggedInUser);
  const [connectedGroupsAndChannels, setConnectedGroupsAndChannels] = useState(
    platform.connected_pages_and_groups ?? []
  );
  const [doneWithFetch, setDoneWithFetch] = useState(false);
  const [workSpaceBots, setWorkspaceBots] = useState([]);
  const postlyBot = "@postly_bot";
  const [defaultBotUserName, setDefaultBotUserName] = useState(postlyBot);
  const [defaultBotLogo, setDefaultBotLogo] = useState(postlyBotLogo);
  const [defaultBot, setDefaultBot] = useState();
  const [fetchingWorkSpaceBots, setFetchingWorkSpaceBots] = useState(true);
  const [creatableBotSlots, setCreatableBotSlots] = useState(0);
  const activeWorkSpacePlan = workSpace.active_plan;
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const [openBotCustomizationDialog, setOpenBotCustomizationDialog] =
    useState(false);
  const [pendingBotRequests, setPendingBotRequests] = useState([]);
  const [updatableBot, setUpdatableBot] = useState();
  const { addPubSubEventListener, removePubSubEventListener } = usePubSub();
  const [searchInputValue, setSearchInputValue] = useState("");
  const [constantPages, setConstantPages] = useState([]);
  const [allConnectedGroupsAndChannels, setAllConnectedGroupsAndChannels] = useState([]);
  const [searchedApplied, setSearchedApplied] = useState(false);
  const [workSpaceTelegramBotData, setWorkSpaceTelegramBotData] = useState(null);
  const [currentActiveOutsideTabOutsourced, setCurrentActiveOutsideTabOutsourced] = useState(null);
  const [popupBlocked, setPopupBlocked] = useState(false);
  const popUpUnblockHint = recommendUrlToUnblockPopup();
  const [purchasingTelegramBot, setPurchasingTelegramBot] = useState(false);

  const setUpCreatableSlots = () => {
    if (workSpace) {
      let telegramBotData = workSpace?.plans?.active?.postly_telegram_bot;
      setWorkSpaceTelegramBotData(telegramBotData)
      if (!telegramBotData) {
        setCreatableBotSlots(0);
      }
      let totalBots = telegramBotData?.Bots ?? 0;
      let availableBots = telegramBotData?.availableBots ?? 0
      let usedBots = telegramBotData?.usedBots ?? 0
      setCreatableBotSlots(availableBots);
    } else {
      setCreatableBotSlots(0);
    }
  }

  useEffect(() => {
    setUpCreatableSlots()
  }, [workSpace])


  useEffect(() => {
    let requestStatusEventListener = (response) => {
      let { data } = response;
      if (data) {
        if (data.success) {
          window.location.reload();
        }
      }
    };
    addPubSubEventListener(
      `${workSpace._id}_bot_customization_status`,
      requestStatusEventListener
    );
    return () => {
      removePubSubEventListener(
        `${workSpace._id}_bot_customization_status`,
        requestStatusEventListener
      );
    };
  }, []);

  const listenToBotConnections = () => {
    addPubSubEventListener(`${workSpace["_id"]}auth`, (data) => {
      if (data["oauth_status"] === "cancelled") {
        dispatchHandler(setPopupCustomAlert({
          type: 'error',
          message: `Integration Cancelled`,
        }));
      } else {
        setConnectedGroupsAndChannels([]);
        setDoneWithFetch(false);
      }
    });
  };

  useEffect(() => {
    localStorage.removeItem("buy_customized_bot");
    let meta = StringUtils.substringAfter(window.location.href, "?meta");
    if (meta !== window.location.href) {
      try {
        let metaData = jwt_decode(meta);
        if (metaData) {
          if (metaData.success_message) {
            if (metaData.allocate_slots_for_custom_telegram_bot) {
              setOpenBotCustomizationDialog(true);
            }
          }
        }
      } catch (e) { }
    }
    listenToBotConnections();
  }, []);

  const applySearch = (searchTerm) => {
    let pages = [...constantPages];
    if (searchTerm) {
      let filters = pages.filter((x) =>
        x.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setAllConnectedGroupsAndChannels(filters);
      setSearchedApplied(true);
    } else {
      setAllConnectedGroupsAndChannels(pages);
      setSearchedApplied(true);
    }
  };

  useEffect(() => {
    if (!doneWithFetch) {
      // if (!doneWithFetch || !connectedGroupsAndChannels?.length) {
      APIService.fetchPagesAndGroups(
        workSpace,
        platform.identifier,
        platform.user_id,
        (response, error) => {
          if (error) {
            dispatchHandler(setPopupCustomAlert({
              type: 'error',
              message: error,
            }));
            return;
          }
          let responseData = response["data"];
          responseData.sort((a, b) => {
            return b["connected"] - a["connected"];
          });
          if (defaultBotUserName !== postlyBot) {
            responseData = responseData.filter(
              (x) => x.bot === defaultBotUserName.replace("@", "")
            );
          }

          setConnectedGroupsAndChannels(responseData);
          setAllConnectedGroupsAndChannels(responseData)
          setConstantPages(responseData);
          setDoneWithFetch(true);
        }
      );
    }
  }, [connectedGroupsAndChannels, platform, doneWithFetch]);

  const fetchPendingBotRequests = () => {
    let requestBody = {
      workSpaceId: workSpace._id,
    };
    APIService.fetchPendingBotRequests(requestBody, (response, error) => {
      setFetchingWorkSpaceBots(false);
      if (error) {
        return;
      }
      if (response) {
        let { data } = response;
        setPendingBotRequests(data);
      }
    });
  };


  useEffect(() => {
    if (platform.user_id) {
      setFetchingWorkSpaceBots(true);
      APIService.fetchWorkSpaceBots(
        workSpace._id,
        platform.user_id,
        (response, error) => {
          fetchPendingBotRequests();
          if (error) {
            return;
          }
          let { data } = response;
          if (data) {
            let botList = [];
            for (let bot of data) {
              let existingBot = botList.find((x) => x.token === bot.token);
              if (!existingBot) {
                botList.push(bot);
              }
            }
            setWorkspaceBots(botList);
            setDefaultBot(botList.find((x) => x.active));
          }
        }
      );
    }
  }, [platform]);

  useEffect(() => {
    if (defaultBot) {
      setDefaultBotUserName(`@${defaultBot.username}`);
      setDefaultBotLogo(defaultBot.logo);
      setConnectedGroupsAndChannels([]);
      setDoneWithFetch(false);
    }
  }, [defaultBot]);

  useEffect(() => {
    setPlatform(props.platform);
  }, [props.platform]);

  const setBotAsDefault = (bot) => {
    setWorkspaceBots((prevworkSpaceBots) => {
      prevworkSpaceBots = prevworkSpaceBots.map((x) => {
        return {
          ...x,
          active: x._id === bot._id,
        };
      });
      return [...prevworkSpaceBots];
    });
    setDefaultBot(bot);
    APIService.setDefaultBot(
      workSpace._id,
      platform.user_id,
      bot.token,
      (response, error) => {
        if (error) {
          dispatchHandler(setPopupCustomAlert({
            type: 'error',
            message: error,
          }));
          return;
        }
        let { data } = response;
        let botList = [];
        for (let bot of data) {
          let existingBot = botList.find((x) => x.token === bot.token);
          if (!existingBot) {
            botList.push(bot);
          }
        }
        setWorkspaceBots(botList);
        setDefaultBot(bot);
        dispatchHandler(setPopupCustomAlert({
          type: 'success',
          message: "Default Bot set successfully!",
        }));
      }
    );
  };

  const editBotDetails = (bot) => {
    if (bot.logo) {
      dispatch(setTelegramBotLogo(bot.logo));
    }
    setUpdatableBot(bot);
    setOpenBotCustomizationDialog(true);
  };

  const ConnectInstructionsView = React.forwardRef((props, ref) => {
    return (
      <Card
        style={{
          display: "flex",
          padding: 20,
          borderRadius: 10,
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: 10,
            marginBottom: 10,
            gap: 5,
          }}
        >
          <FontAwesomeIcon
            icon={faInfo}
            style={{
              color: "#78909C",
            }}
          />
          <span
            style={{
              textAlign: "start",
              marginLeft: 0,
            }}
          >
            Next Step:
          </span>
        </div>
        <ul
          style={{
            padding: 0,
            marginLeft: 23,
            listStyleType: "disc",
            fontSize: 15,
            display: "flex",
            flexDirection: "column",
            gap: 12,
          }}
        >
          <li>
            <span>Add</span>
            <img src={defaultBotLogo} alt="Bot Logo" width={20} height={15} />
            <span>
              <b>{defaultBotUserName}</b>
            </span>
            <span
              dangerouslySetInnerHTML={{
                __html: ` to Telegram Groups created or administered by <b>@${platform.user_name}</b>`,
              }}
            ></span>
          </li>
          <li>
            <span>Add</span>
            <img src={defaultBotLogo} alt="Bot Logo" width={20} height={15} />
            <span>
              <b>{defaultBotUserName}</b>
            </span>
            <span
              dangerouslySetInnerHTML={{
                __html: `as an <b>Admin</b> to Telegram Channels created or administered by <b>@${platform.user_name}</b>`,
              }}
            ></span>
          </li>
          <li>
            <span>
              To avoid conflicts with similar bots, kindly ensure that
              <b>{defaultBotUserName}</b> has{" "}
              {`${defaultBotUserName === "@postly_bot" ? "our" : "your"}`} Logo
            </span>
            <img src={defaultBotLogo} alt="Bot Logo" width={20} height={15} />
          </li>
          <li>
            <span>
              Maximum upload size for Photos is <b>5MB</b>. For other files, it
              is <b>20MB</b>
            </span>
          </li>
        </ul>
        <InfoBar
          info={`Publishable content is pushed only to Groups and Channels where <b>@${platform.user_name}</b> has added <b>${defaultBotUserName}</b>`}
        />
      </Card>
    );
  });

  const ConnectedTelegramChannelsView = React.forwardRef((props, ref) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 10,
        }}
      >
        <Card
          style={{
            display: "flex",
            flexDirection: "column",
            borderRadius: 10,
            padding: 50,
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <h5
              type={"h3"}
              dangerouslySetInnerHTML={{
                __html: `Groups &amp; Channels connected to <b>${defaultBotUserName}</b>`,
              }}
            ></h5>
          </div>
          <Divider />

          <Flex
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 10,
              width: "100%",
              padding: 10,
            }}
          >
            <Form.Control
              type="search"
              placeholder={"Search for channels..."}
              //   placeholder={"Search for a connected groups and channels..."}
              aria-label="Search"
              className="rounded-pill search-input"
              value={searchInputValue}
              disabled={!constantPages.length}
              onChange={({ target }) => {
                applySearch(target.value);
                setSearchInputValue(target.value);
              }}
              style={{
                width: 400,
              }}
            />
          </Flex>
          {/*  */}
          {!allConnectedGroupsAndChannels.length && !doneWithFetch && (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spinner
                animation="border"
                size={40}
                message={`Fetching Groups & Channels`}
              />
            </div>
          )}
          {doneWithFetch && allConnectedGroupsAndChannels.length < 1 ? (
            <span
              style={{
                fontWeight: 400,
                padding: 10,
                textAlign: "center",
              }}
            >
              None
            </span>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 10,
              }}
            >
              {allConnectedGroupsAndChannels.map((entry) => {
                return (
                  <Chip key={entry.id}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minWidth: 100,
                        gap: 10,
                        paddingRight: 10,
                        paddingLeft: 10,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={entry.is_group ? faUserGroup : faTelegramPlane}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          paddingTop: 5,
                          paddingBottom: 5,
                          gap: 2,
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: 12,
                            fontWeight: "bold",
                          }}
                        >
                          {entry.name}
                        </span>
                        <span
                          style={{
                            fontSize: 10,
                            color: "#78909C",
                          }}
                        >
                          {entry.is_group ? "Group" : "Channel"}
                        </span>
                      </div>
                    </div>
                  </Chip>
                );
              })}
            </div>
          )}
        </Card>
      </div>
    );
  });

  const initCustomization = (forceBuyNewBot = false) => {
    if (forceBuyNewBot) {
      setOpenUpgradeDialog(true)
      return
    }
    if (creatableBotSlots < 1) {
      // let metricInitializer = {
      //   action: MetricsProps.BOT_REQUEST,
      //   detailedAction: `Upgrade notice: Bot customization`,
      //   timestamp: new Date().toISOString(),
      //   route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
      // }
      // dispatch(setCollectMetric(metricInitializer))
      // dispatch(setPopupUpgradeAlert(true))
      setOpenUpgradeDialog(true)
    } else {
      setOpenBotCustomizationDialog(true);
    }
  };

  const BotOptionsPopover = ({ x }) => (
    <Dropdown.Menu>
      <Dropdown.Item onClick={() => editBotDetails(x)}>
        Edit Bot Details
      </Dropdown.Item>
      {!x.active && (
        <Dropdown.Item
          onClick={(e) => {
            setBotAsDefault(x);
          }}
        >
          Set as Default
        </Dropdown.Item>
      )}
    </Dropdown.Menu>
  );

  const displayPendingRequests = () => {
    const mappedElement = pendingBotRequests.map((x) => {
      return <PendingBotComponent x={x} fetchPendingBotRequests={fetchPendingBotRequests} />

    });
    return mappedElement
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "10%",
        marginRight: "10%",
        gap: 10,
      }}
    >
      {fetchingWorkSpaceBots && (
        <Card
          style={{
            display: "flex",
            padding: 50,
            borderRadius: 10,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner animation="border" size={40} />
        </Card>
      )}
      {!fetchingWorkSpaceBots && <ConnectInstructionsView />}
      {pendingBotRequests.length > 0 && (
        displayPendingRequests()
      )}
      <Alert variant="info" className="text-left mt-3">
        Connect Your Telegram Bot for Direct Publishing with Postly.
        <br /><br />
        Use your custom bot or our default @postly_bot to link your Telegram channels and groups.
        <br /><br />
        To start publishing directly with Postly, add the bot and make it an Admin of your group or channel.<br /><br />
      </Alert>
      {workSpaceBots.length > 0 && (
        <Card
          style={{
            display: "flex",
            padding: 50,
            paddingTop: 20,
            paddingBottom: 20,
            borderRadius: 10,
            marginTop: 10,
            marginBottom: 10,
            flexDirection: "column",
            gap: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
              marginBottom: 10,
            }}
          >
            <h5 type={"h3"}>Your Custom Bots</h5>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 10,
            }}
          >
            {workSpaceBots.map((x) => {
              return (
                <Chip
                  key={x._id}
                  style={{
                    background: x.active
                      ? "#00897B"
                      : !config.isDark
                        ? lightTheme.postTargetChipBackground
                        : darkTheme.rootBackground,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 5,
                      alignItems: "center",
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingLeft: 10,
                      position: "relative",
                    }}
                  >
                    {x.active && (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{
                          color: "white",
                          height: 25,
                          width: 25,
                        }}
                      />
                    )}
                    <img
                      src={x.logo}
                      style={{
                        width: 30,
                        height: 30,
                        marginRight: 10,
                        borderRadius: "100%",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: 10,
                      }}
                    >
                      <span
                        style={{
                          fontSize: 15,
                          fontWeight: "bold",
                          color: x.active ? "white" : null,
                        }}
                      >
                        {x.name}
                      </span>
                      <span
                        style={{
                          fontSize: 10,
                          color: x.active ? "#CFD8DC" : "#78909C",
                        }}
                      >
                        {`@${x.username}`}
                      </span>
                    </div>
                    <Dropdown>
                      <Dropdown.Toggle
                        className="rounded-circle p-2 px-3"
                        style={{
                          background: "transparent",
                          borderColor: "transparent",
                          outline: "none",
                        }}
                      />
                      <BotOptionsPopover x={x} />
                    </Dropdown>
                  </div>
                </Chip>
              );
            })}
            {pendingBotRequests.length < 1 && (
              <Button
                key={"another_custom_bot"}
                onClick={() => {
                  initCustomization();
                }}
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                  minHeight: 45,
                  borderRadius: 100,
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: 5,
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faAdd} />
                  <span style={{}}>Create another Custom Bot</span>
                </div>
              </Button>
            )}
          </div>
        </Card>
      )}
      {!fetchingWorkSpaceBots &&
        defaultBotUserName === "@postly_bot" &&
        !fetchingWorkSpaceBots && (
          <div style={{ marginTop: 10 }}>
            <CustomTelegramBotComponent
              workSpaceTelegramBotData={workSpaceTelegramBotData}
              initCustomization={initCustomization}
            />
          </div>
        )}
      <ConnectedTelegramChannelsView />
      <Modal
        show={openUpgradeDialog}
        size={"lg"}
        onHide={() => {
          setOpenUpgradeDialog(false);
        }}
      >
        <Modal.Body>
          <UpgradePlanDialog
            refresh={() => { }}
            setCurrentActiveOutsideTabOutsourced={setCurrentActiveOutsideTabOutsourced}
            currentActiveOutsideTabOutsourced={currentActiveOutsideTabOutsourced}
            purchasingTelegramBot={purchasingTelegramBot}
            setPurchasingTelegramBot={setPurchasingTelegramBot}
            modalClose={() => {
              setOpenUpgradeDialog(false);
            }}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={openBotCustomizationDialog}
        size={"lg"}
        withCloseIcon={true}
        onHide={() => {
          setOpenBotCustomizationDialog(false);
        }}
      >
        <Modal.Body>
          <BotConfigurationDialog
            platform={platform}
            updatableBot={updatableBot}
            feedBackHandler={(done) => {
              if (done) {
                setPlatform((prevPlatform) => {
                  prevPlatform.done = true;
                  return { ...prevPlatform };
                });
              }
            }}
            refresh={() => { }}
            modalClose={() => {
              setOpenBotCustomizationDialog(false);
            }}
          />
        </Modal.Body>
      </Modal>
      <ConfirmModal
        open={popupBlocked}
        title={"Popup Blocker Detected"}
        message={`Your browser is blocking our pop-ups. As a result, we could not take you to the payment page. \n\n${popUpUnblockHint
          ? popUpUnblockHint
          : "Kindly disable pop-up blocking"
          } and try again.\n\nYou can re-enable pop-up blocking after purchasing custom Telegram bot slot.\n\nThank you`}
        confirmText={"OK"}
        cancelText={"CLOSE"}
        onConfirm={() => {
          setPopupBlocked(false);
          setPurchasingTelegramBot(false);
        }}
        onCancel={() => {
          setPopupBlocked(false);
          setPurchasingTelegramBot(false);
        }}
      />
    </div>
  );
}
