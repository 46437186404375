import React, {
    useEffect,
    useState
} from 'react';
import {
    getUpdatableEventDay,
    setRefreshPopularDays,
    setUpdatableEventDay
} from '../../../../redux/slices/postslice';
import {
    useSelector,
    useDispatch
} from 'react-redux';
import APIService from '../../../../http/api_service';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import IconButton from 'components/common/IconButton';
import {
    Button, Col,
    Form, Row
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import CustomDateInput from 'components/common/CustomDateInput';
import Flex from 'components/common/Flex';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';

export default function CustomEventDayCreator(props) {

    const dispatch = useDispatch();
    const workSpace = useSelector(getActiveWorkSpace);
    const { modalClose } = props;
    const [name, setName] = useState();
    const [startDate, setStartDate] = useState();
    const [startTime, setStartTime] = useState();
    const [endDate, setEndDate] = useState();
    const [endTime, setEndTime] = useState();
    const [addingDay, setAddingDay] = useState(false);
    const [errorMsg, setErrorMessage] = useState();
    const [location, setLocation] = useState();
    const updatableDay = useSelector(getUpdatableEventDay);

    useEffect(() => {
        if (updatableDay) {
            setName(updatableDay.name);
            setStartDate(new Date(updatableDay.date));
            setLocation(updatableDay.location);
        }
    }, [updatableDay]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (addingDay) {
            return;
        }
        setAddingDay(true);
        setErrorMessage(null);
        APIService.upsertCustomEvent(workSpace._id, updatableDay
            ?
            {
                updatableEvent:
                {
                    ...updatableDay,
                    name,
                    date: startDate.getTime(),
                    startDate,
                    startTime,
                    endDate,
                    endTime,
                    location
                }
            }
            :
            {
                newEventData:
                {
                    eventName: name,
                    eventDate: startDate.getTime(),
                    startDate,
                    startTime,
                    endDate,
                    endTime,
                    location
                }
            },
            (response, error) => {
                if (error) {
                    setAddingDay(false);
                    setErrorMessage(error);
                    return;
                }
                let { message } = response;
                setAddingDay(false);
                toast.success(message, { theme: 'colored' });
                dispatch(setRefreshPopularDays(true));
                dispatch(setUpdatableEventDay(null));
                modalClose();
            });
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 20,
                padding: 20,
                minHeight: 300
            }}>
            <IconButton
                className={'rounded-circle'}
                iconClassName={'fs-6'}
                icon={faCalendarDay}
                style={{
                    width: 100,
                    height: 100
                }}
            />
            <h5 style={{ textAlign: 'center' }}>{updatableDay ? 'Update Event Day' : `Register New Event`}</h5>
            <Form onSubmit={handleSubmit}>
                <Row className="gx-2 gy-3">
                    <Col md="12">
                        <Form.Group controlId="scheduleStartTitle">
                            <Form.Label>Event Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter event name E.g Team Bonding Day"
                                defaultValue={name}
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col md="6">
                        <Form.Group controlId={`ScheduleStartDate`}>
                            <Form.Label>Start Date</Form.Label>
                            <DatePicker
                                minDate={new Date()}
                                selected={startDate}
                                onChange={newDate => {
                                    setStartDate(newDate);
                                }}
                                customInput={
                                    <CustomDateInput
                                        formControlProps={{
                                            placeholder: 'd/m/y',
                                        }}
                                    />
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col md="6">
                        <Form.Group controlId="scheduleStartTime">
                            <Form.Label>Start Time</Form.Label>
                            <DatePicker
                                selected={startTime}
                                defaultValue={startTime}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm"
                                onChange={newDate => {
                                    setStartTime(newDate);
                                }}
                                customInput={
                                    <CustomDateInput
                                        formControlProps={{
                                            placeholder: 'H:i',
                                        }}
                                    />
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col md="6">
                        <Form.Group controlId="scheduleEndDate">
                            <Form.Label>End Date</Form.Label>
                            <DatePicker
                                minDate={new Date()}
                                selected={endDate}
                                defaultValue=""
                                onChange={newDate => {
                                    setEndDate(newDate);
                                }}
                                customInput={
                                    <CustomDateInput
                                        formControlProps={{
                                            placeholder: 'd/m/y',
                                        }}
                                    />
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col md="6">
                        <Form.Group controlId="scheduleEndTime">
                            <Form.Label>End Time</Form.Label>
                            <DatePicker
                                selected={endTime}
                                defaultValue=""
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm"
                                onChange={newDate => {
                                    setEndTime(newDate);
                                }}
                                customInput={
                                    <CustomDateInput
                                        formControlProps={{
                                            placeholder: 'H:i',
                                        }}
                                    />
                                }
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Col md="12" className='mt-3'>
                    <Form.Group controlId="scheduleStartTitle">
                        <Form.Label>Event Location</Form.Label>
                        <Form.Control
                            type="text"
                            value={location}
                            placeholder="Add event Location (Optional)"
                            defaultValue={name}
                            onChange={(e) => {
                                setLocation(e.target.value);
                            }}
                        />
                    </Form.Group>
                </Col>
                <Flex
                    className={'mt-5 gap-2'}
                    alignItems={'end'}
                    justifyContent={'end'}>
                    <Button
                        variant={'default'}
                        onClick={modalClose}>CANCEL
                    </Button>
                    <Button
                        disabled={
                            !name ||
                            !startDate
                            || !startTime
                            || addingDay
                        }
                        type={'submit'}
                        isWorking={addingDay}
                        variant={'primary'}>
                        {addingDay ? updatableDay ? 'UPDATING...' : 'ADDING NEW EVENT...' : updatableDay ? 'UPDATE' : 'ADD'}
                    </Button>
                </Flex>
            </Form>
            {
                errorMsg &&
                <span style={{ textAlign: 'center' }} className={'danger'}>{errorMsg}</span>
            }
        </div>
    );
}