import { useCallback, useState } from "react";
import { useEffect } from "react";




export const useFetchPageOwner = (chatRoomId, chats, users) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!chatRoomId) {
      return;
    }

    const chatRoom = chats?.find(({ room_id }) => room_id === chatRoomId) || {};
    const userId = chatRoom?.participants?.find((id) => id === chatRoom?.user_id);

    if (userId) {
      const foundUser = users?.find(({ page_id }) => page_id === userId);
      setUser(foundUser || null);
    }
  }, [chatRoomId, chats, users]);

  return { user };
};


