import React, { useState, useEffect } from 'react';
import APIService from "../../../http/api_service";
import { toast } from "react-toastify";
import { copyToClipboard } from '../../../utils/browser';
import {
    setComposerFocused,
    setOpenDraftPrompt,
} from "../../../redux/slices/postslice";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "../../../constants";
import { faClone, faRemove, faLocationArrow, faSave } from "@fortawesome/free-solid-svg-icons";
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setPublishablePostTextBody } from 'redux/slices/post_text_body_slice';

const ResultPreview = React.forwardRef((props, ref) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        text,
        _id,
        referenceTag,
        fetchSavedCopies,
        selectedTheme,
        referenceTool,
        workSpace,
        canShowTool = false,
        ...others
    } = props;
    const [saved, setSaved] = useState(others.saved ?? false);
    const [processingSave, setProcessingSave] = useState(false);
    const [copyText, setCopyText] = useState("Copy");
    const characterLength = text.length;
    const wordLength = text.split(' ').length;
    const refTag = referenceTag ? referenceTag : referenceTool;

    useEffect(() => {
        setSaved(others.saved ?? false);
    }, [others.saved]);

    const handleCopy = () => {
        setCopyText("Copied");
        setTimeout(() => setCopyText("Copy"), 2000);
        copyToClipboard(text);
    };

    const saveCopy = () => {
        setProcessingSave(true);
        let ref = referenceTag ? referenceTag : referenceTool;
        if (!ref) {
            setProcessingSave(false);
            return;
        }
        APIService.saveCopy(workSpace._id, ref.name, text, (response, error) => {
            setProcessingSave(false);
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            if (response) {
                setSaved(true);
                toast.success(response.message, { theme: 'colored' });
            }
            fetchSavedCopies();
        });
    }

    const unSaveCopy = () => {
        setProcessingSave(true);
        APIService.removeCopy(workSpace._id, _id, (response, error) => {
            setProcessingSave(false);
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            if (response) {
                setSaved(false);
                toast.success(response.message, { theme: 'colored' });
            }
            fetchSavedCopies();
        });
    }

    const copy = () => {
        let cpy = text.replace(/\n\r?/g, "<br/>").replace("Content:", "").replace("Voice:", "")
        return cpy;
    }

    return (
        <Card
            ref={ref}
            style={{
                width: '100%',
                padding: 25,
                borderRadius: 5
            }}>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column'
                }}>
                {
                    canShowTool && refTag &&
                    <span
                        style={{
                            alignSelf: 'flex-end',
                            color: '#78909C',
                            fontSize: 12
                        }}>{refTag}
                    </span>
                }
                <span
                    style={{
                        width: '100%',
                        paddingBottom: 20
                    }}
                    dangerouslySetInnerHTML={{ __html: copy() }}
                />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        gap: 10
                    }}>
                    <Button
                        variant='outline-primary'
                        className="rounded-pill me-1 mb-1"
                        style={{
                            minWidth: 90
                        }}
                        onClick={() => {
                            dispatch(setComposerFocused(true));
                            dispatch(setPublishablePostTextBody(text));
                            navigate(RoutePaths.POSTS);
                            dispatch(setOpenDraftPrompt(false));
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 5,
                            }}>
                            <FontAwesomeIcon
                                icon={faLocationArrow}
                            />
                            <span>Post
                            </span>
                        </div>
                    </Button>
                    <Button
                        variant='outline-primary'
                        className="rounded-pill me-1 mb-1"
                        onClick={() => {
                            handleCopy();
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 5,
                            }}>
                            <FontAwesomeIcon
                                icon={faClone}
                            />
                            <span>{copyText}
                            </span>
                        </div>
                    </Button>
                    {
                        !saved &&
                        <Button
                            variant='outline-primary'
                            className="rounded-pill me-1 mb-1"
                            disabled={processingSave}
                            onClick={() => {
                                saveCopy()
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 5,
                                }}>
                                <FontAwesomeIcon
                                    icon={faSave}
                                />
                                <span>{processingSave ? 'Saving...' : 'Save'}
                                </span>
                            </div>
                        </Button>
                    }
                    {
                        saved &&
                        <Button
                            variant='outline-primary'
                            className="rounded-pill me-1 mb-1"
                            disabled={processingSave}
                            isWorking={processingSave}
                            onClick={() => {
                                unSaveCopy()
                            }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 5,
                                }}>
                                <FontAwesomeIcon
                                    icon={faRemove}
                                />
                                <span>{processingSave ? 'Unsaving...' : 'Unsave'}
                                </span>
                            </div>
                        </Button>
                    }
                </div>
                <div className='text-end'>
                    <span
                        style={{
                            color: '#78909C',
                            fontSize: 10
                        }}>{`${wordLength} words / ${characterLength} chars`}
                    </span>
                </div>
            </div>
        </Card>
    )
});
export default ResultPreview;