import React, { useContext, useState } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import WorkSpacesTableHeader from './WorkSpacesTableHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { RoutePaths } from '../../../constants';
import StringUtils from 'utils/string';
import moment from 'moment';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { useDispatch } from 'react-redux';
import { getCachedWorkSpaces, setReloadPage } from '../../../redux/slices/workspaceslice';
import { getLoggedInUserFromLocalStorage } from 'utils/user_utils';
import { getActiveWorkspaceFromLocalStorage } from 'utils/workspace_utils';
import { useSelector } from 'react-redux';
import useOrganizationStore, { defaultOrganization } from 'state/organization_store';
import useActiveOrganization from '../organization-and-workspace-setup/hooks/useActiveOrganization';
import { useTranslation } from 'react-i18next';
import OrganizationSettingsPanel from '../organization-and-workspace-setup/organization/settings/organization_settings_panel';
import AppContext from 'context/Context';

const loggedInUser = getLoggedInUserFromLocalStorage();
const activeWorkSpace = getActiveWorkspaceFromLocalStorage();

const selectedState = (id) => {
    if (id === activeWorkSpace?._id) {
        return 'status-online';
    }
    return ''
}

const columns = [
    {
        accessor: 'name',
        Header: 'Name',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { _id, name, archived, avatar } = rowData.row.original;
            return (
                <Link style={{
                    pointerEvents: archived ? 'none' : 'all'
                }} to={`${RoutePaths.SWITCH_WORKSPACE}?to=${_id}`}>
                    <Flex alignItems="center">
                        {avatar ? (
                            <Avatar src={avatar} size="xl" className={`me-2 ${selectedState(_id)}`} />
                        ) : (
                            <Avatar size="xl" name={name} className={`me-2 ${selectedState(_id)}`} />
                        )}
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{name}</h5>
                        </div>
                    </Flex>
                </Link>
            );
        }
    },
    {
        accessor: 'role',
        Header: 'Role',
        Cell: rowData => {
            const { role } = rowData.row.original;
            return <span>{StringUtils.capitalizeString(role)}</span>;
        }
    },
    {
        accessor: 'owner_id',
        Header: 'Ownership',
        Cell: rowData => {
            const { owner_id } = rowData.row.original;
            return <span>{owner_id === loggedInUser?._id ? "Created by You" : "Invited"}</span>;
        }
    },
    {
        accessor: 'archived',
        Header: 'State',
        Cell: rowData => {
            const { archived } = rowData.row.original;
            return <span>{archived ? "Archived" : "Active"}</span>;
        }
    },
    {
        accessor: 'created_at',
        Header: 'Created At',
        Cell: rowData => {
            const { created_at } = rowData.row.original;
            return <span>{moment(new Date(created_at)).format("LLLL")}</span>;
        }
    }
];

const WorkSpaceSearch = ({ cardClassName = '' }) => {

    const { t } = useTranslation();
    const { config: { isDark } } = useContext(AppContext);
    const allWorkspaces = useOrganizationStore(state => state.allWorkspaces);
    const cachedWorkspaces = useSelector(getCachedWorkSpaces);
    const [activeOrganization] = useActiveOrganization();
    let spaces = allWorkspaces.filter(w => w?.organization?._id === activeOrganization?._id);
    spaces = spaces.length > 0 ? spaces : allWorkspaces.filter(workspace => !workspace?.organization);
    const [workSpaces, setWorkSpaces] = useState((spaces.length > 0 ? spaces : cachedWorkspaces).map(workSpace => {
        return {
            ...workSpace,
            disabled: workSpace?.owner_id !== loggedInUser?._id
        }
    }).sort(x => x._id === activeWorkSpace?._id ? -1 : 1));
    const dispatch = useDispatch();
    const [openOrganizationSettingsPanel, setOpenOrganizationSettingsPanel] = useState(false);

    const workSpaceUpdateHandler = (updatedWorkSpaces) => {
        setWorkSpaces([...updatedWorkSpaces]);
    }

    const bulkWorkspacesUpdateHandler = (updatedData) => {
        setWorkSpaces((prevWorkspaces) => {
            for (let workspace of updatedData) {
                let existingWorkspace = prevWorkspaces.find(x => x._id === workspace._id);
                if (existingWorkspace) {
                    let index = prevWorkspaces.indexOf(existingWorkspace);
                    existingWorkspace.name = workspace.name;
                    prevWorkspaces[index] = existingWorkspace;
                }
            }
            return [...prevWorkspaces];
        });
        dispatch(setReloadPage(true));
    }

    const workspacesOutsideActiveOrganization = (workspaces = []) => {
        if (activeOrganization.id === defaultOrganization._id) {
            return undefined;
        }
        let spaces = workspaces.filter(w => w?.organization).filter(Boolean).filter(w => w?.organization?._id !== activeOrganization?._id);
        return spaces.length === 0 ? undefined : spaces.length;
    }

    const orgsCountOutSideActive = workspacesOutsideActiveOrganization(allWorkspaces);

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={workSpaces}
            selection
            sortable
            pagination
            perPage={10}
        >
            <Card className={cardClassName}>
                <Card.Header>
                    <WorkSpacesTableHeader
                        table
                        workSpaces={workSpaces}
                        workSpaceUpdateHandler={workSpaceUpdateHandler}
                        bulkWorkspacesUpdateHandler={bulkWorkspacesUpdateHandler}
                        onWorkspacesMoved={(movedWorkspaces) => {
                            let workspacesCopy = [...workSpaces];
                            for (let movedWorkspace of movedWorkspaces) {
                                let indexOfMovedWorkspace = workSpaces.findIndex(w => w._id === movedWorkspace._id);
                                if (indexOfMovedWorkspace !== -1 && movedWorkspace?.organization?._id !== activeOrganization?._id) {
                                    workspacesCopy.splice(indexOfMovedWorkspace, 1);
                                }
                            }
                            setWorkSpaces(workspacesCopy);
                        }}
                    />
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowCount={workSpaces.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                    <Flex className={'mt-1'} direction={'column'}>
                        <hr style={{ display: orgsCountOutSideActive ? 'inherit' : 'none' }} />
                        <span
                            onClick={() => {
                                setOpenOrganizationSettingsPanel(true);
                            }}
                            className='fs--1 cursor-pointer text-900 mb-2'
                            style={{
                                display: orgsCountOutSideActive ? 'inherit' : 'none'
                            }}>
                            {t('view_workspaces_outside_current_organization', { n: orgsCountOutSideActive, count: orgsCountOutSideActive })}
                        </span>
                    </Flex>
                </Card.Footer>
            </Card>
            <Modal
                centered
                size='lg'
                show={openOrganizationSettingsPanel}
                onHide={() => setOpenOrganizationSettingsPanel(false)}
                onExit={() => setOpenOrganizationSettingsPanel(false)}
            >
                <Modal.Header closeVariant={isDark ? 'white' : 'dark'} closeButton>
                    <Modal.Title>
                        <h5>
                            {t('organization_settings')}
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OrganizationSettingsPanel initialPanel='workspaces' />
                </Modal.Body>
            </Modal>
        </AdvanceTableWrapper>
    );
};

export default WorkSpaceSearch;
