import React from 'react'
import { Button, Card, Spinner } from 'react-bootstrap'
import Flex from '../../../common/Flex'
import { HiMenuAlt3 } from "react-icons/hi";
import { IoChatbubble } from "react-icons/io5";
import { GrFormNextLink } from "react-icons/gr";
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'constants';


const ChatSideArea = (props) => {
    const navigate = useNavigate()
    const { savedConversations, handleLoadConversation, usedChars, userHasUnlimitedAI, maxChars, openMessage, handleNewChat, checkingAICredit } = props;

    const trimWordsIntoNcharacters = (word, numOfChars) => {
        if (word.length > numOfChars) {
            return word.substring(0, numOfChars) + "..."
        } else {
            return word
        }
    }

    const displaySavedConversations = () => {
        return savedConversations.map((conversation) => (
            <Flex
                gap={2}
                key={conversation._id}
                onClick={() => handleLoadConversation(conversation?._id)}
                style={{
                    cursor: 'pointer'
                }}
                className={'fs--1'}
            >
                {trimWordsIntoNcharacters(conversation.topic, 30)}
            </Flex>
        ))

    }

    return (
        <Flex
            direction={'column'}
            style={{
                width: '30%'
            }}
        >
            <Card
                style={{
                    height: '86vh',
                    maxHeight: '100vh',
                }}
                className="p-2 pt-3"
            >
                <Flex
                    gap={1}
                >
                    <Button
                        size='sm'
                        variant={'primary'}
                        style={{
                            width: '90%'
                        }}
                        onClick={handleNewChat}
                    >
                        <IoChatbubble />  New chat
                    </Button>
                    <Button
                        size='sm'
                        variant={'primary'}
                    >
                        <HiMenuAlt3 />
                    </Button>
                </Flex>
                <hr />
                <Flex
                    direction={'column'}
                    gap={3}
                    style={{
                        height: '50vh',
                        overflowY: 'auto'
                    }}
                >
                    {displaySavedConversations()}
                </Flex>
                <hr />
                <Flex
                    direction={'column'}
                    gap={3}
                    style={{
                        height: '20vh'
                    }}
                >
                    <Flex
                        gap={2}
                    >
                        <p
                            className="fs--1 ml-2"
                        >
                            AI credit: {checkingAICredit ? <Spinner animation='border' size={15} /> : <span
                                className="ml-2"
                            >{usedChars} / {userHasUnlimitedAI ? "∞" : maxChars}</span>
                            }
                        </p>
                    </Flex>
                    <Flex
                        gap={2}
                        justifyContent={'between'}
                    >
                        <Button
                            size='sm'
                            // variant={'light'}
                            onClick={() => {
                                navigate(`${RoutePaths.BILLING_STRIPE}#AISection001`);
                            }}
                        >
                            Upgrade to Unlimited AI
                        </Button>
                    </Flex>
                    <Flex
                        gap={2}
                        justifyContent={'between'}
                    >
                        <Button
                            size='sm'
                            // variant={'light'}
                            onClick={() => {
                                openMessage()
                            }}
                        >
                            Enter your API Key
                        </Button>
                    </Flex>
                </Flex>
            </Card>
        </Flex>
    )
}

export default ChatSideArea