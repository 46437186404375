/* eslint-disable array-callback-return */
import { PlatformIdentifier } from 'constants';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserTags } from 'redux/slices/postslice';
import TaggingPop from '../../TaggingPop';
import VisibleTip from './VisibleTip';
import { v4 as uuid } from 'uuid';

const ImageEditor = ({ src, areas, imageUrl, setCanUpdateTags, isVideo }) => {
    const dispatch = useDispatch()
    const imageRef = useRef(null);
    const [openPop, setOpenPop] = useState(false);
    const [currentOffset, setCurrentOffset] = useState({ x: 0, y: 0 });
    const [value, setValue] = useState('');

    const handleImageClick = (event) => {
        const { offsetX, offsetY } = event.nativeEvent;
        const newArea = calculateNewArea(offsetX, offsetY);
        setOpenPop(true);
        setCurrentOffset({ x: newArea.x, y: newArea.y, id: newArea.id, imageUrl: newArea.imageUrl });
    };

    const calculateNewArea = (x, y) => {
        const imageWidth = imageRef.current.width;
        const imageHeight = imageRef.current.height;
        const normalizedX = x / imageWidth;
        const normalizedY = y / imageHeight;

        return {
            id: uuid(),
            x: normalizedX,
            y: normalizedY,
            username: new Date().getTime(),
            imageUrl: imageUrl,
        };
    };

    const handleRemoveTag = (id) => {
        const newAreas = areas.filter((area) => area.id !== id);
        setCanUpdateTags(true);
        dispatch(setUserTags(newAreas));
    };

    const handleUpdateTextFromEditBox = (text, cancel) => {
        let newTag = {
            ...currentOffset,
            username: text,
        }
        if (cancel) {
            setOpenPop(false);
            return;
        }
        setCanUpdateTags(true);
        dispatch(setUserTags([...areas, newTag]))
        setOpenPop(false);
    }

    const popOutPopOver = (
        <div
            style={{
                position: 'absolute',
                left: `${currentOffset.x * 100}%`,
                top: `${currentOffset.y * 100}%`,
                zIndex: 100,
            }}
        >
            <TaggingPop
                setPopDropClose={setOpenPop}
                platform={PlatformIdentifier.INSTAGRAM}
                value={value}
                setValue={setValue}
                setUpdateDirectly={handleUpdateTextFromEditBox}
                fromImageTag={true}
            />
        </div>
    )

    return (
        <div style={{ position: 'relative', cursor: !isVideo && 'crosshair', width: '100%', height: '100%' }}>
            {
                !isVideo ?
                    <img ref={imageRef} src={src} alt="images" onClick={handleImageClick}
                        style={{
                            maxWidth: 470,
                            maxHeight: 470,
                        }}
                    /> : <video ref={imageRef} src={src} alt="images" controls style={{ maxWidth: 470, maxHeight: 470 }} />
            }
            {openPop === true ? popOutPopOver : null}
            {areas.map((area, index) => {
                if (imageUrl === area.imageUrl && !isVideo) {
                    return <VisibleTip
                        imageUrl={imageUrl}
                        key={index}
                        left={`${area.x * 100}%`}
                        top={`${area.y * 100}%`}
                        id={area.id}
                        handleClick={() => handleRemoveTag(area.id)}
                        username={area.username}
                        _imageUrl={area.imageUrl}
                    />
                }
            })}
        </div>
    );
};

export default ImageEditor;
