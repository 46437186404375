import Flex from "components/common/Flex";
import APIService from "http/api_service";
import React, { useContext, useEffect, useState } from "react";
import { Card, Spinner, Tab, Tabs } from "react-bootstrap";
// import { IoMdSettings } from "react-icons/io";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { useSelector } from "react-redux";
// import { GrConfigure } from "react-icons/gr";
import QRCodeComponent from "./qr_code/QRCodeComponent";
// import FreePageHeader from "components/common/FreePageHeader";
import CodeLists from "./qr_code/CodeLists";
import AppContext from "context/Context";
import useProtectedRoute from "hooks/useProtectedRoute";
import { RoutePaths } from "constants";

const Qrcode = () => {
  const [tab, setTab] = useState(1);
  const [workSpaceOwner, setWorkspaceOwner] = useState();
  const workSpace = useSelector(getActiveWorkSpace);
  const { config } = useContext(AppContext);
  const { status } = useProtectedRoute(['free'], `${RoutePaths.BILLING_STRIPE}/#schedulerSection001`)


  const fetchWorkspaceOwner = () => {
    APIService.fetchWorkSpaceOwner(workSpace._id, (response, error) => {
      if (response) {
        let { data } = response;
        setWorkspaceOwner(data);
      }
    });
  };

  useEffect(() => {
    fetchWorkspaceOwner();
  }, []);

  const TabComponent = () => {
    switch (tab) {
      case 1:
        return <QRCodeComponent />;
      case 2:
        return <CodeLists />;
      default:
        return;
    }
  };
  return (
    <>
      <Flex direction={"column"}>
        {!workSpaceOwner && (
          <Flex
            alignItems={"center"}
            style={{
              height: "100vh",
            }}
            justifyContent={"center"}
          >
            <Spinner animation="border" />
          </Flex>
        )}
        {workSpaceOwner && (
        <>
        <Flex
          className={"px-2 pt-2"}
          style={{ background: config.isDark ? "transparent" : "white" }}
          direction={"column"}
          >
          <Flex alignItems={"center"} className={"ps-3 mb-3"}>
          QRcode Generator
          </Flex>
          <Flex
            alignItems={"center"}
            style={{
              // paddingBottom: 2,
              borderBottom: ".5px solid #B0BEC5",
            }}
          >
            <Tabs
              activeKey={tab}
              id="uncontrolled-tab-example"
              transition={true}
              // className="mb-3"
              onSelect={(tab) => {
                setTab(Number(tab));
              }}
            >
              <Tab eventKey={1} title="Create code" />
              <Tab eventKey={2} title="Code Lists" />
            </Tabs>
          </Flex>
        </Flex>
        <Card
          className="mt-2"
          style={{
            minHeight: "80vh",
          }}
        >
          <div>{TabComponent()}</div>
        </Card>
        </>
        )}
      </Flex>
    </>
  );
};

export default Qrcode;
