import { RoutePaths } from 'constants';
import APIService from 'http/api_service';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAltUsers, setAltUsers, setLoggedInUser } from 'redux/slices/user_slice';
import { useDispatch, useSelector } from 'react-redux';

const EmailVerificationForm = ({ hasLabel, formData }) => {

    const dispatch = useDispatch();
    let altUsers = useSelector(getAltUsers);
    const [processing, setProcessing] = useState(false);
    const navigate = useNavigate();
    const [code, setCode] = useState('');
    const [requestInProcess, setRequestInProcess] = useState(false);

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const handleConfirmCodeForEmailVerification = () => {
        if (!code) {
            toast.error('Please enter the code we sent to you', { theme: 'colored' });
            return
        }
        if (!formData?.email) {
            toast.error('Sorry, something went wrong.', { theme: 'colored' }); //this is a fallback that should never happen but just in case
            return
        }
        let requestBody = {
            email: formData?.email,
            code: code
        }
        APIService.confirmCodeForEmailVerification(requestBody, 1, (response, error) => {
            if (error) {
                setProcessing(false);
                toast.error(error, { theme: 'colored' });
                return;
            }
            APIService.createAccountManually(formData.email, formData.password, formData.name, (response, error) => {
                if (error) {
                    setProcessing(false);
                    toast.error(error, {
                        theme: 'colored'
                    });
                    return;
                }
                let userData = response['data'];
                localStorage.clear();
                dispatch(setLoggedInUser({ ...userData }));
                setTimeout(() => {
                    dispatch(setAltUsers([...altUsers]));
                    setProcessing(false);
                    navigate(RoutePaths.DEFAULT);
                }, 200);
            });
        });
    }

    const handleSendVerificationEmail = () => {
        if (!formData?.email) {
            toast.error('Please enter your email address', { theme: 'colored' });
            return
        }
        if (!validateEmail(formData?.email)) {
            toast.error('Please enter a valid email address', { theme: 'colored' });
            return
        }
        let requestBody = {
            email: formData?.email
        }
        setRequestInProcess(true);
        APIService.requestCodeForEmailVerification(requestBody, 1, (response, error) => {
            if (error) {
                setRequestInProcess(false);
                toast.error(error, { theme: 'colored' });
                return;
            }
            setRequestInProcess(false);
            toast.success(response.message, { theme: 'colored' });
        });
    }

    const handleSubmit = e => {
        e.preventDefault();
        setProcessing(true);
        handleConfirmCodeForEmailVerification()
    }

    const handleResendCode = e => {
        e.preventDefault();
        setRequestInProcess(true);
        handleSendVerificationEmail()
    }

    return (
        <Form>
            <Form.Group className="mb-2">
                {hasLabel && <Form.Label>Verification Code</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? 'Please Enter code here' : ''}
                    value={code}
                    name="email"
                    onChange={(e) => setCode(e.target.value)}
                    type="text"
                />
            </Form.Group>
            <Form.Group>
                {/* <Flex alignItems="center" justifyContent="between" gap="10px"> */}
                <Button
                    onClick={handleSubmit}
                    type="submit"
                    color="outlined"
                    className="mt-3"
                    style={{ width: "100%" }}
                    disabled={!code || processing}
                >
                    {processing ? 'Please wait...' : 'Confirm Email'}
                </Button>
                <Button
                    onClick={handleResendCode}
                    type="submit"
                    variant='outline-primary'
                    className="mt-3"
                    style={{ width: "100%" }}
                    disabled={requestInProcess}
                >
                    {requestInProcess ? 'Please wait...' : 'Resend Code'}
                </Button>
                {/* </Flex> */}
            </Form.Group>
        </Form>
    );
}

export default EmailVerificationForm