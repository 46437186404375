import LocalFileSelectionButton from "components/common/LocalFileSelectionButton";
import React from "react";
import { MediaCart, SelectInput } from "./addMediapopUpStyle";
import { useRef } from "react";
import { useDispatch } from 'react-redux';
import { setInstagramVideoThumbnail, setPinterestVideoThumbnail, setShowMediaOptions, setWordpressFeaturedImageThumbnail, setYouTubeVideoThumbnail } from "redux/slices/postslice";
import { PlatformIdentifier } from "constants";
import { Dropdown } from "react-bootstrap";
import { useState } from "react";
import { useDropzone } from 'react-dropzone';
import { BsFileEarmarkArrowUp } from "react-icons/bs";
import { convertImageFile, dataURLToFile, getImageDimensionsFromFile, processAutoFixMedia } from "utils/media";


const LocalStoragePicker = (props) => {
    const [localMedia, setLocalMedia] = useState(props?.fileType)
    const dispatch = useDispatch()
    const selectRef = useRef();
    const {
        // fileType = "image",
        multiple = false,
        fileSelectionHandler,
        platformIdentifier,
        thumbnail = false,
        initiatorId,
    } = props;
    const onDrop = async(acceptedFiles) => {
        // do something with the dropped files
        let files = acceptedFiles;
        let newFiles = [];
        for (const file of files) {
            if (!file.type.includes('image')) {
                newFiles.push(file);
                continue;
            }
            if (file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'image/jpg') {
                const newFile = await convertImageFile(file, 'image/png');
                newFiles.push(newFile);
                continue;
            }
            newFiles.push(file);
        }
        files = newFiles;
        let responseData = {
            files,
            from_local: true,
            is_video: props?.fileType === "video"
        };
        if (thumbnail) {
            let file = files[0];
            let fileUrl = URL.createObjectURL(file);
            let newThumbObject = {
                name: file.name,
                url: fileUrl,
                from_local: true,
                extension: ".png",
                file: file,
                thumbnail: true
            };
            newThumbObject[`thumbnail_for_${platformIdentifier}`] = true;
            if (platformIdentifier === PlatformIdentifier.YOUTUBE) {
                dispatch(setYouTubeVideoThumbnail(newThumbObject));
            } else if (platformIdentifier === PlatformIdentifier.PINTEREST) {
                dispatch(setPinterestVideoThumbnail(newThumbObject));
            } else if (platformIdentifier === PlatformIdentifier.INSTAGRAM) {
                dispatch(setInstagramVideoThumbnail(newThumbObject));
            }
            else if (platformIdentifier === PlatformIdentifier.WORDPRESS) {
                dispatch(setWordpressFeaturedImageThumbnail(newThumbObject))
            }
        } else {
            fileSelectionHandler(responseData);
        }
        dispatch(setShowMediaOptions(false))
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    return (
        <>
            <label htmlFor={`${props?.fileType}-${initiatorId}-local-file-selection-button`}>
                <SelectInput
                    {...getInputProps()}
                    id={`${props?.fileType}-${initiatorId}-local-file-selection-button`}
                    accept={`${props?.fileType}/*`}
                    multiple={multiple}
                    type="file"
                    ref={selectRef}
                    onChange={async (e) => {
                        let files = e.target.files;
                        // perform low level functions here on images....
                        let newFiles = [];
                        for (const file of files) {
                            if (!file.type.includes('image')) {
                                newFiles.push(file);
                                continue;
                            }
                            if (file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'image/jpg') {
                                const newFile = await convertImageFile(file, 'image/png');
                                newFiles.push(newFile);
                                continue;
                            }
                            newFiles.push(file);
                        }
                        files = newFiles;
                        let responseData = {
                            files,
                            from_local: true,
                            is_video: props?.fileType === "video"
                        };
                        if (thumbnail) {
                            let file = files[0];
                            let fileUrl = URL.createObjectURL(file);
                            let newThumbObject = {
                                name: file.name,
                                url: fileUrl,
                                from_local: true,
                                extension: ".png",
                                file: file,
                                thumbnail: true
                            };
                            newThumbObject[`thumbnail_for_${platformIdentifier}`] = true;
                            if (platformIdentifier === PlatformIdentifier.YOUTUBE) {
                                dispatch(setYouTubeVideoThumbnail(newThumbObject));
                            } else if (platformIdentifier === PlatformIdentifier.PINTEREST) {
                                dispatch(setPinterestVideoThumbnail(newThumbObject));
                            } else if (platformIdentifier === PlatformIdentifier.INSTAGRAM) {
                                dispatch(setInstagramVideoThumbnail(newThumbObject));
                            }
                            else if (platformIdentifier === PlatformIdentifier.WORDPRESS) {
                                dispatch(setWordpressFeaturedImageThumbnail(newThumbObject))
                            }
                        } else {
                            fileSelectionHandler(responseData);
                        }
                        dispatch(setShowMediaOptions(false))
                    }}
                />
            </label>
            <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                <span style={{ fontSize: "13px", color: "inherit" }}>{"Upload and attach file from your device"}</span>
            </div>
            <div
                {...getRootProps()}
                onClick={() => selectRef.current.click()}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "15vh",
                    backgroundColor: "inherit",
                    borderRadius: "10px",
                    border: "dashed 3px #ebecf0",
                    cursor: "pointer",
                    marginTop: "10px"
                }}
            >
                <span style={{
                    fontSize: "13px", display: "flex",
                    justifyContent: "center",
                    alignItems: "center", gap: "10px"
                }}><BsFileEarmarkArrowUp size={20} /><span style={{ borderBottom: "2px solid #5e6e82", fontSize: "14px" }}>Click to upload</span> {" "}or drag and drop{``}</span>
            </div>
        </>
    );
};

export default LocalStoragePicker;
