/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import AppContext from "context/Context";
import React, { useContext, useEffect, useRef, useState } from "react";
import "./addOnsStyles.css";
import { scanImages } from "./scanImages";

const ThreadCarousel = ({
  ImageDelete,
  handleDeleteMedia,
  faRemove,
  FontAwesomeIcon,
  PostImage,
  max_min_imageSize,
  getImageDimension,
  PlayMediaInstagram,
  useableMedia,
  openVideoEditArea,
  setOpenVideoEditArea,
  viewOnly,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [minMaxSize, setMinMaxSize] = useState("auto");
  const firstFileRef = useRef(null);
  const [faulty, setFaulty] = useState(false);
  const [dHeight, setDHeight] = useState(0);
  const [dWidth, setDWidth] = useState(0);

  const { config } = useContext(AppContext);

  let editVideoFormat = `Instagram videos supports MP4 and MOV format`;

  const checkValidity = async () => {
    try {
      const imagesMedia = useableMedia.filter((x) => x.is_video == false);
      await scanImages(imagesMedia);
      const { width, height } = await getImageDimension(
        useableMedia[currentIndex]?.url
      );
      setDHeight(height);
      setDWidth(width);
      if (
        !useableMedia[currentIndex]?.external &&
        (width < 1080 || height < 566)
      ) {
        setFaulty(true);
        return;
      }
      setFaulty(false)
    } catch (error) {
    }
  }

  useEffect(() => {
    checkValidity();
  }, [currentIndex]);
  useEffect(() => {
    dHeight == 0 && checkValidity();
  }, [dHeight]);
  useEffect(() => {
    checkValidity();
  }, [useableMedia]);

  useEffect(() => {
    if (useableMedia[0]?.is_video === true && useableMedia.length > 1) {
      setMinMaxSize((prevState) => (prevState = 400));
      return;
    }
    if (firstFileRef?.current?.height > 0) {
      setMinMaxSize((prevState) => (prevState = firstFileRef?.current?.height));
    }
  }, [firstFileRef?.current?.height]);

  const goPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    if (isFirstSlide) {
      return;
    }
    setCurrentIndex((prev) => prev - 1);
  };

  const goNext = () => {
    const lastSlide = currentIndex >= useableMedia.length - 1;
    if (lastSlide) {
      return;
    }
    setCurrentIndex((prev) => prev + 1);
  };

  if (!useableMedia.length) {
    return <div>No media</div>;
  }

  return (
    <>
      {
        !viewOnly &&
        <ImageDelete
          editBgColor={!config.isDark ? "#e0e0e0" : "#0b1727"}
          onClick={(e) => handleDeleteMedia(useableMedia[currentIndex])}
        >
          <FontAwesomeIcon icon={faRemove} />
        </ImageDelete>
      }
      <div style={{ zIndex: 10 }} onClick={goNext} class="swiper-button-next">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="#f5f5f5"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.568 18.005l-1.414-1.415 4.574-4.59-4.574-4.579 1.414-1.416 5.988 5.995-5.988 6.005z" />
        </svg>
      </div>
      {useableMedia[currentIndex]?.is_video === false ? (
        <div style={{ position: "relative", width: "auto", height: "auto" }}>
          <div
            style={{
              position: "absolute",
              top: 5,
              left: 10,
              backgroundColor: "black",
              fontSize: 9,
              color: "white",
              height: "fit-content",
              width: "fit-content",
              borderRadius: "100%",
              padding: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {currentIndex + 1}/{useableMedia.length}
          </div>
          <div style={{ position: "relative", zIndex: 2 }}></div>
          <PostImage
            ref={currentIndex === 0 ? firstFileRef : null}
            className={`${currentIndex === 0 && "firstImage"}`}
            style={{
              // maxHeight: `${minMaxSize}px`,
              // minHeight: `${minMaxSize}px`,
            }}
            src={useableMedia[currentIndex]?.url}
            alt="post preview"
          ></PostImage>
        </div>
      ) : (
        <PlayMediaInstagram
          key={currentIndex}
          url={useableMedia[currentIndex]?.url}
          maximunHeight={max_min_imageSize > 0 && max_min_imageSize}
          indexer={currentIndex}
          faultyVideo={true}
          editVideoFormat={editVideoFormat}
          videoHeightLimit={1199}
          videoWidthLimit={1079}
          external={useableMedia[currentIndex]?.external}
          file={useableMedia[currentIndex]?.file}
          openVideoEditArea={openVideoEditArea}
          setOpenVideoEditArea={setOpenVideoEditArea}
          viewOnly={viewOnly}
        />
      )}
      <div onClick={goPrevious} class="swiper-button-prev">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="#f5f5f5"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12-5.373-12-12-12-12 5.373-12 12zm7.58 0l5.988-5.995 1.414 1.416-4.574 4.579 4.574 4.59-1.414 1.416-5.988-6.006z" />
        </svg>
      </div>
    </>
  );
};

export default ThreadCarousel;
