import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Buffer } from "buffer";
import loadable from "@loadable/component";
import Flex from "components/common/Flex";
import { Spinner } from "react-bootstrap";
import ConnectedPlatformInfoHeader from "./connected_platform_info_header";
import { getRedirectBack, setRedirectBack } from "redux/slices/platformSlice";
import { useSelector, useDispatch } from "react-redux";

const LoadConnectedAccountPages = (account, reconnectionOngoing) => {
  let Page = loadable(() => import(`../${account.target}`), {
    fallback: <Spinner animation="border" size={20} />,
  });
  return (
    <>
      <Page
        reconnectionOngoing={reconnectionOngoing}
        platform={account}
      />
    </>
  );
};

export default function ConnectedAccountDataPage() {
  let { accountInfo } = useParams();
  const [reconnectionOngoing, setReconnectionOngoing] = useState(false)
  let platform;
  const redirectBack = useSelector(getRedirectBack);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (redirectBack) {
      dispatch(setRedirectBack(false))
      setTimeout(() => {
        navigate(-1)
      }, 200)
    }
  }, [redirectBack])


  try {
    let hexString = Buffer.from(accountInfo, "hex").toString();
    platform = JSON.parse(hexString);
  } catch (e) { }

  return (
    <>
      {platform && (
        <Flex direction={"column"}>
          <ConnectedPlatformInfoHeader setReconnectionOngoing={setReconnectionOngoing} platform={platform} />
          <Flex alignItems={"center"} direction={"column"}>
            {LoadConnectedAccountPages(platform, reconnectionOngoing)}
          </Flex>
        </Flex>
      )}
    </>
  );
}
