import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RoutePaths } from "constants";
import React, { useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPublishablePostTextBody } from "redux/slices/post_text_body_slice";
import {
  setArticleTitleSelect,
  setComposerFocused,
  setOpenDraftPrompt,
  setRSSFeedTriggered,
} from "redux/slices/postslice";
import { FeedContentFooter } from "./styles/feed";

const FeedContent = (props) => {
  const { feed, setFeedHasImage } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [feedContent, setFeedContent] = useState();

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };
  useEffect(() => {
    if (feed?.content) {
      const extractContentImage = (htmlString, defaultHeight) => {
        let image, textAfterImage;
        const imageRegex = /<img.*?src=["'](.*?)["'].*?>/g;
        const match = imageRegex.exec(htmlString);
        if (match) {
          const imageUrl = match[1];
          image = `<img src="${imageUrl}" width="100%" height="${defaultHeight}" />`;
          textAfterImage = htmlString.slice(match.index + match[0].length);
        } else {
          image = null;
          textAfterImage = truncateText(htmlString, 100);
        }
        return {
          image,
          textAfterImage,
        };
      };
      const { image, textAfterImage } = extractContentImage(feed?.content, 150);
      if (image) {
        setFeedContent(image);
        setFeedHasImage(true); 
      } else {
        setFeedHasImage(false);
        setFeedContent(truncateText(textAfterImage, 100));
      }
    }
  }, [feed?.content]);

  return (
    <>
      <Card style={{ height: "400px" }}>
        <Card.Body>
          <Card.Title>
            <Card.Link href={feed?.link} target="_blank" rel="noopener" 
            style={{ fontSize: '15px' }}>
              {truncateText(feed?.title, 50)}
            </Card.Link>
          </Card.Title>
          <Card.Subtitle className="my-2 text-muted">
            {feed?.publishedDate}
          </Card.Subtitle>
          <Card.Text>
            <div
              dangerouslySetInnerHTML={{ __html: feed?.content && feedContent }}
            ></div>
          </Card.Text>
          <FeedContentFooter>
            <Button
              variant="outline-primary"
              className="rounded-pill me-1 mb-1"
              style={{
                minWidth: 50,
              }}
              onClick={() => {
                dispatch(setComposerFocused(true));
                dispatch(
                  setPublishablePostTextBody(`${feed?.title}\n\n${feed?.link}`)
                );
                navigate(RoutePaths.POSTS);
                dispatch(setOpenDraftPrompt(false));
                dispatch(setArticleTitleSelect(feed?.title));
                dispatch(setRSSFeedTriggered(true));
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 5,
                }}
              >
                <FontAwesomeIcon icon={faLocationArrow} />
                <span>Post</span>
              </div>
            </Button>
          </FeedContentFooter>
        </Card.Body>
      </Card>
    </>
  );
};

export default FeedContent;
