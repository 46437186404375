import Flex from 'components/common/Flex';
import Lottie from 'lottie-react';
import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap';
import OneInbox from 'assets/animations/one_inbox.json';

const InstaPostsSetup = ({ connectAccounts }) => {
  return (
    <Card.Body
    className="text-center py-1" >
    <Row className="justify-content-center py-2">
        <Col xs={7} md={5}>
            <Lottie animationData={OneInbox} />
        </Col>
    </Row>
    <h3 className="mt-3 fw-normal fs-2 mt-md-4 fs-md-3">
        One Inbox Instagram, Unlimited posts and comments!
    </h3>
    <p className="lead mb-3">
        View all your posts on Instagram<br /> using a single Inbox
    </p>
    <Card.Text style={{textAlign: "center"}}>No result found</Card.Text>
    {/* <Flex alignItems={'center'} justifyContent={'center'}>
        <Button
            variant="primary"
            onClick={() => {
                connectAccounts();
            }}>
            Get Started
        </Button>
    </Flex> */}
</Card.Body>
  )
}

export default InstaPostsSetup