import React from 'react'
import { Button, Card, Modal, Nav, Row, Tab } from 'react-bootstrap'
import { Container, DashboardContainer, Headings, LeftNav, NavArea, RightNav } from './styles/dashboard'
import GoogleDriveIcon from '../../../assets/images/driveImg.png'
import { AiOutlineClose } from 'react-icons/ai'
import DebounceableEditBox from '../../app/social/feed/debounceable_edit_box'
import Folders from './folders'
import { getGoogleDriveManager, setGoogleDriveManager } from 'redux/slices/postslice'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import APIService from 'http/api_service'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { BsArrowLeft } from 'react-icons/bs'
import ReactLoading from 'react-loading';
import ConfirmModal from '../ConfirmModal'
import Flex from '../Flex'
import { getActiveWorkSpace } from 'redux/slices/workspaceslice'
import { getLoggedInUser, setLoggedInUser } from 'redux/slices/user_slice'

const GDriveDashboard = (props) => {
   const { platform } = props;
   const dispatch = useDispatch();
   const googleDriveManager = useSelector(getGoogleDriveManager);
   const [idCallStacks, setIdCallStacks] = useState([])
   const [loading, setLoading] = useState(false)
   const workSpace = useSelector(getActiveWorkSpace);
   const workSpaceId = workSpace._id;
   const [openDisconnectModal, setOpenDisconnectModal] = useState(false);
   const [disconnectBtn, setDisconnectBtn] = useState('Disconnect Google Drive');
   const [currentView, setCurrentView] = useState('Grid View');
   const loggedInUser = useSelector(getLoggedInUser);

   const goBack = async () => {
      //get last item of idCallStacks
      let q;
      let _idCallStacks = [...idCallStacks];
      _idCallStacks.pop();
      setIdCallStacks(_idCallStacks);
      if (_idCallStacks.length === 0) {
         q = `mimeType='application/vnd.google-apps.folder' and 'root' in parents`
      }
      else {
         let lastId = _idCallStacks[_idCallStacks.length - 1];
         q = `'${lastId}' in parents and trashed=false`
      }

      let requestBody = {
         google_drive_access_token: loggedInUser['google_drive_access_token'],
         google_drive_refresh_token: loggedInUser['google_drive_refresh_token'],
         google_drive_access_token_expires_at: loggedInUser['google_drive_access_token_expires_at'],
         q
      }
      setLoading(true);
      APIService.getGoogleDriveMedias(workSpaceId, requestBody, (response, error) => {
         if (error) {
            toast.error(error, { theme: 'colored' });
            setLoading(false);
            return;
         }
         dispatch(setGoogleDriveManager({
            ...googleDriveManager, openPicker: true, folders: response?.data?.files, folderNextPageToken: response?.data?.nextPageToken
         }));
         //  remove last item of idCallStacks
         setLoading(false);
         // toast.success('Images fetched successfully', { theme: 'colored' })
      });
   }

   const handleCloseEvent = () => {
      dispatch(setGoogleDriveManager({ ...googleDriveManager, openPicker: false }))
   }

   const handleDisconnect = () => {
      setDisconnectBtn('Disconnecting...');
      APIService.disconnectGDrive(workSpaceId, { disconnect: true }, (response, error) => {
         if (error) {
            setDisconnectBtn('Disconnect Google Drive');
            toast.error(error, { theme: 'colored' });
            return;
         }
         APIService.fetchLatestMe((latestMeResponse, latestMeError) => {
            if (latestMeError) {
               toast.success('Your google drive is now disconnected', { theme: 'colored' })
               handleCloseEvent()
               return;
            }
            let { data } = latestMeResponse;
            dispatch(setLoggedInUser({ ...data }));
            setTimeout(() => {
               toast.success('Your google drive is now disconnected', { theme: 'colored' })
               handleCloseEvent()
            }, 1000);
         });
      });
   }

   const switcher = (
      <Tab.Container
         id="google_drive_switcher"
         activeKey={currentView}
         onSelect={(e) => {
            setCurrentView(e);
         }}>
         <Row className={'px-3'} style={{ width: 'auto' }}>
            <Nav variant="pills" className="nav-pills-falcon m-0">
               <Nav.Item>
                  <Nav.Link as={Button} size="sm" eventKey="Grid View">
                     Grid View
                  </Nav.Link>
               </Nav.Item>
               <Nav.Item>
                  <Nav.Link as={Button} size="sm" eventKey="List View">
                     List View
                  </Nav.Link>
               </Nav.Item>

            </Nav>
         </Row>
      </Tab.Container>
   )

   return (
      <>
         <Card
            style={{
               position: 'fixed',
               top: 0,
               left: 0,
               width: '100%',
               height: '100%',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               // cursor: 'pointer',
               zIndex: 9999,
               // ...style
            }}
         >
            <DashboardContainer>
               <NavArea>
                  <LeftNav>
                     <Headings>
                        <BsArrowLeft onClick={goBack} />
                        <img

                           src={GoogleDriveIcon}
                           style={{ width: 23, height: 23 }}
                           alt="Google Drive Icon From Postly"
                        />
                        Google Drive
                        {switcher}
                     </Headings>
                     {/* <MediaTypes/> */}
                  </LeftNav>
                  {/* <DebounceableEditBox
                     // title={'Public Account name or Username'}
                     type='text'
                     placeholder={"Search folders..."}
                     // onKeyDown={(e) => e.key === 'Enter' && handleSave()}
                     onChange={(value) => null}
                     style={{
                        width: 300,
                        marginTop: -20
                     }}
                  /> */}
                  <RightNav>
                     <Button
                        className="btn-primary"
                        style={{ fontSize: 15, whiteSpace: "nowrap", marginTop: -6 }}
                        onClick={() => handleDisconnect()}
                     // onClick={() => setOpenDisconnectModal(true)}
                     >
                        {disconnectBtn}
                     </Button>
                     <AiOutlineClose onClick={handleCloseEvent} style={{ cursor: 'pointer' }} size={23} />
                  </RightNav>
               </NavArea>
               {
                  googleDriveManager?.fetchLoading ?
                     <ReactLoading type={'bubbles'} color={'#0052cc'} /> :
               // <p>{ googleDriveManager?.fileType}</p>
                     <Folders
                        currentView={currentView}
                        platform={platform}
                        googleDriveManager={googleDriveManager}
                        setIdCallStacks={setIdCallStacks}
                        idCallStacks={idCallStacks}
                        loading={loading}
                        setLoading={setLoading}
                     />
               }
            </DashboardContainer>
         </Card>

      </>
   )
}

export default GDriveDashboard