export default class FigureUtils {
    static convertBytes(bytes) {
        let result;
        if (bytes >= 1024 * 1024 * 1024) {
            result = {
                value: `${(bytes / (1024 * 1024 * 1024)).toFixed(2)} GB`,
                unit: 'GB'
            };
        } else if (bytes >= 1024 * 1024) {
            result = {
                value: `${(bytes / (1024 * 1024)).toFixed(2)} mb`,
                unit: 'mb'
            }
        } else if (bytes >= 1024) {
            result = {
                value: `${(bytes / 1024).toFixed(2)} kb`,
                unit: 'kb'
            }
        } else {
            result = {
                value: `${bytes} bytes`,
                unit: 'bytes'
            }
        }
        return result;
    }

    static isVideoSizeValidForUpload(){
        
    }
}