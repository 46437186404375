import styled from "styled-components";

export const MainContainer = styled.div`
  background-color: #202023;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  z-index: 10;
`;

export const VideoEditorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const VideoEditorToolsContainer = styled.div`
  display: flex;
  flex: 4;
  border-right: 2.5px solid #121212;
  padding: 0.2rem 0;
  color: hsla(0, 0%, 100%, 0.6);
  border: none;
  height: 100vh;
`;
export const VideoEditorTools = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  height: auto;
  padding-top: 10px;
  border-right: 2.5px solid #121212;
`;

export const VideoMediaTools = styled.div`
  padding: 10px;
  width: 80%;
  border-right: 2px solid #121212;
  color: #ffffff;
`;

export const VideoEditorToolBox = styled.div`
  width: 80px;
  padding: 10px 0;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  color: #ffffff
  font-weight: 800;
  cursor: pointer;
`;

export const VideoPlayerContainer = styled.div`
  flex: 6;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100vh;
  margin-top: 1.5rem;
`;

export const VideoTimelineContainer = styled.div`
  width: 100%;
  padding: 10px;
  margin: 0 1.5rem;
`;
