import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    setYouTubeVideoThumbnail,
    setPinterestVideoThumbnail,
    setInstagramVideoThumbnail,
    setWordpressFeaturedImageThumbnail
} from '../../redux/slices/postslice';
import { useDispatch } from 'react-redux';
import { PlatformIdentifier } from '../../constants';

export default function LocalFileSelectionButton(props) {

    const dispatch = useDispatch();

    const {
        fileType = "image",
        multiple = false,
        fileSelectionHandler,
        platformIdentifier,
        thumbnail = false,
        initiatorId
    } = props;

    return (
        <label
            htmlFor={`${fileType}-${initiatorId}-local-file-selection-button`}
            style={{
                display: 'inline-flex',
                gap: 15,
                minWidth: 170,
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
            }}>
            <div
                style={{
                    width: 30,
                    height: 30,
                    borderRadius: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                <FontAwesomeIcon
                    size='sm'
                    icon={'laptop'}
                />
            </div>
            <span
                style={{
                    flex: 1,
                    textAlign: 'start',
                    marginLeft: 2,
                }}>Local Computer
            </span>
            <input
                id={`${fileType}-${initiatorId}-local-file-selection-button`}
                style={{
                    display: 'none'
                }}
                accept={`${fileType}/*`}
                multiple={multiple}
                type="file"
                onChange={(e) => {
                    let files = e.target.files;
                    let responseData = {
                        files,
                        from_local: true,
                        is_video: fileType === "video"
                    };
                    if (thumbnail) {
                        let file = files[0];
                        let fileUrl = URL.createObjectURL(file);
                        let newThumbObject = {
                            name: file.name,
                            url: fileUrl,
                            from_local: true,
                            extension: ".png",
                            file: file,
                            thumbnail: true
                        };
                        newThumbObject[`thumbnail_for_${platformIdentifier}`] = true;
                        if (platformIdentifier === PlatformIdentifier.YOUTUBE) {
                            dispatch(setYouTubeVideoThumbnail(newThumbObject));
                        } else if (platformIdentifier === PlatformIdentifier.PINTEREST) {
                            dispatch(setPinterestVideoThumbnail(newThumbObject));
                        } else if (platformIdentifier === PlatformIdentifier.INSTAGRAM) {
                            dispatch(setInstagramVideoThumbnail(newThumbObject));
                        }
                        else if (platformIdentifier === PlatformIdentifier.WORDPRESS) {
                            dispatch(setWordpressFeaturedImageThumbnail(newThumbObject))
                        }
                    } else {
                        fileSelectionHandler(responseData);
                    }
                }}
            />
        </label>
    );
}