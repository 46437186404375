import React, { useEffect, useState } from 'react';

const changes = {
    "targets.1": {
        "status": "added",
        "value": {
            "name": "Reddit",
            "identifier": "reddit",
            "accounts": [
                {
                    "_id": "66b689447cafb104a5ff402f",
                    "name": "Reddit",
                    "identifier": "reddit",
                    "workspace": "669e3ca0f1d6482725d4a57c",
                    "user_id": "hp9z5uk7",
                    "user_name": "CompleteAnimator7440",
                    "user_photo": "",
                    "created_by": "66997822cf7a80f9ad7c6edf",
                    "last_possible_connection_date": "2024-08-09T21:25:24.034Z",
                    "created_at": "2024-08-09T21:25:24.113Z",
                    "updated_at": "2024-08-09T21:27:14.792Z",
                    "__v": 0,
                    "connected_pages_and_groups": [
                        {
                            "id": "hp9z5uk7",
                            "name": "u_CompleteAnimator7440",
                            "self": true,
                            "display_name_prefixed": "u/CompleteAnimator7440",
                            "community_url": "/user/CompleteAnimator7440/",
                            "picture": "",
                            "user_id": "hp9z5uk7",
                            "target": "reddit",
                            "connected": true,
                            "selected": true
                        }
                    ],
                    "selected": true
                }
            ],
            "selected": true
        }
    },
    "body": {
        "status": "modified",
        "original": "New audit, blablbal test for post data.",
        "edited": "New audit."
    },
    "description": {
        "status": "modified",
        "original": "New audit, blablbal test for post data.",
        "edited": "New audit."
    },
    "keywords.1": {
        "status": "added",
        "value": "reddit"
    },
    "platformSpecificData.linked_in.text": {
        "status": "modified",
        "original": "New audit, blablbal test for post data.",
        "edited": "New audit."
    },
    "platformSpecificData.reddit": {
        "status": "added",
        "value": {
            "media": [
                "https://firebasestorage.googleapis.com/v0/b/postly-548c1.appspot.com/o/yt4bMJgbCnWZRPPu-1724937026052-Collins.png?alt=media&token=0e9a94d3-6c8b-4da6-80b7-35a3b589a4cf"
            ],
            "text": "New audit."
        }
    },
    "edited": {
        "status": "removed",
        "value": 1
    },
    "reddit_title": {
        "status": "added",
        "value": "new one"
    },
    "manually_edited_platforms": {
        "status": "added",
        "value": {}
    }
}

const allowedKeys = ['body', 'reddit_title', 'platformSpecificData.{any}'];

function matchExpressionWithObject(expression, obj) {
    const expressionParts = expression.split('.');
    const results = {};

    Object.keys(obj).forEach(key => {
        const keyParts = key.split('.');
        let match = true;

        for (let i = 0; i < expressionParts.length; i++) {
            if (expressionParts[i] === '{any}') continue;
            if (expressionParts[i] !== keyParts[i]) {
                match = false;
                break;
            }
        }

        if (match) {
            results[key] = obj[key];
        }
    });

    return Object.keys(results).length > 0 ? results : undefined;
}

const refinerFunc = (changes) => {
    const results = {};
    for (let a of allowedKeys) {
        const result = matchExpressionWithObject(a, changes);
        results[a] = result !== undefined ? result : 'No changes';
    }
    return results;
};


const ChangeLog = () => {
    const [results, setResults] = useState({});

    useEffect(() => {
        const refinedResults = refinerFunc(changes);
        setResults(refinedResults);
    }, [changes]);

    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Change Log</h2>
            {Object.entries(results).map(([key, value]) => (
                <div key={key} className="mb-4">
                    <h3 className="text-lg font-semibold">{key}:</h3>
                    {value === 'No changes' ? (
                        <p className="text-gray-500 italic">No changes</p>
                    ) : (
                        <pre className="bg-gray-100 p-2 rounded">
                            {JSON.stringify(value, null, 2)}
                        </pre>
                    )}
                </div>
            ))}
        </div>
    );
};

export default ChangeLog;