import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTagForPages } from 'utils/gen';
import { Link } from 'react-router-dom';
import { Buffer } from 'buffer';
import { PERMISSION_VIEW_AND_MANAGE_PLATFORMS } from '../../../../constants';
import { svgWhiteList } from 'utils/gen';
import { Alert, Button, Card } from 'react-bootstrap';
import AppContext from 'context/Context';
import VerifiedBadge from 'assets/images/connected_badge.svg';
import { useSelector } from 'react-redux';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { userCanPerformActionInWorkspace } from 'utils/workspace_utils';

export default function ConnectedAccount(props) {

    const workSpace = useSelector(getActiveWorkSpace);
    const { account } = props;
    const { config } = useContext(AppContext);
    let connectedPagesAndGroups = account.connected_pages_and_groups;
    if (connectedPagesAndGroups) {
        connectedPagesAndGroups = connectedPagesAndGroups.filter((x) => x.target === account.target);
    }
    const [displayNameAsAvatar, setDisplayNameAsAvatar] = useState(false);

    const connectedPagesCount = () => {
        let connectedPagesLength = (connectedPagesAndGroups ?? []).length;
        if (connectedPagesLength === 0) {
            return null;
        }
        return `${connectedPagesAndGroups.length} connected ${getTagForPages(account, connectedPagesAndGroups.length > 1)}`;
    }

    return (
        <Link to={`./${Buffer.from(JSON.stringify(account)).toString("hex")}`}
            style={{
                position: 'relative',
                display: 'flex',
                textDecoration: 'none',
                color: config.isDark ? 'hsl(0deg 0% 100% / 55%)' : '#5e6e82',
                pointerEvents: userCanPerformActionInWorkspace(workSpace, PERMISSION_VIEW_AND_MANAGE_PLATFORMS) ? 'all' : 'none'
            }}>
            <Card
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative',
                    justifyContent: 'center',
                    minHeight: 200,
                    minWidth: 200,
                    cursor: 'pointer'
                }}
            >
                {
                    svgWhiteList(account) ?
                        <img
                            src={account?.svg}
                            alt=""
                            style={{
                                width: 30,
                                height: 30,
                                position: 'absolute',
                                top: 15,
                                left: 15,
                            }} /> :
                        <FontAwesomeIcon
                            icon={account.icon}
                            style={{
                                color: account.color,
                                fontSize: 30,
                                position: 'absolute',
                                top: 15,
                                left: 15,
                            }}
                        />
                }
                <img
                    src={VerifiedBadge}
                    alt=""
                    style={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                        width: 25,
                        height: 25,
                    }} />
                <div
                    className='rounded-circle'
                    style={{
                        width: 60,
                        height: 60,
                        borderWidth: 2,
                        borderColor: '#999',
                        borderRadius: 200
                    }}>
                    <div
                        style={{
                            width: 60,
                            height: 60,
                            backgroundColor: '#EEEEEE',
                            borderRadius: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        {
                            displayNameAsAvatar ?
                                <span
                                    style={{
                                        fontSize: 40,
                                        color: 'black',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        alignSelf: 'center',
                                    }}>{account.user_name.charAt(0).toString().toUpperCase()}
                                </span>
                                : account.user_photo ? <img
                                    src={account.user_photo}
                                    alt={account.user_name}
                                    onError={() => {
                                        setDisplayNameAsAvatar(true);
                                    }}
                                    style={{
                                        width: 60,
                                        height: 60,
                                        borderRadius: 100,
                                    }}
                                /> : <span
                                    style={{
                                        fontSize: 40,
                                        color: 'black',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        alignSelf: 'center',
                                    }}>{account.user_name.charAt(0).toString().toUpperCase()}
                                </span>
                        }
                    </div>
                </div>
                <span
                    style={{
                        fontSize: 16,
                        maxLines: 1,
                        textOverflow: 'ellipsis',
                        padding: 10,
                        fontWeight: 'bold',
                    }}
                    textAlign={'center'}>
                    <span>{`${account.user_name ?? ''}`}</span>
                </span>
                {
                    connectedPagesCount() &&
                    <Alert
                        className='rounded-pill fs--2 p-0 px-2 py-1 mx-1'
                        key={'info'}
                        variant={'info'}
                    >
                        {connectedPagesCount()}
                    </Alert>
                }
                {/* <span
                    style={{
                        fontSize: 12,
                        color: '#90A4AE'
                    }}>Click to see more
                </span> */}
                <Button
                    style={{
                        fontSize: 12,
                        marginBottom: 10,
                    }}
                >
                    Manage channels
                </Button>
            </Card>
        </Link>

    );
}