import {
  faFileCsv,
  faFileExcel
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import ConfirmModal from "components/common/ConfirmModal";
import Flex from "components/common/Flex";
import { RoutePaths } from "constants";
import AppContext from "context/Context";
import { saveAs } from "file-saver";
import csvDownload from "json-to-csv-export";
import PropTypes from "prop-types";
import React, { forwardRef, useContext, useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { convertToTitleFormat } from "utils/platform_utils";
import * as XLSX from "xlsx";
import EmailExportField from "./EmailExportField";

const ReportExportDropdown = forwardRef(
  (
    {
      btnRevealClass,
      drop,
      children,
      icon = "ellipsis-h",
      reportTitle,
      reportData
    },
    ref
  ) => {
    const {
      config: { isRTL },
    } = useContext(AppContext);
    const navigate = useNavigate();
    const [emailExport, setEmailExport] = useState(false);
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const [exportTools] = useState([
      {
        name: "CSV",
        icon: faFileCsv,
        type: "csv",
      },
      {
        name: "Excel",
        icon: faFileExcel,
        type: "excel",
      },
    ]);

    const handleReportExport = async (exportType) => {
      // if (!isFreeWorkspace) {
      //   dispatch(setPopupUpgradeAlert(true))
      //   return;
      // }

      if (reportData?.length) {
        if (Array.isArray(reportData)) {
          let columnHeader;
          const data = reportData.find((i) => convertToTitleFormat(i?.title) === reportTitle);
          let columnData = Object.entries(data?.value).map(([key, value]) => {
            return {
              region: key,
              value
            }
          });
          columnHeader = ["region", "value"];
          if (exportType === "csv") {
            const dataToConvert = {
              data: columnData,
              filename: reportTitle,
              delimiter: ",",
              headers: columnHeader,
            };
            csvDownload(dataToConvert);
          }
          if (exportType === "excel") {
            const fileType =
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            const ws = XLSX.utils.json_to_sheet(columnData);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, {
              bookType: "xlsx",
              type: "array",
            });
            const data = new Blob([excelBuffer], { type: fileType });
            saveAs(data, `${reportTitle}.xlsx`);
          }
        }
      }
    };

    if (emailExport) {
      return (
        <EmailExportField
          title={reportTitle}
          show={emailExport}
          onHide={() => setEmailExport(false)}
        />
      );
    };

    return (
      <>
        <Dropdown
          className="font-sans-serif btn-reveal-trigger"
          align={isRTL ? "start" : "end"}
          drop={drop}
        >
          <Dropdown.Toggle
            variant="link"
            size="sm"
            data-boundary="viewport"
            className={classNames("text-600", {
              [btnRevealClass]: btnRevealClass,
              "btn-reveal": !btnRevealClass,
            })}
          >
            <FontAwesomeIcon icon={icon} className="fs-2" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="border py-0">
            {children}
            {!children && (
              <div className="py-2">
                {exportTools.map(({ name, type, icon }) => {
                  return (
                    <Dropdown.Item
                      key={type}
                      onClick={() => {
                        handleReportExport(type);
                      }}
                    >
                      <Flex justifyContent={"between"} alignItems={"center"}>
                        <span>{name}</span>
                        <FontAwesomeIcon icon={icon} />
                      </Flex>
                    </Dropdown.Item>
                  );
                })}
              </div>
            )}
          </Dropdown.Menu>
        </Dropdown>

        <ConfirmModal
          open={openUpgradeDialog}
          title={"Action requires Upgrade"}
          message={
            "Sorry, Analytics is not avaliable to free users. Upgrade your plan to use this feature. Thank you!"
          }
          confirmText={"UPGRADE"}
          cancelText={"CANCEL"}
          onCancel={() => {
            setOpenUpgradeDialog(false);
          }}
          onConfirm={() => {
            setOpenUpgradeDialog(false);
            navigate(`${RoutePaths.BILLING_STRIPE}`);
          }}
        />
      </>
    );
  }
);

export default ReportExportDropdown;
ReportExportDropdown.propTypes = {
  btnRevealClass: PropTypes.string,
  drop: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
};
