import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { simulateMouseClick } from 'utils/gen';
import APIService from '../../../../http/api_service';
import { toast } from 'react-toastify';
import { setRefreshPopularDays } from 'redux/slices/postslice';
import { useDispatch, useSelector } from 'react-redux';
import CircularButton from 'components/common/circularbutton';
import { Button, Spinner } from 'react-bootstrap';
import Divider from 'components/common/Divider';
import AddNewPopularDayLocation from './AddNewPopularDaysLocations';
import Flex from 'components/common/Flex';
import { getActiveWorkSpace, setActiveWorkspace } from 'redux/slices/workspaceslice';

export default function PopularDaySourcesOptionView(props) {

    const dispatch = useDispatch();
    const workSpace = useSelector(getActiveWorkSpace);
    const [popularDaysSources, setPopularDaySources] = useState(workSpace.days_locations ?? []);
    const [openNewLocationDialog, setOpenNewLocationDialog] = useState(false);
    const [addingNewLocations, setAddingNewLocations] = useState(false);
    const [locationsInRemoveProcess, setLocationsInRemoveProcess] = useState([]);

    const addNewLocations = (selectedLocations) => {
        setAddingNewLocations(true);
        APIService.addNewLocationsToDays(workSpace._id, { locations: selectedLocations }, (response, error) => {
            setAddingNewLocations(false);
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { data } = response;
            let updatedWorkspace = { ...workSpace, ...data };
            dispatch(setActiveWorkspace({ ...updatedWorkspace }));
            setPopularDaySources(updatedWorkspace.days_locations ?? []);
            simulateMouseClick(document.getElementById("add-new-locations-trigger"));
            dispatch(setRefreshPopularDays(true));
        });
    }

    const removeLocation = (locationName) => {
        if (!locationsInRemoveProcess.includes(locationName)) {
            locationsInRemoveProcess.push(locationName);
            setLocationsInRemoveProcess([...locationsInRemoveProcess]);
        } else {
            return;
        }
        APIService.removeLocationFromDays(workSpace._id, locationName, (response, err) => {
            if (locationsInRemoveProcess.includes(locationName)) {
                let indexOfLocationName = locationsInRemoveProcess.indexOf(locationName);
                if (indexOfLocationName !== -1) {
                    locationsInRemoveProcess.splice(indexOfLocationName, 1);
                }
            }
            setLocationsInRemoveProcess([...locationsInRemoveProcess]);
            if (err) {
                toast.error(err, { theme: 'colored' });
                return;
            }
            let { data, message } = response;
            toast.success(message, { theme: 'colored' });
            let updatedWorkspace = { ...workSpace, ...data };
            dispatch(setActiveWorkspace({ ...updatedWorkspace }));
            setPopularDaySources(updatedWorkspace.days_locations ?? []);
            dispatch(setRefreshPopularDays(true));
        });
    }

    const EventLocationEntry = React.forwardRef((props, ref) => {
        const { location } = props;
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 10
                }}>
                <span
                    style={{
                        maxWidth: 300,
                        flex: 1
                    }}>{location.name}
                </span>
                <CircularButton
                    onClick={() => {
                        removeLocation(location.name);
                    }}
                    style={{
                        marginRight: 20
                    }}
                    title={`Remove ${location.name} from my Popular Days Locations`}>
                    {
                        locationsInRemoveProcess.includes(location.name)
                            ?
                            <Spinner
                                as={'span'}
                                role="status"
                                animation='border'
                                size="sm"
                            />
                            :
                            <FontAwesomeIcon
                                icon={faTrash}
                                style={{
                                    height: 15,
                                    width: 15,
                                    color: '#C62828',
                                }}
                            />
                    }
                </CircularButton>
            </div>
        )
    });

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 5
            }}>
            <h5>Showing Popular Days from:</h5>
            <Divider />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 5,
                    marginBottom: 20
                }}>
                {
                    popularDaysSources.length > 0 &&
                    popularDaysSources.map(x => {
                        return <EventLocationEntry location={x} />
                    })
                }
            </div>
            {
                !openNewLocationDialog &&
                <Button
                    variant={'outline-info'}
                    disabled={addingNewLocations}
                    onClick={() => {
                        setOpenNewLocationDialog(!openNewLocationDialog);
                    }}>{addingNewLocations ? 'Adding Location(s)...' : 'Add More Locations'}
                </Button>
            }
            {
                openNewLocationDialog &&
                <Flex direction={'column'} className={'gap-1'}>
                    <Divider />
                    <AddNewPopularDayLocation
                        multiChoice={true}
                        modalClose={() => {
                            setOpenNewLocationDialog(false);
                        }}
                        selectionDoneHandler={(selectedLocationNames) => {
                            if (selectedLocationNames.length > 0) {
                                setAddingNewLocations(true);
                                addNewLocations(selectedLocationNames);
                            }
                        }}
                    />
                </Flex>
            }
        </div >
    );
}