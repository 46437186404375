import React, { useEffect, useState } from "react";
import {
  faBullhorn,
  faFileVideo,
  faHashtag,
  faLayerGroup,
  faLightbulb,
  faMagnifyingGlass,
  faMouse,
  faPen,
  faPenClip,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-slidedown/lib/slidedown.css";
import {
  fetchAllSavedCopies,
  fetchAllSavedLocalCopies,
} from "./all_saved_copies";
import {
  setAIWriterResults,
} from "../../../redux/slices/ai_writer_slice";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getActiveWorkSpace, getSearchTerm, setActiveWorkspace, setSearchHint } from "redux/slices/workspaceslice";
import {
  faInstagram,
  faSearchengin,
} from "@fortawesome/free-brands-svg-icons";
import APIService from "http/api_service";
import PageHeader from "components/common/PageHeader";
import { Alert, Col, Row } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import MonthlyYearlySwitch from "../account/pricing/MonthlyYearlySwitch";
import { getBilledYearly } from "redux/slices/workspaceslice";
import Flex from "components/common/Flex";
import { RoutePaths } from "../../../constants";
import { Link } from "react-router-dom";
import { getLoggedInUser } from "redux/slices/user_slice";
import useProtectedRoute from "hooks/useProtectedRoute";

const suffix = "on the following:";

export const tools = [
  {
    name: "Product Description",
    tool: "ProductDescription",
    description: "Generate a description for your product",
    navigationTitle: "What is the name of your product?",
    navigationTitleHint: "E.g Growth Hacker",
    navigationDescription: "Describe your product",
    prefix: `Write a creative product description on the following:`,
    prioritizeProduct: true,
    navigationDescriptionHint:
      "e.g a growth hacker for small businesses and entrepreneurs",
    optional: true,
    toneable: true,
    icon: faBullhorn,
    color: "#EF6C00",
  },
  {
    name: "Blog Section",
    tool: "blogSection",
    description: "Generate a description for your blog",
    navigationTitle: "What is the title of your article?",
    navigationTitleHint: "E.g Growth Hacker",
    navigationDescription: "Describe your article",
    prefix: `Write a creative product description on the following:`,
    prioritizeProduct: true,
    navigationDescriptionHint:
      "e.g a growth hacker for small businesses and entrepreneurs",
    optional: true,
    toneable: true,
    icon: faBullhorn,
    color: "#EF6C00",
  },
  {
    name: "Hashtag Generator",
    tool: "hashtagGenerator",
    description:
      "Generate a hundred hashtags on the following Content with no numberings",
    navigationTitle: "What is your post about?",
    navigationTitleHint: "e.g financial intelligence",
    prefix: `Generate a hundred hashtags on the following Content with no numberings:`,
    canUseVoice: false,
    prioritizeProduct: true,
    optional: true,
    toneable: true,
    icon: faHashtag,
    color: "#4E342E",
  },
  {
    name: "Post Captions",
    tool: "postCaptions",
    description: "Generate state of the art captions for social media",
    navigationTitle: "In what area would you like to generate post captions?",
    navigationTitleHint: "e.g fashion",
    prefix: `Write a creative post caption ${suffix}`,
    prioritizeProduct: true,
    optional: true,
    toneable: true,
    icon: faPenClip,
    color: "#4E342E",
  },
  {
    name: "Blog Captions",
    tool: "blogCaptions",
    description: "Generate state of the art captions for social media",
    navigationTitle: "In what area would you like to generate blog captions?",
    navigationTitleHint: "e.g fashion",
    prefix: `Write a creative blog caption ${suffix}`,
    prioritizeProduct: true,
    optional: true,
    toneable: true,
    icon: faPenClip,
    color: "#4E342E",
  },
  {
    name: "Post Ideas",
    tool: "postIdeas",
    description: "Generate state of the art captions for social media",
    navigationTitle: "In what area would you like to generate post ideas?",
    navigationTitleHint: "e.g science",
    prefix: `Write a creative post idea ${suffix}`,
    prioritizeProduct: true,
    optional: true,
    toneable: true,
    icon: faLightbulb,
    color: "#F4511E",
  },
  {
    name: "Blog Ideas",
    tool: "blogIdeas",
    description: "Generate state of the art captions for social media",
    navigationTitle: "In what area would you like to generate blog ideas?",
    navigationTitleHint: "e.g science",
    prefix: `Write a creative blog idea ${suffix}`,
    prioritizeProduct: true,
    optional: true,
    toneable: true,
    icon: faLightbulb,
    color: "#F4511E",
  },
  {
    name: "Google Ads",
    tool: "googleAds",
    description: "Generate state of the art captions for Google Ads",
    navigationTitle: "What is your Google Ad about",
    navigationTitleHint: "e.g science",
    prefix: `Write a creative Google Ad copy ${suffix}`,
    prioritizeProduct: true,
    optional: true,
    toneable: true,
    icon: faLightbulb,
    color: "#F4511E",
  },
  {
    name: "Facebook Ads",
    tool: "facebookAds",
    description: "Generate state of the art captions for Facebook Ads",
    navigationTitle: "What is your Facebook Ad about",
    navigationTitleHint: "e.g science",
    prefix: `Write a creative Facebook Ad copy ${suffix}`,
    prioritizeProduct: true,
    optional: true,
    toneable: true,
    icon: faLightbulb,
    color: "#F4511E",
  },
  {
    name: "LinkedIn Ads",
    tool: "linkedInAds",
    description: "Generate state of the art captions for LinkedIn Ads",
    navigationTitle: "What is your LinkedIn Ad about",
    navigationTitleHint: "e.g science",
    prefix: `Write a creative LinkedIn Ad copy ${suffix}`,
    prioritizeProduct: true,
    optional: true,
    toneable: true,
    icon: faLightbulb,
    color: "#F4511E",
  },
  {
    name: "Twitter Ads",
    tool: "twitterAds",
    description: "Generate state of the art captions for Twitter Ads",
    navigationTitle: "What is your Twitter Ad about",
    navigationTitleHint: "e.g science",
    prefix: `Write a creative Twitter Ad copy ${suffix}`,
    prioritizeProduct: true,
    optional: true,
    toneable: true,
    icon: faLightbulb,
    color: "#F4511E",
  },
  {
    name: "Instagram Ads",
    tool: "instagramAds",
    description: "Generate state of the art captions for Instagram Ads",
    navigationTitle: "What is your Instagram Ad about",
    navigationTitleHint: "e.g science",
    prefix: `Write a creative Instagram Ad copy ${suffix}`,
    prioritizeProduct: true,
    optional: true,
    toneable: true,
    icon: faLightbulb,
    color: "#F4511E",
  },
  {
    name: "Attention-Interest-Desire-Action",
    tool: "AttentionInterestDesireAction",
    description: "Generate state of the art captions for sales",
    navigationTitle: "What is the name of your product?",
    navigationTitleHint: "e.g Postly",
    prefix: `Describe the stages an individual will go through in the process of purchasing the following product:`,
    prioritizeProduct: true,
    optional: true,
    toneable: true,
    icon: faLightbulb,
    color: "#F4511E",
  },
  {
    name: "Pain-Agitate-Solution",
    tool: "PainAgitateSolution",
    description: "Generate state of the art captions for sales",
    navigationTitle: "What is the name of your product?",
    navigationTitleHint: "e.g Postly",
    prefix: `Write a creative Pain-Agitate-Solution ${suffix} `,
    prioritizeProduct: true,
    optional: true,
    toneable: true,
    icon: faLightbulb,
    color: "#F4511E",
  },
  {
    name: "Feature-To-Benefits",
    tool: "FeatureToBenefits",
    description: "Generate state of the art captions for sales",
    navigationTitle: "What is the name of your product?",
    navigationTitleHint: "e.g Postly",
    prefix: `Write a creative review about the following product and explain how a customer's life gets better because of it:`,
    prioritizeProduct: true,
    optional: true,
    toneable: true,
    icon: faLightbulb,
    color: "#F4511E",
  },
  {
    name: "Feature-Advantage-Benefits",
    tool: "FeatureAdvantageBenefits",
    description: "Generate state of the art captions for sales",
    navigationTitle: "What is the name of your product?",
    navigationTitleHint: "e.g Postly",
    prefix: `Write a creative message on what the following product does and describe its advantages:`,
    prioritizeProduct: true,
    optional: true,
    toneable: true,
    icon: faLightbulb,
    color: "#F4511E",
  },
  {
    name: "Post Call to Action",
    tool: "postCallToAction",
    description: "Generate state of the art captions for social media",
    navigationTitle: "What is your post about?",
    navigationTitleHint: "e.g strawberries",
    prefix: `Write a creative call to action ${suffix}`,
    prioritizeProduct: true,
    optional: true,
    toneable: true,
    icon: faMouse,
    color: "#1A237E",
  },
  {
    name: "Video Ideas",
    tool: "videoIdeas",
    description: "Generate state of the art captions for social media",
    navigationTitle: "Under what genre would you like to generate video ideas",
    navigationTitleHint: "e.g science fiction",
    prefix: `Write a creative video idea ${suffix}`,
    prioritizeProduct: true,
    optional: true,
    toneable: true,
    icon: faVideo,
    color: "#C2185B",
  },
  {
    name: "Video Captions",
    tool: "videoCaptions",
    description: "Generate state of the art captions for social media",
    navigationTitle:
      "Under what genre would you like to generate video captions",
    navigationTitleHint: "e.g drama",
    prefix: `Write a creative video caption ${suffix}`,
    prioritizeProduct: true,
    optional: true,
    toneable: true,
    icon: faFileVideo,
    color: "#AD1457",
  },
  {
    name: "Video Call to Action",
    tool: "videoCallToAction",
    description: "Generate state of the art captions for social media",
    navigationTitle: "What is your post about",
    navigationTitleHint: "e.g drama",
    prefix: `Write a creative video call to action ${suffix}`,
    prioritizeProduct: true,
    optional: true,
    toneable: true,
    icon: faMouse,
    color: "#E91E63",
  },
  {
    name: "Instagram Captions",
    tool: "instagramCaptions",
    description: "Generate state of the art captions for social media",
    navigationTitle: "What is your post about",
    navigationTitleHint: "e.g wears",
    prefix: `Write a creative Instagram post caption ${suffix}`,
    prioritizeProduct: true,
    optional: true,
    toneable: true,
    icon: faInstagram,
    color: "#EB4869",
  },
  {
    name: "SEO Meta Description",
    tool: "seoMetaDescription",
    description: "Generate state of the art captions for social media",
    navigationTitle: "What is your site about",
    navigationTitleHint: "e.g education",
    prefix: `Write a creative SEO Meta description ${suffix}`,
    prioritizeProduct: true,
    optional: true,
    toneable: true,
    icon: faMagnifyingGlass,
    color: "#01579B",
  },
  {
    name: "Blog Title",
    tool: "seoMetaTitle",
    description: "Generate state of the art captions for social media",
    navigationTitle: "What is your site about",
    navigationTitleHint: "e.g finance",
    prefix: `Write a creative SEO Meta title ${suffix}`,
    prioritizeProduct: true,
    optional: true,
    toneable: true,
    icon: faSearchengin,
    color: "#01579B",
  },
  {
    name: "Social Media",
    tool: "SocialMedia",
    description: "Generate state of the art captions for social media",
    toneable: true,
    icon: faLayerGroup,
    color: "#00BCD4",
    sub_items: [],
  },
];

const moreItems = [
  {
    id: 1,
    label: "Unlimited AI characters",
    icon: "infinity",
  },
  {
    id: 2,
    label: "Access to all AI tools",
    icon: "infinity",
  },
  { id: 3, label: "Access 40+ use-cases", icon: "check" },
  {
    id: 4,
    label: "Prompts library (coming soon)",
    icon: "check",
  },
  {
    id: 5,
    label: "Multiple language support (coming soon)",
    icon: "check",
  },
];

export default function AIWriter(props) {

  const { tools } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState(tools);
  const [originalCategories] = useState(categories);
  const workSpace = useSelector(getActiveWorkSpace);
  const searchTerm = useSelector(getSearchTerm);
  const billedYearly = useSelector(getBilledYearly);
  const loggedInUser = useSelector(getLoggedInUser);
 

  const checkAIWriterUsageResumption = () => {
    APIService.checkAIWriterUsageResumption(workSpace["_id"], (res, err) => {
      APIService.fetchWorkSpace(workSpace["_id"], (response, error) => {
        if (error) {
          return;
        }
        if (response) {
          let responseData = response["data"];
          let receivedWorkspace = responseData["workspace"];
          receivedWorkspace["role"] = responseData["role"];
          receivedWorkspace["value"] = receivedWorkspace["name"];
          receivedWorkspace["title"] = responseData["title"];
          receivedWorkspace["label"] = receivedWorkspace["name"];
          dispatch(setActiveWorkspace({ ...receivedWorkspace }));
        }
      });
    });
  };

  useEffect(() => {
    setCategories((initialCategories) => {
      return originalCategories.filter((category) => {
        let containedInTopLevel = category.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        let containedInLowerLevel = false;
        if (category.sub_items) {
          let subItemCategory = category.sub_items.filter((x) =>
            x.name.toLowerCase().includes(searchTerm.toLowerCase())
          );
          containedInLowerLevel = (subItemCategory ?? []).length > 0;
        }
        return containedInTopLevel || containedInLowerLevel;
      });
    });
  }, [searchTerm]);

  useEffect(() => {
    dispatch(setSearchHint("Search AI Writer Categories"));
    let retrievedResults = fetchAllSavedLocalCopies();
    dispatch(setAIWriterResults(retrievedResults));
    checkAIWriterUsageResumption();
    fetchAllSavedCopies(workSpace, retrievedResults, dispatch);
  }, []);

  const ContentWritingSubItemCategory = React.forwardRef((props, ref) => {

    const { name, tool, index, icon } = props;

    return (
      <Card
        onClick={(e) => {
          e.stopPropagation();
          navigate(`./${tool}?tag=${index}`);
        }}
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 10,
          paddingLeft: 15,
          paddingRight: 15,
          borderRadius: 100,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={icon} />
          <span>{name}</span>
        </div>
      </Card>
    );
  });

  return (
    <>
      <PageHeader
        title=""
      >
        {loggedInUser?.active_ai_writer_subscription ? (
          <>
            <h5 className="text-600 fs-0 mb-3">
              Credits Used:{" "}
              <span className="text-primary fw-semi-bold">
                {" "}
                {loggedInUser?.used_ai_writer_characters.toLocaleString()}/Unlimited
              </span>{" "}
              Characters
            </h5>
            <h5 className="text-600 fs-0 mb-3">
              Your credits will reset on:{" "}
              <span className="text-primary fw-semi-bold">
                {loggedInUser?.active_ai_writer_subscription.next_bill_date}{" "}
              </span>{" "}
            </h5>
          </>
        ) : (
          <>
            <h5 className="text-600 fs-0 mb-3">
              Credits Used:{" "}
              <span className="text-primary fw-semi-bold">
                {" "}
                {loggedInUser?.used_ai_writer_characters.toLocaleString()}/
                {loggedInUser?.active_plan["AI_Writer_Chars"].toLocaleString() || loggedInUser?.active_plan["AI_Writer_Words"].toLocaleString()}{" "}
              </span>
              Characters
            </h5>
            {loggedInUser?.active_plan?.next_bill_date && (
              <h5 className="text-600 fs-0 mb-3">
                AI credits will reset on:{" "}
                <span className="text-primary fw-semi-bold">
                  {loggedInUser?.active_plan.next_bill_date}{" "}
                </span>{" "}
              </h5>
            )}
          </>
        )}
      </PageHeader>
      <Row className="g-3 mb-3">
        <Col xxl={6}>
          <Row className="g-3">
            <Col xs={12}></Col>
          </Row>
        </Col>
        <Col xxl={3}>
          <Row className="g-3">
            <Col md xxl={12}>
              <Card>
                <Card.Body className="p-4">
                  {loggedInUser?.active_ai_writer_subscription ? (
                    <>
                      <h4 className="text-800 mb-3">
                        Active Plan: Unlimited AI
                      </h4>
                      <Alert variant="success" className="mt-3">
                        <h3 className="mb-0 text-800">
                          {loggedInUser?.active_ai_writer_subscription
                            .subscriptionMode === "Yearly"
                            ? "$288"
                            : "$30"}
                          <span className="fs-0 fw-medium font-sans-serif text-600">
                            {loggedInUser?.active_ai_writer_subscription
                              .subscriptionMode === "Yearly"
                              ? "/year"
                              : "/month"}
                          </span>
                        </h3>
                      </Alert>
                    </>
                  ) : (
                    <>
                      <h4 className="text-800 mb-3">
                        Do More with Unlimited AI
                      </h4>
                      <MonthlyYearlySwitch />
                      <Alert variant="success" className="mt-3">
                        <h3 className="mb-0 text-800">
                          {billedYearly ? "$288" : "$30"}
                          <span className="fs-0 fw-medium font-sans-serif text-600">
                            {billedYearly ? "/year" : "/month"}
                          </span>
                        </h3>
                      </Alert>
                    </>
                  )}
                  <ul className="fa-ul ms-2 ps-card mb-2">
                    {moreItems.map((item) => (
                      <li key={item.id} className="py-1">
                        <h6 className="text-700">
                          <FontAwesomeIcon
                            icon={item.icon}
                            className="fa-li text-success"
                          />
                          {item.label}
                        </h6>
                      </li>
                    ))}
                  </ul>
                  {!loggedInUser?.active_ai_writer_subscription && (
                    <Link
                      to={`${RoutePaths.UNLIMITED_AI_BILLING}`}
                      className="text-white fw-bold"
                    >
                      <IconButton
                        icon="crown"
                        variant="success"
                        iconClassName="me-2"
                        className="w-100"
                      >
                        Upgrade to Unlimited AI
                      </IconButton>
                    </Link>
                  )}
                </Card.Body>
                {loggedInUser?.active_ai_writer_subscription?.cancel_url && (
                  <Card.Body className="bg-light">
                    <div className="bg-light">
                      {loggedInUser?.active_ai_writer_subscription
                        ?.update_url && (
                          <a
                            href={
                              loggedInUser?.active_ai_writer_subscription
                                ?.update_url
                            }
                            className={"text-decoration-none cursor-pointer"}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Flex
                              className={"gap-1 text-primary"}
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              <FontAwesomeIcon
                                style={{ width: 10, height: 10 }}
                                icon={faPen}
                              />
                              <span className="fs-0">
                                {" "}
                                <b> Update Payment Details</b>
                              </span>
                            </Flex>
                          </a>
                        )}
                      {loggedInUser?.active_ai_writer_subscription
                        ?.cancel_url && (
                          <Flex
                            className={"gap-1 text-warning mt-3 cursor-pointer"}
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <a
                              href={
                                loggedInUser?.active_ai_writer_subscription
                                  ?.cancel_url
                              }
                              className={"text-decoration-none cursor-pointer"}
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: "#e63757" }}
                            >
                              Cancel subscription
                            </a>
                          </Flex>
                        )}
                    </div>
                  </Card.Body>
                )}
              </Card>
            </Col>
            <Col md xxl={12}></Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
