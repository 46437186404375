import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import {
    getActiveWorkSpace,
    getBulkWorkSpacesToCreate,
    setBulkWorkSpacesToCreate
} from "redux/slices/workspaceslice";
import useOrganizationStore from "state/organization_store";

const BulkContentSelect = (props) => {

    const {
        rowIndex = 0,
        property,
    } = props;
    let options = props.options ?? []
    const dispatch = useDispatch();
    const uploadableBulkContent = useSelector(getBulkWorkSpacesToCreate);
    const bulkContentRow = uploadableBulkContent[rowIndex] ?? {};
    const [value, setValue] = useState(bulkContentRow[property]);

    const workSpace = useSelector(getActiveWorkSpace);
    const organizationsFromStore = useOrganizationStore(state => state.organizationList);
    const organizationsMap = new Map();

    if (workSpace && workSpace?.organization) {
        organizationsMap.set(workSpace?.organization?._id, workSpace?.organization);
    }

    organizationsFromStore.filter(org => org).forEach(org => organizationsMap.set(org._id, org));
    const organizations = Array.from(organizationsMap.values()).filter(org => !org.new);

    options = property === 'organization' ? organizations : options;

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            let uploadableBulkContentCopy = [...uploadableBulkContent];
            let updatedRow = { ...bulkContentRow };
            updatedRow[property] = value;
            uploadableBulkContentCopy[rowIndex] = updatedRow;
            dispatch(setBulkWorkSpacesToCreate(uploadableBulkContentCopy));
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [value])

    return (
        <Form.Select
            name={property}
            value={value}
            onChange={(e) => {
                let value = e.target.value;
                if (!value) {
                    value = '';
                }
                setValue(value);
            }}
        >
            {
                options.map(x => {
                    return <option value={property === 'organization' ? x._id : x}>{property === 'organization' ? x?.name : x}</option>
                })
            }
        </Form.Select>
    )
}
export default BulkContentSelect;