import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import { useSelector } from 'react-redux';
import { getBilledYearly } from 'redux/slices/workspaceslice';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const PickAPlanPricingCard = (props) => {

  const handleBilling = props.handleBilling;
  const [pricing, setPricing] = useState(props.pricing);
  const buttonsDisabled = props.buttonsDisabled ?? false;
  const {
    subTitle,
    price,
    currencySign,
    periodical,
    buttonText,
    isFeatured,
    activePlan,
    // featureTitle,
    features,
    ...others
  } = pricing;

  useEffect(() => {
    setPricing(props.pricing);
  }, [props.pricing]);

  const billedYear = useSelector(getBilledYearly);

  return (
    <Col
      lg={3}
      className={classNames('border-top border-200 border border-bottom', {
        'dark__bg-1000 px-4 px-lg-0': isFeatured
      })}
      style={{ backgroundColor: isFeatured && 'rgba(115, 255, 236, 0.18)' }}
    >
      <div className="h100">
        <div className="text-center p-2 pt-4">
          <h3 className="fw-normal my-0 fs-1 text-900"><b>{others.title}</b></h3>
          <p className="mt-3 fs--1">{subTitle}</p>
          <h2 className="fw-medium my-4">
            <sup className="fw-normal fs-2 me-1">{currencySign}</sup>
            {billedYear ? price.Year.toLocaleString() : price.Month.toLocaleString()}
            {periodical && <small className="fs--1 text-700">/{billedYear ? "year" : "month"}</small>}
          </h2>

          <Button
            disabled={buttonsDisabled}
            onClick={() => {
              if (others.plan && !activePlan) {
                handleBilling(others);
              }
            }}
            variant={isFeatured ? 'primary' : activePlan ? 'success' : 'outline-primary'}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
              <span>{activePlan ? "Active Plan" : buttonText}</span>
              {
                activePlan && <FontAwesomeIcon icon={faCheckCircle} />
              }
            </div>
          </Button>
        </div>
        <hr className="border-bottom-0 m-0" />
        <div className="text-start px-sm-3 py-3">
          {/* <h5 className="fw-medium fs--1">{featureTitle}</h5> */}
          <ul className="list-unstyled mt-3">
            {features.map(feature => (
              <li className="py-1 fs--1" key={feature.id}>
                <FontAwesomeIcon icon="check" className="me-1 text-success" />{' '}
                {feature.title}{' '}
                {feature.tag && (
                  <SoftBadge pill bg={feature.tag.type}>
                    {feature.tag.label}
                  </SoftBadge>
                )}
              </li>
            ))}
          </ul>

          <a href="https://www.postly.ai/pricing/"
            className="btn btn-link"
            target={'_blank'}
            rel={'noreferrer'}>
            More about {others.title}
          </a>

        </div>
      </div>
    </Col>
  );
};

PickAPlanPricingCard.propTypes = {
  pricing: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    url: PropTypes.string,
    buttonText: PropTypes.string,
    isFeatured: PropTypes.bool,
    // featureTitle: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        tag: PropTypes.shape({
          label: PropTypes.string,
          type: PropTypes.string
        })
      })
    )
  })
};

export default PickAPlanPricingCard;