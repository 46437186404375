import styled from "styled-components";
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { mauve } from '@radix-ui/colors';

export const StyledTabList = styled(TabsPrimitive.List)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: ${props => props.marginLeft ?? '10.5%'};
    margin-right: ${props => props.marginRight ?? '10.5%'};
    border-bottom: '1px solid ${mauve.mauve6}'
`