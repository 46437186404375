/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGoogleDriveManager, setGoogleDriveManager, setShowMediaOptions } from 'redux/slices/postslice';
import useDrivePicker from 'react-google-drive-picker';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import ConfirmModal from './ConfirmModal';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'constants';
import GoogleDriveIcon from '../../assets/images/driveImg.png'
import { getLoggedInUser, setLoggedInUser } from 'redux/slices/user_slice';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { isFreeWorkspace } from 'utils/workspace_utils';

export default function GoogleDriveImagePicker(props) {
    const { fileType } = props;
    const navigate = useNavigate();
    const [initiateLoader, setInitiateLoader] = useState(false);
    const workSpace = useSelector(getActiveWorkSpace);
    const workSpaceId = workSpace._id;
    const currentUser = useSelector(getLoggedInUser);
    // const [openPicker, authResponse] = useDrivePicker();
    const googleDriveManager = useSelector(getGoogleDriveManager);
    const [canProceed, setCanProceed] = useState(false);
    const [isTokenValid, setisTokenValid] = useState();
    const [doneChecking, setDoneChecking] = useState(false);
    const [conectedStatus, setConectedStatus] = useState(false);
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const dispatch = useDispatch();

    const checkIfDateIsPast = async (promptIfFailed, connectIfTrue) => {
        if (isFreeWorkspace(workSpace)) {
            setCanProceed(false);
            return;
        }
        if (currentUser['google_drive_access_token'] && currentUser['google_drive_refresh_token']) {
            setConectedStatus('connected');
        } else {
            setConectedStatus('not connected');
            setCanProceed(true);
            console.log('not connected')
            return false

        }
        if (new Date() > new Date(currentUser['google_drive_access_token_expires_at'])) {
            connectIfTrue && setInitiateLoader(true);
            setisTokenValid(false);
            let requestBody = {
                refresh_token: currentUser['google_drive_refresh_token']
            }
            const response = await getNewAccessToken(requestBody, promptIfFailed, connectIfTrue);
            setDoneChecking(true);
            return false
        } else {
            console.log('connected')
            setisTokenValid(true);
            dispatch(setGoogleDriveManager({ ...googleDriveManager, connected: true }))
            setDoneChecking(true);
            setCanProceed(true);
            connectIfTrue && handleOpenPicker();
            return true
        }
    }

    const getNext50MinsDate = () => {
        var now = new Date();
        now.setMinutes(now.getMinutes() + 50);
        now = new Date(now);
        return now.toISOString();
    }

    const getNewAccessToken = async (requestBody, promptIfFailed, connectIfTrue) => {
        APIService.getNewGDriveAccessToken(requestBody, (response, error) => {
            if (error) {
                if (error === 'Token Expired') {
                    if (promptIfFailed) {
                        dispatch(setGoogleDriveManager({
                            ...googleDriveManager,
                            promptConnect: true,
                            fromPreviews: true
                        }))
                    }
                    setConectedStatus('not connected');
                    setDoneChecking(true);
                    setCanProceed(true);
                    connectIfTrue && setInitiateLoader(false);
                    return;
                }
            }

            APIService.updateAccountDetails({ google_drive_access_token: response?.data?.access_token, google_drive_refresh_token: response?.data?.refresh_token, google_drive_access_token_expires_at: getNext50MinsDate() }, (response, error) => {
                if (error) {
                    connectIfTrue && setInitiateLoader(false);
                    console.log(error);
                    return;
                }
                APIService.fetchLatestMe((latestMeResponse, error) => {
                    if (error) {
                        connectIfTrue && setInitiateLoader(false);
                        toast.error(error, { theme: 'colored' });
                        return;
                    }
                    connectIfTrue && setInitiateLoader(false);
                    let latestUserData = latestMeResponse.data;
                    dispatch(setLoggedInUser({ ...latestUserData }));
                    setCanProceed(true);
                    setisTokenValid(true)
                    dispatch(setGoogleDriveManager({ ...googleDriveManager, connected: true }))
                    connectIfTrue && handleOpenPicker();
                });
            });
        });
    }

    useEffect(() => {
        currentUser && !doneChecking && checkIfDateIsPast()
    }, [currentUser])

    const handleOpenPicker = () => {
        dispatch(setGoogleDriveManager({
            ...googleDriveManager, openPicker: true, fetchLoading: true
        }));
        let requestBody = {
            google_drive_access_token: currentUser['google_drive_access_token'],
            google_drive_refresh_token: currentUser['google_drive_refresh_token'],
            google_drive_access_token_expires_at: currentUser['google_drive_access_token_expires_at'],
            q: `mimeType='application/vnd.google-apps.folder' and 'root' in parents`
        }
        APIService.getGoogleDriveMedias(workSpaceId, requestBody, (response, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                dispatch(setGoogleDriveManager({
                    ...googleDriveManager, openPicker: true, folders: [], fetchLoading: false
                }));

                return;
            }
            dispatch(setGoogleDriveManager({
                ...googleDriveManager, openPicker: true, fileType: fileType, folders: response?.data?.files, folderNextPageToken: response?.data?.nextPageToken, fetchLoading: false
            }));
        });
    }

    const registerToLocalStorage = (key, value) => {
        localStorage.setItem(key, value);
    }

    return (

        <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}
            onClick={() => {
                registerToLocalStorage('transportedFromGDrivePicker', true)
                navigate(`${RoutePaths.GDRIVE_CONTENTS}`)
                dispatch(setShowMediaOptions(false))
                // window.location.reload()
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: 'center',
                    // marginLeft: 8,
                    cursor: 'pointer',
                    gap: "5px"
                }}>
                <img
                    src={GoogleDriveIcon}
                    style={{ width: 50, height: 50 }}
                    alt="Google Drive Icon From Postly"
                />

                <span
                    style={{
                        fontSize: "10px",
                        // flex: 1,
                        width: 'auto',
                        // fontWeight: 500,
                        // paddingLeft: 20,
                        // textAlign: 'center',
                        color: 'inherit'
                    }}>
                    {
                        canProceed ? 'Google Drives' : 'Please wait...'
                    }

                </span>

            </div>
            <ConfirmModal
                open={openUpgradeDialog}
                title={"Action requires Upgrade"}
                message={"Sorry, GDrive Download and Upload is not avaliable on free plan. Upgrade your plan to use this feature. Thank you!"}
                confirmText={"UPGRADE"}
                cancelText={"CANCEL"}
                onCancel={() => {
                    setOpenUpgradeDialog(false);
                }}
                onConfirm={() => {
                    setOpenUpgradeDialog(false);
                    navigate(`${RoutePaths.BILLING_STRIPE}`);
                }}
            />

        </div>
    );
}
