import React from 'react'
import PollCreationDialog from './polls/poll_creation_dialog'
import PageHeader from 'components/common/PageHeader'
import Flex from 'components/common/Flex'
import { Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const instructionItems = [
    {
        id: 1,
        label: 'Polls are currently only supported on Twitter and LinkedIn.',
        icon: 'check'
    },
];

const CreatePoll = () => {

    function convertTextToLinks(text) {
        const regex = /{{(.*?)\}\}\(\((.*?)\)\)/g;
        const replacedText = text.replace(regex, '<a href="$2" target="_blank">$1</a>');
        return replacedText;
    }


    return (
        <>
            <Flex
                direction={'column'}>

                <PageHeader
                    title={'Schedule a poll'}
                    titleTag="h4"
                />
                <Card className='mt-4'>
                    <Card.Header>
                        <h2
                            className='fs-2 mb-0'
                        >
                            Instructions
                        </h2>
                    </Card.Header>
                    <Card.Body>
                        <ul className="fa-ul ms-2 ps-card mb-2">
                            {instructionItems.map(item => (
                                <li key={item.id} className="py-1">
                                    <h6 className="text-700">
                                        <FontAwesomeIcon
                                            icon={item.icon}
                                            className="fa-li text-success"
                                        />
                                        <div dangerouslySetInnerHTML={{ __html: convertTextToLinks(item.label) }}></div>
                                    </h6>
                                </li>
                            ))}
                        </ul>
                    </Card.Body>
                </Card>
                <Card className='mt-4'>
                    <Card.Body>
                        <PollCreationDialog />
                    </Card.Body>
                </Card>
            </Flex>
        </>
    )
}

export default CreatePoll