import React from 'react';
import PropTypes from 'prop-types';
import { Col, Image, Row } from 'react-bootstrap';
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery';
import ReactPlayer from 'react-player';

const FeedGallery = (props) => {

  const { files, fromPostDetailsDialog } = props;
  const { images, videos } = files;

  const imagePlacementsMapping = fromPostDetailsDialog ? {
    1: [12],
    2: [6, 6],
    3: [6, 6, 12],
    4: [6, 6, 6, 6],
    5: [6, 6, 4, 4, 4],
    6: [4, 4, 4, 4, 4, 4],
    "above": [4, 4, 4, 4, 4, 4]
  } : {
    1: [12],
    2: [6, 6],
    3: [6, 6, 12],
    // 4: [6, 6, 6, 6],
    // 5: [6, 6, 4, 4, 4],
    // 6: [4, 4, 4, 4, 4, 4],
    "above": [4, 4, 4, 4, 4, 4]
  }

  if (fromPostDetailsDialog) {
    return (
      <div>
        <FalconLightBoxGallery images={images}>
          {
            setImgIndex => (
              <Row className="g-2">
                {
                  images.map((image, index) => {
                    let mapping = imagePlacementsMapping[images.length];
                    if (!mapping) {
                      mapping = "above";
                      mapping = imagePlacementsMapping[mapping];
                    }
                    return (
                      <Col xs={mapping[index]}>
                        <Image
                          src={image}
                          fluid
                          rounded
                          style={{
                            cursor: 'pointer',
                            height: '100%',
                            objectFit: 'cover'
                          }}
                          onClick={() => setImgIndex(index)}
                        />
                      </Col>
                    )
                  })
                }
                {
                  videos && videos.map((video) => {
                    return (
                      <Col xs={12}>
                        <ReactPlayer url={video} controls={true} className="react-player" />
                      </Col>
                    )
                  })
                }
              </Row>
            )}
        </FalconLightBoxGallery>
      </div>
    );
  }

  return (
    <div>
      <FalconLightBoxGallery images={images}>
        {
          setImgIndex => (
            <Row className="g-2">
              {
                images.map((image, index) => {
                  let mapping = imagePlacementsMapping[images.length];
                  if (!mapping) {
                    mapping = "above";
                    mapping = imagePlacementsMapping[mapping];
                  }
                  return (
                    <Col xs={mapping[index]}>
                      <Image
                        src={image}
                        fluid
                        rounded
                        style={{
                          cursor: 'pointer',
                          height: '100%',
                          objectFit: 'cover',
                          maxWidth: 100,
                          maxHeight: 100
                        }}
                        onClick={() => setImgIndex(index)}
                      />
                    </Col>
                  )
                })
              }
              {
                videos && videos.map((video) => {
                  return (
                    <Col xs={12}>
                      <ReactPlayer url={video} controls={true} className="react-player" />
                    </Col>
                  )
                })
              }
            </Row>
          )}
      </FalconLightBoxGallery>
    </div>
  );
};

FeedGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string)
};

export default FeedGallery;
