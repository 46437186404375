import React, { useContext, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

import Fraction from 'fraction.js';
import { useDispatch, useSelector } from "react-redux";

import { ProgressBar, Spinner } from "react-bootstrap";

import AppContext from "context/Context";
import { darkTheme, lightTheme } from "utils/theme";
import { PlatformIdentifier } from "../../../../../constants";
import { EditPopUp } from "./styles/facebook";

import { getMediaArrangement } from "redux/slices/publishable_media_arrangement_slice";

const PlayMediaInstagram = ({
  url,
  external,
  maximunHeight = 350,
  item,
  indexer,
  videoWidthLimit,
  videoHeightLimit,
  file,
  fullFile,
  editVideoFormat,
  checkingVideo,
  platform,
  videoInfoText,
  setOpenVideoEditArea,
}) => {
  const videoRef = useRef();
  const dispatch = useDispatch();
  const [comparableUrl, setComparableUrl] = useState("");
  const [validVideoType, setValidVideoType] = useState(true);
  const [videoValid, setVideoValid] = useState(false);
  const [videoMedia, setVideoMedia] = useState([]);
  const [progress, setProgress] = useState(null);
  const [videoSize, setVideoSize] = useState(null);
  const [fileTempUrl, setFileTempUrl] = useState("");
  const [loadTranscoder, setLoadTranscoder] = useState(false);
  const [transcodeText, setTranscodeText] = useState(null);
  const playButton = document.querySelector(".play-button");
  const video = document.getElementById(`video${indexer}`);
  const [playing, setPlaying] = useState(false);
  const { config } = useContext(AppContext);

  const media_items_arrangement = useSelector(getMediaArrangement)
  const socialPlatformVideoType = ["mp4", "mov"];

  useEffect(() => {
    if (platform === PlatformIdentifier.INSTAGRAM) {
      setVideoMedia(media_items_arrangement?.instagram?.media);
    }
  }, [platform, media_items_arrangement]);

  const handlePlayPause = () => {
    if (video?.paused) {
      video.play();
      setPlaying(true);
    } else {
      video?.pause();
      setPlaying(false);
    }
  };

  useEffect(() => {
    setVideoSize(item?.size / 1e6);
    setComparableUrl(item?.url)
    setFileTempUrl(item?.url);
    console.log(media_items_arrangement, 'media file');
  }, []);


  const checkVideoDimension = (height, width) => {
    let minVideoDimension = "9/16";
    let maxVideoDimension = "16/9";
    let decimalToConvert = new Fraction(
      width / height
    );
    let decimalToFraction = decimalToConvert.toFraction(true);
    const fractionStrToDecimal = str => str.split('/').reduce((p, c) => p / c);
    const mediaDimension = fractionStrToDecimal(decimalToFraction).toFixed(2);
    const minDimension = fractionStrToDecimal(minVideoDimension).toFixed(2)
    const maxDimension = fractionStrToDecimal(maxVideoDimension).toFixed(2);

    if (mediaDimension < minDimension || mediaDimension > maxDimension) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    setTimeout(() => {
      const extension = file?.name?.split(".").pop().toLowerCase()
      const foundExtensionType = socialPlatformVideoType.find(element => element === extension);
      if (!foundExtensionType && file) {
        setValidVideoType(false);
      }
      if (!item?.url?.startsWith('https://firebasestorage.googleapis.com/v0/b/postly') && !file) {
        setValidVideoType(false);
      }
      checkVideoDimension(videoRef.current?.videoHeight, videoRef.current?.videoWidth);
    }, 1000);
    setTranscodeText("Getting Ready")
  }, []);

  const loadTranscodeProgress = (
    <div style={{
      backgroundColor: '#ffffff',
      position: 'absolute',
      zIndex: 10,
      top: '50%',
      width: "80%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: 'column',
      padding: "0.5em 1em",
      transform: "translate(35px, 10px)"
    }}>
      <div style={{ fontSize: '13px', marginBottom: '10px' }}>
        <span style={{ marginRight: '10px' }}>{`${transcodeText}`}</span>
        {(progress === 0 || isNaN(progress) || progress == null) ?
          <Spinner animation="border" size="sm" variant="success" /> :
          <span>{`${progress}%`}</span>}
      </div>
      <ProgressBar
        style={{
          width: '100%'
        }}
        striped
        variant="success"
        now={`${progress || 0}`}
      />
    </div>
  );

  let visibilityHandler = !playing ? "visible" : "hidden";

  const play = (
    <svg
      style={{ visibility: `${visibilityHandler}` }}
      xmlns="http://www.w3.org/2000/svg"
      fill="#dbdbdb"
      width="88"
      height="88"
      viewBox="0 0 24 24"
    >
      <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z" />
    </svg>
  );

  const fixVideoFormatDialog = !validVideoType && (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        backgroundColor: "#949ca4AA",
        zIndex: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "80%",
            backgroundColor: `${config.isDark
              ? darkTheme.workSpaceSettingsHeaderBar
              : lightTheme.emptyButtonActive
              }`,
            padding: 10,
            borderRadius: 10,
          }}
        >
          <EditPopUp
            style={{
              color: `${!config.isDark && "black"}`,
              fontSize: 13,
            }}
          >
            {editVideoFormat}
          </EditPopUp>
        </div>
      }
    </div>
  );

  return (
    <>
      <div>{loadTranscoder && loadTranscodeProgress}</div>
      <div>{platform === PlatformIdentifier.INSTAGRAM && fixVideoFormatDialog}</div>
      {videoValid && platform === PlatformIdentifier.INSTAGRAM && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            backgroundColor: "#949ca4AA",
            zIndex: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "80%",
                backgroundColor: `${config.isDark
                  ? darkTheme.workSpaceSettingsHeaderBar
                  : lightTheme.emptyButtonActive
                  }`,
                padding: 10,
                borderRadius: 10,
              }}
            >
              <EditPopUp
                style={{
                  color: `${!config.isDark && "black"}`,
                  fontSize: 13,
                }}
              >
                {videoInfoText}
              </EditPopUp>
            </div>
          }
        </div>
      )}

      <div
        onClick={!item?.uploaded && handlePlayPause}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: 10,
          position: "relative",
          maxHeight: "350px",
        }}
      >
        {external ? (
          <ReactPlayer
            ref={videoRef}
            controls
            muted
            config={{
              file: {
                attributes: {
                  autoPlay: false,
                },
              },
            }}
            url={url}
            width={"100%"}
            height={"100%"}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        ) : (
          <video
            ref={videoRef}
            onContextMenu={false}
            controls={video?.paused ? false : true}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 10,
              maxHeight: "350px",
              position: "relative",
              zIndex: `${video?.paused ? 0 : 2}`,
            }}
            id="video"
          >
            <source src={url} type="video/mp4" />
          </video>
        )}
        {
          <div
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 10,
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: `${video?.paused ? 2 : -1}`,
            }}
          >
            {!item?.uploaded && play}
          </div>
        }
      </div>

      <div></div>
    </>
  );
};

export default PlayMediaInstagram;