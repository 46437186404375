import { RoutePaths } from 'constants';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getLoggedInUser } from 'redux/slices/user_slice';
import {
    getActiveWorkSpace,
} from "redux/slices/workspaceslice";
import { canLoggedInUserBeDemo, isWorkSpaceUnderAppSumo } from 'utils/user_utils';
import { isFreeWorkspace } from 'utils/workspace_utils';

const useProtectedRoute = (targets = [], route = RoutePaths.BILLING_STRIPE, reverseLogic) => {

    const navigate = useNavigate();
    const workSpace = useSelector(getActiveWorkSpace);
    const loggedInUser = useSelector(getLoggedInUser);
    const isDemoAccount = canLoggedInUserBeDemo(loggedInUser);
    const [status, setStatus] = useState(false);
    const checkTargetsAndCarryOutAction = () => {
        if (targets?.length === 0) {
            return;
        }

        let fault = null;
        for (const target of targets) {
            if (target?.toLowerCase() === 'free' && isFreeWorkspace(workSpace)) {
                fault = target;
                break
            }
            if ((target?.toLowerCase() === 'appsumo' || target?.toLowerCase() === 'ltd') && isWorkSpaceUnderAppSumo(workSpace)) {
                fault = target;
                break
            }
        }
        if (fault) {
            localStorage.setItem('section', 'AISection001')
            navigate(route);
        }
    }

    useEffect(() => {
        checkTargetsAndCarryOutAction();
    }, [targets]);

    return { status };
};

export default useProtectedRoute;
