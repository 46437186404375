import ContinousExpansionTextView from "./ContinuousExpansionTextView";
import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    setComposerFocused,
    setDisplayGetStarted,
    setFailedPostData,
    getFailedPostData,
    setOpenComposer
} from "../../../../redux/slices/postslice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import FeedGallery from "./FeedGallery";
import Divider from "components/common/Divider";
import { Button } from "react-bootstrap";
import { isPhoto, isVideo } from "utils/file_utils";
import StringUtils from "utils/string";
import { setPublishablePostTextBody } from "redux/slices/post_text_body_slice";
import { setPublishablePostMedia } from "redux/slices/post_media_slice";

export default function PostFailedReasonDialog(props) {

    const dispatch = useDispatch();
    const failedPostData = useSelector(getFailedPostData) ?? {};
    const { postTextBody, fullTargetName, mediaUrls = [], failedReason } = failedPostData;
    const { modalClose } = props;
    const mediaObjects = mediaUrls?.map((mediaEntry) => {
        let url;
        if (typeof mediaEntry === 'string') {
            url = mediaEntry;
            return {
                name: url,
                url,
                uploaded: true,
                extension: `.${StringUtils.substringAfterLast(StringUtils.substringBeforeLast(url, "?"), ".")}`
            }
        } else {
            return mediaEntry;
        }
    })


    const getAllFiles = () => {
        if (mediaUrls) {
            let mediaLength = mediaUrls.length;
            if (mediaLength > 0) {
                let images = mediaUrls.filter(mediaUrl => isPhoto(mediaUrl));
                let videos = mediaUrls.filter(mediaUrl => isVideo(mediaUrl));
                return { images, videos };
            }
        }
        return null
    }

    const gallery = getAllFiles();

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}>
            <h4
                type={'h2'}
                style={{
                    padding: 10,
                    paddingTop: 15,
                    paddingBottom: 20,
                }}>Failed To Publish Content to {fullTargetName}
            </h4>
            {
                (mediaUrls ?? []).length > 0 &&
                <FeedGallery files={gallery} />
            }
            <ContinousExpansionTextView
                inputStyle={{
                    padding: 10
                }} text={postTextBody ?? ""}
            />
            <Divider />
            <h6 style={{ paddingTop: 10, paddingLeft: 10 }}>Reason for failure:</h6>
            <div
                style={{
                    padding: 10,
                    background: '#E57373',
                    display: 'flex',
                    gap: 15,
                    alignItems: 'center'
                }}>
                <FontAwesomeIcon icon={faExclamation} />
                <span style={{ color: 'whitesmoke' }} dangerouslySetInnerHTML={{ __html: failedReason }} />
            </div>
            <Button
                onClick={() => {
                    if (mediaObjects) {
                        dispatch(setPublishablePostMedia(mediaObjects));
                    }
                    if (postTextBody) {
                        dispatch(setPublishablePostTextBody(postTextBody));
                    }
                    dispatch(setOpenComposer(true));
                    dispatch(setComposerFocused(true));
                    dispatch(setDisplayGetStarted(false));
                    dispatch(setFailedPostData(null));
                    setTimeout(() => {
                        modalClose();
                    }, 1000);
                }}
                style={{
                    marginTop: 50,
                    minHeight: 50
                }} variant={'primary'}>Recreate a new one
            </Button>
        </div>
    );
}