import Flex from 'components/common/Flex'
import React from 'react'
import { Card } from 'react-bootstrap'
import { BarLoader } from 'react-spinners'

const LinkMetadata = (props) => {
    const { linkMetadata, fetchingLinkMetadata } = props
    if (fetchingLinkMetadata) {
        return <div
            style={{
                height: 100,
                width: 300,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: 10,
            }}
        >
            <BarLoader
                color={'blue'}
                size={50}
            />
            <div>
                Searching for link metadata...
            </div>
        </div>

    }
    if (!linkMetadata?.url || !linkMetadata?.siteName) {
        return null
    }
    return (
        <Card
            className='shadow-sm p-3'
        >
            <Flex
                direction="column"
                justify="center"
                style={{
                    width: 300,
                }}
            >
                {
                    linkMetadata?.images?.length ?
                        <div
                            style={{
                                width: "100%",
                            }}
                        >
                            <img
                                src={linkMetadata?.images[0]}
                                alt=""
                                style={{
                                    width: "100%",
                                    height: "auto",
                                }}
                            />
                        </div> : null
                }
                <Flex
                    style={{
                        width: "100%",
                        padding: "5px 0",
                        gap: 5,
                    }}
                    direction="column"
                >

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            fontSize: 12,
                            gap: 5,
                        }}
                    >
                        {
                            linkMetadata?.favicons?.length ?
                                <img
                                    src={linkMetadata?.favicons[0]}
                                    alt=""
                                    style={{
                                        width: 15,
                                        height: 15,
                                    }}
                                /> : null
                        }
                        <span>
                            {linkMetadata?.siteName}
                        </span>
                    </div>

                    <div
                        style={{
                            width: "100%",
                            fontSize: 12,
                        }}
                    >
                        {linkMetadata?.description}
                    </div>
                </Flex>
            </Flex>
        </Card>
    )
}

export default LinkMetadata