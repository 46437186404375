import React, { useState } from 'react';
import APIService from '../../../../http/api_service';
import { PlatformIdentifier } from '../../../../constants';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Button, Card } from 'react-bootstrap';
import VerifiedBadge from 'assets/images/connected_badge.svg';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';

export default function GoogleBusinessAccount(props) {
    const workspace = useSelector(getActiveWorkSpace);
    let {
        id, name, user_id, self = false,
        picture, privacy, target = PlatformIdentifier.GOOGLE_MY_BUSINESS,
        community_url,
        accountId,
        display_name_prefixed,
        workSpace = workspace
    } = props;
    const [connected, setConnected] = useState(props.connected ?? false);
    const [processing, setProcessing] = useState(false);
    const [displayNameAsAvatar, setDisplayNameAsAvatar] = useState(false);

    const handleAddOrRemoveAction = () => {
        setProcessing(true);
        if (!connected) {
            let requestBody = {
                pageOrGroupData: {
                    id: id,
                    name: name,
                    self,
                    display_name_prefixed,
                    community_url,
                    picture: picture,
                    user_id: user_id,
                    privacy: privacy,
                    target: target,
                    accountId: accountId
                }
            }
            APIService.connectPageOrGroup(workSpace['_id'], PlatformIdentifier.GOOGLE_MY_BUSINESS, user_id, id, requestBody, (response, error) => {
                setProcessing(false);
                if (error) {
                    toast.error(error);
                    return;
                }
                let message = response['message'];
                toast.success(message);
                setConnected(true);
            });
        } else {
            APIService.disconnectPageOrGroup(workSpace['_id'], PlatformIdentifier.GOOGLE_MY_BUSINESS, target, user_id, id, (response, error) => {
                setProcessing(false);
                if (error) {
                    toast.error(error);
                    return;
                }
                let message = response['message'];
                toast.success(message);
                setConnected(false);
            });
        }
    }

    return (
        <Card
            style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
                padding: '0.8rem',
                width: 200,
                cursor: 'pointer',
                borderRadius: 10,
                transition: 'color 0.15s ease, border-color 0.15s ease',
            }}>
            {
                connected &&
                <img
                    src={VerifiedBadge}
                    style={{
                        height: 20,
                        width: 20,
                        position: 'absolute',
                        right: 10,
                        top: 10,
                    }}
                />
            }
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: 15
            }}>
                <div
                    style={{
                        width: 80,
                        height: 80,
                        borderRadius: 100,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`
                    }}
                >
                    {
                        displayNameAsAvatar ?
                            <span
                                style={{
                                    fontSize: 40,
                                    color: 'black',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    alignSelf: 'center',
                                }}>{name.charAt(0).toString().toUpperCase()}
                            </span>
                            : picture ? <img
                                src={picture}
                                alt={name}
                                onError={() => {
                                    setDisplayNameAsAvatar(true);
                                }}
                                style={{
                                    width: 60,
                                    height: 60,
                                    borderRadius: 100,
                                }}
                            /> : <span
                                style={{
                                    fontSize: 40,
                                    color: 'black',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    alignSelf: 'center',
                                }}>{name.charAt(0).toString().toUpperCase()}
                            </span>
                    }
                </div>
                {
                    self &&
                    <span style={{
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 20,
                        paddingRight: 20,
                        background: `#B2DFDB`,
                        borderRadius: 50,
                        textAlign: 'center',
                        fontSize: 10,
                        color: 'black',
                        fontWeight: 'bold'
                    }}>Personal Profile
                    </span>
                }
                <span
                    style={{
                        fontSize: 16,
                        fontWeight: 600,
                        textAlign: 'center',
                    }}>{`${name}`}
                </span>
                {
                    connected &&
                    <span style={{
                        color: '#7CB342',
                        fontSize: 14,
                        fontWeight: 600
                    }}>
                        Connected
                    </span>
                }
                <Button
                    variant={connected ? 'danger' : 'primary'}
                    onClick={handleAddOrRemoveAction}
                    isWorking={processing}
                    style={{
                        color: 'white',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    {processing ? "Please wait..." : (connected ? 'Disconnect' : 'Connect')}
                </Button>
            </div>
        </Card>
    );
}