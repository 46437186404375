import React, { useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import IconButton from './IconButton';
import { isPhoto, isVideo } from '../../utils/file_utils';
import ReactPlayer from "react-player";
import {
    setCanOpenThumbnailPreviewDialog,
    setThumbnailPreviewData
} from '../../redux/slices/postslice';
import { useDispatch } from 'react-redux';

export default function MediaObjectsPreviewContainer(props) {

    const dispatch = useDispatch();
    const {
        prefetchVideos = "auto",
        // navButtonStyles = {},
        mediaObjects = [],
        noFit = false,
        height = 200,
        errorFilesHandler,
        canPreview = false
    } = props;
    const canDisplayCloseButton = props.closeable ?? true;
    const mediaRemoveHandler = props.mediaRemoveHandler;
    const style = props.style ?? {};
    const [activeIndex, setActiveIndex] = useState(mediaObjects.length - 1);
    const [activeMediaObject, setActiveMediaObject] = useState(mediaObjects[mediaObjects.length - 1]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (mediaObjects.length > 0) {
            setActiveMediaObject(mediaObjects[mediaObjects.length - 1]);
            setActiveIndex(mediaObjects.length - 1);
        }
        setErrors({});
    }, [mediaObjects]);

    useEffect(() => {
        if (errorFilesHandler) {
            errorFilesHandler(errors);
        }
    }, [errors, errorFilesHandler]);

    const removeItem = (mediaObject) => {
        if (mediaRemoveHandler) {
            mediaRemoveHandler(mediaObject);
            if (mediaObjects.length > 0) {
                let next = Math.min(mediaObjects.length - 1, activeIndex + 1);
                setActiveMediaObject(mediaObjects[next]);
                setActiveIndex(next);
            }
        }
    }

    const PreviewActiveMediaObject = () => {
        if (isPhoto(activeMediaObject.url) && !activeMediaObject.is_video) {
            return <div
                style={{
                    width: '100%',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 10,
                }}>
                {
                    errors[activeMediaObject.url]
                        ? <span
                            style={{
                                color: 'red',
                                padding: 10,
                                height: 200,
                                textAlign: 'center'
                            }}>Unable to preview image. Image link is probably broken
                        </span>
                        : <img
                            onClick={() => {
                                if (canPreview) {
                                    dispatch(setThumbnailPreviewData(activeMediaObject));
                                    dispatch(setCanOpenThumbnailPreviewDialog(true));
                                }
                            }}
                            src={activeMediaObject.url}
                            alt={activeMediaObject.name}
                            style={{
                                objectFit: noFit ? 'contain' : 'cover',
                                width: '100%',
                                height: height,
                                borderRadius: 5,
                            }}
                            onError={() => {
                                if (activeMediaObject.url.toLowerCase().includes('blob')) {
                                    if (mediaRemoveHandler) {
                                        mediaRemoveHandler(activeMediaObject);
                                    }
                                } else {
                                    if (!errors[activeMediaObject.url]) {
                                        setErrors({
                                            [activeMediaObject.url]: true,
                                            ...errors
                                        });
                                    }
                                }
                            }}
                        />
                }
                {
                    canDisplayCloseButton &&
                    <Button
                        className="btn-light"
                        style={{
                            position: 'absolute',
                            left: '40%',
                            bottom: 10,
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            color: 'white'
                        }}
                        onClick={() => removeItem(activeMediaObject)}>
                        Remove
                    </Button>
                }
            </div>
        } else if (isVideo(activeMediaObject.url)) {
            return <div
                style={{
                    width: '100%',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 10,
                    justifyContent: 'center'
                }}>
                {
                    errors[activeMediaObject.url]
                        ? <span
                            style={{
                                color: 'red',
                                padding: 10,
                                height: 200,
                                textAlign: 'center'
                            }}>Error previewing Video
                        </span>
                        :
                        <ReactPlayer
                            controls
                            muted
                            config={{
                                file: {
                                    attributes: {
                                        autoPlay: false,
                                        preload: prefetchVideos
                                    }
                                }
                            }}
                            url={activeMediaObject.url}
                            width={'100%'}
                            height={height}
                            style={{
                                objectFit: 'cover',
                                width: '100%',
                                borderRadius: 10,
                                height: height
                            }}
                            onError={() => {
                                if (!errors[activeMediaObject.url]) {
                                    setErrors({
                                        [activeMediaObject.url]: true,
                                        ...errors
                                    });
                                }
                            }}
                        />
                }
                {
                    canDisplayCloseButton &&
                    <Button
                        variant="text"
                        style={{
                            position: 'absolute',
                            left: '40%',
                            bottom: 10,
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            color: 'white'
                        }}
                        onClick={() => removeItem(activeMediaObject)}>
                        Remove
                    </Button>
                }
            </div>
        }
        if (!errors[activeMediaObject.url]) {
            setErrors({
                [activeMediaObject.url]: true,
                ...errors
            });
        }
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    color: 'red'
                }}><span>Unresolvable file location</span>
            </div>
        );
    }

    const switchActiveMediaObject = (toLeft) => {
        let previous = activeIndex - 1;
        if (previous < 0) {
            previous = mediaObjects.length - 1;
        }
        let next = activeIndex + 1;
        if (next > mediaObjects.length - 1) {
            next = 0;
        }
        if (toLeft) {
            setActiveMediaObject(mediaObjects[previous]);
            setActiveIndex(previous);
        } else {
            setActiveMediaObject(mediaObjects[next]);
            setActiveIndex(next);
        }
    }

    return (
        <div
            style={{
                ...style,
                position: 'relative',
            }}>
            <div style={{
                width: '100%',
                height: height
            }}>
                <PreviewActiveMediaObject />
            </div>
            {
                mediaObjects.length > 1 &&
                <div
                    style={{
                        position: 'absolute',
                        bottom: 2,
                        left: 10,
                        zIndex: 1000,
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: 100,
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                    }}
                    forceUse={
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <span style={{
                                fontSize: 13,
                                color: 'white',
                                fontWeight: 600
                            }}>{`${activeIndex + 1}/${mediaObjects.length}`}
                            </span>
                        </div>
                    }>
                </div>
            }
            {
                mediaObjects.length > 1 &&
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'absolute',
                        width: '100%',
                        bottom: '55%',
                        padding: 10,
                        zIndex: 1,
                    }}>
                    <IconButton
                        size="medium"
                        onClick={() => {
                            switchActiveMediaObject(true);
                        }}
                        icon={faChevronLeft}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'absolute',
                            left: 5,
                            color: 'white',
                            backgroundColor: 'rgba(0,0,0,0.7)'
                        }}
                    />
                    <IconButton
                        size="medium"
                        onClick={() => {
                            switchActiveMediaObject(false);
                        }}
                        style={{
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            position: 'absolute',
                            right: 5,
                            justifyContent: 'center',
                            backgroundColor: 'rgba(0,0,0,0.7)'
                        }}
                        icon={faChevronRight}
                    />
                </div>
            }
        </div>
    );
}