import { faRightLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import TooltipBadge from 'components/common/TooltipBadge';
import { RoutePaths } from '../../../../constants';
import APIService from 'http/api_service';
import React, { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveWorkSpace, setActiveWorkspace } from 'redux/slices/workspaceslice';

const FirstWorkspaceTransferPromptCard = React.forwardRef((props, ref) => {

    const workSpace = useSelector(getActiveWorkSpace);
    const { ownerDetails, stateHandler } = props;
    const [preparingTransfer, setPreparingTransfer] = useState(false);

    const [formData, setFormData] = useState({
        senderEmail: ownerDetails?.email,
        recipientEmail: '',
        remainAsAMemberAfterTheTransfer: true
    });

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (formData.senderEmail === formData.recipientEmail) {
            toast.info("Oop! Both emails can't be the same", { theme: 'colored' });
            return;
        }
        setPreparingTransfer(true);
        APIService.preprocessWorkspaceTransfer(workSpace._id, formData, (response, error) => {
            setPreparingTransfer(false);
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { data } = response;
            data.name = `${data.first_name} ${data.last_name}`;
            stateHandler(1, { ...data, email: formData.recipientEmail }, ownerDetails, formData.remainAsAMemberAfterTheTransfer);
        });
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="senderEmail">
                <Form.Label>From</Form.Label>
                <Form.Control
                    type="email"
                    placeholder="Your email"
                    value={formData.senderEmail}
                    name="senderEmail"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="recipientEmail">
                <Form.Label>To
                    <TooltipBadge
                        tooltip="This is the email address of the user you want to transfer this workspace to"
                        icon="question-circle"
                    />
                </Form.Label>
                <Form.Control
                    type="email"
                    placeholder="Recipient email"
                    value={formData.recipientEmail}
                    name="recipientEmail"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Check type="checkbox" id="remainAsAmember" className="mb-0">
                <Form.Check.Input
                    type="checkbox"
                    name="remember"
                    checked={formData.remainAsAMemberAfterTheTransfer}
                    onChange={e =>
                        setFormData({
                            ...formData,
                            remainAsAMemberAfterTheTransfer: e.target.checked
                        })
                    }
                />
                <Form.Check.Label className="mb-0 text-700">
                    Remain as a member after the transfer
                </Form.Check.Label>
            </Form.Check>
            <div className="text-end">
                <Button
                    disabled={!formData.senderEmail || !formData.recipientEmail || preparingTransfer}
                    variant="primary" type="submit">
                    {preparingTransfer ? "Please wait..." : "Continue"}
                </Button>
            </div>
        </Form>
    )
});

const SecondWorkspaceTransferPromptCard = React.forwardRef((props, ref) => {

    const [transferred, setTransferred] = useState(false);
    const { from, to, remainAsAMemberAfterTheTransfer } = props;
    const [transferringWorkspace, setTransferringWorkspace] = useState(false);
    const workSpace = useSelector(getActiveWorkSpace);
    const [transferMessage, setTransferMessage] = useState();
    const navigate = useNavigate();

    const transferWorkspace = () => {
        setTransferringWorkspace(true);
        APIService.finalizeWorkspaceTransfer(workSpace._id, {
            senderEmail: from?.email,
            recipientEmail: to?.email,
            remainAsAMemberAfterTheTransfer
        }, (response, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { data, message } = response;
            setTransferMessage(message);
            setTransferred(true);
            setTimeout(() => {
                if (!data.membership_retained) {
                    navigate(RoutePaths.DEFAULT);
                    window.location.reload();
                }
            }, 3000);
        });
    }

    return (
        transferred ?
            <Flex
                direction={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                className={'gap-2 p-4'}>
                <FontAwesomeIcon
                    size='5x'
                    icon={'check-circle'}
                    style={{ color: 'green' }}
                />
                <span>{transferMessage}</span>
            </Flex>
            :
            <Flex direction={'column'} className={'gap-2'}>
                <Flex
                    wrap={'wrap'}
                    alignItems={'center'}
                    className={'gap-5 p-5'}
                    justifyContent={'center'}
                >
                    <Flex direction={'column'} alignItems={'center'}>
                        <h5>From:</h5>
                        <br />
                        {
                            from?.avatar ?
                                <Avatar size='3xl' src={from?.avatar} />
                                :
                                <Avatar size='3xl' name={from?.name ?? from.email} />
                        }
                        <h5>{from.name}</h5>
                        <h6>({from.email})</h6>
                    </Flex>
                    <FontAwesomeIcon icon={faRightLong} size={'4x'} />
                    <Flex direction={'column'} alignItems={'center'}>
                        <h5>To:</h5>
                        <br />
                        {
                            to?.avatar ?
                                <Avatar size='3xl' src={to?.avatar} />
                                :
                                <Avatar size='3xl' name={to?.name ?? to.email} />
                        }
                        <h5>{to?.name}</h5>
                        <h6>({to.email})</h6>
                    </Flex>
                </Flex>
                {
                    !remainAsAMemberAfterTheTransfer &&
                    <span style={{ textAlign: 'center' }}>When you click <i>Continue</i>, <b>{from.name}</b> will completely lose ownership of this workspace after the transfer.</span>
                }
                <div
                    className="text-end"
                    disabled={transferringWorkspace}>
                    <Button
                        onClick={() => {
                            transferWorkspace();
                        }}>{transferringWorkspace ? "Transferring Workspace..." : "Continue"}
                    </Button>
                </div>
            </Flex>
    )
});

const WorkspaceTransferPrompt = (props) => {

    const workSpace = useSelector(getActiveWorkSpace);
    const [ownerDetails, setOwnerDetails] = useState(props.ownerDetails);
    const [transferState, setTransferState] = useState(0);
    const [to, setTo] = useState();
    const [from, setFrom] = useState();
    const [remainAsAMemberAfterTheTransfer, setRemainAsAMemberAfterTheTransfer] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const stateHandler = (state, toData, fromData, remainAsAMemberAfterTheTransferData) => {
        setTo(toData);
        setFrom(fromData);
        setRemainAsAMemberAfterTheTransfer(remainAsAMemberAfterTheTransferData);
        setTransferState(state);
    }

    const fetchWorkspaceOwnerDetails = () => {
        APIService.fetchWorkSpaceOwner(workSpace._id, (response, err) => {
            if (err) {
                toast.error(err, { theme: 'colored' });
                if (err.toLowerCase().includes("owner not found")) {
                    dispatch(setActiveWorkspace(null));
                    navigate(RoutePaths.DEFAULT);
                }
                return;
            }
            setOwnerDetails(response.data);
        })
    }

    useEffect(() => {
        fetchWorkspaceOwnerDetails();
    }, []);

    return (
        !ownerDetails ?
            <Flex alignItems={'center'} justifyContent={'center'}>
                <Spinner animation='border' />
            </Flex>
            :
            transferState === 0 ?
                <FirstWorkspaceTransferPromptCard
                    ownerDetails={ownerDetails}
                    stateHandler={stateHandler}
                />
                : <SecondWorkspaceTransferPromptCard
                    to={to}
                    from={from}
                    remainAsAMemberAfterTheTransfer={remainAsAMemberAfterTheTransfer}
                />
    )

}

export default WorkspaceTransferPrompt;
