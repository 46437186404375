import styled, { keyframes } from "styled-components";

const fadeInAnimation = keyframes`
from {
  opacity: 0;
  margin-left:-10%;
}
to {
  opacity: 1;
  margin-left:0%;
}
`;

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;


export const ChunckedBox = styled.div`
  font-family: TwitterChirp, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif;
  height: fit-content;
  width: 100%;
  cursor: auto;
  /* border-style:solid;
  border-width:1px; */
  background-color: ${(props) => props.mainBgColor};
  position: relative;
  z-index: 2;
  bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  padding-top: 30px;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0 !important;
  }
`;

export const LeftSide = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 1%;
`;

export const MiddleSide = styled.div`
  flex: 8;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 5px;
`;

export const UpperRange = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
  font-size: 13px;
  @media only screen and (max-width: 768px) {
    font-size: 10px;
  }
`;

export const GridImageOne = styled.div`
  height: fit-content;
  width: 100%;
  position: relative;
`;

export const GridImage = styled.div`
  height: 300px;
  width: 100% !important;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: ${(props) => props.gridRow};
  position: relative;
  gap: 5px;
`;

export const CenterRange = styled.div`
  flex: 6;
  height: 100%;
  width: 100%;
  font-size: 14px;
  @media only screen and (max-width: 768px) {
    font-size: 11px;
  }
`;
export const LowerRange = styled.div`
  flex: 2;  
  height: 100%;
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
`;

export const ImageHolder = styled.div`
  height: 40px;
  width: 40px;
  borderradius: 100%;
  overflow: hidden;
  position: relative;
  z-index: 2;
  @media only screen and (max-width: 768px) {
    height: 45px;
    width: 45px;
  }
`;

export const RightSide = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  padding-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OverFlowSide = styled.div`
  height:auto;
  width:150px;
  padding:2%;
  position:relative;
  display:flex;
  flex-direction:column;
  background-color:${(props) => props.bgColorOverFlow};
  row-gap:10px;
`;
export const OverFlowList = styled.div`
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display:flex;
  justifyContent:flex-start;
  alignItems:center;
  cursor:pointer;
  color:${(props) => props.listColor};
  background-color:${(props) => props.bgColorOverFlow};
  font-size:16px;
  &:hover{
    color:${(props) => props.listHoverColor};
  }
  &:active{
    opacity:0;
  }
`;


export const PostImage = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${(props) => props.bgImg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  grid-row: ${(props) => props.gridSpanner};
  border-radius: 10px;
  border: ${(props) => props.gridBorder};
  position: relative;
`;

export const PostImageOne = styled.div`
  max-height: 100%;
  max-width: 100%;
  border: ${(props) => props.gridBorder};
  position: relative;
`;

export const ImageDelete = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  color: white;
  right: 5%;
  top: -5%;
  cursor: pointer;
  transition: transform 0.2s;
  :active {
    opacity:0;
  }
`;
export const AltAdd = styled.div`
  height: 13px;
  width: fit-content;
  background-color: black;
  display: flex;
  border-radius:3px;
  position: absolute;
  z-index: 10;
  color: white;
  left: 3%;
  bottom:10%;
  cursor: pointer;
  transition: transform 0.2s;
  padding:6px;
  padding-left:9px;
  padding-right:9px;
  justify-content: center;
  align-items: center;
  font-weight:bold;
  letter-spacing: 2px;
  font-size:9px;
  :active {
    opacity:0;
  }
`;

export const LineThrough = styled.div`
  border-left: ${(props) => props.lineThroughBorder};
  min-height: 91%;
  position: absolute;
`;
export const LineThroughTop = styled.div`
  border-left: ${(props) => props.lineThroughBorder};
  min-height: 20%;
  margin-top: -15%;
  position: absolute;
  @media only screen and (min-width: 768px) {
    min-height: 60% !important;
    margin-top: -11%;
  }
`;
export const RelativeSupport = styled.div`
  width: 100%;
  position: relative;
  background-color: red;
  height: fit-content;
`;

export const PencilEdit = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
  
  :active {
    opacity:0;
  }
`;

export const VideoEdit = styled.div`
  height: fit-content;
  width: fit-content;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
  :active {
    opacity:0;
  }
`;

export const TextEdit = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
  :active {
    opacity:0;
  }
`;

export const EditContainer = styled.div`
  min-height: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: center;
  gap:20px;
  border-radius: 8px;
  // margin-top: 10px;
  flex: 1;
`;

export const ParentSection = styled.div`
  position: relative;
  display: flex;
  /* column-gap: 10px; */
  height: 100%;
  width: 100%;
  animation: ${fadeInAnimation} 0.3s linear;
  cursor: auto;
  justify-content: center;
  align-items: flex-start;
`;

export const BottomHint = styled.div`
  position: relative;
  display: flex;
  height:30px;
  width:100%;
  justify-content: flex-end;
  align-items: flex-end;
`;
