import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { updateMagicEmail } from '../../redux/slices/authSlice';
import APIService from 'http/api_service';

const PasswordLessSignInForm = (props) => {

    const { stateHandler } = props;
    const [email, setEmail] = useState('');
    const dispatch = useDispatch();
    const [processing, setProcessing] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        setProcessing(true);
        if (email) {
            dispatch(updateMagicEmail(email));
            APIService.signUpWithMagicLink(email, (_result, error) => {
                setProcessing(false);
                if (error) {
                    toast.error(error, {
                        theme: 'colored'
                    });
                    return;
                }
                stateHandler(1);
            });
        }
    };

    return (
        <Form
            className="mt-4"
            onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
                <Form.Control
                    placeholder={'Email address'}
                    value={email}
                    name="email"
                    onChange={({ target }) => setEmail(target.value)}
                    type="email"
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Button
                    className="w-100"
                    type="submit"
                    disabled={!email || processing}
                >
                    {processing ? "Please wait..." : "Send Magic Password"}
                </Button>
            </Form.Group>
        </Form>
    );
};

PasswordLessSignInForm.propTypes = {
    layout: PropTypes.string
};
PasswordLessSignInForm.defaultProps = { layout: 'simple' };
export default PasswordLessSignInForm;
