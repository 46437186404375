import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import {
    setOpenExternalFilePickerDialog,
    setExternalFilePickerDialogOptions,
} from '../../redux/slices/postslice';
import PropTypes from 'prop-types';

function ExternalFileSelectionButton(props) {

    const dispatch = useDispatch();
    const {
        fileType = "image",
        multiple = false,
        fileSelectionHandler,
        externalFilePickerTitle,
        initiatorId,
        externalPickerPromptLabel,
        platformIdentifier,
        thumbnail = false,
    } = props;

    return (
        <div
            onClick={(e) => {
                dispatch(setExternalFilePickerDialogOptions({
                    fileType,
                    multiple,
                    fileSelectionHandler,
                    externalFilePickerTitle,
                    initiatorId,
                    externalPickerPromptLabel,
                    platformIdentifier,
                    thumbnail
                }));
                setTimeout(() => {
                    dispatch(setOpenExternalFilePickerDialog(true));
                }, 100);
            }}>
            <div
                style={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    marginLeft: 8,
                    cursor: 'pointer'
                }}>
                <FontAwesomeIcon
                    icon={'link'}
                />
                <span
                    style={{
                        flex: 1,
                        width: 140,
                        fontWeight: 500,
                        paddingLeft: 20,
                    }}>External Link
                </span>
            </div>
        </div>
    );
}

ExternalFileSelectionButton.propTypes = {
    fileType: PropTypes.oneOf(['image', 'video', 'audio', 'document']),
    multiple: PropTypes.bool,
    fileSelectionHandler: PropTypes.func.isRequired,
    externalFilePickerTitle: PropTypes.string,
    initiatorId: PropTypes.string,
    externalPickerPromptLabel: PropTypes.string,
    platformIdentifier: PropTypes.string,
    thumbnail: PropTypes.bool,
};
export default ExternalFileSelectionButton;
