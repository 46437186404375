import React, { useEffect, useState } from 'react'
import StatisticsCard from './stats-cards/StatisticsCard'
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import { getTotalUsers } from 'redux/slices/admin_slice';
import { useSelector } from 'react-redux';
import { RoutePaths } from 'constants';

const TotalUsers = () => {
    const [stat, setStat] = useState({
        title: 'Users',
        value: 0,
        decimal: false,
        suffix: '',
        prefix: '',
        valueClassName: 'text-warning',
        badgeBg: 'warning',
        badgeText: '',
        link: RoutePaths.ALL_USERS,
        linkText: 'See all',
        image: bg1
    })
    const totalUsers = useSelector(getTotalUsers)

    useEffect(() => {
        if (!totalUsers) return;
        setStat({
            ...stat,
            value: totalUsers
        })
    }, [totalUsers])


    return (
        <>
            <StatisticsCard stat={stat} style={{ minWidth: '12rem' }} />
        </>
    )
}

export default TotalUsers