import React, {
    useState,
    useEffect,
    useImperativeHandle,
    useRef
} from 'react';
import {
    setGMBPostType,
    getGMBPostType,
    getUploadableBulkContent,
    setUploadableBulkContent
} from '../../../../../redux/slices/postslice';
import { useSelector, useDispatch } from 'react-redux';
import { findPlatformByIdentifier } from 'components/app/platforms';
import { PlatformIdentifier } from '../../../../../constants';
import EventPostPanel from './event_post_panel';
import OfferPanel from './offer_post_panel';
import CTAPostPanel from './cta_post_panel';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Card, Form } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import Divider from 'components/common/Divider';
import PrePublishErrorResolver from '../PrePublishErrorResolver';
import { getPublishablePostMedia } from 'redux/slices/post_media_slice';

function GMBOptionsField(props, ref) {

    const {
        bulkContentMode = false,
        rowIndex = 0
    } = props;
    const uploadableBulkContent = useSelector(getUploadableBulkContent);
    const bulkContentRow = uploadableBulkContent[rowIndex] ?? {};
    const bulkContentPostTypeMappings = {
        "EVENT": "EVENT",
        "STANDARD": "STANDARD",
        "OFFER": "OFFER",
        "What's New": "STANDARD",
        "Event Post": "EVENT",
        "Offer Post": "OFFER"
    };
    const [bulkContentPostType, setBulkContentPostType] = useState(bulkContentPostTypeMappings[bulkContentRow?.google_my_business_post_type]);
    const eventPanelRef = useRef();
    const offerPanelRef = useRef();
    const [gmbErrorMessage, setGMBErrorMessage] = useState(props.gmbErrorMessage);
    const dispatch = useDispatch();
    const postType = useSelector(getGMBPostType);
    const googleMyBusinessPlatform = findPlatformByIdentifier(PlatformIdentifier.GOOGLE_MY_BUSINESS);
    const postTypeOptions = [
        {
            label: "What's New", value: 'STANDARD'
        },
        {
            label: 'Event Post', value: 'EVENT'
        },
        {
            label: 'Offer Post', value: 'OFFER'
        }
    ];
    const postMedia = useSelector(getPublishablePostMedia);

    useEffect(() => {
        setGMBErrorMessage(props.gmbErrorMessage);
    }, [props.gmbErrorMessage]);

    useEffect(() => {
        if (postType) {
            setGMBErrorMessage(null);
        }
    }, [postType]);

    useImperativeHandle(ref, () => ({
        canPostToGMB: () => {
            if (postType === 'EVENT') {
                let eventPanelError = eventPanelRef.current.getErrorMessage();
                if (eventPanelError) {
                    setGMBErrorMessage(eventPanelError);
                    return false;
                }
            }
            if (postType === 'OFFER') {
                let offerPanelError = offerPanelRef.current.getErrorMessage();
                if (offerPanelError) {
                    setGMBErrorMessage(offerPanelError);
                    return false;
                }
            }
            setGMBErrorMessage(null);
            return true;
        }
    }));

    const updateBulkContentRow = (updatedRow) => {
        let uploadableBulkContentCopy = [...uploadableBulkContent];
        uploadableBulkContentCopy[rowIndex] = updatedRow;
        dispatch(setUploadableBulkContent(uploadableBulkContentCopy));
    }

    const initPostPanelFromSelectedPostType = () => {
        if (bulkContentMode && !bulkContentPostType) {
            return '';
        }
        if (!bulkContentMode && !postType) {
            return '';
        }
        if (postType === 'EVENT' || bulkContentPostType?.toLowerCase().includes('event')) {
            return <EventPostPanel
                titleLabel={"event"}
                ref={eventPanelRef}
                bulkContentMode={bulkContentMode}
                bulkContentRow={bulkContentRow}
                rowUpdateHandler={updateBulkContentRow}
                eventTitle={bulkContentRow?.google_my_business_event_title}
                callToActionButton={bulkContentRow?.google_my_business_call_to_action_button}
                callToActionUrl={bulkContentRow?.google_my_business_call_to_action_url}
                startDateAndTime={bulkContentRow?.google_my_business_event_start_date_and_time}
                endDateAndTime={bulkContentRow?.google_my_business_event_end_date_and_time}
            />
        }
        if (postType === 'OFFER' || bulkContentPostType?.toLowerCase().includes('offer')) {
            return <OfferPanel
                titleLabel={"offer"}
                ref={offerPanelRef}
                bulkContentMode={bulkContentMode}
                bulkContentRow={bulkContentRow}
                rowUpdateHandler={updateBulkContentRow}
                offerTitle={bulkContentRow?.google_my_business_offer_title}
                startDateAndTime={bulkContentRow?.google_my_business_offer_start_date_and_time}
                endDateAndTime={bulkContentRow?.google_my_business_offer_end_date_and_time}
                couponCode={bulkContentRow?.google_my_business_offer_coupon_code}
                offerRedeemUrl={bulkContentRow?.google_my_business_offer_redeem_url}
                termsAndConditions={bulkContentRow?.google_my_business_offer_terms_and_conditions}
            />
        }
        return <CTAPostPanel
            bulkContentMode={bulkContentMode}
            bulkContentRow={bulkContentRow}
            rowUpdateHandler={updateBulkContentRow}
            callToActionButton={bulkContentRow?.google_my_business_call_to_action_button}
            callToActionUrl={bulkContentRow?.google_my_business_call_to_action_url}
        />
    }

    return (
        <Card className='mb-3'>
            <FalconCardHeader
                title={bulkContentMode ? "GMB" : "For Google My Business"}
                endEl={
                    <div
                        style={{
                            width: 20,
                            height: 20,
                            borderRadius: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: 'white'
                        }}>
                        <img
                            src={googleMyBusinessPlatform.svg}
                            alt=''
                            style={{
                                color: 'white',
                                height: 25,
                                width: 25
                            }}
                        />
                    </div>
                } />
            <Card.Body className="bg-light">
                <Flex direction={'column'} className={'gap-3'}>
                    <Form.Group>
                        <Form.Label>Post Type</Form.Label>
                        <Form.Select
                            value={bulkContentMode ? bulkContentPostType : postType}
                            placeholder={'Select Post Type'}
                            onChange={(e) => {
                                let value = e.target.value;
                                if (bulkContentMode) {
                                    let updatedRow = { ...bulkContentRow }
                                    if (!value) {
                                        value = " ";
                                    }
                                    updatedRow.google_my_business_post_type = value;
                                    setBulkContentPostType(value);
                                    updateBulkContentRow(updatedRow);
                                } else {
                                    dispatch(setGMBPostType(value));
                                }
                            }}
                        >
                            {
                                postTypeOptions.map((x) => {
                                    return (<option value={x.value}>{x.label}</option>)
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                    {
                        initPostPanelFromSelectedPostType()
                    }
                    {
                        gmbErrorMessage &&
                        <PrePublishErrorResolver
                            error={gmbErrorMessage}
                        />
                    }
                    {
                        !bulkContentMode &&
                        <Flex direction={'column'}>
                            <Divider />
                            <Alert variant="warning" className="p-2 mb-0">
                                <Flex>
                                    <FontAwesomeIcon icon="exclamation-triangle" className="fs-2" />
                                    <div className="ms-3 flex-1">
                                        <h4 className="alert-heading">Please note</h4>
                                        1. Publish only one image at a time.
                                        <br />
                                        2. Video posts are not allowed at the moment due to API restrictions.
                                    </div>
                                </Flex>
                            </Alert>
                        </Flex>
                    }
                </Flex>
            </Card.Body>
        </Card>
    )
}
export default React.forwardRef(GMBOptionsField);