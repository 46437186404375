import styled from 'styled-components';

export const BoardSectionEntryContainer = styled.div`
background:${props => props.selected ? '#9CCC65' : '#ECEFF1'};
`;

export const AudiencePanelContainer = styled.div`
`;

export const CheckboxContainer = styled.div`
display:flex;
align-items:center;
`;

export const SectionHeaderBase = styled.div`
`;

export const AudienceListItemContainer = styled.div`
`;

export const PlatformsBottomShadow = styled.div`
position: absolute;
background: rgba(0, 0, 0, 0.7);
height: 30px;
width: 100%;
bottom: 0px;
z-index: 100;
&:after{
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  background-image: linear-gradient(to bottom, rgb(246 249 252),#455A647B);
  pointer-events: none;
}
`;
