import { useEffect, useState } from "react";
import ReactLoading from 'react-loading';
import Flex from 'components/common/Flex';
import { lightTheme } from 'utils/theme';
import APIService from '../http/api_service';
import {
    allPlans
} from '../components/app/account/pricing/pricingDataNew';

export default function PaymentPortalProxy() {

    const [successMessage, setSuccessMessage] = useState();
    const [successHeader, setSuccessHeader] = useState("Success!");
    const [processingPayment, setProcessingPayment] = useState(false);
    const [darkMode, setDarkMode] = useState(false);
    const [cancelling, setCancelling] = useState(false);
    const [initializingPaymentIntent, setInitializingPaymentIntent] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search.replace("amp;", ""));
        const dataId = queryParams.get("data-id");
        const apiKey = queryParams.get("api_key");
        const theme = queryParams.get("theme");
        if (theme) {
            if (theme === 'dark') {
                setDarkMode(true);
            } else {
                setDarkMode(false);
            }
        }
        if (dataId) {
            fetchPaymentIntentData(dataId, apiKey);
        }
    }, []);

    const retrievePlanDetails = (plan) => {
        return allPlans.find(x => x.plan === plan);
    }

    const processPaymentData = async (paymentData, apiKey) => {
        let plan = retrievePlanDetails(paymentData.plan?.Key ?? paymentData.plan.plan);
        const forcedSocialAccounts = paymentData.plan.SocialAccounts;
        if (plan) {
            plan = JSON.parse(JSON.stringify(plan));
            if (forcedSocialAccounts) {
                plan.SocialAccounts = forcedSocialAccounts;
            }
        }
        const user = paymentData.workspace_owner;
        const workspace = paymentData.workspace;
        const billedYearly = paymentData.subscriptionMode === "Yearly";
        const subscribe = paymentData.subscribe;
        const cancel = paymentData.cancel;
        const update = paymentData.update;
        if (subscribe) {
            handleSubscription(plan, billedYearly, user, workspace, apiKey);
        } else if (update) {
            const updateUrl = paymentData.update_url;
            if (updateUrl) {
                window.location = updateUrl;
            }
        } else if (cancel) {
            const cancelUrl = paymentData.cance_url;
            if (cancelUrl) {
                window.location = cancelUrl;
            } else {
                setCancelling(true);
                const forAIWriter = plan?.passthrough?.for_ai_writer;
                const forTelegram = plan?.passthrough?.for_telegram_bot;
                if (forAIWriter) {
                    try {
                        await APIService.cancelAISubscriptionSync(apiKey);
                        setCancelling(false);
                        setSuccessHeader("Success!");
                        setSuccessMessage("Active AI Writer subscription cancelled successfully!");
                    } catch (e) {
                        setError("Error cancelling AI Writer subscription. Please try again later");
                    }
                } else if (forTelegram) {

                } else {
                    try {
                        await APIService.cancelSubscriptionSync(apiKey);
                        setCancelling(false);
                        setSuccessHeader("Success!");
                        setSuccessMessage("Active subscription cancelled successfully!");
                    } catch (e) {
                        setError("Error cancelling subscription. Please try again later");
                    }
                }
            }
        }
    }

    const fetchPaymentIntentData = async (dataId, apiKey) => {
        try {
            const { data } = await APIService.fetchTimedPaymentData(apiKey, dataId);
            setInitializingPaymentIntent(false);
            if (data) {
                const paymentData = data.data;
                if (paymentData) {
                    processPaymentData(paymentData, apiKey);
                }
            } else {
                setError("Failed to initialize payment process. Mandatory parameter was null");
            }
        } catch (e) {
            setError("Failed to initialize payment process. Please reload the page and try again");
            setInitializingPaymentIntent(false);
        }
        return null;
    }

    const handleSubscription = (pricingData, billedYearly, user, workspace, apiKey) => {
        const Paddle = window.Paddle;
        const oneOff = pricingData.one_off ?? false;
        Paddle.Checkout.open({
            quantity: pricingData.SocialAccounts ?? 1,
            product: parseInt(billedYearly ? pricingData.AnnualSub : pricingData.MonthlySub),
            email: user?.email,
            plan: pricingData.plan,
            allowQuantity: false,
            passthrough: JSON.stringify({
                accountId: user._id,
                subscriptionMode: billedYearly ? "Yearly" : "Monthly",
                workspaceId: workspace._id,
                plan: pricingData.plan,
                quantity: pricingData.SocialAccounts ?? 1,
                SocialAccounts: pricingData.SocialAccounts ?? 1,
                prorate: true,
                prorated_keys: ["SocialAccounts"],
                coupon: pricingData.coupon,
                ...(pricingData.passthrough ?? {})
            }),
            successCallback: () => {
                setProcessingPayment(true);
                const type = (pricingData?.passthrough ?? {})["additional_social_accounts"] ? "additional_social_accounts" : "new_subscription";
                pollSubscriptionStatus({
                    heartbeat: 2000,
                    type,
                    loggedInUser: user,
                    currentWorkspace: workspace,
                    pricingData,
                    apiKey,
                    done: (message) => {
                        setProcessingPayment(false);
                        setSuccessHeader("Success!");
                        if (message) {
                            setSuccessMessage(message);
                        } else {
                            setSuccessMessage(oneOff ? "Payment successful" : `You have successfully subscribed to the ${pricingData.title} plan`);
                        }
                    }
                });
            },
        });
    };

    const pollSubscriptionStatus = ({
        heartbeat,
        loggedInUser,
        currentWorkspace,
        pricingData,
        apiKey,
        type,
        done
    }) => {
        const intervalId = setInterval(async () => {
            try {
                const { data } = await APIService.fetchUserLatestData(apiKey);
                const localUserPlan = loggedInUser.active_plan;
                let latestUserData = data;
                const newPlan = latestUserData.active_plan;
                const referencePlan = pricingData.plan;
                if (type === "new_subscription" && newPlan.Key === referencePlan) {
                    clearInterval(intervalId);
                    done();
                } else if (type === "new_subscription" && pricingData?.passthrough?.for_ai_writer) {
                    const activeAIWriterSubscription = latestUserData.active_ai_writer_subscription;
                    if (activeAIWriterSubscription != null) {
                        clearInterval(intervalId);
                        done();
                    }
                } else if (type === "new_subscription" && pricingData?.passthrough?.for_telegram_bot) {
                    const existingBotSlots = currentWorkspace.creatable_bot_slots ?? 0;
                    try {
                        const { data } = await APIService.fetchWorkSpaceSync(currentWorkspace._id, apiKey);
                        const latestWorkspaceData = data;
                        const newBotSlots = latestWorkspaceData.creatable_bot_slots ?? 0;
                        if (newBotSlots > existingBotSlots) {
                            clearInterval(intervalId);
                            done();
                        }
                    } catch (e) {
                        clearInterval(intervalId);
                        done();
                    }
                } else if (type === "additional_social_accounts" && newPlan.Key === referencePlan) {
                    const existingCount = parseInt(localUserPlan.SocialAccounts);
                    const newCount = parseInt(newPlan.SocialAccounts);
                    if (!isNaN(existingCount) && !isNaN(newCount)) {
                        if (newCount > existingCount) {
                            clearInterval(intervalId);
                            const diff = newCount - existingCount;
                            done(`You have successfully bought ${diff} more social accounts`);
                        }
                    }
                }
            } catch (e) {
                setError("Sorry, something went wrong while verifying payment");
                clearInterval(intervalId);
            }
        }, heartbeat);
    }

    return (
        <div
            style={{
                flexDirection: 'column',
                height: '100vh',
                display: 'flex',
                background: darkMode ? "#0b1727" : 'white',
            }}>
            {
                error && <span className="text-danger">{error}</span>
            }
            {
                initializingPaymentIntent &&
                <Flex
                    direction={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    style={{
                        height: '100vh'
                    }}>
                    <ReactLoading color={lightTheme.primary} type="bubbles" />
                </Flex>
            }
            {
                processingPayment &&
                <Flex
                    direction={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    style={{
                        height: '100vh'
                    }}>
                    <ReactLoading color={lightTheme.primary} type="bubbles" />
                    <span>Verifying payment...</span>
                </Flex>
            }
            {
                cancelling &&
                <Flex
                    direction={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    style={{
                        height: '100vh'
                    }}>
                    <ReactLoading color={lightTheme.primary} type="bubbles" />
                    <span>Cancelling...</span>
                </Flex>
            }
            {
                successMessage &&
                <Flex
                    direction={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    style={{
                        height: '100vh',
                        gap: 3
                    }}>
                    <h5>{successHeader}</h5>
                    <span className="text-success">{successMessage}</span>
                </Flex>
            }
        </div>
    );
}