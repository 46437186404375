import styled, { keyframes } from "styled-components";

export const MainContainer = styled.div`
    height: 250px;
    /* width: 200px !important; */
`;

export const Header = styled.div`
    height: 50px;
    width: 100%;
    padding: 2%;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const TagContainer = styled.div`
    height: 200px;
    width: 100%;
    padding: 2%;
    font-size: 15px;
`

export const EachTagList = styled.div`
    height: 50px;
    width: 100%;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
