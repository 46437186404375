import Flex from "components/common/Flex";
import { ChatContext } from "context/Context";
import { useContext, useEffect, useState } from "react";
import { Card, Modal, Spinner, Tab } from "react-bootstrap";
import ChatProvider from "./ChatProvider";
import ChatContent from "./content/ChatContent";
import ChatSidebar from "./sidebar/ChatSidebar";
import { useDispatch, useSelector } from "react-redux";
import { getActiveWorkSpace, setSearchHint } from "redux/slices/workspaceslice";
import ChatSetup from "./setup/ChatSetup";
import MessagingAccountsConnector from "./setup/MessagingAccountsConnector";
import { emitReadMessages } from "./chatSocket";
import InstaCommentsHeader from "../comments/useable-components/InstaCommentsHeader";
import { findPlatformByIdentifier } from "../platforms";
import { PlatformIdentifier } from "constants";
import { getInstagramUserId, getSelectedPageForPostCommentsView, setInstagramAccountPageId } from "redux/slices/instagramComments_slice";
import APIService from "http/api_service";
import { toast } from "react-toastify";
import InstaCommentPlatformHeader from "../comments/useable-components/InstaCommentPlatformHeader";

export const markMessageAsRead = (
  chat,
  setHideSidebar,
  setIsOpenThreadInfo,
  setActiveRoomId,
  setSenderId,
  currentChats,
  conversationsDispatch,
  currentChatsDispatch,
  setCurrentConversation,
  setScrollToBottom
) => {
  setHideSidebar(false);
  setIsOpenThreadInfo(false);

  const currentChat = currentChats?.find(
    (thread) => thread?.room_id === chat?.room_id
  );
  const otherUserId = currentChat?.participants?.find(
    (id) => id !== chat?.user_id
  );

  setActiveRoomId(currentChat?.room_id);
  setSenderId(otherUserId);

  const updatedMessages = currentChat?.messages?.map((message) => {
    if (message?.sender !== currentChat?.user_id) {
      if (!message?.read) {
        return { ...message, read: true, status: "seen" };
      }
    }
    return message;
  });

  //? Create a new object with the updated messages property
  const updatedChat = {
    ...currentChat,
    messages: updatedMessages,
  };

  const lastMessage = updatedChat?.messages[updatedChat?.messages.length - 1];
  conversationsDispatch({
    type: "ADD_MESSAGE",
    payload: {
      ...updatedChat,
      last_message_read: lastMessage.read,
    },
    room_id: updatedChat?.room_id,
  });

  currentChatsDispatch({
    type: "ADD_MESSAGE",
    payload: {
      ...updatedChat,
      last_message_read: lastMessage.read,
    },
    room_id: updatedChat?.room_id,
  });

  const lastSender =
    updatedChat?.messages[updatedChat.messages.length - 1]?.sender;
  //? Send to socket
  if (updatedChat && otherUserId && updatedChat.user_id !== lastSender) {
    emitReadMessages(updatedChat?.room_id, otherUserId);
  }

  setCurrentConversation(updatedChat);
  setScrollToBottom(true);
};

const ChatTab = ({account, platform}) => {
  const dispatch = useDispatch();
  const workSpace = useSelector(getActiveWorkSpace);
  const [chatSettings, setChatSettings] = useState(
    workSpace.chat_settings ?? {}
  );
  const [pages, setPages] = useState([]);


  const { hideSidebar, setHideSidebar } = useContext(ChatContext);

  const [openAccountsConnector, setOpenAccountsConnector] = useState(false);
  const selectedPageForPostCommentsView = useSelector(getSelectedPageForPostCommentsView);

  const reloadPage = () => {
    setOpenAccountsConnector(false);
    let latestChatSettings = workSpace?.chat_settings;
    setChatSettings({ ...latestChatSettings });
  };

  const connectAccounts = () => {
    setOpenAccountsConnector(true);
  };

  useEffect(() => {
    dispatch(setSearchHint("Search contacts..."));
  }, []);
  useEffect(() => {
    APIService.fetchPagesAndGroups(workSpace, account.identifier, account.user_id, (response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      const { data } = response;
      let facebookPages = [];
      for (let page of data) {
        if (page.is_page) {
          facebookPages.push(page);
        }
      }
      setPages(facebookPages);
    });
  }, [account]);

  return (
    <>
    <InstaCommentPlatformHeader pages={pages} platform={platform} account={account} />
    {selectedPageForPostCommentsView?.id && <Tab.Container id="social-inbox-tab" defaultActiveKey="0">
      <Card className="card-chat overflow-hidden">
        {/* {!chatSettings.ready_for_messaging && (
          <ChatSetup connectAccounts={connectAccounts} />
        )} */}
        {/* {chatSettings.ready_for_messaging && ( */}
          <Card.Body as={Flex} className="p-0 h-100">
            <ChatSidebar
              connectAccounts={connectAccounts}
              hideSidebar={hideSidebar}
            />
            <ChatContent setHideSidebar={setHideSidebar} />
          </Card.Body>
        {/* )} */}
      </Card>

      <Modal
        show={openAccountsConnector}
        onHide={() => {
          reloadPage();
        }}
        onExit={() => {
          reloadPage();
        }}
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Connect Accounts to Inbox</Modal.Title>
        </Modal.Header>
        <MessagingAccountsConnector />
      </Modal>
    </Tab.Container>}
    </>
  );
};

const Chat = () => {
  const dispatch = useDispatch();
  const platform = findPlatformByIdentifier(PlatformIdentifier.FACEBOOK);
  const [fetchingAccounts, setFetchingAccounts] = useState(false);
  const [connectedAccounts, setConnectedAccounts] = useState();
  console.log("🚀 ~ PostsAndComments ~ connectedAccounts:", connectedAccounts)
  const workSpace = useSelector(getActiveWorkSpace);
  const userId = useSelector(getInstagramUserId);

  const fetchConnectedAccounts = () => {
    // if (WorkSpaceManager.isFreeWorkspace()) {
    //     dispatch(setPopupUpgradeAlert(true))
    //     return;
    // }
    setFetchingAccounts(true);
    APIService.fetchConnectedAccounts(workSpace['_id'], platform.identifier, (response, error) => {
      setFetchingAccounts(false);
      if (error) {
        toast.error(error);
        return;
      }
      let { data } = response || {};
      if (!data?.length) {
        toast.error(`Please connect ${platform.identifier} account to view facebook messages`, { theme: 'colored' });
        return;
      }
      if (data && data?.length > 0) {
        let refinedData = data?.filter((x) => x?.user_id === userId);
        refinedData = refinedData?.map((account, index) => {
          let { user_id, user_name, user_photo, ...platform } = findPlatformByIdentifier(account.target);
          let newAccount = { ...account, ...platform };
          return newAccount;
        })
        setConnectedAccounts(refinedData);
      }
    })
  }

  useEffect(() => {
    if (platform) {
      fetchConnectedAccounts();
      dispatch(setInstagramAccountPageId(null));
    }
  }, [userId]);

  return (
    <ChatProvider>
      <InstaCommentsHeader platform={platform} />
      {fetchingAccounts &&
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          className={'vh-100'}
        >
          <Spinner animation='border' size={40} />
        </Flex>}
      {connectedAccounts && !fetchingAccounts && (
        (() => {
          const account = connectedAccounts.find(account => account.user_id === userId);
          return account ? (
            <div key={account._id} id={account._id}>
              <ChatTab
                account={account}
                platform={platform} />
            </div>
          ) : null;
        })()
      )}
      {/* <ChatTab /> */}
    </ChatProvider>
  );
};

export default Chat;