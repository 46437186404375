import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    fbMessagesAccountPageId: null,
    userId: null,
    selectedPage: {},
    postFeeds: []
    
}
export const facebookMessagesSlice = createSlice({
    name: 'facebook_messages',
    initialState,
    reducers: {
        setFbMessagesAccountPageId: (state, action) => {
            state.fbMessagesAccountPageId = action.payload;
        },
        setFbMessagesUserId: (state, action) => {
            state.userId = action.payload;
        },
        setSelectedPageForMessagesView: (state, action) => {
            state.selectedPage = action.payload;
        },
        setPostFeeds: (state, action) => {
            state.postFeeds = action.payload;
        },
       
    }
});
export const getFbMessagesAccountPageId = (state) => state.facebook_messages.fbMessagesAccountPageId;
export const getFbMessagesUserId = (state) => state.facebook_messages.userId;
export const getSelectedPageForMessagesView = (state) => state.facebook_messages.selectedPage;
export const getPostFeeds = (state) => state.facebook_messages.postFeeds;


export const {
    setFbMessagesAccountPageId,
    setFbMessagesUserId,
    setSelectedPageForMessagesView,
    setPostFeeds,
} = facebookMessagesSlice.actions;

export default facebookMessagesSlice.reducer;