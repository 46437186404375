import styled, { keyframes } from "styled-components";

export const BillerContainer = styled.div`
    position: fixed;
    z-index: 1000;
    display:flex;
    justify-content:center;
    align-items:center;
    background-color: #09131b98;
    height: 100vh;
    width: 100%;
`;
export const BillerBox = styled.div`
    height: auto;
    width: 500px;
    /* background-color: white; */
    border-radius: 10px;
    padding: 20px;
    /* display:flex;
    justify-content:center;
    align-items:center; */
`;
export const Header = styled.div`
    text-align: left;
    font-size: 15px;
    font-weight: 700;
    /* color: black; */
`;
export const MiddleLayer = styled.div`
    margin-top: 30px;
    /* height: 150px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
`;
export const DpBox = styled.div`
    height: 50px;
    width: 50px;
    border-radius: 100%;
    background-image: url(${(props) => props.bgUrl});
    background-position: center;
    background-size: cover;
`;
export const AmountText = styled.div`
    height: 20px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: orange; */
    font-size: 14px;
    /* color: black; */
`;
export const ActionsBox = styled.div`
    height: 30px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
`;
export const SliderValueBox = styled.div`
    
`;
export const BottomButtonsDiv = styled.div`
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
`;
export const Table = styled.table`
  margin-top: 30px;
  width: 100%;
  border-collapse: collapse;
`;
export const TableHead = styled.thead`
 text-align: left;
`;
export const TableBody = styled.tbody`
 text-align: left;
`;
export const Tr = styled.tr`
  &:nth-child(even) {
  /* background-color: #c7bfff; */
}
`;
export const Th = styled.th`
/* border: 1px solid black; */
  border-collapse: collapse;
  padding: 10px;
`;
export const Td = styled.td`
 /* border: 1px solid black; */
  border-collapse: collapse;
  padding: 10px;

`;

