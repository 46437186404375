import React, { useState, useEffect } from 'react';
import Flex from 'components/common/Flex';
import { useNavigate } from 'react-router-dom';
import { getSchedulerPricingData, pricingDataNew } from './PricingData';
import {
    Card, Col, Modal,
    Row, Spinner, Button, Form
} from 'react-bootstrap';
import PricingDefaultHeader from './PricingDefaultHeader';
import PickAPlanPricingCard from './PickAPlanPricingCard';
import { useSelector, useDispatch } from 'react-redux';
import {
    canOpenWorkspaceSearch, getActiveWorkSpace, getBilledYearly,
    getCachedWorkSpaces,
    getReloadPage, searchWorkSpace
} from 'redux/slices/workspaceslice';
import usePubSub from 'pubsub';
import { toast } from 'react-toastify';
import { RoutePaths } from '../../../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkmark } from 'react-checkmark'
import {
    simulateMouseClick
} from '../../../../utils/gen';
import {
    getLoggedInUser,
    getInitiateActivationOnPremiumPlan,
    getLoggedInUserFullName,
    setLoggedInUser
} from "redux/slices/user_slice";
import ProfileDropdown from '../../../navbar/top/ProfileDropdown';
import Divider from 'components/common/Divider';
import bgShape from 'assets/img/illustrations/bg-shape.png';
import shape1 from 'assets/img/illustrations/shape-1.png';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import APIService from 'http/api_service';
import jwt_decode from "jwt-decode";
import Logo from 'components/common/Logo';
import RichInfoBar from 'components/common/RichInfoBar';
import WorkSpaceSearch from 'components/app/workspace-search/WorkSpaceSearch';
import {
    getActiveMetric,
    setCollectMetric,
} from "redux/slices/analytics_slice";
import { v4 as uuid } from "uuid";
import { faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';
import IconButton from 'components/common/IconButton';
import CryptoJS from 'crypto-js';

const MandatoryAccountUpgrade = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openCheckoutCompletedPopup, setOpenCheckoutCompletedPopup] = useState(false);
    const [processingPayment, setProcessingPayment] = useState(false);
    const loggedInUser = useSelector(getLoggedInUser);
    const billedYearly = useSelector(getBilledYearly);
    const [checkoutCompletedMessage, setCheckoutCompletedMessage] = useState();
    const { addPubSubEventListener, removePubSubEventListener } = usePubSub();
    const [openLtdRedemptionDialog, setOpenLtdRedemptionDialog] = useState(false);
    const [redeeming, setRedeeming] = useState(false);
    const [redeemableCode, setRedeemableCode] = useState();
    const [openPendingVerificationDialog, setOpenPendingVerificationDialog] = useState(false);
    const openWorkSpaceSearch = useSelector(canOpenWorkspaceSearch);
    const canReloadPage = useSelector(getReloadPage);
    const cachedWorkspaces = useSelector(getCachedWorkSpaces);
    const activeWorkspace = useSelector(getActiveWorkSpace);
    const [createWorkspaceForSelf, setCreateWorkspaceForSelf] = useState(false);
    const loggedInUserFullName = useSelector(getLoggedInUserFullName);
    const activeMetric = useSelector(getActiveMetric);
    const initiateActivationOnPremiumPlan = useSelector(
        getInitiateActivationOnPremiumPlan
    );
    const [upgradingIndex, setupgradingIndex] = useState(-1);
    const [paymentForNewWorkspace] = useState(false);
    const workspace = useSelector(getActiveWorkSpace);

    const pushOutMetricToServer = (metric) => {
        if (!metric) return;
        APIService.newMetric(metric, (response, error) => {
            if (error) {
                console.log(error);
                return;
            }
            console.log(response);
        });
    };

    useEffect(() => {
        let params = (new URL(document.location)).searchParams;
        let self = params.get("self");
        if (self) {
            setCreateWorkspaceForSelf(true);
        }
    }, []);

    const checkoutSessionListener = (data) => {
        let { error, one_off, cancellation, message, plan = 'Premium' } = data;
        if (error) {
            toast.error(error, { theme: 'colored' });
            return;
        }
        if (!cancellation) {
            APIService.fetchLatestMe((latestMeResponse, error) => {
                if (error) {
                    toast.error(error, { theme: 'colored' });
                    if (error.toLowerCase().includes('Invalid credentials provided'.toLowerCase())) {
                        window.localStorage.clear();
                        setTimeout(() => {
                            navigate(RoutePaths.LOGIN);
                        }, 100);
                    }
                    return;
                }
                let latestUserData = latestMeResponse.data;
                dispatch(setLoggedInUser({ ...latestUserData }));
                setProcessingPayment(false);
                setCheckoutCompletedMessage(one_off ? message : `You have successfully subscribed to our ${plan} plan`);
                setOpenCheckoutCompletedPopup(true);
            });
        } else {
            navigate(RoutePaths.DEFAULT);
            window.location.reload();
        }
    }

    const getVerificationUrl = (plan) => {
        let pathname = window.location.pathname;
        return `${pathname}?meta_vdx=${plan}`;
    }

    const handleStripeSubscription = (item, index) => {
        if (activeMetric) {
            let userResponse = {
                responseId: uuid(),
                timestamp: new Date().toISOString(),
                response: `Successful flow: User attempting to upgrade to ${item.plan} plan`,
                type: "navigation",
            };
            const updatedMetric = {
                ...activeMetric,
                data: {
                    ...activeMetric.data,
                    responses: activeMetric?.data?.responses?.length
                        ? [...activeMetric?.data?.responses, userResponse]
                        : [userResponse],
                },
            };
            pushOutMetricToServer(updatedMetric);
        }
        setupgradingIndex(index);
        APIService.billUser(
            billedYearly,
            paymentForNewWorkspace,
            null,
            false,
            item.plan,
            item.quantity,
            item?.trial_period_days,
            getVerificationUrl(item.plan),
            {
                revision: item.revision,
            },
            (response, error) => {
                if (error) {
                    setupgradingIndex(-1);
                    toast.error(error);
                    return;
                }
                let { data } = response;
                window.location = data;
                setupgradingIndex(-1);
            }
        );
    };

    const handleSubscription = (pricingDataNew, user) => {
        addPubSubEventListener(`${loggedInUser?._id}-billing-status`, checkoutSessionListener);
        const Paddle = window.Paddle;
        Paddle.Checkout.open({
            product: parseInt(billedYearly ? pricingDataNew.AnnualSub : pricingDataNew.MonthlySub),
            email: user?.email,
            passthrough: JSON.stringify({
                accountId: user._id,
                subscriptionMode: billedYearly ? "Yearly" : "Monthly",
                workspaceId: user.workSpaceId,
                plan: pricingDataNew.plan,
                coupon: pricingDataNew.coupon
            }),
            successCallback: () => {
                setProcessingPayment(true);
            }
        });
    }

    useEffect(() => {
        addPubSubEventListener(`${loggedInUser?._id}-billing-status`, checkoutSessionListener);
        return () => {
            removePubSubEventListener(`${loggedInUser?._id}-billing-status`, checkoutSessionListener);
        }
    }, []);

    const redeemVoucher = (e) => {
        if (redeemableCode) {
            setRedeeming(true);
            APIService.redeemVoucher(redeemableCode, loggedInUser._id, (voucherValidationResponse, error) => {
                if (error) {
                    toast.error(error, { theme: 'colored' });
                    setRedeeming(false);
                    return;
                }
                APIService.fetchLatestMe((response, error) => {
                    if (error) {
                        toast.error(error, { theme: 'colored' });
                        return;
                    }
                    let { data } = response;
                    dispatch(setLoggedInUser({ ...data }));
                    setRedeeming(false);
                    let validationResponseData = voucherValidationResponse.data;
                    let validationMessage = voucherValidationResponse.message;
                    let { meta } = validationResponseData;
                    if (meta) {
                        try {
                            let metaData = jwt_decode(meta);
                            if (metaData) {
                                if (metaData.success_message) {
                                    setOpenLtdRedemptionDialog(false);
                                    setCheckoutCompletedMessage(metaData.success_message);
                                    setOpenCheckoutCompletedPopup(true);
                                }
                            }
                        } catch (e) {
                            //Malformed jwt
                        }
                    } else {
                        setRedeemableCode("");
                        toast.success(validationMessage, { theme: 'colored' });
                    }
                });
            });
        }
    }

    const isWorkspaceOwner = () => {
        if (createWorkspaceForSelf) {
            return true;
        }
        if (!activeWorkspace) {
            return true;
        }
        return activeWorkspace.owner_id === loggedInUser._id;
    }


    const openCrisp = (event) => {
        event.preventDefault();
        window.$crisp?.push(["do", "chat:open"]);
    };

    return (
        <div>
            <div
                className='force-refresh-postly-logo'
                style={{
                    position: 'fixed',
                    margin: 15,
                    marginTop: 10,
                    marginLeft: 4,
                    left: 0,
                    top: 0
                }}>
                <Logo width={35} height={32} fontSize={16} />
            </div>
            <Flex
                direction={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                style={{
                    gap: '1.5rem'
                }}>

                <Row className="justify-content-center">
                    <Col md={12}>
                        <form as={Row} className="g-2" onSubmit={(e) => {
                            e.preventDefault();
                            redeemVoucher(e)
                        }} style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            gap: 10,
                        }}>
                            <Col sm>
                                <Flex className={'gap-1'} alignItems={'center'}>
                                    <ProfileDropdown
                                        skipHeader
                                        skipSettings
                                        avatarSize={'3xl'}
                                        allowSwitchToAnotherWorkspace={(cachedWorkspaces ?? []).length > 0}
                                        onWorkspaceSwitchHandler={() => {
                                            dispatch(searchWorkSpace(true));
                                        }}
                                    />
                                    <FontAwesomeIcon
                                        className='cursor-pointer'
                                        icon={faChevronDown}
                                        onClick={() => {
                                            simulateMouseClick(document.getElementById("profileDropdown"));
                                        }}
                                    />
                                </Flex>
                            </Col>
                            <Col sm="auto">
                                <h2
                                    style={{
                                        margin: 0,
                                        padding: 0,
                                        textAlign: 'center'
                                    }}> <b>{loggedInUserFullName}</b>
                                </h2>
                            </Col>
                        </form>
                    </Col>
                </Row>


                <Divider />
                <IconButton
                    onClick={() => {
                        dispatch(searchWorkSpace(true));
                    }}
                    variant="falcon-default"
                    size="sm"
                    icon={faArrowRightArrowLeft}
                    transform="shrink-3"
                    className="mx-2"
                >
                    <span>Switch workspace</span>
                </IconButton>
                <Flex wrap={'wrap'} alignItems={'center'} justifyContent={'center'} className={'gap-2'}>
                    <h3
                        style={{
                            margin: 0,
                            padding: 0,
                            textAlign: 'center'
                        }}
                        dangerouslySetInnerHTML={{
                            __html: `${isWorkspaceOwner()
                                ? "Select a plan to get started"
                                : `Please consult the owner of the workspace(<b>${activeWorkspace?.name}</b>) to upgrade to a premium plan`}`
                        }}
                    />
                    {/* {
                        isWorkspaceOwner() &&
                        <span style={{ textAlign: 'center' }}>(You will get <b>14-day free trial</b>)</span>
                    }
                    {
                        !isWorkspaceOwner() &&
                        <span style={{ textAlign: 'center' }}>(They will get <b>14-day free trial</b>)</span>
                    } */}
                </Flex>
                <Row className="justify-content-center">
                    <Col md={12}>
                        <form as={Row} onSubmit={(e) => {
                            e.preventDefault();
                            redeemVoucher(e)
                        }} style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            gap: 10,
                        }}>
                            <Col sm={8}>
                                <Form.Control
                                    type="text"
                                    disabled={redeeming}
                                    onChange={(e) => setRedeemableCode(e.target.value)}
                                    value={redeemableCode}
                                    placeholder='Redeem a voucher code to get premium'
                                    style={{
                                        fontSize: 18,
                                        fontWeight: 600
                                    }}
                                />
                            </Col>
                            <Col sm="auto">
                                <Button
                                    isWorking={redeeming}
                                    variant={'primary'}
                                    disabled={redeeming || !redeemableCode}
                                    type={'submit'}>
                                    {`${redeeming ? 'Redeeming Code...' : 'Redeem'}`}
                                </Button>
                            </Col>
                        </form>
                    </Col>

                </Row>
                {/* <p disabled={!isWorkspaceOwner()}
                    onClick={() => {
                        setOpenLtdRedemptionDialog(true);
                    }}>
                    <span className="btn btn-outline-primary  fs-1 pt-2">
                        Redeem Code
                    </span>
                </p> */}
                <Card className="mb-3">
                    <Card.Body>
                        <Row className="g-0">
                            <PricingDefaultHeader />
                            {
                                // getSchedulerPricingData(loggedInUser?.active_plan)
                                // pricingDataNew.filter(x => !x.title?.toLowerCase().includes("free")).sort(x => x.price.Year ? 1 : -1).map(pricing => {
                                getSchedulerPricingData(loggedInUser?.active_plan).filter(x => !x.title?.toLowerCase().includes("free")).sort(x => x.price.Year ? 1 : -1).map(pricing => {
                                    return (
                                        <PickAPlanPricingCard
                                            buttonsDisabled={!isWorkspaceOwner()}
                                            key={pricing.id}
                                            pricing={pricing}
                                            handleBilling={(data) => {
                                                handleStripeSubscription(data, {
                                                    ...loggedInUser,
                                                    workSpaceId: workspace._id,
                                                });
                                            }}
                                        />
                                    )
                                })
                            }

                            {/* <Col xs={12} className="text-center">
                                <h5 className="mt-5 mb-3">
                                    Need more social accounts?  You can add more after you upgrade!
                                </h5>
                                <p onClick={openCrisp}>
                                    Need help? {" "}
                                    <span className="btn btn-success text-white fs-1 pt-2">
                                        <FontAwesomeIcon icon="comments" />Chat with Support
                                    </span>
                                </p>
                            </Col> */}
                        </Row>
                    </Card.Body>
                </Card>
                <Modal
                    show={openCheckoutCompletedPopup}
                    onHide={() => {
                        setOpenCheckoutCompletedPopup(false)
                        navigate(RoutePaths.DEFAULT);
                        window.location.reload();
                    }}
                    centered
                    size='lg'>
                    <Modal.Header>
                        <Modal.Title>Subscription Successful!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Flex
                            alignItems={'center'}
                            className={'gap-2'}
                            justifyContent={'center'}
                            direction={'column'}>
                            <Checkmark size='96px' />
                            <span>{checkoutCompletedMessage}</span>
                        </Flex>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => {
                                setOpenCheckoutCompletedPopup(false);
                                navigate(RoutePaths.DEFAULT);
                                window.location.reload();
                            }}>Continue
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={processingPayment}
                    onHide={() => {
                        setProcessingPayment(false);
                        setOpenPendingVerificationDialog(true);
                    }}
                    onExit={() => {
                        setProcessingPayment(false);
                    }}
                    centered>
                    <Modal.Body>
                        <Flex
                            alignItems={'center'}
                            justifyContent={'center'}>
                            <Flex className={'gap-2'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                direction={'column'}>
                                <Spinner animation='border' />
                                <span>Verifying transaction. Please wait...</span>
                            </Flex>
                        </Flex>
                    </Modal.Body>
                </Modal>
            </Flex>
            <Modal
                show={openLtdRedemptionDialog}
                onHide={() => setOpenLtdRedemptionDialog(false)}>
                <Modal.Header>
                    <Modal.Title>Redeem Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify-content-center">
                        <Col md={12}>
                            <form as={Row} className="g-2" onSubmit={(e) => {
                                e.preventDefault();
                                redeemVoucher(e)
                            }} style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                gap: 10,
                            }}>
                                <Col sm>
                                    <Form.Control
                                        type="text"
                                        disabled={redeeming}
                                        onChange={(e) => setRedeemableCode(e.target.value)}
                                        value={redeemableCode}
                                        placeholder='Enter redeemable code'
                                        style={{
                                            fontSize: 18,
                                            fontWeight: 600
                                        }}
                                    />
                                </Col>
                                <Col sm="auto">
                                    <Button
                                        isWorking={redeeming}
                                        variant={'primary'}
                                        disabled={redeeming || !redeemableCode}
                                        type={'submit'}>
                                        {`${redeeming ? 'Redeeming Code...' : 'Redeem'}`}
                                    </Button>
                                </Col>
                            </form>
                        </Col>

                    </Row>
                    {/* <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            redeemVoucher(e)
                        }} style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            gap: 10,
                        }}>
                        <Form.Control
                            type="text"
                            disabled={redeeming}
                            onChange={(e) => setRedeemableCode(e.target.value)}
                            value={redeemableCode}
                            placeholder='Enter redeemable code'
                            style={{
                                fontSize: 18,
                                fontWeight: 600
                            }}
                        />
                        <Button
                            isWorking={redeeming}
                            variant={'primary'}
                            disabled={redeeming || !redeemableCode}
                            type={'submit'}>
                            {`${redeeming ? 'Redeeming Code...' : 'Redeem'}`}
                        </Button>
                    </form> */}
                </Modal.Body>
            </Modal>
            <Modal
                show={openPendingVerificationDialog}
                onHide={() => {
                    setOpenPendingVerificationDialog(false);
                }}
                onExit={() => {
                    setProcessingPayment(false);
                    setOpenPendingVerificationDialog(false);
                }}
                centered>
                <Modal.Header>
                    <Modal.Title>
                        Subscription Status Pending...
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RichInfoBar
                        title="Please do not re-subscribe again"
                        message={"Your subscribed plan will reflect after verification within 24hrs. \n\nKindly contact support if your subscribed plan doesn't reflect after 24hrs"}
                        actionMessage={"OK"}
                        action={() => {
                            setOpenPendingVerificationDialog(false);
                        }}
                    />
                </Modal.Body>
            </Modal>
            <Modal
                show={openWorkSpaceSearch}
                onHide={() => dispatch(searchWorkSpace(false))}
                onExit={() => {
                    searchWorkSpace(false);
                    if (canReloadPage) {
                        navigate(RoutePaths.DEFAULT);
                        window.location.reload();
                    }
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <WorkSpaceSearch />
            </Modal>
        </div >
    );
}

export default MandatoryAccountUpgrade;
