import React from 'react';
import { Tooltip } from 'react-tooltip';
import ImageEditor from './ImageEditor';

const Image = ({ imageUrl, areas, setCanUpdateTags, isVideo }) => {

    return (
        <div>
            <ImageEditor
                src={imageUrl}
                areas={areas}
                imageUrl={imageUrl}
                setCanUpdateTags={setCanUpdateTags}
                isVideo={isVideo}
            />
            {areas.map((tag) => (
                <Tooltip key={tag.id} id={`tag-tooltip-${tag.id}`}>
                    {tag.name}
                </Tooltip>
            ))}
        </div>
    );
};

export default Image;
