import BulkContentPostPlatformSelectionDropdown from './bulk_content_post_platform_selection_dropdown';
import { PlatformBottomShadow } from './styles';
import { useSelector, useDispatch } from 'react-redux';
import {
    getUploadableBulkContent,
    setUploadableBulkContent
} from "../../../../../redux/slices/postslice";
import { Card } from 'react-bootstrap';

export default function PlatformsPreview(props) {

    const {
        modalClose,
        rowIndex = 0
    } = props;
    const dispatch = useDispatch();
    const uploadableBulkContent = useSelector(getUploadableBulkContent);
    const bulkContentRow = uploadableBulkContent[rowIndex] ?? {};
    const platforms = bulkContentRow?.platforms;

    const updateBulkContentRow = (updatedPlatform) => {
        let uploadableContentCopy = [...uploadableBulkContent];
        let updatedRow = { ...bulkContentRow };
        let existingPlatforms = [...updatedRow.platforms];
        let existingPlatform = existingPlatforms.find(x => x._id == updatedPlatform._id);
        let existing = JSON.stringify(existingPlatform);
        let updated = JSON.stringify(updatedPlatform);
        if (existing !== updated) {
            let indexOfPlatform = existingPlatforms.indexOf(existingPlatform);
            existingPlatforms[indexOfPlatform] = updatedPlatform;
            updatedRow.platforms = existingPlatforms;
            uploadableContentCopy[rowIndex] = updatedRow;
            dispatch(setUploadableBulkContent(uploadableContentCopy));
        }
    }

    return (
        <Card
            style={{
                position: 'relative',
            }}>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'stretch',
                    marginTop: 10,
                    marginBottom: 10,
                    maxHeight: 300,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    paddingBottom: 20,
                    cursor: "pointer",
                    gap: 10
                }}>
                {
                    Array.isArray(platforms) && platforms.map((platform) => {
                        platform = JSON.parse(JSON.stringify(platform));
                        return (
                            <BulkContentPostPlatformSelectionDropdown
                                closeModal={modalClose}
                                key={platform._id}
                                platform={{ ...platform }}
                                platformChangedHandler={(updatedPlatform) => {
                                    updateBulkContentRow(updatedPlatform);
                                }}
                            />
                        )
                    })
                }
            </div>
            {
                (platforms ?? []).length >= 6 &&
                <PlatformBottomShadow />
            }
        </Card>
    );
}
