import { PlatformIdentifier } from '../constants';
import { isBlobUrl } from './file_utils';
import { checkPlatformVideos } from './platform_utils';

const MediaValidator = async (arrMedias, extras) => {
   let errorFound = false;
   let affectedPlatforms = [];
   let customErrorMessage = {
      [PlatformIdentifier.INSTAGRAM]: "Instagram doesn't support videos with width or height less than 320px",
      [PlatformIdentifier.GOOGLE_MY_BUSINESS]: "Video(s) is not supported on GOOGLE MY BUSINESS. Please remove the video from GMB in the Previews tab to continue."
   };

   function getImageDimensionsOf(url) {
      return new Promise(resolve => {
         const image = document.createElement('img');
         image.addEventListener("load", function () {
            const height = this.height;
            const width = this.width;
            resolve({ height, width });
         }, false);
         image.src = url;
      });
   }


   const canVideoPassThroughCheckers = async (platformIdentifier, file) => {
      if (platformIdentifier === PlatformIdentifier.INSTAGRAM) {
         let publishIGVideoAsReels = extras?.publishIGVideoAsReel || null
         let publishIGVideoAsStories = extras?.publishIGVideoAsStories || null
         const response = await checkPlatformVideos(file, platformIdentifier, {
            checkIgNormalVideo: true,
            publishIGVideoAsReels,
            publishIGVideoAsStories
         });
         return response
      }
      if (platformIdentifier === PlatformIdentifier.GOOGLE_MY_BUSINESS) {
         return { error: "Video(s) is not supported on GOOGLE MY BUSINESS. Please remove the video from GMB in the Previews tab to continue." }
      }
      return { error: null }
   }

   const checkValidity = async (widthLimit, heightLimit, source, checkType = 'min', platformIdentifier, is_video, external, file) => {
      if (source && source?.startsWith(`https://firebasestorage.googleapis.com/v0/b/postly-548c1.appspot.com`)) return true;
      if (external && is_video) return true;

      if (file && is_video) {
         const { error } = await canVideoPassThroughCheckers(platformIdentifier, file);
         if (error) {
            errorFound = true;
            affectedPlatforms = [...affectedPlatforms, platformIdentifier];
            customErrorMessage[platformIdentifier] = error
            return false
         }
         return true;
      }

      if (isBlobUrl(source) && is_video) {
         //! video checks removed because it isn't complete. VideoWidthLimit and VideoHeightLimit isn't passed and so are always undefined
         return true;
         //TODO: Remove once video checks are done
      }


      if (external) {
         return true; // allow external images go through
      }

      let { width, height } = await getImageDimensionsOf(source);
      if (checkType === 'max') {
         if (width > widthLimit || height > heightLimit) {
            return false
         }
         return true;
      }
      if (width < widthLimit || height < heightLimit) {
         errorFound = true;
         if (!affectedPlatforms.includes(platformIdentifier)) {
            customErrorMessage[platformIdentifier] = `Sorry, one or more selected media formats do not match expected formats or specific dimensions for ${platformIdentifier}. Kindly fix this from the Preview Tab`;
            affectedPlatforms = [...affectedPlatforms, platformIdentifier];
         }
         return false
      }
      return true
   }

   for (let index = 0; index < arrMedias.length; index++) {
      const item = arrMedias[index];

      if (item?.identifier === PlatformIdentifier.FACEBOOK) {
         for (let i = 0; i < item?.media.length; i++) {
            const x = item?.media[i];
            await checkValidity(600, 315, x?.url, 'min', PlatformIdentifier.FACEBOOK, x?.is_video, x?.external ?? x?.uploaded, x?.file);
         }
      }

      if (item?.identifier === PlatformIdentifier.LINKED_IN) {
         for (let i = 0; i < item?.media.length; i++) {
            const x = item?.media[i];
            if (x?.file?.type === 'image/webp') {
               errorFound = true;
               if (!affectedPlatforms.includes(PlatformIdentifier.LINKED_IN)) {
                  affectedPlatforms = [...affectedPlatforms, PlatformIdentifier.LINKED_IN];
               }
            }
            await checkValidity(1080, 1080, x?.url, 'min', PlatformIdentifier.LINKED_IN, x?.is_video, x?.external ?? x?.uploaded);
         }
      }
      if (item?.identifier === PlatformIdentifier.INSTAGRAM) {
         for (let i = 0; i < item?.media.length; i++) {
            const x = item?.media[i];
            await checkValidity(1080, 566, x?.url, 'min', PlatformIdentifier.INSTAGRAM, x?.is_video, x?.external ?? x?.uploaded, x?.file);
         }
      }

      if (item?.identifier === PlatformIdentifier.TWITTER) {
         for (let i = 0; i < item?.media.length; i++) {
            const x = item?.media[i];
            await checkValidity(8192, 8192, x?.url, 'max', PlatformIdentifier.TWITTER, x?.is_video, x?.external ?? x?.uploaded);
            await checkValidity(600, 335, x?.url, 'min', PlatformIdentifier.TWITTER, x?.is_video, x?.external ?? x?.uploaded);
         }
      }

      if (item?.identifier === PlatformIdentifier.TELEGRAM) {
         for (let i = 0; i < item?.media.length; i++) {
            const x = item?.media[i];
            if (x?.file?.type === 'image/webp') {
               errorFound = true;
               if (!affectedPlatforms.includes(PlatformIdentifier.TELEGRAM)) {
                  affectedPlatforms = [...affectedPlatforms, PlatformIdentifier.TELEGRAM];
               }
            }
         }
      }
   }
   arrMedias = [];
   if (errorFound) {
      if (affectedPlatforms.includes(PlatformIdentifier.TELEGRAM)) {
         return {
            success: false,
            affectedPlatforms: affectedPlatforms,
            errorMessage: `Sorry, one or more selected media formats do not match expected formats for Telegram. Kindly fix this from the Preview Tab`,
         }
      }
      if (affectedPlatforms.includes(PlatformIdentifier.LINKED_IN)) {
         return {
            success: false,
            affectedPlatforms: affectedPlatforms,
            errorMessage: `Sorry, one or more selected media formats do not match expected formats or specific dimensions for Linkedin. Kindly fix this from the Preview Tab`,
         }
      }
      if (affectedPlatforms.includes(PlatformIdentifier.INSTAGRAM)) {
         return {
            success: false,
            affectedPlatforms: affectedPlatforms,
            errorMessage: customErrorMessage[PlatformIdentifier.INSTAGRAM] ?? `Sorry, one or more selected media formats do not match expected formats or specific dimensions for Instagram. Kindly fix this from the Preview Tab`,
         }
      }
      if (affectedPlatforms.includes(PlatformIdentifier.GOOGLE_MY_BUSINESS)) {
         return {
            success: false,
            affectedPlatforms: affectedPlatforms,
            errorMessage: customErrorMessage[PlatformIdentifier.GOOGLE_MY_BUSINESS] ?? `Sorry, one or more selected media formats do not match expected formats or specific dimensions for Google My Business. Kindly fix this from the Preview Tab`,
         }
      }
      return {
         success: false,
         affectedPlatforms: affectedPlatforms,
         errorMessage: `One or more photos/videos do not match ${affectedPlatforms.toString()} specific dimensions. Kindly fix this from the Preview tab`,
      }
   }
   return {
      success: true,
   }
}

export default MediaValidator;