import { useState, useEffect } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
    setShouldAddNewPollChoice,
    setPollChoiceValue
} from '../../../../../redux/slices/postslice';
import { useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import CircularButton from 'components/common/circularbutton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const maxAllowedPolls = 4;

export default function PollChoice(props) {

    const dispatch = useDispatch();
    const [label, setLabel] = useState(props.label);
    const [choicesLength, setChoicesLength] = useState(props.choicesLength);
    const [choiceIndex, setChoiceIndex] = useState(props.choiceIndex);
    const [choiceId, setChoiceId] = useState(props.choiceId);
    const [value, setValue] = useState(props.value);
    
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        setLabel(props.label);
    }, [props.label]);

    useEffect(() => {
        setChoicesLength(props.choicesLength);
    }, [props.choicesLength]);

    useEffect(() => {
        setChoiceIndex(props.choiceIndex);
    }, [props.choiceIndex]);

    useEffect(() => {
        setChoiceId(props.choiceId);
    }, [props.choiceId]);

    return (
        <Form.Group>
            <Form.Label>
                {label}
            </Form.Label>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5
                }}>
                <Form.Control
                    style={{ flex: 1 }}
                    type='text'
                    value={value}
                    onChange={(e) => {
                        let tValue = e.target.value;
                        setValue(tValue);
                        dispatch(setPollChoiceValue({
                            choiceId,
                            value: tValue
                        }))
                    }}
                />
                {
                    choiceIndex === choicesLength - 1 && choicesLength < maxAllowedPolls &&
                    <CircularButton
                        onClick={() => {
                            dispatch(setShouldAddNewPollChoice(true));
                        }}>
                        <FontAwesomeIcon icon={faPlus} />
                    </CircularButton>
                }
            </div>
        </Form.Group>
    );
}