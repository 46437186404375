import Flex from "components/common/Flex";
import APIService from "../../../../http/api_service";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { getPostCategories, setPostCategories } from "redux/slices/postslice";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { getLoggedInUser } from "redux/slices/user_slice";

const NewPostCaegoryForm = ({ title = "Create Your First Campaign", doneCallBack }) => {

    const postCategories = useSelector(getPostCategories);
    const dispatch = useDispatch();
    const workSpace = useSelector(getActiveWorkSpace);
    const [formData, setFormData] = useState({
        name: ''
    });
    const [creatingNewPostCategory, setCreatingNewPostCategory] = useState(false);
    const loggedInUser = useSelector(getLoggedInUser);
    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        createNewPostCategory();
    }

    const createNewPostCategory = () => {
        setCreatingNewPostCategory(true);
        APIService.createNewPostCategory(workSpace._id, formData.name, loggedInUser._id, (response, error) => {
            setCreatingNewPostCategory(false);
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { data, message } = response;
            toast.success(message, { theme: 'colored' })
            let categoriesCopy = [...postCategories];
            categoriesCopy.push(data);
            dispatch(setPostCategories(categoriesCopy));
            if (doneCallBack) {
                doneCallBack();
            }
        });
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Flex alignItems={'center'} justifyContent={'center'} className="gap-2">
                <Form.Group>
                    <Form.Label>{title}</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="E.g Digital Marketing"
                        aria-label="Campaign Name"
                        value={formData.name}
                        name="name"
                        onChange={handleChange}
                        style={{
                            minWidth: 300
                        }}
                    />
                </Form.Group>
                <Button
                    disabled={!formData.name || creatingNewPostCategory}
                    type='submit'
                    style={{ alignSelf: 'flex-end', minWidth: 100 }}
                    variant="primary">
                    {creatingNewPostCategory ? "Creating..." : "Create"}
                </Button>
            </Flex>
        </Form>
    )
}
export default NewPostCaegoryForm;