import { faInfoCircle, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { RoutePaths } from 'constants';
import AppContext from 'context/Context';
import React, { useContext } from 'react';
import { Button, Card, Modal, ProgressBar } from 'react-bootstrap';
import { BiCollapse } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { BulkFullScreenContainer, BulkFullScreenContainerInner } from './styles';

const BulkContentPostPreviewFullscreen = (props) => {
    const {
        config: { isDark } } = useContext(AppContext);
    const navigate = useNavigate();
    const { setOpenFullScreen, uploadableContent, uploadingBulkContent, uploadBulkContent, pickNewFile, columns, loadingPlatforms, platformsLoadError, modalClose, noPlatformConnected, canPreview, filePreviewErrorMessage, openCreationSuccessDialog, setOpenCreationSuccessDialog } = props;

    return (
        <BulkFullScreenContainer
            bgColor={isDark ? '#121e2e' : 'white'}
        >
            <div>
                {
                    loadingPlatforms &&
                    <Card>
                        <Card.Body>
                            <div
                                style={{
                                    minHeight: 150,
                                    paddingTop: 75,
                                    paddingLeft: '25%'
                                }}>
                                <ProgressBar label={"Processing file"} animated now={45} />
                            </div>
                        </Card.Body>
                    </Card>
                }
                {
                    !loadingPlatforms && !platformsLoadError &&
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                padding: 10,
                            }}>
                            <div
                                style={{
                                    flex: 1
                                }}
                            />
                            <Button
                                icon="close"
                                iconSize={24}
                                variant="empty"
                                onClick={() => {
                                    modalClose();
                                }}
                            />
                        </div>
                        {
                            !loadingPlatforms && noPlatformConnected && canPreview &&
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    minHeight: 400,
                                    gap: 20,
                                    fontSize: 18
                                }}>
                                <h4>No Connected Platforms found</h4>
                                <span>Connect at least one platform before uploading bulk content</span>
                                <Button
                                    variant={'primary'}
                                    onClick={() => {
                                        modalClose();
                                        navigate(RoutePaths.PLATFORMS);
                                    }}>Connect Platforms
                                </Button>
                            </div>
                        }
                        {
                            columns.length > 0 &&
                            <AdvanceTableWrapper
                                columns={columns}
                                data={uploadableContent}
                                pagination
                                perPage={10}
                            >
                                <BulkFullScreenContainerInner
                                    style={{
                                        width: "100vw"
                                    }}

                                >
                                    <FalconCardHeader
                                        title="Posts to Create"
                                        endEl={
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    gap: 10
                                                }}>
                                                <Button
                                                    type="button"
                                                    icon="plus"
                                                    size="sm"
                                                    className="ms-2"
                                                    variant="falcon-default"
                                                    onClick={() => {
                                                        pickNewFile();
                                                    }}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            gap: 1, alignItems: 'center'
                                                        }}>
                                                        <FontAwesomeIcon icon={'plus'} />
                                                        <span className="d-none d-sm-inline-block ms-1">Pick a New CSV File</span>
                                                    </div>
                                                </Button>
                                                <Button
                                                    disabled={uploadingBulkContent || uploadableContent.length < 4}
                                                    size="sm"
                                                    className="ms-2"
                                                    variant="falcon-primary"
                                                    onClick={() => {
                                                        uploadBulkContent();
                                                    }}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            gap: 1, alignItems: 'center'
                                                        }}>
                                                        <FontAwesomeIcon icon={faPaperPlane} />
                                                        <span className="d-none d-sm-inline-block ms-1">{uploadingBulkContent ? "Scheduling Posts..." : "Schedule Posts"}</span>
                                                    </div>
                                                </Button>
                                                <Button
                                                    size="sm"
                                                    className="ms-2"
                                                    variant="falcon-primary"
                                                    onClick={() => {
                                                        setOpenFullScreen(false);
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            gap: 1, alignItems: 'center'
                                                        }}>
                                                        <BiCollapse size={20} />
                                                    </div>
                                                </Button>
                                            </div>
                                        }
                                    ><span className='fs--1'>Min number of rows must be 4</span></FalconCardHeader>
                                    {/* <Card.Body className="p-0"> */}

                                    <AdvanceTable
                                        table
                                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                                        rowClassName="align-middle white-space-nowrap"
                                        tableProps={{
                                            size: 'sm',
                                            striped: true,
                                            className: 'fs--1 mb-0 overflow-hidden'
                                        }}
                                    />
                                    {/* </Card.Body> */}
                                    {/* <Card.Footer> */}
                                    <AdvanceTableFooter
                                        rowCount={uploadableContent.length}
                                        table
                                        rowInfo
                                        navButtons
                                        rowsPerPageSelection
                                    />
                                    {/* </Card.Footer> */}
                                </BulkFullScreenContainerInner>
                            </AdvanceTableWrapper>
                        }
                        {
                            columns.length > 0 &&
                            <div
                                style={{
                                    display: 'flex',
                                    alignSelf: 'flex-end',
                                    margin: 20,
                                    gap: 30,
                                    alignItems: 'center'
                                }}>
                                {
                                    uploadableContent.length < 3 &&
                                    <span style={{
                                        color: 'red'
                                    }}>Oops! You must have a minimum of <b>three (3)</b> rows</span>
                                }
                            </div>
                        }
                    </div >
                }
                {
                    ((!loadingPlatforms && platformsLoadError) || !canPreview) &&
                    <Card>
                        <Card.Body>
                            <div
                                style={{
                                    height: 400,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 15,
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                    <FontAwesomeIcon icon={faInfoCircle} className={'fs-5 text-danger'} />
                                    <span style={{ maxWidth: 400, textAlign: 'center' }} dangerouslySetInnerHTML={{
                                        __html: filePreviewErrorMessage
                                    }}></span>
                                    <Button
                                        className='mt-2'
                                        onClick={() => {
                                            modalClose();
                                        }}>CLOSE
                                    </Button>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                }
                <Modal
                    backdrop="static"
                    show={openCreationSuccessDialog}
                    onHide={() => setOpenCreationSuccessDialog(false)}
                    onExit={() => setOpenCreationSuccessDialog(false)}
                    centered={false}>
                    <Modal.Header>
                        <Modal.Title>
                            Posts Scheduled
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Flex
                            direction={'column'}
                            className={'gap-3'}>
                            <span className='text-success'>{"Your posts have been successfully scheduled to the targeted platforms!"}</span>
                        </Flex>
                    </Modal.Body>
                    <Modal.Footer>
                        <Flex
                            className={'gap-1'}
                            alignItems={'center'}>
                            <Button
                                variant={'default'}
                                onClick={() => {
                                    setOpenCreationSuccessDialog(false);
                                }}>Not Now
                            </Button>
                            <Button
                                variant={'warning'}
                                onClick={() => {
                                    setOpenCreationSuccessDialog(false);
                                    navigate(RoutePaths.POSTS, { replace: true });
                                    window.location.reload(true); // This performs a hard refresh so new data can be fetched
                                }}>Go Home
                            </Button>
                        </Flex>
                    </Modal.Footer>
                </Modal>
            </div>
        </BulkFullScreenContainer>
    )
}

export default BulkContentPostPreviewFullscreen