import Divider from "components/common/Divider";
import APIService from "http/api_service";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RoutePaths, TermsAndPolicies } from "../../constants";
import SocialAuthButtons from "./SocialAuthButtons";
import Flex from "components/common/Flex";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";

const RegistrationForm = ({
  hasLabel,
  brandPrivacyPolicy,
  brandTermOfService,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    isAccepted: true,
  });

  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const currentURL = window.location.href;

  const sendEmailToFirstPromoter = () => {
    window.fpr("referral", { email: formData.email });
  };

  const handleSendEmailForVerification = (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match", { theme: "colored" });
      return;
    }
    const state = { formData };

    let requestBody = {
      email: formData?.email,
    };
    setProcessing(true);
    APIService.requestCodeForEmailVerification(
      requestBody,
      1,
      (response, error) => {
        if (error) {
          setProcessing(false);
          toast.error(error, { theme: "colored" });
          return;
        }
        setProcessing(false);
        toast.success(response.message, { theme: "colored" });
        sendEmailToFirstPromoter();
        navigate(RoutePaths.VERIFY_EMAIL_ON_SIGNUP, { state });
      }
    );
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Form onSubmit={handleSendEmailForVerification}>
      <SocialAuthButtons disabled={!formData.isAccepted} />
      <Divider>or register with</Divider>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Name</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Name" : ""}
          value={formData.name}
          name="name"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Email address" : ""}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      {/* <Row className="g-2 mb-3"> */}
      <Form.Group as={Col} className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Flex>
          <Form.Control
            placeholder={!hasLabel ? "Password" : ""}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type={passwordVisible ? "text" : "password"}
          />
          <Button
            type="button"
            onClick={() => setPasswordVisible((prevState) => !prevState)}
          >
            {passwordVisible ? <FaEye /> : <FaEyeSlash />}
          </Button>
        </Flex>
      </Form.Group>
      <Form.Group as={Col} className="mb-3">
        {hasLabel && <Form.Label>Confirm Password</Form.Label>}
        <Flex>
          <Form.Control
            placeholder={!hasLabel ? "Confirm Password" : ""}
            value={formData.confirmPassword}
            name="confirmPassword"
            onChange={handleFieldChange}
            type={confirmPasswordVisible ? "text" : "password"}
          />
          <Button
            type="button"
            onClick={() => setConfirmPasswordVisible((prevState) => !prevState)}
          >
            {confirmPasswordVisible ? <FaEye /> : <FaEyeSlash />}
          </Button>
        </Flex>
      </Form.Group>
      {/* </Row> */}

      <Form.Group className="mb-3">
        <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
          <Form.Check.Input
            type="checkbox"
            name="isAccepted"
            checked={formData.isAccepted}
            onChange={(e) =>
              setFormData({
                ...formData,
                isAccepted: e.target.checked,
              })
            }
          />
          <Form.Check.Label className="form-label">
            I accept the{" "}
            <a
              target={"_blank"}
              rel="noreferrer"
              href={
                currentURL !== "app.postly.ai" &&
                  currentURL !== "postly-548c1.web.app" &&
                  currentURL !== "http://localhost:3000" &&
                  !brandPrivacyPolicy
                  ? "#"
                  : currentURL !== "app.postly.ai" &&
                    currentURL !== "postly-548c1.web.app" &&
                    currentURL !== "http://localhost:3000" &&
                    brandPrivacyPolicy
                    ? brandPrivacyPolicy
                    : TermsAndPolicies.TERMS_OF_SERVICE
              }
            >
              terms
            </a>{" "}
            and{" "}
            <a
              target={"_blank"}
              rel="noreferrer"
              href={
                currentURL !== "app.postly.ai" &&
                  currentURL !== "postly-548c1.web.app" &&
                  currentURL !== "http://localhost:3000" &&
                  !brandPrivacyPolicy
                  ? "#"
                  : currentURL !== "app.postly.ai" &&
                    currentURL !== "postly-548c1.web.app" &&
                    currentURL !== "http://localhost:3000" &&
                    brandPrivacyPolicy
                    ? brandPrivacyPolicy
                    : TermsAndPolicies.PRIVACY_POLICY
              }
            >
              privacy policy
            </a>
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>

      <Form.Group className="mb-4">
        <Button
          className="w-100"
          type="submit"
          disabled={
            !formData.name ||
            !formData.email ||
            !formData.password ||
            !formData.confirmPassword ||
            processing
          }
        >
          {processing ? "Please wait..." : "Register"}
        </Button>
      </Form.Group>
      {/* <Divider>or register with</Divider>
      <SocialAuthButtons disabled={!formData.isAccepted} /> */}
    </Form>
  );
};

RegistrationForm.propTypes = {
  hasLabel: PropTypes.bool,
};

export default RegistrationForm;
