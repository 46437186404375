import { faImage, faRemove, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmModal from "components/common/ConfirmModal";
import MultimediaSelectionDropdown from "components/common/MultimediaSelectionDropdown";
import CircularButton from "components/common/circularbutton";
import GDriveDashboard from "components/common/g-drive/dashboard";
import AppContext from "context/Context";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getMediaArrangement, setMediaArrangement } from "redux/slices/publishable_media_arrangement_slice";
import { darkTheme, lightTheme } from "utils/theme";
import { PlatformData, PlatformIdentifier } from "../../../../../constants";
import {
  canOpenPixelFilePickerDialog,
  getGoogleDriveManager,
  getManuallyEditedPlatforms,
  getMediaAddedViaSpecificPlaformUrlMethod,
  getNotifyPreviewsOfLiveMediaChanges,
  getSelectingPhotoFromHome,
  getSpecificMediaPlatformTied,
  setGoogleDriveManager,
  setManuallyEditedPlatforms,
  setOpenPixelFilePickerDialog,
  setSpecificMediaPlatformTied
} from "../../../../../redux/slices/postslice";
import ExtrernalImageSearchDialogue from "./ExtrernalImageSearchDialogue";
import GDriveConnectionRequestListener from "./GDriveConnectionRequestListener";
import BaseVideoPlayer from "./baseVideoPlayer";
import {
  EditContainer,
  ImageDelete,
  ParentSection,
  PinterestImageEditButtonParent,
  PostImage,
  PreviewContentArea,
  SecondLayer,
  Wrapper
} from "./styles/pinterest";
import Flex from "components/common/Flex";
import { displayPlatformIcon } from "utils/platform_utils";
import { Info } from "./styles/facebook";
import ImageEditButton from "./image_edit_button";
import recursiveAspectFinder from "utils/recursiveAspectFinder";
import FilerobotImageEditor, { TABS, TOOLS } from "react-filerobot-image-editor";
import { setSelectedPlatformForPreview } from "redux/slices/platformSlice";

const Pinterest = ({ postContent, fbImgSrc, mainBgColor, theme, viewOnly }) => {
  const googleDriveManager = useSelector(getGoogleDriveManager)
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const googleDriveRef = useRef(null);
  const [currentMediaType, setCurrentMediaType] = useState(null);
  const specificMediaPlatformTied = useSelector(getSpecificMediaPlatformTied);
  const media_items_arrangement = useSelector(getMediaArrangement);
  const [useableMedia, setUseableMedia] = useState([]);
  const selectingPhotoFromHome = useSelector(getSelectingPhotoFromHome);
  const [openMediaSelction, setOpenMediaSelection] = useState(false);
  const mediaAddedViaSpecificPlaformUrlMethod = useSelector(
    getMediaAddedViaSpecificPlaformUrlMethod
  );
  const dispatch = useDispatch();
  const [openVideoEditArea, setOpenVideoEditArea] = useState(false);
  const [blockMediaItemsUpdate, setBlockMediaItemsUpdate] = useState(true);
  const manuallyEditedPlatforms = useSelector(getManuallyEditedPlatforms);
  const openPixelDialoguePicker = useSelector(canOpenPixelFilePickerDialog);
  const { config } = useContext(AppContext);
  const notifyPreviewsOfLiveMediaChanges = useSelector(getNotifyPreviewsOfLiveMediaChanges);
  const [fileNameBackup, setFileNameBackup] = useState("");
  const [openEditArea, setOpenEditArea] = useState(false);
  const [imageToEditUrl, setImageToEditUrl] = useState("");
  const [comparebleUrl, setComparebleUrl] = useState("");
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  let videoInfoText = `Pinterest videos should be at least 1280 x 720.`;

  function getImageDimension(url) {
    return new Promise(resolve => {
      const image = document.createElement('img');
      image.addEventListener("load", function () {
        const height = this.height;
        const width = this.width;
        resolve({ height, width });
      }, false);
      image.src = url;
    });
  }

  const init = async () => {
    const { width, height } = await getImageDimension(useableMedia[0]?.url);;
    setWidth(width);
    setHeight(height);
  }

  useEffect(() => {
    if (useableMedia.length) {
      init();
    }
  }, [useableMedia]);

  useEffect(() => {
    setUseableMedia(media_items_arrangement?.pinterest?.media);
  }, [notifyPreviewsOfLiveMediaChanges]);

  const dispatchEditedFlag = () => {
    if (!manuallyEditedPlatforms[PlatformIdentifier.PINTEREST]) {
      const newData = { ...manuallyEditedPlatforms, pinterest: true };
      dispatch(setManuallyEditedPlatforms(newData || {}));
    }
  };

  const syncNewThread = async () => {
    let syncingThread = [];
    try {
      const mappedThread = useableMedia?.map((thread, index) => {
        const newThreadEntry = {
          name: thread?.name,
          url: thread?.url,
          is_video: thread?.is_video,
          external: thread?.external ?? false,
          uploaded: thread?.uploaded ?? false,
          file: thread?.file,
        };
        return newThreadEntry;
      });
      const oldData = { ...media_items_arrangement };
      const diapatchableData = {
        ...oldData,
        pinterest: { ...oldData.pinterest, media: mappedThread },
      };
      dispatch(setMediaArrangement(diapatchableData));
    } catch (error) { }
    return syncingThread;
  };

  useEffect(() => {
    if (!blockMediaItemsUpdate) {
      setBlockMediaItemsUpdate(true);
      syncNewThread();
    }
  }, [useableMedia]);

  const mediaRemoveHandler = (removableMediaObject) => {
    try {
      let cachedArrayMedia = useableMedia;
      const filterMedia = cachedArrayMedia?.filter(
        (item) => item.url !== removableMediaObject.url
      );
      setBlockMediaItemsUpdate(false);
      setUseableMedia((prevState) => (prevState = filterMedia));
      dispatchEditedFlag();
    } catch (error) { }
  };

  const handleDeleteMedia = (data) => {
    mediaRemoveHandler(data);
  };

  const mediaOpener = (
    <div
      style={{
        position: "relative",
        zIndex: 5,
        width: "96%",
        marginLeft: "4%",
        marginBottom: 20,
        marginTop: 20,
      }}
    >
      {
        <MultimediaSelectionDropdown
          specificPlatformTied={PlatformIdentifier.PINTEREST}
          fromPreview={true}
          initiatorId={
            currentMediaType === "image"
              ? `${PlatformIdentifier.PINTEREST}-photo-chooser`
              : `${PlatformIdentifier.PINTEREST}-video-chooser`
          }
          externalFilePickerTitle={
            currentMediaType === "image"
              ? "Enter Photo Location"
              : "Enter Video Location"
          }
          prompt={
            currentMediaType === "image" ? "Add Photo From" : "Add Video From"
          }
          fileType={currentMediaType === "image" ? "image" : "video"}
          externalPickerPromptLabel={
            currentMediaType === "image" ? "Photo" : "Video"
          }
          multiple={false}
          fileSelectionHandler={(response) => {
            if (response) {
              let { files, from_local, ...others } = response;
              updateMediaItems(files, from_local, others);
            }
          }}
        />
      }
    </div>
  );

  const updateMediaItems = async (
    files,
    canCreateObjectURL = true,
    moreProps = {}
  ) => {
    let mo = [];
    for (let file of files) {
      if (canCreateObjectURL) {
        let fileUrl = URL.createObjectURL(file, { autoRevoke: false });
        let fileName = file.name;
        let existingFile = mo.find((x) => x.url === fileUrl);
        if (!existingFile) {
          let fileExtension = `.${fileName.split(".").pop()}`;
          let newThreadObject = {
            name: fileName,
            url: fileUrl,
            extension: fileExtension,
            file: file,
            ...moreProps,
          };

          if (file.size > PlatformData.MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE) {
            toast.error(
              `Maximum allowed file size is ${PlatformData.MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE / 1e9
              }GB`,
              { theme: "colored" }
            );
          } else {
            mo.push(newThreadObject);
          }
        }
      } else {
        let fileWithName = mo.find((item) => item.name === file.name);
        if (!fileWithName) {
          mo.push(file);
        }
      }
    }
    mo?.map((item) => {
      setBlockMediaItemsUpdate(false);
      setUseableMedia([item]);
    });
    dispatchEditedFlag();
    setOpenMediaSelection(false);
    return;
  };

  const addMediaFromUrl = (obj = mediaAddedViaSpecificPlaformUrlMethod[0]) => {
    const { extension, thumbnail, ...rest } =
      obj;
    setBlockMediaItemsUpdate(false);
    setUseableMedia([rest]);

    dispatchEditedFlag();
    dispatch(setSpecificMediaPlatformTied("global"));
    setOpenMediaSelection(false);
    if (googleDriveManager?.docs) {
      dispatch(setGoogleDriveManager({ ...googleDriveManager, docs: null }))
    }
  };

  const immediateActionWhenClieked = (remove) => {
    if (remove) {
      localStorage.removeItem("gdrivePlatform");
      return;
    }
    localStorage.setItem("gdrivePlatform", PlatformIdentifier.PINTEREST);
  }

  useEffect(() => {
    if (!googleDriveManager?.docs || localStorage.getItem('gdrivePlatform') !== PlatformIdentifier.PINTEREST) return;
    addMediaFromUrl(googleDriveManager?.docs);
    immediateActionWhenClieked(true)
  }, [googleDriveManager])

  useEffect(() => {
    specificMediaPlatformTied === "pinterest" &&
      mediaAddedViaSpecificPlaformUrlMethod.length &&
      addMediaFromUrl();
  }, [mediaAddedViaSpecificPlaformUrlMethod]);

  const handleConnect = async () => {
    try {
      googleDriveRef.current.click();
    } catch (error) {

    }
  }

  useEffect(() => {
    if (googleDriveManager['fromPreviews'] && googleDriveManager['promptConnect']) {
      setOpenConfirmation(true)
    }
  }, [googleDriveManager])

  const handleOpeningEditArea = async (source, width, height, myFile) => {
    setFileNameBackup(myFile?.name);
    const { new_width, new_height } = await recursiveAspectFinder(
      // 1080,  // This is commented to preserve the original aspect ratio of the image as PINTEREST accepts all aspect ratios
      // 1080, // This is commented to preserve the original aspect ratio of the image as PINTEREST accepts all aspect ratios
      width,
      height,
      width,
      height,
      1,
      0.06
    );
    try {
      const image = new Image();
      image.src = source;
      const canvas = document.createElement("canvas");
      canvas.width = new_width;
      canvas.height = new_height;
      let scale = Math.max(
        canvas.width / image.naturalWidth,
        canvas.height / image.naturalHeight
      );
      let x = canvas.width / 2 - (image.naturalWidth / 2) * scale;
      let y = canvas.height / 2 - (image.naturalHeight / 2) * scale;
      canvas
        .getContext("2d")
        .drawImage(
          image,
          x,
          y,
          image.naturalWidth * scale,
          image.naturalHeight * scale
        );
      const dataURL = canvas.toDataURL("image/png");
      setImageToEditUrl(dataURL);
      setComparebleUrl(source);
      setOpenEditArea(!openEditArea);
    } catch (error) {
      const image = new Image();
      image.src = source;
      const canvas = document.createElement("canvas");
      setTimeout(() => {
        canvas.width = 1080;
        canvas.height = 1080;
        let scale = Math.max(
          canvas.width / image.naturalWidth,
          canvas.height / image.naturalHeight
        );
        let x = canvas.width / 2 - (image.naturalWidth / 2) * scale;
        let y = canvas.height / 2 - (image.naturalHeight / 2) * scale;
        canvas
          .getContext("2d")
          .drawImage(
            image,
            x,
            y,
            image.naturalWidth * scale,
            image.naturalHeight * scale
          );
        const dataURL = canvas.toDataURL();
        setImageToEditUrl(dataURL);
        setComparebleUrl(source);
        setOpenEditArea(!openEditArea);
      }, 1000);
    }
  };

  const closeImgEditor = () => {
    setOpenEditArea(false);
  };

  const saveDesignStateImage = async (newUrl) => {
    closeImgEditor();
    const blob = await (await fetch(newUrl)).blob();
    const new_file = new File([blob], fileNameBackup, {
      type: "image/jpeg",
      lastModified: new Date(),
    });
    try {
      setUseableMedia((prevState) => {
        const newState = prevState.map((obj) => {
          if (obj.url === comparebleUrl) {
            setBlockMediaItemsUpdate(false);
            return { ...obj, url: newUrl, file: new_file, optimized: true };
          }
          return obj;
        });
        if (!manuallyEditedPlatforms[PlatformIdentifier.PINTEREST]) {
          const newData = { ...manuallyEditedPlatforms, pinterest: true };
          dispatch(setManuallyEditedPlatforms(newData || {}));
        }
        dispatch(setSelectedPlatformForPreview("refresh"));
        setTimeout(() => {
          dispatch(setSelectedPlatformForPreview(PlatformIdentifier.PINTEREST));
        }, 1000);
        return newState;
      });
    } catch (error) { }
  };

  return useableMedia[0]?.url ? (
    <>
      {openEditArea && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vh",
            width: "100%",
            zIndex: 10000,
          }}
        >
          <FilerobotImageEditor
            source={imageToEditUrl}
            theme={{
              palette: {
                "bg-secondary": !config.isDark
                  ? lightTheme?.rootBackground
                  : "#0b1727",
                "txt-primary": !config.isDark ? "#2e2e2e" : "#919090",
              },
              p: {
                fontFamily: "Roboto, Arial",
              },
            }}
            onBeforeSave={() => false}
            onSave={(editedImageObject, designState) => {
              let canvas = editedImageObject.imageCanvas;
              saveDesignStateImage(canvas.toDataURL());
            }}
            onClose={closeImgEditor}
            annotationsCommon={{
              fill: "#ff0000",
            }}
            Text={{ text: "Postly..." }}
            tabsIds={[
              TABS.ADJUST,
              TABS.ANNOTATE,
              TABS.WATERMARK,
              TABS.FILTERS,
              TABS.FINETUNE,
              TABS.RESIZE,
            ]}
            defaultTabId={TABS.ANNOTATE} // or 'Annotate'
            defaultToolId={TOOLS.TEXT} // or 'Text'
          />
        </div>
      )}

      {
        !viewOnly &&
        <Flex
          gap={3}
          className={`mt-2`}
          wrap={'wrap'}
        >
          <MultimediaSelectionDropdown
            dropDownAnchor={
              <CircularButton onClick={() => immediateActionWhenClieked(false)} title="Add Image">
                <FontAwesomeIcon icon={faImage} />
              </CircularButton>
            }
            specificPlatformTied={PlatformIdentifier.PINTEREST}
            fromPreview={true}
            initiatorId={`${PlatformIdentifier.PINTEREST}-photo-chooser`}
            externalFilePickerTitle={"Enter Photo Location"}
            prompt={"Add Photo From"}
            fileType={"image"}
            externalPickerPromptLabel={"Photo"}
            multiple={true}
            fileSelectionHandler={(response) => {
              if (response) {
                let { files, from_local, ...others } = response;
                updateMediaItems(files, from_local, others);
              }
            }}
          />

          <MultimediaSelectionDropdown
            dropDownAnchor={
              <CircularButton title="Add Video">
                <FontAwesomeIcon icon={faVideo} />
              </CircularButton>
            }
            specificPlatformTied={PlatformIdentifier.PINTEREST}
            fromPreview={true}
            initiatorId={`${PlatformIdentifier.PINTEREST}-video-chooser`}
            externalFilePickerTitle={"Enter Video Location"}
            prompt={"Add Video From"}
            fileType={"video"}
            externalPickerPromptLabel={"Video"}
            multiple={false}
            fileSelectionHandler={(response) => {
              if (response) {
                let { files, from_local, ...others } = response;
                updateMediaItems(files, from_local, others);
              }
            }}
          />
        </Flex>
      }
      <ParentSection>
        {
          googleDriveManager?.openPicker ? <GDriveDashboard platform={PlatformIdentifier.PINTEREST} /> : null
        }
        <Wrapper>
          {openMediaSelction && !selectingPhotoFromHome && mediaOpener}
          <PreviewContentArea
            mainBgColor={!config.isDark ? "white" : "#0b1727"}
            style={{
              borderColor: `${!config.isDark ? "#ededed" : "transparent"}`,
            }}
            theme={!config.isDark ? "1px 1px 3px #949494" : "none"}
          >
            {!viewOnly && manuallyEditedPlatforms[PlatformIdentifier.PINTEREST] && (
              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  fontStyle: "italic",
                  position: "absolute",
                  top: 0,
                  right: 5,
                  fontSize: 11,
                }}
              >
                <p
                  style={{
                    color: !config.isDark ? "grey" : "grey",
                  }}
                >
                  Edited
                </p>
              </div>
            )}
            <Flex
              gap={2}
              alignContent={"start"}
              alignItems={"start"}
              style={{
                width: '100%',
                padding: '2%'
              }}
            >
              {displayPlatformIcon(PlatformIdentifier.PINTEREST)}
              <Info>
                <span
                  type={"p"}
                  style={{
                    fontSize: 11,
                    fontWeight: "600",
                    color: `${!config.isDark ? "#050505" : "#E4E6EB"}`,
                  }}
                >
                  Pinterest Preview
                </span>
              </Info>
            </Flex>

            <SecondLayer>
              {useableMedia[0].is_video === true ? (
                <div
                  style={{ position: "relative", height: "auto", width: "auto" }}
                >
                  {
                    !viewOnly &&
                    <ImageDelete
                      editBgColor={!config.isDark ? "#e0e0e0" : "#0b1727"}
                      onClick={(e) => handleDeleteMedia(useableMedia[0])}
                    >
                      <FontAwesomeIcon icon={faRemove} />
                    </ImageDelete>
                  }
                  <BaseVideoPlayer
                    styles={{ borderRadius: 15 }}
                    controls={true}
                    height={"100%"}
                    width={"100%"}
                    videoInfoText={videoInfoText}
                    platform="pinterest"
                    videoUri={useableMedia[0]?.url}
                    file={useableMedia[0]?.file}
                    videoWidthLimit={1199}
                    videoHeightLimit={719}
                    checkingVideo={true}
                    fullFile={useableMedia[0]}
                    openVideoEditArea={openVideoEditArea}
                    setOpenVideoEditArea={setOpenVideoEditArea}
                    viewOnly={viewOnly}
                  />
                </div>
              ) : (
                <div
                  style={{ position: "relative", height: "auto", width: "auto" }}
                >
                  <PostImage
                    src={useableMedia[0]?.url}
                    alt="post preview"
                  />
                  {
                    !viewOnly &&
                    <ImageDelete
                      editBgColor={!config.isDark ? "#e0e0e0" : "#0b1727"}
                      onClick={(e) => handleDeleteMedia(useableMedia[0])}
                    >
                      <FontAwesomeIcon icon={faRemove} />
                    </ImageDelete>
                  }
                  {
                    !viewOnly &&
                    <PinterestImageEditButtonParent>
                      <ImageEditButton
                        tintBackground={config.isDark}
                        variant="primary"
                        onClick={() => handleOpeningEditArea(useableMedia[0]?.url, width, height)}
                      // style={{
                      //   position: "absolute",
                      //   margin: 10,
                      // }}
                      />
                    </PinterestImageEditButtonParent>
                  }
                </div>
              )}
            </SecondLayer>
          </PreviewContentArea>
        </Wrapper>
      
        <Modal
          show={openPixelDialoguePicker}
          onHide={() => {
            dispatch(setOpenPixelFilePickerDialog(false));
          }}
          onExit={() => {
            dispatch(setOpenPixelFilePickerDialog(false));
          }}
        >
          <Modal.Header>
            <Modal.Title>Pexels.com</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ExtrernalImageSearchDialogue
              setUseableMedia={setUseableMedia}
              determiner={PlatformIdentifier.PINTEREST}
              modalClose={() => {
                // dispatch(setOpenExternalFilePickerDialog(false));
              }}
            />
          </Modal.Body>
        </Modal>
        <ConfirmModal
          open={openConfirmation}
          title={"Connect Your Google Drive Account"}
          message={'Your google drive account is not connected or has been disconnected. Do you wish to connect?'}
          confirmText={"PROCEED"}
          cancelText={"CANCEL"}
          showDialoguePrompt
          onCancel={() => {
            setOpenConfirmation(false);
            dispatch(setGoogleDriveManager({
              ...googleDriveManager,
              promptConnect: false,
            }))
          }}
          onConfirm={() => {
            handleConnect();
            dispatch(setGoogleDriveManager({ ...googleDriveManager, promptConnect: false, fromPreviews: false }))
          }}
        />
        <GDriveConnectionRequestListener
          googleDriveRef={googleDriveRef}
        />
      </ParentSection>
    </>
  ) : (
    <div
      style={{
        display: "flex",
        minHeight: "100px",
        width: "100%",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        marginTop: "50px",
      }}
    >
      <GDriveConnectionRequestListener
        googleDriveRef={googleDriveRef}
      />
      {
        googleDriveManager?.openPicker ? <GDriveDashboard platform={PlatformIdentifier.PINTEREST} /> : null
      }
      <ConfirmModal
        open={openConfirmation}
        title={"Connect Your Google Drive Account"}
        message={'Your google drive account is not connected or has been disconnected. Do you wish to connect?'}
        confirmText={"PROCEED"}
        cancelText={"CANCEL"}
        showDialoguePrompt
        onCancel={() => {
          setOpenConfirmation(false);
          dispatch(setGoogleDriveManager({
            ...googleDriveManager,
            promptConnect: false,
          }))
        }}
        onConfirm={() => {
          handleConnect();
          dispatch(setGoogleDriveManager({ ...googleDriveManager, promptConnect: false, fromPreviews: false }))
        }}
      />


      {!viewOnly && !openMediaSelction &&
        "You need to have an image or video to preview pinterest."}
      {!viewOnly && openMediaSelction && !selectingPhotoFromHome && mediaOpener}
    </div>
  );
};

export default Pinterest;
