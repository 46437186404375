import classNames from 'classnames'
import FeedEvent from 'components/app/social/feed/FeedEvent'
import FeedGallery from 'components/app/social/feed/FeedGallery'
import FeedUrl from 'components/app/social/feed/FeedUrl'
import FalconLightBox from 'components/common/FalconLightBox'
import createMarkup from 'helpers/createMarkup'
import React from 'react'
import { Card, Image } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import PropTypes from 'prop-types';
import InstaFeedGallery from './InstaFeedGallery'

const InstaFeedCardContent = ({
    status,
    imgSrc,
    gallery,
    feedEvent,
    url,
    video
  }) => {
  return (
    <Card.Body className={classNames({ 'p-0': !!feedEvent })}>
    {!!status && <p dangerouslySetInnerHTML={createMarkup(status)} />}
    {!!imgSrc && (
      <FalconLightBox image={imgSrc}>
        <Image src={imgSrc} fluid rounded />
      </FalconLightBox>
    )}
    {!!gallery && <InstaFeedGallery images={gallery} />}
    {!!feedEvent && <FeedEvent {...feedEvent} />}
    {!!url && <FeedUrl {...url} />}
    {!!video && (
      <ReactPlayer url={video.src} controls={true} className="react-player" />
    )}
  </Card.Body>
  )
}

InstaFeedCardContent.propTypes = {
    status: PropTypes.string,
    imgSrc: PropTypes.string,
    gallery: PropTypes.array,
    feedEvent: PropTypes.object,
    url: PropTypes.object,
    video: PropTypes.object
  };

export default InstaFeedCardContent