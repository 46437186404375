import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setSearchHint } from "redux/slices/workspaceslice";
import { RoutePaths } from "constants";

const VideoEditorPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSearchHint("Search..."));
  }, []);
  //   const maintainanceDisplay = (
  //     <>
  //       <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
  //         Under maintenance
  //       </p>
  //       <hr />
  //       <p>
  //         Sorry, this page may not be accessible to you at the moment, please
  //         check back later.
  //       </p>
  //     </>
  //   );

  //   const introArea = (
  //     <>
  //       <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
  //         Kindly select any of the following to continue.
  //       </p>
  //       <hr />
  //     </>
  //   );

  return (
    <>
      <Container className="mt-5">
        <Row className="mt-3">
          <Col>
            <Card
              onClick={() => {
                navigate(RoutePaths.VIDEO_EDITOR_FULLSCREEN);
              }}
              className="text-center cursor-pointer"
            >
              <Card.Body className="">
                <img
                  src={process.env.PUBLIC_URL + "/add_text_to_video.png"}
                  style={{ maxHeight: "130px" }}
                  height="100%"
                  width="100%"
                  alt=""
                />
                <p className="mt-3">Add Text to Videos</p>
                <p style={{ fontSize: 14 }}>
                  Engage your audience with all the information needed,
                  additional text on your videos is a good way to do this.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card
              onClick={() => {
                navigate(RoutePaths.VIDEO_EDITOR_FULLSCREEN);
              }}
              className="text-center cursor-pointer"
            >
              <Card.Body className="">
                <img
                  style={{ maxHeight: "130px" }}
                  src={process.env.PUBLIC_URL + "/trimming_and_cropping.png"}
                  height="100%"
                  width="100%"
                  alt=""
                />
                <p className="mt-3">Trimming and Cropping</p>
                <p style={{ fontSize: 14 }}>
                  Trim your videos to ensure you get only the best footage out
                  there.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card
              onClick={() => {
                navigate(RoutePaths.VIDEO_EDITOR_FULLSCREEN);
              }}
              className="text-center cursor-pointer"
            >
              <Card.Body className="">
                <img
                  src={process.env.PUBLIC_URL + "/add_watermark.png"}
                  style={{ maxHeight: "130px" }}
                  height="100%"
                  width="100%"
                  alt=""
                />
                <p className="mt-3">Add Watermark</p>
                <p style={{ fontSize: 14 }}>
                  Make your videos your own with Watermarks. Branded content
                  help to give more visibility to your brand.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Card
              onClick={() => {
                navigate(RoutePaths.VIDEO_EDITOR_FULLSCREEN);
              }}
              className="text-center cursor-pointer"
            >
              <Card.Body className="">
                <img
                  src={process.env.PUBLIC_URL + "/resize_aspect_ratio.png"}
                  style={{ maxHeight: "130px" }}
                  height="100%"
                  width="100%"
                  alt=""
                />
                <p className="mt-3">Resize aspect ratio</p>
                <p style={{ fontSize: 14 }}>
                  Adapt your videos to different platforms, change your video
                  format as needed, from vertical to square to landscape
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card
              onClick={() => {
                navigate(RoutePaths.VIDEO_EDITOR_FULLSCREEN);
              }}
              className="text-center cursor-pointer"
            >
              <Card.Body className="">
                <img
                  src={process.env.PUBLIC_URL + "/remove_bg.png"}
                  style={{ maxHeight: "130px" }}
                  height="100%"
                  width="100%"
                  alt=""
                />
                <p className="mt-3">Remove background</p>
                <p style={{ fontSize: 14 }}>
                  Remove your video background. You can customize the background
                  with color, or images
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card
              onClick={() => {
                navigate(RoutePaths.VIDEO_EDITOR_FULLSCREEN);
              }}
              className="text-center cursor-pointer"
            >
              <Card.Body className="">
                <img
                  src={process.env.PUBLIC_URL + "/add_audio.png"}
                  style={{ maxHeight: "130px" }}
                  height="100%"
                  width="100%"
                  alt=""
                />
                <p className="mt-3">Add audio</p>
                <p style={{ fontSize: 14 }}>
                  Spice up your videos with good background music.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default VideoEditorPage;
