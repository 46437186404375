import APIService from "http/api_service";
import React, { useEffect, useState } from "react";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { useSelector } from "react-redux";
import Flex from "components/common/Flex";
import { Card, Spinner } from "react-bootstrap";
import FreePageHeader from "components/common/FreePageHeader";
import Builder from "./campaign_builder/Builder";

const CampaignBuilder = () => {
  const [workSpaceOwner, setWorkspaceOwner] = useState();
  const workSpace = useSelector(getActiveWorkSpace);
  const currentURL = window.location.href;


  const fetchWorkspaceOwner = () => {
    APIService.fetchWorkSpaceOwner(workSpace._id, (response, error) => {
      if (response) {
        let { data } = response;
        setWorkspaceOwner(data);
      }
    });
  };

  useEffect(() => {
    fetchWorkspaceOwner();
  }, []);
  return (
    <>
      {!workSpaceOwner && (
        <Flex
          alignItems={"center"}
          style={{
            height: "100vh",
          }}
          justifyContent={"center"}
        >
          <Spinner animation="border" />
        </Flex>
      )}

      {workSpaceOwner && (
        <Flex direction={"column"}>
          <FreePageHeader titleTag="h5" className="mb-1">
            <Flex style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <h5>Campaign URL Builder</h5>
              </div>
            </Flex>
            <p className="fs--1 mt-1">
              {`${(currentURL === `app.postly.ai` || currentURL === `http://localhost:3000`) && 'Postly'} UTM builder will generate custom UTM parameters to make tracking your marketing campaigns easier.`}
            </p>
          </FreePageHeader>
          <Card
            className="mt-2"
            style={{
              minHeight: "80vh",
            }}
          >
            <Builder />
          </Card>
        </Flex>
      )}
    </>
  );
};

export default CampaignBuilder;
