import Flex from 'components/common/Flex';
import Lottie from 'lottie-react';
import React from 'react'
import { Button, Card, Form, Spinner } from "react-bootstrap";
import CuriousAnimation from 'assets/animations/curious.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let features = [
  {
    icon: 'check-circle',
    text: 'Personalize content for each platform.'
  },
  {
    icon: 'check-circle',
    text: 'Manage threads on Twitter and Threads.'
  },
  {
    icon: 'check-circle',
    text: 'Rearrange media per platform.'
  },
  {
    icon: 'check-circle',
    text: 'Tag users in IG posts and Stories (images).'
  },
  {
    icon: 'clock',
    iconColor: 'text-warning',
    text: 'Schedule at different times per platform (coming soon)'
  },
]

const PreviewSkeleton = () => {
  return <Card
    style={{
      width: '100%'
    }}
    className='mt-2 p-2'
  >
    <Flex
      gap={1}
      // alignItems={'center'}
      style={{
        width: '100%'
      }}
    >
      <Flex
        direction={'column'}
        // justifyContent={'center'}
        gap={1}
      >
        <h5
          className='mt-2'
          // style={{
          //   fontStyle: 'italic'
          // }}
        >
          Did you know?
        </h5>
        <p
          className='fs--1'
          // style={{
          //   fontStyle: 'italic'
          // }}
        >
          You can do a lot more on this Preview area than just previewing your post!
        </p>
      </Flex>
      <div
        style={{
          width: '120px',
          height: '120px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Lottie
          animationData={CuriousAnimation}
          loop={true}
          style={{
            width: 120,
            maxWidth: 150,
            height: 120
          }}
        />
      </div>
    </Flex>
    <Flex
      direction={'column'}
      gap={1}
    >
      {
        features.map((feature, index) => (
          <div
            key={index}
            className='fs--2'
          >
            <FontAwesomeIcon
              icon={feature.icon}
              className={`mr-2 ${feature.iconColor || 'text-success'}`}
            /> {feature.text}
          </div>
        ))
      }
    </Flex>
  </Card>
  // return (
  //   <Card
  //     style={{
  //       width: '100%'
  //     }}
  //     className='mt-2 p-2'>
  //     <Flex
  //       gap={3}
  //       alignItems={'center'}
  //       style={{
  //         width: '100%'
  //       }}
  //     >
  //       <div
  //         style={{
  //           width: '45px',
  //           height: '45px',
  //           borderRadius: '50%',
  //           backgroundColor: '#e5e6eb',
  //           display: 'flex',
  //           justifyContent: 'center',
  //           alignItems: 'center'
  //         }}
  //       >

  //       </div>
  //       <Flex
  //         direction={'column'}
  //         justifyContent={'center'}
  //         gap={2}
  //       >
  //         <div style={{ height: 5, width: 130, backgroundColor: '#e5e6eb', borderRadius: '8px', }}></div>
  //         <div style={{ height: 5, width: 100, backgroundColor: '#e5e6eb', borderRadius: '8px', }}></div>
  //       </Flex>
  //     </Flex>
  //     <Flex
  //       gap={3}
  //       alignItems={'center'}
  //     >
  //       <Flex
  //         direction={'column'}
  //         justifyContent={'center'}
  //         gap={2}
  //         className={'mt-3'}
  //       >
  //         <div style={{ height: 10, width: 270, backgroundColor: '#e5e6eb', borderRadius: '10px', }}></div>
  //         <div style={{ height: 10, width: 250, backgroundColor: '#e5e6eb', borderRadius: '10px', }}></div>
  //         <div style={{ height: 10, width: 200, backgroundColor: '#e5e6eb', borderRadius: '10px', }}></div>
  //       </Flex>
  //     </Flex>
  //     <Flex
  //       gap={3}
  //       alignItems={'center'}
  //     >
  //       <Flex
  //         direction={'column'}
  //         justifyContent={'center'}
  //         gap={2}
  //         className={'mt-3'}
  //       >
  //         <div style={{ height: 220, minWidth: 265, backgroundColor: '#e5e6eb', borderRadius: '10px', }}></div>
  //       </Flex>
  //     </Flex>
  //   </Card>
  // )
}

export default PreviewSkeleton