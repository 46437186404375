import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PollChoices from './poll_choices';
import PollTimeSlot from './poll_time_slot';
import {
    getChoiceData,
    isContentPublishingOngoing,
    setContentPublishingInProgress,
    getSelectedPlatforms,
    getTimeZoneToPostContent,
    getPostGroupIdentifier,
    getEditablePost
} from '../../../../../redux/slices/postslice';
import StringUtils from 'utils/string';
import APIService from 'http/api_service';
import PostAudiencePage from '../audience';
import { createDateWithTimeZone } from 'utils/time';
import TextareaAutoSize from 'react-textarea-autosize';
import AppContext from 'context/Context';
import Divider from 'components/common/Divider';
import DatePicker from 'react-datepicker';
import {
    Button, Col, Form,
    OverlayTrigger, Popover, Row
} from 'react-bootstrap';
import InfoBar from 'components/common/InfoBar';
import SelectedTargetAudienceContainer from '../SelectedTargetAudienceContainer';
import Flex from 'components/common/Flex';
import { PlatformIdentifier, RoutePaths } from '../../../../../constants';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { useNavigate } from 'react-router-dom';
import { isFreeWorkspace } from 'utils/workspace_utils';
import { setPopupUpgradeAlert } from 'redux/slices/alertSlice';

export default function PollCreationDialog(props) {

    const workspace = useSelector(getActiveWorkSpace);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    let { modalClose, style = {} } = props;
    const [error, setError] = useState();
    const updatablePost = useSelector(getEditablePost);
    const updatablePollData = updatablePost?.data?.poll_data ?? {};
    const [question, setQuestion] = useState(updatablePollData?.question);
    const [pollDays, setPollDays] = useState(updatablePollData.pollDays ?? 1);
    const [pollHours, setPollHours] = useState(updatablePollData.pollHours ?? 0);
    const [pollMinutes, setPollMinutes] = useState(updatablePollData.pollMinutes ?? 0);
    const choiceData = useSelector(getChoiceData);
    const [timeToPost, setTimeToPost] = useState(updatablePost ? new Date(updatablePost.scheduled_for ?? updatablePost?.data?.time_to_post) : new Date());
    const timeZone = useSelector(getTimeZoneToPostContent);
    const [postNow, setPostNow] = useState(updatablePost ? false : true);
    const selectedPlatforms = useSelector(getSelectedPlatforms);
    let trueTargetPlatforms;
    const pollIdentifier = useSelector(getPostGroupIdentifier) ?? StringUtils.generateRandomString(64);
    const publishingInProgress = useSelector(isContentPublishingOngoing);
    const { config } = useContext(AppContext);

    const computePollDuration = () => {
        let total = 0;
        let daysInSecond = pollDays * 86400;
        let hoursInSecond = pollHours * 3600;
        let minsInSeconds = pollMinutes * 60;
        total += total + daysInSecond + hoursInSecond + minsInSeconds;
        return total;
    }

    const attemptPollCreation = () => {
        trueTargetPlatforms = selectedPlatforms.filter((x) => x.identifier && x.selected);
        if (trueTargetPlatforms.length === 0) {
            setError('Kindly select target platforms to publish poll to');
            return;
        }
        if (!question) {
            setError("Enter your question");
            return;
        }
        if (Object.keys(choiceData).length < 1) {
            setError('Malformed Poll');
            return;
        }
        for (let choice in choiceData) {
            let value = choiceData[choice];
            if (!value) {
                setError('Some choices were left empty');
                return;
            }
        }
        setError(null);
        let keywords = trueTargetPlatforms.map((x) => x.identifier);
        let data = {
            targets: trueTargetPlatforms,
            body: StringUtils.generateRandomString(64),
            time_to_post: createDateWithTimeZone(timeToPost, timeZone, true).toString(),
            time_zone: timeZone,
            post_group_identifier: pollIdentifier,
            keywords,
            instantPost: postNow,
            refined: true,
            poll_data: {
                pollDays,
                pollHours,
                pollMinutes,
                duration: computePollDuration(),
                question,
                choiceData,
                choices: Object.entries(choiceData).map((item) => {
                    let processedItem = item[1];
                    if (processedItem.length > 25) {
                        processedItem = processedItem.substring(0, 25);
                    }
                    return processedItem;
                })
            }
        };
        if (updatablePost) {
            data['updatable_post_id'] = updatablePost._id;
        }
        dispatch(setContentPublishingInProgress(true));
        APIService.postToWorkSpace(workspace._id, { data }, (response, err) => {
            //Fire and forget
            //Further communication is achieved via socket events below.
        });
        navigate(RoutePaths.POSTS)
    }

    const popOver = (
        <Popover
            style={{
                border: 'none',
                outline: 'none',
                width: 500,
                maxWidth: 500,
                margin: 0,
                padding: 0
            }} id="poll-popover">
            <PostAudiencePage
                prompt={"Who can see your Poll?"}
                only={[PlatformIdentifier.TWITTER, PlatformIdentifier.LINKED_IN]}
                handleCancelChanges={() => {
                    document.body.click();
                }}
                handleSavedChanges={() => {
                    document.body.click();
                }}
                goBack={() => {
                    document.body.click();
                }}
                height={200}
                hideShadow
            />
        </Popover>
    )

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 8,
                ...style
            }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 5
                    }}>
                    <TextareaAutoSize
                        maxRows={10}
                        minRows={2}
                        placeholder={'Ask a question'}
                        value={question}
                        as="textarea"
                        className={`shadow-none resize-none border-1 ${config.isDark ? 'border-x' : ''} px-card border-200`}
                        onChange={(e) => {
                            setQuestion(e.target.value);
                        }}
                        style={{
                            width: '100%',
                            outline: 'none',
                            whiteSpace: 'pre-wrap',
                            display: 'inherit',
                            background: config.isDark ? "#0b1727" : "white",
                            color: 'inherit',
                            paddingTop: 10,
                            fontSize: 'inherit',
                        }}
                    />
                    <PollChoices />
                    <Form.Group className='mt-3'>
                        <Form.Label>Poll Length</Form.Label>
                        <Divider />
                        <Row>
                            <Col lg={4}>
                                <PollTimeSlot
                                    selectedValue={pollDays}
                                    label={"Days"}
                                    onOptionsChangeHandler={(selectedValue) => {
                                        setPollDays(selectedValue);
                                    }}
                                    options={Array.from(Array(15).keys()).map((value, index) => {
                                        return {
                                            label: index,
                                            value: index,
                                        }
                                    })}
                                />
                            </Col>
                            <Col lg={4}>
                                <PollTimeSlot
                                    selectedValue={pollHours}
                                    label={"Hours"}
                                    onOptionsChangeHandler={(selectedValue) => {
                                        setPollHours(selectedValue);
                                    }}
                                    options={Array.from(Array(24).keys()).map((value, index) => {
                                        return {
                                            label: index,
                                            value: index,
                                        }
                                    })}
                                />
                            </Col>
                            <Col lg={4}>
                                <PollTimeSlot
                                    selectedValue={pollMinutes}
                                    label={"Mins"}
                                    onOptionsChangeHandler={(selectedValue) => {
                                        setPollMinutes(selectedValue);
                                    }}
                                    options={Array.from(Array(60).keys()).map((value, index) => {
                                        return {
                                            label: index,
                                            value: index,
                                        }
                                    })}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group className='mt-3'>
                        <Form.Label>When to Post Poll</Form.Label>
                        <Row>
                            <Col lg={8}>
                                <DatePicker
                                    minDate={new Date()}
                                    onChange={(e) => {
                                        setTimeToPost(new Date(e));
                                        setPostNow(false);
                                    }}
                                    selected={timeToPost ?? new Date()}
                                    className={'form-control'}
                                />
                            </Col>
                            <Col lg={4}>
                                <Form.Check
                                    type='switch'
                                    checked={postNow}
                                    label={'Post Now'}
                                    id='post-poll-now-switch'
                                    onChange={(e) => {
                                        let value = e.target.checked;
                                        setPostNow(value);
                                        if (value) {
                                            setTimeToPost(new Date());
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                    <OverlayTrigger
                        placement='top'
                        rootClose
                        trigger={'click'}
                        overlay={popOver}>
                        <span className='mt-3 cursor-pointer'>
                            <SelectedTargetAudienceContainer
                                selectedPlatforms={selectedPlatforms}
                                placeholder={"Select Targets"}
                                canAddMore={false}
                            />
                        </span>
                    </OverlayTrigger>
                    <InfoBar info={"Polls are currently only supported on Twitter and LinkedIn. More platforms support coming soon..."} />
                    {
                        error &&
                        <span style={{
                            padding: 10,
                            textAlign: 'center',
                            color: 'red'
                        }}>
                            {error}
                        </span>
                    }
                    <Flex style={{ gap: 10, alignSelf: 'flex-end' }} className={'mt-2'}>
                        <Button
                            variant={'default'}
                            onClick={() => {
                                modalClose();
                            }}>
                            CANCEL
                        </Button>
                        <Button
                            disabled={publishingInProgress}
                            isWorking={publishingInProgress}
                            variant={'primary'}
                            onClick={() => {
                                if (isFreeWorkspace(workspace)) {
                                    dispatch(setPopupUpgradeAlert(true))
                                    return;
                                }
                                attemptPollCreation();
                            }}
                        >
                            {publishingInProgress ? updatablePost ? "Updating Poll..." : "Creating Poll..." : updatablePost ? "Update Poll" : "Create Poll"}
                        </Button>
                    </Flex>
                </div>
            </div>
        </div>
    );
}