import Flex from 'components/common/Flex'
import React from 'react'
import AudienceAccountEdit from './audience_account_edit'

const AudienceLiStEdit = (props) => {
    const { setOpenAudienceGroupsView, cloneData, setCloneData } = props

    return (
        <Flex direction={'column'} className={'gap-1'}>
            {
                cloneData?.map((platform, index) => (<AudienceAccountEdit
                    key={index}
                    platform={platform}
                    setOpenAudienceGroupsView={setOpenAudienceGroupsView}
                    setCloneData={setCloneData}
                    cloneData={cloneData}
                />))
            }
        </Flex>
    )
}

export default AudienceLiStEdit