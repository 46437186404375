import styled, { keyframes } from "styled-components";

export const ItemHolder = styled.div`
   margin-top: 10px;
   min-height: 180px;
   width: 200px;
   border-radius: 10px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 10px;
`;

