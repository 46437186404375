import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: 0,
    email: '',
    account_switch_mode: false,
    flip_to_child_pages: false,
    popupUpgradeAlert: false,
    popupUpgradeAlertForLTD: false,
    popupUpgradeAlertGoBackOnCancel: false,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        changeAuthPage: (state, action) => {
            let newState = state.value;
            newState.push(action.payload);
            state.value = [...newState];
        },
        updateMagicEmail: (state, action) => {
            state.email = action.payload;
        },
        popPage: (state) => {
            state.value = [...state.value.splice(state.value.length - 1, 1)];
        },
        initialPage: (state) => {
            state.value = 0;
        },
        setSwitchMode: (state, action) => {
            state.account_switch_mode = action.payload;
        },
        setFlipToChildPages: (state, action) => {
            state.flip_to_child_pages = action.payload;
        },
    }
});

export let selectAuthPage = (state) => state.auth.value[state.auth.value.length - 1];
export let availablePagesCount = (state) => state.auth.value.length;
export let getMagicEmail = (state) => state.auth.email;
export const getSwitchMode = (state) => state.auth.account_switch_mode;
export const canFlipToChildPages = (state) => state.auth.flip_to_child_pages;
export let {
    changeAuthPage,
    popPage,
    updateMagicEmail,
    initialPage,
    setSwitchMode,
    setFlipToChildPages,
} = authSlice.actions;
export default authSlice.reducer;