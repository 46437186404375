import styled from 'styled-components';

export const CSSVariables = styled.div`
--panel-back: ${props => `#141629`};
--panel-stroke: ${props => `#222544`};
--main-back: ${props => `#070a1b`};
--secondary-text-color: ${props => `#a0a5d0`};
--input-color: ${props => `#22233e`};
--accent-color: ${props => `#166ef1`};
--button-hover: ${props => `#262746`};
`

export const Logo = styled.div`
  top: 0px;
  left: 0px;
  position: fixed;
  margin-right: 0px;
  text-align: center;
  height: 60px;
  line-height: 60px;
  color: var(--main-text-color);
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  background: var(--panel-back);
  width: 76px;
  border-right: 1px solid var(--panel-stroke);
  box-sizing: border-box;

  &:after {
    content: "";
  display: block;
  height: 1px;
  width: 40px;
  background-color: var(--panel-stroke);
  margin-left: 18px;
  }
`

export const TopCanvas = styled.div`
position: absolute;
top: 10px;
left: 0px;
z-index: 99999999;
width: 100%;
`
export const Undo = styled.div`
position: absolute;
top: 0px;
left: 10px;
font-family: Inter;
font-size: 14px;
color: var(--secondary - text - color);
vertical-align: middle;
opacity: 0.5;
height: 32px;
line-height: 32px;
border-radius: 5px;
padding-left: 10px;
padding-right: 10px;
font-weight: 500;
`

export const Redo = styled.div`
background: var(--panel-back);
  position: absolute;
  top: 0px;
  left: 94px;
  font-family: Inter;
  font-size: 14px;
  color: var(--secondary-text-color);
  vertical-align: middle;
  opacity: 0.5;
  height: 32px;
  line-height: 32px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
`

export const OtherControls = styled.div`
position: absolute;
  right: 20px;
  top: 0px;
  color: var(--main-text-color);
  font-family: Inter;
  height: 100%;
  font-size: 14px;
`

export const ZoomOptions = styled.div`
top: 33px;
  right: -10px;
  position: absolute;
  background-color: var(--panel-back);
  width: 150px;
  border-radius: 5px 0px 5px 5px;
  overflow: hidden;
`

export const ZoomLevel = styled.div`
margin-right: -10px;
  height: 32px;
  vertical-align: middle;
  width: 65px;
  text-align: right;
  background: var(--panel-back) !important;
  border-radius: 5px;
  opacity: 0.9;
`

export const HandTool = styled.div`
height: 32px;
  width: 32px;
  position: absolute;
  top: 0px;
  right: 60px;
  line-height: 32px;
  text-align: center;
  background-color: var(--panel-back);
  border-radius: 5px;
  opacity: 0.9;
`

export const BottomCanvas = styled.div`
position: absolute;
  bottom: 0px;
  right: -20px;
  z-index: 9999999;
  width: 100%;
`

export const ToolBar = styled.div`
position: absolute;
  height: calc(100% - 340px);
  width: 76px;
  background-color: var(--panel-back);
  border-right: 1px solid var(--panel-stroke);
  left: 0px;
  top: 0px;
  box-sizing: border-box;
  z-index: 99999;
  box-shadow: 1px 0px 0px #000000;
`

export const ToolWrap = styled.div`
overflow-y: overlay;
  margin-top: 60px;
  height: calc(100% - 60px);
`

export const Tool = styled.div`
border-radius: 5px;
  width: 55px;
  height: 50px;
  text-align: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
`

export const BehindBrowser = styled.div`
position: absolute;
  height: 100%;
  width: 299px;
  left: 76px;
  background: var(--panel-back);
  border-right: 1px solid var(--panel-stroke);
  z-index: 1;
  box-sizing: border-box;
`

export const Browser = styled.div`
position: absolute;
  height: calc(100% - 450px);
  width: 299px;
  background-color: var(--panel-back);
  border-right: 1px solid var(--panel-stroke);
  left: 76px;
  top: 110px;
  box-sizing: border-box;
  z-index: 999999;
  overflow-y: overlay;
  display: block;
  overflow-x: hidden;
`

export const BrowserContainer = styled.div`
width: 260px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
`

export const ImagesGrid = styled.div`
 width: 100%;
  line-height: 0;
  -webkit-column-count: 2;
  -webkit-column-gap: 15px;
  -moz-column-count: 2;
  -moz-column-gap: 15px;
  column-count: 2;
  column-gap: 15px;
  padding-bottom: 10px;
`

export const GalleryRow = styled.div`
  margin-top: 20px;
  display: grid;
  grid-gap: 15px;
  width: 100%;
  grid-auto-columns: auto;
  grid-template-columns: 52px 52px 52px 52px;
  overflow: hidden;
`

export const SearchFixed = styled.div`
  position: fixed;
  top: 0px;
  width: 279px;
  padding-left: 19px;
  margin-left: -19px;
  z-index: 999999;
  background-color: var(--panel-back);
  padding-top: 10px;
`
export const BrowserSearchInput = styled.input`
  background-color: var(--input-color);
  border: 0px;
  box-sizing: border-box;
  height: 35px;
  width: calc(100% - 70px);
  border-radius: 5px;
  text-indent: 28px;
  font-size: 14px;
  font-family: Inter;
  color: var(--main-text-color);
  outline: none !important;
  padding-right: 30px;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
`
export const SearchButton = styled.div`
background-color: var(--accent-color);
  box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.05),
    inset 0px -2px 1px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: inline-block;
  width: 45px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  height: 35px;
  vertical-align: top;
  box-sizing: border-box;
  text-align: center;
  line-height: 35px;
  font-weight: 500;
  margin-left: 5px;
`

export const DeleteSearch = styled.div`
  position: absolute;
  right: 75px;
  margin-top: 8px;
  width: 20px;
  visibility: hidden;
`

export const PixaBay = styled.div`
  right: 0px;
  margin-left: 0px;
  margin-top: 10px;
  margin-right: 75px;
  opacity: 0.4;
  position: absolute;
  display: inline-block;
`

export const LandingText = styled.div`
  color: var(--secondary-text-color);
  font-size: 12px;
  line-height: normal;
  font-family: Inter;
`

export const Category = styled.div`
  width: 100%;
  border: 1px solid var(--panel-stroke);
  height: 38px;
  line-height: 38px;
  color: #fff;
  font-size: 12px;
  font-family: Inter;
  border-radius: 5px;
  margin-bottom: 8px;
`

export const NoResults = styled.div`
  color: var(--secondary-text-color);
  font-size: 14px;
  font-family: Inter;
  text-align: center;
  margin-top: 10px;
  line-height: normal;
`

export const AddText = styled.div`
  width: 100%;
  color: var(--main-text-color);
  background-color: var(--input-color);
  font-family: Inter;
  margin-bottom: 10px;
  border-radius: 5px;
  text-indent: 10px;
  box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.05),
    inset 0px -2px 1px rgba(0, 0, 0, 0.05);
`

export const HeadingText = styled.div`
  font-size: 22px;
  font-weight: 700;
  height: 44px;
  line-height: 44px;
`

export const BodyText = styled.div`
  font-size: 12px;
  font-weight: 400;
  height: 28px;
  line-height: 28px;
`

export const UploadButton = styled.div`
  height: 38px;
  line-height: 38px;
  width: 93%;
  color: var(--main-text-color);
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  border-radius: 5px;
  background: var(--accent-color);
  box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.05),
    inset 0px -2px 1px rgba(0, 0, 0, 0.05);
  margin-top: 20px;
`

export const UploadTabs = styled.div`
  width: calc(100% - 20px);
  margin-top: 10px;
  margin-bottom: 10px;
`

export const UploadTab = styled.div`
  width: calc(100% / 2);
  display: inline-block;
  text-align: center;
  font-family: Inter;
  color: var(--secondary-text-color);
  font-size: 14px;
  font-weight: 500;
  vertical-align: middle;
  height: 42px;
  line-height: 40px;
  border-bottom: 1px solid var(--panel-stroke);
  box-sizing: border-box;
`

export const Properties = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  width: 300px;
  height: calc(100% - 340px);
  background-color: var(--panel-back);
  border-left: 1px solid var(--panel-stroke);
  z-index: 999;
  overflow-y: overlay;
`

export const PropertiesOverlay = styled.div`
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: #000;
  opacity: 0.4;
  position: fixed;
  visibility: hidden;
`

export const Align = styled.div`
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 15px;
`

export const HeadingRule = styled.hr`
  width: 260px;
  margin-left: auto;
  margin-right: auto;
  border: none;
  height: 1px;
  background-color: var(--panel-stroke);
  margin-bottom: 20px;
`

export const PanelSection = styled.div`
  width: 260px;
  margin-left: auto;
  margin-right: auto;
`

export const PropertyTitle = styled.div`
  font-family: Inter;
  color: var(--main-text-color);
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
`

export const Table = styled.table`
  width: 100%;
`

export const PropertyInput = styled.div`
  display: inline-block;
`

export const PropertyInputField = styled.input`
  background-color: var(--input-color) !important;
  border: none;
  color: var(--main-text-color);
  height: 35px;
  font-family: Inter !important;
  width: 91px;
  box-sizing: border-box;
  text-indent: 11px;
  border-radius: 5px;
  font-weight: normal;
  font-size: 14px;
`

export const ColorPicker = styled.div`
  position: absolute;
  top: 100px;
  left: 100px;
`

export const RsHandle = styled.div`
  background-color: var(--main-text-color) !important;
  border: 7px solid var(--main-text-color);
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2) !important;
  width: 13px !important;
  height: 13px !important;
  margin-top: -8px !important;
  border-radius: 30px !important;
  position: absolute !important;
  transform: translate(-7.5px, 0px);
`

export const RsProgress = styled.div`
 background-color: #116df7 !important;
  height: 2px !important;
  border-radius: 30px !important;
  box-sizing: border-box !important;
`

export const RsBar = styled.div`
  height: 2px !important;
  background-color: #222544 !important;
  border-radius: 30px !important;
  box-sizing: border-box !important;
`

export const FiltersButton = styled.div`
	height: 35px;
  box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.05),
    inset 0px -2px 1px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background-color: var(--input-color);
  color: var(--main-text-color);
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  line-height: 35px;
  width: 48%;
	display: inline-block;
  vertical-align: middle;
`

export const CropImage = styled.div`
	height: 35px;
  box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.05),
    inset 0px -2px 1px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background-color: var(--accent-color);
  color: var(--main-text-color);
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  line-height: 35px;
  width: 48%;
	display: inline-block;
  vertical-align: middle;
	margin-left: 10px;
`

export const Controls = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 60px;
  border-top: 1px solid var(--panel-stroke);
  background-color: var(--panel-back);
  z-index: 99999999;
  box-shadow: 0px -1px 0px #000000;
`

export const PlayBack = styled.div`
  position: absolute;
  margin: auto;
  left: 0px;
  right: 0px;
  text-align: center;
  height: 60px;
  bottom: 0px;
`

export const CurrentTime = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0px;
  right: 0px;
  transform: translate(-110px, 20px);
  width: 100px;
`

export const CurrentTotalTimeInput = styled.input`
  text-align: left;
  background-color: transparent;
  color: var(--main-text-color);
  border: none;
  font-family: Inter;
  font-weight: normal;
  font-size: 14px;
  width: 62px;
`

export const TotalTime = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0px;
  right: 0px;
  width: 100px;
  transform: translate(110px, 3px);
`

export const Share = styled.div`
  width: 160px;
  position: absolute;
  right: 160px;
  top: 10px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background-color: #30314e;
  color: var(--main-text-color);
  border-radius: 5px;
  box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.05),
    inset 0px -2px 1px rgba(0, 0, 0, 0.05);
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
`

export const ShareImg = styled.img`
  margin-bottom: -3px;
  margin-right: 5px;

  &:hover {
    cursor: pointer;
  opacity: 0.7;
  }
`

export const Download = styled.div`
  height: 38px;
  font-family: Inter;
  color: var(--main-text-color);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  line-height: 38px;
  background-color: var(--accent-color);
  box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.05),
    inset 0px -2px 1px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  right: 15px;
  bottom: 12px;

  &:hover {
    cursor: pointer;
  opacity: 0.7;
  }
`

export const DownloadImg = styled.img`
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -2px;
`

export const ImportExportModal = styled.div`
  width: 300px;
  height: 265px;
  background-color: var(--panel-back);
  border: 1px solid var(--panel-stroke);
  border-radius: 5px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
  position: absolute;
  right: 160px;
  bottom: 70px;
  visibility: hidden;
  display: block;
  z-index: 99999999999;
`

export const SubTitle = styled.div`
  color: var(--secondary-text-color);
  font-family: Inter;
  font-size: 14px;
  margin-left: 20px;
`

export const HeaderTwo = styled.p`
  color: var(--main-text-color);
  font-family: Inter;
  font-size: 14px;
  margin-left: 20px;
`

export const ImportExportProject = styled.div`
  background: var(--input-color);
  box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.05),
    inset 0px -2px 1px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  color: #fff;
  text-align: center;
  height: 38px;
  line-height: 38px;
  margin-left: 20px;
  width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
  font-family: Inter;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  opacity: 0.7;
  }
`

export const ImportExportProjectImg = styled.img`
  vertical-align: middle;
  margin-right: 5px;
  margin-bottom: 5px;
`

export const DownloadModal = styled.div`
  width: 300px;
  height: 255px;
  background-color: var(--panel-back);
  border: 1px solid var(--panel-stroke);
  border-radius: 5px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
  position: absolute;
  right: 17px;
  bottom: 70px;
  visibility: hidden;
  z-index: 9999999999;
`

export const BackgroundOverlay = styled.div`
  height: calc(100% - 60px);
  width: 100%;
  position: absolute;
  background: #070a1b;
  opacity: 0.6;
  visibility: hidden;
  z-index: 999999999;
  left: 0px;
  bottom: 60px;
`

export const Header = styled.p`
  font-family: Inter;
  color: var(--main-text-color);
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  margin-left: 20px;
`

export const SubHeader = styled.p`
  font-family: Inter;
  color: var(--secondary-text-color);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-left: 20px;
`

export const Label = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--main-text-color);
  margin-left: 10px;
  margin-bottom: 14px;
`

export const LabelSpan = styled.span`
  color: var(--secondary-text-color);
`

export const DownloadReal = styled.div`
  height: 38px;
  font-family: Inter;
  color: var(--main-text-color);
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  line-height: 38px;
  background-color: var(--accent-color);
  box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.05),
    inset 0px -2px 1px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 85%;
  margin: auto;
`

// canvas area
export const CanvasArea = styled.div`
position: absolute;
top: 0px;
left: 375px;
height: calc(100% - 342px);
width: calc(100% - 675px);
box-sizing: border-box;
overflow: hidden;
`

export const CanvasContainer = styled.div`
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
`

export const Canvas = styled.canvas`
  top: 0px !important;
  bottom: 0px !important;
  left: 0px;
  right: 0px;
  margin: auto;
  width: 100%;
  height: 100%;
`

export const ReplaceImage = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0px;
  right: 0px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  z-index: 999999999;
  opacity: 0;
  transition: all 0.25s cubic-bezier(0.05, 0.03, 0.35, 1);
  pointer-events: none;
`

export const LoadMedia = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0px;
  right: 0px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  z-index: 999999999;
  opacity: 0;
  transition: all 0.25s cubic-bezier(0.05, 0.03, 0.35, 1);
  pointer-events: none;
`

export const LayerList = styled.div`
  position: absolute;
  height: 245px;
  width: 375px;
  left: 0px;
  bottom: 60px;
  background-color: var(--panel-back);
  box-sizing: border-box;
  border-right: 1px solid var(--panel-stroke);
  z-index: 9999999;
`

export const Layer = styled.div`
  width: 100%;
  margin-bottom: 2px;
  box-sizing: border-box;
`

export const LayerPropsName = styled.div`
  height: 35px;
  width: 100%;
  color: var(--main-text-color);
  line-height: 35px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  text-indent: 25px;
  display: inline-block;
`

export const LayerCustomName = styled.div`
  background: transparent;
  color: var(--main-text-color);
  outline: none;
  border: 0px;
  font-weight: 500;
  font-size: 14px;
  font-family: "Inter";
  height: 25px;
`
export const LayerOptions = styled.div`
  float: right;
  margin-top: 5px;
  margin-right: 15px;
`

export const Lock = styled.div`
  display: inline-block;
  width: 17px;
  opacity: 0;
  margin-right: 7px;
`

export const PropertyName = styled.div`
  color: var(--secondary-text-color) !important;
  margin-bottom: 2px;
  text-indent: 50px !important;
  text-transform: capitalize;
  display: inline-block;
`

export const FreezeProp = styled.div`
  width: 17px;
  float: right;
  margin-right: 15px;
  margin-top: 7px;
  opacity: 0;
`

export const PropertyKeyFrame = styled.div`
  width: 9px;
  height: 9px;
  display: inline-block;
  margin-right: 12px;
  margin-left: -2px;
  background: var(--secondary-text-color);
  box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.05),
    inset 0px -2px 1px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  transform: rotate(45deg);
  opacity: 0.8;

  &:hover {
    opacity: 1 !important;
  }
`

export const LayerHead = styled.div`
  text-indent: 25px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: var(--secondary-text-color);
  line-height: 40px;
  height: 40px;
  width: 375px;
  position: fixed;
  margin-top: -35px;
  background-color: var(--panel-back);
  box-sizing: border-box;
  border-bottom: 1px solid var(--panel-stroke);
  border-top: 1px solid var(--panel-stroke);
  border-right: 1px solid var(--panel-stroke);
`

export const Nothing = styled.div`
  width: 20px;
  height: 20px;
  display: block;
  opacity: 0;
  position: absolute;
`

//timeline area 

export const TimeArea = styled.div`
  position: absolute;
  bottom: 60px;
  left: 375px;
  height: 245px;
  background-color: var(--main-back);
  width: calc(100% - 375px);
  border-top: 1px solid var(--panel-stroke);
  z-index: 999;
`

export const TimelineHandle = styled.div`
  height: 3px;
  width: 100%;
  position: absolute;
  bottom: 337px;
  z-index: 999999999999 !important;

  &:hover { 
  cursor: ns-resize;
  background-color: var(--panel-stroke);
  }
`

export const SeekArea = styled.div`
  height: 100%;
  z-index: 99999;
  width: calc(100% - 375px);
  overflow-x: auto;
  overflow-y: hidden;
  position: fixed;
  border-bottom: 1px solid var(--panel-stroke);
  border-top: 1px solid var(--panel-stroke);
  margin-top: -35px;
  box-sizing: border-box;
  pointer-events: none;

  &:before {
    content: "";
  display: block;
  position: fixed;
  width: 100%;
  height: 40px;
  background-color: var(--main-back);
  }
`

export const SeekEvents = styled.div`
  height: 40px;
  width: 100%;
  position: fixed;
  pointer-events: all;
`

export const InnerSeekArea = styled.div`
  height: 100%;
  box-sizing: border-box;
`

export const Timeline = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: overlay;
  box-sizing: border-box;
`

export const InnerTimeline = styled.div`
  width: 2000px;
  box-sizing: border-box;
  margin-top: 6px;
`

export const Row = styled.div`
  height: 35px;
  width: 100%;
  position: relative;
  margin-bottom: 2px;
`

export const TrimRow = styled.div`
  position: absolute;
  background-color: inherit;
  display: block;
  content: "";
  height: 100%;
  width: 100%;
  z-index: 9999;

  &:before {
    width: 5px;
  position: absolute;
  display: block;
  content: "";
  height: 100%;
  z-index: 9999 !important;
  }
  &:hover {
    &:before {
        cursor: ew-resize;
    }
  }
  &:after {
    width: 7px;
  position: absolute;
  right: 0px;
  height: 100%;
  content: "";
  z-index: 9999 !important;
  }

  &:hover {
    &:after {
        cursor: ew-resize;
    }
  }
`

export const KeyFrame = styled.div`
  z-index: 999;
  width: 0px;
  height: 0px;
  border-radius: 50%;
  background: var(--accent-color);
  box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.05),
    inset 0px -2px 1px rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  box-sizing: border-box;

  &:hover {
    cursor: ew-resize;
  }

  &:after {
    content: "";
  display: block;
  margin-left: -4.5px;
  width: 9px;
  height: 9px;
  background: inherit;
  box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.05),
    inset 0px -2px 1px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  transform: rotate(45deg);
  box-sizing: border-box;
  }
`

export const KeyframeSelected = styled.div`
&:after {
    box-shadow: 0px 0px 0px 2px var(--main-text-color),
    inset 0px 2px 2px rgba(255, 255, 255, 0.05),
    inset 0px -2px 1px rgba(0, 0, 0, 0.05) !important;
}
`
export const LineSnap = styled.div`
  width: 1px;
  height: calc(100% - 5px);
  background-color: red;
  position: absolute;
  opacity: 0;
  z-index: 99999;
  margin-top: 5px;
`
export const KeyframeProps = styled.div`
  width: 220px;
  height: 90px;
  background-color: var(--panel-back);
  border: 1px solid var(--panel-stroke);
  position: absolute;
  top: 100px;
  left: 100px;
  border-radius: 5px;
  z-index: 99999999;
  opacity: 0;
  display: none;
`

export const Easing = styled.div`
  width: 192px;
  margin-left: auto;
  margin-right: auto;
`

export const Buttons = styled.div`
  display: table;
  width: 100%;
  height: 38px;
  margin-top: 60px;
`
export const DeleteApplyEasing = styled.div`
  display: table-cell;
`

export const DeleteKeyFrameSpan = styled.span`
  background-color: #30314e;
  color: var(--main-text-color);
  border-radius: 5px;
  box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.05),
    inset 0px -2px 1px rgba(0, 0, 0, 0.05);
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  line-height: 38px;
  display: block;
  width: 90%;
  height: 100%;

  &:hover {
    opacity: 0.6;
  cursor: pointer;
  }
`

export const ApplyEasingSpan = styled.span`
  background-color: var(--accent-color);
  color: var(--main-text-color);
  border-radius: 5px;
  box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.05),
    inset 0px -2px 1px rgba(0, 0, 0, 0.05);
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  line-height: 38px;
  display: block;
  width: 100%;
  height: 100%;

  &:hover {
    opacity: 0.6;
  cursor: pointer;
  }
`

export const SeekHover = styled.div`
  height: 100%;
  background-color: #fff;
  width: 3px;
  opacity: 0.3;
  margin-top: 40px;
  pointer-events: none;
  top: 0px;
  z-index: 99999999;
  position: absolute;
  border-radius: 5px;
`

export const SeekBar = styled.div`
  margin-top: 40px;
  height: 100%;
  width: 2px;
  background-color: var(--secondary-text-color);
  position: absolute;
  z-index: 99999999;
  pointer-events: all;
  top: 0px;

  &:hover {
    outline: 3px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  cursor: ew-resize;
  }

  &:after {
    background: url(assets/seeker.svg);
  display: block;
  content: "";
  position: absolute;
  width: 13px;
  height: 18px;
  margin-left: -6px;
  margin-top: -10px;
  z-index: 9;
  box-sizing: border-box;
  }
`

export const TimeNumbers = styled.div`
  height: 40px;
  width: max-content;
  position: absolute;
  z-index: 99999999;
  top: 0px;
`

export const TimeNumber = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-text-color);
  text-align: center;
  width: 20px;
  position: relative;
  margin-top: 10px;
  display: inline-block;
  transform: translateX(-10px);
`

export const TimeNumberSpan = styled.span`
  display: block;
  width: 1px;
  height: 6px;
  background-color: #3c406f;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6px;
`

export const TimelineZoom = styled.div`
  position: absolute;
  left: 64px;
  bottom: 30px;
  z-index: 99999;
  width: 135px;
`

export const SpeedId = styled.div`
	left: 250px;
	position: absolute;
	bottom: 12px;
	z-index: 99999;
	font-family: Inter;
	color: var(--main-text-color);
	border-radius: 5px;
	height: 40px;
	line-height: 40px;
	padding-left: 5px;
	padding-right: 5px;
	font-size: 14px;

    &:hover {
        cursor: pointer;
	background-color: var(--input-color);
    }
`

export const SpeedClass = styled.div`
	width: 100%;
	height: 32px;
	font-size: 14px;
	text-indent: 15px;
	line-height: 32px;
	border-bottom: 1px solid var(--panel-stroke);

    &:hover {
        cursor: pointer;
	background: var(--input-color);
    }
`

export const SpeedIdImg = styled.img`
	vertical-align: middle;
`

export const SpeedSettings = styled.div`
	background-color: var(--panel-back);
	width: 80px;
	position: absolute;
	z-index: 9999;
	margin-top: -220px;
	margin-left: -10px;
	border: 1px solid var(--panel-stroke);
	border-radius: 5px;
	visibility: hidden;
`
export const VideoUpload = styled.div`
  display: none;
`

export const AudioUpload = styled.div`
  width: 100%;
  display: block;
  width: calc(100% + 40px);
  margin-left: -20px;
`

export const AudioItem = styled.div`
  width: calc(100% - 40px);
  height: 68px;
  border-radius: 5px;
  border: 1px solid var(--panel-stroke);
  margin-bottom: 10px;
  margin-left: 20px;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  background: var(--input-color);
  }
`

export const AudioItemActive = styled.div`
  border: 1px solid var(--accent-color);
  background-color: rgba(22, 95, 205, 0.1) !important;
`

export const AudioPreview = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-top: 10px;
  margin-left: 8px;
`

export const AudioPreviewImg = styled.img`
  width: 10px;
`

export const AudioThumb = styled.div`
  width: 50px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  margin-top: 6px;
  border-radius: 5px;
`

export const AudioInfo = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  font-family: Inter;
  font-size: 12px;
  margin-top: 8px;
`

export const AudioInfoDuration = styled.div`
  margin-top: 3px;
  color: var(--secondary-text-color) !important;
  font-size: 10px !important;
`

export const NoLayers = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  display: block;
`

export const SelectionArea = styled.div`
  background: rgba(46, 115, 252, 0.11);
  border: 2px solid rgba(98, 155, 255, 0.81);
  border-radius: 0.1em;
  z-index: 999999999999 !important;
`

// upload popup
export const UploadPopUp = styled.div`
	position: absolute;
	z-index: 999999999999;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	display: none;
`

export const UploadPopUpContainer = styled.div`
	position: absolute;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	margin: auto;
	height: 320px;
	width: 500px;
	background: var(--panel-back);
	border: 1px solid var(--panel-stroke);
	border-radius: 5px;
	z-index: 9999999;
`

export const UploadPopUpHeader = styled.p`
	height: 48px;
	width: 100%;
	position: relative;
	line-height: 48px;
`
export const UploadPopUpTitle = styled.p`
	color: var(--main-text-color);
	font-family: Inter;
	font-weight: 600;
	font-size: 14px;
	margin-left: 15px;
`

export const UploadPopUpClose = styled.div`
	position: absolute;
	top: 12px;
	right: 15px;
	width: 20px;
	z-index: 999999999;

    &:hover {
        cursor: pointer;
	opacity: .7;
    }
`

export const UploadPopUpArea = styled.div`
	width: calc(100% - 30px);
	margin: auto;
	margin-top: 0px;
	margin-bottom: 15px;
	height: 200px;
	border: 2px dashed var(--panel-stroke);
	border-radius: 5px;
	text-align: center;

    &:hover {
        cursor: pointer;
	background: var(--input-color);
    }
`

export const UploadDropGroup = styled.div`
	width: 200px;
	margin: auto;
	text-align: center;
	margin-top: 60px;
`

export const UploadDropGroupImg = styled.img`
	margin-bottom: 5px;
`
export const UploadDropGroupTitle = styled.p`
	color: var(--main-text-color);
	font-family: Inter;
	font-size: 14px;
	font-weight: 600;
`

export const UploadDropGroupSubTitle = styled.p`
	color: var(--secondary-text-color);
	font-family: Inter;
	font-size: 14px;
	font-weight: 400;
	margin-top: 5px;
`

export const UploadLink = styled.div`
	width: calc(100% - 30px);
	margin-left: auto;
	margin-right: auto;
	height: 48px;
`

export const UploadLinkInput = styled.input`
	background: var(--input-color);
	border-radius: 5px;
	display: inline-block;
	box-sizing: border-box;
	vertical-align: middle;
	width: 83%;
	height: 38px;
	line-height: 38px;
	text-decoration: none;
	outline: none;
	border: 0px;
	text-indent: 10px;
	color: var(--main-text-color);
`

export const UploadLinkAdd = styled.div`
	background: var(--accent-color);
	color: var(--main-text-color);
	text-align: center;
	font-family: Inter;
	height: 38px;
	line-height: 38px;
	border-radius: 5px;
	display: inline-block;
	width: 15%;
	margin-left: 5px;
	vertical-align: middle;
	box-sizing: border-box;
	font-size: 14px;

    &:hover {
        opacity: .7;
	cursor: pointer;
    }
`

export const UploadOverlay = styled.div`
	background: #000;
	opacity: .5;
	z-index: 99999;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	position: absolute;
`

// filters parent 

export const FiltersParent = styled.div`
	top: 50px;
	right: 10px;
	width: 250px;
	height: calc(100% - 100px);
	position: absolute;
	z-index: 9999;
	background: var(--panel-back);
	border: 1px solid var(--panel-stroke);
	border-radius: 5px;
	overflow: hidden;
	visibility: hidden;
`

export const ShowFilters = styled.div`
	visibility: visible!important;
`

export const Filters = styled.div`
	overflow-y: overlay;
	width: 100%;
	margin-top: 45px;
	height: calc(100% - 45px);
`

export const FiltersContainers = styled.div`
	width: 100%;
	height: 100%;
`

export const FiltersHeader = styled.p`
	position: fixed;
	width: 250px;
	background: var(--panel-back);
	height: 45px;
	line-height: 45px;
	z-index: 99999;
	margin-top: -45px;
	box-shadow: 0px 8px 4px -4px rgb(12 13 26 / 50%);
`

export const FiltersTitle = styled.p`
	color: var(--main-text-color);
	font-size: 14px;
	font-weight: 500;
	font-family: Inter;
	margin-left: 15px;
	margin-top: 10px;
`

export const ResetFilters = styled.div`
	float: right;
	margin-top: -20px;
	margin-right: 15px;
	color: var(--secondary-text-color);
	font-family: Inter;
	font-size: 14px;
	background: var(--input-color);
	border-radius: 5px;
	padding-left: 8px;
	padding-right: 8px;
	height: 25px;
	line-height: 25px;
	text-align: center;

    &:hover {
        cursor: pointer;
	opacity: .7;
    }
`

export const FiltersClose = styled.div`
	position: absolute;
	right: 15px;
	top: 13px;
	width: 20px;

    &:hover {
        cursor: pointer;
	opacity: .6;
    }
`

export const FilterRow = styled.div`
	color: var(--secondary-text-color);
  font-family: Inter;
  font-weight:400 !important;
  font-size: 14px;
  margin-top: 25px;
	margin-left: 15px;
`

export const PropertyFilter = styled.div`
  display: inline-block;
`

export const PropertyFilterInput = styled.input`
  background-color: var(--input-color) !important;
  border: none;
  color: var(--main-text-color);
  height: 35px;
  font-family: Inter !important;
  width: 41px;
  box-sizing: border-box;
  text-indent: 11px;
  border-radius: 5px;
  font-weight: normal;
  font-size: 14px;

  &:after {
    content: attr(data-label);
  color: var(--secondary-text-color);
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  margin-top: 10px;
  margin-left: -20px;
  text-align: right;
  position: absolute;
  }
`

export const SelectFilter = styled.div`
	float: right;
	margin-top: 10px;
	margin-right: 15px;
	width: 120px;
`

export const StatusToggle = styled.div`
	width: 120px;
	background: var(--input-color);
	border-radius: 5px;
	float: right;
	margin-right: 15px;
	height: 35px;
	line-height: 35px;
	margin-top: -10px;
	overflow: hidden;
`

export const StatusActive = styled.div`
	background: var(--accent-color);
	color: var(--main-text-color)!important;
`

export const StatusTrigger = styled.div`
	width: 48%;
	color: var(--secondary-text-color);
	font-size: 14px;
	font-family: Inter;
	text-align: center;
	display: inline-block;
	box-sizing: border-box;

    &:not {
        ${StatusActive}:hover {
            cursor: pointer;
	opacity: .7;
        }
    }
`

export const ChromaColor = styled.div`
	float: right;
	margin-right: 15px;
	margin-top: -8px;
`

export const ChromaColorInput = styled.input`
	margin-top: -27px!important;
	width: 85px!important;
`

export const FilterColor = styled.div`
	margin-top: 30px;
	margin-bottom: 25px;
`

// Eyedropper 

export const EyeDropper = styled.div`
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	display: block;
	margin-top: 5px;
`

export const LottieCanvas = styled.div`
	display: none;
`

// animated text
export const AnimatedSet = styled.div`
	width: 30px;
	padding-left: 15px;
	padding-right: 15px;
	color: var(--main-text-color);
	font-family: Inter;
	font-size: 14px;
	background: var(--accent-color);
	border-radius: 5px;
	text-align: center;
	display: inline-block;
	height: 35px;
	line-height: 35px;
	vertical-align: middle;
	margin-left: 8px;
	margin-top: -3px;

    &:hover {
        cursor: pointer;
	opacity: .7;
    }
`

export const OrderToogle = styled.div`
	width: 100%;
	background: var(--input-color);
	border-radius: 5px;
	height: 35px;
	line-height: 35px;
	overflow: hidden;
`

// Animated text grid

export const AnimatedTextGrid = styled.div`
	display: grid;
	grid-gap: 15px;
	grid-auto-columns: auto;
  grid-template-columns: 75px 75px 75px;
`

export const AnimatedTextItems = styled.div`
	background: var(--input-color);
	border-radius: 5px;
	height: 82px;
    width: 82px;
    text-align: center;
    position: relative;
    box-shadow: inset 0px 2px 2px rgb(255 255 255 / 5%), inset 0px -2px 1px rgb(0 0 0 / 5%);

    &:hover {
        cursor: pointer;
	background: var(--button-hover)!important;
    }
`

// more over

export const MoreOver = styled.div`
	position: absolute;
	background: var(--panel-back);
	width: 200px;
	border-radius: 5px;
	left: 80px;
	z-index: 999999999;
	border: 1px solid var(--panel-stroke);
	top: 200px;
	display: none;
`

export const MoreShow = styled.div`
	display: block!important;
`

export const ClearUploadProject = styled.div`
	height: 38px;
	color: var(--secondary-text-color);
	font-size: 14px;
	font-family: Inter;
	display: inline-block;
	width: 100%;
	line-height: 38px;
	vertical-align: middle;

    &:hover {
	cursor: pointer;
	background: var(--input-color)!important;
    }
`

export const ClearProjectImg = styled.div`
	vertical-align: middle;
	display: inline-block;
	margin-left: 15px;
	margin-right: 5px;
	margin-top: -5px;
`
export const UploadLottieImg = styled.div`
	margin-left: 16px;
	margin-right: 5px;
	margin-top: -5px;
	vertical-align: middle;
	display: inline-block;
`

// scrollbar 

export const Optimized = styled.div`
	padding: 20px;
	height: min-content;
	width: 300px;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	border-radius: 5px;
	background: var(--panel-back);
	border: 1px solid var(--panel-stroke);
	box-sizing: border-box;
	box-shadow: 0 4px 100px rgb(0 0 0 / 25%);
	z-index: 9999999999;
`

export const Emoji = styled.div`
	font-size: 16px;
`

export const OptTitle = styled.p`
	color: var(--main-text-color);
	font-family: Inter;
	font-size: 14px;
	font-weight: 700;
	margin-top: 10px;
`

export const OptDesc = styled.p`
	color: var(--secondary-text-color);
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	margin-top: 10px;
	line-height: 22px;
`