import React, { useContext, useEffect, useState } from "react";
import APIService from "../../../../http/api_service";
import { toast } from "react-toastify";
import GoPremiumAnimation from 'assets/animations/update-to-premium.json';
import Lottie from "lottie-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import DataTable, { createTheme } from 'react-data-table-component';
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "../../../../constants";
import AppContext from "context/Context";
import RichInfoBar from "components/common/RichInfoBar";
import { Button, Form, Modal, Card, Col, Spinner, Row } from "react-bootstrap";
import { getActiveWorkSpace, setActiveWorkspace } from "redux/slices/workspaceslice";
import { getLoggedInUser, setLoggedInUser } from "redux/slices/user_slice";
import { useSelector, useDispatch } from 'react-redux';

export default function CreditsPage(_props) {

    const dispatch = useDispatch();
    const [redeemableCode, setRedeemableCode] = useState();
    const [accountId, setAccountId] = useState();
    const [vouchers, setVouchers] = useState([]);
    const [loadingvouchers, setLoadingVouchers] = useState(true);
    const [redeeming, setRedeeming] = useState(false);
    const workSpace = useSelector(getActiveWorkSpace);
    const workSpaceId = workSpace._id;
    const [headsUp] = useState(localStorage.getItem('life_time_deal_text'));
    const loggedInUser = useSelector(getLoggedInUser);
    const activePlan = loggedInUser.active_plan;
    const navigate = useNavigate();
    const { config } = useContext(AppContext);
    const [openHeadsUp, setOpenHeadsUp] = useState(false);

    const creditColumns = [
        {
            name: 'Plan',
            selector: row => row.issuer,
        },
        {
            name: 'Code Used',
            selector: row => row.code,
        },
        {
            name: 'Access',
            selector: row => row.access
        }
    ];

    useEffect(() => {
        if (config.isDark) {
            createTheme('solarized', {
                text: {
                    primary: '#9da9bb'
                },
                background: {
                    default: '',
                },
            }, 'light');
        }
    }, [config]);

    useEffect(() => {
        if (headsUp) {
            setTimeout(() => {
                localStorage.removeItem('life_time_deal_text');
            }, 500);
        }
    }, [headsUp]);

    const initializeData = () => {
        APIService.fetchWorkSpaceOwner(workSpaceId, (response, err) => {
            if (err) {
                toast.error(err, { theme: 'colored' });
                if (err.toLowerCase().includes("owner not found")) {
                    dispatch(setActiveWorkspace(null));
                    navigate(RoutePaths.DEFAULT);
                }
                return;
            }
            let { _id: account_id } = response.data;
            setAccountId(account_id);
            fetchVouchersByAccount(account_id);
        });
    }

    const fetchVouchersByAccount = (accId) => {
        if (loggedInUser) {
            if (loggedInUser._id === accId) {
                setLoadingVouchers(true);
                APIService.showAttachedVoucherCode(accId, (response1, err) => {
                    if (err) {
                        toast.error(err, { theme: 'colored' });
                        return;
                    }
                    let { data } = response1;
                    data = data.map((x) => {
                        x['access'] = 'Premium';
                        return x;
                    });
                    setVouchers(data);
                    setLoadingVouchers(false);
                });
            } else {
                setLoadingVouchers(false);
            }
        } else {
            setLoadingVouchers(false);
        }
    };

    useEffect(() => {
        if (!accountId) {
            initializeData();
        }
    }, []);

    const redeemVoucher = (e) => {
        if (redeemableCode) {
            setRedeeming(true);
            APIService.fetchWorkSpaceOwner(workSpaceId, (response, err) => {
                if (err) {
                    toast.error(err, { theme: 'colored' });
                    return;
                }
                let workspaceOwnerLatestData = response.data;
                let { _id: account_id } = response.data;
                APIService.redeemVoucher(redeemableCode, account_id, (voucherValidationResponse, error) => {
                    if (error) {
                        toast.error(error, { theme: 'colored' });
                        setRedeeming(false);
                        return;
                    }
                    APIService.fetchLatestMe((response, error) => {
                        if (error) {
                            toast.error(error, { theme: 'colored' });
                            return;
                        }
                        let { data } = response;
                        dispatch(setLoggedInUser({ ...data }));
                        let updatedWorkspace = {
                            ...workSpace,
                            active_plan: workspaceOwnerLatestData.active_plan
                        };
                        setTimeout(() => {
                            dispatch(setActiveWorkspace({ ...updatedWorkspace }));
                        }, 100);
                        setRedeeming(false);
                        let validationResponseData = voucherValidationResponse.data;
                        let validationMessage = voucherValidationResponse.message;
                        let { meta } = validationResponseData;
                        if (meta) {
                            let destinationUrl = window.location.origin;
                            window.location.href = `${destinationUrl}/billing?meta=${meta}`;
                        } else {
                            setRedeemableCode("");
                            fetchVouchersByAccount(accountId);
                            toast.success(validationMessage, { theme: 'colored' });
                        }
                    });
                });
            });
        }
    }

    const CreditsTable = React.forwardRef((_props, _ref) => {
        return (
            <DataTable
                columns={creditColumns}
                data={vouchers}
                theme={config.isDark ? "solarized" : null}
            />
        )
    })
    CreditsTable.displayName = 'CreditsTable';

    return (
        <Card elevation={0}>
            <Card.Body className="p-0">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 10,
                    }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 10,
                    }}>
                        <Lottie
                            animationData={GoPremiumAnimation}
                            loop={false}
                            style={{
                                width: 50,
                                maxWidth: 150,
                                height: 50
                            }}
                        />
                        <h4
                            style={{
                                padding: 10,
                                textAlign: 'center',
                                lineHeight: 2
                            }}
                            dangerouslySetInnerHTML={{ __html: `<p>${activePlan['Name'] === 'Free' ? 'Go' : 'Get more'} ${activePlan['Name'] === 'Free' ? 'Premium' : ' Workspaces'} with Vouchers &amp; Credits</p>` }}>
                        </h4>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 10,
                        }}>
       <Row className="justify-content-center">
                            <Col md={12}>
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        redeemVoucher(e)
                                    }} style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexWrap: 'wrap',
                                        gap: 10,
                                    }}>
                                    <Col lg>
                                        <Form.Control
                                            type="text"
                                            disabled={redeeming}
                                            onChange={(e) => setRedeemableCode(e.target.value)}
                                            value={redeemableCode}
                                            placeholder='Enter redeemable code'
                                            style={{
                                                fontSize: 18,
                                                fontWeight: 600
                                            }}
                                        />
                                    </Col>
                                    <Col sm="auto">
                                        <Button
                                            isworking={redeeming}
                                            variant={'primary'}
                                            disabled={redeeming || !redeemableCode}
                                            type={'submit'}>
                                            {`${redeeming ? 'Redeeming Code...' : 'Redeem Code'}`}
                                        </Button>
                                    </Col>
                                </form>
                            </Col>
                        </Row>                 
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 5
                            }}>
                            <FontAwesomeIcon
                                icon={faInfoCircle}
                                style={{
                                    width: 15,
                                    height: 15,
                                }}
                            />
                            <span
                                style={{
                                    fontSize: 11,
                                    textAlign: 'center'
                                }}>{'Redeemable codes apply strictly to your own Workspace(s) and not Workspaces you were invited to'}
                            </span>
                        </div>
                    </div>
                    <Card style={{ marginTop: 20 }} elevation={0}>
                        <Card.Header className="bg-light">
                            <h5 className="mb-1 mb-md-0">Redeemed Codes &amp; Vouchers</h5>
                        </Card.Header>
                        <Card.Body className="p-0">
                            {
                                loadingvouchers ?
                                    <p
                                        type="p"
                                        onClick={() => fetchVouchersByAccount(accountId)}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontSize: "14px",
                                            color: "#90A4AE",
                                            textAlign: 'center',
                                            cursor: "pointer",
                                        }}
                                    >
                                        <span>Retrieving redeemed codes...</span>
                                        <Spinner animation="border" size={20} />
                                    </p>
                                    : <>
                                        {
                                            vouchers.length === 0 ? (
                                                <p
                                                    onClick={() => fetchVouchersByAccount(accountId)}
                                                    style={{
                                                        fontSize: "16px",
                                                        textAlign: 'center',
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    You&apos;ve not redeemed any voucher yet
                                                </p>
                                            ) : (
                                                <CreditsTable />
                                            )
                                        }
                                    </>
                            }
                        </Card.Body>
                    </Card>
                    <Modal
                        show={openHeadsUp}
                        onHide={() => setOpenHeadsUp(false)}
                        onExit={() => setOpenHeadsUp(false)}
                        centered
                        size='lg'>
                        <Modal.Body>
                            <RichInfoBar
                                title={"Great!"}
                                message={headsUp}
                                actionMessage={"Okay"}
                                action={() => {
                                    setOpenHeadsUp(false);
                                }}
                            />
                        </Modal.Body>
                    </Modal>
                </div>
            </Card.Body>
        </Card>
    );
}
