/* eslint-disable react-hooks/exhaustive-deps */
import Flex from 'components/common/Flex';
import React, { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import { useDispatch } from 'react-redux';
import { setShowMediaOptions } from 'redux/slices/postslice';
import { SlArrowDown, SlArrowUp } from 'react-icons/sl';

const FacebookCarouselInputForm = (props) => {
    const { index, e, handleUpdateCarouselLinkText } = props;
    const dispatch = useDispatch()
    const [text, setText] = useState('');
    const [description, setDescription] = useState('');
    const [name, setName] = useState('');
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [beganTyping, setBeganTyping] = useState(false);
    const [savingText, setSavingText] = useState(false);
    const [seeMore, setSeeMore] = useState(false);

    useEffect(() => {
        setText(e?.link)
        setDescription(e?.description)
        setName(e?.name)
    }, [index])

    const isLink = (text) => {
        const urlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+)(\/[^\s]*)?$/i;
        return urlPattern.test(text) && !text.includes(',');
    }

    useEffect(() => {
        return () => {
            if (typingTimeout) {
                clearTimeout(typingTimeout);
            }
        };
    }, [typingTimeout]);


    const _handleUpdateCarouselLinkText = (text, index, key) => {
        if (key === 'name') {
            setName(text)
        }
        if (key === 'description') {
            setDescription(text)
        }
        if (key === 'link') {
            setText(text)
        }

        setSavingText(true);
        if (!beganTyping) {
            setBeganTyping(true);
        }
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        setTypingTimeout(setTimeout(() => {
            handleUpdateCarouselLinkText(text, index, key)
            setSavingText(false);
        }, 1000));

    }

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center',
                gap: 10,
                marginTop: 20
            }}
        >
            <div>
                <img
                    onClick={() => { localStorage.setItem('gdrivePlatform', 'global'); dispatch(setShowMediaOptions(true)) }}
                    src={e?.picture}
                    alt=""
                    width={60}
                />
                {
                    savingText &&
                    <Flex className={'mt-2'}>
                        <div
                            className='flex flex-column align-items-center justify-content-center'
                        >
                            <ReactLoading type={'spin'} width={20} height={20} color={'#0052cc'} />
                            <p
                                className={'mb-0 mt-2'}
                                style={{
                                    fontSize: 12
                                }}
                            >
                                Saving..
                            </p>
                        </div>
                    </Flex>
                }
            </div>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 10,

                }}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 10,
                    }}
                >

                    <Form.Control
                        type="text"
                        style={{
                            borderColor: (e?.link && !isLink(e?.link)) ? 'red' : '#ced4da',
                        }}
                        value={text}
                        placeholder="Add a link to this image."
                        onChange={(e) =>
                            _handleUpdateCarouselLinkText(e.target.value, index, 'link')
                        }
                    />
                </div>
                {
                    seeMore &&
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 10,
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                justifyContent: 'center',
                            }}>
                            <p
                                className='m-0'
                                style={{
                                    fontSize: 14,
                                    textAlign: 'start'
                                }}
                            >
                                Title: the title of the link preview. (optional)
                            </p>
                            <Form.Control
                                type="text"
                                value={name}
                                placeholder="The title of the link preview. (optional)"
                                onChange={(e) =>
                                    _handleUpdateCarouselLinkText(e.target.value, index, 'name')
                                }
                            />
                        </div>
                    </div>
                }
                {
                    seeMore &&
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 10,
                        }}
                    >

                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                justifyContent: 'center',
                            }}>
                            <p
                                className='m-0'
                                style={{
                                    fontSize: 14,
                                    textAlign: 'start'
                                }}
                            >
                                Description: show price, discount, website domain, etc. (optional)
                            </p>
                            <Form.Control
                                type="text"
                                value={description}
                                placeholder="Description"
                                onChange={(e) =>
                                    _handleUpdateCarouselLinkText(e.target.value, index, 'description')
                                }
                            />
                        </div>
                    </div>
                }
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 10,
                }}
            >
                {
                    seeMore ? <SlArrowUp
                        size={20}
                        cursor={'pointer'}
                        onClick={() => setSeeMore(!seeMore)}
                    /> :
                        <SlArrowDown
                            size={20}
                            cursor={'pointer'}
                            onClick={() => setSeeMore(!seeMore)}
                        />
                }
            </div>
        </div>
    )
}

export default FacebookCarouselInputForm