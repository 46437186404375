import React, { useContext, useEffect, useState } from "react";
import ProfileDropdown from "components/navbar/top/ProfileDropdown";
import GeneralCardLayout from "layouts/GeneralCardLayout";
import { Row, Col, Image, Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import OrganizationSetup from "./organization";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Flex from "components/common/Flex";
import setupBg from 'assets/img/team/org.jpg';
import NewWorkspaceInOrganization from "./workspace";
import { RoutePaths, WorkSpaceContentApprovalWorkFlow } from "../../../constants";
import WorkSpaceApprovalWorkFlow from "../new-workspace/workspace_approval_setup";
import { useSelector, useDispatch } from 'react-redux';
import OrganizationAndWorkspaceSetupReview from "./review";
import SubmitButton from "components/common/SubmitButton";
import APIService from "http/api_service";
import { toast } from "react-toastify";
import { setActiveWorkspace } from "redux/slices/workspaceslice";
import { getLoggedInUser, setLoggedInUser } from "redux/slices/user_slice";
import { useNavigate } from "react-router-dom";
import { cachePosts } from "redux/slices/postslice";
import { UpgradeToPremiumAccountCard } from "../new-workspace";
import useOrganizationStore from "state/organization_store";
import firebase from '../../../firebase';
import AppContext from "context/Context";
import './organization.css';
import { lightTheme } from "utils/theme";
import { styled } from "@mui/material";

export default function OrganizationAndWorkspaceSetup() {

    const { t } = useTranslation();
    const { config: { isDark } } = useContext(AppContext);
    const authenticatedUser = useSelector(getLoggedInUser);
    const [activeStep, setActiveStep] = React.useState(0);
    const [attributes, setAttributes] = useState();
    const allWorkspaces = useOrganizationStore(state => state.allWorkspaces);
    const setAllWorkspaces = useOrganizationStore(state => state.setAllWorkspaces);
    const workspace = useOrganizationStore(state => state.workspaceForOrganizationBeingCreated);
    const organization = useOrganizationStore(state => state.organizationBeingCreated);
    const setActiveOrganization = useOrganizationStore(state => state.setActiveOrganization);
    const setWorkspaceForOrganizationBeingCreated = useOrganizationStore(state => state.setWorkspaceForOrganizationBeingCreated);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [finalizingCreation, setFinalizingCreation] = useState(false);
    const [openUpgradeCard, setOpenUpgradeCard] = useState(false);
    const [displayOrganizationTypeSelectionPage, setDisplayOrganizationTypeSelectionPage] = useState(true);
    const [organizationType, setOrganizationType] = useState('');
    const [initialOrganizationName, setInitialOrganizationName] = useState();

    const authenticateAnonymouslyForFileStorage = () => {
        firebase.auth().onAuthStateChanged((user) => {
            if (!user) {
                firebase.auth().signInAnonymously().then(() => {
                }).catch((_error) => {
                });
            }
        });
    }

    useEffect(() => {
        authenticateAnonymouslyForFileStorage();
    }, []);

    useEffect(() => {
        let params = (new URL(document.location)).searchParams;
        let receivedAttributes = params.get("attributes");
        if (receivedAttributes) {
            setAttributes(JSON.parse(decodeURIComponent(receivedAttributes)));
        }
    }, []);

    const finalizeCreation = async () => {
        setFinalizingCreation(true);
        let formData = JSON.parse(JSON.stringify({ workspace, organization }));
        if (attributes) {
            formData.attributes = attributes;
        }
        if (organization?.avatar && typeof organization?.avatar !== 'string') {
            let organizationLogoUploadForm = new FormData();
            organizationLogoUploadForm.append("file", organization?.avatar[0].file);
            let response = await APIService.uploadNewFile(null, organizationLogoUploadForm, 0, null, 0);
            let result = response.data;
            formData.organization.avatar = result;
        }
        if (workspace?.avatar && typeof workspace?.avatar !== 'string') {
            let workspaceLogoUploadForm = new FormData();
            workspaceLogoUploadForm.append("file", workspace?.avatar[0].file);
            let response = await APIService.uploadNewFile(null, workspaceLogoUploadForm, 0, null, 0);
            let result = response.data;
            formData.workspace.avatar = result;
        }
        APIService.upsertOrganizationWithWorkspace(formData, (response, error) => {
            if (error) {
                setFinalizingCreation(false);
                if (error.toLowerCase().includes('Pay For Extra Workspace'.toLowerCase())) {
                    setOpenUpgradeCard(true);
                    return;
                }
                if (error.toLowerCase().includes('upgrade'.toLowerCase())) {
                    setOpenUpgradeCard(true);
                } else {
                    toast.error(error, { theme: 'colored' });
                }
                return;
            }
            let { data } = response;
            let workspace = data.workspace;
            let organization = data.organization;
            dispatch(setActiveWorkspace(workspace));
            setActiveOrganization(organization);
            let allWorkspacesCopy = [...(allWorkspaces ?? [])];
            let workspaceIndex = allWorkspacesCopy.findIndex(w => w._id === workspace._id);
            if (workspaceIndex !== -1) {
                allWorkspacesCopy[workspaceIndex] = workspace;
            } else {
                allWorkspacesCopy.push(workspace);
            }
            setAllWorkspaces(allWorkspacesCopy);
            dispatch(setLoggedInUser({
                ...authenticatedUser,
                has_workspaces: true
            }));
            dispatch(cachePosts([]));
            setWorkspaceForOrganizationBeingCreated(null);
            setTimeout(() => {
                setFinalizingCreation(false);
                navigate(RoutePaths.DEFAULT);
                window.location.reload();
            }, 1000);
        });
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const steps = [
        {
            stepLabel: t('organization'),
            content: <OrganizationSetup attributes={attributes} handleNext={handleNext} handleBack={handleBack} initialOrganizationName={initialOrganizationName} />
        },
        {
            stepLabel: t('workspace'),
            content: <NewWorkspaceInOrganization attributes={attributes} handleOnClose={handleNext} />
        },
        {
            stepLabel: t('workspace_approval_workflow'),
            content: <Flex direction={'column'} className={'gap-3'}>
                <WorkSpaceApprovalWorkFlow
                    hideFooter
                    workSpace={workspace}
                    approvalOption={(workspace?.settings ?? {})[WorkSpaceContentApprovalWorkFlow.ACTIVE_WORKSPACE_APPROVAL_OPTION]}
                    passOwnersPostsThroughApproval={(workspace?.settings ?? {})[WorkSpaceContentApprovalWorkFlow.ALLOW_OWNERS_POST_GO_THROUGH_APPROVAL]}
                    passAdminsPostsThroughApproval={(workspace?.settings ?? {})[WorkSpaceContentApprovalWorkFlow.ALLOW_ADMINS_POST_GO_THROUGH_APPROVAL]}
                    handleApprovalOptionSelectionHandler={({ index, selection, approvalCountValue, allowOwnersToGoThroughApproval }) => {
                        let settings = {
                            [WorkSpaceContentApprovalWorkFlow.ACTIVE_WORKSPACE_APPROVAL_OPTION]: index ?? selection,
                            [WorkSpaceContentApprovalWorkFlow.ALLOW_OWNERS_POST_GO_THROUGH_APPROVAL]: allowOwnersToGoThroughApproval
                        };
                        if (approvalCountValue) {
                            settings[WorkSpaceContentApprovalWorkFlow.THIRD_APPROVAL_OPTION_VALUE] = approvalCountValue;
                        }
                        setWorkspaceForOrganizationBeingCreated({ ...workspace, settings });
                    }}
                />
                <Button onClick={handleNext} size="sm" className="rounded-pill">{t('next')}</Button>
            </Flex>
        },
        {
            stepLabel: t('review'),
            content: <OrganizationAndWorkspaceSetupReview handleNext={handleNext} />
        },
        {
            stepLabel: t('done'),
            content: <Flex><SubmitButton className="rounded-pill" onClick={finalizeCreation} label={t('create')} processing={finalizingCreation} /></Flex>
        }
    ];

    const StyledStepLabel = styled(StepLabel)(({ isDark }) => ({
        ".MuiStepLabel-label": {
            color: isDark ? '#adb5bd' : '#495057',
        },
        "& .MuiStepLabel-active": {
            color: lightTheme.primary
        }
    }));

    return (
        <GeneralCardLayout
            leftSideContent={
                <div>
                    <ProfileDropdown />
                </div>
            }
        >
            <h4 className={`mb-${displayOrganizationTypeSelectionPage ? 0 : 4}`}>{t('set_up_your_organization')}</h4>
            {
                displayOrganizationTypeSelectionPage &&
                <p className="mb-4">{t('organization_type_use_case')}</p>
            }
            {
                displayOrganizationTypeSelectionPage &&
                <Row className="mb-3 g-3">
                    <Col lg={12}>
                        <div className="d-flex justify-content-around">
                            <div
                                className={`${organizationType !== 'personal' ? 'organization-type-card' : ''} ${organizationType === 'personal' ? 'selected' : ''}`}
                                onClick={() => {
                                    setOrganizationType('personal');
                                }}
                                style={{
                                    border: organizationType === 'personal' ? '2px solid #007bff' : '1px solid #ddd',
                                    borderRadius: '10px',
                                    padding: '20px',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    boxShadow: organizationType === 'personal' ? '0 4px 12px rgba(0, 123, 255, 0.2)' : 'none'
                                }}
                            >
                                <h5>{t('personal_use')}</h5>
                                <p>{t('use_full_name_as_organization_name')}</p>
                            </div>
                            <div
                                className={`${organizationType !== 'business' ? 'organization-type-card' : ''} ${organizationType === 'business' ? 'selected' : ''}`}
                                onClick={() => {
                                    setOrganizationType('business');
                                }}
                                style={{
                                    border: organizationType === 'business' ? '2px solid #007bff' : '1px solid #ddd',
                                    borderRadius: '10px',
                                    padding: '20px',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    boxShadow: organizationType === 'business' ? '0 4px 12px rgba(0, 123, 255, 0.2)' : 'none'
                                }}
                            >
                                <h5>{t('business_use')}</h5>
                                <p>{t('create_organization_for_business_purposes')}</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            }
            {
                displayOrganizationTypeSelectionPage && organizationType &&
                <Flex alignItems={'center'} justifyContent={'center'}>
                    <Button className="px-3 mt-3 rounded-pill" style={{ minWidth: 200 }} onClick={() => {
                        if (organizationType === 'personal') {
                            let userFullName = `${authenticatedUser?.first_name ?? ''} ${authenticatedUser?.last_name ?? ''}`.trim();
                            if (userFullName) {
                                setInitialOrganizationName(userFullName);
                            }
                        }
                        setDisplayOrganizationTypeSelectionPage(false);
                    }}>{t('next')}</Button>
                </Flex>
            }
            {
                !displayOrganizationTypeSelectionPage &&
                <Row className="mb-3 g-3">
                    <Col lg={6}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {
                                steps.map((step, index) => (
                                    <Step key={step.stepLabel}>
                                        <StyledStepLabel
                                            isDark={isDark}
                                            className="cursor-pointer"
                                            onClick={() => {
                                                if (index < activeStep) {
                                                    setActiveStep(index);
                                                }
                                            }}
                                        >
                                            {step.stepLabel}
                                        </StyledStepLabel>
                                        <StepContent>
                                            {step.content}
                                        </StepContent>
                                    </Step>
                                ))
                            }
                        </Stepper>
                    </Col>
                    <Col lg={6} className="d-none d-lg-block">
                        <Flex direction={'column'} alignItems={'center'} justifyContent={'center'} className={'h-100'}>
                            <Image src={setupBg} className="img-fluid" />
                        </Flex>
                    </Col>
                </Row>
            }
            <Modal
                show={openUpgradeCard}
                onHide={() => setOpenUpgradeCard(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <UpgradeToPremiumAccountCard />
                </Modal.Body>
            </Modal>
        </GeneralCardLayout>
    )
}