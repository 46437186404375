import { faCheckDouble, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import APIService from 'http/api_service';
import React, { useContext, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import {
    getUserTags,
    setUserTags
} from 'redux/slices/postslice';
import AppContext from 'context/Context';
import { darkTheme, lightTheme } from 'utils/theme';
import { useSelector, useDispatch } from 'react-redux';
import { findPlatformByIdentifier } from 'components/app/platforms';
import { Icon } from '@iconify/react';
import twitterFill from '@iconify/icons-akar-icons/twitter-fill';
import { PlatformIdentifier } from 'constants';
import { BarLoader } from "react-spinners";
import {
    InputContainer, ListContainer,
    ListDetailsBox, ListDp, ListItem,
    ListNames, MainArena, SubmitArea,
    TitleArea, TopArea
} from './TaggingStyles';
import DebounceableEditBox from '../feed/debounceable_edit_box';
import Divider from 'components/common/Divider';
import { ImCancelCircle } from 'react-icons/im'
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { toast } from 'react-toastify';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

const TaggingPop = (props) => {
    const { fromImageTag } = props;
    const workSpace = useSelector(getActiveWorkSpace);
    const dispatch = useDispatch()
    const [usernamesData, setUsernamesData] = useState([]);
    const [loading, setLoading] = useState(false)
    const { config } = useContext(AppContext);
    const userTags = useSelector(getUserTags);
    const postTextBody = props.postTextBody;
    const [pickedUsernames, setPickedUsernames] = useState([]);
    const [search, setSearch] = useState();

    const fetchUsernames = async (search) => {
        setLoading(true);
        setPickedUsernames([]);
        APIService.fetchTaggableUsersOrPublicPage(workSpace._id, search, (response, error) => {
            if (error) {
                setUsernamesData([]);
                setLoading(false);
                return;
            }
            let { data } = response;
            setUsernamesData(data);
            setLoading(false)
        })
    }

    const addUserPickedUsername = (value) => {
        const isExisting = pickedUsernames?.filter(x => x?.username !== value?.username);
        if (isExisting?.length != pickedUsernames?.length) {
            setPickedUsernames(isExisting)
            // dispatch(setUserTags(isExisting));
            return
        }
        const cloneUserTags = [...userTags];
        setPickedUsernames(prev => [...prev, value])
        // dispatch(setUserTags([...cloneUserTags, value]));
    }

    const getColor = (identifier) => {
        try {
            let platform = findPlatformByIdentifier(identifier);
            return platform.color;
        } catch (error) { }
    }

    const getIcon = (key) => {
        switch (key) {
            case PlatformIdentifier.TWITTER:
                return twitterFill;
            case PlatformIdentifier.INSTAGRAM:
                return 'akar-icons:instagram-fill';
            case PlatformIdentifier.FACEBOOK:
                return "akar-icons:facebook-fill"
            case PlatformIdentifier.LINKED_IN:
                return "akar-icons:linkedin-box-fill"
            case PlatformIdentifier.REDDIT:
                return "akar-icons:reddit-fill"
            case PlatformIdentifier.TELEGRAM:
                return "akar-icons:telegram-fill"
            case PlatformIdentifier.PINTEREST:
                return "akar-icons:pinterest-fill"
            case PlatformIdentifier.GOOGLE_MY_BUSINESS:
                return "ant-design:google-circle-filled"
            case PlatformIdentifier.YOUTUBE:
                return "akar-icons:youtube-fill"
            case PlatformIdentifier.TIKTOK:
                return "akar-icons:tiktok-fill"
            default:
                return null;
        }
    }

    const tagListing = usernamesData?.map((item, index) => {
        const canCheck = pickedUsernames?.find(x => x?.username === item?.username);
        let icon = getIcon(item?.identifier);
        return <ListItem
            listHeight={'60px'}
            hoverColor={!config.isDark
                ? lightTheme.surfaceColor
                : darkTheme.rootBackground} onClick={() => addUserPickedUsername(item)}>
            <ListDetailsBox>
                <ListDp>
                    <img src={item?.photo} alt="" height="100%" width="100%" style={{ objectFit: 'cover' }} />
                </ListDp>
                <ListNames>
                    <b>
                        {item?.full_name}
                    </b>
                    <div>
                        @{item?.username}
                    </div>

                </ListNames>
            </ListDetailsBox>
            <Icon icon={icon} color={getColor(item?.identifier)} />

            {
                canCheck &&
                <FontAwesomeIcon
                    style={{
                        color: `${!config.isDark ? "green" : "green"
                            }`,
                    }}
                    icon={faCheckDouble}
                />
            }
        </ListItem>
    })

    const handleCancel = async () => {
        if (fromImageTag) {
            props.setPopDropClose(false); // to close the pop because it is from image tag which is reversed in value
            return;
        }
        props.setPopDropClose(true);
    }

    const handleSave = async (valuePassed) => {

        let newValue = props?.value;
        pickedUsernames.map((item, index) => {
            newValue = `${newValue} @${item.username}`;
        })
        let refined_search = search.charAt(0) == '@' ? search : `@${search}`;
        if (fromImageTag) {
            props?.setUpdateDirectly(refined_search)
            return;
        }
        const newValueOthers = `${props?.value} ${refined_search}`
        if (props?.platform === PlatformIdentifier.TWITTER) {
            dispatch(setUserTags([...userTags, ...pickedUsernames]));
            console.log(newValue, 'newValue')
            props?.setValue(newValue)
            props?.setUpdateDirectly(newValue)
            setPickedUsernames([]);
            handleCancel()
            // props?.updateText(newValue)
            return;
        }
        const newObj = {
            username: refined_search,
            identifier: props?.platform
        }
        dispatch(setUserTags([...userTags, newObj]));
        props?.setValue(newValueOthers)
        props?.setUpdateDirectly(newValueOthers)
        setPickedUsernames([]);
        console.log(userTags, "userTags");
        handleCancel()
        // props?.updateText(newValueOthers)
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <MainArena mainWidth={props.width}>
                        <TopArea>
                            <TitleArea>
                                <h6>Tag A User</h6>
                            </TitleArea>
                            {/* <ImCancelCircle /> */}
                        </TopArea>
                        {/* <Divider /> */}
                        <InputContainer>
                            <DebounceableEditBox
                                title={'Public Account name or Username'}
                                type='text'
                                placeholder={"E.g @johnson"}
                                onKeyDown={(e) => e.key === 'Enter' && handleSave()}
                                onChange={(value) => {
                                    setSearch(value);
                                    if (value) {
                                        fetchUsernames(value);
                                    }
                                }}
                                style={{
                                    width: '100%'
                                }}
                            />
                            <ListContainer>
                                {
                                    props?.platform === PlatformIdentifier.TWITTER ?
                                        loading ?
                                            <BarLoader
                                                color={'blue'}
                                                loading={loading}
                                                cssOverride={override}
                                                size={150}
                                            /> :
                                            tagListing : <i>Hit the enter key to add username to instagram</i>
                                }
                            </ListContainer>

                            {/* <TaggedPeople>
                                User Tagged: {pickedUsernames?.toString()}
                            </TaggedPeople> */}
                            <SubmitArea>
                                <Button
                                    variant='outline-primary'
                                    className="rounded-pill me-1 mb-1"
                                    style={{
                                        minWidth: 40,
                                        height: 35
                                    }}
                                    onClick={() => handleCancel()}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: 5,
                                            fontSize: 14
                                        }}>
                                        <FontAwesomeIcon
                                            icon={faSave}
                                        />
                                        <span>Close</span>
                                    </div>
                                </Button>
                                <Button
                                    variant='outline-primary'
                                    className="rounded-pill me-1 mb-1"
                                    disabled={loading || !search}
                                    style={{
                                        minWidth: 40,
                                        height: 35
                                    }}
                                    onClick={() => handleSave()}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: 5,
                                            fontSize: 14
                                        }}>
                                        <FontAwesomeIcon
                                            icon={faSave}
                                        />
                                        <span>Submit</span>
                                    </div>
                                </Button>
                            </SubmitArea>
                        </InputContainer>
                    </MainArena>
                </Card.Body>
            </Card>
        </>
    )
}

export default TaggingPop