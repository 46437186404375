import APIService from 'http/api_service';
import React, { useEffect, useState } from 'react';
import { ProgressBar, Spinner } from 'react-bootstrap';
import { BsImage } from 'react-icons/bs';
import { FaFolder } from 'react-icons/fa';
import { IoDocumentText } from 'react-icons/io5';
import { MdMovie } from 'react-icons/md';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getPublishablePostMedia, setPublishablePostMedia } from 'redux/slices/post_media_slice';
import { getPostlyCloudGoogleDriveUploadIsActive, setGoogleDriveManager, setShowMediaOptions } from 'redux/slices/postslice';
import { getLoggedInUser } from 'redux/slices/user_slice';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { RoutePaths } from '../../../constants';
import ConfirmModal from '../ConfirmModal';
import { EachFolderBox, FolderContainer, FolderName, LoadingOverlay } from './styles/folders';

const Folders = (props) => {

   const { googleDriveManager, setIdCallStacks, loading, setLoading, platform, currentView, cameFromGDrivePicker } = props;
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { folder_id } = useParams();
   const workSpace = useSelector(getActiveWorkSpace);
   const postlyCloudGoogleDriveUploadIsActive = useSelector(getPostlyCloudGoogleDriveUploadIsActive)
   const workSpaceId = workSpace._id;
   const [currentId, setCurrentId] = useState(null);
   const mediaObjects = useSelector(getPublishablePostMedia);
   const [progress, setProgress] = useState(0);
   const [showProgress, setShowProgress] = useState(false);
   const [totalBytes, setTotalBytes] = useState(0);
   const [fetchController, setFetchController] = useState(null);
   const [stopFilefetching, setStopFilefetching] = useState(false);
   const [openConfirmation, setOpenConfirmation] = useState(false);
   const [promptGetter, setPromptGetter] = useState(null);
   const [selectedItems, setSelectedItems] = useState({
      id: null,
      log: null,
      folderType: null,
      folderName: null,
      isVideo: null
   });
   const [loadingMore, setLoadingMore] = useState(false);
   const loggedInUser = useSelector(getLoggedInUser);

   const getMedias = async (folderId, nextPageToken) => {
      setCurrentId(folderId);
      if (!nextPageToken) {
         setIdCallStacks(prev => [...prev, folderId]);
      }
      let requestBody = {
         google_drive_access_token: loggedInUser['google_drive_access_token'],
         google_drive_refresh_token: loggedInUser['google_drive_refresh_token'],
         google_drive_access_token_expires_at: loggedInUser['google_drive_access_token_expires_at'],
         q: `'${folderId}' in parents and trashed=false`
      }
      if (nextPageToken) {
         requestBody['nextPageToken'] = googleDriveManager.folderNextPageToken;
      }

      if (!nextPageToken) {
         setLoading(true);
      } else {
         setLoadingMore(true);
      }
      APIService.getGoogleDriveMedias(workSpaceId, requestBody, (response, error) => {
         if (error) {
            toast.error(error, { theme: 'colored' });
            if (!nextPageToken) {
               setLoading(false);
            } else {
               setLoadingMore(false);
            }
            return;
         }

         if (nextPageToken) {
            let files = [...googleDriveManager.folders, ...response?.data?.files];
            dispatch(setGoogleDriveManager({
               ...googleDriveManager, openPicker: props.pickerChecker ? false : true, folders: files, folderNextPageToken: response?.data?.nextPageToken
            }));
            if (!nextPageToken) {
               setLoading(false);
            } else {
               setLoadingMore(false);
            }
            return;
         }
         dispatch(setGoogleDriveManager({
            ...googleDriveManager, openPicker: props.pickerChecker ? false : true, folders: response?.data?.files, folderNextPageToken: response?.data?.nextPageToken
         }));
         if (!nextPageToken) {
            setLoading(false);
         } else {
            setLoadingMore(false);
         }
      });
   }

   const handleNavigation = (folderId, useNextPageToken) => {
      if (!useNextPageToken) {
         navigate(`${RoutePaths.GDRIVE_CONTENTS}/${folderId}`)
         return
      }
   }

   useEffect(() => {
      if (folder_id) {
         getMedias(folder_id);
      }
   }, [folder_id])


   const convertBlobToFile = (blob, fileName) => {
      return new File(
         [blob],
         fileName,
         {
            type: blob.type,
            lastModified: new Date().getTime()
         }
      )
   }

   useEffect(() => {
      if (stopFilefetching) {
         fetchController.abort();
         setShowProgress(false);
         setProgress(0);
         setStopFilefetching(false);
      }
   }, [stopFilefetching])

   const handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom && googleDriveManager.folderNextPageToken && !loading) {
         getMedias(currentId, true);
      }
   }

   const downloadFile = async (fileId, key, type, fileName, is_video) => {
      const _fetchController = new AbortController();
      if (!showProgress) {
         setShowProgress(true);
      }
      const response = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
         method: 'GET',
         signal: _fetchController.signal,
         headers: {
            'Authorization': `Bearer ${key}`
         }
      })
      setFetchController(_fetchController);

      const contentLength = response.headers.get('content-length');
      if (!showProgress) {
         setShowProgress(true);
      }
      const total = parseInt(contentLength, 10);
      setTotalBytes(total);
      let loaded = 0;
      const res = new Response(new ReadableStream({
         async start(controller) {
            const reader = response.body.getReader();
            for (; ;) {
               const { done, value } = await reader.read();
               if (done) break;
               loaded += value.byteLength;
               let newLoaded = (loaded / total * 100).toFixed(2)
               setProgress(newLoaded);
               controller.enqueue(value);
            }

            controller.close();
         },
      }));
      const buffer = await res.arrayBuffer();
      setShowProgress(false);
      let newBlob = new Blob([buffer], { type: type });
      const blobUrl = URL.createObjectURL(newBlob);
      const convertedFIle = convertBlobToFile(newBlob, fileName);
      if (postlyCloudGoogleDriveUploadIsActive) {
         dispatch(setGoogleDriveManager({ ...googleDriveManager, openPicker: false, docs: convertedFIle, aboutUploadingToPostlyCloudFromGoogleDrive: true }))
         dispatch(setShowMediaOptions(false));
         navigate(RoutePaths.POSTLY_CLOUD)
         return
      }
      let newMediaObject = {
         name: fileName,
         url: blobUrl,
         is_video: is_video,
         file: convertedFIle
      }
      if (!platform) {
         dispatch(setPublishablePostMedia([...mediaObjects, newMediaObject]))
         dispatch(setGoogleDriveManager({ ...googleDriveManager, openPicker: false }))
         dispatch(setShowMediaOptions(false))
         navigate(RoutePaths.POSTS)
         return;
      }
      let newPlatformMediaObject = {
         external: false,
         file: convertedFIle,
         is_video: is_video,
         name: fileName,
         optimized: false,
         uploaded: false,
         url: blobUrl,
      }
      try {
         localStorage.setItem('gdrivePlatform', platform);
         dispatch(setGoogleDriveManager({ ...googleDriveManager, docs: newPlatformMediaObject, openPicker: false }))
         dispatch(setShowMediaOptions(false))
      } catch (error) {
         console.log(error, "as error")
      }
   }

   const displayFolders = googleDriveManager?.folders?.map((folder) => {
      let fileType = googleDriveManager?.fileType
      let icon;
      let is_video;
      if (folder?.mimeType?.includes('image')) {
         is_video = false;
         icon = <img src={folder?.iconLink} alt="" height={currentView === 'Grid View' ? 50 : 40} width={currentView === 'Grid View' ? 50 : 40} /> ?? <BsImage size={50} />
      }
      else if (folder?.mimeType?.includes('video')) {
         is_video = true;
         icon = <img src={folder?.iconLink} alt="" height={currentView === 'Grid View' ? 50 : 40} width={currentView === 'Grid View' ? 50 : 40} /> ?? <MdMovie size={50} />
      }
      else {
         icon = <img src={folder?.iconLink} alt="" height={currentView === 'Grid View' ? 50 : 40} width={currentView === 'Grid View' ? 50 : 40} /> ?? <IoDocumentText size={50} />
      }

      if (folder?.fileExtension
         // && (cameFromGDrivePicker && folder?.mimeType?.includes(fileType))
      ) {
         if (cameFromGDrivePicker) {
            if (folder?.mimeType?.includes(fileType)) {
               return (
                  <>
                     <EachFolderBox
                        minHeight={currentView === "Grid View" ? '120px' : '40px'}
                        maxWidth={currentView === "Grid View" ? '150px' : 'unset'}
                        flexDirection={currentView === "Grid View" ? 'column' : 'row'}
                        gap={currentView === "Grid View" ? '0px' : '10px'}
                        justifyContent={currentView === "Grid View" ? 'center' : 'flex-start'}
                        onClick={() => {
                           console.log("🚀 ~ file: folders.js:278 ~ displayFolders ~ localStorage.getItem('showPromptHandler'):", localStorage.getItem('dontshowPromptHandler'))
                           if (localStorage.getItem('dontshowPromptHandler')) {
                              downloadFile(folder?.id, loggedInUser['google_drive_access_token'], folder?.mimeType, folder?.name, is_video)
                              return;
                           } else {
                              setSelectedItems({ ...selectedItems, id: folder?.id, log: loggedInUser['google_drive_access_token'], folderType: folder?.mimeType, folderName: folder?.name, isVideo: is_video })
                              setOpenConfirmation(true)
                           }
                        }}
                     >
                        {
                           folder?.thumbnailLink ?
                              <img
                                 src={folder?.thumbnailLink}
                                 alt=""
                                 referrerPolicy="no-referrer"
                                 style={{ maxHeight: 70, maxWidth: 70 }}
                              /> : icon
                        }

                        <FolderName>{
                           currentView === 'Grid View' ? folder?.name.split('', 15).reduce((o, c) => o.length === 9 ? `${o}${c}...` : `${o}${c}`, '') : folder?.name
                        }</FolderName>
                     </EachFolderBox>
                  </>
               )
            }
         } else {
            return (
               <>
                  <EachFolderBox
                     minHeight={currentView === "Grid View" ? '120px' : '40px'}
                     maxWidth={currentView === "Grid View" ? '150px' : 'unset'}
                     flexDirection={currentView === "Grid View" ? 'column' : 'row'}
                     gap={currentView === "Grid View" ? '0px' : '10px'}
                     justifyContent={currentView === "Grid View" ? 'center' : 'flex-start'}
                     onClick={() => {
                        console.log("🚀 ~ file: folders.js:278 ~ displayFolders ~ localStorage.getItem('showPromptHandler'):", localStorage.getItem('dontshowPromptHandler'))
                        if (localStorage.getItem('dontshowPromptHandler')) {
                           downloadFile(folder?.id, loggedInUser['google_drive_access_token'], folder?.mimeType, folder?.name, is_video)
                           return;
                        } else {
                           setSelectedItems({ ...selectedItems, id: folder?.id, log: loggedInUser['google_drive_access_token'], folderType: folder?.mimeType, folderName: folder?.name, isVideo: is_video })
                           setOpenConfirmation(true)
                        }
                     }}
                  >
                     {
                        folder?.thumbnailLink ?
                           <img
                              src={folder?.thumbnailLink}
                              alt=""
                              referrerPolicy="no-referrer"
                              style={{ maxHeight: 70, maxWidth: 70 }}
                           /> : icon
                     }

                     <FolderName>{
                        currentView === 'Grid View' ? folder?.name.split('', 15).reduce((o, c) => o.length === 9 ? `${o}${c}...` : `${o}${c}`, '') : folder?.name
                     }</FolderName>
                  </EachFolderBox>
               </>
            )
         }

      }
      if (folder?.mimeType?.includes('folder')) {
         return (
            <EachFolderBox
               minHeight={currentView === "Grid View" ? '120px' : '40px'}
               maxWidth={currentView === "Grid View" ? '150px' : 'unset'}
               flexDirection={currentView === "Grid View" ? 'column' : 'row'}
               gap={currentView === "Grid View" ? '0px' : '10px'}
               justifyContent={currentView === "Grid View" ? 'center' : 'flex-start'}
               onClick={() => handleNavigation(folder?.id)}
            >
               <FaFolder size={currentView === 'Grid View' ? 50 : 40} />
               <FolderName>{
                  currentView === 'Grid View' ? folder?.name.split('', 15).reduce((o, c) => o.length === 9 ? `${o}${c}...` : `${o}${c}`, '') : folder?.name
               }</FolderName>
            </EachFolderBox>
         )
      }
   })

   const handleStopDownload = () => {
      setStopFilefetching(true);
   }

   return (
      <>
         {
            showProgress && <LoadingOverlay>
               <div style={{
                  backgroundColor: '#ffffff',
                  position: 'absolute',
                  zIndex: 10,
                  top: '50%',
                  width: "40%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: 'column',
                  padding: "0.5em 1em",
                  transform: "translate(35px, 10px)"
               }}>
                  <div style={{ fontSize: '13px', marginBottom: '10px', position: 'relative', width: '100%', color: 'black' }}>
                     <span style={{ marginRight: '10px' }}>{`${'Fetching File'}`}</span>
                     {(progress === 0 || isNaN(progress) || progress == null) ?
                        <Spinner animation="border" size="sm" variant="success" /> :
                        <span>{`${progress}%`}</span>}
                     <div onClick={handleStopDownload} style={{ position: 'absolute', top: 0, right: 0, cursor: 'pointer' }}>
                        Cancel
                     </div>
                  </div>
                  <ProgressBar
                     style={{
                        width: '100%'
                     }}
                     striped
                     variant="success"
                     now={`${progress || totalBytes}`}
                  />
               </div>

            </LoadingOverlay>

         }
         <FolderContainer onScroll={handleScroll}>
            {
               loading ? <ReactLoading type={'bubbles'} color={'#0052cc'} /> :
                  displayFolders
            }
            {
               loadingMore && <ReactLoading type={'bubbles'} color={'#0052cc'} />
            }
         </FolderContainer>
         <ConfirmModal

            open={openConfirmation}
            title={"Confirm Items"}
            message={'Do you still wish to proceed?'}
            confirmText={"PROCEED"}
            cancelText={"CANCEL"}
            showDialoguePrompt
            setPromptGetter={setPromptGetter}
            onCancel={() => {
               setOpenConfirmation(false);
            }}
            onConfirm={() => {
               localStorage.setItem('dontshowPromptHandler', promptGetter)
               downloadFile(selectedItems?.id, selectedItems?.log, selectedItems?.folderType, selectedItems?.folderName, selectedItems?.isVideo)
               setOpenConfirmation(false);
            }}
         />
      </>
   )
}

export default Folders