import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

const EditableSection = React.forwardRef((props, ref) => {

    const {
        title,
        placeholder,
        onChangeHandler,
        onChange,
        style,
        resetValue,
        onKeyDown,
        type = 'text'
    } = props;

    const [value, setValue] = useState(props.value);

    useEffect(() => {
        if (resetValue) {
            setValue('')
        }
    }, [resetValue])


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (onChangeHandler) {
                onChangeHandler(value);
            }
            if (onChange) {
                onChange(value);
            }
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [value])

    return (
        <div
            ref={ref}
            style={{
                display: 'flex',
                flexDirection: 'column',
                ...style
            }}>
            <Form.Group>
                {title && <Form.Label>{title}</Form.Label>}
                <Form.Control
                    type={type}
                    as={type === 'textarea' ? 'textarea' : 'input'}
                    onKeyDown={onKeyDown}
                    value={value}
                    placeholder={placeholder}
                    onChange={e => {
                        setValue(e.target.value);
                    }}
                />
            </Form.Group>
        </div>
    );
});

export default EditableSection;