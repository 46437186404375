import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import settings from 'assets/img/icons/spot-illustrations/settings.png';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import AppContext from 'context/Context';
import React, { useContext } from 'react';
import { Badge, Button, Card, Offcanvas } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getSideNotificationPanel, getSideNotifications, setSideNotificationPanel } from 'redux/slices/notification_slice';
import { markAllSideNotificationsAsRead, markSingleSideNotificationAsRead } from 'utils/notification';
import { displayPlatformIcon } from 'utils/platform_utils';

const SideNotificationPanel = () => {
    const dispatch = useDispatch();
    const notificationPanel = useSelector(getSideNotificationPanel);
    const sideNotifications = useSelector(getSideNotifications);

    const parseNotificationType = (type) => {
        switch (type) {
            case 'info':
                return {
                    type: `primary`,
                    display: `info`
                };
            case 'warning':
                return {
                    type: `warning`,
                    display: `critical`
                }
            case 'danger':
                return {
                    type: `danger`,
                    display: `very critical`
                }
            case 'success':
                return {
                    type: `success`,
                    display: `success`
                };
            default:
                return {
                    type: `secondary`,
                    display: `secondary`
                };
        }
    }

    const displaySideNotifications = sideNotifications.map((notification, index) => {
        if (notification?.read) return null;
        return <Card
            className='mb-2'
        >
            <Flex
                justifyContent={'between'}
                className={'p-2 mb-0'}
            >
                {
                    notification?.platform && displayPlatformIcon(notification?.platform)
                }
                <Flex>
                    <SoftBadge pill bg={parseNotificationType(notification?.type)?.type}>
                        {parseNotificationType(notification?.type)?.display}
                    </SoftBadge>
                    <FontAwesomeIcon icon="times" className="ms-2 fs--1" onClick={() => {
                        markSingleSideNotificationAsRead(notification);
                    }
                    } />
                </Flex>
            </Flex>
            <Card.Body className="p-2">
                <h6 className="mb-1">
                    {notification?.header}
                </h6>
                <p className="fs--1 mb-0">
                    {notification?.message}
                </p>
            </Card.Body>
        </Card>
    });

    return (
        <Offcanvas
            show={notificationPanel}
            onHide={() => {
                dispatch(setSideNotificationPanel(false));
            }}
            placement="end"
            style={{ maxWidth: '22rem' }}
            className="border-0"
        >
            <Offcanvas.Header
                closeButton
                closeVariant="white"
                className="bg-shape settings-panel-header"
            >
                <Offcanvas.Title as="div" className="py-1 z-index-1 light">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                        <h5 className="text-white">
                            <FontAwesomeIcon icon="palette" className="me-2 fs-0" />
                            Updates
                        </h5>
                        <Button
                            variant="primary"
                            size="sm"
                            className="rounded-pill mt-0 mb-0"
                            style={{ fontSize: '12px' }}
                            onClick={() => {
                                markAllSideNotificationsAsRead()
                                dispatch(setSideNotificationPanel(false));
                            }}
                        >
                            <FontAwesomeIcon
                                icon="times"
                                style={{ fontSize: '10px' }}
                                className="me-1"
                            />
                            Clear all
                        </Button>
                    </div>
                    <p className="mb-0 fs--1 text-white opacity-75">
                        Quick updates for you shows up here.
                    </p>
                </Offcanvas.Title>
            </Offcanvas.Header>
            {/* <ScrollBarCustom> */}
            <Offcanvas.Body className="scrollbar">
                {displaySideNotifications}
            </Offcanvas.Body>
            {/* </ScrollBarCustom> */}
        </Offcanvas>
    );
};

export default SideNotificationPanel;
