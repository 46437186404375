export default class EndPoints {
    static API_VERSION = 'v1';
    static ROOT_DOMAIN = 'postly.ai';
    static SOCIAL_INBOX_BASE = `https://social-inbox.${this.ROOT_DOMAIN}`;
    // static SOCIAL_INBOX_BASE = `http://localhost:5000`;
    static IMAGE_EDITOR_BASE = `https://image-editor.${this.ROOT_DOMAIN}`;
    static LINK_PREVIEWER_BASE = `https://link-previewer.${this.ROOT_DOMAIN}`;
    static LINK_SHORTENER = `https://postly.click`;
    // static LINK_SHORTENER = `http://localhost:8080`;
    static OPEN_AI_API = `https://api.openai.com/v1/chat/completions`

    // BASE ROOT
    static LOCAL_BASE_ROOT = `http://localhost:8080`;
    static LOCAL_BASE = `${this.LOCAL_BASE_ROOT}/${this.API_VERSION}`;
    static LOCAL_BASE_V2 = `${this.LOCAL_BASE_ROOT}/v2`;

    static DEV_BASE_ROOT = `https://app.postly.ai/api`;
    static DEV_BASE = `${this.DEV_BASE_ROOT}/${this.API_VERSION}`;
    static DEV_BASE_V2 = `${this.DEV_BASE_ROOT}/v2`;

    static PRODUCTION_SOCKET_BASE = '/'
    static PRODUCTION_BASE_ROOT = `/api`
    static PRODUCTION_BASE = `${this.PRODUCTION_BASE_ROOT}/v1`;
    static PRODUCTION_BASE_V2 = `${this.PRODUCTION_BASE_ROOT}/v2`;

    static LEGACY_PRODUCTION_SOCKET_BASE = `https://api.${this.ROOT_DOMAIN}`
    static LEGACY_PRODUCTION_BASE_ROOT = `https://api.${this.ROOT_DOMAIN}`
    static LEGACY_PRODUCTION_BASE = `${this.LEGACY_PRODUCTION_BASE_ROOT}/${this.API_VERSION}`;
    static LEGACY_PRODUCTION_BASE_V2 = `${this.LEGACY_PRODUCTION_BASE_ROOT}/v2`;

    static BASE = this.LEGACY_PRODUCTION_BASE;
    static BASE_V2 = this.LEGACY_PRODUCTION_BASE_V2;
    static SOCKET_BASE = this.LEGACY_PRODUCTION_SOCKET_BASE;
    
    // User Authentication
    static CSRF_TOKEN = `${this.BASE}/accounts/csrf_token`;
    static SIGN_UP = `${this.BASE}/accounts/sign_up`;
    static UPDATE_PERSONAL_INFO = `${this.BASE}/accounts/update_account_personal_info`;
    static CHECK_IF_EMAIL_IS_VERIFIED = `${this.BASE}/accounts/check_if_email_is_verified`;
    static REQUEST_CODE_FOR_EMAIL_VERIFICATION = `${this.BASE}/accounts/request_code_for_email_verification`;
    static CONFIRM_CODE_FOR_EMAIL_VERIFICATION = `${this.BASE}/accounts/confirm_code_for_email_verification`;
    static UPDATE_TITLE_IN_WORKSPACE = `${this.BASE}/workspaces/update_title_in_workspace`;
    static UPDATE_ACCOUNT_PASSWORD = `${this.BASE}/accounts/update_account_password`;
    static UPDATE_ACCOUNT_DETAILS = `${this.BASE}/accounts/update_account_details`;
    static UPSERT_CUSTOM_EVENT_DAY = `${this.BASE}/workspaces/:workspace_id/upsert_custom_event`;
    static DELETE_CUSTOM_EVENT = `${this.BASE}/workspaces/:workspace_id/custom_events/:eventId`;
    static SIGN_IN = `${this.BASE}/accounts/login`;
    static LOG_OUT = `${this.BASE}/accounts/logout`;
    static DELETE_ACCOUNT = `${this.BASE}/accounts/delete_account`;
    static ME = `${this.BASE}/accounts/me`;
    static UPDATE_LAST_QUERY = `${this.BASE}/accounts/update_last_query`;
    static UPDATE_USER_QUICK_RESPONSE = `${this.BASE}/accounts/update_quick_response`;
    static MY_FINGER_PRINT = `${this.BASE}/accounts/my_meta_data`;
    static INITIATE_PASSWORD_RESET = `${this.BASE}/accounts/initiate_pwd_reset`;
    static FINALIZE_PASSWORD_RESET = `${this.BASE}/accounts/reset_pwd`;
    static TIMED_PAYMENT_EVENT = `${this.BASE}/events/timed_payment_event`;
    static CONFIRM_PASSWORD_LESS_CONFIRMATION_CODE = `${this.BASE}/accounts/confirm_passwordless_confirmation_code`;
    static USER_WORKSPACES = `${this.BASE}/workspaces/user_workspaces`;

    // Workspace
    static CHECK_FOR_COMPLIMENTARY_PLAN_GIFTING = `${this.BASE}/workspaces/:workSpaceId/check_for_complimentary_plan_gifting`;
    static GET_USER_PLATFORM_COUNT_STATEMENT = `${this.BASE}/workspaces/:workSpaceId/get_platform_count_statement`;
    static DOES_USER_HAVE_WORKSPACES = `${this.BASE}/workspaces/does_user_have_workspaces`;
    static NEW_WORKSPACE = `${this.BASE}/workspaces/new_workspace`;
    static UPDATE_WORKSPACE_NAME = `${this.BASE}/workspaces/:workSpaceId/update_name`;
    static BULK_UPDATE_WORKSPACE_NAMES = `${this.BASE}/workspaces/bulk_workspace_names_update`;
    static UPDATE_WORKSPACE_THEME = `${this.BASE}/workspaces/:workSpaceId/update_theme`;
    static UPDATE_WORKSPACE_LOGO = `${this.BASE}/workspaces/:workSpaceId/update_logo`;
    static UPDATE_WORKSPACE_SETTINGS = `${this.BASE}/workspaces/:workSpaceId/update_settings`;
    static DELETE_WORKSPACE = `${this.BASE}/workspaces/:workSpaceId/delete`;
    static UPDATE_WORKSPACE_CONTENT_PLANNER_SETTINGS = `${this.BASE}/workspaces/:workSpaceId/content_planner_settings`;
    static UPDATE_WORKSPACE_LANGUAGE_AND_REGION_SETTINGS = `${this.BASE}/workspaces/:workSpaceId/language_and_region_settings`;
    static UPDATE_WORKSPACE_MORE_OPTIONS_SETTINGS = `${this.BASE}/workspaces/:workSpaceId/more_options_settings`;
    static APPLY_TO_ALL_WORKSPACES_SETTINGS = `${this.BASE}/workspaces/:workSpaceId/apply_to_all_workspaces_settings`;
    static ARCHIVE_WORKSPACES = `${this.BASE}/workspaces/archive_workspaces`;
    static DELETE_WORKSPACES = `${this.BASE}/workspaces/delete_workspaces`;
    static CREATE_BULK_WORKSPACES = `${this.BASE}/workspaces/create_bulk_workspaces`;
    static WORKSPACE = `${this.BASE}/workspaces`;
    static ORGANIZATIONS = `${this.BASE}/organizations`;
    static MY_ORGANIZATIONS = `${this.ORGANIZATIONS}/mine`;
    static UPSERT_ORGANIZATION_WITH_WORKSPACE = `${this.ORGANIZATIONS}/upsert_with_workspace`
    static UPDATE_ORGANIZATION = `${this.ORGANIZATIONS}/:id/update`
    static MOVE_WORKSPACES_TO_ORGANIZATION = `${this.ORGANIZATIONS}/:id/move_workspaces`
    static DELETE_ORGANIZATION = `${this.ORGANIZATIONS}/:id/delete`
    static ORGANIZATION_MEMBERS = `${this.ORGANIZATIONS}/:id/members`
    static ALL_POSTS = `${this.BASE}/workspaces/:workSpaceId/all_posts`;
    static DRAFT_POST = `${this.BASE}/posts/:workSpaceId/draft_post`;
    static USER_DRAFT = `${this.BASE}/posts/:workSpaceId/user_draft`;
    static USER_AVATAR = `${this.BASE}/accounts/update_user_avatar`;
    static ACCOUNT_LOGO = `${this.BASE}/accounts/update_account_logo`;
    static UPDATE_LAST_VIEWED_WORKSPACE = `${this.BASE}/accounts/update_last_viewed_workspace`;
    static ALL_USER_DRAFTS = `${this.BASE}/posts/:workSpaceId/all_user_drafts`;
    static DRAFTED_POSTS = `${this.BASE}/workspaces/:workSpaceId/drafted_posts`;
    static POST = `${this.BASE}/posts/:workSpaceId/:post_id`;
    static POST_TIME = `${this.BASE}/posts/:workSpaceId/:post_id/update_time`;
    static UPDATE_POST = `${this.BASE}/posts/:workSpaceId/:post_id/update`;
    static UPDATE_DRAFT_CONTENT = `${this.BASE}/drafts/:workSpaceId/update_draft_content`;
    static FETCH_ALL_WORKSPACE_DRAFT_CONTENT = `${this.BASE}/drafts/:workSpaceId/fetch_all_workspace_draft_contents`;
    static DELETE_DRAFTED_POST = `${this.BASE}/drafts/:workSpaceId/delete_draft_content/:id`;
    static RECURRING_POST = `${this.BASE}/posts/:workSpaceId/recurring_posts/:post_group_identifier`;
    static SCHEDULED_POSTS = `${this.BASE}/posts/:workSpaceId/scheduled_posts`;
    static PUBLISHED_POSTS = `${this.BASE}/posts/:workSpaceId/published_posts`;
    static POPULAR_POSTS = `${this.BASE}/posts/:workSpaceId/popular_posts`;
    static ACTIVITIES = `${this.BASE}/workspaces/:workSpaceId/activities`;
    static UPSERT_AUDIENCE_GROUP = `${this.BASE}/workspaces/:workSpaceId/upsert_audience_group`;
    static AUDIENCE_GROUP_COUNT = `${this.BASE}/workspaces/:workSpaceId/count_audience_groups`;
    static AUDIENCE_GROUP_FETCH = `${this.BASE}/workspaces/:workSpaceId/fetch_audience_groups`;
    static DELETE_AUDIENCE_GROUP = `${this.BASE}/workspaces/:workSpaceId/delete_audience_group/:group_id`;
    static UPDATE_AUDIENCE_GROUP = `${this.BASE}/workspaces/:workSpaceId/update_audience_group/:group_id`;
    static TEAM = `${this.BASE}/workspaces/:workspace_id/team`;
    static PENDING_TEAM_INVITES = `${this.BASE}/workspaces/:workspace_id/pending_team_invites`;
    static CANCEL_PENDING_TEAM_INVITE = `${this.BASE}/workspaces/:workspace_id/pending_team_invites/:token`;
    static INVITE_TEAM_MEMBER = `${this.BASE}/workspaces/:workspace_id/invite_team_member`;
    static UPDATE_MEMBER_ROLE = `${this.BASE}/workspaces/:workspace_id/update_member_role`;
    static ACCEPT_TEAM_INVITE = `${this.BASE}/workspaces/accept_invite`;
    static REMOVE_TEAM_MEMBER = `${this.BASE}/workspaces/:workspace_id/remove_team_member`;
    static MY_WORKSPACE_ROLE = `${this.BASE}/workspaces/:workspace_id/my_role`;
    static WORKSPACE_OWNER = `${this.BASE}/workspaces/:workspace_id/owner`;
    static WORKSPACE_PLATFORMS = `${this.BASE}/workspaces/:workSpaceId/platforms`;
    static CONNECTED_ACCOUNTS = `${this.BASE}/workspaces/:workSpaceId/platforms/:identifier/accounts`;
    static CONNECTED_PLATFORMS_COUNT = `${this.BASE}/workspaces/:workspace_id/connected_platforms_count`;
    static ADD_NEW_WORKSPACE_PLATFORM = `${this.BASE}/workspaces/:workSpaceId/add_new_platform`;
    static REMOVE_WORKSPACE_PLATFORM = `${this.BASE}/workspaces/:workSpaceId/platforms/:platform_identifier/:user_id/remove`;
    static SUBREDDIT_FLAIRS = `${this.BASE}/workspaces/:workSpaceId/subreddit_flair/:user_id/:subreddit`;
    static REMOVE_CORRUPTED_PLATFORMS = `${this.BASE}/workspaces/:workSpaceId/remove_corrupted_platforms`;
    static PLATFORMS_PAGES_AND_GROUPS = `${this.BASE}/workspaces/:workSpaceId/platforms/:platform_identifier/:user_id/pages_and_groups`;
    static CONNECT_PAGE_OR_GROUP = `${this.BASE}/workspaces/:workSpaceId/platforms/:platform_identifier/pages_and_groups/:page_or_group_id/:user_id/connect`;
    static CONNECT_MULTIPLE_PAGE_OR_GROUP = `${this.BASE}/workspaces/:workSpaceId/connect_multiple_pages_and_groups`;
    static DISCONNECT_MULTIPLE_PAGE_OR_GROUP = `${this.BASE}/workspaces/:workSpaceId/disconnect_multiple_pages_and_groups`;

    static CONNECT_PAGE_OR_ACCOUNT_FOR_MESSAGING = `${this.BASE}/workspaces/:workSpaceId/platforms/:platform_identifier/pages_and_accounts/:page_or_account_id/:user_id/connect_for_messaging`;
    static DISCONNECT_PAGE_OR_GROUP = `${this.BASE}/workspaces/:workSpaceId/platforms/:platform_identifier/pages_and_groups/:page_or_group_id/:target/:user_id/disconnect`;
    static DISCONNECT_PAGE_OR_ACCOUNT_FROM_MESSAGING = `${this.BASE}/workspaces/:workSpaceId/platforms/:platform_identifier/pages_and_accounts/:page_or_account_id/:target/:user_id/disconnect_from_messaging`;
    static POST_TO_WORKSPACE = `${this.BASE}/posts/:workSpaceId/new_post`;
    static CREATE_NEW_PINTEREST_BOARD = `${this.BASE}/workspaces/:workSpaceId/new_pinterest_board`;
    static CREATE_NEW_PINTEREST_BOARD_SECTION = `${this.BASE}/workspaces/:workSpaceId/new_pinterest_board_section`;
    static REDEEM_VOUCHER = `${this.BASE}/ltds/credit/redeem_voucher`;
    static FETCH_VOURCHERS_BY_ACCOUNT = `${this.BASE}/ltds/account/vouchers`;
    static BILL_USER = `${this.BASE}/billing/create-checkout-session`;
    static CANCEL_SUBSCRIPTION = `${this.BASE}/billing/cancel_subscription`;
    static CANCEL_AI_SUBSCRIPTION = `${this.BASE}/billing/cancel_ai_subscription`;
    static UPCOMING_POPULAR_EVENTS = `${this.BASE}/recommendations/upcoming_popular_events/:workspace_id`;
    static USER_NOTIFICATIONS = `${this.BASE}/notifications`;
    static READ_NOTIFICATION = `${this.BASE}/notifications/:id/update_read_status`;
    static CLEAR_ALL_NOTIFICATIONS = `${this.BASE}/notifications/clear_all`;
    static WORKSPACE_DETAILS_FROM_INVITE_TOKEN = `${this.BASE}/workspaces/workspace_details_from/:invite_token`;
    static APPROVE_POST = `${this.BASE}/posts/:workSpaceId/:post_id/approve_post`;
    static DISAPPROVE_POST = `${this.BASE}/posts/:workSpaceId/:post_id/disapprove_post`;
    static GET_AVAILABLE_TRENDS = `${this.BASE}/recommendations/trends_available_for/:workspace_id`;
    static BULK_CONTENT_UPLOAD = `${this.BASE}/posts/:workSpaceId/bulk_content_upload`;
    static VERIFY_NEW_EMAIL_ADDRESS = `${this.BASE}/accounts/verify_email`;
    static SUPPORTED_COUNTRIES = `${this.BASE}/recommendations/supported_countries_for/:workspace_id`
    static ADD_NEW_DAYS_LOCATION = `${this.BASE}/recommendations/add_days_locations_to/:workspace_id`;
    static REMOVE_LOCATION_FROM_WORKSPACE_DAYS = `${this.BASE}/recommendations/remove_location_from_days/:workspace_id/:location`;
    static GENERATE_TEXT = `${this.BASE}/ai_writer/:workSpaceId/generate_text`;
    static GET_AND_CALC_AI_POINT = `${this.BASE}/ai_writer/:workSpaceId/calculate_ai_point`;
    static GET_SAVED_AICONVERSATION = `${this.BASE}/ai_writer/:workSpaceId/saved_conversations`;
    static SAVE_AI_KEY = `${this.BASE}/ai_writer/:workSpaceId/save_api_key`;
    static GENERATE_TEXT_V2 = `${this.BASE_V2}/ai_writer/:workSpaceId/generate_text`
    static TRANSLATE_TEXT = `${this.BASE}/ai_writer/:workSpaceId/translate_text`
    static SAVE_OR_UPDATE_AICONVERSATION = `${this.BASE}/ai_writer/:workSpaceId/save_conversation`;
    static SAVE_PROMPT = `${this.BASE}/ai_writer/:workSpaceId/save_prompt`;
    static GET_PROMPTS = `${this.BASE}/ai_writer/:workSpaceId/get_prompt`;
    static GENERATE_ART = `${this.BASE}/ai_art/:workSpaceId/generate_art`;
    static CHECK_AI_WRITER_USAGE_RESUMPTION = `${this.BASE}/ai_writer/:workSpaceId/check_ai_writer_usage_resumption`;
    static GET_WORKSPACE_OWNER_AI_CREDITS = `${this.BASE}/ai_writer/:workSpaceId/get_workspace_owner_ai_writer_credits`;
    static SAVED_COPIES = `${this.BASE}/ai_writer/:workSpaceId/saved_copies/:tool`;
    static ALL_SAVED_COPIES = `${this.BASE}/ai_writer/:workSpaceId/all_saved_copies`;
    static ALL_SAVED_ART_COPIES = `${this.BASE}/ai_art/:workSpaceId/all_saved_copies`;
    static SAVE_COPY = `${this.BASE}/ai_writer/:workSpaceId/save_copy`;
    static SAVE_ART_COPY = `${this.BASE}/ai_art/:workSpaceId/save_copy`;
    static REMOVE_COPY = `${this.BASE}/ai_writer/:workSpaceId/remove_copy/:copy_id`;
    static REMOVE_ART_COPY = `${this.BASE}/ai_art/:workSpaceId/remove_copy/:copy_id`;
    static WORKSPACE_BOTS = `${this.BASE}/bots/:work_space_id/:platform_user_id`;
    static REQUEST_FOR_CUSTOMIZED_TELEGRAM_BOT = `${this.BASE}/bots/new_customized_bot_request/:workSpaceId`;
    static CANCEL_BOT_REQUEST = `${this.BASE}/bots/cancel_bot_request/:workSpaceId/:botRequestId`;
    static BOT_REQUESTS = `${this.BASE}/bots/bot_requests`;
    static SET_DEFAULT_BOT = `${this.BASE}/bots/set_default_bot/:work_space_id/:platform_user_id`;
    static POST_BATCH = `${this.BASE}/posts/:workSpaceId/post_batch/:batch_id`;
    static TRANSACTION_HISTORY = `${this.BASE}/transactions/transaction_history`;
    static TAGGABLES = `${this.BASE}/tagging/taggables`;
    static UPLOAD_NEW_FILE = `${this.BASE}/files/:workSpaceId/upload_new_file`;
    static TURN_OFF_ALL_POSTS_AND_CAMPAIGNS = `${this.BASE}/workspaces/:workSpaceId/turn_off_all_posts_and_campaigns`;
    static PAUSE_POST = `${this.BASE}/posts/:workSpaceId/:post_id/pause`;
    static DELETE_POST_FROM_ONLY_POSTLY = `${this.BASE}/posts/:workSpaceId/:post_id/delete_from_only_postly`;
    static DELETE_ALL_RECURRING_POSTS_FROM_WORKSPACE = `${this.BASE}/posts/:workSpaceId/delete_all_recurring_posts_from_workspace`;
    static DELETE_POST_FROM_ONLY_PUBLISHED_PLATFORMS = `${this.BASE}/posts/:workSpaceId/:post_id/delete_from_only_published_platforms`;
    static DELETE_ALL_SAVED_COPIES = `${this.BASE}/ai_writer/:workSpaceId/delete_all_saved_copies`;
    static DEFAULT_TIMEZONE_FOR_PUBLISHING = `${this.BASE}/workspaces/:workspace_id/default_time_zone_for_publishing`;
    static YOUTUBE_VIDEO_CATEGORIES = `${this.BASE}/workspaces/:workspace_id/:regionCode/youtube_video_categories`;
    static PREPROCESS_WORKSPACE_TRANSFER = `${this.BASE}/workspaces/:workSpaceId/init_transfer_to`;
    static FINALIZE_WORKSPACE_TRANSFER = `${this.BASE}/workspaces/:workSpaceId/finalize_transfer`;
    static TOGGLE_PANEL = `${this.BASE}/workspaces/:workSpaceId/toggle_panel`;
    static POST_EMAIL_NOTIFICATIONS = `${this.BASE}/workspaces/:workSpaceId/post_email_notifications`;
    static UPDATE_WORKSPACE_TWITTER_API_KEY = `${this.BASE}/workspaces/:workSpaceId/update_twitter_api_key`;
    static COUNT_POSTS = `${this.BASE}/posts/:workSpaceId/:criteria/count`;
    static DELETE_ALL_POSTS = `${this.BASE}/posts/:workSpaceId/delete_posts`;
    static POST_CATEGORIES = `${this.BASE}/posts/:workSpaceId/post_categories`;
    static NEW_POST_CATEGORY = `${this.BASE}/posts/:workSpaceId/new_post_category`;
    static POST_NEW_SCHEDULED_RESOURCE_CLEAN_UP_DATE = `${this.BASE}/accounts/schedule_resources_clean_up`;
    static DELETE_POST_CATEGORY = `${this.BASE}/posts/:workSpaceId/post_categories/:categoryId/delete`;
    static SIMILAR_HASHTAGS = `${this.BASE}/hashtags/similar_hashtags/:workSpaceId`;
    static TAGGING_MENTION = `http://localhost:8000/get_usernames`;
    static GET_GOOGLE_ACCESS_TOKEN = `${this.BASE}/external_image/get_google_drive_access_token`;
    static GET_GOOGLE_DRIVE_MEDIAS = `${this.BASE}/external_image/:workSpaceId/get_google_drive_medias`;
    static DISCONNECT_GOOGLE_DRIVE = `${this.BASE}/external_image/:workSpaceId/disconnect_google_drive`;
    static TAGGABLE_USER_OR_PUBLIC_PAGE = `${this.BASE}/tagging/:workSpaceId/taggable_user_or_public_page`;
    static ADMIN_LOG = `${this.BASE}/admin/login`;
    static ADMIN_GET_ALL_USERS = `${this.BASE}/admin/users`;
    static GET_METRICS_FOR_ADMINS = `${this.BASE}/admin/get_metrics`;
    static UPDATE_PASSWORD_USING_ADMIN_PRIVILEGES = `${this.BASE}/admin/update_account_password_using_admin_privileges`;
    static GET_PEXEL_VIDEOS = `${this.IMAGE_EDITOR_BASE}/get_pexels_videos`;
    static GET_PEXEL_IMAGES = `${this.IMAGE_EDITOR_BASE}/get_pexels_images`;
    static GET_SHUTTERSTOCK_IMAGES = `${this.IMAGE_EDITOR_BASE}/get_shutterstock_images`;
    static UPLOAD_MEDIA_CLOUD = `${this.BASE}/postly_cloud/media_documents/upload/:workSpaceId/:parentFolder`;
    static FETCH_MEDIA_CLOUD = `${this.BASE}/postly_cloud/media_documents/retrieve/:workSpaceId/:parentFolder`;
    static DELETE_MEDIA_CLOUD = `${this.BASE}/postly_cloud/media_documents/delete/:workSpaceId`;
    static CREATE_FOLDER_MEDIA_CLOUD = `${this.BASE}/postly_cloud/folder/:workSpaceId/:parentFolder`;
    static GET_POSTLY_CLOUD_FOLDER_PARENTAL_GENERATIONS = `${this.BASE}/postly_cloud/media_documents/descendants/:parentFolder`;
    static MOVE_DOCS_MEDIA_CLOUD = `${this.BASE}/postly_cloud/media_documents/move/:workSpaceId/:parentFolder`;
    static GET_POSTLY_CLOUD_USAGE = `${this.BASE}/postly_cloud/storage_usage/:workSpaceId`;
    static GET_SHUTTERSTOCK_VIDEOS = `${this.IMAGE_EDITOR_BASE}/get_shutterstock_videos`;
    static REMOVE_IMAGE_BACKGROUND = `${this.IMAGE_EDITOR_BASE}/remove_background`;
    static GET_LINK_PREVIEW = `${this.LINK_PREVIEWER_BASE}/preview_link`;
    static GET_WORDPRESS_CATEGORIES = `${this.BASE}/workspaces/:workSpaceId/wordpress_categories`;
    static PROXY_IMAGE = `${this.BASE}/external_request/proxy_image`;
    static REDIRECT_USER_TO_STRIPE_CONNECT_ACCOUNT_ONBOARDING_LINK = `${this.BASE}/external_request/redirect_user_to_stripe_connect_account_onboarding_link`;
    static ANALYTICS = `${this.BASE}/analytics/:workSpaceId/:platformId/:identifier`;
    static EXPORT_ANALYTICS_REPORT = `${this.BASE}/analytics/report/save`;
    static UPDATE_AUTO_SAVE_DRAFT_STATUS = `${this.BASE}/workspaces/:workSpaceId/change_auto_save_draft_status`;
    static FETCH_AUTO_SAVE_DRAFT_STATUS = `${this.BASE}/workspaces/:workSpaceId/fetch_auto_save_draft_status`;
    static FETCH_RSS_FEED_CONTENT = `${this.BASE}/workspaces/:workSpaceId/:platform_user_id/feeds`;
    static DELETE_RSS_FEED = `${this.BASE}/workspaces/:workSpaceId/:feedIds/delete`;
    static UPSERT_WORKSPACE_RSS_FEED = `${this.BASE}/workspaces/:workSpaceId/upsert_rss_fields`;
    static ADD_NEW_RSS_FEED = `${this.BASE}/workspaces/:workSpaceId/add_new_feed`;
    static POST_RSS_FEED_TO_PLATORM = `${this.BASE}/posts/:workSpaceId/new_post_feed`;
    static UPDATE_FEED_CONTENT = `${this.BASE}/workspaces/:workSpaceId/update_feed_content`;
    static FETCH_ALL_WORKSPACE_CUSTOM_DOMAINS = `${this.BASE}/workspaces/:workSpaceId/custom_domains`
    static UPDATE_WORKSPACE_CUSTOM_DOMAINS = `${this.BASE}/workspaces/:workSpaceId/update_custom_domains`
    static PREPARE_CUSTOM_DOMAIN_FOR_WORKSPACE = `${this.BASE}/workspaces/:workSpaceId/prepare_custom_domain`
    static REMOVE_DOMAIN_FROM_WORKSPACE = `${this.BASE}/workspaces/:workSpaceId/custom_domains/remove/:domainId`
    static PREPARE_CUSTOM_DOMAIN_FOR_EMAIL_SUPPORT = `${this.BASE}/workspaces/:workSpaceId/email_management/domain_setup/add/:domainId`
    static PLATFORM_VIDEO_UPLOAD_LIMIT = `${this.BASE}/workspaces/:workSpaceId/video_management/upload_limit/platforms/all`
    static UPDATE_CUSTOM_EMAIL_USERNAME = `${this.BASE}/workspaces/:workSpaceId/email_management/domain_setup/update_username/:domainId`
    static DESTROY_CUSTOM_DOMAIN_FOR_EMAIL_SUPPORT = `${this.BASE}/workspaces/:workSpaceId/email_management/domain_setup/destroy/:domainId`
    static CONNECT_SELF_HOSTED_WORDPRESS_SITE = `${this.BASE}/workspaces/:workSpaceId/add_self_host_wordpress_site`;
    static POST_LIMITATIONS = `${this.BASE}/workspaces/:workSpaceId/post_limitations`;

    static CREATE_CONNECTED_ACCOUNT = `${this.BASE}/create-connected-account`;
    static CONNECTED_ACCOUNT = `${this.BASE}/get-connected-account`;
    static GET_CONNECTED_ACCOUNT_BALANCE_TRANSACTIONS = `${this.BASE}/get-connected-account-balance-transactions`;
    static GET_CONNECTED_ACCOUNT_PAYOUTS = `${this.BASE}/get-connected-account-payouts`;
    static UPDATE_CONNECTED_ACCOUNT = `${this.BASE}/update-connected-account`;
    static HANDLE_DYNAMIC_PRICE_ITEMS_CHECKOUT = `${this.BASE}/handle_dynamic_checkout`;
    static CURRENCY_CONVERT = 'https://v6.exchangerate-api.com/v6/3ddff5eb35925114fc60795a/latest/USD';
    static GET_CUSTOM_DOMAIN_LOGO = `${this.BASE}/accounts/get_custom_domain_logo/:domain`
    static NEW_METRIC = `${this.BASE}/metric/upsert`
    static ADD_NEW_PRICING_PRODUCT = `${this.BASE}/product/add`
    static FETCH_ALL_PRODUCTS_AND_PRICES = `${this.BASE}/product/list`
    static UPDATE_WHITE_LABEL_PRODUCT = `${this.BASE}/product/update/:id`
    static RETRIEVE_WEBSITE_PRODUCT_PLANS = `${this.BASE}/product/:website/plans`
    static INIT_STRIPE_CONNECT_CHECKOUT_SESSION = `${this.BASE}/product/stripe/init_checkout_session`
    static CLEAR_POST_BACKLOG = `${this.BASE}/admin/clear_post_backlog`
    static FETCH_ALL_INVOICE = `${this.BASE}/invoice/all`

    // static CONNECT_OR_DISCONNECT_PAGE_IN_SOCIAL_INBOX = `${this.SOCIAL_INBOX_BASE}/connect_or_disconnect_account`;
    // static GET_SOCIAL_INBOX_USERS = `${this.SOCIAL_INBOX_BASE}/users`;
    // static SEND_MESSAGE_FROM_SOCIAL_INBOX = `${this.SOCIAL_INBOX_BASE}/:chat_room_id/send_message`;
    // static GET_SOCIAL_INBOX_ROOM_MESSAGES = `${this.SOCIAL_INBOX_BASE}/:user_id/room_messages`;
}