import { faBolt, faCheckCircle, faInfoCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import APIService from 'http/api_service';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Nav, Row, Tab } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
    BeforeInput, BottomRow, EachLayer, EachLayerText,
    HashIcon, HashText,
    HashTextSecond, InputBox, InputContainer,
    ListContainer, ListItem,
    SendContainer,
    SubListItemBottom, SubListItemTop
} from './HashTagPopStyle';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedPlatforms, setShowPreview } from 'redux/slices/postslice';
import AppContext from 'context/Context';
import { darkTheme, lightTheme } from 'utils/theme';
import { findPlatformByIdentifier } from 'components/app/platforms';
import { Icon } from '@iconify/react';
import twitterFill from '@iconify/icons-akar-icons/twitter-fill';
import { PlatformIdentifier } from 'constants';
import { BarLoader } from "react-spinners";
import { formatTrendVolume } from 'utils/gen';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../../../constants';
import Flex from 'components/common/Flex';
import Divider from 'components/common/Divider';
import { setSelectedPlatformForPreview } from 'redux/slices/platformSlice';
import {
    maxUsableCharacters,
    usedAIWriterChars
} from 'components/app/ai-writer/ai_writer_tool';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { setPublishablePostTextBody } from 'redux/slices/post_text_body_slice';

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

const HashTagPop = (props) => {
    const selectedPlatforms = useSelector(getSelectedPlatforms);
    const dropShadow = props.dropShadow;
    const workSpace = useSelector(getActiveWorkSpace);
    const dispatch = useDispatch();
    let [loading, setLoading] = useState(false);
    const { mainWidth = '400px', value, updater, icon, fromTyping, fromPreviews, ...others } = props;
    console.log("🚀 ~ HashTagPop ~ fromPreviews:", fromPreviews)
    const [currentTab, setCurrentTab] = useState(fromPreviews ? 'Trending' : 'AI Writer');
    const [searchText, setSearchText] = useState('SEO');
    const [hashData, setHashData] = useState(null);
    const [sendText, setSendText] = useState('GO')
    const [singlePlatformData, setSinglePlatformData] = useState([])
    const { config } = useContext(AppContext);
    const [workSpaceOwner, setWorkspaceOwner] = useState({});
    const navigate = useNavigate();

    const fetchWorkspaceOwner = () => {
        APIService.fetchWorkSpaceOwner(workSpace._id, (response, _error) => {
            if (response) {
                let { data } = response;
                setWorkspaceOwner(data);
            }
        });
    }

    const getColor = (identifier) => {
        try {
            let platform = findPlatformByIdentifier(identifier);
            return platform.color;
        } catch (error) { }
    }

    const handleCancel = () => {
        setSearchText('');
    }

    const addNewHash = (hashTagText) => {
        let newValue;
        if (fromTyping) {
            newValue = value.replace(`#${props?.searchedWord}`, `${hashTagText} `);
        } else {
            newValue = `${value} ${hashTagText}`
        }
        const newArr = singlePlatformData?.filter(x => x?.hashWord !== hashTagText);
        setSinglePlatformData(newArr);
        if (!props?.identifier) {
            dispatch(setPublishablePostTextBody(newValue));
            return;
        }
        updater(newValue)
        props?.setSearchedWord('');
    }

    const callApi = (tab) => {
        setSendText('...')
        if (fromTyping && props?.searchedWord) {
            setLoading(true);
        }
        if (!fromTyping && searchText) {
            setLoading(true);
        }
        APIService.fetchSimilarHashTags(workSpace._id, fromTyping ? props?.searchedWord : searchText, tab === "AI Writer", (response, error) => {
            fetchWorkspaceOwner();
            if (error && !fromTyping) {
                console.log(error, "error")
                setLoading(false);
                toast.error(error, { theme: 'colored' })
                setSendText('GO');
                return;
            }
            let { data } = response;
            setHashData(data)
            setLoading(false)
            setSendText("GO")
        })
    }

    const fetchTrendingTopics = () => {
        console.log("first...............")
        APIService.fetchTendingTopics(workSpace._id, (response, error) => {
        console.log("🚀 ~ APIService.fetchTendingTopics ~ error:", error)
        //   updateVisibilityOptions("TRENDS");
          if (response) {
            let { data } = response;
            console.log("🚀 ~ APIService.fetchTendingTopics ~ data:", data)
           
          }
        });
      }

    useEffect(() => {
        fetchWorkspaceOwner();
        fetchTrendingTopics();
        // callApi(currentTab);
    }, [])

    useEffect(() => {
        if (fromTyping) {
            callApi(currentTab);
        }
    }, [props?.searchedWord])

    const getIcon = (key) => {
        switch (key) {
            case PlatformIdentifier.TWITTER:
                return twitterFill;
            case PlatformIdentifier.INSTAGRAM:
                return 'akar-icons:instagram-fill';
            case PlatformIdentifier.FACEBOOK:
                return "akar-icons:facebook-fill"
            case PlatformIdentifier.LINKED_IN:
                return "akar-icons:linkedin-box-fill"
            case PlatformIdentifier.REDDIT:
                return "akar-icons:reddit-fill"
            case PlatformIdentifier.TELEGRAM:
                return "akar-icons:telegram-fill"
            case PlatformIdentifier.PINTEREST:
                return "akar-icons:pinterest-fill"
            case PlatformIdentifier.GOOGLE_MY_BUSINESS:
                return "ant-design:google-circle-filled"
            case PlatformIdentifier.YOUTUBE:
                return "akar-icons:youtube-fill"
            case PlatformIdentifier.TIKTOK:
                return "akar-icons:tiktok-fill"
            default:
                return null;
        }
    }

    const handleNavigate = async (e) => {
        setCurrentTab(e)
        let platformToNavigate = selectedPlatforms?.length ? selectedPlatforms[0].identifier : PlatformIdentifier.FACEBOOK
        try {
            localStorage.setItem('open_hashtag_pop', true);
            dispatch(setSelectedPlatformForPreview(platformToNavigate));
            dispatch(setShowPreview(true));
            //   setDisplayConfirm(false);
        } catch (error) {
        }
    }

    const previewLister = hashData?.map((item) => {
        if (item[props?.identifier]) {
            let icon = getIcon(props?.identifier);
            return <ListItem listHeight={'60px'} hoverColor={!config.isDark
                ? lightTheme.surfaceColor
                : darkTheme.rootBackground} onClick={() => (addNewHash(item?.hashTag))}>
                <SubListItemTop>
                    <HashText statusTextColor={!config.isDark ? "#0b1727" : "white"}>
                        {item?.hashTag}
                    </HashText>
                    <HashIcon>
                        {
                            item?.total_count > 9 && <HashIcon>
                                <FontAwesomeIcon
                                    style={{
                                        width: 12,
                                        height: 12,
                                        color: "#FBC02D",
                                    }}
                                    icon={faBolt}
                                />
                            </HashIcon>
                        }
                    </HashIcon>
                </SubListItemTop>
                {/* <SubListItemBottom>
                    <HashIcon>
                        <Icon icon={icon} color={getColor(props?.identifier)} />
                    </HashIcon>
                    <HashTextSecond>
                        {formatTrendVolume(item[props?.identifier])}
                    </HashTextSecond>
                </SubListItemBottom> */}
            </ListItem>
        }
    })

    const lister = hashData?.map((item) => {
        const displayIconsCount = Object.entries(item).map(([key, value], index) => {
            let icon = getIcon(key);
            if (key === 'hashTag' || key === 'total_count') return <></>;
            return <>
                <HashIcon leftSide={index != 0 && '10px'}>
                    <Icon icon={icon} color={getColor(key)} />
                </HashIcon>
                <HashTextSecond>
                    {formatTrendVolume(value)}
                </HashTextSecond>
            </>
        })
        return <ListItem listHeight={'60px'} hoverColor={!config.isDark
            ? lightTheme.surfaceColor
            : darkTheme.rootBackground} onClick={() => (addNewHash(item?.hashTag))}>
            <SubListItemTop>
                <HashText statusTextColor={!config.isDark ? "#0b1727" : "white"}>
                    {item?.hashTag}
                </HashText>
                {
                    item?.total_count > 9 && <HashIcon>
                        <FontAwesomeIcon
                            style={{
                                width: 12,
                                height: 12,
                                color: "#FBC02D",
                            }}
                            icon={faBolt}
                        />
                    </HashIcon>
                }

            </SubListItemTop>
            <SubListItemBottom>
                {displayIconsCount}
            </SubListItemBottom>
        </ListItem>
    })


    const listerAI = hashData?.map((item) => {
        return <ListItem listHeight={'35px'} hoverColor={!config.isDark
            ? lightTheme.surfaceColor
            : darkTheme.rootBackground} onClick={() => (addNewHash(item?.hashTag))}>
            <SubListItemTop>
                <HashText statusTextColor={!config.isDark ? "#0b1727" : "white"}>
                    {item?.hashTag}
                </HashText>
            </SubListItemTop>
        </ListItem>
    })


    const listerTrending = [...Array(5)]?.map((item) => {
        return <ListItem listHeight={'35px'} hoverColor={!config.isDark
            ? lightTheme.surfaceColor
            : darkTheme.rootBackground} onClick={() => (addNewHash("pop smoke"))}>
            <SubListItemTop>
                <HashText statusTextColor={!config.isDark ? "#0b1727" : "white"}>
                    pop smoke
                </HashText>
            </SubListItemTop>
        </ListItem>
    })

    const switcher = (
        <Tab.Container
            id="trending-ai_writer-tab"
            activeKey={currentTab}
            onSelect={(e) => {
                // e === 'Trending' && !fromPreviews ? handleNavigate(e) : setCurrentTab(e)
                setCurrentTab(e)
                // callApi(e);
            }}>
            <Row className={'px-3'} style={{ width: 221 }}>
                <Nav variant="pills" className="nav-pills-falcon m-0">
                    <Nav.Item>
                        <Nav.Link as={Button} size="sm" eventKey="Trending">
                            Trending
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Button} size="sm" eventKey="AI Writer">
                            AI Writer
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </Row>
        </Tab.Container>
    )

    const bottomRow = (
        <BottomRow>
            <EachLayer flexJustify={'flex-start'}>
                <InputContainer>
                    <BeforeInput onClick={handleCancel}>
                        <FontAwesomeIcon
                            style={{
                                color: !config.isDark ? "#0b1727" : "white",
                            }}
                            icon={faXmarkCircle}
                        />
                    </BeforeInput>

                    <InputBox statusTextColor={!config.isDark ? "#0b1727" : "white"} value={searchText} onChange={(e) => setSearchText(e.target.value)} onKeyPress={(e) => e.key === 'Enter' && !loading && callApi(currentTab)} />
                </InputContainer>
                <SendContainer statusTextColor={!config.isDark ? "#0b1727" : "white"} sendEvent={sendText == 'GO' ? '' : 'none'} onClick={() => callApi(currentTab)}>
                    {sendText}
                </SendContainer>
            </EachLayer>
            {
                !fromTyping && loading && <EachLayer flexJustify={'center'}>
                    <EachLayerText>Please write something or search by keyword.</EachLayerText>
                </EachLayer>
            }
        </BottomRow>
    )

    return (
        <>
            <Card
                className={`ps-${props.padLeft} m-3`}
                style={{
                    minWidth: props.mainWidth,
                    boxShadow: dropShadow && 'none'
                }}
            >
                <Card.Body>
                    {switcher}
                    <hr />
                    {(!fromTyping && currentTab == "AI Writer") && bottomRow}
                    {/* {!fromTyping && bottomRow} */}
                    <ListContainer>
                        {
                            loading ?
                                <BarLoader
                                    color={'blue'}
                                    loading={loading}
                                    cssOverride={override}
                                    size={150}
                                /> :
                                currentTab == 'Trending'
                                    ?
                                    // (fromPreviews ? previewLister : lister)
                                    previewLister
                                    : listerAI
                        }
                    </ListContainer>
                </Card.Body>
                {
                    currentTab === 'AI Writer' &&
                    <Card.Footer className='bg-100'>
                        <Flex direction={'column'}>
                            <Flex alignItems={'center'} className={'gap-1'}>
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    style={{
                                        width: 12,
                                        height: 12,
                                    }}
                                />
                                <span className='fs--2'>Suggestions are from your AI Writer Quota</span>
                            </Flex>
                            <Divider />
                            <Flex alignItems={'center'}>
                                <div style={{ flex: 1 }}></div>
                                <Button
                                    style={{ alignSelf: 'flex-end' }}
                                    size={'sm'}
                                    variant={`${maxUsableCharacters() === "Unlimited" ? "" : "outline-"}info`}
                                    className='fs--2 rounded-pill'
                                    onClick={() => {
                                        navigate(`${RoutePaths.BILLING_STRIPE}`)
                                    }}
                                >
                                    {maxUsableCharacters(workSpaceOwner) === "Unlimited" ? "Subscribed" : "Subscribe to Unlimited Quota"}
                                    {
                                        maxUsableCharacters(workSpaceOwner) === "Unlimited" &&
                                        <FontAwesomeIcon
                                            icon={faCheckCircle}
                                            style={{ marginLeft: 5 }}
                                        />
                                    }
                                </Button>
                            </Flex>
                        </Flex>
                    </Card.Footer>
                }
            </Card>
        </>
    )
}

export default HashTagPop
