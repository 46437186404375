import React from 'react';
import ReactTimeago from 'react-timeago';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import ContinuousExpansionTextView from 'components/app/social/feed/ContinuousExpansionTextView';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { useSelector } from 'react-redux';
import { getLoggedInUser } from 'redux/slices/user_slice';

export default function TeamFeedbackEntry(props) {

    const { feedback } = props;
    const loggedInUser = useSelector(getLoggedInUser);

    return (
        <Flex
            style={{
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 5,
                marginRight: 10,
                gap: 10,
                border: `1px dashed ${feedback.disapproved ? "#FF8A65" : "#A5D6A7"}`
            }}>
            <Avatar fallBackName={feedback.name} src={feedback.avatar} />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                <h6 className='text-black'>{feedback.member_id === loggedInUser._id ? 'You' : feedback.name}</h6>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 4,
                        color: '#78909C'
                    }}>
                    <FontAwesomeIcon
                        icon={feedback.disapproved ? faTimes : faCheck}
                        style={{
                            height: 10,
                            width: 10,
                            color: feedback.disapproved ? 'red' : 'green'
                        }}
                    />
                    <span
                        style={{
                            fontSize: 12
                        }}>{feedback.disapproved ? 'I disapprove' : 'I approve'}
                    </span>
                    <span
                        dangerouslySetInnerHTML={{ __html: `&#11044;` }}
                        style={{
                            fontSize: 5
                        }}>
                    </span>
                    <ReactTimeago
                        date={new Date(feedback.created_at).toUTCString()}
                        units="minute"
                        style={{
                            fontSize: 12,
                        }}
                    />
                </div>
                {
                    feedback.reason &&
                    <ContinuousExpansionTextView
                        text={feedback.reason}
                        style={{
                            marginTop: 2
                        }}
                        inputStyle={{
                            fontSize: 13
                        }}
                    />
                }
            </div>
        </Flex>
    );
}