import { faLink, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmModal from "components/common/ConfirmModal";
import Divider from "components/common/Divider";
import Flex from "components/common/Flex";
import FreePageHeader from "components/common/FreePageHeader";
import CopyLinkButton from "components/common/copylinkbutton";
import { RoutePaths } from "constants";
import APIService from "http/api_service";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdSettings } from "react-icons/io";
import { MdLock } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getAutoShortenerStatus,
  getDomains,
  getLinksFolders,
  setAutoShortenerStatus,
  setDomains,
  setLinksFolders,
} from "redux/slices/links_shortener_slice";
import { getActiveWorkSpace, setSearchHint } from "redux/slices/workspaceslice";
import { copyToClipboard } from "utils/browser";
import { getPreviewFromUrl } from "utils/url";
import LinkMetadata from "./LinkMetadata";
import { setPopupUpgradeAlert } from "redux/slices/alertSlice";
import { setCollectMetric } from "redux/slices/analytics_slice";
import { MetricsProps } from "constants";
import Papa from 'papaparse';
import CsvDownloader from "react-csv-downloader";
import { CgExport } from "react-icons/cg";
import AlertModal from "components/common/GlobalModals/AlertModal";
import SoftBadge from "components/common/SoftBadge";

const Shortener = ({ tabRef, setTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = useSelector(getAutoShortenerStatus);
  const domains = useSelector(getDomains);


  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const [shortLink, setShortLink] = useState();
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [displayNewFolderInput, setDisplayNewFolderInput] = useState(false);
  const [folderActionhandler, setFolderActionhandler] = useState("Create");
  const [allowLinksToExpire, setAllowLinksToExpire] = useState(false);
  const [showLinkPreview, setShowLinkPreview] = useState(true);
  const [saveLinkData, setSaveLinkData] = useState({
    link_name: "",
    save_folder: "",
    link_url: "",
    links_urls: []
  });
  const [useableLinksData, setUseableLinksData] = useState([]);
  const [whenToExpire, setWhenToExpire] = useState(
    moment(new Date()).add(7, "days").toDate()
  );
  const [formData, setFormData] = useState({
    full_link: "",
  });
  const [columnData, setColumnData] = useState(null);
  const [displayValue, setDisplayValue] = useState('');
  const fileInputRef = useRef(null);
  const [generatingShortLink, setGeneratingShortLink] = useState();
  const workSpace = useSelector(getActiveWorkSpace);
  const linksFolders = useSelector(getLinksFolders);
  const [workSpaceOwner, setWorkspaceOwner] = useState();
  const [saveButtonState, setSaveButtonState] = useState("Save");
  const [linkMetadata, setLinkMetadata] = useState();
  const [fetchingLinkMetadata, setFetchingLinkMetadata] = useState(false);
  const [showPopup, setShowPopup] = useState(false)


  const getLinkMetaData = async (fullLink) => {
    try {
      setFetchingLinkMetadata(true)
      const result = await getPreviewFromUrl(fullLink ?? "");
      setLinkMetadata(result?.data);
      setFetchingLinkMetadata(false)
    } catch (error) {
      setFetchingLinkMetadata(false)
      setLinkMetadata(null);
    }
  }

  useEffect(() => {
    getLinkMetaData(formData?.full_link)
  }, [formData?.full_link])

  const fetchWorkspaceOwner = () => {
    APIService.fetchWorkSpaceOwner(workSpace._id, (response, error) => {
      if (response) {
        let { data } = response;
        setWorkspaceOwner(data);
      }
    });
  };

  const getStatusHandler = async () => {
    APIService.getAutoShortenedLinkStatus((response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      let { data } = response;
      //   toast.success(message);
      dispatch(setAutoShortenerStatus(data));
    });
  };





  useEffect(() => {
    dispatch(setSearchHint("Search..."));
    fetchWorkspaceOwner();
    getStatusHandler();
  }, []);

  const userOnFreePlan = () => {
    let activePlan = workSpaceOwner?.active_plan;
    if (
      activePlan?.Name?.toLowerCase() === "free" ||
      activePlan?.Key?.toLowerCase() === "free"
    ) {
      return true;
    }
    return false;
  };

  const handleInputChanges = (e) => {
    if (e.target.name === "full_link" && !formData.custom_domain) {
      // toast.error("You need to set up custom domain first.",
      //   { theme: "colored" })
      setShowPopup(true);
      return
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (allowLinksToExpire) {
      if (!formData.fall_back_url_after_expiration) {
        toast.error(
          "Kindly provide a fallback link to be used after expiration",
          { theme: "colored" }
        );
        return;
      }
    }


    if (formData?.custom_domain) {
      if (userOnFreePlan() === true) {
        let metricInitializer = {
          action: MetricsProps.LINK_SHORTENER,
          detailedAction: `Upgrade notice: Shorten link with custom domain`,
          timestamp: new Date().toISOString(),
          route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
        }
        dispatch(setCollectMetric(metricInitializer))
        dispatch(setPopupUpgradeAlert(true))
        return;
      }
      if (!formData.custom_domain.includes(".")) {
        toast.error("Kindly provide subdomain or domain.", {
          theme: "colored",
        });
        return;
      }
    }

    if (formData.back_half && userOnFreePlan() === true) {
      let metricInitializer = {
        action: MetricsProps.LINK_SHORTENER,
        detailedAction: `Upgrade notice: Shorten link with back-half`,
        timestamp: new Date().toISOString(),
        route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
      }
      dispatch(setCollectMetric(metricInitializer))
      dispatch(setPopupUpgradeAlert(true))
      return;
    }
    formData.linkMetadata = linkMetadata;
    formData.showLinkPreview = showLinkPreview;
    setGeneratingShortLink(true);
    const urlString = formData?.full_link;
    const urlArray = urlString.split("\n").map(url => url.trim());
    if (urlArray?.length > 1) {
      if (userOnFreePlan() === true) {
        let metricInitializer = {
          action: MetricsProps.LINK_SHORTENER,
          detailedAction: `Upgrade notice: Multiple links upload for shortening`,
          timestamp: new Date().toISOString(),
          route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
        }
        dispatch(setCollectMetric(metricInitializer))
        dispatch(setPopupUpgradeAlert(true))
        return;
      }
      // formData.full_link = urlArray.join(", ")
      // let _copyformData = {...formData}
      // _copyformData.full_link = urlArray.join(", ")
      APIService.bulkShortenLinks(formData, (response, error) => {
        setGeneratingShortLink(false);
        if (error) {
          toast.error(error, { theme: "colored" });
          if (error === "Domain not setup.") {
            // setTab(4);
            navigate(`${RoutePaths.CUSTOM_DOMAIN}`)
          }
          return;
        }
        let { data } = response;
        console.log("🚀 ~ APIService.bulkShortenLinks ~ data:", data)
        // setShortLink(data);
        setSaveLinkData({ ...saveLinkData, links_urls: data });
        document.getElementById("link-shortener-footer")?.scrollIntoView();
      });
      return;
    } else {
      APIService.shortenLink(formData, "no", (response, error) => {
        setGeneratingShortLink(false);
        if (error) {
          toast.error(error, { theme: "colored" });
          if (error === "Domain not setup.") {
            // setTab(4);
            navigate(`${RoutePaths.CUSTOM_DOMAIN}`)
          }
          return;
        }
        let { data } = response;
        setShortLink(data);
        setSaveLinkData({ ...saveLinkData, link_url: data });
        document.getElementById("link-shortener-footer")?.scrollIntoView();
      });
    }
  };

  const saveLinkHandler = async (e) => {
    e.preventDefault();
    if (!saveLinkData.link_name) {
      toast.error("Input link name", { theme: "colored" });
      return;
    }
    setSaveButtonState("Saving...");
    APIService.saveLink(saveLinkData, (response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        setSaveButtonState("Save");
        return;
      }
      let { message } = response;
      toast.success(message);
      setSaveButtonState("Save");
      setSaveLinkData({
        ...saveLinkData,
        link_name: "",
        save_folder: "",
        link_url: "",
      });
      setShortLink("");
      setShowSaveModal(false);
    });
  };

  //get folders
  const fetchFoldersHandler = async (e) => {
    APIService.fetchFolders((response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      let { message, data } = response;
      //   toast.success(message);
      dispatch(setLinksFolders(data));
    });
  };

  //create folder
  const createFolderHandler = async (e) => {
    e.preventDefault();
    if (!newFolderName) {
      toast.error("Input folder name", { theme: "colored" });
      return;
    }
    setFolderActionhandler("Creating...");
    let requestBody = {
      folder_name: newFolderName,
    };
    APIService.createLinkFolder(requestBody, (response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        setFolderActionhandler("Create Folder");

        return;
      }
      let { message } = response;
      toast.success(message);
      fetchFoldersHandler();
      setFolderActionhandler("Create");
      setDisplayNewFolderInput(false);
      setNewFolderName("");
    });
  };

  const handleNewFolderClick = () => {
    setDisplayNewFolderInput(true);
  };

  const handleToggle = async (e) => {
    e.preventDefault();
    if (userOnFreePlan() === true) {
      let metricInitializer = {
        action: MetricsProps.LINK_SHORTENER,
        detailedAction: `Upgrade notice: Toggle auto-shorten links`,
        timestamp: new Date().toISOString(),
        route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
      }
      dispatch(setCollectMetric(metricInitializer))
      dispatch(setPopupUpgradeAlert(true))
      return;
    }
    let requestBody = {
      status: e.target.checked,
    };
    await APIService.toggleAutoShortenLinks(requestBody, (response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      let { message, data } = response;
      dispatch(setAutoShortenerStatus(data));
      toast.success(message);
      getStatusHandler();
    });
  };

  const handleButtonClick = () => {
    fileInputRef.current.click(); // Trigger the file input click
  };

  //write a function that will handle upload cvs file form local system
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true, // Treat the first row as header
        complete: (results) => {
          const data = results.data;
          if (data.length > 0 && data[0].hasOwnProperty('Links')) {
            const values = data.map(row => row["Links"]).filter(value => value); // Get values from the specified column
            console.log("🚀 ~ handleFileUpload ~ values:", values)
            setFormData({ ...formData, full_link: values?.length === 1 ? values.join("") : values.join("\n") });
            setShowBulkUploadModal(false);
            setColumnData(values);
            return;
          } else {
            toast.error("The uploaded file does not contain a 'Links' column.", { theme: "colored" });
            return;
          }

        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
        }
      });
    }
  };

  const columns = [
    {
      id: "full_link",
      displayName: "Full link",
    },
    {
      id: "short_link",
      displayName: "Short link",
    },]

  const handleKeyDown = (event) => {
    // Allow default behavior for Enter key
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default behavior of the Enter key
      console.log("🚀 ~ handleKeyDown ~ event.target.value:", event.target.value);

      // Add a newline to the current value
      const currentValue = event.target.value;
      const newValue = `${currentValue}\n`; // Append a newline character

      // Update the input or textarea value
      event.target.value = newValue;

      // Optionally, update your formData if needed
      formData.full_link = newValue; // Update formData if necessary
    }
  };





  return (
    <>
      <Card.Body className="text-left py-50">
        {/* <Card style={{ marginBottom: "20px", padding: "10px 10px" }}>
          <div
            style={{
              width: "100%",
              paddingBottom: "10px",
              // borderBottom: "1px solid ",
            }}
          >
            <>
              <h3 className="fw-normal fs-2 fs-md-2">Settings</h3>
              <Flex alignItems={"center"} className={"gap-3"}>
                <IoMdSettings size={20} />
                <Form.Label
                  style={{
                    paddingTop: 8,
                  }}
                  htmlFor="auto-shorten-link-settings"
                >
                  Auto-shorten links in your posts
                </Form.Label>
                <Form.Check
                  //   style={{
                  //     cursor: "pointer",
                  //   }}
                  id="auto-shorten-link-settings"
                  type="switch"
                  onChange={handleToggle}
                  checked={status}
                // label="Auto Shorten Links in Post"
                />
              </Flex>
            </>
          </div>
        </Card> */}
        <Card style={{ padding: "10px 10px" }}>
          {/* <h3 className="fw-normal fs-2 fs-md-2">Shorten your links</h3> */}
          <div
            style={{
              width: "100%",
              paddingBottom: "10px",
              // borderBottom: "1px solid ",
            }}
          >
            <>
              {/* <h3 className="fw-normal fs-2 fs-md-2">Settings</h3> */}
              <Flex alignItems={"center"} className={"gap-3"}>
                <IoMdSettings size={20} />
                <Form.Label
                  style={{
                    paddingTop: 8,
                  }}
                  htmlFor="auto-shorten-link-settings"
                >
                  Auto-shorten links in your posts
                </Form.Label>
                <Form.Check
                  //   style={{
                  //     cursor: "pointer",
                  //   }}
                  id="auto-shorten-link-settings"
                  type="switch"
                  onChange={handleToggle}
                  checked={status}
                // label="Auto Shorten Links in Post"
                />
              </Flex>
            </>
          </div>
          <p></p>
          <p></p>
          <Alert variant="warning" className="p-2 mt-3">
            <Flex>
              <FontAwesomeIcon icon="exclamation-triangle" className="fs-2" />
              <div className="ms-3 flex-1" style={{ backgroundColor: "amber", width: "50%", fontSize: "14px" }}>
                <div>To use the Link shortener feature, please set up a custom domain first. Once configured, you'll be able to start shortening links with ease!</div>
              </div>
            </Flex>
          </Alert>
          <Row className="justify-content-center align-items-center">
            {
              // userOnFreePlan() ?
              //     <div>
              //         <p>Upgrade your account to use this service</p>
              //         <Button as={Link} to={`${RoutePaths.ACCOUNT}?t=billing`}>Upgrade</Button>
              //     </div>
              //     :
              <Col md={12}>
                <Form onSubmit={handleSubmit} className="g-2">
                  <Row>
                    <Col sm md={12}>
                      <Form.Label>Domain</Form.Label>{" "}
                      <span
                        style={{ cursor: "pointer", fontSize: "12px" }}
                        data-tooltip="Add your domain"
                        onClick={() => {
                          if (userOnFreePlan() === true) {
                            let metricInitializer = {
                              action: MetricsProps.LINK_SHORTENER,
                              detailedAction: `Upgrade notice: Shorten link with custom domain`,
                              timestamp: new Date().toISOString(),
                              route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
                            }
                            dispatch(setCollectMetric(metricInitializer))
                            dispatch(setPopupUpgradeAlert(true))
                            return;
                          }
                          navigate(`${RoutePaths.CUSTOM_DOMAIN}`);
                        }}
                      >
                        {" "}
                        (Set up a domain){" "}
                      </span>
                      <div style={{ width: "100%", position: "relative" }}>
                        {/* <Form.Control
                          type="text"
                          placeholder="Enter the domain you set up e.g. link.example.co"
                          aria-label="Long Link Text box"
                          value={formData.custom_domain}
                          name={"custom_domain"}
                          onChange={handleInputChanges}
                        // style={{width: "100%"}}
                        /> */}
                        <Form.Select aria-label="Default select example" value={formData.custom_domain} name={"custom_domain"} onChange={handleInputChanges}>
                          <option value="">Select custom domain</option>
                          {domains?.map((domain, index) => (
                            <option key={index} value={domain?.result?.hostname}>{domain?.result?.hostname}</option>
                          ))}
                        </Form.Select>
                      </div>
                      <br />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm style={{ marginBottom: "12px", width: "100%" }}>
                      {/* <Form.Label>Paste a long URL</Form.Label> */}
                      <Flex style={{ width: "100%", justifyContent: "space-between" }} alignItems={"center"}>
                        <div>
                          <Form.Label>
                            <span>Paste a long URL or multiple links</span>
                          </Form.Label>
                        </div>
                        <Flex alignItems={"center"} gap={3}>
                          <span>Bulk upload link:</span>
                          <Button size="sm" variant="falcon-primary"
                            onClick={(event) => {
                              event.stopPropagation();
                              if (userOnFreePlan() === true) {
                                let metricInitializer = {
                                  action: MetricsProps.LINK_SHORTENER,
                                  detailedAction: `Upgrade notice: Bulk upload links for shortening`,
                                  timestamp: new Date().toISOString(),
                                  route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
                                }
                                dispatch(setCollectMetric(metricInitializer))
                                dispatch(setPopupUpgradeAlert(true))
                                return;
                              }
                              setShowBulkUploadModal(true)
                            }}
                          >
                            <FontAwesomeIcon icon={faPrint} size="1x" />
                          </Button>
                        </Flex>
                      </Flex>
                      <Form.Control as="textarea" placeholder={`www.facebook.com\nwww.twitter.com`}
                        aria-label="Long Link Text box"
                        value={formData.full_link}
                        name={"full_link"}
                        onChange={handleInputChanges}
                        onKeyDown={handleKeyDown} // Handle key down events
                        style={{ width: "100%", margin: "0px" }} rows={formData.full_link?.split("\n")?.length > 2 ? formData.full_link?.split("\n")?.length : 2} />
                    </Col>
                    {
                      showLinkPreview &&
                      <Col sm="auto">
                        <LinkMetadata
                          linkMetadata={linkMetadata}
                          fetchingLinkMetadata={fetchingLinkMetadata}
                        />
                      </Col>
                    }
                    {/* <Col sm="auto">
                      <Button size="sm" variant="falcon-primary">
                        <FontAwesomeIcon icon={faPrint} size="1x" />
                      </Button>
                    </Col> */}
                    <br />
                  </Row>

                  <Row className="g-3">
                    <Col sm md={12} style={{ marginBottom: "12px" }}>
                      <Form.Label>{`Back-half (This is optional. E.g. https://${formData.custom_domain || "example.com"}/CJx/social)`}</Form.Label>
                      <div style={{ width: "100%", position: "relative" }}>
                        <Form.Control
                          type="text"
                          placeholder="E.g. social"
                          aria-label="Long Link Text box"
                          value={formData.back_half}
                          name={"back_half"}
                          onChange={handleInputChanges}
                        />
                      </div>
                      <br />
                    </Col>
                  </Row>
                  {/* {formData.full_link && ( */}
                  <div>
                    <Flex
                      alignItems={"flex-start"}
                      className={"gap-3"}
                      direction={"column"}
                      style={{
                        marginTop: 10,
                      }}
                    >
                      <div>
                        <Flex gap={3} alignItems={"center"}>
                          <Form.Label
                            style={{
                              paddingTop: 8,
                            }}
                            htmlFor="allow-short-links-to-expire-check"
                          >
                            Allow this Link to expire?
                          </Form.Label>

                          <Form.Check
                            id="allow-short-links-to-expire-check"
                            type="switch"
                            checked={allowLinksToExpire}
                            onChange={(e) => {
                              setAllowLinksToExpire(e.target.checked);
                              if (!e.target.checked) {
                                setFormData((prevData) => {
                                  prevData = { ...prevData };
                                  delete prevData.expireAt;
                                  delete prevData.fall_back_url_after_expiration;
                                  return { ...prevData };
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  expireAt: new Date(),
                                });
                              }
                            }}
                          />
                        </Flex>

                        {allowLinksToExpire && (
                          <div>
                            <Flex direction={"column"}>
                              <Row className="gap-3">
                                <Col sm={12}>
                                  <Form.Group className="text-start">
                                    <Form.Label>When to Expire</Form.Label>
                                    <ReactDatePicker
                                      className="form-control"
                                      selected={whenToExpire}
                                      minDate={new Date()}
                                      onChange={(newDate) => {
                                        setWhenToExpire(newDate);
                                        setFormData({
                                          ...formData,
                                          expireAt: newDate,
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col sm={12}>
                                  <Form.Group className="text-start">
                                    <Form.Label>Fallback Link</Form.Label>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={
                                        "Specify a fallback link when your link expires"
                                      }
                                      value={formData?.fall_back_url_after_expiration}
                                      name={"fall_back_url_after_expiration"}
                                      onChange={handleInputChanges}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Flex>
                            <br />
                          </div>

                        )}
                        <Divider />
                      </div>
                      <Flex gap={3} alignItems={"center"}>
                        <Form.Label
                          style={{
                            paddingTop: 8,
                          }}
                          htmlFor="show-link-preview-check"
                        >
                          Show link preview?
                        </Form.Label>
                        <Form.Check
                          id="show-link-preview-check"
                          type="switch"
                          checked={showLinkPreview}
                          onChange={(e) => {
                            setShowLinkPreview(e.target.checked);
                          }}
                        />
                      </Flex>
                    </Flex>
                    <br />
                  </div>
                  {/* )} */}

                  <Row>
                    <Col sm="auto" style={{ marginTop: "4" }}>
                      <Button
                        onClick={handleSubmit}
                        variant="primary"
                        disabled={!formData.full_link || generatingShortLink || fetchingLinkMetadata}
                        className="d-block w-100"
                      >
                        {generatingShortLink ? "Please wait..." : "Get your link"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            }
          </Row>
          {saveLinkData.links_urls?.length > 1 && <Card.Footer id="link-shortener-footer" className="p-0" style={{ marginTop: "10px" }}>
            {useableLinksData?.length > 0 && <div style={{ width: "100%", justifyContent: "flex-end", alignItems: "center" }}>
              <Flex gap={3} alignItems={"center"}>
                <Card.Subtitle>Export links as CSV</Card.Subtitle>
                <CsvDownloader
                  filename="shortened links"
                  extension=".csv"
                  columns={columns}
                  datas={useableLinksData?.map(({ full_link, short_link }) => ({ full_link, short_link }))}
                >

                  <Button style={{ cursor: "pointer" }}>
                    Export
                  </Button>
                </CsvDownloader>
              </Flex>
            </div>}
            {saveLinkData.links_urls?.length > 1 && <div style={{ marginTop: "10px" }}>
              <Table border="1" style={{ border: "1px solid black", borderCollapse: "collapse" }} responsive>
                <thead>
                  <tr>
                    <th> <input type="checkbox" onChange={(e) => {
                      if (e.target.checked === true) {
                        setUseableLinksData(saveLinkData.links_urls)
                        return;
                      } else {
                        setUseableLinksData([])
                      }
                    }} /> </th>
                    <th style={{ border: "1px solid black" }}>Full Links</th>
                    <th style={{ border: "1px solid black" }}>Shortened Links</th>
                  </tr>
                </thead>
                <tbody>
                  {saveLinkData.links_urls?.length > 1 && saveLinkData.links_urls?.map((urlData, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ border: "1px solid black" }}><input type="checkbox" checked={useableLinksData?.find((us) => us?.full_link === urlData?.full_link) ? true : false} onChange={(e) => {
                          if (e.target.checked === true) {
                            setUseableLinksData([...useableLinksData, urlData])
                            return;
                          } else {
                            let filterdatas = useableLinksData?.filter((us) => us?.full_link !== urlData?.full_link)
                            setUseableLinksData(filterdatas)
                          }
                        }} /></td>
                        <td style={{ border: "1px solid black" }}> <a href={urlData?.full_link} target="_blank">{urlData?.full_link}</a></td>
                        <td style={{ border: "1px solid black" }}>
                          <Flex alignItems={"center"} className={"gap-4"}>
                            <a href={urlData?.short_link} target="_blank">{urlData?.short_link}</a>
                            <Flex alignItems={"center"} className={"gap-4"}>
                              <CopyLinkButton
                                defaultText={"Copy"}
                                variant={"outline-info"}
                                handleCopy={() => {
                                  copyToClipboard(urlData?.short_link);
                                }}
                              />
                              {userOnFreePlan() !== true && (
                                <Button onClick={() => {
                                  setSaveLinkData({ ...saveLinkData, link_url: urlData?.short_link })
                                  setShowSaveModal(true)
                                }
                                }>Save</Button>
                              )}
                            </Flex>
                          </Flex>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>}
          </Card.Footer>}
          {shortLink && (
            <Card.Footer id="link-shortener-footer" className="p-0">
              <Card.Header>
                <Card.Title>
                  <span>Link Shortened</span>
                </Card.Title>
              </Card.Header>
              <Card.Body className="bg-light">
                <Row>
                  <Col lg={6}>
                    <Flex alignItems={"center"} className={"pt-2"}>
                      <span
                        style={{
                          maxWidth: 300,
                        }}
                      >
                        {formData?.full_link}
                      </span>
                    </Flex>
                  </Col>
                  <Col lg={6}>
                    <Flex alignItems={"center"} className={"gap-4"}>
                      <a
                        style={{
                          maxWidth: 300,
                        }}
                        href={shortLink}
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        {shortLink}
                      </a>
                      <CopyLinkButton
                        defaultText={"Copy"}
                        variant={"outline-info"}
                        handleCopy={() => {
                          copyToClipboard(shortLink);
                        }}
                      />
                      {userOnFreePlan() !== true && (
                        <Button onClick={() => setShowSaveModal(true)}>Save</Button>
                      )}
                    </Flex>
                  </Col>
                </Row>
              </Card.Body>
            </Card.Footer>
          )}
        </Card>
      </Card.Body >


      <Modal
        size="lg"
        centered
        show={showSaveModal}
        onHide={() => setShowSaveModal(false)}
        onExit={() => setShowSaveModal(false)}
      >
        <div style={{ padding: "0px" }}>
          <div style={{ border: "1px solid #bfc9d7" }}>
            <Modal.Header>
              <span
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Modal.Title>Save In</Modal.Title>
                </div>
                <AiOutlineClose
                  cursor={"pointer"}
                  onClick={() => setShowSaveModal(false)}
                />
              </span>
            </Modal.Header>
            <Modal.Body>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <h5>Name</h5>
                <Form.Control
                  type="text"
                  placeholder="Enter link name"
                  aria-label="Long Link Text box"
                  value={saveLinkData.link_name}
                  name={"link_name"}
                  onChange={(e) =>
                    setSaveLinkData({
                      ...saveLinkData,
                      link_name: e.target.value,
                    })
                  }
                // style={{width: "100%"}}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "20px",
                }}
              >
                <h5>Folder</h5>
                <Form.Select
                  style={{ cursor: "pointer" }}
                  aria-label="Default select example"
                  onChange={(e) =>
                    setSaveLinkData({
                      ...saveLinkData,
                      save_folder: e.target.value,
                    })
                  }
                  value={saveLinkData.save_folder}
                  name={"save_folder"}
                >
                  <option style={{ cursor: "pointer" }} value="">
                    Open this select menu
                  </option>
                  {linksFolders?.map((folder, index) => (
                    <option
                      key={index}
                      style={{ cursor: "pointer" }}
                      value={folder?._id}
                    >
                      {folder?.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                  marginTop: "30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                    marginTop: "20px",
                  }}
                >
                  {displayNewFolderInput && (
                    <Form.Control
                      type="text"
                      placeholder="Folder name"
                      aria-label="Long Link Text box"
                      value={newFolderName}
                      name={"newFolderName"}
                      onChange={(e) => {
                        setNewFolderName(e.target.value);
                        setFolderActionhandler("Create Folder");
                        if (!e.target.value) {
                          setFolderActionhandler("Create");
                          setDisplayNewFolderInput(false);
                        }
                      }}
                    // style={{width: "100%"}}
                    />
                  )}
                  {
                    <Button
                      style={{
                        alignSelf: "flex-end",
                        backgroundColor: "#1e63fe",
                        minHeight: 35,
                      }}
                      onClick={
                        folderActionhandler === "Create"
                          ? handleNewFolderClick
                          : createFolderHandler
                      }
                      size="sm"
                      className={`px-3 px-sm-2`}
                    //   disabled={folderToMoveId ? false : true}
                    >
                      {folderActionhandler}
                    </Button>
                  }
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    style={{
                      alignSelf: "flex-end",
                      backgroundColor: "#fafbfd",
                      minHeight: 35,
                      color: "#7f8894",
                    }}
                    onClick={() => setShowSaveModal(false)}
                    size="sm"
                    className={`px-3 px-sm-5`}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      alignSelf: "flex-end",
                      backgroundColor: "#1e63fe",
                      minHeight: 35,
                    }}
                    onClick={saveLinkHandler}
                    size="sm"
                    className={`px-3 px-sm-5`}
                    disabled={saveLinkData.link_name ? false : true}
                  >
                    {saveButtonState}
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </div>
        </div>
      </Modal>
      {/* Modal for Bulk uploads of links */}
      <Modal
        size="lg"
        centered
        show={showBulkUploadModal}
        onHide={() => setShowBulkUploadModal(false)}
        onExit={() => setShowBulkUploadModal(false)}
      >
        <div style={{ padding: "0px" }}>
          <div style={{ border: "1px solid #bfc9d7" }}>
            <Modal.Header>
              <span
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Modal.Title>Upload a CSV file</Modal.Title>
                </div>
                <AiOutlineClose
                  cursor={"pointer"}
                  onClick={() => setShowBulkUploadModal(false)}
                />
              </span>
            </Modal.Header>
            <Modal.Body>
              <ul>
                <li>Upload a CSV file, your links must be listed under one column with header "Links". Example below.</li>
              </ul>
              <div>
                <Button onClick={() => {
                  window.open("https://docs.google.com/spreadsheets/d/1vqUibjnEo3WTgnR4WQmqnnQ5HHV6oO_pT_QR1N3DKN4/edit?usp=sharing", "_blank")
                }}>View sample</Button>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <input
                type="file"
                accept=".csv"
                onChange={handleFileUpload}
                ref={fileInputRef}
                style={{ display: 'none' }} // Hide the file input
              />
              <Button
                variant="secondary"
                onClick={() => setShowBulkUploadModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleButtonClick}
              >
                Upload
              </Button>
            </Modal.Footer>
          </div>
        </div>
      </Modal>
      <ConfirmModal
        open={openUpgradeDialog}
        title={"Action requires Upgrade"}
        message={"This feature requires that you are on a premium account"}
        confirmText={"UPGRADE"}
        cancelText={"CANCEL"}
        onCancel={() => {
          setOpenUpgradeDialog(false);
        }}
        onConfirm={() => {
          setOpenUpgradeDialog(false);
          navigate(`${RoutePaths.BILLING_STRIPE}`);
        }}
      />
      <Modal
        size="md"
        // size="lg"
        centered
        show={showPopup}
        onHide={() => {
          setShowPopup(false);
        }}
      >
        <Modal.Header>
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Modal.Title>
                Notification 🔔
              </Modal.Title>
            </div>
            <Modal.Title>
              <AiOutlineClose
                cursor={"pointer"}
                onClick={() => {
                  setShowPopup(false);
                }}
              />
            </Modal.Title>
          </span>
        </Modal.Header>
        <Modal.Body>
        <SoftBadge
                pill
                bg={"warning"}
                className='cursor-pointer'
                wrapText={true}
              >
                {`Notification: You need to ${domains?.length > 0 ? "select a" : "set up"} custom domain first.`}
              </SoftBadge>
        </Modal.Body>

      </Modal>
    </>
  );
};

export default Shortener;
