import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import geminiLogo from "assets/img/logos/gemini.png";
import claudeIcon from "assets/svgs/claudeIcon.svg";
import openAIIcon from "assets/svgs/openAIIcon.svg";
import ConfirmModal from "components/common/ConfirmModal";
import Flex from "components/common/Flex";
import { RoutePaths, MetricsProps } from "constants";
import APIService from "http/api_service";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setPopupUpgradeAlert } from "redux/slices/alertSlice";
import { useDispatch } from "react-redux";
import { setCollectMetric } from "redux/slices/analytics_slice";

const APIKeyManagementCentre = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
  const { apiKey, checkAIWriterUsageResumption, workSpace } = props;
  const [geminiKey, setGeminiKey] = useState("");
  const [claudeKey, setClaudeKey] = useState("");
  const [openaiKey, setOpenAIKey] = useState("");
  const [isSaveKeyGemini, setIsSaveKeyGemini] = useState(false);
  const [isSaveKeyClaude, setIsSaveKeyClaude] = useState(false);
  const [isSaveKeyOpenAI, setIsSaveKeyOpenAI] = useState(false);
  const [loading, setLoading] = useState(false);
  const [workSpaceOwner, setWorkspaceOwner] = useState();
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);

  const fetchWorkspaceOwner = () => {
    APIService.fetchWorkSpaceOwner(workSpace._id, (response, error) => {
      if (response) {
        let { data } = response;
        setWorkspaceOwner(data);
      }
    });
  };

  useEffect(() => {
    fetchWorkspaceOwner();
  }, []);

  const userOnFreePlan = () => {
    let activePlan = workSpaceOwner?.active_plan;
    if (
      activePlan?.Name?.toLowerCase() === "free" ||
      activePlan?.Key?.toLowerCase() === "free"
    ) {
      return true;
    }
    return false;
  };

  const handleSaveKey = (val, removeKey = false) => {
    if (userOnFreePlan() === true) {
      let metricInitializer = {
        action: MetricsProps.AI_CHAT_TOOL,
        detailedAction: `Upgrade notice: Attempted to save AI API key on a free plan`,
        timestamp: new Date().toISOString(),
        route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
      }
      dispatch(setCollectMetric(metricInitializer))
      dispatch(setPopupUpgradeAlert(true))
      return;
    }
    if (loading) return;
    setLoading(true);
    if (val == "GEMINI") {
      setIsSaveKeyGemini(true);
      if (!geminiKey && !removeKey) {
        toast.error("Please enter Gemini api key", { theme: "colored" });
        setLoading(false);
        return;
      }
      APIService.saveApiKey(
        workSpace["_id"],
        "geminiKey",
        geminiKey,
        removeKey,
        (res, err) => {
          setLoading(false);
          if (err) {
            toast.error(err, { theme: "colored" });
            return;
          }
          if (removeKey) {
            setGeminiKey("");
          }
          toast.success(res?.message, { theme: "colored" });
          checkAIWriterUsageResumption();
        }
      );
      setIsSaveKeyGemini(false);
      return;
    }
    if (val == "CLAUDE") {
      setIsSaveKeyClaude(true);
      if (!claudeKey && !removeKey) {
        toast.error("Please enter Claude api key", { theme: "colored" });
        setLoading(false);
        return;
      }
      APIService.saveApiKey(
        workSpace["_id"],
        "claudeAIKey",
        claudeKey,
        removeKey,
        (res, err) => {
          setLoading(false);
          if (err) {
            setIsSaveKeyClaude(false);
            toast.error(err, { theme: "colored" });
            return;
          }
          if (removeKey) {
            setClaudeKey("");
          }
          toast.success(res?.message, { theme: "colored" });
          checkAIWriterUsageResumption();
        }
      );
      return;
    }
    if (val == "OpenAI") {
      setIsSaveKeyOpenAI(true);
      if (!openaiKey && !removeKey) {
        toast.error("Please enter OpenAI api key", { theme: "colored" });
        setLoading(false);
        return;
      }
      APIService.saveApiKey(
        workSpace["_id"],
        "openAIKey",
        openaiKey,
        removeKey,
        (res, err) => {
          setLoading(false);
          if (err) {
            setIsSaveKeyOpenAI(false);
            toast.error(err, { theme: "colored" });
            return;
          }
          if (removeKey) {
            setOpenAIKey("");
          }
          toast.success(res?.message, { theme: "colored" });
          checkAIWriterUsageResumption();
        }
      );
    }
  };

  return (
    <>
      <Card
        // show={isApiKeyModel}
        // centered
        style={{
          width: "60%",
        }}
      >
        <Card.Header closeButton>
          <Card.Title style={{ marginLeft: "8.5rem" }}>
            🔑 Manage your API Keys
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="api-key-input">
            <p>
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  display: "inline-block",
                }}
              >
                <img src={openAIIcon} alt="copy" height="25px" width="25px" />
              </div>
              &nbsp;OpenAI:
            </p>
            {apiKey?.openAIKey ? (
              <Form.Control
                type="password"
                defaultValue={apiKey?.openAIKey}
                onChange={(e) => setOpenAIKey(e.target.value)}
              />
            ) : (
              <Form.Control
                type="text"
                value={openaiKey}
                onChange={(e) => setOpenAIKey(e.target.value)}
              />
            )}
            &nbsp;
            <Button onClick={() => handleSaveKey("OpenAI")} disabled={loading}>
              Update
            </Button>
            &nbsp;
            {/* <Button
              style={{
                backgroundColor: "#b20000",
              }}
              variant={"danger"}
              onClick={() => handleSaveKey("OpenAI", true)}
              disabled={loading}
            >
              Remove
            </Button> */}
            <Flex
              className={"gap-1 text-warning"}
              alignItems={"center"}
              justifyContent={"left"}
              style={{
                fontSize: 20,
                width: "fit-content",
              }}
            >
              <span
                style={{
                  fontSize: 20,
                  cursor: "pointer",
                  position: "relative",
                }}
                className="remove-span"
                onClick={() => handleSaveKey("OpenAI", true)}
              >
                Remove
              </span>
            </Flex>
          </div>
          <div className="api-key-input">
            <p>
              <div
                style={{
                  width: "40px",
                  height: "40px",
                  display: "inline-block",
                  marginLeft: "-0.5rem",
                }}
              >
                <img
                  src={geminiLogo}
                  width="40"
                  height="40"
                  class="object-cover w-full"
                />
              </div>
              &nbsp;Gemini:
            </p>
            {apiKey?.geminiKey ? (
              <Form.Control
                type="password"
                defaultValue={apiKey?.geminiKey}
                onChange={(e) => setGeminiKey(e.target.value)}
              />
            ) : (
              <Form.Control
                type="text"
                value={geminiKey}
                onChange={(e) => setGeminiKey(e.target.value)}
              />
            )}
            &nbsp;
            <Button onClick={() => handleSaveKey("GEMINI")} disabled={loading}>
              Update
            </Button>
            &nbsp;
            {/* <Button
              style={{
                backgroundColor: "#b20000",
              }}
              variant={"danger"}
              onClick={() => handleSaveKey("GEMINI", true)}
              disabled={loading}
            >
              Remove
            </Button> */}
            <Flex
              className={"gap-1 text-warning"}
              alignItems={"center"}
              justifyContent={"left"}
              style={{
                fontSize: 20,
                width: "fit-content",
              }}
            >
              <span
                style={{
                  fontSize: 20,
                  cursor: "pointer",
                  position: "relative",
                }}
                className="remove-span"
                onClick={() => handleSaveKey("GEMINI", true)}
              >
                Remove
              </span>
            </Flex>
          </div>
          <div className="api-key-input">
            <p>
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  display: "inline-block",
                }}
              >
                <img src={claudeIcon} alt="copy" height="25px" width="25px" />
              </div>
              &nbsp;Claude:
            </p>
            {apiKey?.claudeAIKey ? (
              <Form.Control
                type="password"
                defaultValue={apiKey?.claudeAIKey}
                onChange={(e) => setClaudeKey(e.target.value)}
              />
            ) : (
              <Form.Control
                type="text"
                value={claudeKey}
                onChange={(e) => setClaudeKey(e.target.value)}
              />
            )}
            &nbsp;
            <Button onClick={() => handleSaveKey("CLAUDE")} disabled={loading}>
              Update
            </Button>
            &nbsp;
            {/* <Button
              style={{
                backgroundColor: "#b20000",
              }}
              variant={"danger"}
              onClick={() => handleSaveKey("CLAUDE", true)}
              disabled={loading}
            >
              Remove
            </Button> */}
            <Flex
              className={"gap-1 text-warning"}
              alignItems={"center"}
              justifyContent={"left"}
              style={{
                fontSize: 20,
                width: "fit-content",
              }}
            >
              <span
                style={{
                  fontSize: 20,
                  cursor: "pointer",
                  position: "relative",
                }}
                className="remove-span"
                onClick={() => handleSaveKey("CLAUDE", true)}
              >
                Remove
              </span>
            </Flex>
          </div>
        </Card.Body>
      </Card>
      <ConfirmModal
        open={openUpgradeDialog}
        title={"Action requires Upgrade"}
        message={"This feature requires that you are on a premium account"}
        confirmText={"UPGRADE"}
        cancelText={"CANCEL"}
        onCancel={() => {
          setOpenUpgradeDialog(false);
        }}
        onConfirm={() => {
          setOpenUpgradeDialog(false);
          navigate(`${RoutePaths.BILLING_STRIPE}`);
        }}
      />
    </>
  );
};

export default APIKeyManagementCentre;
