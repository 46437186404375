import React from "react";
import { Button, Card, Dropdown, Form, FormLabel } from "react-bootstrap";
import { MediaBox, PexelsHeader } from "./styles/pexels_page";
import { SiPexels } from "react-icons/si";
import { useState } from "react";
import { toast } from "react-toastify";
import APIService from "http/api_service";
import { useDispatch, useSelector } from "react-redux";
import {
  getManuallyEditedPlatforms,
  setManuallyEditedPlatforms,
  setOpenPixelFilePickerDialog,
  setOpenPixelFilePickerDialogForWriteTab,
} from "redux/slices/postslice";
import {
  getPublishablePostTextBody,
  setPublishablePostTextBody,
} from "redux/slices/post_text_body_slice";
import {
  getPublishablePostMedia,
  setPublishablePostMedia,
} from "redux/slices/post_media_slice";
import {
  getMediaArrangement,
  setMediaArrangement,
} from "redux/slices/publishable_media_arrangement_slice";
import { PlatformIdentifier } from "constants";
import EachExternalImageDisplay from "./EachExternalImageDisplay";
import EachExternalVideosDisplay from "./EachExternalVideosDisplay";
import { VideoWrapper, Wrapper } from "./ExtrernalImageSearchDialogueStyle";
import { useEffect } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from 'react-router-dom';
import {RoutePaths} from '../../../../../constants'
import { TestButton } from "./styles/media_cloud";


const PexelsPage = (props) => {
  // console.log("🚀 ~ file: PexelsPage.jsx:23 ~ PexelsPage ~ props:", props);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const manuallyEditedPlatforms = useSelector(getManuallyEditedPlatforms);
  const postTextBody = useSelector(getPublishablePostTextBody);
  const mediaObjects = useSelector(getPublishablePostMedia);
  const media_items_arrangement = useSelector(getMediaArrangement);

  const [initialContent, setInitialContent] = useState("Car");
  const [mediaType, setMediaType] = useState("images");
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [selectedObjects, setSelectedObjects] = useState([]);

  //get pexels images
  const getPixelImages = async () => {
    if (!initialContent) {
      toast.error(`Invalid Request or empty query.`, { theme: "colored" });
    }
    try {
      setLoading(true);
      APIService.fetchPixelImages(initialContent, (response, error) => {
        if (error) {
          console.log(
            "🚀 ~ file: ExtrernalImageSearchDialogue.js:55 ~ APIService.fetchPixelImages ~ error:",
            error
          );
          setImages([]);
          setLoading(false);
          return;
        }
        let { data } = response;
        console.log(
          "🚀 ~ file: ExtrernalImageSearchDialogue.js:61 ~ APIService.fetchPixelImages ~ data:",
          data
        );
        setImages(data?.photos);
        setLoading(false);
      });
      // console.log(data);
    } catch (error) {
      setLoading(false);
    }
  };

  //get pexels images
  const getPixelVideos = async () => {
    console.log("pexel videos");
    if (!initialContent) {
      toast.error(`Invalid Request or empty query.`, { theme: "colored" });
    }
    try {
      setLoading(true);
      APIService.fetchPixelVideos(initialContent, (response, error) => {
        if (error) {
          setVideos([]);
          setLoading(false);
          return;
        }
        let { data } = response;
        console.log(
          "🚀 ~ file: ExtrernalImageSearchDialogue.js:83 ~ APIService.fetchPixelVideos ~ data:",
          data
        );
        setVideos(data?.videos);
        setLoading(false);
      });
      // console.log(data);
    } catch (error) {
      console.log(
        "🚀 ~ file: PexelsPage.jsx:96 ~ getPixelVideos ~ error:",
        error
      );
      setLoading(false);
    }
  };

  //handle click

  const handleClick = async (src, photographer, file) => {
    try {
      let newObj;
      if (!file) {
        newObj = {
          external: true,
          file: null,
          name: src,
          optimized: true,
          uploaded: true,
          url: src,
        };
      } else {
        newObj = {
          external: false,
          file: file,
          is_video: true,
          name: src,
          optimized: false,
          uploaded: false,
          url: src,
        };
      }
      if (props?.fromWriteTab) {
        let newPost = `${postTextBody}
        
  
        media by: ${photographer}
      `;
        dispatch(setPublishablePostTextBody(newPost));
        dispatch(setPublishablePostMedia([...mediaObjects, newObj]));
        // dispatch(setOpenPixelFilePickerDialogForWriteTab(false));
        navigate(RoutePaths.POSTS)
        return;
      }
      const oldData = { ...media_items_arrangement };
      if (props?.determiner === PlatformIdentifier.PINTEREST) {
        const newMedia = [newObj];
        props?.setUseableMedia(newMedia);
        const diapatchableData = {
          ...oldData,
          [props?.determiner]: {
            ...oldData?.[props?.determiner],
            media: newMedia,
          },
        };
        if (!manuallyEditedPlatforms[props?.determiner]) {
          const newData = {
            ...manuallyEditedPlatforms,
            [props?.determiner]: true,
          };
          dispatch(setManuallyEditedPlatforms(newData || {}));
        }
        dispatch(setMediaArrangement(diapatchableData));
        dispatch(setOpenPixelFilePickerDialog(false));
        return;
      }
      if (props?.determiner === PlatformIdentifier.TWITTER) {
        const twitterMediaClone = { ...media_items_arrangement };
        const threadIndex = parseInt(localStorage.getItem("twitterIndex"));
        localStorage.removeItem("twitterIndex");
        let newText;
        const newClone = twitterMediaClone?.twitter?.map((item, index) => {
          newText = `${item.text}
          
          photo by: ${photographer}
          `;
          if (index === threadIndex) {
            let medias;
            if (item?.media?.length) {
              let newMedia = [...item?.media];
              newMedia.push(newObj);
              medias = newMedia;
            } else {
              medias = [newObj];
            }
            let Obj = {
              media: medias,
              text: `${item.text} ...media by: ${photographer}`,
              mediaEdited: true,
              textEdited: true,
              // textEdited: item?.textEdited,
            };
            return Obj;
          }
          return item;
        });
        dispatch(
          setMediaArrangement({
            ...media_items_arrangement,
            twitter: newClone,
          })
        );
        props?.setChunkedTweet(newClone);
        dispatch(setOpenPixelFilePickerDialog(false));
        return;
      }
      if (props?.determiner === PlatformIdentifier.THREADS) {
        const threadsMediaClone = { ...media_items_arrangement };
        const threadIndex = parseInt(localStorage.getItem("threadsIndex"));
        localStorage.removeItem("threadsIndex");
        let newText;
        const newClone = threadsMediaClone?.threads?.map((item, index) => {
          newText = `${item.text}
          
          photo by: ${photographer}
          `;
          if (index === threadIndex) {
            let medias;
            if (item?.media?.length) {
              let newMedia = [...item?.media];
              newMedia.push(newObj);
              medias = newMedia;
            } else {
              medias = [newObj];
            }
            let Obj = {
              media: medias,
              text: `${item.text} ...media by: ${photographer}`,
              mediaEdited: true,
              textEdited: true,
              // textEdited: item?.textEdited,
            };
            return Obj;
          }
          return item;
        });
        dispatch(
          setMediaArrangement({
            ...media_items_arrangement,
            threads: newClone,
          })
        );
        props?.setChunkedTweet(newClone);
        dispatch(setOpenPixelFilePickerDialog(false));
        return;
      }
      let newText = `${oldData?.[props?.determiner]?.text}
      
      media by: ${photographer}
    `;
      const newMedia = [...oldData?.[props?.determiner]?.media, newObj];
      props?.setUseableMedia(newMedia);
      const diapatchableData = {
        ...oldData,
        [props?.determiner]: {
          ...oldData?.[props?.determiner],
          media: newMedia,
          text: newText,
        },
      };

      if (!manuallyEditedPlatforms[props?.determiner]) {
        const newData = {
          ...manuallyEditedPlatforms,
          [props?.determiner]: true,
        };
        dispatch(setManuallyEditedPlatforms(newData || {}));
      }

      dispatch(setMediaArrangement(diapatchableData));
      dispatch(setOpenPixelFilePickerDialog(false));
    } catch (error) {
      console.log(
        "🚀 ~ file: PexelsPage.jsx:206 ~ handleClick ~ error:",
        error
      );
    }
  };

  const imagesDisplay = images?.map((x, i) => {
    // console.log(x, 'as x')
    return (
      <EachExternalImageDisplay
        handleClick={handleClick}
        x={x}
        selectedObjects={selectedObjects}
        setSelectedObjects={setSelectedObjects}
        key={i}
      />
    );
  });

  const videosDisplay = videos?.map((x, i) => {
    return (
      <EachExternalVideosDisplay handleClick={handleClick} x={x} key={i} />
    );
  });
  console.log("🚀 ~ file: PexelsPage.jsx:267 ~ videosDisplay ~ videos:", videos)

  const publishPostHandler = () => {
    const cites = selectedObjects.map(item => item.cite);
    const result = cites.join(', ');
    let newPost = `${postTextBody}
        
  
    media by: ${result}
  `;
    dispatch(setPublishablePostTextBody(newPost));
    dispatch(setPublishablePostMedia([...mediaObjects, ...selectedObjects]));
    navigate(RoutePaths.POSTS)
  };

  const displayImagesArea = (
    <>
      {mediaType === "images" ? (
        <Wrapper>{imagesDisplay}</Wrapper>
      ) : (
        <VideoWrapper>{videosDisplay}</VideoWrapper>
      )}
    </>
  );

  useEffect(() => {
    mediaType !== "videos" ? getPixelImages() : getPixelVideos();
  }, [mediaType]);

  useEffect(() => {
    mediaType !== "videos" ? getPixelImages() : getPixelVideos();
  }, []);

  return (
    <>
      <Card
        style={{
          width: "100%",
          minHeight: "80vh",
        }}
      >
        <PexelsHeader>
          <SiPexels size={50} color="#008000" />
          <MediaBox>
            <TestButton
              // size="md"
              // variant={"falcon-default"}
              // // variant={'outline-secondary'}
              // className="mx-2 fs--2 px-1 fw-medium cursor-pointer"
              style={{
                borderColor: "#0065ff",
                backgroundColor: mediaType === "images" ? "#e6e6e6" : "#fff",
              }}
              onClick={() => setMediaType("images")}
            >
              <span style={{ fontSize: "13px" }}>Images</span>
            </TestButton>
            <TestButton
              // size="md"
              // variant={"falcon-default"}
              // // variant={'outline-secondary'}
              // className="mx-2 fs--2 px-1 fw-medium cursor-pointer"
              style={{
                borderColor: "#0065ff",
                backgroundColor: mediaType === "videos" ? "#e6e6e6" : "#fff",
              }}
              onClick={() => setMediaType("videos")}
            >
              <span style={{ fontSize: "13px" }}>Videos</span>
            </TestButton>
          </MediaBox>
          <Form.Control
            style={{ width: "20vw" }}
            placeholder={"Search for content here"}
            className="rounded-2 resize-none border-y-10 px-card border-300 mb-2"
            // value={initialContent}
            onChange={(e) => {
              setInitialContent(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                mediaType !== "videos" ? getPixelImages() : getPixelVideos();
              }
            }}
          />
         {selectedObjects?.length > 0 &&  <Button
              style={{
                alignSelf: "flex-end",
                backgroundColor: "#1e63fe",
                minHeight: 35
              }}
              onClick={publishPostHandler}
              size="sm"
              className={`px-3 px-sm-5`}
            >Continue</Button>}
         
        </PexelsHeader>
        
        {loading ? (
          <ReactLoading type={"bubbles"} color={"#0052cc"} />
        ) : (
          displayImagesArea
        )}
        <p></p>
      </Card>
    </>
  );
};

export default PexelsPage;
