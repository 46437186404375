import { Alert, Button, Container } from "react-bootstrap";

const RichInfoBar = (props) => {
    const {
        title,
        message,
        actionMessage,
        secondaryActionMessage,
        action,
        secondaryAction,
        variant = 'success',
        hideLine = false,
        style = {}
    } = props;
    return (
        <Container>
            <Alert
                show={true}
                variant={variant}
                style={{
                    ...style
                }}>
                <Alert.Heading>{title}</Alert.Heading>
                <p dangerouslySetInnerHTML={{ __html: message }} />
                {
                    !hideLine &&
                    <hr />
                }
                <div className="d-flex justify-content-end gap-5">
                    {secondaryActionMessage &&
                        <Button
                            onClick={() => {
                                secondaryAction();
                            }}
                            variant={`outline-${variant}`}>
                            {secondaryActionMessage}
                        </Button>}
                    {
                        actionMessage &&
                        <Button
                            onClick={() => {
                                action();
                            }}
                            variant={`outline-${variant}`}>
                            {actionMessage}
                        </Button>
                    }
                </div>
            </Alert>
        </Container>
    )
}
export default RichInfoBar;