import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Flex from 'components/common/Flex'
import SoftBadge from 'components/common/SoftBadge'
import APIService from "http/api_service"
import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux"
import { toast } from 'react-toastify'
import { getLoggedInUser } from "redux/slices/user_slice"
import {
    getActiveWorkSpace,
    getBilledYearly,
    getTeam,
    setTeam
} from "redux/slices/workspaceslice"
import { isWorkSpaceUnderAppSumo } from 'utils/user_utils'
import { isFreeWorkspace } from 'utils/workspace_utils'
import './AddOnsStyles.css'

const PlanCardAdvance = (props) => {
    const { data, priceToggle, updatableUrl, hasActiveSubscription } = props
    const { ...others } = data;
    const dispatch = useDispatch()
    const loggedInUser = useSelector(getLoggedInUser);
    const workspace = useSelector(getActiveWorkSpace);
    const numberOfValidTeamMembers = workspace?.active_plan?.Users || null
    const team = useSelector(getTeam);
    const [connectedPlatformsAccountStat, setConnectedPlatformsAccountStat] = useState();
    const billedYearly = useSelector(getBilledYearly);

    const fetchConnectedSocialsCount = () => {
        APIService.fetchConnectedSocialsCount(workspace._id, (response, error) => {
            if (response) {
                let { data } = response;
                let { connected, total } = data;
                let stats = { total, connected };
                stats["true_connected"] = connected;
                connected = Math.max(connected, 1);
                stats["completed"] = Math.max(connected, 10);
                if (typeof total !== "number") {
                    stats["max"] = connected;
                } else {
                    stats["max"] = Math.min(total, 100);
                }
                setConnectedPlatformsAccountStat(stats);
            }
        });
    };

    useEffect(() => {
        fetchConnectedSocialsCount();
    }, [])


    const fetchTeams = async () => {
        APIService.fetchTeam(workspace['_id'], (response, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let data = response['data'];
            data = data.filter(x => x.user_id);
            data = data.map((x) => {
                if (!x.user_id.first_name || !x.user_id.last_name) {
                    x.user_id.first_name = 'Postly';
                    x.user_id.last_name = 'User'
                }
                let name = `${x.user_id.first_name} ${x.user_id.last_name}`;
                x.member = name;
                if (x.user_id._id === loggedInUser['_id']) {
                    let member = x.member;
                    member = `${member}(You)`;
                    x.member = member;
                }
                x.email = x.user_id.email;
                return x;
            });
            dispatch(setTeam(data));
        });
    }

    useEffect(() => {
        fetchTeams();
    }, []);

    const getPrice = () => {
        const price = data.price
        if (!billedYearly) {
            return <><span
                style={{
                    fontSize: 25,
                    fontWeight: 700
                }}
            >
                {price?.Month}
            </span><span
                style={{
                    fontSize: 13
                }}
            >/mo</span></>
        }
        return <><span
            style={{
                fontSize: 25,
                fontWeight: 700
            }}
        >
            {price?.Year}
        </span><span
            style={{
                fontSize: 13
            }}
        >/yr</span></>
    }

    const displaySubTitle = (text) => {
        if (text === 'prepare user details') {
            return `Your users may be assigned across all your workspaces.`
        }
        if (text === 'prepare social account details') {
            return <div>
                {/* ou have used <b>{connectedPlatformsAccountStat?.connected}</b> out
                of <b>{connectedPlatformsAccountStat?.total}</b> Social Accounts.  */}
                Your social accounts may be distributed across all your workspaces.</div>
        }
        return text
    }

    const displayFeatures = data?.features?.map((feature, index) => {
        return <Flex
            key={index}
            style={{
                fontSize: 12
            }}
        >
            <FontAwesomeIcon icon={feature?.icon ?? 'check'} className="me-1 text-success" />
            <p
                style={{
                    fontSize: 12,
                    width: 180
                }}
            >
                {feature?.title}
            </p>
        </Flex>
    })

    if (data?.plan === 'AddOrRemoveSocialAccount' && (isWorkSpaceUnderAppSumo(workspace))) {
        return <></>
    }

    return (
        <Card
            className={data?.highlighted ? 'bg-[#E8F1F2]' : ''}
            style={{
                'position': 'relative'
            }}>
            <Card.Header
                className={!data?.highlighted ? 'bg-light' : ''}
                style={{
                    minWidth: 235,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <Flex
                    gap={2}
                >
                    <Flex
                        direction={'column'}
                    >
                        <h5>
                            {data?.title.replace(new RegExp('\\b' + 'Scheduler' + '\\b', 'g'), '')}
                            {
                                props?.addOns &&
                                <span
                                    style={{
                                        fontSize: 14,
                                    }}
                                >
                                    <SoftBadge
                                        pill
                                        bg="success"
                                        className="ms-2"
                                        style={{
                                            fontSize: 10,
                                        }}
                                    >
                                        {/* <FontAwesomeIcon icon={tag.icon} className="me-2" /> */}
                                        Add-Ons
                                    </SoftBadge>
                                </span>
                            }
                        </h5>
                        <p
                            style={{
                                fontSize: 12,
                                color: '#777',
                                fontWeight: 400,
                                width: 200
                            }}
                        >
                            {displaySubTitle(data?.subTitle)}
                        </p>

                        {
                            hasActiveSubscription ? <Button
                                disabled={!data?.cancelText}
                                onClick={() => {
                                    if (others.plan) {
                                        props?.handleSubscriptionCancellation()
                                    }
                                }}
                                variant={data?.cancelText ? "danger" : "success"}
                                className="d-block w-70"
                                style={{
                                    fontSize: 11,
                                    padding: 5
                                }}
                            >
                                {data?.cancelText ? data?.cancelText : 'Active'}
                            </Button> :
                                <Button
                                    onClick={() => {
                                        props?.handleSubscribe()
                                    }}
                                    variant="primary"
                                    className="d-block w-70"
                                    style={{
                                        fontSize: 11,
                                        padding: 5
                                    }}
                                >
                                    {data?.buttonText}
                                </Button>
                        }
                        {/* {
                            updatableUrl && <a
                                href={updatableUrl}
                                className={"text-decoration-none cursor-pointer mt-2"}
                            >
                                <Flex
                                    className={"gap-1"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    style={{
                                        fontSize: 11,
                                        color: data?.highlighted ? 'black' : 'black'
                                    }}
                                >
                                    <FontAwesomeIcon
                                        style={{ width: 10, height: 10 }}
                                        icon={faPen}
                                    />
                                    <span style={{
                                        fontSize: 11
                                    }} className="">Update Payment Details</span>
                                </Flex>
                            </a>
                        } */}
                    </Flex>
                    {
                        !data?.disablePrice &&
                        <Flex>
                            <div>
                                <span
                                    style={{
                                        color: data?.highlighted && 'black'
                                    }}
                                >
                                    <span
                                        style={{
                                            position: 'relative',
                                            top: '-10px',

                                        }}
                                    >
                                        {data?.currencySign}
                                    </span>
                                    {getPrice()}
                                </span>
                            </div>
                        </Flex>
                    }
                </Flex>
            </Card.Header>
            <Card.Body>
                <Flex
                    direction={'column'}
                    style={{
                        fontSize: 12
                    }}
                >
                    {displayFeatures}
                </Flex>
            </Card.Body>
        </Card>
    )
}

export default PlanCardAdvance