import { promisify } from "util";

const getImageDimension = (source, cb) => {
  let img = new Image();
  img.src = source;
  img.onload = function () {
    cb(null, {
      width: this.width,
      height: this.height
    })
  }
  return {
    width: img.width,
    height: img.height,
  };
};

export const scanImages = async (arrayToScan) => {
  const mediaStoreCopy = arrayToScan;
  let finalData = [];
  const mappingData = mediaStoreCopy?.map(async (item, index) => {
    let imageWidthAndHeight = promisify(getImageDimension);
    let { width, height } = await imageWidthAndHeight(item?.url);
    if (width < 600 || height < 315) {
      finalData = [...finalData, { platform: 'facebook', imageUrl: item?.url, isValid: false }]
      return
    }
    finalData = [...finalData, { platform: 'facebook', imageUrl: item?.url, isValid: true }]
    return
  })
  await Promise?.allSettled(mappingData);
  const returningData = arrayToScan?.map((item) => {
    const findMatchData = finalData?.find((x) => x.imageUrl === item.url);
    if (findMatchData) {
      return { ...item, isValid: findMatchData?.isValid }
    }
  })
  return returningData;
}
