import React, { useEffect, useState } from 'react';
import { munchTarget } from '../audience';
import {
    getMunchedPostTargetIds,
    setMunchedPostTargetIds
} from '../../../../../redux/slices/postslice';
import { useSelector, useDispatch } from 'react-redux';
import Avatar from 'components/common/Avatar';
import { Form } from 'react-bootstrap';

export default function AccountListItem(props) {

    const dispatch = useDispatch();
    const munchedTargetIds = [...useSelector(getMunchedPostTargetIds)];
    const { id, accountChangedListener } = props;
    const [account, setAccount] = useState(props.account);
    const [checked, setChecked] = useState(account.selected);

    useEffect(() => {
        setAccount(props.account);
        setChecked(props.account.selected);
    }, [props.account]);

    
    function shortenWord(word, N) {
        if (word.length <= N) {
            return word;
        } else {
            return word.slice(0, N) + "...";
        }
    }

    return (
        <div
            id={id}
            style={{
                display: 'flex',
                flexDirection: 'row',
                padding: 10,
                paddingLeft: 0,
                cursor: 'pointer'
            }}
            onClick={(e) => {
                let accountCopy = { ...account };
                let prevState = accountCopy.selected ?? false;
                let newState = !prevState;
                accountCopy.selected = newState;
                setAccount({ ...accountCopy });
                setChecked(newState);
                accountChangedListener({ ...accountCopy });
                let munchedTarget = munchTarget(accountCopy._id, accountCopy.identifier);
                if (!newState) {
                    let indexOfMunchedTarget = munchedTargetIds.indexOf(munchedTarget);
                    if (indexOfMunchedTarget !== -1) {
                        munchedTargetIds.splice(indexOfMunchedTarget, 1);
                    }
                } else {
                    if (!munchedTargetIds.includes(munchedTarget)) {
                        munchedTargetIds.push(munchedTarget);
                    }
                }
                dispatch(setMunchedPostTargetIds([...munchedTargetIds]));
            }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    // paddingTop: 5,
                    alignItems: 'center',
                    gap: 6
                }}>
                <Avatar src={account.user_photo} fallBackName={account.user_name} />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    <p style={{
                        fontSize: 11,
                        width: 95,
                        wordWrap: 'break-word'
                    }} className='p-0 m-0'>{shortenWord(account.user_name, 35)}</p>
                    <span
                        style={{
                            fontSize: 10,
                            color: '#90A4AE'
                        }}>account
                    </span>
                </div>
            </div>
            <div
                style={{
                    paddingTop: 10
                }}>
                <Form.Check
                    type='checkbox'
                    id={`user-${account.user_name}-check`}
                    checked={checked}
                    style={{
                        width: 14,
                        height: 14
                    }}
                />
            </div>
        </div>
    );
}