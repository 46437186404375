import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useContext } from 'react';
import AppContext from 'context/Context';

const Divider = (props, { className, children }) => {
  const { config } = useContext(AppContext);
  
  if(props?.vertical) {
    return <div style={{ width: 1, height: props?.height, backgroundColor: `${!config?.isDark ? props?.lightBg : props?.darkBg}` }} />
  }

 return <div className={classNames('w-100 position-relative text-center', className)}>
    <hr className="text-300" />
    <div className="divider-content-center">{children}</div>
  </div>
};

Divider.propTypes = {
  className: PropTypes.node,
  children: PropTypes.node
};

export default Divider;
