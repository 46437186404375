import styled from "styled-components";

export const MediaCart = styled.div`
width: 100%;
height: auto;
margin-top: 20px;
`;
export const MediaOptions = styled.div`
width: fit-content;
height: auto;
display: flex;
justify-content: flex-start;

`;
export const SelectInput = styled.input`
width: 100%;
padding: 5px;
display: none;
`;