import Divider from "components/common/Divider";
import Flex from "components/common/Flex";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import SpecificTimeAndExpirationView from "./SpecificTimeAndExpirationView";
import WhenToBeginRepeatInfoBar from "./WhenToBeginRepeatInfoBar";

const DailyRepeatOptionView = ({
    presetOptions,
    startFrom,
    commitSelectedRecurringSettings,
    commitStopTime,
    commitSpecificTime,
    ...others
}) => {

    const [selectedDailyOption, setSelectedDailyOption] = useState(presetOptions['daily_repeat_option']);
    const [selectedDailyRepeatExceptions, setselectedDailyRepeatExceptions] = useState(presetOptions['daily_repeat_exceptions']);

    const dailyRepeatOptions = [
        {
            label: '24hrs',
            value: '24hrs'
        },
        {
            label: '2days',
            value: '2days'
        },
        {
            label: '3days',
            value: '3days'
        },
        {
            label: '4days',
            value: '4days'
        },
        {
            label: '5days',
            value: '5days'
        },
        {
            label: '6days',
            value: '6days'
        }
    ];

    const dailyRepeatExceptions = [
        {
            label: 'None',
            value: 'none'
        },
        {
            label: 'Weekdays',
            value: 'exempt_weekdays'
        },
        {
            label: 'Weekends',
            value: 'exempt_weekends'
        }
    ];

    return (
        <Flex direction={'column'} className={'gap-4 mb-2'}>
            <Row alignItems={'center'}>
                <Form.Group as={Col} lg={6} className={'mt-3'}>
                    <Form.Label>Repeat Every</Form.Label>
                    <Form.Select
                        value={selectedDailyOption}
                        onChange={(e) => {
                            let value = e.target.value;
                            setSelectedDailyOption(value);
                            commitSelectedRecurringSettings({
                                daily_repeat_option: value,
                                daily_repeat_exceptions: selectedDailyRepeatExceptions,
                                specific_time_to_repeat: presetOptions?.specific_time_to_repeat ?? new Date(),
                                stop_time: presetOptions?.stop_time ?? "never"
                            })
                        }}
                    >
                        <option hidden>Pick an option</option>
                        {
                            dailyRepeatOptions.map((x) => {
                                return (<option value={x.value}>{x.label}</option>)
                            })
                        }
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col} lg={6} className={'mt-3'}>
                    <Form.Label>
                        Except on
                    </Form.Label>
                    <Form.Select
                        value={selectedDailyRepeatExceptions}
                        onChange={(e) => {
                            let value = e.target.value;
                            setselectedDailyRepeatExceptions(value);
                            commitSelectedRecurringSettings({
                                daily_repeat_option: selectedDailyOption,
                                daily_repeat_exceptions: value,
                                specific_time_to_repeat: presetOptions?.specific_time_to_repeat ?? new Date(),
                                stop_time: presetOptions?.stop_time ?? "never"
                            })
                        }}>
                        <option hidden>Pick an option</option>
                        {
                            dailyRepeatExceptions.map((x) => {
                                return (<option value={x.value}>{x.label}</option>)
                            })
                        }
                    </Form.Select>
                </Form.Group>
            </Row>
            <SpecificTimeAndExpirationView
                presetOptions={presetOptions}
                commitStopTime={commitStopTime}
                commitSpecificTime={commitSpecificTime}
            />
            {
                selectedDailyOption &&
                <WhenToBeginRepeatInfoBar
                    startFrom={startFrom}
                    feedBackData={{
                        daily_repeat_option: selectedDailyOption,
                        daily_repeat_exceptions: selectedDailyRepeatExceptions,
                        specific_time_to_repeat: presetOptions?.specific_time_to_repeat ?? new Date(),
                        stop_time: presetOptions?.stop_time ?? "never"
                    }}
                    {...others}
                />
            }
            <Divider />
        </Flex>
    )
}
export default DailyRepeatOptionView;