import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularButton from "components/common/circularbutton";
import Flex from "components/common/Flex";
import APIService from "../../../../http/api_service";
import { useState } from "react";
import {
    Button, Form, Modal,
    OverlayTrigger,
    Spinner, Tooltip
} from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { getPostCategories, getSelectedPostCategory, setPostCategories, setSelectedPostCategory } from "redux/slices/postslice";
import NewPostCategoryForm from "./NewPostCategoryForm";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";

const PostCategoryList = ({ initialValue, modalClose }) => {

    const workSpace = useSelector(getActiveWorkSpace);
    const dispatch = useDispatch();
    const postCategories = useSelector(getPostCategories);
    const [openNewCategoryBox, setOpenNewCategoryBox] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(initialValue?.name);
    const [openDeleteCategoryPrompt, setOpenDeleteCategoryPrompt] = useState(false);
    const [canDisplayDeletePrompt, setCanDisplayDeletePrompt] = useState(true);
    const [categoryToDelete, setCategoryToDelete] = useState();
    const [categoriesUnderDelete, setCategoriesUnderDelete] = useState({});
    const [clearingAllCategories, setClearingAllCategories] = useState(false);
    const realSelection = useSelector(getSelectedPostCategory);

    const deleteCategory = (category) => {
        if (categoriesUnderDelete[category._id]) {
            return;
        }
        setCategoriesUnderDelete({
            ...categoriesUnderDelete,
            [category._id]: true
        })
        APIService.deletePostCategory(workSpace._id, category._id, (response, error) => {
            setCategoriesUnderDelete((prevCategories) => {
                delete prevCategories[category._id];
                return { ...prevCategories };
            });
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { message } = response;
            toast.success(message, { theme: 'colored' });
            let categoriesCopy = [...postCategories];
            let categoryCopy = categoriesCopy.find(x => x._id === category._id);
            let indexOfCategory = categoriesCopy.indexOf(categoryCopy);
            categoriesCopy.splice(indexOfCategory, 1);
            dispatch(setPostCategories(categoriesCopy));
            if (categoryCopy.name === realSelection?.name) {
                dispatch(setSelectedPostCategory({ name: "Campaign" }));
            }
        });
    }

    const PostCategoryListItem = ({ name, _id, ...others }) => {
        return (
            <Flex alignItems={'center'}>
                <Form.Check
                    id={`${name}-checkbox`}
                    type="radio"
                    name='campaigns'
                    label={name}
                    value={name}
                    checked={selectedCategory === name}
                    className={'mt-2'}
                />
                <div
                    style={{
                        flex: 1,
                        marginRight: 50
                    }}></div>
                <OverlayTrigger
                    placement='top'
                    overlay={
                        <Tooltip>
                            {categoriesUnderDelete[_id] ? "Deleting..." : "Delete this campaign"}
                        </Tooltip>
                    }>
                    <span>
                        <CircularButton onClick={() => {
                            if (canDisplayDeletePrompt) {
                                setCategoryToDelete({ name, _id, ...others });
                                setOpenDeleteCategoryPrompt(true);
                            } else {
                                deleteCategory({ name, _id, ...others });
                            }
                        }}>
                            {
                                clearingAllCategories || categoriesUnderDelete[_id]
                                    ? <Spinner
                                        animation='border'
                                        size={'sm'}
                                    />
                                    :
                                    <FontAwesomeIcon className='text-danger' icon={faTrash} />
                            }
                        </CircularButton>
                    </span>
                </OverlayTrigger>
            </Flex>
        )
    }

    return (
        <Flex direction={'column'} className={'gap-5'}>
            <Flex direction={openNewCategoryBox ? 'column' : 'row'} className={`gap-${openNewCategoryBox ? 4 : 5}`}>
                <h4>{!openNewCategoryBox ? "Pick a Campaign" : ""}</h4>
                {
                    !openNewCategoryBox &&
                    <Button
                        variant={'outline-info'}
                        size="sm"
                        onClick={() => {
                            setOpenNewCategoryBox(true);
                        }}>Add New
                    </Button>
                }
                {
                    openNewCategoryBox &&
                    <Flex className={'gap-5'} alignItems={'center'}>
                        <Button
                            onClick={() => {
                                setOpenNewCategoryBox(false);
                            }} variant="outline-warning" size="sm" style={{ alignSelf: 'flex-end' }}>CLOSE</Button>
                        <NewPostCategoryForm
                            title={"Add Campaign"}
                            doneCallBack={() => {
                                setOpenNewCategoryBox(false);
                            }}
                        />
                    </Flex>
                }
            </Flex>
            <Form
                style={{
                    overflowY: 'auto',
                    maxHeight: 400
                }}
                onChange={(e) => {
                    setSelectedCategory(e.target.value);
                }}>
                {
                    postCategories.map((x) => {
                        return (<PostCategoryListItem {...x} />)
                    })
                }
            </Form>
            {
                selectedCategory &&
                <Button
                    onClick={() => {
                        let realCategory = postCategories.find(x => x.name === selectedCategory);
                        dispatch(setSelectedPostCategory(realCategory));
                        modalClose();
                    }}>Continue
                </Button>
            }
            <Modal
                show={openDeleteCategoryPrompt}
                centered={false}
                onHide={() => setOpenDeleteCategoryPrompt(false)}
                onExit={() => setOpenDeleteCategoryPrompt(false)}
            >
                <Modal.Header>
                    <Modal.Title>Delete Campaign?</Modal.Title>
                    <Modal.Body>
                        Are you certain about deleting this campaign from this workspace?
                    </Modal.Body>
                </Modal.Header>
                <Modal.Footer>
                    <Flex
                        style={{ width: '100%' }}
                        className={'gap-2'}
                        alignItems={'center'}>
                        <Form.Check
                            className='p-0 m-0 ms-3 cursor-pointer'
                            type='checkbox'
                            id='deletePrompt'
                            name='deletePrompt'
                            defaultChecked={false}
                            checked={!canDisplayDeletePrompt}
                            onChange={(e) => {
                                setCanDisplayDeletePrompt(!e.target.checked);
                            }}
                            label={"Don't show this again"}
                        />
                        <div style={{ flex: 1 }}></div>
                        <Button
                            onClick={() => setOpenDeleteCategoryPrompt(false)}>NO</Button>
                        <Button
                            onClick={() => {
                                setOpenDeleteCategoryPrompt(false);
                                deleteCategory(categoryToDelete);
                            }}>YES, DELETE
                        </Button>
                    </Flex>
                </Modal.Footer>
            </Modal>
        </Flex>
    )

}
export default PostCategoryList;