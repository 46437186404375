import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery';
import React, { useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import {
    useSelector,
} from 'react-redux';
import {
    getUploadableBulkContent,
} from '../../../../../redux/slices/postslice';

export default function ImageContentPreview(props) {

    let { rowIndex = 0 } = props;
    const uploadableBulkContent = useSelector(getUploadableBulkContent);
    const bulkContentRow = uploadableBulkContent[rowIndex] ?? {};
    const [imageUrl] = useState(bulkContentRow.image_url);

    return (
        <div
            style={{
                width: 200,
                height: 200
            }}>
            <FalconLightBoxGallery images={imageUrl}>
                {
                    setImgIndex => (
                        <Row className="g-2">
                            {
                                imageUrl.map((image, index) => {
                                    return (
                                        <Col xs={4}>
                                            <Image
                                                src={image}
                                                fluid
                                                rounded
                                                style={{
                                                    cursor: 'pointer',
                                                    height: '100%',
                                                    objectFit: 'cover'
                                                }}
                                                onClick={() => setImgIndex(index)}
                                            />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    )
                }
            </FalconLightBoxGallery>
        </div>
    );

}