import classNames from "classnames";
import Flex from "components/common/Flex";
import PropTypes from "prop-types";
import React from "react";
import { Card, Nav, Spinner, Tab } from "react-bootstrap";
import SimpleBarReact from "simplebar-react";
import { numberWithCommasFormat } from "utils/analytics_utils";
import AudienceChart from "./AudienceChart";
import { PlatformIdentifier } from "../../../../constants";

const TabTitle = ({ title, value }) => (
  <div className="p-2 pe-4 text-start cursor-pointer">
    <h6 className="text-800 fs--2 text-nowrap">{title}</h6>
    <h5 className="text-800" style={{ fontSize: '14px' }}>{numberWithCommasFormat(value) ?? 0}</h5>
  </div>
);

const Audience = (props) => {
  const {
    chartData,
    className,
    loading,
    values,
    identifier
  } = props;

  const activeKey = identifier === PlatformIdentifier.YOUTUBE ? "Views" : "Impressions";
  return (
    <Card className={classNames(className, "overflow-hidden")}>
      <Tab.Container id="audience-tab" defaultActiveKey={activeKey}>
        <SimpleBarReact>
          <Card.Header className="p-1">
            <Nav className="nav-tabs border-0 flex-nowrap chart-tab">
              {
                chartData && chartData.length > 0 && chartData?.map(({ title }) => (
                  <Nav.Item key={title}>
                    <Nav.Link className="mb-0" eventKey={title}>
                      <TabTitle title={title} value={values[title.toLowerCase().replace(/\s/g, '_')] ?? 0} />
                    </Nav.Link>
                  </Nav.Item>
                ))
              }
            </Nav>
          </Card.Header>
        </SimpleBarReact>

        {loading && (
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            className={"vh-50"}
          >
            <Spinner animation="border" size={40} />
          </Flex>
        )}

        <Card.Body style={{ backgroundColor: "#fff" }}>
          <Tab.Content>
            <div>
              {!loading && chartData?.map(({ title, value }) => (
                <Tab.Pane unmountOnExit eventKey={title} key={title}>
                  <AudienceChart data={value} chartTitle={title} />
                </Tab.Pane>
              ))}
            </div>
          </Tab.Content>
        </Card.Body>
      </Tab.Container>
    </Card >
  );
};

TabTitle.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  percentage: PropTypes.string.isRequired,
  progress: PropTypes.bool,
};

Audience.propTypes = {
  chartData: PropTypes.shape({
    users: PropTypes.arrayOf(PropTypes.array),
    sessions: PropTypes.arrayOf(PropTypes.array),
    rate: PropTypes.arrayOf(PropTypes.array),
    duration: PropTypes.arrayOf(PropTypes.array),
  }).isRequired,
  className: PropTypes.string.isRequired,
};

export default Audience;
