import { PlatformIdentifier } from "../constants";
import Color from 'color';
import { extractLinksFromTweet } from "./url";
import twitterText from 'twitter-text';

const refinedStati = (originalTweet, statusParts) => {
    let allWords = originalTweet.split(" ");
    let lastFilteredOutWord = [];
    for (let i = 0; i < statusParts.length; i++) {
        let status = statusParts[i];
        let wordsInStatus = `${lastFilteredOutWord.join("")}${status}`.split(" ");
        let validWords = wordsInStatus.filter(x => allWords.includes(x));
        statusParts[i] = validWords.join(" ");
        let invalidWord = wordsInStatus.filter(x => !allWords.includes(x));
        lastFilteredOutWord = invalidWord;
    }
    statusParts.push(lastFilteredOutWord);
    statusParts = statusParts.flat();
    return statusParts.filter(x => x.length);
}

const endsWithPunctuation = (text) => {
    var punctuationRegex = /[.!?]$/;
    return punctuationRegex.test(text);
}

export const chunkTweetIntoThreads = (tweet, _breakPoint = 279) => {
    const breakPoint = _breakPoint;
    let statusParts = [];

    const links = extractLinksFromTweet(tweet, true); // Extract links before splitting

    let tweetWithoutLinks = tweet;
    for (let i = 0; i < links.length; i++) {
        tweetWithoutLinks = tweetWithoutLinks.replace(links[i], `LINK_PLACEHOLDER_${i}`);
    }

    let addedSimulatedExclamationMark = false;

    const hasSentenceEndingPunctuation = endsWithPunctuation(tweetWithoutLinks);
    if (!hasSentenceEndingPunctuation) {
        tweetWithoutLinks = tweetWithoutLinks + '!'; // Add a simulated exclamation mark
        addedSimulatedExclamationMark = true;
    }

    const sentences = tweetWithoutLinks.match(/[^.!?]+[.!?]+/g);

    if (sentences === null) {
        // No sentences found, break by words
        return chunkByWords(tweet, breakPoint, links);
    }

    if (sentences.length === 1) {
        return chunkByWords(tweet, breakPoint, links);
    }

    let currentThread = sentences[0];

    for (let i = 1; i < sentences.length; i++) {
        const potentialThread = `${currentThread} ${sentences[i]}`;
        // const parsedTweet = twitterText.parseTweet(potentialThread);
        // console.log("🚀 ~ chunkTweetIntoThreads ~ parsedTweet:", parsedTweet)
        // Check if the potentialThread contains a link and the sentence doesn't exceed the limit
        if (!containsLink(potentialThread) && potentialThread.length <= breakPoint) {
            currentThread = potentialThread;
        } else {
            statusParts.push(currentThread);
            currentThread = sentences[i];
        }
    }

    statusParts.push(currentThread);

    if (addedSimulatedExclamationMark) {
        statusParts[statusParts.length - 1] = statusParts[statusParts.length - 1].replace('!', '');
    }


    for (let i = 0; i < statusParts.length; i++) {
        for (let j = 0; j < links.length; j++) {
            statusParts[i] = statusParts[i].replace(`LINK_PLACEHOLDER_${j}`, links[j]);
        }
    }

    return statusParts;
}

function _extractLinksFromTweet(tweet) {
    const linkPattern = /https?:\/\/\S+/gi;
    return tweet.match(linkPattern) || [];
}

function containsLink(text) {
    const linkPattern = /https?:\/\/\S+/gi;
    return linkPattern.test(text);
}

function chunkByWords(text, breakPoint, links) {
    const words = text.split(/\s+/);
    let statusParts = [];
    let currentThread = words[0];

    for (let i = 1; i < words.length; i++) {
        const potentialThread = `${currentThread} ${words[i]}`;
        // const parsedTweet = twitterText.parseTweet(potentialThread);
        // console.log("🚀 ~ chunkTweetIntoThreads ~ parsedTweet 2:", parsedTweet)

        if (potentialThread.length <= breakPoint) {
            currentThread = potentialThread;
        } else {
            statusParts.push(currentThread);
            currentThread = words[i];
        }
    }

    statusParts.push(currentThread); // Add the last thread

    // Restore links in the threads
    for (let i = 0; i < statusParts.length; i++) {
        for (let j = 0; j < links.length; j++) {
            statusParts[i] = statusParts[i].replace(`LINK_PLACEHOLDER_${j}`, links[j]);
        }
    }

    return statusParts;
}

export const getTagForPages = (platform, pluralize = true) => {
    let tag = `page${pluralize ? 's' : ''}`
    if (platform.identifier === PlatformIdentifier.YOUTUBE) {
        tag = `channel${pluralize ? 's' : ''}`;
    } else if (platform.identifier === PlatformIdentifier.PINTEREST) {
        tag = `board${pluralize ? 's' : ''}`;
    } else if (platform.identifier === PlatformIdentifier.REDDIT) {
        tag = `subreddit${pluralize ? 's' : ''}`;
    } else if (platform.identifier === PlatformIdentifier.TELEGRAM) {
        tag = `group(s) & channel(s)`;
    } else if (platform.identifier === PlatformIdentifier.FACEBOOK) {
        tag = `page(s)`;
    } else if (platform.identifier === PlatformIdentifier.GOOGLE_MY_BUSINESS) {
        tag = `location${pluralize ? '(s)' : ''}`;
    } else if (platform.identifier === PlatformIdentifier.TWITTER) {
        tag = `account${pluralize ? '(s)' : ''}`;
    } else if (platform.identifier === PlatformIdentifier.WHATSAPP) {
        tag = `account${pluralize ? '(s)' : ''}`;
    }
    return tag;
}

export const formatTrendVolume = n => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
}

export const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
export function simulateMouseClick(element) {
    if (element) {
        mouseClickEvents.forEach(mouseEventType =>
            element.dispatchEvent(
                new MouseEvent(mouseEventType, {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    buttons: 1
                })
            )
        );
    }
}

export const svgWhiteList = (existingPlatform) => {
    let whiteList = [PlatformIdentifier.GOOGLE_MY_BUSINESS];
    return whiteList.includes(existingPlatform?.identifier);
}

export const mixin = {
    darken: (colorValue, amount) =>
        Color(colorValue)
            .darken(amount)
            .string(),
    lighten: (colorValue, amount) =>
        Color(colorValue)
            .lighten(amount)
            .string(),
    rgba: (colorValue, opacity) =>
        Color(colorValue)
            .alpha(opacity)
            .string()
};

export const ordinalSuffixOf = (i) => {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}