import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findPlatformByIdentifier } from "components/app/platforms";
import Flex from "components/common/Flex";
import React from "react";
import {
    Alert,
    Button,
    OverlayTrigger,
    Popover,
    Tooltip
} from "react-bootstrap";

const SelectedItemChip = (props) => {

    const { name, identifier, accounts } = props;
    const platform = findPlatformByIdentifier(identifier);
    let accountsCopy = JSON.parse(JSON.stringify(accounts));

    const flattenAccounts = () => {
        let allAccounts = [];
        for (let account of accountsCopy) {
            if (account?.connected_pages_and_groups) {
                for (let subItem of account.connected_pages_and_groups) {
                    if (subItem.selected) {
                        allAccounts.push({
                            name: subItem.name,
                            photo: subItem.picture
                        })
                    }
                }
            } else {
                if (account.selected) {
                    allAccounts.push({
                        name: account.user_name,
                        photo: account.user_photo
                    })
                }
            }
        }
        return allAccounts;
    }

    let subPages = flattenAccounts();

    const realSelectionsPopOver = (
        <Popover>
            <Popover.Body>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2
                    }}>
                    {
                        subPages.map((x) => {
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2
                                    }}>
                                    <div>
                                        <img
                                            alt=""
                                            size="sm"
                                            src={x.photo}
                                            fallBackName={x.name}
                                            style={{
                                                width: 30,
                                                height: 30,
                                                borderRadius: '100%',
                                                padding: 2
                                            }}
                                        />
                                    </div>
                                    <span style={{
                                        flex: 1,
                                        padding: 10
                                    }}>{x.name}</span>
                                    <FontAwesomeIcon icon={faCheckCircle} className={'text-success'} />
                                </div>
                            )
                        })
                    }
                </div>
            </Popover.Body>
        </Popover>
    )

    return (
        <OverlayTrigger
            trigger={'hover'}
            placement='bottom'
            overlay={realSelectionsPopOver}>
            {/* <Alert
                style={{
                    borderRadius: 10,
                    cursor: 'pointer',
                    display: 'flex',
                    padding: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 3,
                    paddingBottom: 3,
                    margin: 0,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}> */}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 5,
                    color: platform?.color
                }}>
                {platform.icon ? (
                    <FontAwesomeIcon
                        icon={platform.icon}
                        style={{
                            width: 33,
                            height: 33,
                            fontSize: 14,
                            borderRadius: 100,
                            padding: 2,
                        }}
                    />
                ) : (
                    <img
                        src={platform?.svg}
                        alt=''
                        style={{
                            width: 30,
                            height: 30,
                        }}
                    />
                )}
                {/* <span
                        style={{
                            fontSize: 14,
                            fontWeight: 500,
                            textAlign: 'center',
                            whiteSpace: 'nowrap'
                        }}
                    >{platform.name}
                    </span> */}
            </div>
            {/* </Alert> */}
        </OverlayTrigger>
    )
}

const SelectedTargetAudienceContainer = (props) => {
    const { selectedPlatforms = [],
        placeholder, canAddMore = true, ...others } = props;
    return (
        <Flex
            id={'selectedPlatformsContainer'}
            wrap={'wrap'}
            className={'gap-2'} alignItems={'center'}
            {...props}
        >
            {
                selectedPlatforms.map((platform) => (<SelectedItemChip {...platform} />))
            }
            {/* {
                selectedPlatforms.length > 0 && selectedPlatforms.length < 4 && canAddMore &&
                <span
                    style={{
                        cursor: 'pointer',
                        fontSize: 12
                    }}>Click to add more platforms
                </span>
            } */}
            {
                placeholder && selectedPlatforms.length < 1 &&
                <Button
                    variant="outline-info"
                    style={{ width: '100%' }}
                    className={"mt-2"}
                    {...others}>{placeholder}
                </Button>
            }
        </Flex>
    )
}

export default SelectedTargetAudienceContainer;