import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: '',
}

export const postTextBodySlice = createSlice({
    name: 'post_text_body',
    initialState,
    reducers: {
        setPublishablePostTextBody: (state, action) => {
            state.value = action.payload;
        }
    }
});

export let getPublishablePostTextBody = (state) => state.post_text_body.value;
export let {
    setPublishablePostTextBody
} = postTextBodySlice.actions;
export default postTextBodySlice.reducer;