import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

export default function SubRedditFlairSelector(props) {

    const [flair, setFlair] = useState(props.flair);
    const [selectOption, setSelectOption] = useState(props.selectedFlair);
    const { flairSelectionChangeListener } = props;
    const [flairOptions, setFlairOptions] = useState();

    useEffect(() => {
        setFlair(props.flair);
    }, [props.flair]);

    useEffect(() => {
        setFlairOptions(flair[1].map(x => {
            return {
                value: x.text,
                label: x.text,
                id: x.id,
                subreddit: flair[0]
            }
        }));
    }, [flair]);

    return (
        <Form.Group>
            <Form.Label>{flair[0]}</Form.Label>
            {
                flairOptions &&
                <Form.Select
                    label={'Select Flair'}
                    placeholder={'Select Flair'}
                    prompt={'Select Flair'}
                    searchLabel={'Search Flair'}
                    value={selectOption}
                    onChange={(e) => {
                        let selection = e.target.value;
                        setSelectOption(selection);
                        if (flairSelectionChangeListener) {
                            flairSelectionChangeListener(flairOptions.find(entry => entry.value.toLowerCase() === selection.toLowerCase()));
                        }
                    }}
                >
                    {
                        flairOptions.map((x) => {
                            return (<option value={x.value}>{x.label}</option>)
                        })
                    }
                </Form.Select>
            }
        </Form.Group>
    );
}