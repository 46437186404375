import APIService from '../http/api_service';
import { getLoggedInUserFromLocalStorage } from './user_utils';

export default class WorkspaceStats {

    static isLTD(activePlan) {
        let oldIdentifiers = ["appsumo", "pitch"];
        let activePlanName = activePlan.Key ?? activePlan.Name;
        let ltd = oldIdentifiers.find(x => activePlanName.toLowerCase().includes(x));
        if (ltd) {
            return true;
        }
        return activePlan.LTD ?? false;
    }

    static async getSync() {
        let res = await APIService.fetchLatestMeSync();
        let loggedInUser = res?.data ?? getLoggedInUserFromLocalStorage();
        let totalCreatables = 0;
        let total = 0;
        let created = 0;
        let toBeCreated = 0;
        let invited = 0;
        let archived = 0;
        if (loggedInUser) {
            let activePlan = loggedInUser.active_plan ?? {};
            if (activePlan.Workspace) {
                if (activePlan.Workspace === 0) {
                    totalCreatables = 1;
                } else {
                    totalCreatables = activePlan.Workspace;
                }
            } else {
                totalCreatables = 0;
            }
            if (WorkspaceStats.isLTD(activePlan)) {
                totalCreatables += (loggedInUser.creatable_workspace_slots ?? 0);
            }
            try {
                let { data } = await APIService.fetchUserWorkSpacesSync();
                created = data.filter(x => x?.workspace !== null && x?.workspace?.owner_id === loggedInUser._id).length;
                invited = data.filter(x => x?.workspace !== null && x?.workspace?.owner_id !== loggedInUser._id).length;
                archived = data.filter(x => x?.workspace !== null && x?.workspace?.archived).length;
                if (typeof totalCreatables !== 'string') {
                    total = totalCreatables + invited;
                    toBeCreated = totalCreatables - created;
                } else {
                    total = totalCreatables;
                    toBeCreated = totalCreatables;
                }
            } catch (e) { }
        }
        return { created, toBeCreated, totalCreatables, total, invited, archived };
    }

}