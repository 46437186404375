import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setOpenPostDetailsDialog } from 'redux/slices/postslice';

const Error500 = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOpenPostDetailsDialog({ open: false }));
  }, []);

  return (
    <Card className="text-center h-100">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">500</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          Whoops, something went wrong!
        </p>
        <hr />
        <p>
          Try refreshing the page, or going back and attempting the action again.
          If this problem persists,
          <a href="mailto:team@postly.ai" className="ms-1">
            contact us team@postly.ai
          </a>
          .
        </p>
      </Card.Body>
    </Card>
  )
}

export default Error500;
