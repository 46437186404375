import { createSlice } from '@reduxjs/toolkit';
import StringUtils from "../../utils/string";
import { getActiveWorkspaceFromLocalStorage } from 'utils/workspace_utils';

const activeWorkSpace = getActiveWorkspaceFromLocalStorage();

const workSpaceSelectedTimeZone = () => {
    if (activeWorkSpace?.default_time_zone_for_publishing) {
        if (typeof activeWorkSpace?.default_time_zone_for_publishing !== 'string') {
            return Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        return activeWorkSpace?.default_time_zone_for_publishing;
    }
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

const initialState = {
    value: 'all',
    postSearchTerm: "",
    filters: {},
    contentPublishingInProgress: false,
    timeToPostContent: new Date(),
    timeZoneToPostContent: workSpaceSelectedTimeZone(),
    post_group_identifier: StringUtils.generateRandomString(64),
    revertChanges: false,
    draftCount: 0,
    composerFocused: false,
    editablePost: null,
    currentDraft: null,
    startedCollectingDraftData: false,
    ignoreDraftUpdate: false,
    aboutRepublishingPost: false,
    cachedPosts: [],
    forceFetchPosts: false,
    canExpandPublisherActions: false,
    openPostDetailsDialog: { open: false },
    deletedPost: null,
    persistedAccounts: null,
    postNow: true,
    munchedPostTargetIds: [],
    updatedPlatform: {},
    advanced_content_creation_options: {},
    youTubeVideoTitle: null,
    youtubeVideoComment: null,
    youTubeVideoThumbnail: null,
    youTubeVideoPrivacy: 'public',
    redditTitle: null,
    pinterestTitle: null,
    wordpressTitle: null,
    wordpressSlug: null,
    wordpressFeaturedImageThumbnail: null,
    wordpressPostTags: [],
    wordpressPostPassword: null,
    wordpressPostVisibility: "public",
    wordpressSiteUrl: null,
    wordpressCategories: {},
    wordpressFormat: null,
    pinterestDestinationLink: null,
    pinterestAltText: null,
    pinterestVideoThumbnail: null,
    tiktokVideoTitle: null,
    tiktokAllowComments: false,
    stitchAllowTiktok: false,
    tiktokSelectedViewSetting: "public to everyone",
    tiktokAllowDuet: false,
    tiktokDiscloseVideoContent: false,
    tiktokCommercialForYourBrand: false,
    tiktokCommercialForBrandedContent: false,
    twitterMediaAltText: null,
    openExternalFilePickerDialog: false,
    openPixelFilePickerDialog: false,
    openPixelFilePickerDialogForWriteTab: false,
    googleDriveManager: {
        connected: false,
        access_token: null,
        promptConnect: false,
        fromPreviews: false,
        docs: null,
        openPicker: false,
        fetchLoading: false,
        fileType: null,
        folders: [],
        selectedFolder: null,
        selectedFolderId: null,
        files: [],
        folderNextPageToken: null,
        aboutUploadingToPostlyCloudFromGoogleDrive: false,

    },
    externalFilePickerDialogOptions: {},
    externalMediaFiles: [],
    openThumbnailPreviewDialog: false,
    thumbnailPreviewData: null,
    addNewPollChoice: false,
    pollChoiceValue: {},
    choiceData: {},
    publishedPollIdentifier: null,
    notifyYouTubeSubscribers: true,
    uploadingBulkContent: false,
    openComposer: false,
    displayGetStarted: false,
    instagramVideoThumbnail: null,
    refreshPopularDays: true,
    refreshTrends: true,
    updatableDay: null,
    exactTimeForRecurringPost: new Date(),
    failedPostData: null,
    contentUploadingProgress: null,
    loadingPostData: false,
    calendarPostTime: null,
    refreshCalendar: null,
    selectedPlatforms: [],
    showPreview: false,
    promptedMissingTextAlert: false,
    userChooseToIgnoreMissingTextAlert: false,
    firstAltAddedForTwitter: false,
    fullScreenComposer: false,
    displayWeekNumbers: false,
    manuallyEditedPlatforms: {},
    selectingPhotoFromHome: false,
    shouldForceUpdateTwitterThread: false,
    unhideThreadEdit: null,
    unhideThreadTextEdit: null,
    unhideAltEdit: null,
    twitterEditedThreadTexts: [],
    mediaStore: {
        facebook: [],
        twitter: [],
        instagram: [],
        linked_in: [],
        telegram: [],
        youtube: [],
        reddit: [],
        tiktok: [],
        google_my_business: [],
        pinterest: [],
    },
    removedMedia: {},
    blockTwitterShallowUpdate: {
        response: false,
        threadCount: null,
        createdIndex: null
    },
    loggerTracker: [],
    formattedMediaForTwitter: [],
    formattedMediaForThreads: [],
    formattedMediaForReddit: [],
    twitterMediaObjectForAltMemory: [],
    canUpdateFirstTweet: 0,
    altSaveTrigger: 0,
    altSaveObject: {
        altValue: '',
        currentUrlToCompare: '',
        storedIndex: ''
    },
    canFixAllImagesStore: true,
    triggerComponentRemount: 0,
    altCancelTrigger: 0,
    mediaAddedViaSpecificPlaformUrlMethod: [],
    specificMediaPlatformTied: 'global',
    forceUpdateUseableMediaOnPlatformPreviews: [],
    notifyPreviewsOfLiveMediaChanges: 0,
    postReadyForPreviews: false,
    facebookMediaRemovalObserver: 0,
    twitterCurrentThreadIndexToAddMedia: 0,
    threadsCurrentThreadIndexToAddMedia: 0,
    altTextBackup: [],
    draftRestored: false,
    gmbPostType: "STANDARD",
    gmbEventTitle: null,
    gmbEventSchedule: null,
    gmbEventSummary: null,
    gmbCTAActionType: 'BOOK',
    gmbCTAActionUrl: null,
    gmbCouponCode: null,
    gmbRedeemUrl: null,
    gmbTermsAndConditions: null,
    publishIGVideoToFeed: true,
    publishIGVideoAsReel: false,
    publishIGVideoAsStories: false,
    publishIGImageAsStories: false,
    publishIGImageAsNormalIGPost: true,
    openMediaEditor: false,
    igVideoCoverThumbOffset: 0,
    uploadableBulkContent: [],
    firstComment: {},
    suggestPopularHashTags: false,
    hashTagsSuggested: false,
    countries: [],
    regionCode: 'US',
    youTubeVideoCategories: [],
    youTubeVideoCategoryId: undefined,
    youTubeVideoLicense: "youtube",
    youTubeVideoTags: [],
    youTubeVideoMadeForKids: false,
    youTubeVideoEmbeddable: true,
    drafts: [],
    openDraftPrompt: true,
    postCategories: [],
    selectedPostCategory: { name: "Campaign" },
    disablePostButton: false,
    userTags: [],
    singlePostType: false,
    fetchRounds: 0,
    selectAllAudience: false,
    activeBatchDeletes: {},
    repeatOptionType: 'no_repeat',
    repeatOption: {},
    selectedMediaOption: "image",
    showMediaOptions: false,
    doneChecking: false,
    shouldStartSyncingMediasOnPlatformSelection: false,
    facebookCarouselLinks: [],
    isNormalFacebookPost: true,
    isFacebookPostCarousel: false,
    isFacebookPostReel: false,
    isFacebookPostStory: false,
    justAddedSpecialCharacterToPostTextBody: false,
    documents: [],
    postlyCloudGoogleDriveUploadIsActive: false,
    mediaBank: [],
    bloggerTitle: null,
    bloggerUrl: null,
    bloggerLabels: [],
    rssFeedUserId: null,
    articleTitleSelect: null,
    rssFeedTriggered: false,
    openFeedSettings: false,
    feedSelectedPlatforms: [],
    postlyCloudPickerMediaType: null,
    postBatchData: [],
    postlyCloudUsedBytes: 'unavailable',
    postlyCloudTotalStorageLimit: 0,
    postlyCloudUserAlerts: [],
    postlyCloudMediaUrls: [],
    draftInQueue: false,
    processingDraft: false,
    draftLocalSaveToken: null,
    draftPersistentSaveToken: null,
    uploadingDraftMedia: false,
    draftContent: {},
    draftSaveState: 'saved',
    saveToDraftsButtonClicked: false,
    canStartNewPost: false,
    timeZoneToPostFeedContent: workSpaceSelectedTimeZone(),
    refreshConnectedFeed: false,
    changesFoundForDraft: false,
    triggerFeedPost: false,
    feedProcessing: false,
    feeds: {},
    makingPostFromContentPlanner: false,
    workspaceManagement: null,
    postsTypeCounter: null,
    draftCounter: null,
    failedCounter: null,
    recurringCounter: null,
    scheduledCounter: null,
    hasCalledFetchPosts: false,
    globalErrorMessage: [],
    editedPostFlag: {
        edited: false,
        count: 0
    },
    currentDisplayingPreviewPlatform: 'all',
    previewMedia: [],
    feedPublishInterval: {},
    postCategoryType: null,
    postSpecificDataVisualRequest: null,
    previousPostObjectBeforeEdit: null,
    platformFirstComment: [], 
    facebookFirstComment: null
};

export const postSlice = createSlice({
    name: 'posts',
    initialState,
    reducers: {
        setSelectAllAudience: (state, action) => {
            state.selectAllAudience = action.payload;
        },
        setSelectedPostType: (state, action) => {
            state.value = action.payload
        },
        setPostSearchTerm: (state, action) => {
            state.postSearchTerm = action.payload;
        },
        setPostsFilters: (state, action) => {
            state.filters = action.payload;
        },
        setPostGroupIdentifier: (state, action) => {
            state.post_group_identifier = action.payload;
        },
        setContentPublishingInProgress: (state, action) => {
            state.contentPublishingInProgress = action.payload;
        },
        setTimeToPostContent: (state, action) => {
            state.timeToPostContent = action.payload;
        },
        setTimeZoneToPostContent: (state, action) => {
            state.timeZoneToPostContent = action.payload;
        },
        revertChanges: (state, action) => {
            state.revertChanges = action.payload;
        },
        setDraftCount: (state, action) => {
            state.draftCount = action.payload;
        },
        setFirstAltAddedForTwitter: (state, action) => {
            state.firstAltAddedForTwitter = action.payload;
        },
        setComposerFocused: (state, action) => {
            state.composerFocused = action.payload;
        },
        setTwitterMediaObjectForAltMemory: (state, action) => {
            state.twitterMediaObjectForAltMemory = action.payload;
        },
        setBlockTwitterShallowUpdate: (state, action) => {
            state.blockTwitterShallowUpdate = action.payload;
        },
        setEditablePost: (state, action) => {
            state.editablePost = action.payload;
        },
        setCurrentDraft: (state, action) => {
            state.currentDraft = action.payload;
        },
        setStartedCollectingDraftData: (state, action) => {
            state.startedCollectingDraftData = action.payload;
        },
        setIgnoreDraftUpdate: (state, action) => {
            state.ignoreDraftUpdate = action.payload;
        },
        setAboutRepublishingPost: (state, action) => {
            state.aboutRepublishingPost = action.payload;
        },
        cachePosts: (state, action) => {
            const { payload } = action;
            state.cachedPosts = payload;
            const persistable = payload.slice(0, Math.min(20, payload.length));
            try {
                localStorage.setItem('cached_posts', JSON.stringify(persistable));
            } catch (e) {
                console.log(e);
            }
        },
        setForceFetchPosts: (state, action) => {
            state.forceFetchPosts = action.payload;
        },
        setCanExpandPublisherActions: (state, action) => {
            state.canExpandPublisherActions = action.payload;
        },
        setOpenPostDetailsDialog: (state, action) => {
            state.openPostDetailsDialog = action.payload;
        },
        setDeletedPost: (state, action) => {
            state.deletedPost = action.payload;
        },
        setPersistedAudienceAccounts: (state, action) => {
            state.persistedAccounts = action.payload;
        },
        setPostNow: (state, action) => {
            state.postNow = action.payload;
        },
        setMunchedPostTargetIds: (state, action) => {
            state.munchedPostTargetIds = action.payload;
        },
        setUpdatedPlatform: (state, action) => {
            state.updatedPlatform = action.payload;
        },
        setAdvancedContentCreationOptions: (state, action) => {
            state.advanced_content_creation_options = action.payload;
        },
        setYouTubeVideoTitle: (state, action) => {
            state.youTubeVideoTitle = action.payload;
        },
        setYouTubeVideoComment: (state, action) => {
            state.youtubeVideoComment = action.payload;
        },
        setYouTubeVideoThumbnail: (state, action) => {
            state.youTubeVideoThumbnail = action.payload;
        },
        setYouTubeVideoPrivacy: (state, action) => {
            state.youTubeVideoPrivacy = action.payload;
        },
        setPinterestTitle: (state, action) => {
            state.pinterestTitle = action.payload;
        },
        setPinterestDestinationLink: (state, action) => {
            state.pinterestDestinationLink = action.payload;
        },
        setPinterestAltText: (state, action) => {
            state.pinterestAltText = action.payload;
        },
        setTiktokVideoTitle: (state, action) => {
            state.tiktokVideoTitle = action.payload;
        },
        setTiktokAllowComments: (state, action) => {
            state.tiktokAllowComments = action.payload;
        },
        setTiktokAllowDuet: (state, action) => {
            state.tiktokAllowDuet = action.payload;
        },
        setTiktokDiscloseVideoContent: (state, action) => {
            state.tiktokDiscloseVideoContent = action.payload;
        },
        setTiktokCommercialForYourBrand: (state, action) => {
            state.tiktokCommercialForYourBrand = action.payload;
        },
        setTiktokCommercialForBrandedContent: (state, action) => {
            state.tiktokCommercialForBrandedContent = action.payload;
        },
        setStitchAllowTiktok: (state, action) => {
            state.stitchAllowTiktok = action.payload;
        },
        setTiktokSelectedViewSetting: (state, action) => {
            state.tiktokSelectedViewSetting = action.payload;
        },
        setTwitterMediaAltText: (state, action) => {
            state.twitterMediaAltText = action.payload;
        },
        setRedditTitle: (state, action) => {
            state.redditTitle = action.payload;
        },
        setWordpressTitle: (state, action) => {
            state.wordpressTitle = action.payload;
        },
        setWordpressFeaturedImageThumbnail: (state, action) => {
            state.wordpressFeaturedImageThumbnail = action.payload;
        },
        setWordpressSlug: (state, action) => {
            state.wordpressSlug = action.payload;
        },
        setWordpressPostTags: (state, action) => {
            state.wordpressPostTags = action.payload;
        },
        setWordpressPostPassword: (state, action) => {
            state.wordpressPostPassword = action.payload;
        },
        setWordpressCategories: (state, action) => {
            state.wordpressCategories = action.payload;
        },
        setWordpressPostVisibility: (state, action) => {
            state.wordpressPostVisibility = action.payload;
        },
        setWordpressSiteUrl: (state, action) => {
            state.wordpressSiteUrl = action.payload;
        },
        setWordpressFormat: (state, action) => {
            state.wordpressFormat = action.payload;
        },
        setTriggerComponentRemount: (state, action) => {
            state.triggerComponentRemount = action.payload;
        },
        setPinterestVideoThumbnail: (state, action) => {
            state.pinterestVideoThumbnail = action.payload;
        },
        setOpenExternalFilePickerDialog: (state, action) => {
            state.openExternalFilePickerDialog = action.payload;
        },
        setOpenPixelFilePickerDialog: (state, action) => {
            state.openPixelFilePickerDialog = action.payload;
        },
        setGoogleDriveManager: (state, action) => {
            state.googleDriveManager = action.payload;
        },
        setOpenPixelFilePickerDialogForWriteTab: (state, action) => {
            state.openPixelFilePickerDialogForWriteTab = action.payload;
        },
        setExternalFilePickerDialogOptions: (state, action) => {
            state.externalFilePickerDialogOptions = action.payload;
        },
        setExternalMediaFiles: (state, action) => {
            state.externalMediaFiles = action.payload;
        },
        setCanOpenThumbnailPreviewDialog: (state, action) => {
            state.openThumbnailPreviewDialog = action.payload;
        },
        setThumbnailPreviewData: (state, action) => {
            state.thumbnailPreviewData = action.payload;
        },
        setShouldAddNewPollChoice: (state, action) => {
            state.addNewPollChoice = action.payload;
        },
        setPollChoiceValue: (state, action) => {
            state.pollChoiceValue = action.payload;
        },
        setChoiceData: (state, action) => {
            state.choiceData = action.payload;
        },
        setPublishedPollIdentifier: (state, action) => {
            state.publishedPollIdentifier = action.payload;
        },
        setShouldNotifyYouTubeSubscribers: (state, action) => {
            state.notifyYouTubeSubscribers = action.payload;
        },
        setUploadingBulkContent: (state, action) => {
            state.uploadingBulkContent = action.payload;
        },
        setOpenComposer: (state, action) => {
            state.openComposer = action.payload;
        },
        setDisplayGetStarted: (state, action) => {
            state.displayGetStarted = action.payload;
        },
        setInstagramVideoThumbnail: (state, action) => {
            state.instagramVideoThumbnail = action.payload;
        },
        setRefreshPopularDays: (state, action) => {
            state.refreshPopularDays = action.payload;
        },
        setRefreshTrends: (state, action) => {
            state.refreshTrends = action.payload;
        },
        setUpdatableEventDay: (state, action) => {
            state.updatableDay = action.payload;
        },
        setExactTimeForRecurringPost: (state, action) => {
            state.exactTimeForRecurringPost = action.payload;
        },
        setFailedPostData: (state, action) => {
            state.failedPostData = action.payload;
        },
        setContentUploadingProgress: (state, action) => {
            state.contentUploadingProgress = action.payload;
        },
        setLoadingPostData: (state, action) => {
            state.loadingPostData = action.payload;
        },
        setCalendarPostTime: (state, action) => {
            state.calendarPostTime = action.payload;
        },
        setRefreshCalendar: (state, action) => {
            state.refreshCalendar = action.payload;
        },
        setSelectedPlatforms: (state, action) => {
            state.selectedPlatforms = action.payload;
        },
        setShowPreview: (state, action) => {
            state.showPreview = action.payload;
        },
        setPromptedMissingTextAlert: (state, action) => {
            state.promptedMissingTextAlert = action.payload;
        },
        setUserChooseToIgnoreMissingTextAlert: (state, action) => {
            state.userChooseToIgnoreMissingTextAlert = action.payload;
        },

        setAltSaveTrigger: (state, action) => {
            state.altSaveTrigger = action.payload;
        },
        setAltSaveObject: (state, action) => {
            state.altSaveObject = action.payload;
        },
        setAltCancelTrigger: (state, action) => {
            state.altCancelTrigger = action.payload;
        },
        setDisplayWeekNumbers: (state, action) => {
            state.displayWeekNumbers = action.payload;
        },
        makeComposerFullScreen: (state, action) => {
            state.fullScreenComposer = action.payload;
        },
        setTwitterThreadEdited: (state, action) => {
            state.twitterThreadEdited = action.payload;
        },
        setTwitterEditedThreadTexts: (state, action) => {
            state.twitterEditedThreadTexts = action.payload;
        },
        setSelectingPhotoFromHome: (state, action) => {
            state.selectingPhotoFromHome = action.payload;
        },
        setShouldForceUpdateTwitterThread: (state, action) => {
            state.shouldForceUpdateTwitterThread = action.payload;
        },
        setUnhideThreadEdit: (state, action) => {
            state.unhideThreadEdit = action.payload;
        },
        setUnhideThreadTextEdit: (state, action) => {
            state.unhideThreadTextEdit = action.payload;
        },
        setUnhideAltEdit: (state, action) => {
            state.unhideAltEdit = action.payload;
        },
        setCanFixAllImagesStore: (state, action) => {
            state.canFixAllImagesStore = action.payload;
        },
        setMediaStore: (state, action) => {
            state.mediaStore = action.payload;
        },
        setRemovedMedia: (state, action) => {
            state.removedMedia = action.payload;
        },
        setCanUpdateFirstTweet: (state, action) => {
            state.canUpdateFirstTweet = action.payload;
        },
        setFormattedMediaForTwitter: (state, action) => {
            state.formattedMediaForTwitter = action.payload;
        },
        setFormattedMediaForThreads: (state, action) => {
            state.formattedMediaForThreads = action.payload;
        },
        setFormattedMediaForReddit: (state, action) => {
            state.formattedMediaForReddit = action.payload;
        },
        setMediaAddedViaSpecificPlaformUrlMethod: (state, action) => {
            state.mediaAddedViaSpecificPlaformUrlMethod = action.payload;
        },
        setSpecificMediaPlatformTied: (state, action) => {
            state.specificMediaPlatformTied = action.payload;
        },
        setForceUpdateUseableMediaOnPlatformPreviews: (state, action) => {
            state.forceUpdateUseableMediaOnPlatformPreviews = action.payload;
        },
        setNotifyPreviewsOfLiveMediaChanges: (state, action) => {
            state.notifyPreviewsOfLiveMediaChanges = action.payload;
        },
        setPostReadyForPreviews: (state, action) => {
            state.postReadyForPreviews = action.payload;
        },
        setFacebookMediaRemovalObserver: (state, action) => {
            state.facebookMediaRemovalObserver = action.payload;
        },
        setTwitterCurrentThreadIndexToAddMedia: (state, action) => {
            state.twitterCurrentThreadIndexToAddMedia = action.payload;
        },
        setThreadsCurrentThreadIndexToAddMedia: (state, action) => {
            state.threadsCurrentThreadIndexToAddMedia = action.payload;
        },
        setAltTextBackup: (state, action) => {
            state.altTextBackup = action.payload;
        },
        setDraftRestored: (state, action) => {
            state.draftRestored = action.payload;
        },
        setLoggerTracker: (state, action) => {
            state.loggerTracker = action.payload;
        },
        setGMBPostType: (state, action) => {
            state.gmbPostType = action.payload;
        },
        setGMBEventTitle: (state, action) => {
            state.gmbEventTitle = action.payload;
        },
        setGMBEventSummary: (state, action) => {
            state.gmbEventSummary = action.payload;
        },
        setGMBEventSchedule: (state, action) => {
            state.gmbEventSchedule = action.payload;
        },
        setGMBCTAActionType: (state, action) => {
            state.gmbCTAActionType = action.payload;
        },
        setGMBCTAActionURL: (state, action) => {
            state.gmbCTAActionUrl = action.payload;
        },
        setGMBCouponCode: (state, action) => {
            state.gmbCouponCode = action.payload;
        },
        setGMBRedeemURL: (state, action) => {
            state.gmbRedeemUrl = action.payload;
        },
        setGMBTermsAndConditions: (state, action) => {
            state.gmbTermsAndConditions = action.payload;
        },
        setPublishIGVideoAsReel: (state, action) => {
            state.publishIGVideoAsReel = action.payload;
        },
        setPublishIGVideoToFeed: (state, action) => {
            state.publishIGVideoToFeed = action.payload;
        },
        setPublishIGVideoAsStories: (state, action) => {
            state.publishIGVideoAsStories = action.payload;
        },
        setPublishIGImageAsStories: (state, action) => {
            state.publishIGImageAsStories = action.payload;
        },
        setPublishIGImageAsNormalIGPost: (state, action) => {
            state.publishIGImageAsNormalIGPost = action.payload;
        },
        setOpenMediaEditor: (state, action) => {
            state.openMediaEditor = action.payload;
        },
        setIGVideoCoverThumbOffset: (state, action) => {
            state.igVideoCoverThumbOffset = action.payload;
        },
        setUploadableBulkContent: (state, action) => {
            state.uploadableBulkContent = action.payload;
        },
        setFirstComment: (state, action) => {
            state.firstComment = action.payload;
        },
        setCanSuggestPopularHashTags: (state, action) => {
            state.suggestPopularHashTags = action.payload;
        },
        setHashTagsSuggested: (state, action) => {
            state.hashTagsSuggested = action.payload;
        },
        setCountries: (state, action) => {
            state.countries = action.payload;
        },
        setYouTubeVideoCategories: (state, action) => {
            state.youTubeVideoCategories = action.payload;
        },
        setYouTubeVideoCategoryId: (state, action) => {
            state.youTubeVideoCategoryId = action.payload;
        },
        setRegionCode: (state, action) => {
            state.regionCode = action.payload;
        },
        setYouTubeVideoEmbeddable: (state, action) => {
            state.youTubeVideoEmbeddable = action.payload;
        },
        setYouTubeVideoLicense: (state, action) => {
            state.youTubeVideoLicense = action.payload;
        },
        setIsYouTubeVideoMadeForKids: (state, action) => {
            state.youTubeVideoMadeForKids = action.payload;
        },
        setYouTubeVideoTags: (state, action) => {
            state.youTubeVideoTags = action.payload;
        },
        setManuallyEditedPlatforms: (state, action) => {
            state.manuallyEditedPlatforms = action.payload || {};
        },
        setDrafts: (state, action) => {
            state.drafts = action.payload;
        },
        setOpenDraftPrompt: (state, action) => {
            state.openDraftPrompt = action.payload;
        },
        setPostCategories: (state, action) => {
            state.postCategories = action.payload;
        },
        setSelectedPostCategory: (state, action) => {
            state.selectedPostCategory = action.payload;
        },
        setDisablePostButton: (state, action) => {
            state.disablePostButton = action.payload;
        },
        setUserTags: (state, action) => {
            state.userTags = action.payload;
        },
        setSinglePostType: (state, action) => {
            state.singlePostType = action.payload;
        },
        setFetchRounds: (state, action) => {
            state.fetchRounds = action.payload;
        },
        setAnalyticsActivePreview: (state, action) => {
            state.analyticsActivePreview = action.payload;
        },
        setActiveBatchDelete: (state, action) => {
            state.activeBatchDeletes = action.payload;
        },
        setRepeatOptionType: (state, action) => {
            state.repeatOptionType = action.payload;
        },
        setRepeatOption: (state, action) => {
            state.repeatOption = action.payload;
        },
        setSelectedMediaOption: (state, action) => {
            state.selectedMediaOption = action.payload;
        },
        setShowMediaOptions: (state, action) => {
            state.showMediaOptions = action.payload;
        },
        setDoneChecking: (state, action) => {
            state.doneChecking = action.payload;
        },
        setShouldStartSyncingMediasOnPlatformSelection: (state, action) => {
            state.shouldStartSyncingMediasOnPlatformSelection = action.payload;
        },
        setFacebookCarouselLinks: (state, action) => {
            state.facebookCarouselLinks = action.payload;
        },
        setIsNormalFacebookPost: (state, action) => {
            state.isNormalFacebookPost = action.payload;
        },
        setIsFacebookPostCarousel: (state, action) => {
            state.isFacebookPostCarousel = action.payload;
        },
        setIsFacebookPostReel: (state, action) => {
            state.isFacebookPostReel = action.payload
        },
        setIsFacebookPostStory: (state, action) => {
            state.isFacebookPostStory = action.payload
        },
        setJustAddedSpecialCharacterToPostTextBody: (state, action) => {
            state.justAddedSpecialCharacterToPostTextBody = action.payload;
        },
        setDocuments: (state, action) => {
            state.documents = action.payload;
        },
        setBloggerTitle: (state, action) => {
            state.bloggerTitle = action.payload;
        },
        setBloggerUrl: (state, action) => {
            state.bloggerUrl = action.payload;
        },
        setBloggerLabel: (state, action) => {
            state.bloggerLabels = action.payload;
        },
        setRSSFeedUserId: (state, action) => {
            state.rssFeedUserId = action.payload;
        },
        setArticleTitleSelect: (state, action) => {
            state.articleTitleSelect = action.payload;
        },
        setRSSFeedTriggered: (state, action) => {
            state.rssFeedTriggered = action.payload;
        },
        setPostlyCloudGoogleDriveUploadIsActive: (state, action) => {
            state.postlyCloudGoogleDriveUploadIsActive = action.payload;
        },
        setMediaBank: (state, action) => {
            state.mediaBank = action.payload;
        },
        setOpenFeedSettings: (state, action) => {
            state.openFeedSettings = action.payload;
        },
        setFeedSelectedPlatforms: (state, action) => {
            state.feedSelectedPlatforms = action.payload;
        },
        setPostlyCloudPickerMediaType: (state, action) => {
            state.postlyCloudPickerMediaType = action.payload;
        },
        setPostBatchData: (state, action) => {
            state.postBatchData = action.payload;
        },
        setPostlyCloudUsedBytes: (state, action) => {
            state.postlyCloudUsedBytes = action.payload;
        },
        setPostlyCloudTotalStorageLimit: (state, action) => {
            state.postlyCloudTotalStorageLimit = action.payload;
        },
        setPostlyCloudUserAlerts: (state, action) => {
            state.postlyCloudUserAlerts = action.payload;
        },
        setPostlyCloudMediaUrls: (state, action) => {
            state.postlyCloudMediaUrls = action.payload;
        },
        setDraftInQueue: (state, action) => {
            state.draftInQueue = action.payload;
        },
        setProcessingDraft: (state, action) => {
            state.processingDraft = action.payload;
        },
        setDraftLocalSaveToken: (state, action) => {
            state.draftLocalSaveToken = action.payload;
        },
        setDraftPersistentSaveToken: (state, action) => {
            state.draftPersistentSaveToken = action.payload;
        },
        setUploadingDraftMedia: (state, action) => {
            state.uploadingDraftMedia = action.payload;
        },
        setDraftContent: (state, action) => {
            state.draftContent = action.payload;
        },
        setDraftSaveState: (state, action) => {
            state.draftSaveState = action.payload;
        },
        setSaveToDraftsButtonClicked: (state, action) => {
            state.saveToDraftsButtonClicked = action.payload;
        },
        setCanStartNewPost: (state, action) => {
            state.canStartNewPost = action.payload;
        },
        setTimeZoneToPostFeedContent: (state, action) => {
            state.timeZoneToPostFeedContent = action.payload;
        },
        setRefreshConnectedFeed: (state, action) => {
            state.refreshConnectedFeed = action.payload;
        },
        setChangesFoundForDraft: (state, action) => {
            state.changesFoundForDraft = action.payload;
        },
        setTriggerFeedPost: (state, action) => {
            state.triggerFeedPost = action.payload;
        },
        setFeedProcessing: (state, action) => {
            state.feedProcessing = action.payload;
        },
        setFeeds: (state, action) => {
            state.feeds = action.payload;
        },
        setMakingPostFromContentPlanner: (state, action) => {
            state.makingPostFromContentPlanner = action.payload;
        },
        setWorkspaceManagement: (state, action) => {
            state.workspaceManagement = action.payload;
        },
        setpostsTypeCounter: (state, action) => {
            state.postsTypeCounter = action.payload;
        },
        setdraftCounter: (state, action) => {
            state.draftCounter = action.payload;
        },
        setfailedCounter: (state, action) => {
            state.failedCounter = action.payload;
        },
        setrecurringCounter: (state, action) => {
            state.recurringCounter = action.payload;
        },
        setscheduledCounter: (state, action) => {
            state.scheduledCounter = action.payload;
        },
        sethasCalledFetchPosts: (state, action) => {
            state.hasCalledFetchPosts = action.payload;
        },
        setGlobalErrorMessage: (state, action) => {
            state.globalErrorMessage = action.payload;
        },
        setFeedPublishInterval: (state, action) => {
            state.feedPublishInterval = action.payload;
        },
        setEditedPostFlag: (state, action) => {
            state.editedPostFlag = action.payload;
        },
        setCurrentDisplayingPreviewPlatform: (state, action) => {
            state.currentDisplayingPreviewPlatform = action.payload;
        },
        setPreviewMedia: (state, action) => {
            state.previewMedia = action.payload;
        },
        setPostCategoryType: (state, action) => {
            state.postCategoryType = action.payload;
        },
        setPostSpecificDataVisualRequest: (state, action) => {
            state.postSpecificDataVisualRequest = action.payload;
        },
        setPreviousPostObjectBeforeEdit: (state, action) => {
            state.previousPostObjectBeforeEdit = action.payload
        },
        setPlatformFirstComment: (state, action) => { 
            state.platformFirstComment = action.payload; 
        },
        setFacebookFirstComment: (state, action) => { 
            state.facebookFirstComment = action.payload; 
        }
    }
});
export const getActiveBatchDelete = (state) => state.posts.activeBatchDeletes;
export const getSelectedPostType = (state) => state.posts.value;
export const getShowPreview = (state) => state.posts.showPreview;
export const getPromptedMissingTextAlert = (state) => state.posts.promptedMissingTextAlert;
export const getUserChooseToIgnoreMissingTextAlert = (state) => state.posts.userChooseToIgnoreMissingTextAlert;
export const getSelectingPhotoFromHome = (state) => state.posts.selectingPhotoFromHome;
export const getShouldForceUpdateTwitterThread = (state) => state.posts.shouldForceUpdateTwitterThread;
export const getUnhideThreadEdit = (state) => state.posts.unhideThreadEdit;
export const getUnhideThreadTextEdit = (state) => state.posts.unhideThreadTextEdit;
export const getUnhideAltEdit = (state) => state.posts.unhideAltEdit;
export const getCanFixAllImagesStore = (state) => state.posts.canFixAllImagesStore;
export const getTwitterEditedThreadTexts = (state) => state.posts.twitterEditedThreadTexts;
export const getFormattedMediaForTwitter = (state) => state.posts.formattedMediaForTwitter;
export const getFormattedMediaForThreads = (state) => state.posts.formattedMediaForThreads;
export const getFormattedMediaForReddit = (state) => state.posts.formattedMediaForReddit;
export const getFirstAltAddedForTwitter = (state) => state.posts.firstAltAddedForTwitter;
export const getAltSaveTrigger = (state) => state.posts.altSaveTrigger;
export const getLoggerTracker = (state) => state.posts.loggerTracker;
export const getAltSaveObject = (state) => state.posts.altSaveObject;
export const getTwitterMediaObjectForAltMemory = (state) => state.posts.twitterMediaObjectForAltMemory;
export const getBlockTwitterShallowUpdate = (state) => state.posts.blockTwitterShallowUpdate;
export const getAltCancelTrigger = (state) => state.posts.altCancelTrigger;
export const getTriggerComponentRemount = (state) => state.posts.triggerComponentRemount;
export const getPostSearchTerm = (state) => state.posts.postSearchTerm;
export const getPostFilters = (state) => state.posts.filters;
export const isContentPublishingOngoing = (state) => state.posts.contentPublishingInProgress;
export const getTimeToPostContent = (state) => state.posts.timeToPostContent;
export const getRevertChanges = (state) => state.posts.revertChanges;
export const getDraftCount = (state) => state.posts.draftCount;
export const getEditablePost = (state) => state.posts.editablePost;
export const getCurrentDraft = (state) => state.posts.currentDraft;
export const getStartedCollectingDraftData = (state) => state.posts.startedCollectingDraftData;
export const getIgnoreDraftUpdate = (state) => state.posts.ignoreDraftUpdate;
export const getAboutRepublishingPost = (state) => state.posts.aboutRepublishingPost;
export const isComposerFocused = (state) => state.posts.composerFocused;
export const getCachedPosts = (state) => state.posts.cachedPosts;
export const canForceFetchPosts = (state) => state.posts.forceFetchPosts;
export const canExpandPublisherActions = (state) => state.posts.canExpandPublisherActions;
export const canOpenPostDetailsDialog = (state) => state.posts.openPostDetailsDialog;
export const getDeletedPost = (state) => state.posts.deletedPost;
export const getPersistedAccounts = (state) => state.posts.persistedAccounts;
export const canPostNow = (state) => state.posts.postNow;
export const getMunchedPostTargetIds = (state) => state.posts.munchedPostTargetIds;
export const getUpdatedPlatform = (state) => state.posts.updatedPlatform;
export const getAdvancedContentCreationOptions = (state) => state.posts.advanced_content_creation_options;
export const getYouTubeVideoTitle = (state) => state.posts.youTubeVideoTitle;
export const getYouTubeVideoComment = (state) => state.posts.youtubeVideoComment;
export const getYouTubeVideoThumbnail = (state) => state.posts.youTubeVideoThumbnail;
export const getWordPressTitle = (state) => state.posts.wordpressTitle;
export const getWordpressFeaturedImageThumbnail = (state) => state.posts.wordpressFeaturedImageThumbnail;
export const getWordpressSlug = (state) => state.posts.wordpressSlug;
export const getWordpressPostTags = (state) => state.posts.wordpressPostTags;
export const getWordpressPostPassword = (state) => state.posts.wordpressPostPassword;
export const getWordpressSiteUrl = (state) => state.posts.wordpressSiteUrl;
export const getWordpressCategories = (state) => state.posts.wordpressCategories;
export const getWordpressFormat = (state) => state.posts.wordpressFormat;
export const getRedditTitle = (state) => state.posts.redditTitle;
export const getPinterestTitle = (state) => state.posts.pinterestTitle;
export const getPinterestDestinationLink = (state) => state.posts.pinterestDestinationLink;
export const getWordpressPostVisibility = (state) => state.posts.wordpressPostVisibility;
export const getPinterestVideoThumbnail = (state) => state.posts.pinterestVideoThumbnail;
export const getYouTubeVideoPrivacy = (state) => state.posts.youTubeVideoPrivacy;
export const canOpenExternalFilePickerDialog = (state) => state.posts.openExternalFilePickerDialog;
export const canOpenPixelFilePickerDialog = (state) => state.posts.openPixelFilePickerDialog;
export const getGoogleDriveManager = (state) => state.posts.googleDriveManager;
export const canOpenPixelFilePickerDialogForWriteTab = (state) => state.posts.openPixelFilePickerDialogForWriteTab;
export const getExternalFilePickerDialogOptions = (state) => state.posts.externalFilePickerDialogOptions;
export const getExternalMediaFiles = (state) => state.posts.externalMediaFiles;
export const canOpenThumbnailPreviewDialog = (state) => state.posts.openThumbnailPreviewDialog;
export const getThumbnailPreviewData = (state) => state.posts.thumbnailPreviewData;
export const shouldAddNewPollChoice = (state) => state.posts.addNewPollChoice;
export const getPollChoiceValue = (state) => state.posts.pollChoiceValue;
export const getChoiceData = (state) => state.posts.choiceData;
export const getPublishedPollIdentifier = (state) => state.posts.publishedPollIdentifier;
export const shouldNotifyYouTubeSubscribers = (state) => state.posts.notifyYouTubeSubscribers;
export const isUploadingBulkContent = (state) => state.posts.uploadingBulkContent;
export const canOpenComposer = (state) => state.posts.openComposer;
export const canDisplayGetStarted = (state) => state.posts.displayGetStarted;
export const getInstagramVideoThumbnail = (state) => state.posts.instagramVideoThumbnail;
export const canRefreshPopularDays = (state) => state.posts.refreshPopularDays;
export const getUpdatableEventDay = (state) => state.posts.updatableDay;
export const getPinterestAltText = (state) => state.posts.pinterestAltText;
export const getTiktokVideoTitle = (state) => state.posts.tiktokVideoTitle;
export const getTiktokAllowComments = (state) => state.posts.tiktokAllowComments;
export const getTiktokAllowDuet = (state) => state.posts.tiktokAllowDuet;
export const getTiktokCommercialForYourBrand = (state) => state.posts.tiktokCommercialForYourBrand;
export const getTiktokCommercialForBrandedContent = (state) => state.posts.tiktokCommercialForBrandedContent;
export const getTiktokDiscloseVideoContent = (state) => state.posts.tiktokDiscloseVideoContent;
export const getStitchAllowTiktok = (state) => state.posts.stitchAllowTiktok;
export const getTiktokSelectedViewSetting = (state) => state.posts.tiktokSelectedViewSetting;
export const getTimeZoneToPostContent = (state) => state.posts.timeZoneToPostContent;
export const getExactTimeForRecurringPost = (state) => state.posts.exactTimeForRecurringPost;
export const getPostGroupIdentifier = (state) => state.posts.post_group_identifier;
export const getTwitterMediaAltText = (state) => state.posts.twitterMediaAltText;
export const getFailedPostData = (state) => state.posts.failedPostData;
export const getContentUploadingProgress = (state) => state.posts.contentUploadingProgress;
export const getLoadingPostData = (state) => state.posts.loadingPostData;
export const getCalendarPostTime = (state) => state.posts.calendarPostTime;
export const getRefreshedCalendarData = (state) => state.posts.refreshCalendar;
export const getSelectedPlatforms = (state) => state.posts.selectedPlatforms;
export const getMediaAddedViaSpecificPlaformUrlMethod = (state) => state.posts.mediaAddedViaSpecificPlaformUrlMethod;
export const isComposerFullScreen = (state) => state.posts.fullScreenComposer;
export const canDisplayWeekNumbers = (state) => state.posts.displayWeekNumbers;
export const getMediaStore = (state) => state.posts.mediaStore;
export const getRemovedMedia = (state) => state.posts.removedMedia;
export const getCanUpdateFirstTweet = (state) => state.posts.canUpdateFirstTweet;
export const getTwitterCurrentThreadIndexToAddMedia = (state) => state.posts.twitterCurrentThreadIndexToAddMedia;
export const getThreadsCurrentThreadIndexToAddMedia = (state) => state.posts.threadsCurrentThreadIndexToAddMedia;
export const getSpecificMediaPlatformTied = (state) => state.posts.specificMediaPlatformTied;
export const getForceUpdateUseableMediaOnPlatformPreviews = (state) => state.posts.forceUpdateUseableMediaOnPlatformPreviews;
export const getNotifyPreviewsOfLiveMediaChanges = (state) => state.posts.notifyPreviewsOfLiveMediaChanges;
export const getPostReadyForPreviews = (state) => state.posts.postReadyForPreviews;
export const getFacebookMediaRemovalObserver = (state) => state.posts.facebookMediaRemovalObserver;
export const getAltTextBackup = (state) => state.posts.altTextBackup;
export const getDraftRestored = (state) => state.posts.draftRestored;
export const getGMBPostType = (state) => state.posts.gmbPostType;
export const getGMBEventTitle = (state) => state.posts.gmbEventTitle;
export const getGMBEventSchedule = (state) => state.posts.gmbEventSchedule;
export const getGMBEventSummary = (state) => state.posts.gmbEventSummary;
export const getGMBCTAActionType = (state) => state.posts.gmbCTAActionType;
export const getGMBCTAActionURL = (state) => state.posts.gmbCTAActionUrl;
export const getGMBCouponCode = (state) => state.posts.gmbCouponCode;
export const getGMBRedeemURL = (state) => state.posts.gmbRedeemUrl;
export const getGMBTermsAndConditions = (state) => state.posts.gmbTermsAndConditions;
export const canPublishIGVideoAsReel = (state) => state.posts.publishIGVideoAsReel;
export const canPublishIGVideoToFeed = (state) => state.posts.publishIGVideoToFeed;
export const canPublishIGVideoAsStories = (state) => state.posts.publishIGVideoAsStories;
export const canPublishIGImageAsStories = (state) => state.posts.publishIGImageAsStories;
export const canPublishIGImageAsNormalIGPost = (state) => state.posts.publishIGImageAsNormalIGPost;
export const canOpenMediaEditor = (state) => state.posts.openMediaEditor;
export const getIGVideoCoverThumbOffset = (state) => state.posts.igVideoCoverThumbOffset;
export const getUploadableBulkContent = (state) => state.posts.uploadableBulkContent;
export const getFirstComment = (state) => state.posts.firstComment;
export const getCanSuggestPopularHashTags = (state) => state.posts.suggestPopularHashTags;
export const getHashTagsSuggested = (state) => state.posts.hashTagsSuggested;
export const getCountries = (state) => state.posts.countries;
export const getRegionCode = (state) => state.posts.regionCode;
export const getYouTubeVideoCategories = (state) => state.posts.youTubeVideoCategories;
export const getYouTubeVideoCategoryId = (state) => state.posts.youTubeVideoCategoryId;
export const getYouTubeVideoLicense = (state) => state.posts.youTubeVideoLicense;
export const getIsYouTubeVideoMadeForKids = (state) => state.posts.youTubeVideoMadeForKids;
export const getYouTubeVideoTags = (state) => state.posts.youTubeVideoTags;
export const getIsYouTubeVideoEmbeddable = (state) => state.posts.youTubeVideoEmbeddable;
export const getManuallyEditedPlatforms = (state) => state.posts.manuallyEditedPlatforms;
export const getCanRefreshTrends = (state) => state.posts.refreshTrends;
export const getDrafts = (state) => state.posts.drafts;
export const getOpenDraftPrompt = (state) => state.posts.openDraftPrompt;
export const getPostCategories = (state) => state.posts.postCategories;
export const getSelectedPostCategory = (state) => state.posts.selectedPostCategory;
export const canDisablePostButton = (state) => state.posts.disablePostButton;
export const getUserTags = (state) => state.posts.userTags;
export const getSinglePostType = (state) => state.posts.singlePostType;
export const getFetchRounds = (state) => state.posts.fetchRounds;
export const canSelectAllAudience = (state) => state.posts.selectAllAudience;
export const getAnalyticsActivePreview = (state) => state.posts.analyticsActivePreview
export const getRepeatOptionType = (state) => state.posts.repeatOptionType;
export const getRepeatOption = (state) => state.posts.repeatOption;
export const getSelectedMediaOption = (state) => state.posts.selectedMediaOption;
export const getShowMediaOptions = (state) => state.posts.showMediaOptions;
export const getDoneChecking = (state) => state.posts.doneChecking;
export const getShouldStartSyncingMediasOnPlatformSelection = (state) => state.posts.shouldStartSyncingMediasOnPlatformSelection;
export const getFacebookCarouselLinks = (state) => state.posts.facebookCarouselLinks;
export const getIsNormalFacebookPost = (state) => state.posts.isNormalFacebookPost;
export const getIsFacebookPostCarousel = (state) => state.posts.isFacebookPostCarousel;
export const getIsFacebookPostReel = (state) => state.posts.isFacebookPostReel;
export const getIsFacebookPostStory = (state) => state.posts.isFacebookPostStory;
export const getJustAddedSpecialCharacterToPostTextBody = (state) => state.posts.justAddedSpecialCharacterToPostTextBody;
export const getDocuments = (state) => state.posts.documents;
export const getBloggerTitle = (state) => state.posts.bloggerTitle;
export const getBloggerUrl = (state) => state.posts.bloggerUrl;
export const getBloggerLabel = (state) => state.posts.bloggerLabels;
export const getRSSFeedUserId = (state) => state.posts.rssFeedUserId;
export const getArticleTitleSelect = (state) => state.posts.articleTitleSelect;
export const getRSSFeedTriggered = (state) => state.posts.rssFeedTriggered;
export const getPostlyCloudGoogleDriveUploadIsActive = (state) => state.posts.postlyCloudGoogleDriveUploadIsActive;
export const getMediaBank = (state) => state.posts.mediaBank;
export const getOpenFeedSettings = (state) => state.posts.openFeedSettings;
export const getFeedSelectedPlatforms = (state) => state.posts.feedSelectedPlatforms;
export const getPostlyCloudPickerMediaType = (state) => state.posts.postlyCloudPickerMediaType;
export const getPostBatchData = (state) => state.posts.postBatchData;
export const getPostlyCloudUsedBytes = (state) => state.posts.postlyCloudUsedBytes;
export const getPostlyCloudTotalStorageLimit = (state) => state.posts.postlyCloudTotalStorageLimit;
export const getPostlyCloudUserAlerts = (state) => state.posts.postlyCloudUserAlerts;
export const getPostlyCloudMediaUrls = (state) => state.posts.postlyCloudMediaUrls;
export const getDraftInQueue = (state) => state.posts.draftInQueue;
export const getProcessingDraft = (state) => state.posts.processingDraft;
export const getDraftLocalSaveToken = (state) => state.posts.draftLocalSaveToken;
export const getDraftPersistentSaveToken = (state) => state.posts.draftPersistentSaveToken;
export const getUploadingDraftMedia = (state) => state.posts.uploadingDraftMedia;
export const getDraftContent = (state) => state.posts.draftContent;
export const getDraftSaveState = (state) => state.posts.draftSaveState;
export const getSaveToDraftsButtonClicked = (state) => state.posts.saveToDraftsButtonClicked;
export const getCanStartNewPost = (state) => state.posts.canStartNewPost;
export const getTimeZoneToPostFeedContent = (state) => state.posts.timeZoneToPostFeedContent;
export const getRefreshConnectedFeed = (state) => state.posts.refreshConnectedFeed;
export const getChangesFoundForDraft = (state) => state.posts.changesFoundForDraft;
export const getTriggerFeedPost = (state) => state.posts.triggerFeedPost;
export const getFeedProcessing = (state) => state.posts.feedProcessing;
export const getFeeds = (state) => state.posts.feeds;
export const getMakingPostFromContentPlanner = (state) => state.posts.makingPostFromContentPlanner;
export const getWorkspaceManagement = (state) => state.posts.workspaceManagement;
export const getpostsTypeCounter = (state) => state.posts.postsTypeCounter;
export const getdraftCounter = (state) => state.posts.draftCounter;
export const getfailedCounter = (state) => state.posts.failedCounter;
export const getrecurringCounter = (state) => state.posts.recurringCounter;
export const getscheduledCounter = (state) => state.posts.scheduledCounter;
export const gethasCalledFetchPosts = (state) => state.posts.hasCalledFetchPosts;
export const getGlobalErrorMessage = (state) => state.posts.globalErrorMessage;
export const getFeedPublishInterval = (state) => state.posts.feedPublishInterval;
export const getEditedPostFlag = (state) => state.posts.editedPostFlag;
export const getCurrentDisplayingPreviewPlatform = (state) => state.posts.currentDisplayingPreviewPlatform;
export const getPreviewMedia = (state) => state.posts.previewMedia;
export const getPostCategoryType = (state) => state.posts.postCategoryType;
export const getPostSpecificDataVisualRequest = (state) => state.posts.postSpecificDataVisualRequest;
export const getPreviousPostObjectBeforeEdit = (state) => state.posts.previousPostObjectBeforeEdit;
export const getFacebookFirstComment = (state) => state.posts.facebookFirstComment; 
export const getPlatformFirstComment = (state) => state.posts.platformFirstComment; 

export const {
    setSelectedPostType,
    setPostCategoryType,
    setPostSearchTerm,
    setPostsFilters,
    setContentPublishingInProgress,
    setTimeToPostContent,
    setPostGroupIdentifier,
    setTimeZoneToPostContent,
    revertChanges,
    setDraftCount,
    setComposerFocused,
    setEditablePost,
    setCurrentDraft,
    setStartedCollectingDraftData,
    setIgnoreDraftUpdate,
    setAboutRepublishingPost,
    cachePosts,
    setForceFetchPosts,
    setCanExpandPublisherActions,
    setOpenPostDetailsDialog,
    setDeletedPost,
    setPersistedAudienceAccounts,
    setPostNow,
    setMunchedPostTargetIds,
    setUpdatedPlatform,
    setAdvancedContentCreationOptions,
    setYouTubeVideoTitle,
    setYouTubeVideoComment,
    setYouTubeVideoThumbnail,
    setPinterestTitle,
    setPinterestDestinationLink,
    setTwitterMediaObjectForAltMemory,
    setBlockTwitterShallowUpdate,
    setPinterestAltText,
    setTiktokVideoTitle,
    setTiktokAllowComments,
    setTiktokAllowDuet,
    setTiktokDiscloseVideoContent,
    setTiktokCommercialForYourBrand,
    setTiktokCommercialForBrandedContent,
    setStitchAllowTiktok,
    setTiktokSelectedViewSetting,
    setPinterestVideoThumbnail,
    setRedditTitle,
    setYouTubeVideoPrivacy,
    setOpenExternalFilePickerDialog,
    setExternalFilePickerDialogOptions,
    setOpenPixelFilePickerDialog,
    setGoogleDriveManager,
    setOpenPixelFilePickerDialogForWriteTab,
    setExternalMediaFiles,
    setCanOpenThumbnailPreviewDialog,
    setThumbnailPreviewData,
    setShouldAddNewPollChoice,
    setPollChoiceValue,
    setChoiceData,
    setPublishedPollIdentifier,
    setShouldNotifyYouTubeSubscribers,
    setCanFixAllImagesStore,
    setUploadingBulkContent,
    setOpenComposer,
    setDisplayGetStarted,
    setInstagramVideoThumbnail,
    setRefreshPopularDays,
    setUpdatableEventDay,
    setExactTimeForRecurringPost,
    setTwitterMediaAltText,
    setFailedPostData,
    setContentUploadingProgress,
    setFirstAltAddedForTwitter,
    setLoadingPostData,
    setCalendarPostTime,
    setRefreshCalendar,
    setSelectedPlatforms,
    setShowPreview,
    setPromptedMissingTextAlert,
    setUserChooseToIgnoreMissingTextAlert,
    makeComposerFullScreen,
    setDisplayWeekNumbers,
    setAltSaveTrigger,
    setAltSaveObject,
    setAltCancelTrigger,
    setTwitterThreadEdited,
    setTriggerComponentRemount,
    setSelectingPhotoFromHome,
    setShouldForceUpdateTwitterThread,
    setUnhideThreadEdit,
    setLoggerTracker,
    setUnhideThreadTextEdit,
    setMediaStore,
    setRemovedMedia,
    setCanUpdateFirstTweet,
    setMediaAddedViaSpecificPlaformUrlMethod,
    setSpecificMediaPlatformTied,
    setForceUpdateUseableMediaOnPlatformPreviews,
    setFacebookMediaRemovalObserver,
    setTwitterCurrentThreadIndexToAddMedia,
    setThreadsCurrentThreadIndexToAddMedia,
    setFormattedMediaForTwitter,
    setFormattedMediaForThreads,
    setFormattedMediaForReddit,
    setUnhideAltEdit,
    setTwitterEditedThreadTexts,
    setAltTextBackup,
    setDraftRestored,
    setGMBPostType,
    setGMBEventTitle,
    setGMBEventSummary,
    setGMBEventSchedule,
    setGMBCTAActionType,
    setGMBCTAActionURL,
    setGMBCouponCode,
    setGMBRedeemURL,
    setGMBTermsAndConditions,
    setPublishIGVideoAsReel,
    setPublishIGVideoToFeed,
    setPublishIGVideoAsStories,
    setPublishIGImageAsStories,
    setPublishIGImageAsNormalIGPost,
    setOpenMediaEditor,
    setIGVideoCoverThumbOffset,
    setUploadableBulkContent,
    setFirstComment,
    setCanSuggestPopularHashTags,
    setHashTagsSuggested,
    setCountries,
    setRegionCode,
    setYouTubeVideoCategories,
    setYouTubeVideoCategoryId,
    setYouTubeVideoLicense,
    setIsYouTubeVideoMadeForKids,
    setYouTubeVideoTags,
    setYouTubeVideoEmbeddable,
    setManuallyEditedPlatforms,
    setRefreshTrends,
    setDrafts,
    setOpenDraftPrompt,
    setPostCategories,
    setSelectedPostCategory,
    setDisablePostButton,
    setUserTags,
    setSinglePostType,
    setFetchRounds,
    setSelectAllAudience,
    setWordpressTitle,
    setWordpressFeaturedImageThumbnail,
    setWordpressSlug,
    setWordpressPostTags,
    setWordpressPostPassword,
    setWordpressPostVisibility,
    setWordpressSiteUrl,
    setWordpressCategories,
    setWordpressFormat,
    setAnalyticsActivePreview,
    setActiveBatchDelete,
    setRepeatOptionType,
    setRepeatOption,
    setSelectedMediaOption,
    setShowMediaOptions,
    setDoneChecking,
    setShouldStartSyncingMediasOnPlatformSelection,
    setFacebookCarouselLinks,
    setIsNormalFacebookPost,
    setIsFacebookPostCarousel,
    setIsFacebookPostReel,
    setIsFacebookPostStory,
    setJustAddedSpecialCharacterToPostTextBody,
    setDocuments,
    setBloggerTitle,
    setBloggerUrl,
    setBloggerLabel,
    setRSSFeedUserId,
    setArticleTitleSelect,
    setRSSFeedTriggered,
    setPostlyCloudGoogleDriveUploadIsActive,
    setMediaBank,
    setOpenFeedSettings,
    setFeedSelectedPlatforms,
    setPostlyCloudPickerMediaType,
    setPostBatchData,
    setPostlyCloudUsedBytes,
    setPostlyCloudTotalStorageLimit,
    setPostlyCloudUserAlerts,
    setPostlyCloudMediaUrls,
    setDraftInQueue,
    setProcessingDraft,
    setDraftLocalSaveToken,
    setDraftPersistentSaveToken,
    setUploadingDraftMedia,
    setDraftContent,
    setDraftSaveState,
    setSaveToDraftsButtonClicked,
    setCanStartNewPost,
    setTimeZoneToPostFeedContent,
    setRefreshConnectedFeed,
    setChangesFoundForDraft,
    setTriggerFeedPost,
    setFeedProcessing,
    setNotifyPreviewsOfLiveMediaChanges,
    setPostReadyForPreviews,
    setFeeds,
    setMakingPostFromContentPlanner,
    setWorkspaceManagement,
    setpostsTypeCounter,
    setdraftCounter,
    setfailedCounter,
    setrecurringCounter,
    setscheduledCounter,
    sethasCalledFetchPosts,
    setGlobalErrorMessage,
    setFeedPublishInterval,
    setEditedPostFlag,
    setCurrentDisplayingPreviewPlatform,
    setPreviewMedia,
    setPostSpecificDataVisualRequest,
    setPreviousPostObjectBeforeEdit,
    setFacebookFirstComment,
    setPlatformFirstComment, 
} = postSlice.actions;
export default postSlice.reducer;