import React, { useEffect, useState } from 'react';
import AccountsList from './accounts_list';
import SectionHeader from './section_header';
import { AudienceListItemContainer } from './styles';
import SlideDown from 'react-slidedown';

export default function PostAudienceListItem(props) {

    const [expanded, setExpanded] = useState(false);
    const [platform, setPlatform] = useState(props.platform);
    const { platformChangedHandler } = props;

    useEffect(() => {
        setPlatform(props.platform);
    }, [props.platform]);

    const handleSelectAll = (affectedPlatform) => {
        try {
            for (let account of affectedPlatform.accounts) {
                if (account.selected) {
                    account.selected = false;
                    if (account.connected_pages_and_groups) {
                        account.connected_pages_and_groups.forEach(page => {
                            page.selected = false;
                        })
                    }
                } else {
                    account.selected = true;
                    if (account.connected_pages_and_groups) {
                        account.connected_pages_and_groups.forEach(page => {
                            page.selected = true;
                        })
                    }
                }
                setPlatform(affectedPlatform);
                platformChangedHandler(affectedPlatform)
            }
        } catch (e) {
        }
    }

    const handleExpansion = () => {
        setExpanded(!expanded);
    }

    return (
        <AudienceListItemContainer
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}>
            <SectionHeader
                handleExpansion={handleExpansion}
                id={platform.identifier}
                key={platform.identifier}
                platform={platform}
                handleSelectAll={handleSelectAll}
            />
            <SlideDown className={'my-dropdown-slidedown'}>
                {
                    expanded &&
                    <AccountsList
                        platform={platform}
                        platformChangedListener={(changedPlatform) => {
                            platformChangedHandler(changedPlatform);
                        }}
                    />
                }
            </SlideDown>
        </AudienceListItemContainer>
    );
}