import { Dropdown, FormLabel } from 'react-bootstrap';
import LocalFileSelectionButton from './LocalFileSelectionButton';
import ExternalFileSelectionButton from './ExternalFileSelectionButton';
import { useDispatch } from 'react-redux';
import { setSpecificMediaPlatformTied } from 'redux/slices/postslice';
import { useEffect } from 'react';
import PixelImagePicker from './PixelImagePicker';
import GoogleDriveImagePicker from './GoogleDriveImagePicker';

const MultimediaSelectionDropdown = (props) => {
    const dispatch = useDispatch();
    const {
        dropDownAnchor,
        prompt = "Add File",
        alignment = "end",
        specificPlatformTied,
        ...others
    } = props;
        // console.log("🚀 ~ MultimediaSelectionDropdown ~ specificPlatformTied:", specificPlatformTied)

    useEffect(() => {
        if (specificPlatformTied) {
            dispatch(setSpecificMediaPlatformTied(specificPlatformTied));
        }
    }, [specificPlatformTied])

    return (
        <Dropdown className="btn-reveal-trigger">
            <Dropdown.Toggle
                variant="link"
                size="sm"
                className="p-0 m-0 text-decoration-none text-600"
            >
                {dropDownAnchor}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ width: 'fit-content' }} className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg p-2" align={alignment}>
                <Dropdown.Header>{prompt}</Dropdown.Header>
                <Dropdown.Item as={FormLabel}>
                    <LocalFileSelectionButton {...others} />
                </Dropdown.Item>
                <Dropdown.Item>
                    <ExternalFileSelectionButton {...others} />
                </Dropdown.Item>

                {
                    <Dropdown.Item>
                        <PixelImagePicker fileType={props?.fileType} {...others} specificPlatformTied={specificPlatformTied} />
                    </Dropdown.Item>
                }
                {
                    <Dropdown.Item>
                        <GoogleDriveImagePicker fileType={'image'} {...others} />
                    </Dropdown.Item>
                }
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default MultimediaSelectionDropdown;