import styled from "styled-components";

export const MainArena = styled.div`
  height:auto;
  width:${(props) => props.mainWidth};
  margin-bottom:20px;
`;
export const TitleArea = styled.div`
  height:auto;
  width:100%;
  display:flex;
//   background-color:red;
  justify-content:flex-start;
  align-items:center;
  margin-bottom:20px;
`;
export const InputContainer = styled.div`
  height:auto;
  width:100%;
  display:flex;
  flex-direction:column;
//   background-color:blue;
  justify-content:center;
  align-items:flex-start;
//   gap:10px;
`;
export const SubmitArea = styled.div`
  height:50px;
  margin-top:10px;
  width:100%;
  display:flex;
  gap:5px;
//   background-color:blue;
  justify-content:flex-end;
  align-items:center;
`;
export const TaggedPeople = styled.div`
  min-height:50px;
  margin-top:10px;
  font-size:14px;
  width:100%;
//   display:flex;
//   justify-content:flex-start;
//   align-items:center;
`;
export const Text = styled.p`
  font-size:15px;
`;

export const ListContainer = styled.div`
  max-height:250px !important;
  width:100%;
  padding:5px;
  padding-top:15px;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  gap:5px;
  flex-direction:column;
  overflow-y:scroll;
  overflow:auto;
`;

export const HashIcon = styled.div`
  height:auto;
  width:auto;
  display:flex;
  justify-content:center;
  align-items:center;
  margin-left:${(props) => props.leftSide};
`;

export const ListItem = styled.div`
  min-height:${(props) => props.listHeight
  };
  width:100%;
  display:flex;
  justify-content:flex-start;
  align-items:center;
//   flex-direction:column;
  cursor:pointer;
  gap:10px;
  padding:10px;
  &:hover{
    background-color:${(props) => props.hoverColor
  };
  }
  &:active{
    opacity:0;
  }
`;
export const ListDetailsBox = styled.div`
 height:100%;
 width:80%;
 display:flex;
  justify-content:flex-start;
  align-items:center;
  gap:10px;
`;
export const ListDp = styled.div`
 height:50px;
 width:50px;
 background-color:orange;
 border-radius:100%;
 display:flex;
  justify-content:flex-start;
  align-items:center;
  overflow:hidden;
`;
export const ListNames = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
`;
export const TopArea = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
`;
