import { PlatformIdentifier } from 'constants';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFacebookCarouselLinks, getForceUpdateUseableMediaOnPlatformPreviews, setForceUpdateUseableMediaOnPlatformPreviews } from 'redux/slices/postslice';
import { getMediaArrangement, setMediaArrangement } from 'redux/slices/publishable_media_arrangement_slice';
import FacebookCarouselInputForm from './FacebookCarouselInputForm';

const FacebookLinkCarousels = (props) => {
    const { facebookMedias } = props;
    const dispatch = useDispatch()
    const facebookCarouselLinks = useSelector(getFacebookCarouselLinks)
    const forceUpdateUseableMediaOnPlatformPreviews = useSelector(getForceUpdateUseableMediaOnPlatformPreviews);
    const media_items_arrangement = useSelector(getMediaArrangement);

    const handleUpdateCarouselLinkText = (text, index, key) => {
        let _media = [...facebookMedias];
        _media[index] = { ..._media[index], carousel: { ..._media[index]?.carousel, [key]: text } };
        let _mediaItemsArrangements = {
            ...media_items_arrangement, facebook: {
                ...media_items_arrangement?.facebook,
                media: _media
            }
        };
        dispatch(setMediaArrangement(_mediaItemsArrangements))
        if (!forceUpdateUseableMediaOnPlatformPreviews.includes(PlatformIdentifier.FACEBOOK)) {
            dispatch(setForceUpdateUseableMediaOnPlatformPreviews([PlatformIdentifier.FACEBOOK]));
        }
    }

    const displayCarouselLinks = facebookCarouselLinks?.map((e, index) => {

        return (
            <FacebookCarouselInputForm
                key={index}
                index={index}
                e={e}
                handleUpdateCarouselLinkText={handleUpdateCarouselLinkText}
            />
        )
    })

    return (
        <div>
            {displayCarouselLinks}
        </div>
    )
}

export default FacebookLinkCarousels