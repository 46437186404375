import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import createMarkup from 'helpers/createMarkup';

const Comments = ({ comments, loadComment }) => {
  return (
    <>
      {
        comments.map(({ id, avatarSrc, name, content, postTime }) => (
          <div key={id}>
            <Flex className="mt-3">
              <Avatar fallBackName={name} src={avatarSrc} size="xl" />
              <div className="flex-1 ms-2 fs--1">
                <p className="mb-1 bg-200 rounded-3 p-2">
                  <span className="text-primary fw-semi-bold">
                    {name}
                  </span>
                  <span
                    className="ms-1"
                    dangerouslySetInnerHTML={createMarkup(content)}
                  />
                </p>
                <div className="px-2">
                  <span className='text-primary cursor-pointer'>Like</span> • <span className='cursor-pointer text-primary'>Reply</span> • {postTime}
                </div>
              </div>
            </Flex>
          </div>
        ))
      }
      {
        loadComment && (
          <span className="fs--1 cursor-pointer text-700 d-inline-block mt-2" href="">
            Load more comments ({loadComment})
          </span>
        )
      }
    </>
  );
};

Comments.propTypes = {
  comments: PropTypes.array,
  loadComment: PropTypes.string
};

export default Comments;
