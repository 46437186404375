import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import FreePageHeader from 'components/common/FreePageHeader';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getPageId, setPageId, setSelectedPageForAnalytics } from 'redux/slices/analytics_slice';

const PlatformHeader = (props) => {
    const dispatch = useDispatch();
    const pageId = useSelector(getPageId);
    const [selectedPage, setSelectedPage] = useState({});
    const { pages, platform, account } = props;

    useEffect(() => {
        if (!pageId) {
            let page = pages[0] ?? {};
            dispatch(setPageId(page.id));
        }
    }, [pages, pageId]);

    useEffect(() => {
        const page = pages.find((x) => x.id === pageId);
        setSelectedPage({ ...page });
        dispatch(setSelectedPageForAnalytics({ ...page }));
    }, [pages, pageId]);

    return (
        <FreePageHeader
            titleTag="h5"
            className="mb-3">
            <Flex
                alignItems={'center'}
                className={'gap-2 flex-1'}
                wrap={'wrap'}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    color: platform.color,
                    alignSelf: 'center',
                    // background: '#dde7f5',
                    marginRight: 5,
                    width: 40,
                    height: 40,
                    borderRadius: 100,
                    justifyContent: 'center',
                    position: 'relative'
                }}
                >
                    <FontAwesomeIcon
                        icon={platform.icon}
                        variant={'light'}
                        style={{
                            width: 25,
                            height: 25
                        }}
                    />
                </div>
                <h5>{account.user_name}</h5>
                <div style={{ flex: 1 }}></div>

                <Dropdown size="md">
                    <Dropdown.Toggle variant="primary" id="dropdown-basic" style={{ minWidth: '200px', height: '100px' }}>
                        {pageId ? (
                            <>
                                <Avatar
                                    src={selectedPage.picture}
                                    alt={selectedPage.name}
                                    style={{ width: 15, height: 15 }} />
                                <p className='text-bold' style={{ fontSize: '12px' }}>{selectedPage?.name}</p>
                            </>
                        ) : (
                            'Select Page'
                        )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {pages && pages.map((page) => (
                            <Dropdown.Item
                                key={page.id}
                                href='#'
                                onClick={() => {
                                    dispatch(setPageId(page.id));
                                    setSelectedPage({ ...page })
                                }}>
                                <Flex alignItems={'center'} justifyContent={'space-between'} style={{ alignItems: 'center', gap: '10px' }}>
                                    <div>
                                        <Avatar
                                            src={page.picture}
                                            alt={page.name}
                                            style={{
                                                width: 20,
                                                height: 20
                                            }} />
                                    </div>
                                    <p className='text-bold'>{page?.name}</p>
                                </Flex>
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </Flex>
        </FreePageHeader>
    )
}


export default PlatformHeader;