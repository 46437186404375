import { LoadingOverlay } from "components/common/g-drive/styles/folders";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, ProgressBar, Spinner } from "react-bootstrap";
import ReactPlayer from "react-player";
import { ItemHolder } from "./styles/external_video_styles";

const EachExternalShutterstockVideosDisplay = (props) => {
  const [fetchController, setFetchController] = useState(null);
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [totalBytes, setTotalBytes] = useState(0);
  const [stopFilefetching, setStopFilefetching] = useState(false);
  const [buttonText, setButtonText] = useState("Select Video");

  const { handleClick, x } = props;

  const convertBlobToFile = (blob, fileName) => {
    return new File([blob], fileName, {
      type: blob.type,
      lastModified: new Date().getTime(),
    });
  };

  useEffect(() => {
    if (stopFilefetching) {
      fetchController.abort();
      setShowProgress(false);
      setProgress(0);
      setStopFilefetching(false);
    }
  }, [stopFilefetching]);

  const downloadFile = async (url, fileType, photographer) => {
    console.log("downloading file............");
    setButtonText("Fetching Video...");
    const _fetchController = new AbortController();
    const response = await fetch(url, {
      method: "GET",
      signal: _fetchController.signal,
    });
    setFetchController(_fetchController);

    const contentLength = response.headers.get("content-length");
    if (!showProgress) {
      setShowProgress(true);
    }
    const total = parseInt(contentLength, 10);
    setTotalBytes(total);
    let loaded = 0;
    const res = new Response(
      new ReadableStream({
        async start(controller) {
          const reader = response.body.getReader();
          for (;;) {
            const { done, value } = await reader.read();
            if (done) break;
            loaded += value.byteLength;
            let newLoaded = ((loaded / total) * 100).toFixed(2);
            setProgress(newLoaded);
            console.log(loaded, total, "loaded, total");
            controller.enqueue(value);
          }

          controller.close();
        },
      })
    );
    const buffer = await res.arrayBuffer();
    setShowProgress(false);
    let newBlob = new Blob([buffer], { type: fileType });
    const blobUrl = URL.createObjectURL(newBlob);
    console.log(blobUrl, "blob download");
    let fileName;
    let splitedFileType = fileType.split("/");
    let extension = splitedFileType[splitedFileType.length - 1];
    fileName = `${url}.${extension}`;
    const convertedFIle = convertBlobToFile(newBlob, fileName);
    setButtonText("Select Video");
    handleClick(blobUrl, photographer, convertedFIle);
  };

  const handleStopDownload = () => {
    setButtonText("Select Video");
    setStopFilefetching(true);
  };
  return (
    <>
      {showProgress && (
        <LoadingOverlay>
          <div
            style={{
              backgroundColor: "#ffffff",
              position: "absolute",
              zIndex: 10,
              top: "50%",
              width: "40%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "0.5em 1em",
              transform: "translate(35px, 10px)",
            }}
          >
            <div
              style={{
                fontSize: "13px",
                marginBottom: "10px",
                position: "relative",
                width: "100%",
                color: "black",
              }}
            >
              <span
                style={{ marginRight: "10px" }}
              >{`${"Fetching File"}`}</span>
              {progress === 0 || isNaN(progress) || progress == null ? (
                <Spinner animation="border" size="sm" variant="success" />
              ) : (
                <span>{`${progress}%`}</span>
              )}
              <div
                onClick={handleStopDownload}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                }}
              >
                Cancel
              </div>
            </div>
            <ProgressBar
              style={{
                width: "100%",
              }}
              striped
              variant="success"
              now={`${progress || totalBytes}`}
            />
          </div>
        </LoadingOverlay>
      )}
      <ItemHolder>
        <ReactPlayer
          controls
          muted
          config={{
            file: {
              attributes: {
                autoPlay: false,
              },
            },
          }}
          url={x?.assets?.preview_mp4?.url}
          width={"200px"}
          height={"150px"}
          style={{
            width: "200px",
            height: "150px",
          }}
        />
        <Button
          style={{
            alignSelf: "flex-end",
            backgroundColor: "#1e63fe",
            minHeight: 35,
          }}
          size="sm"
          centered
          className={`px-3 px-sm-5`}
          onClick={() => {
            console.log(x, "item");
            downloadFile(
              x?.assets?.preview_mp4?.url,
              x?.media_type,
              x?.user?.name ? x?.user?.name : "Shutterstock"
            );
            // handleClick(x?.video_files[0]?.link, x?.user?.name)
          }}
        >
          {buttonText}
        </Button>
      </ItemHolder>
    </>
  );
};

export default EachExternalShutterstockVideosDisplay;
