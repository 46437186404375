import { Check, CheckCircle, Folder, Hand, Swap } from "@phosphor-icons/react";
import Avatar from "components/common/Avatar";
import Flex from "components/common/Flex";
import SoftBadge from "components/common/SoftBadge";
import React, { useState } from "react";
import {
    Form, InputGroup, Row,
    Col, Button, Modal
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useOrganizationStore, { defaultOrganization } from "state/organization_store";
import { useSelector } from 'react-redux';
import { getActiveWorkSpace, switchingWorkspace } from "redux/slices/workspaceslice";
import StringUtils from "utils/string";
import OrganizationsToMoveSelectedWorkspacesTo from "../organizations_to_move_selected_workspaces_to";
import { toast } from "react-toastify";
import useActiveOrganization from "../../hooks/useActiveOrganization";
import useWorkspaceSwitcher from "../../hooks/useWorkspaceSwitcher";
import { getLoggedInUser } from "redux/slices/user_slice";
import FullScreenSpinner from "components/common/FullScreenSpinner";

export default function OrganizationWorkspaceSettings() {

    const { t } = useTranslation();
    const activeWorkspace = useSelector(getActiveWorkSpace);
    const allWorkspaces = useOrganizationStore(state => state.allWorkspaces);
    const organizationsFromStore = useOrganizationStore(state => state.organizationList);
    const organizationsMap = new Map();
    if ((organizationsFromStore ?? []).length == 0) {
        organizationsMap.set(defaultOrganization._id, defaultOrganization);
    }
    organizationsFromStore.filter(org => org).forEach(org => organizationsMap.set(org._id, org));
    const organizations = Array.from(organizationsMap.values()).filter(org => !org.new && org._id !== defaultOrganization._id);
    const [activeOrganization, setActiveOrganization] = useActiveOrganization();

    const [searchTerm, setSearchTerm] = useState("");
    const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
    const [openOrganizationsToMoveTo, setOpenOrganizationsToMoveTo] = useState(false);

    const authenticatedUser = useSelector(getLoggedInUser);
    const switchWorkspace = useWorkspaceSwitcher(authenticatedUser);
    const workspaceSwitchHappening = useSelector(switchingWorkspace);

    const handleWorkspaceSelection = (workspace) => {
        const isSelected = selectedWorkspaces.some(selected => selected._id === workspace._id);
        if (isSelected) {
            setSelectedWorkspaces(prev => prev.filter(selected => selected._id !== workspace._id));
        } else {
            setSelectedWorkspaces(prev => [...prev, workspace]);
        }
    };

    const isWorkspaceSelected = (workspace) => {
        return selectedWorkspaces.some(selected => selected._id === workspace._id);
    };

    const filteredManagedWorkspaces = allWorkspaces
        .filter(workspace =>
            workspace.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            workspace.organization?._id === activeOrganization?._id
        )
        .sort((a, b) => {
            if (a._id === activeWorkspace?._id) return -1;
            if (b._id === activeWorkspace?._id) return 1;
            return 0;
        });

    const filteredUnmanagedWorkspaces = allWorkspaces.filter(workspace =>
        workspace.organization?._id !== activeOrganization?._id &&
        workspace.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const canBeSelectedAsActiveWorkspace = () => {
        if (selectedWorkspaces.length === 1) {
            let workspace = selectedWorkspaces[0];
            if (workspace.organization?._id !== activeOrganization?._id) {
                return 1
            } else {
                return 0;
            }
        }
        return 0;
    }

    return (
        <Flex
            direction={'column'}
            style={{
                transition: 'all .5s'
            }}>
            <InputGroup className="mb-3">
                <InputGroup.Text>{t('search_workspaces')}</InputGroup.Text>
                <Form.Control
                    type="text"
                    placeholder={t('search')}
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    className="outline-none"
                />
            </InputGroup>
            <Row className="mb-3">
                <Col>
                    <h5>{t('workspaces_managed_by_organization', { organization_name: activeOrganization?.name })}</h5>
                    {filteredManagedWorkspaces.length > 0 ? (
                        filteredManagedWorkspaces.map(workspace => (
                            <SoftBadge
                                key={workspace._id}
                                bg={isWorkspaceSelected(workspace) ? 'info' : 'primary'}
                                className="me-2 mb-2 cursor-pointer rounded-pill"
                                onClick={() => handleWorkspaceSelection(workspace)}
                                style={{
                                    minHeight: 10
                                }}
                            >
                                <Flex alignItems={'center'} className={'gap-2'}>
                                    {
                                        workspace.avatar
                                            ? <Avatar src={workspace.avatar} size="s" alt={workspace.name} />
                                            : <Folder size={20} />
                                    }
                                    <Flex alignItems={'center'}>
                                        <span>{StringUtils.highlightMatches(workspace.name, searchTerm)}</span>
                                        {
                                            workspace._id === activeWorkspace?._id &&
                                            <SoftBadge bg="success" className="ms-1 ps-1">{t('active')}</SoftBadge>
                                        }
                                    </Flex>
                                    {
                                        isWorkspaceSelected(workspace) &&
                                        <CheckCircle size={16} weight="fill" />
                                    }
                                </Flex>
                            </SoftBadge>
                        ))
                    ) : (
                        <p>{t('no_managed_workspaces_found')}</p>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <h5>{t('workspaces_outside_organization', { organization_name: activeOrganization?.name })}</h5>
                    {filteredUnmanagedWorkspaces.length > 0 ? (
                        filteredUnmanagedWorkspaces.map(workspace => (
                            <SoftBadge
                                key={workspace._id}
                                bg={isWorkspaceSelected(workspace) ? 'info' : 'secondary'}
                                className="me-2 mb-2 cursor-pointer rounded-pill"
                                onClick={() => handleWorkspaceSelection(workspace)}
                                style={{
                                    minHeight: 10
                                }}
                            >
                                <Flex alignItems={'center'} className={'gap-2'}>
                                    {
                                        workspace.avatar
                                            ? <Avatar src={workspace.avatar} size="s" alt={workspace.name} />
                                            : <Folder size={20} />
                                    }
                                    <span>{StringUtils.highlightMatches(workspace.name, searchTerm)}</span>
                                    {
                                        isWorkspaceSelected(workspace) &&
                                        <CheckCircle size={16} weight="fill" />
                                    }
                                </Flex>
                            </SoftBadge>
                        ))
                    ) : (
                        <p>{t('no_unmanaged_workspaces_found')}</p>
                    )}
                </Col>
            </Row>
            <Flex direction={'column'} className={'w-100'}>
                <hr
                    style={{
                        transition: 'all 0.5s ease',
                        opacity: selectedWorkspaces.length > 0 ? 1 : 0,
                    }}
                />
                <Flex alignItems={'center'} justifyContent={'center'} className={'gap-2'}>
                    <Button
                        size="sm"
                        className="rounded-pill"
                        variant="info"
                        disabled={workspaceSwitchHappening}
                        style={{
                            transition: 'all 0.5s ease',
                            opacity: canBeSelectedAsActiveWorkspace(),
                        }}
                        onClick={() => {
                            let workspaceOrg = selectedWorkspaces[0].organization;
                            if (!workspaceOrg) {
                                setActiveOrganization(undefined);
                            } else {
                                setActiveOrganization(workspaceOrg);
                            }
                            switchWorkspace(selectedWorkspaces[0]._id);
                        }}
                    >
                        <Flex alignItems={'center'} className={'gap-2'}>
                            <span>{t('set_as_active_workspace')}</span>
                            <CheckCircle size={18} />
                        </Flex>
                    </Button>
                    <Button
                        size="sm"
                        className="rounded-pill"
                        style={{
                            transition: 'all 0.5s ease',
                            opacity: selectedWorkspaces.length > 0 ? 1 : 0,
                        }}
                        onClick={() => {
                            setOpenOrganizationsToMoveTo(true);
                        }}
                    >
                        <Flex alignItems={'center'} gap={'2'}>
                            <span>{t('move_selected_workspaces', { count: selectedWorkspaces.length })}</span>
                            <Swap size={18} weight="fill" />
                        </Flex>
                    </Button>
                </Flex>
            </Flex>
            <Modal
                show={openOrganizationsToMoveTo}
                onHide={() => {
                    setOpenOrganizationsToMoveTo(false);
                }}>
                <Modal.Header>
                    <Modal.Title>
                        <h5 className='fs-1'>{t('move_selected_workspaces_to_organization')}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OrganizationsToMoveSelectedWorkspacesTo
                        workspaces={selectedWorkspaces}
                        incomingOrganizations={organizations}
                        onWorkspacesMoved={() => {
                            setSelectedWorkspaces([]);
                            toast.success(t('selected_workspaces_moved'), { theme: 'colored' });
                            setOpenOrganizationsToMoveTo(false);
                        }}
                    />
                </Modal.Body>
            </Modal>
            {
                workspaceSwitchHappening &&
                <FullScreenSpinner postingFeedBack={{ posting: true, label: t('switching_to_workspace'), dimFactor: 8, textColor: 'white', variant: 'light' }} />
            }
        </Flex>
    );
}
