export default class ContentTips {

    static tips = [
        "Here is what I think about this",
        "I'm quite indifferent about this whole matter",
        "Alot of problems in the world would disappear if we really spend time to deliberate on this",
        "I sincerely am dumbfounded by the wealth of opinions that are shared in here",
        "It feels good to be part of this",
        "All I can say is that {tag} is really worth talking about",
        "Why is {tag} trending?",
        "Wow! feels good to see everyone talking about this",
        "It's shocking how many people are reacting to {tag}",
        "If I were to ask you about {tag}, what would you say?",
        "When it comes to {tag}, there is only one right answer.",
        "Unlike most people, I don't agree with",
        "I don't care about {tag}.",
        "I am excited about {tag}.",
        "It is just sad what people are saying about {tag}",
        "Reading the comments here, it is obvious that people don't understand what {tag} is really about",
        "It is good that we are discussing this {tag} right now."
    ];

    static celebrationTips = [
        "A time to find your joy, a time for {eventName}.",
        "We're thankful for {eventName}",
        "Keep your Goals for the {eventName}",
        "All I really want for {eventName} is happiness",
        "Warmest wishes this {eventName}",
        "Treasure your family and friends that accompany you during this incredible season of {eventName}",
        "It's a pleasure to witness this day once again",
        "We may not be together for the Holidays, but I’m with you in spirit. Happy holidays — I miss you!",
        "It feels good to be part of this once again",
        "My opinions about this are rather indifferent",
        "{eventName}Holidays — any holiday — are such a great opportunity to focus on bringing the family together.",
        "Yay it's {eventName}",
        "Whatever is meaningful and brings you merriment, may it become yours during the {eventName} and throughout the coming year.",
        "Sending a smile across the miles for a wonderful {eventName} holiday! ",
        "May a lovely {eventName} be followed by a year of blessings. ",
        "Take pride in this magnificent {eventName} that you get to spend with your family.",
        "I am blessed to share the true meaning of {eventName} with you this season and remember the ones we love.",
        "May the wonderful spirit of {eventName} follow you throughout the holiday season.",
        "The present of {eventName} is a cherished indication that we are loved! I wish you a happy {eventName} filled with light.",
        "Joy and good cheer this {eventName}",
        "Love is all around this {eventName}",
        "Cheers to a wonderful holiday season",
        "Sending our best to you and yours."
    ];

    static async generateContentFromTagLine(tag) {
        let randomTip = this.tips[Math.round(Math.random() * this.tips.length - 1)];
        if (randomTip.includes("{tag}")) {
            randomTip = randomTip.replace(/{tag}/g, tag);
        } else {
            randomTip = `${randomTip} ${tag}`;
        }
        return randomTip;
    }

    static generateContentFromEventDay(eventName) {
        let randomEventMsg = this.celebrationTips[Math.round(Math.random() * this.celebrationTips.length - 1)];
        if (randomEventMsg.includes("{eventName}")) {
            randomEventMsg = randomEventMsg.replace(/{eventName}/g, eventName);
        } else {
            randomEventMsg = `${randomEventMsg} ${eventName}`;
        }
        return randomEventMsg;
    }

}