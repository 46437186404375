import React, { useState, useEffect } from "react";
import { Button, Card, Form, FormControl } from "react-bootstrap";
import ConnectedAccount from "../connected_accounts/connected_account";
import Flex from "components/common/Flex";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { useSelector } from "react-redux";
import APIService from "http/api_service";
import { toast } from "react-toastify";
import WordpressSelfHostedConnectedAccount from "./wordpress_connected_account";
import { PlatformIdentifier } from "../../../../constants";
import FalconCardHeader from "components/common/FalconCardHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCurrentHostnameDomain } from 'utils/domain';

const WordpressConnectionPage = ({ props }) => {
  const {
    processing,
    connectAccount,
    connectedAccounts,
    NewAccountConnectionView,
  } = props;

  const hostnameDomain = getCurrentHostnameDomain();

  const workSpace = useSelector(getActiveWorkSpace);
  const [siteName, setSiteName] = useState("");
  const [siteUrl, setSiteUrl] = useState("");
  const [email, setEmail] = useState("");
  const [appPassword, setAppPassword] = useState("");
  const [openWordpressConnectOption, setWordpressConnectOption] = useState(false);
  const [processingConnection, setProcessingConnection] = useState(false);

  const handleConnect = () => {
    setProcessingConnection(true)
    const requestBody = { 
      website_name: siteName, 
      website_url: siteUrl,
      username: email,
      app_password: appPassword, 
      self_hosted: true
    }; 
    APIService.connectSelfHostedWordpress(workSpace['_id'], requestBody, (response ,error) => { 
      if (error) { 
        toast.error(error, { theme: 'colored' }); 
        setProcessingConnection(false);
        return;
      }
      if (response) { 
        let { data } = response;
        toast.success(response.message, { theme: 'colored' });
        setProcessingConnection(false);
        window.location.reload();
        return;
      }
    })
  };

  const selfHostedConnectionField = (
    <Card>
      <Card.Header>
        <h6>
          Connect self-hosted Wordpress sites
        </h6>
      </Card.Header>
      <Card.Body className="bg-light">
        <div className="d-flex justify-content-center">
          <div style={{ width: "60%" }}>
            <Form.Label>Website Name</Form.Label>
            <FormControl
              type="text"
              value={siteName}
              // placeholder="Growth Diary"
              onChange={(e) => {
                setSiteName(e.target.value);
              }}
            />

            <Form.Label>Website URL</Form.Label>
            <FormControl
              type="text"
              value={siteUrl}
              // placeholder="https://www.growthdiary.co"
              onChange={(e) => {
                setSiteUrl(e.target.value);
              }}
            />

            <Form.Label>Username</Form.Label>
            <FormControl
              type="text"
              value={email}
              // placeholder="growthdiary"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />

            <Form.Label>Application Password</Form.Label>
            <FormControl
              type="password"
              value={appPassword}
              // placeholder="Enter Application Password"
              onChange={(e) => {
                setAppPassword(e.target.value);
              }}
            />

            <Button
              variant="primary"
              disabled={processingConnection}
              isWorking={processingConnection}
              className="mt-3"
              onClick={handleConnect}
              style={{ width: "100%" }}
            >
              {" "}
              {processingConnection ? "Connecting Wordpress..." : "Connect"}
            </Button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );

  const wordpressConnectionGuideline = (
    <Card className="mt-5 mb-3">
      <FalconCardHeader
        title={
          <Flex>
            <FontAwesomeIcon icon="exclamation-triangle" className="fs-2 text-info" />
            <div className="ms-1 flex-1">
              <h4 className="alert-heading text-info">Please note</h4>
            </div>
          </Flex>
        }
      />
      <Card.Body className="bg-light">
        <ul className="ms-0 ps-card mb-2">
          <li className="py-1">
            Create an app password from your WordPress account. This will be used to authenticate the connection between Postly and your self-hosted WordPress site.
          </li>
          <li className="py-1">
            To create an app password:
            <ul>
              <li>Log in to your WordPress admin panel using an account with administrator privileges.</li>
              <li>Navigate to Users &gt; Your Profile.</li>
              <li>Scroll down to the section titled "Application Passwords".</li>
              <li>Click the button "Add New Application Password".</li>
              <li>Give your new password a descriptive name (e.g., "Mobile App Login").</li>
              <li>Crucially: Copy and paste the generated password to a secure location. You won't be able to retrieve it later.</li>
            </ul>
          </li>
          <li className="py-1">
            Generate a new app password and make a note of it. This password will be used in the next step.
          </li>
          <li className="py-1">
            Return to the Postly application and enter the following details:
            <ul>
              <li>Website Name: Enter the name of your self-hosted WordPress site.</li>
              <li>Website URL: Enter the URL of your self-hosted WordPress site.</li>
              <li>Username: Enter the username associated with your WordPress account.</li>
              <li>Application Password: Enter the app password you generated in the previous step.</li>
            </ul>
          </li>
          <li className="py-1">
            Click the "Connect" button to initiate the connection process.
          </li>
          <li className="py-1">
            Postly will attempt to establish a connection with your self-hosted WordPress site using the provided credentials. If the connection is successful, you will receive a success message.
          </li>
          <li className="py-1">
            If there are any errors or issues during the connection process, an error message will be displayed. Double-check the entered details and try again.
          </li>
          <li className="py-1">
            Please note that you must have admin access to your self-hosted WordPress site in order to connect and post successfully.
          </li>
          <li className="py-1">
            For a detailed guide and demo video: {" "}
            { (hostnameDomain === "app.postly.ai" || hostnameDomain === "localhost") ?
              <a href="https://postly.ai/wordpress" rel="noopener noreferrer">https://postly.ai/wordpress</a> :
              <>Contact support</>
            }
          </li>
        </ul>
      </Card.Body>
    </Card>
  );

  const connectView = ( 
    <>
    <Card className="mb-4">
      <Card.Header>
        <h6>
          Connect Wordpress.com sites
        </h6>
      </Card.Header>
      <Card.Body>
        <div className="d-flex justify-content-center">
          <Button
            variant={"primary"}
            disabled={processing}
            isWorking={processing}
            onClick={connectAccount}
            style={{
              color: "white",
              minWidth: 200,
              display: "flex",
              fontSize: 20,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {processing ? "Please wait..." : "Connect"}
          </Button>
        </div>
      </Card.Body>
    </Card>
    <div>{selfHostedConnectionField}</div>
    {wordpressConnectionGuideline}
  </>
  )

  const atLeastOneAccountIsConnectedView = () => {
    return (
      <Flex direction={"column"} justifyContent={"center"}>
        <Flex
          alignItems={"center"}
          className={"gap-3 pt-2"}
          justifyContent={"center"}
          wrap={"wrap"}
        >
          {connectedAccounts?.length > 0 &&
            connectedAccounts?.map((account, index) => {
                return (
                  <div key={account._id} id={account._id}>
                    <ConnectedAccount workSpace={workSpace} account={account} />
                  </div>
                );
            })}
          <NewAccountConnectionView setWordpressConnectOption={setWordpressConnectOption} />          
        </Flex>
        {wordpressConnectionGuideline}
      </Flex>
    );
  };

  if(openWordpressConnectOption) { 
    return connectView;
  }

  if (connectedAccounts?.length > 0) {
    return atLeastOneAccountIsConnectedView();
  }
  return connectView;
};

export default WordpressConnectionPage;
