export const grabHashTags = (postTextBody, stripNumbersAfterHashTag = false) => {
    let words = postTextBody.split(" ");
    let hashTags = words.filter(word => word.includes("#"));
    let refinedHashTags = [];
    for (let hashTag of hashTags) {
        let indexOfHashTag = hashTag.indexOf("#")
        hashTag = hashTag.substr(indexOfHashTag).split(" ")[0]
        if (stripNumbersAfterHashTag) {
            hashTag = hashTag.replace(/[0-9]/g, '');
        }
        hashTag = hashTag.replace(new RegExp(/\r?\n|\r/g), "").replace(".", "");
        refinedHashTags.push(hashTag);
    }
    return Array.from(new Set(refinedHashTags));
}