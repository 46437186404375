import Flex from "components/common/Flex";
import Divider from "components/common/Divider";
import APIService from "http/api_service";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import {
  PlatformIdentifier,
  RoutePaths,
  WorkSpaceRole,
} from "../../../../constants";
import FeedCard, { extractUrlsFromPost } from "./FeedCard";
import WorkSpacePostDetailsDates from "./dates";
import FeedbackField from "./team-approvals/FeedbackField";
import TeamFeedbackView from "./team-approvals/team_feedback_view";
import { setOpenPostDetailsDialog } from "redux/slices/postslice";
import StringUtils from "utils/string";
import FeedAnalyticsView from "./FeedAnalyticsView";
import { setDeletedPost } from "../../../../redux/slices/postslice";
import { getPlatformMetaData } from "utils/platform_utils";
import MetaDataPop from "./MetaDataPop";
import { format } from "date-fns";
import csvDownload from "json-to-csv-export";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { useSelector } from "react-redux";
import { getLoggedInUser } from "redux/slices/user_slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const FullScreenPostDetailsDialog = ({ postId, timeToPost }) => {
  const dispatch = useDispatch();
  const [post, setPost] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [pageError, setPageError] = useState();
  const workSpace = useSelector(getActiveWorkSpace);
  const loggedInUser = useSelector(getLoggedInUser);
  const [aboutToDisapprovePost, setAboutToDisapprovePost] = useState(false);
  const [reasonsForDisapproval, setReasonsForDisapproval] = useState();
  const [disapprovingPost, setDisapprovingPost] = useState(false);
  const [approvingPost, setApprovingPost] = useState(false);
  const [disapprovals, setDisApprovals] = useState(
    post?.data?.disapprovals ?? {}
  );
  const [approvals, setApprovals] = useState(post?.data?.approvals ?? {});
  const [disapproved, setDisapproved] = useState(false);
  const [approved, setApproved] = useState(false);
  const [recurringPost, setRecurringPost] = useState();
  const [postTargets, setPostTargets] = useState();
  const [platformMetaData, setPlatformMetaData] = useState({});

  function convertKeysToSnakeCase(obj) {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map((item) => convertKeysToSnakeCase(item));
    }

    const snakeCaseObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const snakeCaseKey = key.replace(
          /[A-Z]/g,
          (match) => `_${match.toLowerCase()}`
        );
        snakeCaseObj[snakeCaseKey] = convertKeysToSnakeCase(obj[key]);
      }
    }

    return snakeCaseObj;
  }
  const generateHeadersForMetaDataCsvExport = async (targets) => {
    let headers = ["Created By", "Published Date", "PostLink"];
    for (let index = 0; index < postTargets.length; index++) {
      const element = postTargets[index];
      if (element.identifier === PlatformIdentifier.GOOGLE_MY_BUSINESS) {
        headers = [
          ...headers,
          "Gmb CTA Action Type",
          "Gmb CTA Action Url",
          "Gmb Coupon Code",
          "Gmb Coupon Redeem URL",
          "Gmb Event Title",
          "Gmb Post Type",
          "Gmb Summary",
          "Gmb Terms And Conditions, Gmb Event Schedule",
        ];
      }
      if (element.identifier === PlatformIdentifier.INSTAGRAM) {
        headers = [
          ...headers,
          "Publish IG Video As Stories",
          "Publish IG Video As Reel",
          "Publish IG Image As Stories",
          "First Comment",
        ];
      }
      if (element.identifier === PlatformIdentifier.YOUTUBE) {
        headers = [
          ...headers,
          "Notify YouTube Subscribers",
          "YouTube Video Category",
          "YouTube Video Embeddable",
          "YouTube Video License",
          "YouTube Video Made For Kids",
          "YouTube Video Privacy",
          "YouTube Video Title",
          "YouTube Video Tags",
        ];
      }
      if (element.identifier === PlatformIdentifier.PINTEREST) {
        headers = [
          ...headers,
          "Pinterest Alt Text",
          "Pinterest Destination Link",
          "Pinterest Title",
        ];
      }
    }
    return headers;
  };

  // TODO: will come to the below code later
  // const exportMetadataAsCSV = async (data) => {
  //     const headers = await generateHeadersForMetaDataCsvExport(postTargets);
  //     console.log("🚀 ~ file: FullScreenPostDetailsDialog.js:66 ~ exportMetadataAsCSV ~ headers:", headers)
  //     const displayMetadataForPostTargets = postTargets?.map((p, index) => {
  //         const platformMetaData = getPlatformMetaData(p.identifier, post?.data, post);
  //         const snakeCaseKeys = convertKeysToSnakeCase(platformMetaData);
  //         console.log("🚀 ~ file: FullScreenPostDetailsDialog.js:90 ~ displayMetadataForPostTargets ~ snakeCaseKeys:", snakeCaseKeys)
  //         return snakeCaseKeys;
  //     })
  //     console.log("🚀 ~ file: FullScreenPostDetailsDialog.js:72 ~ displayMetadataForPostTargets ~ displayMetadataForPostTargets:", displayMetadataForPostTargets)

  //     let fileName = `metadata${format(new Date(), 'dd MMM yyyy')}.csv`
  //     // let fileName = `metadata${format(new Date(post.data.time_to_post) ?? new Date(), 'dd MMM yyyy')}.csv`
  //     const dataToConvert = {
  //         data: displayMetadataForPostTargets,
  //         filename: fileName,
  //         delimiter: ',',
  //         headers: headers,
  //     }
  //     csvDownload(dataToConvert)
  // }

  const displayMetadataForPostTargets = postTargets?.map((p, index) => {
    const platformMetaData = getPlatformMetaData(
      p.identifier,
      post?.data,
      post
    );
    return (
      <MetaDataPop
        key={index}
        target={p.identifier}
        platformMetaData={platformMetaData}
      />
    );
  });
  const displayMetadataForGeneral = postTargets?.map((p, index) => {
    if (index === 0) {
      const platformMetaData = getPlatformMetaData(null, post?.data, null);
      if (platformMetaData) {
        return (
          <MetaDataPop
            key={index}
            target={p.identifier}
            platformMetaData={platformMetaData}
            isGeneral={true}
          />
        );
      }
    }
  });
  const displayPreScheduleMetadata = postTargets?.map((p, index) => {
    if (index === 0) {
      const platformMetaData = post?.data?.pre_schedule_metadata
      if (platformMetaData) {
        return (
          <MetaDataPop
            key={index}
            target={p.identifier}
            platformMetaData={platformMetaData}
            preSchedule={true}
          />
        );
      }
    }
  });

  useEffect(() => {
    if (postId) {
      APIService.fetchPost(workSpace["_id"], postId, (response, error) => {
        setLoadingData(false);
        if (error) {
          setPageError(error);
          dispatch(setOpenPostDetailsDialog({ open: false }));
        } else {
          let { data } = response;
          if (data) {
            data = extractUrlsFromPost(data);
            // console.log(data);
            if (typeof data.created_by && data.data.createdBy) {
              data.created_by = data.data.createdBy;
            }
            setPost(data);
            setPostTargets(data.data.targets);
          } else {
            dispatch(setOpenPostDetailsDialog({ open: false }));
            setPageError("Oops! We missed it here");
          }
        }
      });
    }
  }, [postId]);

  const disapprovePost = () => {
    setDisapprovingPost(true);
    APIService.disApprovePost(
      workSpace["_id"],
      post._id,
      {
        reason: reasonsForDisapproval,
      },
      (response, error) => {
        setDisapprovingPost(false);
        if (error) {
          toast.error(error, { theme: "colored" });
          return;
        }
        let { data, message } = response;
        toast.success(message, { theme: "colored" });
        setPost(data);
        setDisapproved(true);
      }
    );
  };

  const approvePost = () => {
    setApprovingPost(true);
    APIService.approvePost(workSpace["_id"], post._id, (response, error) => {
      setApprovingPost(false);
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      let { data, message } = response;
      toast.success(message, { theme: "colored" });
      setPost(data);
      setApproved(true);
    });
  };

  useEffect(() => {
    setDisApprovals(post?.data?.disapprovals ?? {});
    setApprovals(post?.data?.approvals ?? {});
  }, [post]);

  const dropPostDetailsDialog = () => {
    let currentWindowLocation = window.location.href;
    let newLocation = StringUtils.substringBeforeLast(
      currentWindowLocation,
      "/"
    );
    window.history.pushState("", "", newLocation);
    dispatch(setOpenPostDetailsDialog({ open: false }));
  };

  const ApprovalList = () => {
    const approvals = post?.data?.approvals;
    return (
      <>
        <Col style={{ padding: "10px" }}>
          <p>Approved:</p>
          {Object.entries(post?.data?.approvals || {}).map(([key, value]) => (
            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "20px" }}>
              {/* <FontAwesomeIcon icon={faCheck} color="#339933" /> */}
              <Card.Text
                style={{
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {/* <FontAwesomeIcon icon={faCheck} color="#339933" />{" "} */}
                {value.avatar ? (
                  <img
                    src={value.avatar}
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "100%",
                    }}
                  />
                ) : (
                  <span style={{ fontSize: "20px" }}>&#128512;</span>
                )}
                {value?.email} {`{${value?.role}}`}
              </Card.Text>
            </div>
          ))}
        </Col>
      </>
    );
  };


  const DisApprovalList = () => {
    const approvals = post?.data?.disapprovals;
    return (
      <>
        <Col style={{ padding: "10px" }}>
          <p>Rejected:</p>
          <>
            {Object.entries(post?.data?.disapprovals || {}).map(([key, value]) => (
              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "20px" }}>
                {/* <FontAwesomeIcon icon={faCheck} color="#339933" /> */}
                <Card.Text
                  style={{
                    fontSize: "12px",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {/* <FontAwesomeIcon icon={faCheck} color="#339933" />{" "} */}
                  {value.avatar ? (
                    <img
                      src={value.avatar}
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "100%",
                      }}
                    />
                  ) : (
                    <span style={{ fontSize: "20px" }}>&#128512;</span>
                  )}
                  {value?.email} {`{${value?.role}}`}
                </Card.Text>
              </div>
            ))}
          </>
        </Col>
      </>
    );
  };

  return (
    <>
      {loadingData && (
        <Flex className={"p-5"} alignItems={"center"} justifyContent={"center"}>
          <Spinner animation={"border"} />
        </Flex>
      )}
      {pageError && <Navigate to={RoutePaths.NOT_FOUND} />}
      {post && (
        <Flex direction={"column"}>
          <FeedCard
            rootCardClassNames={"mb-0 shadow-none"}
            post={post}
            fromPostDetailsDialog={true}
            headerProps={{
              parentModal: () => {
                dispatch(setOpenPostDetailsDialog({ open: false }));
              },
              onDeleteDoneCallback: (delPost) => {
                dropPostDetailsDialog();
                dispatch(setDeletedPost(delPost));
              },
              enableView: false,
            }}
          />
          <Flex direction={"column"}>
            <ApprovalList />
            <DisApprovalList />
          </Flex>
          {/* <FeedAnalyticsView />
                    <Divider /> */}
          {/* <MetaDataPop
                        target={target}
                        platformMetaData={platformMetaData}
                    /> */}
          {displayMetadataForGeneral}
          {displayMetadataForPostTargets}
          {displayPreScheduleMetadata}
          {/* {
                        <Button
                            // isWorking={disapprovingPost}
                            // disabled={disapprovingPost}
                            variant={'primary'}
                            style={{
                                maxWidth: 250,
                                margin: 'auto',
                                marginBottom: 30
                            }}
                            onClick={() => {
                                exportMetadataAsCSV();
                            }}>
                            Export Metadata as CSV
                        </Button>
                    } */}
          <WorkSpacePostDetailsDates
            post={{ ...post }}
            timeToPost={timeToPost}
            recurringPostHandler={(fetchedRecurringPost) => {
              if (fetchedRecurringPost) {
                setRecurringPost(fetchedRecurringPost);
              }
            }}
          />
          {!post.posted &&
            post.pending_approval &&
            post.created_by !== loggedInUser._id &&
            workSpace.role !== WorkSpaceRole.VIEWER && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                }}
              >
                {aboutToDisapprovePost && (
                  <FeedbackField
                    placeholder={"Add reason for disapproval (Optional)"}
                    incomingValue={reasonsForDisapproval}
                    onChange={(value) => {
                      setReasonsForDisapproval(value);
                    }}
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 15,
                    padding: 10,
                  }}
                >
                  {!aboutToDisapprovePost &&
                    !approvals.hasOwnProperty(loggedInUser._id) &&
                    !disapprovingPost &&
                    !approved &&
                    post?.data?.createdBy?._id !== loggedInUser._id && (
                      <Button
                        onClick={() => {
                          approvePost();
                        }}
                        isWorking={approvingPost}
                        disabled={approvingPost}
                        variant={"success"}
                        style={{
                          minWidth: 200,
                        }}
                      >
                        {approvingPost ? "Submitting..." : "Approve Post"}
                      </Button>
                    )}
                  {!disapprovals.hasOwnProperty(loggedInUser._id) &&
                    !approvingPost &&
                    !disapproved &&
                    post?.data?.createdBy?._id !== loggedInUser._id && (
                      <Button
                        isWorking={disapprovingPost}
                        disabled={disapprovingPost}
                        variant={"danger"}
                        style={{
                          minWidth: 200,
                        }}
                        onClick={() => {
                          if (!aboutToDisapprovePost) {
                            setAboutToDisapprovePost(true);
                          } else {
                            setAboutToDisapprovePost(false);
                            disapprovePost();
                          }
                        }}
                      >
                        {aboutToDisapprovePost
                          ? "Submit"
                          : `${disapprovingPost
                            ? "Submitting..."
                            : "Disapprove Post"
                          }`}
                      </Button>
                    )}
                </div>
              </div>
            )}
          <TeamFeedbackView
            post={post}
            style={{
              marginTop: 50,
            }}
          />
        </Flex>
      )}
    </>
  );
};
export default FullScreenPostDetailsDialog;
