/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { PlatformData, PlatformIdentifier } from "../../../../../constants";
import {
  getGoogleDriveManager,
  getMediaAddedViaSpecificPlaformUrlMethod,
  getSpecificMediaPlatformTied,
  getTwitterCurrentThreadIndexToAddMedia, setGoogleDriveManager
} from "../../../../../redux/slices/postslice";
import TwitterThreadPreviewAndEdit from "./twitterThreadPreviewAndEdit.js";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setSelectedPlatformForPreview } from "redux/slices/platformSlice";
import { getMediaArrangement, setMediaArrangement } from "redux/slices/publishable_media_arrangement_slice";
import { scanImages } from "./scanImages";
import { getPlatformVideoUploadLimit } from "redux/slices/workspaceslice";
import FigureUtils from "utils/figures";

const Twitter = ({
  imgSrc,
  viewOnly = false,
}) => {
  const dispatch = useDispatch();
  const mediaAddedViaSpecificPlaformUrlMethod = useSelector(
    getMediaAddedViaSpecificPlaformUrlMethod
  );
  const specificMediaPlatformTied = useSelector(getSpecificMediaPlatformTied);
  const mediaObjectsTotalLength = 4;
  const googleDriveManager = useSelector(getGoogleDriveManager)
  const mediaItemsArrangement = useSelector(getMediaArrangement);
  const [chunkedTweet, setChunkedTweet] = useState([])
  const [processAltSave, setProcessAltSave] = useState(false)
  const twitterCurrentThreadIndexToAddMedia = useSelector(
    getTwitterCurrentThreadIndexToAddMedia
  );
  const [platform] = useState(PlatformIdentifier.TWITTER)
  const platformVideoUploadLimit = useSelector(getPlatformVideoUploadLimit);
  let typingTimeout

  useEffect(() => {
    localStorage.setItem("satisfied_with_twitter_thread", "yes");
  }, []);

  useEffect(() => {
    setChunkedTweet(mediaItemsArrangement?.twitter)
  }, [mediaItemsArrangement?.twitter])

  const mediaRemoveHandler = (removableMediaObject, threadIndex) => {
    let mo = [...chunkedTweet] ?? [];
    let newMedia = mo.filter((item) =>
      item?.media?.includes(removableMediaObject)
    );
    let NestedMedias = newMedia[0].media;
    let indexOfMediaObject = newMedia[0].media.indexOf(removableMediaObject);
    if (indexOfMediaObject !== -1) {
      NestedMedias = NestedMedias?.filter((item, index) => {
        if (index != indexOfMediaObject) {
          return item;
        }
      });
    }
    NestedMedias = NestedMedias ?? [];
    let latestMediaObjects = [...NestedMedias];
    const twitterMediaClone = { ...mediaItemsArrangement };
    const newClone = twitterMediaClone?.twitter?.map((item, index) => {
      if (index === threadIndex) {
        let newObj = {
          media: latestMediaObjects, text: item.text, mediaEdited: true, textEdited: item?.textEdited,
        }
        return newObj;
      }
      return item;
    })

    dispatch(setMediaArrangement({ ...mediaItemsArrangement, twitter: newClone }))
    setChunkedTweet(newClone);
  }

  const updateMediaObjects = async (
    files,
    canCreateObjectURL = true,
    text,
    threadIndex,
    moreProps = {}
  ) => {
    localStorage.removeItem('twitterIndex')
    const caclength = files.length + mediaItemsArrangement?.twitter[threadIndex]?.media?.length;
    if (
      caclength > mediaObjectsTotalLength
    ) {
      toast.error(
        `Sorry, you can only upload a maximum of ${mediaObjectsTotalLength} files at a time.`, { theme: 'colored' }
      );
      return;
    }
    let mo = [];
    for (let file of files) {
      if (canCreateObjectURL) {
        let fileUrl = URL.createObjectURL(file, { autoRevoke: false });
        let fileName = file.name;
        let existingFile = mo.find((x) => x.url === fileUrl);
        if (!existingFile) {
          let fileExtension = `.${fileName.split(".").pop()}`;
          let newThreadObject = {
            name: fileName,
            url: fileUrl,
            extension: fileExtension,
            file: file,
            ...moreProps,
          };
          if (file?.size / 1000000 > 5 && moreProps?.is_video === false) {
            toast.error(
              `Image(s) above 5MB have been removed from twitter. Kindly upload images below 5MB.`,
              { theme: "colored" }
            );
            return;
          }
          if (platformVideoUploadLimit[platform] && file.size > platformVideoUploadLimit[platform]) {
            let errorMsg = `${file?.name} have been removed from ${platform} because it exceeds the allowed sized limit of ${FigureUtils.convertBytes(platformVideoUploadLimit[platform])?.value}.`
            if (platformVideoUploadLimit['canUpgrade']) {
              errorMsg = errorMsg + ` Upgrade your plan to get more upload limits.`;
            }
            toast.error(errorMsg, { theme: "colored" });
          } else {
            mo.push(newThreadObject);
          }
        }
      } else {
        let fileWithName = mo.find((item) => item.name === file.name);
        if (!fileWithName) {
          mo.push(file);
        }
      }
    }

    const twitterMediaClone = { ...mediaItemsArrangement };
    const newClone = twitterMediaClone?.twitter?.map((item, index) => {
      if (index === threadIndex) {
        let newObj = {
          media: [...item?.media ?? [], ...mo], text: item.text, mediaEdited: true, textEdited: item?.textEdited
        }
        return newObj;
      }
      return item;
    })
    const imagesMedia = mo?.filter(x => x.is_video == false);
    await scanImages(imagesMedia)
    dispatch(setMediaArrangement({ ...mediaItemsArrangement, twitter: newClone }))
    setChunkedTweet(newClone);
  };

  const mediaToReplace = async (comparebleUrl, newUrl, threadIndex, updatedMedias = undefined) => {
    const twitterMediaClone = { ...mediaItemsArrangement };
    let mediaToScan = [];
    const newClone = twitterMediaClone?.twitter?.map((item, index) => {
      if (index === threadIndex) {
        let mediaReplaced = [];
        if (updatedMedias) {
          mediaReplaced = updatedMedias
        } else {
          mediaReplaced = item?.media.map((x, i) => {
            if (x.url === comparebleUrl) {
              return { ...x, url: newUrl, optimized: true }
            }
            return x
          })
        }
        let newObj = {
          media: mediaReplaced, text: item.text, mediaEdited: true, textEdited: item?.textEdited
        }
        mediaToScan = newObj
        return newObj;
      }
      return item;
    })
    const imagesMedia = mediaToScan?.media?.filter(x => x.is_video == false);
    await scanImages(imagesMedia)
    dispatch(setMediaArrangement({ ...mediaItemsArrangement, twitter: newClone }))
    dispatch(setSelectedPlatformForPreview('refresh'));
    setTimeout(() => {
      dispatch(setSelectedPlatformForPreview(PlatformIdentifier.TWITTER));
    }, 1000);
    setChunkedTweet(newClone);
  }

  const saveAltValue = (threadIndex, url, value) => {
    if (!url || !value) return;
    const twitterMediaClone = { ...mediaItemsArrangement };
    const newClone = twitterMediaClone?.twitter?.map((item, index) => {
      if (index === threadIndex) {
        const updatedMedias = item?.media?.map((x, i) => {
          if (x.url === url) {
            return { ...x, alt_text: value }
          }
          return x;
        })
        let newObj = {
          media: updatedMedias, text: item?.text, textEdited: true, mediaEdited: item?.mediaEdited,
        }
        return newObj;
      }
      return item;
    })
    dispatch(setMediaArrangement({ ...mediaItemsArrangement, twitter: newClone }))
    setChunkedTweet(newClone);
    setProcessAltSave(false)
  }

  const handleUpdateTextFromEditBox = (text, threadIndex) => {
    clearTimeout(typingTimeout);
    const twitterMediaClone = { ...mediaItemsArrangement };
    const newClone = twitterMediaClone?.twitter?.map((item, index) => {
      if (index === threadIndex) {
        let newObj = {
          media: item?.media, text: text, textEdited: true, mediaEdited: item?.mediaEdited
        }
        return newObj;
      }
      return item;
    })

    dispatch(setMediaArrangement({ ...mediaItemsArrangement, twitter: newClone }))
    setChunkedTweet(newClone);
  }

  const addMediaFromUrl = (threadIndex, mediaIncoming) => {
    const twitterMediaClone = { ...mediaItemsArrangement };
    const newClone = twitterMediaClone?.twitter?.map((item, index) => {
      if (index == threadIndex) {
        let newObj = {
          media: [...item?.media, ...mediaIncoming], text: item?.text, textEdited: true, mediaEdited: item?.mediaEdited
        }
        return newObj;
      }
      return item;
    })
    if (googleDriveManager?.docs) {
      dispatch(setGoogleDriveManager({ ...googleDriveManager, docs: null }))
    }
    dispatch(setMediaArrangement({ ...mediaItemsArrangement, twitter: newClone }))
    setChunkedTweet(newClone);
  };

  const immediateActionWhenClieked = (remove) => {
    if (remove) {
      localStorage.removeItem("gdrivePlatform");
      return;
    }
    localStorage.setItem("gdrivePlatform", PlatformIdentifier.TWITTER);
  }

  useEffect(() => {
    if (!googleDriveManager?.docs || localStorage.getItem('gdrivePlatform') !== PlatformIdentifier.TWITTER) return;
    let threadIndex = localStorage.getItem('twitterIndex') ?? 0;
    addMediaFromUrl(threadIndex, [googleDriveManager?.docs]);
    immediateActionWhenClieked(true)
  }, [googleDriveManager])

  useEffect(() => {
    specificMediaPlatformTied === "twitter" &&
      mediaAddedViaSpecificPlaformUrlMethod.length &&
      addMediaFromUrl(
        twitterCurrentThreadIndexToAddMedia,
        mediaAddedViaSpecificPlaformUrlMethod
      );
  }, [mediaAddedViaSpecificPlaformUrlMethod]);

  const deleteThread = (threadIndex) => {
    const twitterMediaClone = { ...mediaItemsArrangement };
    const newClone = twitterMediaClone?.twitter?.filter((item, index) => index != threadIndex);
    dispatch(setMediaArrangement({ ...mediaItemsArrangement, twitter: newClone }))
    setChunkedTweet(newClone);
  }

  const addTwitterThread = (threadIndex, value) => {
    const twitterMediaClone = { ...mediaItemsArrangement };
    const newClone = twitterMediaClone?.twitter?.map((item, index) => {
      if (index === threadIndex && value) {
        let newObj = {
          media: item?.media, text: value, textEdited: true, mediaEdited: item?.mediaEdited
        }
        return newObj;
      }
      return item;
    })
    let newObj = {
      media: [],
      text: 'Start by editing this new thread here',
      textEdited: true,
    }
    newClone.splice(threadIndex + 1, 0, newObj);
    dispatch(setMediaArrangement({ ...mediaItemsArrangement, twitter: newClone }))
    dispatch(setSelectedPlatformForPreview('refresh'));
    setTimeout(() => {
      dispatch(setSelectedPlatformForPreview(PlatformIdentifier.TWITTER));
    }, 1000);
    setChunkedTweet(newClone);
  }

  // console.log(chunkedTweet, 'chunkedTweet')

  const mappedTweet = chunkedTweet?.map((item, index) => {
    try {
      return (
        <TwitterThreadPreviewAndEdit
          key={index}
          item={item}
          index={index}
          imgSrc={imgSrc}
          handleUpdateTextFromEditBox={handleUpdateTextFromEditBox}
          chunkedTweet={chunkedTweet}
          setChunkedTweet={setChunkedTweet}
          saveAltValue={saveAltValue}
          addTwitterThread={addTwitterThread}
          setProcessAltSave={setProcessAltSave}
          processAltSave={processAltSave}
          mediaToReplace={mediaToReplace}
          updateMediaObjects={updateMediaObjects}
          mediaRemoveHandler={mediaRemoveHandler}
          deleteThread={deleteThread}
          viewOnly={viewOnly}
        />
      );
    } catch (error) { }
  });

  return (
    <>
      <div>{mappedTweet}</div>
    </>
  );
};

export default Twitter;
