import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks
} from 'date-fns';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { setDatePeriod } from 'redux/slices/analytics_slice';

const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
  <div className="position-relative">
    <Form.Control
      size="sm"
      ref={ref}
      onClick={onClick}
      value={value}
      className="ps-4"
      onChange={e => {
        console.log({ e });
      }}
    />
    <FontAwesomeIcon
      icon="calendar-alt"
      className="text-primary position-absolute top-50 translate-middle-y ms-2"
    />
  </div>
));

const CustomDateInput = ({ date }) => {
  const startDateRef = useRef(null);
  return (
    <>
      <Form.Control value={format(date, 'MMM dd, yyyy')} size='sm' ref={startDateRef} />
    </>
  )
}

const DatePickerContainer = () => {
  const date = new Date();
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(date.setDate(date.getDate() - 28));
  const [endDate, setEndDate] = useState(new Date());
  const [selectDate, setSelectDate] = useState("");
  const [closeDatePicker, setCloseDatePicker] = useState(false);

  const dateOptions = [
    "Last 7 days",
    "Last 14 days",
    "Last week",
    "Last 28 days",
    "Last 90 days",
    "This month",
    "Last month",
    "Custom"
  ];

  const handleApplyDates = () => {
    dispatch(setDatePeriod({
      since: moment(startDate).format('YYYY-MM-DD'),
      until: moment(endDate).format('YYYY-MM-DD')
    }));
    setCloseDatePicker(true);
    setSelectDate('Custom');
  }

  const handleDateChange = item => {
    setSelectDate(item);
    let startTime, endTime;
    if (item === "Last 7 days") {
      startTime = startOfDay(subDays(new Date(), 6));
      endTime = endOfDay(new Date());
    } else if (item === "Last 14 days") {
      startTime = startOfDay(subDays(new Date(), 13));
      endTime = endOfDay(new Date());
    } else if (item === "Last week") {
      startTime = startOfWeek(subWeeks(new Date(), 1));
      endTime = endOfWeek(subWeeks(new Date(), 1));
    } else if (item === "Last 28 days") {
      startTime = startOfDay(subDays(new Date(), 27));
      endTime = endOfDay(new Date());
    } else if (item === "Last 90 days") {
      startTime = startOfDay(subDays(new Date(), 89));
      endTime = endOfDay(new Date());
    } else if (item === "This month") {
      startTime = startOfMonth(new Date());
      endTime = endOfDay(new Date());
    } else if (item === "Last month") {
      startTime = startOfMonth(subMonths(new Date(), 1));
      endTime = endOfMonth(subMonths(new Date(), 1));
    } else if (item === "Custom") {
      setCloseDatePicker(false);
      return;
    }
    setStartDate(startTime);
    setEndDate(endTime);
    startTime = format(startTime, "yyyy-MM-dd");
    endTime = format(endTime, "yyyy-MM-dd");
    dispatch(setDatePeriod({
      since: startTime,
      until: endTime
    }));
    setCloseDatePicker(true);
  }

  const handleCloseDatePicker = () => {
    setCloseDatePicker(true);
  }

  const handleOpenDatePicker = () => {
    setCloseDatePicker(false);
  }

  useEffect(() => {
    setSelectDate("Last 28 days");
  }, []);

  const DateSelectionContainer = ({ className, children }) => {
    return (
      <div>
        <Flex
          justifyContent={"between"}
          alignItems={"start"}
          className={"gap-3"}
          style={{
            background: "#fff",
            padding: "16px",
            textWrap: "nowrap",
            boxShadow: "1px 1px 5px #000",
            borderRadius: "8px"
          }}>
          <div className='d-flex flex-column items-start p-3'>
            <Form>
              {dateOptions.map(item => (
                <Form.Check
                  key={item}
                  label={item}
                  type="radio"
                  id={`radio-${item}`}
                  name="dates"
                  checked={selectDate === item}
                  onChange={() => handleDateChange(item)}
                />
              ))}
            </Form>
          </div>
          <div
            style={{ color: "#fff" }}>
            <CalendarContainer className={className}>
              <div style={{ position: "relative" }}>{children}</div>
            </CalendarContainer>
            <Flex
              justifyContent={"end"}
              className="gap-3 mt-4 text-black"
              style={{
                cursor: 'pointer'
              }}
            >
              <Button
                style={{
                  background: "#F8F9FB",
                  color: "#1C2B33",
                  border: "1px solid #F8F9FB"
                }}
                onClick={handleCloseDatePicker}
              >
                Cancel
              </Button>
              <Button
                onClick={handleApplyDates}>
                Apply
              </Button>
            </Flex>
          </div>
        </Flex >
      </div>
    );
  };
  return (
    <>
      <div>
        <DatePicker
          selected={startDate}
          onChange={(dates) => {
            const [start, end] = dates;
            setStartDate(start);
            setEndDate(end);
          }}
          wrapperClassName='border-none'
          startDate={startDate}
          onInputClick={handleOpenDatePicker}
          formatWeekDay={day => day.slice(0, 3)}
          endDate={endDate}
          selectsRange
          dateFormat="MMM dd"
          isClearable={false}
          shouldCloseOnSelect={false}
          calendarContainer={DateSelectionContainer}
          popperClassName={closeDatePicker ? 'd-none' : 'd-block'}
          customInput={<DatePickerInput />}
        />
      </div>
    </>
  )
};

DatePickerInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func
};

export default DatePickerContainer;
