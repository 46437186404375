/***
 * 
Going forward, it is recommended we transition to this central storage engine 
in order to facilitate easy swapping of storage systems and avoid limiting 
ourselves to local storage.

Currently, the backing storage engine remains local storage. 
However, this will soon change due to observed limitations on 
the local storage capacity.

Therefore, it is advised to refrain from direct communication with 
local storage and instead migrate the logic here. 
This will allow for seamless swapping of storage systems.

Furthermore, ensure that all operations you perform here are asynchronous, 
as other storage engines may operate asynchronously, unlike local storage.

*/
export default class StorageEngine {

    static async clear() {
        localStorage.clear();
    }

    static async getItem(key) {
        return localStorage.getItem(key);
    }

    static async setItem(key, value) {
        localStorage.setItem(key, value);
    }

    static async removeItem(key) {
        localStorage.removeItem(key);
    }

    static async fetchPostsFromCache(key) {
        let cachedPosts = localStorage.getItem(key);
        if (cachedPosts) {
            return JSON.parse(cachedPosts);
        }
        return [];
    }

    static async cachePosts(key, posts) {
        localStorage.setItem(key, posts);
    }

}