import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-tooltip/dist/react-tooltip.css'
import Layout from './layouts/Layout';
import ReactGA from 'react-ga';
import ErrorBoundary from 'ErrorBoundary';
import { createContext } from 'react';
import NavigationHandler from 'utils/navigationHandler';
import { getCurrentHostnameDomain } from 'utils/domain';

ReactGA.initialize('G-QHJNCJNYNC');

export const PubSubContext = createContext();
const hostnameDomain = getCurrentHostnameDomain();

const App = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    // Show Crisp.chat widget only on specific domains
    if (hostnameDomain === "app.postly.ai" || hostnameDomain === "localhost") {
      window.$crisp?.push(['do', 'chat:show']);
    } else {
      window.$crisp?.push(['do', 'chat:hide']);
    }
  }, []);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <NavigationHandler />
      <ErrorBoundary>
        <Layout />
      </ErrorBoundary>
    </Router>
  );

}

export default App;
