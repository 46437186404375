import styled, { keyframes } from "styled-components";

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
    margin-left:10%;
  }
  to {
    opacity: 1;
    margin-left:50%;
  }
`;

export const ParentSection = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  animation: ${fadeInAnimation} 0.3s linear;
  cursor: auto;
  justify-content: center;
  align-items: flex-start;
`;

export const Wrapper = styled.div`
  /* flex: 9; */
  /* height: fit-content; */
  width: 100%;
  position: relative;
`;

export const PreviewContentArea = styled.div`
  /* border-style:solid;
  border-width:1px; */
  width: 100%;
  background-color: ${(props) => props.mainBgColor};
  /* z-index: 2; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-top: 10px;
  padding-top: 8px;
  /* margin-bottom: -60px; */
  animation: ${fadeInAnimation} 0.4s linear;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0 !important;
  }
`;

export const FirstSpace = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 3;
  align-items: center;
  position: relative;
  gap: 10px;
`;

export const LastSpace = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: black;
  align-items: center;
  left: 0;
  position: absolute;
  gap: 10px;
  bottom: 0;
  z-index: 3;
`;

export const TextSpace = styled.div`
  min-height: 50px;
  width: 80%;
  left: 0;
  position: absolute;
  gap: 10px;
  bottom: 40px;
  z-index: 10;
`;
export const VideoWrapper = styled.div`
  height: 100%;
  width: 100%;
  left: 0;
  /* position: absolute; */
  gap: 10px;

  top: 0;
`;

export const ImageDelete = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 20;
  color: white;
  right: 2%;
  top: 0%;
  cursor: pointer;
  transition: transform 0.2s;
  :active {
    opacity:0;
  }
`;


export const BottomIconBox = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  gap: 3px;
  align-items: center;
  z-index: 3;
`;

export const BottomIconBoxCenter = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 3px;
  align-items: center;
  z-index: 3;
`;

export const SecondSpace = styled.div`
  flex: 7;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
`;

export const ButtonArea = styled.button`
  height: 30px;
  width: fit-content;
  padding: 1% 20px;
  display: flex;
  background-color: transparent;
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  border-color: #fa3434;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 15px;
  @media only screen and (max-width: 768px) {
    height: 20px;
    padding: 1% 7px;
    border-width: 1px;
    font-size: 11px;
  }
`;

export const ThirdSpace = styled.div`
  flex: 2;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 3;
  align-items: flex-start;
`;

export const ThirdOne = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 3;
  align-items: center;
`;
export const IconsContainer = styled.div`
  flex: 4;
  height: auto;
  padding: 0 0 0 10px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
  row-gap: 25px;
  bottom: 15%;
  right: 3%;
  align-items: flex-start;
  z-index: 3;
`;

export const TiktokImageHolder = styled.div`
  min-height: 50px;
  min-width: 50px;
  max-height: 50px;
  max-width: 50px;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    min-height: 30px;
    min-width: 30px;
    max-height: 30px;
    max-width: 30px;
  }
`;

export const IconHolder = styled.div`
  border-radius: 100%;
  overflow: ${(props) => props.overflowState};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    min-height: 30px;
    min-width: 30px;
    max-height: 30px;
    max-width: 30px;
    font-size: 13px;
  }
`;

export const FollowWrapper = styled.div`
  height: 14px;
  width: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  position: absolute;
  border-radius: 100%;
  left: 18px;
  top: 20px;
`;

export const UpperLayer = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  position: relative;
  position: relative;
  flex-direction: ${(props) => props.username};
  padding: 0 2%;
  column-gap: 10px;
  margin-bottom: 30px;
`;

export const PostImage = styled.img`
  margin-top: 10px;
  max-height: 550px;
  width: 100%;
  position: relative;
`;
export const Name = styled.div`
  font-size: 14px;
  @media only screen and (max-width: 768px) {
    font-size: 11px;
  }
`;
export const UserName = styled.div`
  font-size: 12px;
  @media only screen and (max-width: 768px) {
    font-size: 9px;
  }
`;
export const PostText = styled.div`
  font-size: 15px;
  @media only screen and (max-width: 768px) {
    font-size: 13px;
  }
`;
export const TextEdit = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
  
  :active {
    opacity:0;
  }
`;

export const EditContainer = styled.div`
  min-height: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: center;
  gap:10px;
  border-radius: 8px;
  margin-top: 10px;
  flex: 1;
`;