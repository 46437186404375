import RichInfoBar from 'components/common/RichInfoBar';
import { generateTimeClause } from '../time_to_post_configurer';

const WhenToBeginRepeatInfoBar = ({
    startFrom,
    onOpenTimeToPostDialog,
    feedBackData
}) => {
    return (
        <RichInfoBar
            variant={'info'}
            message={`Your post will repeat after the first post on <b>${generateTimeClause(new Date(startFrom))}</b>`}
            actionMessage={"Pick New Start Time"}
            action={() => {
                onOpenTimeToPostDialog(feedBackData);
            }}
        />
    );
}
export default WhenToBeginRepeatInfoBar;