import Avatar from 'components/common/Avatar'
import Flex from 'components/common/Flex'
import APIService from 'http/api_service'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Container, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import PlanCard from './PlanCard'
import WhiteLabelMonthlyYearlySwitch from './WhiteLabelMonthlyYearlySwitch'

const WhiteLabelStripeConnectPricingPage = () => {
    const [products, setProducts] = useState(null);
    const [productExtras, setProductExtras] = useState(null);
    const [fetchingPlans, setFetchingPlans] = useState(true);
    const [processing, setProcessing] = useState(false);

    const getWebsitePlans = async () => {
        let host = window.location.hostname
        setFetchingPlans(true);
        APIService.fetchWebsiteProductPlans(host, (response, error) => {
            if (error) {
                toast.error(error.message, { theme: 'colored' });
                setFetchingPlans(false);
                return
            }
            const data = response.data;
            const { products, ...extras } = data;
            setProducts(products);
            setProductExtras(extras);
            setFetchingPlans(false);
        });
    }

    useEffect(() => {
        getWebsitePlans();
    }, [])

    const redirectToCheckout = (sessionUrl) => {
        window.location = sessionUrl;
    }

    const initProductCheckoutSession = (priceId) => {
        setProcessing(true);
        let requestBody = {
            priceId,
            website: `cowry.linky.com.ng`
        }
        APIService.initProductCheckoutSession(requestBody, (response, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                setProcessing(false);
                return
            }
            const data = response.data;
            const { session } = data;
            redirectToCheckout(session);
        });
    }

    if (fetchingPlans) {
        return <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}
        >
            <Spinner animation="border" />
            <span>
                Fetching subscription plans, please wait...
            </span>
        </div>

    }

    return (
        <Flex
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            className='m-2 h-100'
        >
            <Flex
                direction={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                {
                    productExtras?.brand_logo &&
                    <Avatar
                        size="4xl"
                        className="cursor-pointer mt-2"
                        src={productExtras?.brand_logo}
                    // mediaClass="img-thumbnail shadow-sm"
                    />
                }
                {
                    productExtras?.brand_name &&
                    <h4 className='mt-2'>{productExtras?.brand_name}</h4>
                }
                <h3
                    className='mt-2 text-center'
                >
                    Choose a subscription plan that suits your needs
                </h3>
            </Flex>
            <Flex
                className={'mt-2'}
            >
                <WhiteLabelMonthlyYearlySwitch />
            </Flex>
            <Flex
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                className='mt-2 mb-5'
                gap={3}
                wrap={'wrap'}
            >
                {
                    products?.map((product, index) => {
                        return (
                            <PlanCard
                                key={index}
                                product={product}
                                initProductCheckoutSession={initProductCheckoutSession}
                                processing={processing}
                            />
                        )
                    })
                }
            </Flex>
        </Flex>
    )
}

export default WhiteLabelStripeConnectPricingPage