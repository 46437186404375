import React from 'react'
import { Button, Card } from 'react-bootstrap'
import Flex from '../../../common/Flex'
import { HiMenuAlt3 } from "react-icons/hi";
import { IoChatbubble } from "react-icons/io5";
import { GrFormNextLink } from "react-icons/gr";
import ChatSideArea from './ChatSideArea'
import ConversationArea from './ConversationArea'

const ChatArea = (props) => {
    return (
        <Flex
            gap={1}
            style={{
                width: '100%',

            }}
        >
            <ChatSideArea
                {...props}
            />
            <ConversationArea
                {...props}
            />
        </Flex>
    )
}

export default ChatArea