import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";
import PricingDefaultHeader from "./PricingDefaultHeader";
import PricingDefaultCard from "./PricingDefaultCard";
import {
  pricingDataNew,
  extraSocialAccounts,
  extraAIWriterPricing,
} from "./pricingDataNew";
import Flex from "components/common/Flex";
import AIWriterAddon from "./AIWriterAdon";
import ActivePlanPopup from "./ActivePlanPopup";
import ConfirmModal from "components/common/ConfirmModal";
import APIService from "http/api_service";
import { toast } from "react-toastify";
import { RoutePaths } from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveWorkSpace,
  getBilledYearly,
  getSocialAccountQuantity,
  setActiveWorkspace,
} from "redux/slices/workspaceslice";
import usePubSub from "pubsub";
import { Checkmark } from "react-checkmark";
import { useQuery } from "utils/url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { getLoggedInUser, setLoggedInUser } from "redux/slices/user_slice";
import { isFreePlan } from "utils/pricing_utils";
import { isFreeUser } from "utils/user_utils";

const PricingDefault = (props) => {
  const dispatch = useDispatch();
  const workspace = useSelector(getActiveWorkSpace);
  const billedYearly = useSelector(getBilledYearly);
  const socialAccountQuantity = useSelector(getSocialAccountQuantity);
  const loggedInUser = useSelector(getLoggedInUser);
  const [activePlan, setActivePlan] = useState(loggedInUser.active_plan);
  const [openSubscriptionCancelPrompt, setOpenSubscriptionCancelPrompt] =
    useState(false);
  const [
    openAIWriterSubscriptionCancelPrompt,
    setOpenAIWriterSubscriptionCancelPrompt,
  ] = useState(false);
  const [cancellingSubscription, setCancellingSubscription] = useState(false);
  const [cancellingAIWriterSubscription, setCancellingAIWriterSubscription] =
    useState(false);
  const [openCheckoutCompletedPopup, setOpenCheckoutCompletedPopup] =
    useState(false);
  const [checkoutCompletedMessage, setCheckoutCompletedMessage] = useState();
  const [processingPayment, setProcessingPayment] = useState(false);
  const [
    openJoinedAIWriterCancellationPrompt,
    setOpenJoinedAIWriterCancellationPrompt,
  ] = useState(false);
  const navigate = useNavigate();
  const query = useQuery();
  const { addPubSubEventListener, removePubSubEventListener } = usePubSub();
  const [openPrecancellationDialog, setOpenPreCancellationDialog] =
    useState(false);

  const refreshLoggedInUserData = () => {
    APIService.fetchLatestMe((latestMeResponse, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        if (
          error
            .toLowerCase()
            .includes("Invalid credentials provided".toLowerCase())
        ) {
          window.localStorage.clear();
          setTimeout(() => {
            navigate(RoutePaths.AUTHENTICATION);
          }, 100);
        }
        return;
      }
      let latestUserData = latestMeResponse.data;
      setActivePlan(latestUserData.active_plan);
      dispatch(setLoggedInUser({ ...latestUserData }));
      checkAndLazyUpdateActiveWorkspace(latestUserData);
    });
  };

  const pollSubscriptionStatus = (heartbeat, pricingData, type, done) => {
    const intervalId = setInterval(() => {
      APIService.fetchLatestMe((latestMeResponse, error) => {
        if (error) {
          toast.error(error, { theme: "colored" });
          if (
            error
              .toLowerCase()
              .includes("Invalid credentials provided".toLowerCase())
          ) {
            window.localStorage.clear();
            setTimeout(() => {
              navigate(RoutePaths.LOGIN);
            }, 100);
          }
          return;
        }
        const localUserPlan = loggedInUser.active_plan;
        let latestUserData = latestMeResponse.data;
        const newPlan = latestUserData.active_plan;
        const referencePlan = pricingData.plan;
        if (type === "new_subscription" && newPlan.Key === referencePlan) {
          clearInterval(intervalId);
          done(latestUserData);
        } else if (type === "new_subscription" && pricingData.for_ai_writer) {
          const activeAIWriterSubscription =
            latestUserData.active_ai_writer_subscription;
          if (activeAIWriterSubscription != null) {
            clearInterval(intervalId);
            done(latestUserData);
          }
        } else if (
          type === "new_subscription" &&
          pricingData.for_telegram_bot
        ) {
          const currentWorkspace = workspace;
          const existingBotSlots = currentWorkspace.creatable_bot_slots ?? 0;
          APIService.fetchWorkSpace(currentWorkspace._id, (res, err) => {
            if (err) {
              clearInterval(intervalId);
              done(latestUserData);
              return;
            }
            const latestWorkspaceData = res.data;
            const newBotSlots = latestWorkspaceData.creatable_bot_slots ?? 0;
            if (newBotSlots > existingBotSlots) {
              dispatch(setActiveWorkspace({ ...latestWorkspaceData }));
              clearInterval(intervalId);
              done();
            }
          });
        } else if (
          type === "additional_social_accounts" &&
          newPlan.Key === referencePlan
        ) {
          const existingCount = parseInt(localUserPlan.SocialAccounts);
          const newCount = parseInt(newPlan.SocialAccounts);
          if (!isNaN(existingCount) && !isNaN(newCount)) {
            if (newCount > existingCount) {
              clearInterval(intervalId);
              done(latestUserData, newCount - existingCount);
            }
          }
        }
      });
    }, heartbeat);
  };

  const handleSubscription = (pricingData, user, _price) => {
    const Paddle = window.Paddle;
    const oneOff = pricingData.one_off ?? false;
    Paddle.Checkout.open({
      quantity: pricingData.SocialAccounts ?? 1,
      product: parseInt(
        billedYearly ? pricingData.AnnualSub : pricingData.MonthlySub
      ),
      email: user?.email,
      plan: pricingData.plan,
      allowQuantity: false,
      passthrough: JSON.stringify({
        accountId: user._id,
        subscriptionMode: billedYearly ? "Yearly" : "Monthly",
        workspaceId: user.workSpaceId,
        plan: pricingData.plan,
        quantity: pricingData.SocialAccounts ?? 1,
        SocialAccounts: pricingData.SocialAccounts ?? 1,
        prorate: true,
        prorated_keys: ["SocialAccounts"],
        coupon: pricingData.coupon,
        for_ai_writer: pricingData.for_ai_writer,
        for_telegram_bot: pricingData.for_telegram_bot,
      }),
      successCallback: () => {
        setProcessingPayment(true);
        pollSubscriptionStatus(
          2000,
          pricingData,
          "new_subscription",
          (data) => {
            if (data) {
              dispatch(setLoggedInUser({ ...data }));
              checkAndLazyUpdateActiveWorkspace(data);
              setProcessingPayment(false);
              setCheckoutCompletedMessage(
                oneOff
                  ? "Payment successful"
                  : `You have successfully subscribed to the ${pricingData.title} plan`
              );
              if (!openCheckoutCompletedPopup) {
                setOpenCheckoutCompletedPopup(true);
              }
            }
          }
        );
      },
    });
  };

 
  const buyMoreSocialAccounts = (pricingData, user, _price) => {
    const Paddle = window.Paddle;
    const extraSocialAccountPlan = extraSocialAccounts.find(
      (x) => x.LinkedPlan === pricingData.plan
    );
    Paddle.Checkout.open({
      product: parseInt(
        billedYearly
          ? extraSocialAccountPlan.AnnualSub
          : extraSocialAccountPlan.MonthlySub
      ),
      email: user?.email,
      quantity: parseInt(socialAccountQuantity),
      plan: extraSocialAccountPlan.plan,
      allowQuantity: false,
      passthrough: JSON.stringify({
        accountId: user._id,
        subscriptionMode: billedYearly ? "Yearly" : "Monthly",
        workspaceId: user.workSpaceId,
        plan: extraSocialAccountPlan.plan,
        linkedInPlan: pricingData.plan,
        linkedToExistingPlan: true,
        quantity: parseInt(socialAccountQuantity),
        SocialAccounts: parseInt(socialAccountQuantity),
        prorate: true,
        planName: extraSocialAccountPlan.title,
        prorated_keys: ["SocialAccounts"],
        offering: `${parseInt(socialAccountQuantity)} social accounts`,
        coupon: pricingData.coupon,
        downgradable: false,
        assignable: false,
      }),
      successCallback: () => {
        setProcessingPayment(true);
        pollSubscriptionStatus(
          2000,
          pricingData,
          "additional_social_accounts",
          (data, diff) => {
            if (data) {
              dispatch(setLoggedInUser({ ...data }));
              checkAndLazyUpdateActiveWorkspace(data);
              setProcessingPayment(false);
              setCheckoutCompletedMessage(
                `You have successfully bought ${diff} more social accounts`
              );
              if (!openCheckoutCompletedPopup) {
                setOpenCheckoutCompletedPopup(true);
              }
            }
          }
        );
      },
    });
  };

  useEffect(() => {
    let paymentFor = query.get("payment_for");
    if (paymentFor) {
      try {
        let data = JSON.parse(decodeURIComponent(paymentFor));
        let { pricingData, coupon, user } = data;
        if (coupon) {
          pricingData.coupon = coupon;
        }
        if (pricingData) {
          handleSubscription(pricingData, user);
        }
      } catch (e) {}
    }
  }, [query]);

  const subscribeToAIWriterUnlimited = (pricingData, user) => {
    handleSubscription({ ...pricingData, for_ai_writer: true }, user);
  };

  const checkAndLazyUpdateActiveWorkspace = (latestUserData) => {
    if (!latestUserData) {
      return;
    }
    if (latestUserData._id === workspace.owner_id) {
      setTimeout(() => {
        dispatch(
          setActiveWorkspace({
            ...workspace,
            active_plan: latestUserData.active_plan,
          })
        );
      }, 1000);
    }
  };

  useEffect(() => {
    refreshLoggedInUserData();
  }, []);

  useEffect(() => {
    let checkoutSessionListener = (data) => {
      let { error, one_off, cancellation, message, plan = "Premium" } = data;
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      if (!cancellation) {
        if (openCheckoutCompletedPopup) {
          return;
        }
        APIService.fetchLatestMe((latestMeResponse, error) => {
          if (error) {
            toast.error(error, { theme: "colored" });
            if (
              error
                .toLowerCase()
                .includes("Invalid credentials provided".toLowerCase())
            ) {
              window.localStorage.clear();
              setTimeout(() => {
                navigate(RoutePaths.LOGIN);
              }, 100);
            }
            return;
          }
          let latestUserData = latestMeResponse.data;
          dispatch(setLoggedInUser({ ...latestUserData }));
          checkAndLazyUpdateActiveWorkspace(latestUserData);
          setProcessingPayment(false);
          setCheckoutCompletedMessage(
            one_off
              ? message
              : `You have successfully subscribed to the ${plan} plan`
          );
          setOpenCheckoutCompletedPopup(true);
        });
      } else {
        navigate(RoutePaths.DEFAULT);
        window.location.reload();
      }
    };
    addPubSubEventListener(
      `${loggedInUser?._id}-billing-status`,
      checkoutSessionListener
    );
    return () => {
      removePubSubEventListener(
        `${loggedInUser?._id}-billing-status`,
        checkoutSessionListener
      );
    };
  }, []);

  const cancelSubscription = (e) => {
    let activePlan = workspace.active_plan;
    if (activePlan.cancel_url) {
      window.location = activePlan.cancel_url;
    } else {
      setCancellingAIWriterSubscription(true);
      APIService.cancelSubscription((response, error) => {
        if (error) {
          setCancellingSubscription(false);
          toast.error(error, { theme: "colored" });
          return;
        }
        let { message, data } = response;
        dispatch(setLoggedInUser({ ...data }));
        checkAndLazyUpdateActiveWorkspace(data);
        toast.success(message, { theme: "colored" });
        setCancellingSubscription(false);
        setTimeout(() => {
          window.location = window.location.href.split("?")[0];
          window.location.reload();
        }, 500);
      });
    }
  };

  const cancelAISubscription = (e) => {
    let activeAISubscription = loggedInUser.active_ai_writer_subscription;
    if (activeAISubscription.tied_to_plan) {
      setOpenJoinedAIWriterCancellationPrompt(true);
      return;
    }
    if (activeAISubscription.cancel_url) {
      window.location = activeAISubscription.cancel_url;
    } else {
      setCancellingAIWriterSubscription(true);
      APIService.cancelAISubscription((response, error) => {
        if (error) {
          setCancellingAIWriterSubscription(false);
          toast.error(error, { theme: "colored" });
          return;
        }
        let { message, data } = response;
        dispatch(setLoggedInUser({ ...data }));
        toast.success(message, { theme: "colored" });
        setCancellingAIWriterSubscription(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      });
    }
  };

  const hashUpdatableUrl = () => {
    let cancelUrl = activePlan.cancel_url;
    let updateUrl = activePlan.update_url;
    if (updateUrl) {
      return updateUrl;
    }
    if (cancelUrl) {
      return cancelUrl.replace("cancel", "update");
    }
    return null;
  };

  const updatableUrl = hashUpdatableUrl();

  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          <Row className="g-0">
            <Col xs={12}>
              <Flex
                className={"gap-2, justify-content-center"}
                alignItems={"center"}
                wrap={"wrap"}
              >
                <Col lg={7}>
                  <Flex direction={"column"} alignItems={"start"} wrap={"wrap"}>
                    <h5 className="mt-0 mb-0">
                      {" "}
                      Your Active Plan: <b>{activePlan["Name"]}</b>
                    </h5>
                    {activePlan["Name"] === "Appsumo Lifetime deal" ? null : (
                      <>
                        {!isFreeUser(loggedInUser) && (
                          <span className="mt-0 mb-2">
                            Next billing date:{" "}
                            <b>{activePlan["next_bill_date"]}</b>{" "}
                          </span>
                        )}
                      </>
                    )}

                    <ActivePlanPopup />
                    {!isFreeUser(loggedInUser) && (
                      <Button
                        variant="text"
                        className="btn btn-sm btn-link ps-0 text-danger mb-2"
                        style={{
                          textDecoration: "none",
                        }}
                        onClick={() => {
                          setOpenSubscriptionCancelPrompt(true);
                        }}
                      >
                        {cancellingSubscription
                          ? "Cancelling subscription..."
                          : " Cancel your subscription"}
                      </Button>
                    )}
                    {updatableUrl && (
                      <a href={updatableUrl} className={"text-decoration-none"}>
                        <Button
                          size="sm"
                          className="rounded-pill mb-2 mt-3"
                          variant="outline-warning"
                        >
                          <Flex className={"gap-1"} alignItems={"center"}>
                            <FontAwesomeIcon
                              style={{ width: 10, height: 10 }}
                              icon={faPen}
                            />
                            <span className="fs--1">
                              Update Payment Details
                            </span>
                          </Flex>
                        </Button>
                      </a>
                    )}
                  </Flex>
                </Col>
                <div style={{ flex: 1 }}></div>
                <Col lg={5}>
                  <AIWriterAddon
                    cancellingAIWriterSubscription={
                      cancellingAIWriterSubscription
                    }
                    pricing={extraAIWriterPricing}
                    handleBilling={() => {
                      subscribeToAIWriterUnlimited(
                        {
                          ...extraAIWriterPricing,
                          ai_writer_subscription: true,
                        },
                        loggedInUser
                      );
                    }}
                    handleAIWriterSubscriptionCancel={() => {
                      setOpenAIWriterSubscriptionCancelPrompt(true);
                    }}
                  />
                </Col>
              </Flex>
            </Col>
            <PricingDefaultHeader />
            {pricingDataNew.map((pricing) => {
              if (activePlan?.Name === pricing?.title) {
                pricing.activePlan = true;
              }
              return (
                <PricingDefaultCard
                  key={pricing.id}
                  pricing={pricing}
                  activePlanFromDB={activePlan}
                  quantity={props.quantity}
                  handleAddSubscriptionQuantity={(data) => {
                    buyMoreSocialAccounts(data, {
                      ...loggedInUser,
                      workSpaceId: workspace._id,
                    });
                  }}
                  handleBilling={(data) => {
                    if (!isFreePlan(loggedInUser.active_plan)) {
                      setOpenPreCancellationDialog(true);
                      return;
                    }
                    handleSubscription(data, {
                      ...loggedInUser,
                      workSpaceId: workspace._id,
                    });
                  }}
                />
              );
            })}
            <Col xs={12} className="text-center">
              <a
                href="https://www.postly.ai/pricing/"
                className="btn btn-link mt-3"
                target={"_blank"}
                rel={"noreferrer"}
              >
                Learn more about pricing
              </a>
            </Col>
            <Col xs={12} className="text-center">
              <h5 className="mt-3 mb-3">
                Need more than 5 social accounts? You can add more after you
                upgrade!
              </h5>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <ConfirmModal
        open={openJoinedAIWriterCancellationPrompt}
        onCancel={() => {
          setOpenJoinedAIWriterCancellationPrompt(false);
        }}
        title="Cancel your subscription?"
        message="Your AI Writer Unlimited Subscription is currently tied to your active plan. Kindly use the Cancel Subscription option on your active plan instead"
        cancelText="CLOSE"
        confirmText="OKAY"
        onConfirm={() => {
          setOpenJoinedAIWriterCancellationPrompt(false);
        }}
      />
      <ConfirmModal
        open={openSubscriptionCancelPrompt}
        onCancel={() => {
          setOpenSubscriptionCancelPrompt(false);
        }}
        title="Cancel your subscription?"
        message="This will cancel your current subscription and downgrade your account to the free plan."
        cancelText="NO"
        confirmText="CONTINUE"
        onConfirm={() => {
          setOpenSubscriptionCancelPrompt(false);
          cancelSubscription();
        }}
      />
      <ConfirmModal
        open={openAIWriterSubscriptionCancelPrompt}
        onCancel={() => {
          setOpenAIWriterSubscriptionCancelPrompt(false);
        }}
        title="Cancel your Unlimited AI subscription"
        message="This will cancel your current Unlimited AI subscription and downgrade your Unlimited AI quota to the free plan."
        cancelText="NO"
        confirmText="CONTINUE"
        onConfirm={() => {
          setOpenAIWriterSubscriptionCancelPrompt(false);
          cancelAISubscription();
        }}
      />
      <ConfirmModal
        open={openPrecancellationDialog}
        onCancel={() => {
          setOpenPreCancellationDialog(false);
        }}
        title="Cancel existing subscription"
        message="You have an existing subscription, please cancel it before changing your plan. Please note that all your scheduled posts will continue to be published, however, you need to reconnect any disconnected platforms."
        cancelText="CLOSE"
        confirmText="OK"
        onConfirm={() => {
          setOpenPreCancellationDialog(false);
        }}
      />
      <Modal
        show={openCheckoutCompletedPopup}
        onHide={() => {
          setOpenCheckoutCompletedPopup(false);
          navigate(RoutePaths.DEFAULT);
          window.location.reload();
        }}
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Subscription Successful!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Flex
            alignItems={"center"}
            className={"gap-2"}
            justifyContent={"center"}
            direction={"column"}
          >
            <Checkmark size="96px" />
            <span>{checkoutCompletedMessage}</span>
          </Flex>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setOpenCheckoutCompletedPopup(false);
              navigate(RoutePaths.DEFAULT);
              window.location.reload();
            }}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={processingPayment}
        onHide={() => {
          setProcessingPayment(false);
          toast.info("Your new plan will reflect after verification", {
            theme: "colored",
          });
        }}
        onExit={() => {
          setProcessingPayment(false);
        }}
        centered
      >
        <Modal.Body>
          <Flex alignItems={"center"} justifyContent={"center"}>
            <Flex
              className={"gap-2"}
              alignItems={"center"}
              justifyContent={"center"}
              direction={"column"}
            >
              <Spinner animation="border" />
              <span>Verifying. Please wait...</span>
            </Flex>
          </Flex>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PricingDefault;
