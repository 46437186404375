import React from 'react';
import PropTypes from 'prop-types';
import Flex from './Flex';

const Calendar = ({ month, day, year }) => (
  <Flex direction={'column'} alignItems={'center'}>
    <div className="calendar">
      <span className="calendar-month">{month}</span>
      <span className="calendar-day">{day}</span>
    </div>
    <span>{year}</span>
  </Flex>
);

Calendar.propTypes = {
  month: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired
};

export default Calendar;
