import React from 'react';
import { styled } from '@stitches/react';
import { mauve } from '@radix-ui/colors';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { StyledTabList } from './styled_tabs_list';

const StyledTabs = styled(TabsPrimitive.Root, {
    display: 'flex',
    flexDirection: 'column',
});

const StyledTrigger = styled(TabsPrimitive.Trigger, {
    all: 'unset',
    fontFamily: 'inherit',
    padding: '0 20px',
    height: 45,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 15,
    lineHeight: 1,
    userSelect: 'none',
    cursor: 'pointer',
    '&:first-child': { borderTopLeftRadius: 6 },
    '&:last-child': { borderTopRightRadius: 6 },
    '&:hover': { color: 'currentColor' },
    '&[data-state="active"]': {
        color: '#2c7be5',
        boxShadow: 'inset 0 -1px 0 0 currentColor, 0 1px 0 0 currentColor',
        border: 'none'
    }
});

const StyledContent = styled(TabsPrimitive.Content, {
    flexGrow: 1,
});

const TabsList = StyledTabList;
const TabsTrigger = StyledTrigger;
const TabsContent = StyledContent;
const Box = styled('div', {});

export default function Tabs(props) {
    const { titles = [], contents = [], tabMarginLeft, tabMarginRight } = props;
    return (
        <Box css={{ width: '100%' }}>
            <StyledTabs defaultValue="tab0">
                <TabsList marginRight={tabMarginRight} marginLeft={tabMarginLeft}>
                    {
                        titles.map((tabTitle, index) => {
                            return (
                                <TabsTrigger value={`tab${index}`}>
                                    {tabTitle}
                                </TabsTrigger>
                            )
                        })
                    }
                </TabsList>
                {
                    contents.map((content, index) => {
                        return (
                            <TabsContent value={`tab${index}`}>{content}</TabsContent>
                        )
                    })
                }
            </StyledTabs>
        </Box>
    );
}