import React from 'react';
import { Col, Row } from 'react-bootstrap';
import MonthlyYearlySwitch from './MonthlyYearlySwitch';

const PricingDefaultHeader = () => {

  return (
    <Col xs={12} className="mb-3">
      <Row className="justify-content-center">
        <MonthlyYearlySwitch />
        <Col sm="auto" className="text-center">
        </Col>
      </Row>
    </Col>
  );
};

export default PricingDefaultHeader;
