import Flex from 'components/common/Flex'
import React, { useEffect, useState } from 'react'
import ConnectedPageOrGroupEdit from './connected_page_or_group'

const ConnectedPagesAndGroupsEdit = (props) => {
    const { connected_pages_and_groups, setCloneData, account, cloneData, platform } = props

    return (
        <Flex direction={'column'} className={'gap-1'}>
            {
                connected_pages_and_groups?.map((connected_page_or_group, index) => {
                    return <ConnectedPageOrGroupEdit
                        key={index}
                        connected_page_or_group={connected_page_or_group}
                        setCloneData={setCloneData}
                        account={account}
                        cloneData={cloneData}
                        platform={platform}
                    />
                })
            }
        </Flex>
    )
}

export default ConnectedPagesAndGroupsEdit