import React, { useEffect, useState } from 'react';
import "./recurring_post_options_style.css";
import { Button, Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import DailyRepeatOptionView from './DailyRepeatOptionView';
import WeeklyRepeatOptionView from './WeeklyRepeatOptionView';
import MonthlyRepeatOptionView from './MonthlyRepeatOptionView';
import YearlyRepeatOptionView from './YearlyRepeatOptionView';

export default function RecurringPostOptions(props) {

    const { modalClose, onOptionsApplied, ...others } = props;
    const [startFrom, setStartFrom] = useState(props.startFrom);
    const [presetOptions, setPresetOptions] = useState(props.presetOptions);
    const [selectedRepeatOption, setSelectedRepeatOption] = useState(-1);
    const [committedRecurringSettings, setCommittedRecurringSettings] = useState({ ...presetOptions });

    useEffect(() => {
        setStartFrom(props.startFrom);
    }, [props.startFrom]);

    useEffect(() => {
        setPresetOptions(props.presetOptions);
    }, [props.presetOptions]);

    useEffect(() => {
        if (presetOptions) {
            if (presetOptions['daily_repeat_option']) {
                setSelectedRepeatOption("daily");
            } else if (presetOptions['weekdays_to_repeat']) {
                setSelectedRepeatOption("weekly");
            } else if (presetOptions['day_of_month_to_repeat']) {
                setSelectedRepeatOption("monthly");
            } else if (presetOptions['specific_date_to_repeat']) {
                setSelectedRepeatOption("yearly");
            } else {
                setSelectedRepeatOption(-1);
            }
        }
    }, [presetOptions]);

    const commitSelectedRecurringSettings = (data) => {
        setCommittedRecurringSettings(data);
    }

    const commitStopTime = (whenToStop) => {
        if (Object.entries(committedRecurringSettings).length > 0) {
            setCommittedRecurringSettings((prevData) => {
                return {
                    ...prevData,
                    stop_time: whenToStop
                }
            });
        }
    }

    const commitSpecificTime = (specificTime) => {
        if (Object.entries(committedRecurringSettings).length > 0) {
            setCommittedRecurringSettings((prevData) => {
                return {
                    ...prevData,
                    specific_time_to_repeat: specificTime
                }
            });
        }
    }

    return (
        <Flex direction={'column'}>
            <Form as={Flex}
                direction={'column'}
                className='gap-3'>
                <Form.Check
                    type='radio'
                    id='daily'
                    label='Daily'
                    name='repeatOption'
                    checked={selectedRepeatOption === "daily"}
                    value={'daily'}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setSelectedRepeatOption("daily");
                        }
                    }}
                />
                {
                    selectedRepeatOption === "daily" &&
                    <DailyRepeatOptionView
                        startFrom={startFrom}
                        presetOptions={presetOptions}
                        commitSelectedRecurringSettings={commitSelectedRecurringSettings}
                        commitStopTime={commitStopTime}
                        commitSpecificTime={commitSpecificTime}
                        {...others}
                    />
                }
                <Form.Check
                    type='radio'
                    id='weekly'
                    label='Weekly'
                    name='repeatOption'
                    value={'weekly'}
                    checked={selectedRepeatOption === "weekly"}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setSelectedRepeatOption("weekly");
                        }
                    }}
                />
                {
                    selectedRepeatOption === "weekly" &&
                    <WeeklyRepeatOptionView
                        startFrom={startFrom}
                        presetOptions={presetOptions}
                        commitSelectedRecurringSettings={commitSelectedRecurringSettings}
                        commitStopTime={commitStopTime}
                        commitSpecificTime={commitSpecificTime}
                        {...others}
                    />
                }
                <Form.Check
                    type='radio'
                    id='monthly'
                    label='Monthly'
                    name='repeatOption'
                    value={'monthly'}
                    checked={selectedRepeatOption === "monthly"}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setSelectedRepeatOption("monthly");
                        }
                    }}
                />
                {
                    selectedRepeatOption === "monthly" &&
                    <MonthlyRepeatOptionView
                        startFrom={startFrom}
                        presetOptions={presetOptions}
                        commitSelectedRecurringSettings={commitSelectedRecurringSettings}
                        commitStopTime={commitStopTime}
                        commitSpecificTime={commitSpecificTime}
                        {...others}
                    />
                }
                <Form.Check
                    type='radio'
                    id='yearly'
                    label='Yearly'
                    name='repeatOption'
                    value={'yearly'}
                    checked={selectedRepeatOption === "yearly"}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setSelectedRepeatOption("yearly");
                        }
                    }}
                />
                {
                    selectedRepeatOption === "yearly" &&
                    <YearlyRepeatOptionView
                        startFrom={startFrom}
                        presetOptions={presetOptions}
                        commitSelectedRecurringSettings={commitSelectedRecurringSettings}
                        commitStopTime={commitStopTime}
                        commitSpecificTime={commitSpecificTime}
                        {...others}
                    />
                }
            </Form>
            {
                Object.entries(committedRecurringSettings).length > 0 &&
                <Flex
                    alignItems={'center'}
                    justifyContent={'flex-end'}
                    className={'mt-4 gap-3 p-2'}
                    style={{
                        alignSelf: 'flex-end'
                    }}>
                    {
                        presetOptions && Object.entries(presetOptions).length > 0 &&
                        <Button
                            variant={'falcon-danger'}
                            onClick={() => {
                                onOptionsApplied({});
                                modalClose();
                            }}
                            style={{
                                marginRight: 20
                            }}>DON'T REPEAT
                        </Button>
                    }
                    <Button
                        variant={'falcon-default'}
                        hollow
                        onClick={() => {
                            modalClose();
                        }}>
                        CANCEL
                    </Button>
                    <Button
                        variant={'primary'}
                        onClick={() => {
                            onOptionsApplied(committedRecurringSettings);
                            modalClose();
                        }}
                    >
                        APPLY
                    </Button>
                </Flex>
            }
        </Flex>
    );
}