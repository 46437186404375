import Flex from 'components/common/Flex'
import React from 'react'
import { Card, Form } from 'react-bootstrap'

const NewChatPlaceholder = (props) => {

    const { handleClick } = props;

    const handleModelSelection = (value) => {
        if (value === "CLAUDE.AI") {
            !props?.apiKey?.claudeAIKey ? props?.openMessage(value) : props?.handleOptionClick(value);
            return
        }
        if (value === "GEMINI") {
            !props?.apiKey?.geminiKey ? props?.openMessage(value) : props?.handleOptionClick(value);
            return
        }
        props?.handleOptionClick(value)
    }

    const modelSwitcher = <Flex>
        <Form.Group className="mb-3">
            <Form.Select
                value={props?.selectedOption}
                onChange={(e) => handleModelSelection(e.target.value)}>
                <option value={'GPT 3.5 Turbo'}>GPT 3.5 Turbo</option>
                <option value={'GPT 4 Turbo'}>GPT 4</option>
                <option value={'GPT-4o'}>GPT-4o</option>
                <option value={'CLAUDE.AI'}>CLAUDE</option>
                <option value={'GEMINI'}>GEMINI</option>
            </Form.Select>
        </Form.Group>
    </Flex>

    if (props?.hasStartChat) {
        return modelSwitcher
    }

    return (
        <Flex
            direction={'column'}
            style={{
                height: '100%',
                width: '90%',
                maxWidth: '90%',

            }}
        >
            {modelSwitcher}
            <Flex
                alignItems={'center'}
                justifyContent={'center'}
                style={{
                    height: '100%',
                    marginTop: 70
                }}
                direction={"column"}
                gap={3}
            >
                <Flex
                >
                    <h4>
                        How can I help you today?
                    </h4>
                </Flex>
                <Flex
                    gap={2}
                >
                    <Card
                        onClick={() => handleClick(`Product Feature Highlights:Write the product's unique attributes and benefits. Highlight what sets it apart from competitors`)}
                        className="p-3 cursor-pointer">
                        <div>Product Feature Highlights</div>
                        <div className="fs--1">Write the product's unique attribute...</div>
                    </Card>
                    <Card
                        onClick={() => handleClick(`Lifestyle Integration:Connect my product to my audience's lifestyle. Show how it fits seamlessly into their daily activities and fulfills their aspirations`)}
                        className="p-3 cursor-pointer">
                        <div>Lifestyle Integration</div>
                        <div className="fs--1">Connect my product to my audience's ...</div>
                    </Card>
                </Flex>
                <Flex gap={2}>
                    <Card
                        onClick={() => handleClick(`Problem-Solution Framework:Identify challenges my potential users face and explain how your product solves these problems, improving their convenience and satisfaction`)}
                        className="p-3 cursor-pointer">
                        <div>Problem-Solution Framework</div>
                        <div className="fs--1">Identify challenges my potential use...</div>
                    </Card>
                    <Card
                        onClick={() => handleClick(`User Experience Enhancements:Describe how my product enhances the user experience. Focus on usability, design, and the intuitive interaction it offers`)}
                        className="p-3 cursor-pointer">
                        <div>User Experience Enhancements</div>
                        <div className="fs--1">Describe how my product enhances the ..</div>
                    </Card>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default NewChatPlaceholder