import PropTypes from "prop-types";
import { Tab } from "react-bootstrap";
import ChatContentHeader from "./ChatContentHeader";
import ChatContentBody from "./ChatContentBody";
import MessageTextArea from "./MessageTextArea";
import { getLoggedInUser } from "redux/slices/user_slice";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import ReactLoading from "react-loading";
import { ChatContext } from "context/Context";
import { useContext } from "react";
import {
  onChatReset,
  onMessageDelivery,
  onNewChat,
  onNewMessage,
  onReaction,
  removeListener,
  useSocketState,
} from "../chatSocket";
import { getCurrentUserMessages } from "redux/slices/messagingSlice";
import APIService from "http/api_service";
import { toast } from "react-toastify";
import { ChatBox, Pointer } from "../svgs";
import Flex from "components/common/Flex";
import { getSelectedPageForPostCommentsView } from "redux/slices/instagramComments_slice";

const ChatContent = ({ setHideSidebar }) => {
  const user = useSelector(getLoggedInUser);
  console.log("🚀 ~ ChatContent ~ user:", user._id)
  const socket = null
  // const socket = useSocketState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSwitchLoading, setIsSwitchLoading] = useState(false);
  const messages = useSelector(getCurrentUserMessages);
  const {
    conversationsDispatch,
    currentChatsDispatch,
    userChats,
    setUserChats,
    activeRoomId,
    setActiveRoomId,
  } = useContext(ChatContext);
  const isMountedRef = useRef(true);

  const isComponentMounted = () => isMountedRef?.current;
  const selectedPageForPostCommentsView = useSelector(getSelectedPageForPostCommentsView);
  console.log("🚀 ~ ChatContent ~ selectedPageForPostCommentsView:", selectedPageForPostCommentsView)

  const fetchData = async () => {
    try {
      if (!isComponentMounted()) {
        return;
      }

      setIsSwitchLoading(true)

      // Fetch data from the server using a promise
      const { data, error } = await new Promise((resolve, reject) => {
        APIService.getUserRoomMessages(user?._id, selectedPageForPostCommentsView?.id, (data, error) => {
          if (error) {
            reject({ data: null, error });
          } else {
            resolve({ data, error: null });
          }
        });
      });

      if (!isComponentMounted()) {
        return;
      }

      if (error) {
        console.error("Error from chatContent:", error);
        toast.error(error, { theme: "colored" });
        return;
      }

      //? Use data if available
      const newData = data;

      let message = data["message"];
      toast.success(message, { theme: "colored" });

      if (!isComponentMounted()) {
        return;
      }
      setUserChats(newData);
      conversationsDispatch({ type: "FETCH_MESSAGES", payload: newData });
      currentChatsDispatch({ type: "FETCH_MESSAGES", payload: newData });
      setIsSwitchLoading(false)
      setIsLoading(false);
    } catch (error) {
      // Handle errors
      if (!isComponentMounted()) {
        return;
      }

      console.error("Error from chatContent:", error);
      toast.error(error?.message, { theme: "colored" });
      setIsLoading(false);
    }
  };

  const fetchDataAndUIUpdate = async () => {
    try {
      if (!messages?.length) {
        // Fetch data from the server only if messages.length is 0
        await fetchData();
      }

      // Update UI based on the messages state
      updateUI();
    } catch (error) {
      // Handle errors
      console.error("Error from chatContent:", error);
      toast.error(error?.message, { theme: "colored" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    isMountedRef.current = true;
    if (selectedPageForPostCommentsView?.id) {
      // postData()
      fetchDataAndUIUpdate();
    }



    // Cleanup function: Set isMounted to false when the component unmounts
    return () => {
      isMountedRef.current = false;
      // disconnectSocket();
    };
  }, [socket, isLoading, user?._id, messages, selectedPageForPostCommentsView?.id]);


  const updateUI = () => {
    //? Your existing updateUI logic here

    if (messages?.length === 0) {
      setActiveRoomId(null);
      return;
    }
    if (!isComponentMounted()) {
      return;
    }

    setUserChats(messages);
    conversationsDispatch({ type: "FETCH_MESSAGES", payload: messages });
    currentChatsDispatch({ type: "FETCH_MESSAGES", payload: messages });
  };

  useEffect(() => {
    const handleNewChat = (data) => {
      if (!isMountedRef?.current) {
        return;
      }

      setUserChats((prevUserChats) => {
        const chatExists = prevUserChats?.some(
          (chat) => chat?.room_id === data?.conversation?.room_id
        );
        if (!chatExists) {
          return [...prevUserChats, data?.conversation];
        }
        return prevUserChats;
      });

      conversationsDispatch({
        type: "ADD_CHAT",
        payload: [data?.conversation],
      });
      currentChatsDispatch({
        type: "ADD_MESSAGE",
        payload: data?.conversation,
        room_id: data?.conversation?.room_id,
        isUpdatedStart: true,
      });
    };

    const handleNewMessage = (data) => {
      if (!isMountedRef?.current) {
        return;
      }

      console.log("new message notification has been triggered");

      setUserChats((prevUserChats) => {
        return prevUserChats?.map((chat) => {
          if (chat?.room_id === data?.conversation?.room_id) {
            return {
              ...data?.conversation,
            };
          }
          return chat;
        });
      });

      conversationsDispatch({
        type: "ADD_MESSAGE",
        payload: {
          ...data?.conversation,
        },
        room_id: data?.conversation?.room_id,
      });
      currentChatsDispatch({
        type: "ADD_MESSAGE",
        payload: data.conversation,
        room_id: data.conversation.room_id,
        isUpdatedStart: true,
      });

      fetchData()
    };

    const handleReaction = (data) => {
      if (!isMountedRef?.current) {
        return;
      }

      setUserChats((prevUserChats) => {
        return prevUserChats?.map((chat) => {
          if (chat?.room_id === data?.conversation?.room_id) {
            return {
              ...data?.conversation,
            };
          }
          return chat;
        });
      });

      conversationsDispatch({
        type: "ADD_MESSAGE",
        payload: {
          ...data?.conversation,
        },
        room_id: data?.conversation?.room_id,
      });
      currentChatsDispatch({
        type: "ADD_MESSAGE",
        payload: data?.conversation,
        room_id: data?.conversation?.room_id,
        isUpdatedStart: true,
      });

      fetchData()
    };

    const handleMessageDelivery = (data) => {
      if (!isMountedRef?.current) {
        return;
      }

      let updatedCurrentChat;
      setUserChats((prevUserChats) => {
        updatedCurrentChat = prevUserChats?.map((chat) => {
          if (chat?.room_id === data?.conversation?.room_id) {
            const updatedMessages = chat?.messages?.map((message) => {
              if (message?.sender === chat?.user_id) {
                if (!message?.read) {
                  return { ...message, status: "delivered" };
                }
              }
              return message;
            });

            //? Update the current chat with the marked messages
            return {
              ...chat,
              messages: updatedMessages,
            };
          }
          return chat;
        });

        return updatedCurrentChat;
      });

      const roomChat = updatedCurrentChat?.find(
        (chat) => chat?.room_id === data?.conversation?.room_id
      );

      conversationsDispatch({
        type: "ADD_MESSAGE",
        payload: {
          ...roomChat,
        },
        room_id: data?.conversation?.room_id,
      });

      currentChatsDispatch({
        type: "ADD_MESSAGE",
        payload: {
          ...roomChat,
        },
        room_id: data?.conversation?.room_id,
      });

      fetchData();
    };

    onNewChat(handleNewChat);
    // onChatReset(resetChat);
    onNewMessage(handleNewMessage);
    onMessageDelivery(handleMessageDelivery);
    onReaction(handleReaction);
    return () => {
      // Clean up event listeners when component unmounts
      removeListener("new-chat", handleNewChat);
      // removeListener('reset-chat', resetChat);
      removeListener("new-message", handleNewMessage);
      removeListener("message-delivery", handleMessageDelivery);
      removeListener("reaction", handleReaction);
    };
  }, []);

  // Render loading indicator or handle errors
  if (isLoading || isSwitchLoading) {
    return (
      <div id="loader">
        <ReactLoading type={"bubbles"} color={"#0052cc"} />
      </div>
    );
  }

  return (
    <>
      {/* {(isFetching && userChats?.length?.length < 1) ? (
        <>
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            direction={"column"}
          >
            <Flex
              className={"vh-70"}
              alignItems={"center"}
              justifyContent={"center"}
              direction={"column"}
            >
              <ReactLoading type={"bubbles"} color={"#0052cc"} />
            </Flex>
          </Flex>
        </>
      ) : (
        <Tab.Content className="card-chat-content">
          {!userChats?.length ? (
            //? Render when there are no chat conversations.
            <div
              style={{
                width: "100%",
                height: "50vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                margin: "auto 0",
              }}
            >
              <p style={{ fontSize: "clamp(16px, 3vw, 24px)" }}>
                You can start managing your new conversations from here <br /> (you
                have no new chat yet)
              </p>
            </div>
          ) : !activeRoomId && userChats?.length ? (
            //? Render when there are chat conversations but none are selected.
            <div
              style={{
                width: "100%",
                height: "50vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                margin: "auto 0",
                position: "relative",
              }}
            >
              <p style={{ fontSize: "clamp(16px, 3vw, 24px)" }}>
                Select any chat to begin a conversation
              </p>
            </div>
          ) : (
            //? Render when there are chat conversations and one is selected.
            userChats?.map((thread, index) => (
              <Tab.Pane key={index} eventKey={index} className="card-chat-pane">
                <ChatContentHeader
                  thread={thread}
                  setHideSidebar={setHideSidebar}
                />
                <ChatContentBody thread={thread} />
              </Tab.Pane>
            ))
          )}
          {activeRoomId && userChats?.length !== 0 ? <MessageTextArea /> : null}
        </Tab.Content>
      )} */}
       <Tab.Content className="card-chat-content">
          {!userChats?.length ? (
            //? Render when there are no chat conversations.
            <div
              style={{
                width: "100%",
                height: "50vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                margin: "auto 0",
              }}
            >
              <p style={{ fontSize: "clamp(16px, 3vw, 24px)" }}>
                You can start managing your new conversations from here <br /> (you
                have no new chat yet)
              </p>
            </div>
          ) : !activeRoomId && userChats?.length ? (
            //? Render when there are chat conversations but none are selected.
            <div
              style={{
                width: "100%",
                height: "50vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                margin: "auto 0",
                position: "relative",
              }}
            >
              {/* <ChatBox /> */}
              {/* <FontAwesomeIcon icon={"comment"} style={{ fontSize: '2em', color: '#007bff' }} /> */}

              {/* <Pointer /> */}
              <p style={{ fontSize: "clamp(16px, 3vw, 24px)" }}>
                Select any chat to begin a conversation
              </p>
            </div>
          ) : (
            //? Render when there are chat conversations and one is selected.
            userChats?.map((thread, index) => (
              <Tab.Pane key={index} eventKey={index} className="card-chat-pane">
                <ChatContentHeader
                  thread={thread}
                  setHideSidebar={setHideSidebar}
                />
                <ChatContentBody thread={thread} />
              </Tab.Pane>
            ))
          )}
          {activeRoomId && userChats?.length !== 0 ? <MessageTextArea /> : null}
        </Tab.Content>
    </>
  );
};

ChatContent.propTypes = {
  setHideSidebar: PropTypes.func.isRequired,
};

export default ChatContent;