import { RoutePaths } from 'constants';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { useSelector } from 'react-redux';
import { isFreeWorkspace } from './workspace_utils';
import { getShowFreeUserBillingUpgradeModal } from 'redux/slices/user_slice';

const NavigationListener = ({ onNavigationEvent, setShowPopup }) => {
    const location = useLocation();
    const prevLocationRef = useRef(location);
    const workSpace = useSelector(getActiveWorkSpace);


    useEffect(() => {
        if (isFreeWorkspace(workSpace)) {
            const handleExitIntent = (e) => {
                if (location.pathname === RoutePaths.BILLING_STRIPE) {
                    if (e.clientY < 0 && !localStorage.getItem('persistentBillingPopup')) {
                        setShowPopup(true)
                        localStorage.setItem('persistentBillingPopup', true)
                    }
                }
            };
            window.addEventListener('mouseout', handleExitIntent);
            return () => window.removeEventListener('mouseout', handleExitIntent);
        }
    }, [location])


    useEffect(() => {
        const handleBeforeUnload = (event) => {
            onNavigationEvent(prevLocationRef.current, location);
            // event.preventDefault();
            // event.returnValue = ''; // Necessary for Chrome to show the confirmation dialog
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [location, onNavigationEvent]);

    useEffect(() => {
        if (prevLocationRef.current.pathname !== location.pathname) {
            onNavigationEvent(prevLocationRef.current, location);
            prevLocationRef.current = location;
        }
    }, [location, onNavigationEvent]);

    return null;
};

export default NavigationListener;
