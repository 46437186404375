import Flex from "components/common/Flex"
import SubmitButton from "components/common/SubmitButton";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import OrganizationLogoPicker from "./organization_logo_picker";
import { getLoggedInUser } from "redux/slices/user_slice";
import StringUtils from "utils/string";
import useOrganizationStore from "state/organization_store";

export default function NewOrganizationSetup({ attributes = {}, handleOnClose, initialOrganizationName }) {

    const { t } = useTranslation();
    const authenticatedUser = useSelector(getLoggedInUser);
    const organizationList = useOrganizationStore(state => state.organizationList);
    const setOrganizationList = useOrganizationStore(state => state.setOrganizationList);
    const setOrganizationBeingCreated = useOrganizationStore(state => state.setOrganizationBeingCreated);
    const organizationBeingCreated = useOrganizationStore(state => state.organizationBeingCreated);
    const [form, setForm] = useState({ ...(organizationBeingCreated ?? { name: initialOrganizationName }) });

    const handleSubmit = (e) => {
        e.preventDefault();
        if (Object.entries(form).length === 0) {
            return;
        }
        form.owner = authenticatedUser._id;
        form._id = StringUtils.generateRandomString();
        form.new = true;
        form.attributes = attributes;
        form.avatar = organizationBeingCreated?.avatar
        let organizationsCopy = [...(organizationList ?? [])];
        const existingIndex = organizationsCopy.findIndex(org => org?.name === form.name);
        if (existingIndex !== -1) {
            form._id = organizationsCopy[existingIndex]._id;
            organizationsCopy.splice(existingIndex, 1);
        }
        organizationsCopy.unshift(form);
        setOrganizationList(organizationsCopy);
        setOrganizationBeingCreated(form);
        if (handleOnClose) {
            handleOnClose();
        }
    }

    return (
        <Form onSubmit={handleSubmit} className="w-100">
            <Flex direction={'column'} className={'gap-3'}>
                <Form.Group>
                    <Form.Label>{t('name')}</Form.Label>
                    <Form.Control
                        className="full-width"
                        type="text"
                        value={form?.name}
                        placeholder={t('enter_name_of_organization')}
                        onChange={(e) => {
                            setForm((prevForm) => {
                                return { ...prevForm, name: e.target.value }
                            })
                        }}
                    />
                </Form.Group>
                <OrganizationLogoPicker />
                <Flex className={'w-100 gap-2'} alignItems={'center'}>
                    <SubmitButton disabled={!form.name} className={'rounded-pill'} size={'sm'} processing={false} label={t('save')} />
                </Flex>
            </Flex>
        </Form>
    );
}