import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import { RoutePaths } from '../../../constants';
import { useSelector } from 'react-redux';
import Flex from 'components/common/Flex';
import {
  faArrowRightArrowLeft,
  faGear,
  faRightFromBracket,
  faRotate,
  faUpRightFromSquare,
  faUserGroup,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { setFlipToChildPages } from 'redux/slices/authSlice';
import { getLoggedInUser } from 'redux/slices/user_slice';

const ProfileDropdown = (props) => {

  const {
    skipHeader = false, skipSettings = false,
    avatarSize = 'xl', allowSwitchToAnotherWorkspace = false,
    onWorkspaceSwitchHandler
  } = props;

  const dispatch = useDispatch();
  const loggedInUser = useSelector(getLoggedInUser);
  const loggedInUserAvatar = loggedInUser?.avatar;

  return (
    <Dropdown navbar={true}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={'span'}
        id={"profileDropdown"}
        className="pe-0 nav-link cursor-pointer"
      >
        {
          loggedInUserAvatar ?
            <Avatar src={loggedInUserAvatar} size={avatarSize} />
            :
            <Avatar name={loggedInUser?.first_name ?? "U"} size={avatarSize} />
        }
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-caret dropdown-menu-card dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {
            !skipHeader &&
            <Dropdown.Item as={Link} className="fw-bold text-warning" to={RoutePaths.ACCOUNT}>
              <Flex alignItems={'center'} className={'gap-2'}>
                <FontAwesomeIcon icon="user" />
                <span className='flex-1'>{`${loggedInUser?.first_name} ${loggedInUser?.last_name}`}</span>
                <FontAwesomeIcon icon={faUpRightFromSquare} />
              </Flex>
            </Dropdown.Item>
          }
          {
            !skipHeader &&
            <Dropdown.Divider />
          }
          {
            !skipSettings &&
            <Dropdown.Item as={Link} to={RoutePaths.ACCOUNT}>
              <Flex alignItems={'center'} className={'gap-2 mt-2'}>
                <FontAwesomeIcon icon={faUserCircle} />
                <span>Profile</span>
              </Flex>
            </Dropdown.Item>
          }
          {/* {
            !skipSettings &&
            <Dropdown.Item as={Link} to={RoutePaths.SCHEDULER_BILLING}>
              <Flex alignItems={'center'} className={'gap-2'}>
                <FontAwesomeIcon icon={faDollarSign} />
                <span>Scheduler billing</span>
              </Flex>
            </Dropdown.Item>
          } */}
          {/* {
            isFreeUser(loggedInUser) &&
            <Dropdown.Item as={Link} to={RoutePaths.BILLING_STRIPE}>
              <Flex alignItems={'center'} className={'gap-2'}>
                <FontAwesomeIcon icon={faShoppingCart} style={{
                  color:"#2c7be5"
                }} />
                <span style={{color:"#2c7be5"}}>Upgrade</span>
              </Flex>
            </Dropdown.Item>
          } */}
          {
            !skipSettings &&
            <Dropdown.Item as={Link} to={RoutePaths.TEAM}>
              <Flex alignItems={'center'} className={'gap-2 mt-2'}>
                <FontAwesomeIcon icon={faUserGroup} />
                <span>Manage team</span>
              </Flex>
            </Dropdown.Item>
          }
          {
            !skipSettings &&
            <Dropdown.Item as={Link} to={RoutePaths.WORKSPACE_SETTINGS}>
              <Flex alignItems={'center'} className={'gap-2 mt-2'}>
                <FontAwesomeIcon icon={faGear} />
                <span>Manage workspace</span>
              </Flex>
            </Dropdown.Item>
          }
          {
            allowSwitchToAnotherWorkspace &&
            <Dropdown.Item
              onClick={() => {
                onWorkspaceSwitchHandler();
              }}>
              <Flex alignItems={'center'} className={'gap-2 mt-2'}>
                <FontAwesomeIcon icon={faArrowRightArrowLeft} />
                <span>Switch workspace</span>
              </Flex>
            </Dropdown.Item>
          }
          {
            allowSwitchToAnotherWorkspace &&
            <Dropdown.Divider />
          }
          <Dropdown.Item
            onClick={() => {
              dispatch(setFlipToChildPages(false));
            }}
            as={Link}
            to={`${RoutePaths.LOGIN}?switch=true`}>
            <Flex alignItems={'center'} className={'gap-2 mt-2'}>
              <FontAwesomeIcon icon={faRotate} />
              <span>Switch accounts</span>
            </Flex>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to={RoutePaths.LOGOUT}>
            <Flex alignItems={'center'} className={'gap-2 '}>
              <FontAwesomeIcon icon={faRightFromBracket} />
              <span>Log out</span>
            </Flex>
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
