/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
import APIService from 'http/api_service.js';
import React, { useEffect, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getGoogleDriveManager, setGoogleDriveManager } from 'redux/slices/postslice';
import { setLoggedInUser } from 'redux/slices/user_slice.js';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice.js';
import { getNextMinsDate } from 'utils/add_minutes_to_current_date.js';
import usePubSub from '../../../../../pubsub.js';

const GDriveConnectionRequestListener = (props) => {
    const {
        googleDriveRef,
    } = props;

    const scope = 'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.readonly';
    const dispatch = useDispatch();
    const workSpace = useSelector(getActiveWorkSpace);
    const workSpaceId = workSpace?._id;
    const googleDriveManager = useSelector(getGoogleDriveManager);
    const { socket, addPubSubEventListener } = usePubSub();
    let currentOpenedWindow;

    const updateGDriveToken = (access_token, refresh_token) => {
        APIService.updateAccountDetails({ google_drive_access_token: access_token, google_drive_refresh_token: refresh_token, google_drive_access_token_expires_at: getNextMinsDate(50) }, (response, error) => {
            if (error) {
                console.log(error);
                return;
            }
            APIService.fetchLatestMe((latestMeResponse, error) => {
                if (error) {
                    toast.error(error, { theme: 'colored' });
                    return;
                }
                let latestUserData = latestMeResponse.data;
                dispatch(setLoggedInUser({ ...latestUserData }));
                console.log(currentOpenedWindow, 'currentOpenedWindow')
                if (currentOpenedWindow) {
                    currentOpenedWindow?.close();
                    currentOpenedWindow = undefined;
                }
                setTimeout(async () => {
                    toast.success(
                        `Google Drive account is connected successfully!`,
                        { theme: "colored" }
                    );
                    dispatch(setGoogleDriveManager({ ...googleDriveManager, connected: true }))
                }, 1000);
            });
        });
    }

    const connectSocketIOEventListener = () => {
        addPubSubEventListener(`${workSpaceId}_connecting_google`, ({ data, status }) => {
            if (!status) {
                toast.error(
                    `Failed to connect GDrive. Try again later.`,
                    { theme: "colored" }
                )
                return
            }
            updateGDriveToken(data['access_token'], data['refresh_token']);
        })
    }

    useEffect(() => {
        connectSocketIOEventListener();
    }, [])

    useImperativeHandle(googleDriveRef, () => ({
        click: () => {
            currentOpenedWindow = window.open(`https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://api.postly.ai/v1/external_image/callback_google&prompt=consent&response_type=code&client_id=111332725522-lm6o114nrh5jncs9ecb44hmouhcienmj.apps.googleusercontent.com&scope=${scope}&access_type=offline&state=${JSON.stringify({ socketId: socket?.id, workSpaceId })}`);
            connectSocketIOEventListener();
        }
    }), []);

    return (
        <div ref={googleDriveRef} target="_blank" className="GDrive-button"></div>
    )

}

export default GDriveConnectionRequestListener