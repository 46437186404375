import React from "react";
import PropTypes from "prop-types";
import { useContext } from "react";
import { ChatContext } from "context/Context";

const LastMessage = ({ lastMessage, thread }) => {
  const { getUser, getAccount } = useContext(ChatContext);

  // const user = lastMessage?.sender === thread?.user_id ? useFetchPageOwner(activeRoomId, conversations, users) : getUser(thread?.room_id);
  if (lastMessage) {
    const user =
      lastMessage?.sender === thread?.user_id
        ? getAccount(thread?.room_id)
        : getUser(thread?.room_id);
    const name = user?.name?.split(" ");
    let lastMessagePreview;

    if (
      lastMessage?.message?.attachments &&
      (!lastMessage?.message?.reactions ||
        lastMessage?.message?.reactions?.length === 0)
    ) {
      const attachmentType = lastMessage.message.attachments[0].type;

      switch (attachmentType) {
        case "image":
          lastMessagePreview = `sent a photo`;
          break;
        case "video":
          lastMessagePreview = `sent a video`;
          break;
        case "audio":
          lastMessagePreview = `sent an audio`;
          break;
        // Add more cases for handling other file types
        default:
          lastMessagePreview = `sent a file`;
          break;
      }
    } else if (
      lastMessage?.message?.text &&
      (!lastMessage?.message?.reactions ||
        lastMessage?.message?.reactions?.length === 0)
    ) {
      // Only consider reactions if the length is 0
      lastMessagePreview = lastMessage.message.text.split("<br>");
    } else if (
      lastMessage?.message?.reactions &&
      lastMessage?.message?.reactions.length > 0
    ) {
      lastMessagePreview = thread?.last_message;
    } else {
      // Handle the case when none of the above conditions are met
      lastMessagePreview = "Unknown message type";
    }

    if (
      lastMessage?.senderUserId === 3 ||
      lastMessage?.sender === thread?.user_id
    ) {
      return <span className="me-1">{`You: ${lastMessagePreview}`}</span>;
    }

    if (thread?.room_type === "group") {
      return (
        <div
          className="chat-contact-content"
          dangerouslySetInnerHTML={{
            __html: `${name[0]}: ${lastMessagePreview}`,
          }}
        />
      );
    }

    return (
      <div
        className="chat-contact-content"
        dangerouslySetInnerHTML={{ __html: lastMessagePreview }}
      />
    );
  }

  return <div>Say hi to your new friend</div>;
};

LastMessage.propTypes = {
  thread: PropTypes.object.isRequired,
  lastMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
export default LastMessage;