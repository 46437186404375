import { useState } from "react";
import Flex from "components/common/Flex";
import FreePageHeader from "components/common/FreePageHeader";
import { Button } from "react-bootstrap";
import Avatar from "components/common/Avatar";
import ConfirmModal from "components/common/ConfirmModal";
import APIService from "http/api_service";
import { toast } from 'react-toastify';
import { PlatformIdentifier } from "constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from 'react-redux';
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import InfoBar from "components/common/InfoBar";
import { setReconnectAccount } from "redux/slices/platformSlice";

const ConnectedPlatformInfoHeader = (props) => {
    const dispatch = useDispatch()
    const workSpace = useSelector(getActiveWorkSpace);
    const platform = props.platform ?? {};
    const setReconnectionOngoing = props?.setReconnectionOngoing
    // console.log("🚀 ~ ConnectedPlatformInfoHeader ~ platform:", platform)
    const [disconnectMsg, setDisconnectMsg] = useState("Disconnect account");
    const [displayDisconnectPrompt, setDisplayDisconnectPrompt] = useState(false);
    const [disconnectTitle, setDisconnectTitle] = useState();
    const [disconnectPrompt, setDisconnectPrompt] = useState();
    const [reconnectMsg, setReconnectMsg] = useState("Reconnect account");
    const [reconnectionClicked, setReconnectClicked] = useState(false);

    const platformNotEligibleForAutomaticReconnection = [
        PlatformIdentifier.TWITTER,
        PlatformIdentifier.TELEGRAM,
    ]

    const finalizePlatformRemoval = (action) => {
        if (action === "renew") {
            setReconnectionOngoing(true)
            dispatch(setReconnectAccount(platform.identifier))
            setReconnectMsg("Reconnecting...")
        } else {
            setDisconnectMsg("Disconnecting...");
        }
        APIService.removeWorkSpacePlatform(workSpace['_id'], platform.identifier, platform.user_id, (response, error) => {
            if (action === "renew") {
                setReconnectMsg("Reconnect account")
            } else {
                setDisconnectMsg("Disconnect account");
            }
            if (error) {
                if (action === "renew") {
                    setReconnectMsg("Reconnect account")
                    setReconnectionOngoing(false);
                } else {
                    setDisconnectMsg("Disconnect account");
                }
                toast.error(error, { theme: 'colored' });
                return;
            }
            let message = response['message'];
            if (action !== "renew") {
                toast.success(message, { theme: 'colored' });
            }
            window.history.back();
        });
    }

    const removePlatform = (action) => {
        if (action === 'renew') {
            setReconnectClicked(true)
        }
        if (platform.identifier === PlatformIdentifier.YOUTUBE) {
            setDisconnectTitle("IMPORTANT!");
            setDisconnectPrompt(`Please note that when you disconnect this YouTube account, we will also disconnect any <b>Google My Business</b> account(s) connected to the <b>${platform.user_name}</b> gmail account`);
            setDisplayDisconnectPrompt(true);
            return;
        }
        if (platform.identifier === PlatformIdentifier.GOOGLE_MY_BUSINESS) {
            setDisconnectTitle("IMPORTANT!");
            setDisconnectPrompt(`Please note that when you disconnect this Google My Business account, we will also disconnect any <b>YouTube</b> account(s) connected to the <b>${platform.user_name}</b> gmail account`);
            setDisplayDisconnectPrompt(true);
            return;
        }
        finalizePlatformRemoval(action);
    }

    const displayReconnectionButton = () => {
        if (platformNotEligibleForAutomaticReconnection.includes(platform.identifier)) {
            return;
        }
        return (
            <Button
                onClick={() => {
                    removePlatform('renew');
                }}
                className=""
                variant={'primary'}
                style={{
                    margin: 'auto',
                    fontSize: 17,
                }}
            >{reconnectMsg}
            </Button>
        )
    }

    return (
        <FreePageHeader
            titleTag="h5"
            className="mb-3">
            <Flex
                alignItems={'center'}
                className={'gap-2 flex-1'}
                wrap={'wrap'}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    color: platform.color,
                    alignSelf: 'center',
                    background: '#dde7f5',
                    marginRight: 5,
                    width: 55,
                    height: 55,
                    borderRadius: 100,
                    justifyContent: 'center',
                    position: 'relative',
                    padding: 5
                }}
                >
                    <Avatar
                        src={platform.user_photo}
                        alt={platform.user_name}
                        // style={{
                        //     width: 10,
                        //     height: 10
                        // }}
                        size="10"
                    />
                    <div
                        style={{
                            position: 'absolute',
                            bottom: -2,
                            right: -11,
                            background: '#FFF',
                            borderRadius: '10%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // padding: -5,
                            paddingTop: 0
                        }}>
                        <FontAwesomeIcon
                            icon={platform.icon}
                            variant={'light'}
                            style={{
                                width: 33,
                                height: 33
                            }}
                        />
                    </div>
                </div>
                <h5>{platform.user_name}</h5>
                <Flex
                    direction={"column"}
                >
                    <InfoBar info={"If your post shows published status but does not display on the social platforms or you have recurring post issues. Please reconnect your account and ensure to accept all permissions while reconnecting."} />
                    <Flex
                        gap={3}
                        style={{
                            marginTop: '10px',
                            marginBottom: '10px',
                        }}

                    >
                        <Button
                            onClick={() => {
                                removePlatform();
                            }}
                            className=""
                            variant={'danger'}
                            style={{
                                margin: 'auto',
                                fontSize: 17,
                            }}
                        >{disconnectMsg}
                        </Button>
                        {
                            displayReconnectionButton()
                        }
                    </Flex>
                </Flex>
                <div style={{ flex: 1 }}></div>
            </Flex>
            <p className="fs--1 mt-1">{`Your connected ${platform.name} account`}</p>
            <ConfirmModal
                open={displayDisconnectPrompt}
                onClose={() => {
                    setDisplayDisconnectPrompt(false);
                }}
                title={disconnectTitle}
                message={disconnectPrompt}
                cancelText='CANCEL'
                confirmText='CONTINUE'
                onCancel={() => {
                    setReconnectClicked(false)
                    setDisplayDisconnectPrompt(false);
                }}
                onConfirm={() => {
                    setDisplayDisconnectPrompt(false);
                    finalizePlatformRemoval(reconnectionClicked ? 'renew' : null);
                }}
            />
        </FreePageHeader >
    )
}
export default ConnectedPlatformInfoHeader;