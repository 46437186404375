import styled from "styled-components";

export const PexelsHeader = styled.div`
width: 100%;
height: 20%;
display: flex;
justify-content: space-between;
align-items: center;
padding: 10px 20px;
border-bottom: 1px solid #d6dbe2;
`;
export const MediaBox = styled.div`
width: fit-content;
height: auto;
display: flex;
justify-content: center;
align-items: center;
gap: 20px;
`;