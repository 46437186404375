import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import createMarkup from 'helpers/createMarkup';
import { Button, Form } from 'react-bootstrap';
import av3 from 'assets/img/team/3.jpg';
import { v4 as uuid } from 'uuid';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import {useSelector} from "react-redux"
import { getSelectedPageForPostCommentsView } from 'redux/slices/instagramComments_slice';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import InstaComment from './InstaComment';



const InstaComments = ({ user, comments, loadComment }) => {
  dayjs.extend(relativeTime);

  const selectedPageForPostCommentsView = useSelector(getSelectedPageForPostCommentsView);

    //state hook that'll handle display comment reply input field.
    const [showReplyInput, setShowReplyInput] = useState(false);
    //write state to handle the reply comment input field
    const [comment, setComment] = useState('');
    //write state for if coment is being submited.
    const [isSubmitting, setIsSubmitting] = useState(false);


    const submitComment = (id) => {
        setIsSubmitting(true)
        const requestBody = {
            commentId: id,
            replyText: comment,
            accessToken: selectedPageForPostCommentsView?.accessToken,
        };
        //write an if condition that'll check if the request body object keys has value.
        if (!requestBody.replyText) {
            toast.error('Please enter a comment', { theme: "colored" });
            setIsSubmitting(false);
            return;
        }

        APIService.replyInstagramComment(requestBody, (data, error) => {
            if (error) {
                toast.error(error, { theme: "colored" })
                console.log("🚀 ~ APIService.getUserInstagramPosts ~ error:", error);
                setIsSubmitting(false);
                return;
            } else {
                console.log("🚀 ~ APIService.getUserInstagramPosts ~ data:", data?.data)
                toast.success("Reply sent successfully...", { theme: "colored" })
                setComment("")
                setIsSubmitting(false);
                return;
            }
        });

        // const feed = feeds.find(feed => feed.id === id);
        // feed.details.reactions.comment = true;
        // feed.details.comments = [newComment, ...comments];
        // instaFeedDispatch({ type: 'UPDATE', payload: { id, feed } });
        // setComment('');
    };

    return (
        <>
            {
                comments.map(({ id, avatarSrc, name, content, postTime }) => (
                    <div key={id}>
                        <InstaComment user={user} id={id} avatarSrc={avatarSrc} name={name} content={content} postTime={postTime} />
                    </div>
                ))
            }
            {
                loadComment && (
                    <span className="fs--1 cursor-pointer text-700 d-inline-block mt-2" href="">
                        Load more comments ({loadComment})
                    </span>
                )
            }
        </>
    );
};

InstaComments.propTypes = {
    comments: PropTypes.array,
    loadComment: PropTypes.string
};

export default InstaComments;
