import LTDUpgradePage from "components/app/account/pricing/LTDUpgradePage";
import FalconCardHeader from "components/common/FalconCardHeader";
import Flex from "components/common/Flex";
import APIService from "http/api_service";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getLoggedInUser, setLoggedInUser } from "redux/slices/user_slice";
import {
  getActiveWorkSpace,
  getBilledYearly,
  getTeam,
  setActiveWorkspace,
  setSocialAccountQuantity,
  setTeam
} from "redux/slices/workspaceslice";
import { isLTD } from "utils/ltd_utils";
import { isWorkSpaceUnderAppSumo } from "utils/user_utils";
import { isFreeWorkspace } from 'utils/workspace_utils';
import { MetricsProps, RoutePaths } from "../../../../constants";
import ConfirmModal from '../../../common/ConfirmModal';
import MonthlyYearlySwitch from "./MonthlyYearlySwitch";
import {
  postlyExtraUsers,
  pricingDataNew
} from "./pricingDataNew";
import { setPopupUpgradeAlert } from "redux/slices/alertSlice";
import { setCollectMetric } from "redux/slices/analytics_slice";

const AddOns = (props) => {
  // const { numberOfValidTeamMembers } = props;
  const loggedInUser = useSelector(getLoggedInUser);
  const workspace = useSelector(getActiveWorkSpace);
  const numberOfValidTeamMembers = workspace?.active_plan?.Users || null
  const [connectedPlatformsAccountStat, setConnectedPlatformsAccountStat] = useState();
  const [openSocialAccountQuantityPicker, setOpenSocialAccountQuantityPicker] = useState(false);
  const [openUserQuantityPicker, setOpenUserQuantityPicker] = useState(false);
  const [currentPlan] = useState(loggedInUser.active_plan);
  const [planType, setPlanType] = useState(0);
  const selectedPlan = pricingDataNew[planType];
  const billedYearly = useSelector(getBilledYearly);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ numberOfUsers: "", });
  const handleBilling = props.handleBilling;
  const [currentPricingData, setCurrentPricingData] = useState(null);
  const navigate = useNavigate();
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(null);
  const team = useSelector(getTeam);

  const [processingPayment, setProcessingPayment] = useState(false);
  const [checkoutCompletedMessage, setCheckoutCompletedMessage] = useState();
  const [openCheckoutCompletedPopup, setOpenCheckoutCompletedPopup] =
    useState(false);
  const totalAmountToBeCharged =
    formData.numberOfUsers *
    (billedYearly ? selectedPlan.extraPrice.Year : selectedPlan.extraPrice.Month);
  const handleAddSubscriptionQuantity = props.handleAddSubscriptionQuantity;


  const findCurrentPlanFromPricingTable = (planKey) => {
    const currentPlan = pricingDataNew.find(plan => plan.plan === planKey);
    return currentPlan || null;
  };

  useEffect(() => {
    const planKeyToFind = props.activePlanFromDB.Key;
    // Call the function to find the current plan when the component mounts
    const plan = findCurrentPlanFromPricingTable(planKeyToFind);
    // Set the current plan in the component state
    setCurrentPricingData(plan);
  }, [props.activePlanFromDB.Key]); // Re-run the effect when props.activePlanFromDB.Key changes

  const fetchConnectedSocialsCount = () => {
    APIService.fetchConnectedSocialsCount(workspace._id, (response, error) => {
      if (response) {
        let { data } = response;
        let { connected, total } = data;
        let stats = { total, connected };
        stats["true_connected"] = connected;
        connected = Math.max(connected, 1);
        stats["completed"] = Math.max(connected, 10);
        if (typeof total !== "number") {
          stats["max"] = connected;
        } else {
          stats["max"] = Math.min(total, 100);
        }
        setConnectedPlatformsAccountStat(stats);
      }
    });
  };

  const fetchTeams = async () => {
    APIService.fetchTeam(workspace['_id'], (response, error) => {
      if (error) {
        toast.error(error, { theme: 'colored' });
        return;
      }
      let data = response['data'];
      data = data.filter(x => x.user_id);
      data = data.map((x) => {
        if (!x.user_id.first_name || !x.user_id.last_name) {
          x.user_id.first_name = 'Postly';
          x.user_id.last_name = 'User'
        }
        let name = `${x.user_id.first_name} ${x.user_id.last_name}`;
        x.member = name;
        if (x.user_id._id === loggedInUser['_id']) {
          let member = x.member;
          member = `${member}(You)`;
          x.member = member;
        }
        x.email = x.user_id.email;
        return x;
      });
      dispatch(setTeam(data));
    });
  }

  useEffect(() => {
    fetchTeams();
    fetchConnectedSocialsCount();
    const plan = findCurrentPlanFromPricingTable(props.activePlanFromDB.Key);
    setCurrentPricingData(plan);
  }, []);

  const handleNumberOfSocualAccountChange = (event) => {
    const value = parseInt(event.target.value);
    dispatch(setSocialAccountQuantity(event.target.value));
    if (value >= 0) {
      setFormData({
        ...formData,
        numberOfUsers: value,
      });
    } else {
      setFormData({
        ...formData,
        numberOfUsers: "",
      });
    }
  };

  const handleClickOpenSocialAccountQuantityPicker = (planType) => {
    if (planType === 0) {
      setOpenSocialAccountQuantityPicker(true);
      setPlanType(0);
    } else if (planType === 1) {
      setOpenSocialAccountQuantityPicker(true);
      setPlanType(1);
    } else if (planType === 2) {
      setOpenSocialAccountQuantityPicker(true);
      setPlanType(2);
    }
  };

  const handleClickOpenUserQuantityPicker = () => {
    setOpenUserQuantityPicker(true);
  };

  const pollSubscriptionStatus = (heartbeat, pricingData, type, done) => {
    const intervalId = setInterval(() => {
      APIService.fetchLatestMe((latestMeResponse, error) => {
        if (error) {
          toast.error(error, { theme: "colored" });
          if (
            error
              .toLowerCase()
              .includes("Invalid credentials provided".toLowerCase())
          ) {
            window.localStorage.clear();
            setTimeout(() => {
              navigate(RoutePaths.LOGIN);
            }, 100);
          }
          return;
        }
        const localUserPlan = loggedInUser.active_plan;
        let latestUserData = latestMeResponse.data;
        const newPlan = latestUserData.active_plan;
        const referencePlan = pricingData.plan;
        if (type === "new_subscription" && newPlan.Key === referencePlan) {
          clearInterval(intervalId);
          done(latestUserData);
        } else if (type === "new_subscription" && pricingData.for_ai_writer) {
          const activeAIWriterSubscription =
            latestUserData.active_ai_writer_subscription;
          if (activeAIWriterSubscription != null) {
            clearInterval(intervalId);
            done(latestUserData);
          }
        } else if (
          type === "new_subscription" &&
          pricingData.for_telegram_bot
        ) {
          const currentWorkspace = workspace;
          const existingBotSlots = currentWorkspace.creatable_bot_slots ?? 0;
          APIService.fetchWorkSpace(currentWorkspace._id, (res, err) => {
            if (err) {
              clearInterval(intervalId);
              done(latestUserData);
              return;
            }
            const latestWorkspaceData = res.data;
            const newBotSlots = latestWorkspaceData.creatable_bot_slots ?? 0;
            if (newBotSlots > existingBotSlots) {
              dispatch(setActiveWorkspace({ ...latestWorkspaceData }));
              clearInterval(intervalId);
              done();
            }
          });
        } else if (
          type === "additional_social_accounts" &&
          newPlan.Key === referencePlan
        ) {
          const existingCount = parseInt(localUserPlan.SocialAccounts);
          const newCount = parseInt(newPlan.SocialAccounts);
          if (!isNaN(existingCount) && !isNaN(newCount)) {
            if (newCount > existingCount) {
              clearInterval(intervalId);
              done(latestUserData, newCount - existingCount);
            }
          }
        }
      });
    }, heartbeat);
  };

  const handleSubscription = (pricingData, user, _price) => {
    console.log(pricingData)
    const Paddle = window.Paddle;
    const oneOff = pricingData.one_off ?? false;
    Paddle.Checkout.open({
      quantity: pricingData.SocialAccounts ?? 1,
      product: parseInt(
        billedYearly ? pricingData.AnnualSub : pricingData.MonthlySub
      ),
      email: user?.email,
      plan: pricingData.plan,
      allowQuantity: true,
      passthrough: JSON.stringify({
        accountId: user._id,
        subscriptionMode: billedYearly ? "Yearly" : "Monthly",
        workspaceId: user.workSpaceId,
        plan: pricingData.plan,
        quantity: pricingData.SocialAccounts ?? 1,
        SocialAccounts: pricingData.SocialAccounts ?? 1,
        prorate: true,
        prorated_keys: ["SocialAccounts"],
        coupon: pricingData.coupon,
        for_ai_writer: pricingData.for_ai_writer,
        for_telegram_bot: pricingData.for_telegram_bot,
      }),
      successCallback: () => {
        setProcessingPayment(true);
        pollSubscriptionStatus(
          2000,
          pricingData,
          "new_subscription",
          (data) => {
            if (data) {
              dispatch(setLoggedInUser({ ...data }));
              checkAndLazyUpdateActiveWorkspace(data);
              setProcessingPayment(false);
              setCheckoutCompletedMessage(
                oneOff
                  ? "Payment successful"
                  : `You have successfully subscribed to the ${pricingData.title} plan`
              );
              if (!openCheckoutCompletedPopup) {
                setOpenCheckoutCompletedPopup(true);
              }
            }
          }
        );
      },
    });
  };

  const checkAndLazyUpdateActiveWorkspace = (latestUserData) => {
    if (!latestUserData) {
      return;
    }
    if (latestUserData._id === workspace.owner_id) {
      setTimeout(() => {
        dispatch(
          setActiveWorkspace({
            ...workspace,
            active_plan: latestUserData.active_plan,
          })
        );
      }, 1000);
    }
  };

  const buyExtraUsers = (pricingData, user) => {
    handleSubscription({ ...pricingData, for_postly_extra_users: true }, user);
  };

  const controlButtonAddUserButtonDisplay = () => {
    if (currentPlan["Name"] === "Free") {
      return (<>
        <h5 className="mb-0">Social Accounts</h5>
        <p className="fs--1">
          {/* You have used <b>{connectedPlatformsAccountStat?.connected}</b> out
          of <b>{connectedPlatformsAccountStat?.total}</b> Social Accounts.
          Click the button below to buy more.
          You can reduce or add more
          Social Accounts anytime.  */}
          Your social accounts may be distributed across all your workspaces.
        </p>

        <Button variant="falcon-primary" className="w-100"
          onClick={() => {
            if (isFreeWorkspace(workspace)) {
              let metricInitializer = {
                action: MetricsProps.EXTRA_SOCIAL_ACCOUNTS,
                detailedAction: `Upgrade notice: Adding extra social accounts`,
                timestamp: new Date().toISOString(),
                route: window.location.pathname.replace(workspace._id, '').replace('//', '/'),
              }
              dispatch(setCollectMetric(metricInitializer))
              dispatch(setPopupUpgradeAlert(true))
              return;
            }
            handleClickOpenSocialAccountQuantityPicker(currentPricingData.id)
          }}
        >
          Add Extra Social Accounts
        </Button>
      </>)
    }
    else if (isWorkSpaceUnderAppSumo(workspace)) {
      if (workspace?.active_plan?.Workspace > 189) {
        return (<>
          <p className="fs--1"></p>
        </>)
      } else {
        return (<>
          <LTDUpgradePage
            removeCardAreas={true}
          />
        </>)
      }
    } else {
      return (<>
        <h5 className="mb-0">Social Accounts</h5>
        <p className="fs--1">
          {/* You have used <b>{connectedPlatformsAccountStat?.connected}</b> out
          of <b>{connectedPlatformsAccountStat?.total}</b> Social Accounts.
          Click the button below to buy more.
          You can reduce or add more
          Social Accounts anytime.  */}
          Your social accounts may be distributed across all your workspaces.
        </p>

        <Button variant="falcon-primary" className="w-100"
          onClick={() => {
            if (isFreeWorkspace(workspace)) {
              let metricInitializer = {
                action: MetricsProps.EXTRA_SOCIAL_ACCOUNTS,
                detailedAction: `Upgrade notice: Adding extra social accounts`,
                timestamp: new Date().toISOString(),
                route: window.location.pathname.replace(workspace._id, '').replace('//', '/'),
              }
              dispatch(setCollectMetric(metricInitializer))
              dispatch(setPopupUpgradeAlert(true))
              return;
            }
            handleClickOpenSocialAccountQuantityPicker(currentPricingData.id)
          }}
        >
          Add Extra Social Accounts
        </Button>
      </>)
    }
  }

  return (
    <>
      <Card bg={'white'}>
        <FalconCardHeader title="Add-ons" />
        <Card.Body className="bg-light">
          {controlButtonAddUserButtonDisplay()}
          <div className="border-dashed border-bottom my-4" />
          <h5 className="mb-0">Users</h5>
          <p className="fs--1">
            {
              `Your social accounts may be distributed across all your workspaces.`
            }
            {/* You have used <b>{connectedPlatformsAccountStat?.connected}</b> out
              of <b>{connectedPlatformsAccountStat?.total}</b> User slot(s). Click
              the button below to buy more user slots.  */}
          </p>
          <Button variant="falcon-primary" className="w-100"
            onClick={() => {
              if (isFreeWorkspace(workspace)) {
                let metricInitializer = {
                  action: MetricsProps.EXTRA_USERS,
                  detailedAction: `Upgrade notice: Adding extra users`,
                  timestamp: new Date().toISOString(),
                  route: window.location.pathname.replace(workspace._id, '').replace('//', '/'),
                }
                dispatch(setCollectMetric(metricInitializer))
                dispatch(setPopupUpgradeAlert(true))
                return;
              }
              handleClickOpenUserQuantityPicker()
            }}
          >
            Add Extra Users
          </Button>
        </Card.Body>
      </Card>

      <Modal
        show={openSocialAccountQuantityPicker}
        onHide={() => setOpenSocialAccountQuantityPicker(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Upgrade
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {isLTD(currentPlan) ? (
            <p>You are not eligible for this request</p>
          ) : ( */}
          <>
            <MonthlyYearlySwitch />
            <hr />
            {planType !== 2 && (
              <span className="mt-0 fs-0 fs-sm-0 fs-lg-0 px-2">
                Your Active Plan: <b>{currentPlan["Name"]}</b>
              </span>
            )}
            <br />
            <span className="mt-0 fs-0 fs-sm-0 fs-lg-0 px-2">
              Your Number of Social Accounts: <b>{currentPlan["SocialAccounts"]}</b>
            </span>
            <br />
            {currentPlan["Name"] === "Free" && (
              <span className="mt--4 fs-0 fs-sm-0 fs-lg-0 px-2">
                Request:{" "}
                <b>
                  Upgrade to {selectedPlan.title}
                </b>
              </span>
            )}
            <br /> <br />
            <Flex direction={"column"} className={"gap-2"}>
              {
                planType !== 2 && (
                  <>
                    <Form.Group className="mt-3">
                      <Form.Label>
                        {" "}
                        How many social accounts do you want?
                      </Form.Label>
                      <Row className="align-items-center gx-0">
                        <Col
                          xs
                          md="auto"
                          className="d-flex justify-content-start"
                        >
                          <Form.Group controlId="numberOfUsers">
                            <Form.Control
                              type="number"
                              value={formData.numberOfUsers}
                              name="numberOfUsers"
                              onChange={handleNumberOfSocualAccountChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col
                          xs="auto"
                          className="d-flex justify-content-start ml-10"
                        >
                          <p className="mb-1 fs-0 fs-sm-0 fs-lg-0 px-2">
                            x $
                            {billedYearly
                              ? selectedPlan.extraPrice.Year
                              : selectedPlan.extraPrice.Month}
                            /{billedYearly ? "yr" : "mo"} = $
                            {totalAmountToBeCharged}/
                            {billedYearly ? "yr" : "mo"}
                          </p>
                        </Col>
                      </Row>
                    </Form.Group>
                    <p className="mt-4 fs-0 fs-sm-0 fs-lg-0 px-2">
                      You can buy more social accounts at any time. You will
                      be charged a prorated fee for the billing cycle.
                    </p>
                  </>
                )}
              <div className="text-end">
                <Button
                  disabled={formData.numberOfUsers <= 0}
                  onClick={() => {
                    handleAddSubscriptionQuantity(currentPricingData);
                  }}
                  style={{ width: "100%" }}
                >
                  Pay now
                </Button>
              </div>
              <div className="text-center">
                <Button
                  onClick={() => {
                    setOpenSocialAccountQuantityPicker(false);
                  }}
                  variant={"outlined"}
                >
                  Cancel
                </Button>
              </div>
            </Flex>
          </>
          {/* )} */}
        </Modal.Body>
      </Modal>

      <Modal
        show={openUserQuantityPicker}
        onHide={() => setOpenUserQuantityPicker(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Buy extra user seats
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // isLTD(currentPlan) ? (
            //   <p>You are not eligible for this request</p>
            // ) : 
            (
              <>
                <MonthlyYearlySwitch />
                <hr />
                {planType !== 2 && (
                  <span className="mt-0 fs-0 fs-sm-0 fs-lg-0 px-2">
                    Your Active Plan: <b>{currentPlan["Name"]}</b>
                  </span>
                )}
                <br />
                {/* <span className="mt-0 fs-0 fs-sm-0 fs-lg-0 px-2">
                  Your number of users: <b>{currentPlan["SocialAccounts"]}</b>
                </span> */}
                <br />
                {currentPlan["Name"] === "Free" && (
                  <span className="mt--4 fs-0 fs-sm-0 fs-lg-0 px-2">
                    Request:{" "}
                    <b>
                      Upgrade to {selectedPlan.title}
                    </b>
                  </span>
                )}
                <br /> <br />
                <Flex direction={"column"} className={"gap-2"}>
                  {
                    planType !== 2 && (
                      <>
                        <Form.Group className="mt-3">
                          <Form.Label>
                            {" "}
                            How many users do you want?
                          </Form.Label>
                          <Row className="align-items-center gx-0">
                            <Col
                              xs
                              md="auto"
                              className="d-flex justify-content-start"
                            >
                              <Form.Group controlId="numberOfUsers">
                                <Form.Control
                                  type="number"
                                  value={formData.numberOfUsers}
                                  name="numberOfUsers"
                                  onChange={handleNumberOfSocualAccountChange}
                                />
                              </Form.Group>
                            </Col>
                            <Col
                              xs="auto"
                              className="d-flex justify-content-start ml-10"
                            >
                              <p className="mb-1 fs-0 fs-sm-0 fs-lg-0 px-2">
                                x $
                                {billedYearly
                                  ? selectedPlan.extraPrice.Year
                                  : selectedPlan.extraPrice.Month}
                                /{billedYearly ? "yr" : "mo"} = $
                                {totalAmountToBeCharged}/
                                {billedYearly ? "yr" : "mo"}
                              </p>
                            </Col>
                          </Row>
                        </Form.Group>
                        <p className="mt-4 fs-0 fs-sm-0 fs-lg-0 px-2">
                          You can buy more seats at any time. You will
                          be charged a prorated fee for the billing cycle.
                        </p>
                      </>
                    )}
                  <div className="text-end">
                    <Button
                      disabled={formData.numberOfUsers <= 0}
                      onClick={() => {
                        buyExtraUsers(
                          {
                            ...postlyExtraUsers,
                            for_postly_extra_user: true,
                          },
                          loggedInUser
                        );
                      }}
                      style={{ width: "100%" }}
                    >
                      Pay now
                    </Button>
                  </div>
                  <div className="text-center">
                    <Button
                      onClick={() => {
                        setOpenSocialAccountQuantityPicker(false);
                      }}
                      variant={"outlined"}
                    >
                      Cancel
                    </Button>
                  </div>
                </Flex>
              </>
            )}
        </Modal.Body>
      </Modal>
      <ConfirmModal
        open={openUpgradeDialog}
        title={"Action requires Upgrade"}
        message={"Sorry, this requires a premium account. Upgrade your plan to use this feature. Thank you!"}
        confirmText={"UPGRADE"}
        cancelText={"CANCEL"}
        onCancel={() => {
          setOpenUpgradeDialog(false);
        }}
        onConfirm={() => {
          setOpenUpgradeDialog(false);
          navigate(`${RoutePaths.BILLING_STRIPE}#plan_check`);
        }}
      />
    </>
  );
};

export default AddOns;
