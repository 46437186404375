
export const isLTD = (activePlan) => {
    if (activePlan.LTD) {
        return true;
    }
    if ((activePlan.Name || activePlan.Key).toLowerCase().includes('appsumo')) {
        return true;
    }
    if ((activePlan.Name || activePlan.Key).toLowerCase().includes('pitchground')) {
        return true;
    }
    return false;
}

export const isLTDOrFreeAccount = (activePlan) => {
    if (activePlan.LTD) {
        return true;
    }
    if ((activePlan.Name || activePlan.Key).toLowerCase().includes('appsumo')) {
        return true;
    }
    if ((activePlan.Name || activePlan.Key).toLowerCase().includes('pitchground')) {
        return true;
    }
    return activePlan["Name"].toLowerCase() === "Free".toLowerCase();
}