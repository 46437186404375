import Flex from 'components/common/Flex';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getPostCategories, setPostCategories } from 'redux/slices/postslice';
import invite from 'assets/img/illustrations/ecommerce-bg.png';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import NewPostCaegoryForm from './NewPostCategoryForm';
import PostCategoryList from './PostCategoryList';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';

const PostCategoriesPopup = ({ initialValue, modalClose }) => {

    const dispatch = useDispatch();
    const postCategories = useSelector(getPostCategories);
    const [fetchingCategories, setFetchingCategories] = useState(false);
    const workSpace = useSelector(getActiveWorkSpace);

    const fetchPostCategories = () => {
        setFetchingCategories(true);
        APIService.fetchWorkSpacePostCategories(workSpace._id, (response, error) => {
            setFetchingCategories(false);
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { data } = response;
            dispatch(setPostCategories(data));
        });
    }

    useEffect(() => {
        if (!postCategories.length) {
            fetchPostCategories();
        }
    }, []);


    const NoPostCategoriesView = () => {

        return (
            <Flex direction={'column'}
                justifyContent={'center'}
                alignItems={'center'}>
                <img
                    className="img-fluid"
                    src={invite}
                    alt="image"
                    width="28%"
                />
                <h3 className="mt-3 fw-normal fs-2 mt-md-4 fs-md-3">
                    Post Campaign
                </h3>
                <p className="mb-5 text-align-center" style={{ textAlign: 'center', maxWidth: 450 }}>
                    Use campaigns to organize your posts into relevant sections for easy referencing and filtering.
                </p>
                <NewPostCaegoryForm />
            </Flex>
        )
    }

    return (
        <Flex
            className={'p-5'}
            alignItems={'center'}
            justifyContent={'center'}>
            {
                fetchingCategories &&
                <Flex alignItems={'center'} justifyContent={'center'}>
                    <Spinner animation={'border'} />
                </Flex>
            }
            {
                (!fetchingCategories && !postCategories.length) &&
                <NoPostCategoriesView />
            }
            {
                (!fetchingCategories && postCategories.length > 0) &&
                <PostCategoryList initialValue={initialValue} modalClose={modalClose} />
            }
        </Flex>
    )

}

export default PostCategoriesPopup;