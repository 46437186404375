import React, { useEffect, useState } from 'react';
import { munchTarget } from '../audience';
import {
    getMunchedPostTargetIds,
    setMunchedPostTargetIds,
} from '../../../../../redux/slices/postslice';
import { useSelector, useDispatch } from 'react-redux';
import BoardSectionsPage from './board_sections';
import { PlatformIdentifier } from '../../../../../constants';
import Avatar from 'components/common/Avatar';
import { Form } from 'react-bootstrap';

export default function AccountSubListItem(props) {

    const dispatch = useDispatch();
    const { subListItemChangedHandler, id, accountId } = props;
    const [subListItem, setSubListItem] = useState(props.subListItem);
    const [checked, setChecked] = useState(subListItem.selected);
    const munchedTargetIds = [...useSelector(getMunchedPostTargetIds)];

    const getSubTitle = () => {
        let subListItemTarget = subListItem.target ?? subListItem.identifier;
        switch (subListItemTarget) {
            case PlatformIdentifier.FACEBOOK:
                if (subListItem.is_page) {
                    return "page";
                }
                return "group";
            case PlatformIdentifier.INSTAGRAM:
                return "page";
            case PlatformIdentifier.YOUTUBE:
                return "channel";
            case PlatformIdentifier.LINKED_IN:
                return "page";
            case PlatformIdentifier.REDDIT:
                return "subreddit";
            case PlatformIdentifier.PINTEREST:
                return "board";
            case PlatformIdentifier.TELEGRAM:
                if (subListItem.is_group) {
                    return "group";
                }
                return "channel";
            case PlatformIdentifier.GOOGLE_MY_BUSINESS:
                return "location";
            default:
                return "";
        }
    }

    useEffect(() => {
        setSubListItem(props.subListItem);
        setChecked(props.subListItem.selected);
    }, [props.subListItem]);

    const sectionChangeHandler = (selectedSection) => {
        let boardSections = subListItem.sections;
        let existingBoard = boardSections.find(section => section.id === selectedSection.id);
        if (existingBoard) {
            let indexOfExistingBoard = boardSections.indexOf(existingBoard);
            if (indexOfExistingBoard !== -1) {
                boardSections[indexOfExistingBoard] = selectedSection;
            }
        }
        let subListItemCopy = { ...subListItem };
        subListItemCopy.sections = boardSections;
        setSubListItem({ ...subListItemCopy });
    }

    const selectSubListItem = () => {
        let subListItemCopy = { ...subListItem };
        let prevState = subListItemCopy.selected ?? false;
        let newState = !prevState;
        subListItemCopy.selected = newState;
        setSubListItem({ ...subListItemCopy });
        setChecked(newState);
        subListItemChangedHandler(subListItemCopy, accountId);
        let munchedTarget = munchTarget(subListItemCopy.id, subListItemCopy.target);
        if (!newState) {
            let indexOfMunchedTarget = munchedTargetIds.indexOf(munchedTarget);
            if (indexOfMunchedTarget !== -1) {
                munchedTargetIds.splice(indexOfMunchedTarget, 1);
            }
        } else {
            if (!munchedTargetIds.includes(munchedTarget)) {
                munchedTargetIds.push(munchedTarget);
            }
        }
        dispatch(setMunchedPostTargetIds([...munchedTargetIds]));
    }

    function shortenWord(word, N) {
        if (word.length <= N) {
            return word;
        } else {
            return word.slice(0, N) + "...";
        }
    }
    return (
        <div
            id={id}
            onClick={(e) => {
                console.log('clicked here again now.....')
                selectSubListItem();
            }}
            style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 3,
                paddingLeft: 0,
                cursor: 'pointer'
            }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingRight: 10,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        paddingTop: 5,
                        alignItems: 'center',
                        gap: 4
                    }}>
                    <Avatar
                        src={subListItem.picture}
                        fallBackName={subListItem.name}
                        avatarWidth={13}
                        avatarStyles={{
                            width: 25,
                            height: 25
                        }}
                    />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                gap: 2,
                                alignItems: 'center'
                            }}>
                            <p className='m-0'

                                style={{
                                    fontSize: 11,
                                    width:95,
                                    wordWrap:'break-word'
                                }}>
                                {
                                    shortenWord(subListItem.name, 35)
                                }
                            </p>
                            {
                                subListItem.business_account_username &&
                                <span
                                    style={{
                                        // paddingLeft: 2
                                        fontSize: 10,
                                    }}><b>
                                        (@{shortenWord(subListItem.business_account_username, 8)})
                                    </b>
                                </span>
                            }
                        </div>
                        <span
                            style={{
                                fontSize: 10,
                                color: '#90A4AE'
                            }}>
                            {getSubTitle()}
                        </span>
                    </div>
                </div>
                <div
                    style={{
                        paddingTop: 10
                    }}>
                    <Form.Check
                        type='checkbox'
                        id={`${subListItem.name}-check`}
                        checked={checked}
                        alignItems={'flex-start'}
                        paddingTop={11}
                        onChange={(value) => { }}
                        style={{
                            width: 14,
                            height: 14
                        }}
                    />
                </div>
            </div>
            {
                subListItem.sections &&
                <BoardSectionsPage
                    sectionChangeHandler={sectionChangeHandler}
                    sections={subListItem.sections}
                    target={subListItem.target}
                />
            }
        </div>
    );
}
