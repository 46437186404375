import Flex from 'components/common/Flex'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'

const ConnectedPageOrGroupEdit = (props) => {
    const { connected_page_or_group, setCloneData, account, cloneData, platform } = props
    const [checked, setChecked] = useState(connected_page_or_group?.selected ?? false)
    const [initialChecked, setInitialChecked] = useState(false)

    useEffect(() => {
        setChecked(connected_page_or_group?.selected)
    }, [cloneData])

    const atLeastOnePagesOrGroupIsSelected = (account) => {
        let foundOnePageOrGroupSelected = false;
        let array = account?.connected_pages_and_groups
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            if (element?.selected) {
                foundOnePageOrGroupSelected = true
                break
            }
        }
        return foundOnePageOrGroupSelected;
    }

    const allPagesOrGroupAreSelected = (platform) => {
        let foundOnePageOrGroupSelected = true;
        let array = platform?.accounts
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            for (const pageOrGroup of element.connected_pages_and_groups) {
                if (!pageOrGroup?.selected) {
                    foundOnePageOrGroupSelected = false
                    break
                }
            }
        }
        return foundOnePageOrGroupSelected;
    }

    const handleSelection = () => {
        const _account = { ...account };
        const _connected_page_or_group = _account?.connected_pages_and_groups?.find((_connected_page_or_group) => _connected_page_or_group.id === connected_page_or_group.id);
        _connected_page_or_group.selected = checked;
        const _connected_page_or_group_index = _account?.connected_pages_and_groups?.findIndex((_connected_page_or_group) => _connected_page_or_group.id === connected_page_or_group.id);
        _account?.connected_pages_and_groups?.splice(_connected_page_or_group_index, 1, _connected_page_or_group);
        const atLeastOnePageOrGroupIsPicked = atLeastOnePagesOrGroupIsSelected(_account)
        _account.selected = atLeastOnePageOrGroupIsPicked;
        const _cloneData = [...cloneData];
        const _platform = { ...platform }
        let platformAccounts = []
        for (const platformAccount of _platform.accounts) {
            if (platformAccount.identifier === _account.identifier) {
                platformAccounts.push(_account)
                continue;
            }
            platformAccounts.push(platformAccount)
        }
        _platform.accounts = platformAccounts;
        _platform.selected = allPagesOrGroupAreSelected(_platform);
        let newCloneData = []
        for (const clone of _cloneData) {
            if (clone.identifier === _platform.identifier) {
                newCloneData.push(_platform);
                continue;
            }
            newCloneData.push(clone);
        }
        setCloneData(newCloneData);
    }

    useEffect(() => {
        if (!initialChecked) {
            setInitialChecked(true)
            return
        }
        handleSelection()
    }, [checked])

    return (
        <Flex
            direction={'row'}
            className={'gap-3'}
            justifyContent={'between'}
            alignItems={'center'}
        >
            <Flex
                direction={'row'}
                className={'gap-3'}
                justifyContent={'start'}
                alignItems={'center'}
            >
                <img src={connected_page_or_group?.picture} alt="" height="24" width="24" />
                <span>
                    {connected_page_or_group?.name}
                </span>
            </Flex>
            <Flex
                direction={'row'}
                className={'gap-3'}
                justifyContent={'start'}
                alignItems={'center'}
            >
                <Form.Check
                    id={`${connected_page_or_group?.name}-group-checkbox`}
                    type="checkbox"
                    name='groups'
                    checked={checked}
                    className={'mt-2'}
                    onChange={(e) => {
                        setChecked(e.target.checked)
                    }}

                />

            </Flex>
        </Flex>
    )
}

export default ConnectedPageOrGroupEdit