import PageHeader from 'components/common/PageHeader'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { getActiveWorkSpace, getBilledYearly, getSearchTerm, setActiveWorkspace } from 'redux/slices/workspaceslice';
import { getLoggedInUser } from 'redux/slices/user_slice';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import APIKeyManagementCentre from 'components/app/ai-options/api_key_management'
import Flex from 'components/common/Flex';
import InfoBar from 'components/common/InfoBar';

const AISettings = (props) => {

    const { tools } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [categories, setCategories] = useState(tools);
    const [originalCategories] = useState(categories);
    const workSpace = useSelector(getActiveWorkSpace);
    const searchTerm = useSelector(getSearchTerm);
    const billedYearly = useSelector(getBilledYearly);
    const loggedInUser = useSelector(getLoggedInUser);
    const [currentChars, setCurrentChars] = useState(0)
    const [apiKey, setApiKey] = useState({ geminiKey: null, claudeAIKey: null, openAIKey: null });
    const [isLoading, setIsLoading] = useState(true)

    const checkAIWriterUsageResumption = () => {
        APIService.checkAIWriterUsageResumption(workSpace["_id"], (res, err) => {
            if (err) {
                toast.error(err, { theme: 'colored' })
                return;
            }
            let apikeys = {}
            apikeys.geminiKey = res?.data?.ai_writer_model_settings?.geminiKey
            apikeys.claudeAIKey = res?.data?.ai_writer_model_settings?.claudeAIKey
            apikeys.openAIKey = res?.data?.ai_writer_model_settings?.openAIKey
            setApiKey(apikeys)
            APIService.fetchWorkSpace(workSpace["_id"], (response, error) => {
                if (error) {
                    return;
                }
                if (response) {
                    let responseData = response["data"];
                    let receivedWorkspace = responseData["workspace"];
                    receivedWorkspace["role"] = responseData["role"];
                    receivedWorkspace["value"] = receivedWorkspace["name"];
                    receivedWorkspace["title"] = responseData["title"];
                    receivedWorkspace["label"] = receivedWorkspace["name"];
                    dispatch(setActiveWorkspace({ ...receivedWorkspace }));
                }
                setIsLoading(false)
            });
            APIService.getWorkspaceOwnerAICredits(workSpace["_id"], (response, error) => {
                if (error) {
                    return;
                }
                if (response) {
                    setCurrentChars(response?.data?.used_ai_writer_characters)
                }
                setIsLoading(false)
            });
        });
    };

    useEffect(() => {
        checkAIWriterUsageResumption()
    }, [])


    return (
        <>
            <PageHeader
                title=""
            >
                {loggedInUser?.active_ai_writer_subscription ? (
                    <>
                        <h5 className="text-600 fs-0 mb-3">
                            Credits Used:{" "}
                            <span className="text-primary fw-semi-bold">
                                {" "}
                                {currentChars?.toLocaleString() || 0}/Unlimited
                            </span>{" "}
                            Characters
                        </h5>
                        <h5 className="text-600 fs-0 mb-3">
                            Your credits will reset on:{" "}
                            <span className="text-primary fw-semi-bold">
                                {loggedInUser?.active_ai_writer_subscription.next_bill_date}{" "}
                            </span>{" "}
                        </h5>
                    </>
                ) : (
                    <>
                        <h5 className="text-600 fs-0 mb-3">
                            Credits Used:{" "}
                            <span className="text-primary fw-semi-bold">
                                {" "}
                                {currentChars?.toLocaleString() || 0}/
                                {loggedInUser?.active_plan["AI_Writer_Chars"]?.toLocaleString() || loggedInUser?.active_plan["AI_Writer_Words"]?.toLocaleString()}{" "}
                            </span>
                            Characters
                        </h5>
                        {loggedInUser?.active_plan?.next_bill_date && (
                            <h5 className="text-600 fs-0 mb-3">
                                Monthly credits will reset on:{" "}
                                <span className="text-primary fw-semi-bold">
                                    {loggedInUser?.active_plan.next_bill_date}{" "}
                                </span>{" "}
                            </h5>
                        )}
                    </>
                )}
            </PageHeader>
            <Flex
                direction={'column'}
                gap={2}
                className={'mt-3'}
                style={{
                    width: '100%'
                }}
            >
                <APIKeyManagementCentre
                    apiKey={apiKey}
                    checkAIWriterUsageResumption={checkAIWriterUsageResumption}
                    workSpace={workSpace}
                />

                <InfoBar info={"Please note that your keys are only visible to you once when you initially add them. If you forget your keys, you may need to generate new ones."} />
            </Flex>
        </>
    )
}

export default AISettings