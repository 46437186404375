import React from 'react';

const VisibleTip = ({ top = '10px', left, username, handleClick, imageUrl, _imageUrl }) => {
    return (
        <div
            style={{
                position: 'absolute',
                backgroundColor: '#0a0706',
                color: 'white',
                padding: '8px',
                borderRadius: '4px',
                fontSize: '14px',
                top: top,
                left: left,
                transform: 'translateX(-50%)',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer'
            }}
            onClick={handleClick}
        >
            <div
                style={{
                    position: 'absolute',
                    width: '12px',
                    height: '12px',
                    backgroundColor: '#0a0706',
                    top: '-6px',
                    left: '50%',
                    transform: 'translateX(-50%) rotate(45deg)',
                }}
            />
            <div>{username}</div>
        </div>
    )
}

export default VisibleTip