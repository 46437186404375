import React, { useState } from "react";
import { Modal, Form, Button, Row } from "react-bootstrap";
import PropTypes from "prop-types";

const EmailExportField = (props) => {
    const { title, ...others } = props;
    const [email, setEmail] = useState(null);
    const [processing, setProcessing] = useState(false);

    const handleEmailReport = e => {
        e.preventDefault();
        // logic for email export here...
    }

    return (
        <Modal
            {...others}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Email Report
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleEmailReport}>
                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            placeholder={"Email"}
                            value={email}
                            name="email"
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            type="email"
                        />
                    </Form.Group>
                    <Row>
                        <Form.Group>
                            <Button
                                type="submit"
                                color="primary"
                                className="mt-3 w-100"
                                disabled={!email || processing}
                            >
                                {processing ? 'Please wait...' : 'Send Email Report'}
                            </Button>
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default EmailExportField;
EmailExportField.propTypes = {
    title: PropTypes.string,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.node.isRequired
}