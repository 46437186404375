import React, { useContext, useEffect, useState } from 'react';
import {
    useSelector,
    useDispatch
} from 'react-redux';
import {
    getUploadableBulkContent,
    setUploadableBulkContent
} from "../../../../../redux/slices/postslice";
import TextareaAutoSize from 'react-textarea-autosize';
import AppContext from 'context/Context';

export default function BulkContentEditTextArea(props) {

    const {
        rowIndex = 0,
        property
    } = props;
    const dispatch = useDispatch();
    const uploadableBulkContent = useSelector(getUploadableBulkContent);
    const bulkContentRow = uploadableBulkContent[rowIndex] ?? {};
    const [value, setValue] = useState(bulkContentRow[property]);
    const { config } = useContext(AppContext);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            let uploadableBulkContentCopy = [...uploadableBulkContent];
            let updatedRow = { ...bulkContentRow };
            updatedRow[property] = value;
            uploadableBulkContentCopy[rowIndex] = updatedRow;
            dispatch(setUploadableBulkContent(uploadableBulkContentCopy));
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [value])

    return (
        <TextareaAutoSize
            maxRows={10}
            value={value}
            className={`shadow-none resize-none px-card border-200`}
            onChange={(e) => {
                let value = e.target.value;
                if (!value) {
                    value = '';
                }
                setValue(value);
            }}
            style={{
                width: 200,
                height: 100,
                outline: 'none',
                whiteSpace: 'pre-wrap',
                display: 'inherit',
                background: config.isDark ? "#0b1727" : "white",
                color: 'inherit',
                fontSize: 'inherit',
                border: '2px dashed gray'
            }}
        />
    );
}