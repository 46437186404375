import styled from "styled-components";

export const ParentPreviewSection = styled.div`
  position: relative;
  display: flex;
  flex-direction:column;
  column-gap: 10px;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: flex-start;
`;

export const LinkingTag = styled.a`
  position: relative;
  text-decoration:none;
`;
export const ImageHolder = styled.img`
  position: relative;
  display: flex;
  min-width: 100%;
  max-width:100%;
  max-height: 350px;
`;
export const TopLayer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 250px;
`;
export const BottomLayer = styled.div`
position: relative;
display: flex;
flex-direction:column;
width: 100%;
min-height: 70px;
row-gap:10px;
padding:1%;
`;
export const BottomTop = styled.div`
position: relative;
flex:1.5;
display: flex;
justify-content:flex-start;
align-items:center;
width: 100%;
height: 100px;
gap:10px;
`;
export const BottomBottom = styled.div`
position: relative;
flex:3;
display: flex;
font-weight:700;
width: 100%;
height: 100px;
`;