import styled, { keyframes } from "styled-components";

const fadeInAnimation = keyframes`
from {
  opacity: 0;
  margin-left:-10%;
}
to {
  opacity: 1;
  margin-left:0%;
}
`;

export const ParentSection = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  animation: ${fadeInAnimation} 0.3s linear;
  cursor: auto;
  justify-content: center;
  align-items: flex-start;
`;

export const Wrapper = styled.div`
  /* flex: 9; */
  /* height: fit-content; */
  width: 100%;
  position: relative;
`;

export const PreviewContentArea = styled.div`
  /* border-style:solid;
  border-width:1px; */
  width: 100%;
  background-color: ${(props) => props.mainBgColor};
  /* z-index: 2; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-top: 10px;
  padding-top: 8px;
  /* margin-bottom: -60px; */
  animation: ${fadeInAnimation} 0.4s linear;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0 !important;
  }
`;

export const Title = styled.div`
  font-size: 15px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
export const FirstLayer = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  column-gap: 10px;
  padding:2px 5px;
`;

export const ImageHolder = styled.div`
  min-height: 35px;
  min-width: 35px;
  max-height: 35px;
  max-width: 35px;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  margin-left: 5px;
`;

export const Info = styled.div`
  height: auto;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 12px;
  @media only screen and (max-width: 768px) {
    font-size: 9px;
  }
`;

export const DotsBox = styled.div`
  height: 70px;
  padding: 0 2%;
  overflow: hidden;
  display: flex;
  column-gap: 4px;
  margin-top: -1%;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 12px;
  width: 25%;
  @media only screen and (max-width: 768px) {
    font-size: 9px;
  }
`;

export const SecondLayer = styled.div`
  height: auto;
  width: 100%;
  line-height: 20px;
  font-size: 14px;
  @media only screen and (max-width: 768px) {
    font-size: 11px;
  }
`;

export const ThirdLayer = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-style: solid;
  border-width:0.5px;
  border-color: ${(props) => props.borderLineColor};
  align-items: center;
  column-gap: 10px;
  margin-top: -2%;
`;

export const EachBtn = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  font-size: 14px;
  @media only screen and (max-width: 768px) {
    font-size: 11px;
  }
`;

export const FourthLayer = styled.div`
  height: auto;
  width: 100%;
`;

export const PostImage = styled.div`
  height: ${(props) => props.overFiveHeight};
  width: 100%;
  background-image: url(${(props) => props.bgImg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: ${(props) => props.gridBorder};
  border-radius: 10px;
  position: relative;
`;
export const PostImageOne = styled.div`
  max-height: 100%;
  border: ${(props) => props.gridBorder};
  max-width: 100%;
  position: relative;
`;

export const ImageDelete = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  color: white;
  right: 5%;
  top: -5%;
  cursor: pointer;
  transition: transform 0.2s;
  
  :active {
    opacity:0;
  }
`;

export const GridImageOne = styled.div`
  width: 100%;
  position: relative;
`;

export const GridImage = styled.div`
  width: 100% !important;
  display: grid;
  grid-auto-rows: ${(props) => props.gridRow};
  position: relative;
  transition: all 0.45;
  gap: 5px;
`;

export const PencilEdit = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
  
  :active {
    opacity:0;
  }
`;

export const VideoEdit = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
  
  :active {
    opacity:0;
  }
`;
export const TextEdit = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
  
  :active {
    opacity:0;
  }
`;
export const EditContainer = styled.div`
  min-height: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: center;
  gap:10px;
  border-radius: 8px;
  margin-top: 10px;
  flex: 1;
`;
// export const EditContainer = styled.div`
//   min-height: 150px;
//   width: 100%;
//   background-color: ${(props) => props.editBgColor};
//   display: flex;
//   flex-direction: column;
//   justify-content:flex-start;
//   align-items: center;
//   color: white;
//   gap:10px;
//   border-radius: 8px;
//   margin-top: 10px;
//   flex: 1;
// `;
