/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import {
  faPencil,
  faRemove,
  faVideo
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmModal from "components/common/ConfirmModal";
import MultimediaSelectionDropdown from "components/common/MultimediaSelectionDropdown";
import CircularButton from "components/common/circularbutton";
import GDriveDashboard from "components/common/g-drive/dashboard";
import AppContext from "context/Context";
import parse from "html-react-parser";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getMediaArrangement, setMediaArrangement } from "redux/slices/publishable_media_arrangement_slice";
import { darkTheme, lightTheme } from "utils/theme";
import { url_replacer } from "utils/url";
import { PlatformData, PlatformIdentifier } from "../../../../../constants";
import {
  getGoogleDriveManager,
  getManuallyEditedPlatforms, getMediaAddedViaSpecificPlaformUrlMethod,
  getNotifyPreviewsOfLiveMediaChanges,
  getSelectingPhotoFromHome,
  getSpecificMediaPlatformTied, getTimeToPostContent,
  getYouTubeVideoThumbnail,
  getYouTubeVideoTitle, setGoogleDriveManager, setManuallyEditedPlatforms,
  setSelectingPhotoFromHome,
  setSpecificMediaPlatformTied
} from "../../../../../redux/slices/postslice";
import { platforms } from "../../../platforms";
import GDriveConnectionRequestListener from "./GDriveConnectionRequestListener";
import EditTextBox from "./editTextBox";
import {
  EditContainer, ImageDelete, ParentSection,
  Wrapper, YTFirstLayer, YTImageHolder, YTInfo, YTPostImage, YTPreviewContentArea, YTTimeStamp,
  YTTitle
} from "./styles/youtube";
import Flex from "components/common/Flex";
import { displayPlatformIcon } from "utils/platform_utils";
import TextareaAutoSize from "react-textarea-autosize";
import { getPlatformVideoUploadLimit } from "redux/slices/workspaceslice";
import FigureUtils from "utils/figures";

const YouTube = (props) => {
  const { viewOnly } = props;
  const dispatch = useDispatch();
  const googleDriveManager = useSelector(getGoogleDriveManager)
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const googleDriveRef = useRef(null);
  const platformsWithLimits = [...platforms];
  const media_items_arrangement = useSelector(getMediaArrangement);
  const specificMediaPlatformTied = useSelector(getSpecificMediaPlatformTied);
  const youTubeVideoThumbnail = useSelector(getYouTubeVideoThumbnail);
  const youTubeVideoTitle = useSelector(getYouTubeVideoTitle);
  const textEditRef = useRef(null);
  const mediaAddedViaSpecificPlaformUrlMethod = useSelector(
    getMediaAddedViaSpecificPlaformUrlMethod
  );
  const [currentMediaType, setCurrentMediaType] = useState(null);
  const [openMediaSelction, setOpenMediaSelection] = useState(false);
  const mediaObjectsTotalLength = 1;
  const selectingPhotoFromHome = useSelector(getSelectingPhotoFromHome);
  const [useableMedia, setUseableMedia] = useState([]);
  const [validYoutubeMedia, setValidYoutubeMedia] = useState([]);
  const [timeFormat, setTimeFormat] = useState();
  const myRef = useRef(null);
  const timeToPost = useSelector(getTimeToPostContent);
  const [blockMediaItemsUpdate, setBlockMediaItemsUpdate] = useState(true)
  const manuallyEditedPlatforms = useSelector(getManuallyEditedPlatforms);
  const { config } = useContext(AppContext);
  const [toggleEditTextInput, setToggleEditTextInput] = useState(false);
  const [timer, setTimer] = useState();
  const [limitedPostTextBody, setLimitedPostTextBody] = useState("");
  const [formattedPostBodyForLinks, setFormattedPostBodyForLinks] = useState("");
  const notifyPreviewsOfLiveMediaChanges = useSelector(getNotifyPreviewsOfLiveMediaChanges);
  const [platform] = useState(PlatformIdentifier.FACEBOOK)
  const platformVideoUploadLimit = useSelector(getPlatformVideoUploadLimit);
  const [textToDisplay, setTextToDisplay] = useState('');
  let typingTimeout;

  const limitChars = (text, limit) => {
    setLimitedPostTextBody(text?.substring(0, limit));
  };
  const waitTime = 1000;

  function runWhenUserStopTying() {
    window.clearTimeout(timer);
    setTimer(
      window.setTimeout(() => {
        if (!manuallyEditedPlatforms[PlatformIdentifier.YOUTUBE]) {
          limitChars(media_items_arrangement?.youtube?.text, 63206);
        }
      }, waitTime)
    );
  }

  useEffect(() => {
    runWhenUserStopTying();
  }, [media_items_arrangement?.youtube?.text]);

  useEffect(() => {
    limitChars(media_items_arrangement?.youtube?.text, 63206);
  }, []);

  useEffect(() => {
    const result = url_replacer(limitedPostTextBody ?? "");
    setFormattedPostBodyForLinks(result);
  }, [limitedPostTextBody]);

  useEffect(() => {
    setUseableMedia(media_items_arrangement?.youtube?.media)
  }, [notifyPreviewsOfLiveMediaChanges])

  useEffect(() => {
    if (googleDriveManager['fromPreviews'] && googleDriveManager['promptConnect']) {
      setOpenConfirmation(true)
    }
  }, [googleDriveManager])

  const dispatchEditedFlag = () => {
    if (!manuallyEditedPlatforms[PlatformIdentifier.YOUTUBE]) {
      const newData = { ...manuallyEditedPlatforms, youtube: true }
      dispatch(setManuallyEditedPlatforms(newData || {}));
    }
  }
  const loadVideo = (file) =>
    new Promise((resolve, reject) => {
      try {
        let video = document.createElement("video");
        video.preload = "metadata";
        video.onloadedmetadata = function () {
          resolve(this);
        };
        video.onerror = function () {
          reject("Invalid video. Please select a video file.");
        };
        video.src = window.URL.createObjectURL(file);
      } catch (e) {
        reject(e);
      }
    });

  const getVideoMetaData = async (file) => {
    const video = await loadVideo(file);
    const newTime = new Date(video?.duration * 1000)
      .toISOString()
      .substr(11, 8);
    setTimeFormat(newTime);
  };

  useEffect(() => {
    if (validYoutubeMedia.length) {
      getVideoMetaData(validYoutubeMedia[0].file);
    }
  }, [validYoutubeMedia]);

  const syncNewThread = async () => {
    let syncingThread = [];
    try {
      const mappedThread = validYoutubeMedia?.map((thread, index) => {
        const newThreadEntry = {
          name: thread?.name,
          url: thread?.url,
          is_video: thread?.is_video,
          external: thread?.external ?? false,
          uploaded: thread?.uploaded ?? false,
          file: thread?.file,
        };
        return newThreadEntry;
      });
      const oldData = { ...media_items_arrangement };
      const diapatchableData = { ...oldData, youtube: { ...oldData.youtube, media: mappedThread } }
      dispatch(setMediaArrangement(diapatchableData));
    } catch (error) { }
    return syncingThread;
  };

  useEffect(() => {
    if (!blockMediaItemsUpdate) {
      setBlockMediaItemsUpdate(true)
      syncNewThread();
    }
  }, [useableMedia, validYoutubeMedia]);


  const updateMediaItems = async (
    files,
    canCreateObjectURL = true,
    moreProps = {}
  ) => {
    if (
      files.length > mediaObjectsTotalLength ||
      setValidYoutubeMedia.length > mediaObjectsTotalLength
    ) {
      toast.error(
        `Sorry, you can only upload a maximum of ${mediaObjectsTotalLength} files at a time`
      );
      return;
    }

    let mo = [];
    for (let file of files) {
      if (canCreateObjectURL) {
        let fileUrl = URL.createObjectURL(file);
        let fileName = file.name;
        let existingFile = mo.find((x) => x.url === fileUrl);
        if (!existingFile) {
          let fileExtension = `.${fileName.split(".").pop()}`;
          let newThreadObject = {
            name: fileName,
            url: fileUrl,
            extension: fileExtension,
            file: file,
            ...moreProps,
          };

          if (platformVideoUploadLimit[platform] && file.size > platformVideoUploadLimit[platform]) {
            let errorMsg = `${file?.name} have been removed from ${platform} because it exceeds the allowed sized limit of ${FigureUtils.convertBytes(platformVideoUploadLimit[platform])?.value}.`
            if (platformVideoUploadLimit['canUpgrade']) {
              errorMsg = errorMsg + ` Upgrade your plan to get more upload limits.`;
            }
            toast.error(errorMsg, { theme: "colored" });
          } else {
            mo.push(newThreadObject);
          }
        }
      } else {
        let fileWithName = mo.find((item) => item.name === file.name);
        if (!fileWithName) {
          mo.push(file);
        }
      }
    }
    mo?.map((item) => {
      setBlockMediaItemsUpdate(false)
      setValidYoutubeMedia((oldArray) => [item]);
    });

    dispatchEditedFlag()
    setOpenMediaSelection(false);
    return;
  };

  const toggleMediaSelection = (mediaType) => {
    if (mediaType !== currentMediaType && openMediaSelction) {
      setCurrentMediaType(mediaType);
      dispatch(setSelectingPhotoFromHome(false));
      return;
    }
    setCurrentMediaType(mediaType);
    dispatch(setSelectingPhotoFromHome(false));
    openMediaSelction
      ? setOpenMediaSelection(false)
      : setOpenMediaSelection(true);
    return;
  };

  const handleDeleteMedia = (data) => {
    setBlockMediaItemsUpdate(false);
    dispatchEditedFlag()
    setValidYoutubeMedia([]);
  };

  const mediaOpener = (
    <div
      ref={myRef}
      style={{
        position: "relative",
        zIndex: 5,
        width: "96%",
        marginLeft: "4%",
        marginBottom: 20,
        marginTop: 20,
      }}
    >
      {
        <MultimediaSelectionDropdown
          specificPlatformTied={PlatformIdentifier.YOUTUBE}
          initiatorId={
            currentMediaType === "image" ? `${PlatformIdentifier.YOUTUBE}-photo-chooser` : `${PlatformIdentifier.YOUTUBE}-video-chooser`
          }
          fromPreview={true}
          externalFilePickerTitle={
            currentMediaType === "image"
              ? "Enter Photo Location"
              : "Enter Video Location"
          }
          prompt={
            currentMediaType === "image" ? "Add Photo From" : "Add Video From"
          }
          fileType={currentMediaType === "image" ? "image" : "video"}
          externalPickerPromptLabel={
            currentMediaType === "image" ? "Photo" : "Video"
          }
          multiple={currentMediaType === "image" ? true : false}
          fileSelectionHandler={(response) => {
            if (response) {
              let { files, from_local, ...others } = response;
              updateMediaItems(files, from_local, others);
            }
          }}
        />
      }
    </div>
  );

  const handleConnect = async () => {
    try {
      googleDriveRef.current.click();
    } catch (error) {

    }
  }

  const getOneVideo = () => {
    const newVideoArr = [];
    const newVideo = useableMedia?.filter(
      (media, index) => media.is_video === true
    );
    if (newVideo.length && newVideo[0]?.url !== validYoutubeMedia[0]?.url) {
      newVideoArr.push(newVideo[0]);
      setValidYoutubeMedia((prevState) => (prevState = newVideoArr));
      return;
    }
    if (newVideo.length && newVideo[0]?.url === validYoutubeMedia[0]?.url)
      return;

    if (!newVideo.length) return setValidYoutubeMedia([]);
  };

  useEffect(() => {
    getOneVideo();
  }, [useableMedia]);

  const displayMedia = validYoutubeMedia?.map((media, index) => {
    return (
      <div>
        {
          !viewOnly &&
          <ImageDelete editBgColor={!config.isDark ? "#e0e0e0" : "#0b1727"} onClick={(e) => handleDeleteMedia(media)}>
            <FontAwesomeIcon icon={faRemove} />
          </ImageDelete>
        }
        {youTubeVideoThumbnail ? (
          <img
            style={{ height: "100%", width: "100%", maxHeight: 300 }}
            src={youTubeVideoThumbnail.url}
            alt="dp"
          />
        ) : media?.uploaded ? <ReactPlayer
          controls
          muted
          config={{
            file: {
              attributes: {
                autoPlay: false
              }
            }
          }}
          url={media.url}
          width={'100%'}
          height={'100%'}
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%'
          }} /> : (
          <video
            src={media.url}
            style={{ height: "100%", width: "100%", maxHeight: 300 }}
          ></video>
        )}
      </div>
    );
  });

  const addMediaFromUrl = (obj = mediaAddedViaSpecificPlaformUrlMethod[0]) => {
    const { extension, thumbnail, ...rest } = obj
    setBlockMediaItemsUpdate(false);
    setUseableMedia([rest]);
    setValidYoutubeMedia((oldArray) => [rest])

    dispatchEditedFlag()
    dispatch(setSpecificMediaPlatformTied("global"));
    setOpenMediaSelection(false);
    if (googleDriveManager?.docs) {
      dispatch(setGoogleDriveManager({ ...googleDriveManager, docs: null }))
    }
  };

  const handleUpdateTextFromEditBox = (text) => {
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {
      if (!manuallyEditedPlatforms[PlatformIdentifier.YOUTUBE]) {
        const newData = { ...manuallyEditedPlatforms, youtube: true };
        dispatch(setManuallyEditedPlatforms(newData || {}));
      }
      const oldData = { ...media_items_arrangement };
      const getTextLimit = platformsWithLimits.find(
        (x) => x.identifier === PlatformIdentifier.YOUTUBE
      );
      const diapatchableData = {
        ...oldData,
        youtube: {
          ...oldData.youtube,
          text: text.substring(0, getTextLimit.textLimit),
        },
      };
      dispatch(setMediaArrangement(diapatchableData));
    }, 1000);
  };


  const immediateActionWhenClieked = (remove) => {
    if (remove) {
      localStorage.removeItem("gdrivePlatform");
      return;
    }
    localStorage.setItem("gdrivePlatform", PlatformIdentifier.YOUTUBE);
  }

  const handleEditClick = () => {
    setToggleEditTextInput(true);
  };

  useEffect(() => {
    if (!googleDriveManager?.docs || localStorage.getItem('gdrivePlatform') !== PlatformIdentifier.YOUTUBE) return;
    addMediaFromUrl(googleDriveManager?.docs);
    immediateActionWhenClieked(true)
  }, [googleDriveManager])

  useEffect(() => {
    specificMediaPlatformTied === "youtube" &&
      mediaAddedViaSpecificPlaformUrlMethod.length &&
      addMediaFromUrl();
  }, [mediaAddedViaSpecificPlaformUrlMethod]);

  return validYoutubeMedia.length ? (
    <>
      {
        !viewOnly &&
        <Flex
          gap={3}
          className={`mt-2`}
          wrap={'wrap'}
        >
          <MultimediaSelectionDropdown
            dropDownAnchor={
              <CircularButton title="Add Video">
                <FontAwesomeIcon icon={faVideo} />
              </CircularButton>
            }
            specificPlatformTied={PlatformIdentifier.YOUTUBE}
            fromPreview={true}
            initiatorId={`${PlatformIdentifier.YOUTUBE}-video-chooser`}
            externalFilePickerTitle={"Enter Video Location"}
            prompt={"Add Video From"}
            fileType={"video"}
            externalPickerPromptLabel={"Video"}
            multiple={false}
            fileSelectionHandler={(response) => {
              if (response) {
                let { files, from_local, ...others } = response;
                updateMediaItems(files, from_local, others);
              }
            }}
          />
          {!toggleEditTextInput && (
            <CircularButton
              editBgColor={!config.isDark ? "#e0e0e0" : "#333333"}
              onClick={() => handleEditClick()}
              title="Edit Text"
            >
              <FontAwesomeIcon icon={faPencil} />
            </CircularButton>
          )}
        </Flex>
      }
      <ParentSection>
        {
          googleDriveManager?.openPicker ? <GDriveDashboard platform={PlatformIdentifier.YOUTUBE} /> : null
        }
        <Wrapper>
          {toggleEditTextInput && (
            <EditTextBox
              ref={textEditRef}
              isPlatformEdited={PlatformIdentifier.YOUTUBE}
              incomingRef={textEditRef}
              value={limitedPostTextBody}
              setValue={setLimitedPostTextBody}
              setToggleEditTextInput={setToggleEditTextInput}
              platform={PlatformIdentifier.YOUTUBE}
              setter={{ ...manuallyEditedPlatforms, youtube: true }}
              updateText={handleUpdateTextFromEditBox}
            />
          )}
          {!viewOnly && openMediaSelction && !selectingPhotoFromHome && mediaOpener}
          <YTPreviewContentArea
            mainBgColor={!config.isDark ? "white" : "#0b1727"}
            style={{
              borderColor: `${!config.isDark ? "#ededed" : "transparent"
                }`,
            }}
            theme={!config.isDark ? "1px 1px 3px #949494" : "none"}
          >
            {!viewOnly && manuallyEditedPlatforms[PlatformIdentifier.YOUTUBE] && (
              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  fontStyle: "italic",
                  position: "absolute",
                  top: 0,
                  right: 5,
                  fontSize: 11,
                }}
              >
                <p style={{ color: !config.isDark ? "grey" : "grey" }}>
                  Edited
                </p>
              </div>
            )}
            <YTPostImage>
              <YTTimeStamp>
                <p
                  type={"p"}
                  style={{
                    fontSize: 13,
                    color: "white",
                  }}
                >
                  {timeFormat}
                </p>
              </YTTimeStamp>
              <div>{displayMedia}</div>
            </YTPostImage>
            <YTFirstLayer>
              {displayPlatformIcon(PlatformIdentifier.YOUTUBE)}
              <YTInfo>
                <YTTitle
                  type={"p"}
                  style={{
                    color: `${!config.isDark ? "black" : "#b3b3b3"}`,
                    fontSize: 9
                  }}
                >
                  {youTubeVideoTitle && youTubeVideoTitle}
                </YTTitle>
                <p
                  type={"p"}
                  style={{
                    color: "grey",
                    fontSize: 9
                  }}
                >
                  {"YouTube Preview"}
                </p>
                <p
                  type={"p"}
                  style={{
                    color: "grey",
                    fontSize: 9
                  }}
                >
                  0 views
                  {""} - {moment(timeToPost, "SS").fromNow()}
                </p>
              </YTInfo>
            </YTFirstLayer>
            {/* <TextareaAutoSize
              id="compose-box"
              minRows={2}
              ref={textRef}
              as="textarea"
              placeholder={''}
              className={`shadow-none resize-none border-0 ${config.isDark ? "border-x" : ""
                } px-2 border-200`}
              onChange={(e) => {
                let value = e.target.value;
                handleUpdateTextFromEditBox(value);
              }}
              style={{
                width: "100%",
                outline: "none",
                whiteSpace: "pre-wrap",
                display: "inherit",
                background: config.isDark ? "#0b1727" : "#fff",
                color: "inherit",
                fontSize: 11,
              }}
              maxRows={20}
            /> */}
            <pre
              style={{
                color: `${!config.isDark ? "#050505" : "#E4E6EB"}`,
                padding: "0 5%",
                maxWidth: "100%",
                width: "100%",
                wordWrap: "break-word",
                whiteSpace: "pre-wrap",
                marginBottom: "20px",
              }}
            >
              {parse(formattedPostBodyForLinks)}
            </pre>
            {!viewOnly && openMediaSelction && !selectingPhotoFromHome && mediaOpener}
          </YTPreviewContentArea>
        </Wrapper>
        <ConfirmModal
          open={openConfirmation}
          title={"Connect Your Google Drive Account"}
          message={'Your google drive account is not connected or has been disconnected. Do you wish to connect?'}
          confirmText={"PROCEED"}
          cancelText={"CANCEL"}
          showDialoguePrompt
          onCancel={() => {
            setOpenConfirmation(false);
            dispatch(setGoogleDriveManager({
              ...googleDriveManager,
              promptConnect: false,
            }))
          }}
          onConfirm={() => {
            handleConnect();
            dispatch(setGoogleDriveManager({ ...googleDriveManager, promptConnect: false, fromPreviews: false }))
          }}
        />
        <GDriveConnectionRequestListener
          googleDriveRef={googleDriveRef}
        />
      </ParentSection>
    </>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: 'column',
        alignItems: "center",
        marginTop: "50px",
        color: `${!config.isDark ? darkTheme.workSpaceSettingsHeaderBar : lightTheme.workSpaceSettingsHeaderBar}`,
        height: "100px",
        width: "100%",
        position: "relative",
        columnGap: 18,
      }}
    >
      {
        !viewOnly &&
        <MultimediaSelectionDropdown
          dropDownAnchor={
            <CircularButton title="Add Video">
              <FontAwesomeIcon icon={faVideo} />
            </CircularButton>
          }
          specificPlatformTied={PlatformIdentifier.YOUTUBE}
          fromPreview={true}
          initiatorId={`${PlatformIdentifier.YOUTUBE}-video-chooser`}
          externalFilePickerTitle={"Enter Video Location"}
          prompt={"Add Video From"}
          fileType={"video"}
          externalPickerPromptLabel={"Video"}
          multiple={false}
          fileSelectionHandler={(response) => {
            if (response) {
              let { files, from_local, ...others } = response;
              updateMediaItems(files, from_local, others);
            }
          }}
        />
      }
      {
        googleDriveManager?.openPicker ? <GDriveDashboard platform={PlatformIdentifier.YOUTUBE} /> : null
      }

      <ConfirmModal
        open={openConfirmation}
        title={"Connect Your Google Drive Account"}
        message={'Your google drive account is not connected or has been disconnected. Do you wish to connect?'}
        confirmText={"PROCEED"}
        cancelText={"CANCEL"}
        showDialoguePrompt
        onCancel={() => {
          setOpenConfirmation(false);
          dispatch(setGoogleDriveManager({
            ...googleDriveManager,
            promptConnect: false,
          }))
        }}
        onConfirm={() => {
          handleConnect();
          dispatch(setGoogleDriveManager({ ...googleDriveManager, promptConnect: false, fromPreviews: false }))
        }}
      />
      <GDriveConnectionRequestListener
        googleDriveRef={googleDriveRef}
      />
      You need to have a video to preview youtube
      {!viewOnly && openMediaSelction && !selectingPhotoFromHome && mediaOpener}
    </div>
  );
};

export default YouTube;
