import React, {
    useImperativeHandle,
    useRef,
    useEffect,
    useState
} from 'react';
import {
    useSelector,
    useDispatch
} from 'react-redux';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditableSection from '../debounceable_edit_box';
import {
    getGMBCouponCode,
    getGMBRedeemURL,
    getGMBTermsAndConditions,
    setGMBCouponCode, setGMBRedeemURL,
    setGMBTermsAndConditions
} from '../../../../../redux/slices/postslice';
import EventPostPanel from './event_post_panel';
import Flex from 'components/common/Flex';
import Divider from 'components/common/Divider';

function OfferPostPanel(props, ref) {

    const {
        rowUpdateHandler,
        bulkContentMode = false,
        bulkContentRow = {}
    } = props;
    const dispatch = useDispatch();
    const couponCode = useSelector(getGMBCouponCode);
    const redeemURL = useSelector(getGMBRedeemURL);
    const termsAndConditions = useSelector(getGMBTermsAndConditions);
    const [bulkContentCouponCode, setBulkContentCouponCode] = useState(props.couponCode);
    const [bulkContentRedeemURL, setBulkContentRedeemURL] = useState(props.offerRedeemUrl);
    const [bulkContentTermsAndConditions, setBulkContentTermsAndConditions] = useState(props.termsAndConditions);
    const eventPanelRef = useRef();

    useEffect(() => {
        if (bulkContentMode) {
            setBulkContentCouponCode(props?.bulkContentRow?.google_my_business_offer_coupon_code);
            setBulkContentRedeemURL(props?.bulkContentRow?.google_my_business_offer_redeem_url);
            setBulkContentTermsAndConditions(props?.bulkContentRow?.google_my_business_offer_terms_and_conditions);
        }
    }, [props.bulkContentRow]);

    useImperativeHandle(ref, () => ({
        getErrorMessage: () => {
            let eventPanelError = eventPanelRef.current.getErrorMessage();
            if (eventPanelError) {
                return eventPanelError;
            }
            return null;
        }
    }));

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 10,
            }}>
            <EventPostPanel titleLabel={"Offer"} ref={eventPanelRef} />
            <EditableSection
                value={bulkContentMode ? bulkContentCouponCode : couponCode}
                title="Coupon Code"
                placeholder={"Enter optional coupon code for this offer"}
                onChangeHandler={value => {
                    if (bulkContentMode) {
                        setBulkContentCouponCode(value);
                        let updatedRow = { ...bulkContentRow }
                        if (!value) {
                            value = " ";
                        }
                        updatedRow.google_my_business_offer_coupon_code = value;
                        rowUpdateHandler(updatedRow);
                    } else {
                        dispatch(setGMBCouponCode(value));
                    }
                }}
            />
            <EditableSection
                value={bulkContentMode ? bulkContentRedeemURL : redeemURL}
                title="Coupon Redeem URL"
                placeholder={"Enter optional URL where coupon can be redeemed"}
                onChangeHandler={value => {
                    if (bulkContentMode) {
                        setBulkContentRedeemURL(value);
                        let updatedRow = { ...bulkContentRow }
                        if (!value) {
                            value = " ";
                        }
                        updatedRow.google_my_business_offer_redeem_url = value;
                        rowUpdateHandler(updatedRow);
                    } else {
                        dispatch(setGMBRedeemURL(value));
                    }
                }}
            />
            <EditableSection
                value={bulkContentMode ? bulkContentTermsAndConditions : termsAndConditions}
                title="Offer Terms & Conditions"
                placeholder={"Enter optional terms and conditions for this offer"}
                onChangeHandler={value => {
                    if (bulkContentMode) {
                        setBulkContentTermsAndConditions(value);
                        let updatedRow = { ...bulkContentRow }
                        if (!value) {
                            value = " ";
                        }
                        updatedRow.google_my_business_offer_terms_and_conditions = value;
                        rowUpdateHandler(updatedRow);
                    } else {
                        dispatch(setGMBTermsAndConditions(value));
                    }
                }}
            />
            {
                !bulkContentMode &&
                <Flex direction={'column'}>
                    <Divider />
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 5
                        }}>
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            style={{
                                width: 15,
                                height: 15,
                            }}
                        />
                        <span
                            style={{
                                fontSize: 12,
                            }}>Message above will be used as the offer details
                        </span>
                    </div>
                </Flex>
            }
        </div>
    );
}
export default React.forwardRef(OfferPostPanel);