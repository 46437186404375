import React, { useEffect, useState } from 'react';
import { TelegramBotLogoContainer } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
    getActiveWorkSpace,
    getTelegramBotLogo, setActiveWorkspace, setTelegramBotLogo
} from '../../../../redux/slices/workspaceslice';
import {
    canOpenExternalFilePickerDialog,
    getExternalFilePickerDialogOptions,
    getExternalMediaFiles,
    setOpenExternalFilePickerDialog
} from '../../../../redux/slices/postslice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faPen, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import MultimediaSourcesDropdown from 'components/common/MultimediaSelectionDropdown';
import ExternalFilePickerDialog from 'components/common/ExternalFilePickerDialog';
import SentAnimation from 'assets/animations/sent.json';
import Lottie from 'lottie-react';
import APIService from '../../../../http/api_service';
import usePubSub from '../../../../pubsub';
import { toast } from 'react-toastify';
import { Button, Form, Modal } from 'react-bootstrap';
import CircularButton from 'components/common/circularbutton';
import { simulateMouseClick } from 'utils/gen';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'constants';
import { setLoggedInUser } from 'redux/slices/user_slice';

export default function CustomizeBot(props) {

    const workSpace = useSelector(getActiveWorkSpace);
    const navigate = useNavigate();
    const { modalClose, feedBackHandler, platform, updatableBot = {} } = props;
    const dispatch = useDispatch();
    const [selectedLogo, setSelectedLogo] = useState();
    const telegramBotLogo = useSelector(getTelegramBotLogo);
    const openExternalFilePickerDialog = useSelector(canOpenExternalFilePickerDialog);
    const externalFilePickerDialogOptions = useSelector(getExternalFilePickerDialogOptions);
    const pickedFiles = useSelector(getExternalMediaFiles);
    const [botName, setBotName] = useState(updatableBot.name);
    const [aboutBot, setAboutBot] = useState(updatableBot.about);
    const [botDescription, setBotDescription] = useState(updatableBot.description);
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const { addPubSubEventListener, removePubSubEventListener } = usePubSub();

    useEffect(() => {
        if (pickedFiles) {
            if (pickedFiles.length > 0) {
                dispatch(setTelegramBotLogo(pickedFiles[0].url));
                setSelectedLogo(pickedFiles[0]);
            }
        }
    }, [pickedFiles]);

    useEffect(() => {
        let requestStatusEventListener = (response) => {
            let { error } = response;
            if (error) {
                console.log("🚀 ~ requestStatusEventListener ~ error:", error)
                // toast.error(error, {
                //     theme: 'colored'
                // });
                setSubmitting(false);
            } else {
                setSubmitting(false);
                setSubmitted(true);
                if (feedBackHandler) {
                    feedBackHandler(true);
                    setTimeout(() => {
                        modalClose();
                        navigate('/');
                    }, 3000);
                }
            }
        };
        addPubSubEventListener(`${workSpace._id}_bot_customization_status`, requestStatusEventListener);
        return () => {
            removePubSubEventListener(`${workSpace._id}_bot_customization_status`, requestStatusEventListener);
        }
    }, []);

    const submitRequest = () => {
        setSubmitting(true);
        let requestBody = new FormData();
        let data = {
            name: botName,
            description: botDescription,
            targetPlatformUserIds: [platform.user_id],
            about: aboutBot
        };
        if (telegramBotLogo) {
            data['logo'] = telegramBotLogo;
        }
        if (updatableBot.token) {
            data['token'] = updatableBot.token;
        }
        requestBody.append('data', JSON.stringify(data));
        if (selectedLogo && selectedLogo.file) {
            requestBody.append(`file`, selectedLogo.file);
        }
        APIService.requestForCustomizedBot(workSpace._id, requestBody, (response, error) => {
            if (error) {
                console.log(error);
            }
        });
    }

    const LogoPreviewer = React.forwardRef((props, ref) => {
        if (!selectedLogo && !telegramBotLogo) {
            return '';
        }
        return (
            <img
                onClick={() => {
                    simulateMouseClick(document.getElementById('telegram-bot-logo-chooser'));
                }}
                src={selectedLogo ? selectedLogo.url : telegramBotLogo}
                alt={' '}
                style={{
                    objectFit: 'cover',
                    width: '99%',
                    height: '99%',
                    cursor: 'pointer',
                    position: 'absolute',
                    outline: 'none',
                    borderRadius: 10,
                    zIndex: !selectedLogo ? 0 : 100
                }}
            />
        );
    });

    const LogoSelector = React.forwardRef((props, ref) => {
        const { update = false } = props;
        return (
            <MultimediaSourcesDropdown
                dropDownAnchor={
                    <CircularButton
                        id={'telegram-bot-logo-chooser'}>
                        <FontAwesomeIcon
                            icon={update ? faPen : faPlusCircle}
                            style={{
                                width: update ? 15 : 40,
                                height: update ? 15 : 40
                            }}
                        />
                    </CircularButton>
                }
                initiatorId={'telegram-bot-logo-chooser'}
                externalFilePickerTitle={"Enter Logo Location"}
                prompt={"Add Logo From"}
                fileType={"image"}
                externalPickerPromptLabel="Logo"
                multiple={false}
                fileSelectionHandler={(response) => {
                    if (response) {
                        let { files, from_local, ...others } = response;
                        let file = files[0];
                        let canCreateObjectURL = from_local ?? true;
                        if (canCreateObjectURL) {
                            let fileUrl = URL.createObjectURL(file);
                            let fileName = file.name;
                            let fileExtension = `.${fileName.split('.').pop()}`;
                            let newMediaObject = {
                                name: fileName,
                                url: fileUrl,
                                extension: fileExtension,
                                file: file,
                                ...others
                            };
                            setSelectedLogo(newMediaObject);
                        } else {
                            setSelectedLogo(file);
                        }
                    }
                }}
            />
        );
    });

    const AddBotLogoPrompt = React.forwardRef((props, ref) => {
        return (
            <div
                style={{
                    position: 'absolute',
                    bottom: 15,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: 20
                }}>
                <LogoSelector />
                <span
                    style={{
                        fontSize: 13
                    }}>Pick a Logo for Your Bot*
                </span>
            </div>
        );
    });

    return (
        submitted ?
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 30,
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 10,
                    paddingBottom: 100,
                    paddingTop: 100,
                }}>
                <Lottie
                    animationData={SentAnimation}
                    loop={false}
                    style={{
                        height: 200
                    }}
                />
                <span
                    style={{
                        fontSize: 20,
                        paddingTop: -50,
                        textAlign: 'center'
                    }}>Your request has been received. Your bot will be {updatableBot.token ? 'updated' : 'ready'} within 24hrs
                </span>
            </div> :
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 30,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                <h5
                    type={'h5'}
                    style={{
                        fontSize: 22,
                        paddingBottom: 30,
                        paddingTop: 20,
                    }}>{updatableBot.token ? 'Update' : 'Configure'} Your Telegram Bot
                </h5>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        height: 200,
                        width: 200,
                    }}>
                    <TelegramBotLogoContainer
                        style={{
                            height: '100%',
                            width: '100%',
                        }}>
                    </TelegramBotLogoContainer>
                    <LogoPreviewer />
                    <AddBotLogoPrompt />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 20,
                        padding: 10,
                        marginTop: 20,
                        width: '100%'
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 5
                        }}>
                        <span>
                            {'Name*'}
                        </span>
                        <Form.Control
                            type='text'
                            value={botName}
                            placeholder={'What would you like to call your bot? E.g Finance Man'}
                            style={{
                                width: '100%',
                            }}
                            onChange={(e) => {
                                setBotName(e.target.value);
                            }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 5
                        }}>
                        <span>
                            {'About*'}
                        </span>
                        <Form.Control
                            type='text'
                            value={aboutBot}
                            placeholder={'What is your Bot about? E.g A Finance Manager'}

                            onChange={(e) => {
                                setAboutBot(e.target.value);
                            }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 5
                        }}>
                        <span>
                            {'Description*'}
                        </span>
                        <Form.Control
                            as="textarea"
                            placeHolderFontSize={15}
                            noPlaceholderAlignment={true}
                            value={botDescription}
                            placeholder={'Briefly describe your bot. E.g Not just another finance manager that helps you manage your finances...etc'}

                            onChange={(e) => {
                                setBotDescription(e.target.value);
                            }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 10
                        }}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        <span style={{
                            fontSize: 13,
                        }}>Upon submission, your bot will be provisioned in under 24hrs</span>
                    </div>
                    <Button
                        onClick={() => {
                            submitRequest();
                        }}
                        variant={'primary'}
                        isWorking={submitting}
                        disabled={submitting || !botName || !aboutBot || !botDescription || (!selectedLogo && !telegramBotLogo)}
                    >{submitting ? 'Please wait...' : 'Submit'}
                    </Button>
                </div>
                <Modal
                    open={openExternalFilePickerDialog}
                    onHide={() => {
                        dispatch(setOpenExternalFilePickerDialog(false));
                    }}
                >
                    <Modal.Body>
                        <ExternalFilePickerDialog
                            options={externalFilePickerDialogOptions}
                            modalClose={() => {
                                dispatch(setOpenExternalFilePickerDialog(false));
                            }}
                        />
                    </Modal.Body>
                </Modal>
            </div>
    );
}