import React, { useState } from 'react';
import LogoutContent from 'components/authentication/LogoutSuccessContent';
import LogoutPromptContent from '../LogOutPrompt';
import AuthCardLayout from 'layouts/AuthCardLayout';

const Logout = () => {

  const [loggedOut, setLoggedOut] = useState(false);

  return (
    <AuthCardLayout>
      <div className="text-center">
        {
          loggedOut ?
            <LogoutContent layout="card" titleTag="h3" /> :
            <LogoutPromptContent stateHandler={(result) => {
              setLoggedOut(result);
            }}
            />
        }
      </div>
    </AuthCardLayout>
  );

};

export default Logout;
