import InfoBar from "components/common/InfoBar";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";

export default function PostDeletePrompt(props) {

    const { promptIdentifier = "", prompt, canDeleteCallBack, cancelledCallback, igIncluded, showExtraInfoDialogue } = props;
    const [canShowDialogPromptAgain, setCanShowDialogPromptAgain] = useState(false);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
                padding: 50,
            }}>
            <h3>Delete Post?</h3>
            <span>{prompt}</span>
            {
                showExtraInfoDialogue &&
                <InfoBar info={'Please note that this process does not delete the post from Twitter, TikTok, and Instagram. You would have to delete the post from these platforms manually'} />
            }
            <Form.Check
                id={"delete-post_post-prompt_check"}
                type="checkbox"
                label={"Don't show this again?"}
                checked={canShowDialogPromptAgain}
                onChange={(value) => {
                    setCanShowDialogPromptAgain(value);
                }}
            />
            <div
                style={{
                    display: 'flex',
                    alignSelf: 'flex-end',
                    alignItems: 'center',
                    gap: 10
                }}>
                <Button
                    variant={'default'}
                    onClick={() => {
                        cancelledCallback();
                    }}>CANCEL
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        localStorage.setItem(`${promptIdentifier}can_auto_delete_post`, canShowDialogPromptAgain);
                        canDeleteCallBack();
                    }}>PROCEED
                </Button>
            </div>
        </div>
    );
}