import ConfirmModal from "components/common/ConfirmModal";
import IconButton from 'components/common/IconButton';
import Folders from "components/common/g-drive/folders";
import {
  DashboardContainer,
  Headings,
  RightNav
} from "components/common/g-drive/styles/dashboard";
import { RoutePaths } from "constants";
import useProtectedRoute from "hooks/useProtectedRoute";
import APIService from "http/api_service";
import React, { useEffect, useRef, useState } from 'react';
import {
  Button, Card, Col,
  Nav, Row, Tab
} from 'react-bootstrap';
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getGoogleDriveManager,
  getSelectedMediaOption,
  setDoneChecking,
  setGoogleDriveManager
} from "redux/slices/postslice";
import { getLoggedInUser, setLoggedInUser } from "redux/slices/user_slice";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import GoogleDriveIcon from "../../../../../assets/images/driveImg.png";
import GDriveConnectionRequestListener from "./GDriveConnectionRequestListener";

const GoogleDrivePage = (props) => {
  const { platform } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { folder_id } = useParams();
  const googleDriveManager = useSelector(getGoogleDriveManager);
  const currentUser = useSelector(getLoggedInUser);
  const [idCallStacks, setIdCallStacks] = useState([]);
  const [loading, setLoading] = useState(false);
  const workSpace = useSelector(getActiveWorkSpace);
  const workSpaceId = workSpace._id;
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const [currentView, setCurrentView] = useState("Grid View");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const googleDriveRef = useRef(null);
  const [processingButtonClickRequest, setProcessingButtonClickRequest] = useState(false)
  const [cameFromGDrivePicker, setCameFromGDrivePicker] = useState(false)
  const { status } = useProtectedRoute(['free'], `${RoutePaths.BILLING_STRIPE}/#schedulerSection001`)

  useEffect(() => {
    const value = localStorage.getItem("transportedFromGDrivePicker");
    if (value) {
      setCameFromGDrivePicker(true)
      localStorage.removeItem("transportedFromGDrivePicker")
    }
  }, [])


  useEffect(() => {
    if (!folder_id) {
      getGoogleDriveConnectionState(true, true);
    }
  }, [folder_id])

  const selectedMediaOption = useSelector(getSelectedMediaOption);

  const loggedInUser = useSelector(getLoggedInUser);

  const goBack = async () => {
    let q;
    let _idCallStacks = [...idCallStacks];
    _idCallStacks.pop();
    setIdCallStacks(_idCallStacks);
    if (_idCallStacks.length === 0) {
      q = `mimeType='application/vnd.google-apps.folder' and 'root' in parents`;
    } else {
      let lastId = _idCallStacks[_idCallStacks.length - 1];
      q = `'${lastId}' in parents and trashed=false`;
    }

    let requestBody = {
      google_drive_access_token: loggedInUser["google_drive_access_token"],
      google_drive_refresh_token: loggedInUser["google_drive_refresh_token"],
      google_drive_access_token_expires_at:
        loggedInUser["google_drive_access_token_expires_at"],
      q,
    };
    setLoading(true);
    APIService.getGoogleDriveMedias(
      workSpaceId,
      requestBody,
      (response, error) => {
        if (error) {
          toast.error(error, { theme: "colored" });
          setLoading(false);
          return;
        }
        dispatch(
          setGoogleDriveManager({
            ...googleDriveManager,
            folders: response?.data?.files,
            folderNextPageToken: response?.data?.nextPageToken,
          })
        );
        setLoading(false);
      }
    );
  };

  const getButtonMessage = () => {
    if (processingButtonClickRequest) {
      return "Processing...";
    }
    if (googleDriveManager?.connected) {
      return "Disconnect Google Drive";
    }
    return "Connect Google Drive";
  }

  const handleDisconnect = () => {
    setProcessingButtonClickRequest(true)
    APIService.disconnectGDrive(
      workSpaceId,
      { disconnect: true },
      (response, error) => {
        if (error) {
          toast.error(error, { theme: "colored" });
          setProcessingButtonClickRequest(false)
          return;
        }

        APIService.fetchLatestMe((latestMeResponse, latestMeError) => {
          if (latestMeError) {
            toast.success("Your google drive is now disconnected", {
              theme: "colored",
            });
            return;
          }
          let { data } = latestMeResponse;
          dispatch(setLoggedInUser({ ...data }));
          dispatch(
            setGoogleDriveManager({
              ...googleDriveManager,
              connected: false,
              folders: [],
            })
          );
          setProcessingButtonClickRequest(false)
          toast.success("Your google drive is now disconnected", {
            theme: "colored",
          });
        });
      }
    );
  };

  const handleOpenPicker = () => {
    let requestBody = {
      google_drive_access_token: currentUser["google_drive_access_token"],
      google_drive_refresh_token: currentUser["google_drive_refresh_token"],
      google_drive_access_token_expires_at:
        currentUser["google_drive_access_token_expires_at"],
      q: `mimeType='application/vnd.google-apps.folder' and 'root' in parents`,
    };
    APIService.getGoogleDriveMedias(
      workSpaceId,
      requestBody,
      (response, error) => {
        if (error) {
          toast.error(error, { theme: "colored" });
          dispatch(
            setGoogleDriveManager({
              ...googleDriveManager,
              folders: [],
              fetchLoading: false,
            })
          );

          return;
        }
        dispatch(
          setGoogleDriveManager({
            ...googleDriveManager,
            fileType: selectedMediaOption,
            folders: response?.data?.files,
            folderNextPageToken: response?.data?.nextPageToken,
            fetchLoading: false,
            connected: true, // remove if prove less impactful
          })
        );
      }
    );
  };

  const getNext50MinsDate = () => {
    var now = new Date();
    now.setMinutes(now.getMinutes() + 50);
    now = new Date(now);
    return now.toISOString();
  };

  const getNewAccessToken = async (
    requestBody,
    promptIfFailed,
    connectIfTrue
  ) => {
    APIService.getNewGDriveAccessToken(requestBody, (response, error) => {
      if (error) {
        if (error === "Token Expired") {
          if (promptIfFailed) {
            dispatch(
              setGoogleDriveManager({
                ...googleDriveManager,
                promptConnect: true,
                fromPreviews: true,
              })
            );
          }
          //   toast.error('Token Expired')
          dispatch(setDoneChecking(true));
          return;
        }
      }

      APIService.updateAccountDetails(
        {
          google_drive_access_token: response?.data?.access_token,
          google_drive_refresh_token: response?.data?.refresh_token,
          google_drive_access_token_expires_at: getNext50MinsDate(),
        },
        (response, error) => {
          if (error) {
            console.log(error);
            return;
          }
          APIService.fetchLatestMe((latestMeResponse, error) => {
            if (error) {
              toast.error(error, { theme: "colored" });
              return;
            }
            let latestUserData = latestMeResponse.data;
            dispatch(setLoggedInUser({ ...latestUserData }));
            dispatch(
              setGoogleDriveManager({ ...googleDriveManager, connected: true })
            );
            connectIfTrue && handleOpenPicker();
          });
        }
      );
    });
  };

  const getGoogleDriveConnectionState = async (promptIfFailed, connectIfTrue) => {
    //TODO: uncomment the below code and add toasts for the user to be aware of what happened.
    // if (isFreeWorkspace(workSpace)) {
    //   return;
    // }
    setProcessingButtonClickRequest(true)
    const tokenAvailable = currentUser["google_drive_access_token"] && currentUser["google_drive_refresh_token"];
    let tokenHasNotExpired = new Date() < new Date(currentUser["google_drive_access_token_expires_at"]);
    if (tokenAvailable && tokenHasNotExpired) {
      dispatch(
        setGoogleDriveManager({ ...googleDriveManager, connected: true })
      );
      connectIfTrue && handleOpenPicker();
      console.log("connected");
      return true;
    }

    if (tokenHasNotExpired && tokenAvailable) {
      let requestBody = {
        refresh_token: currentUser["google_drive_refresh_token"],
      };
      await getNewAccessToken(
        requestBody,
        promptIfFailed,
        connectIfTrue
      );
      return false;
    }
    dispatch(
      setGoogleDriveManager({ ...googleDriveManager, connected: false })
    );
    return false;
  }

  const handleConnect = async () => {
    setProcessingButtonClickRequest(true)
    try {
      googleDriveRef.current.click();
    } catch (error) {
      console.log(error, "as error");
    }
  };

  useEffect(() => {
    if (googleDriveManager?.connected && processingButtonClickRequest) {
      handleOpenPicker();
    }
    if (processingButtonClickRequest) {
      setProcessingButtonClickRequest(false)
    }
  }, [googleDriveManager])


  const switcher = (
    <Tab.Container
      id="google_drive_switcher"
      activeKey={currentView}
      onSelect={(e) => {
        setCurrentView(e);
      }}>
      <Row className={'px-0'} style={{ width: 'auto' }}>
        <Nav variant="pills" className="nav-pills-falcon m-0">
          <Nav.Item>
            <Nav.Link as={Button} size="sm" eventKey="Grid View">
              Grid View
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Button} size="sm" eventKey="List View">
              List View
            </Nav.Link>
          </Nav.Item>

        </Nav>
      </Row>
    </Tab.Container>
  )


  return (
    <>
      <Card
        style={{
          width: '100%',
          minHeight: '80vh',
        }}
      >

        <Card.Header>
          <Row className="flex-between-center">
            <Col md>
              <Headings>
                <img

                  src={GoogleDriveIcon}
                  style={{ width: 30, height: 30 }}
                  alt="Google Drive Icon From Postly"
                />
                Google Drive
              </Headings>
            </Col>
            <Col xs="auto">
              <RightNav>
                {switcher}
                <IconButton
                  variant="falcon-default"
                  style={{ fontSize: 14, whiteSpace: "nowrap", marginTop: -6 }}
                  className="ms-3"
                  icon="redo"
                  onClick={goBack}
                />
                <Button
                  className="btn-primary"
                  style={{ fontSize: 15, whiteSpace: "nowrap", marginTop: -6 }}
                  onClick={() => { googleDriveManager?.connected ? handleDisconnect() : handleConnect() }}
                >
                  {getButtonMessage()}
                </Button>
              </RightNav>
            </Col>
          </Row>
        </Card.Header>
        <hr />
        <DashboardContainer>
          {
            (googleDriveManager?.fetchLoading) ?
              <ReactLoading type={'bubbles'} color={'#0052cc'} /> :
              <Folders
                currentView={currentView}
                platform={platform}
                googleDriveManager={googleDriveManager}
                setIdCallStacks={setIdCallStacks}
                idCallStacks={idCallStacks}
                loading={loading}
                setLoading={setLoading}
                pickerChecker={true}
                cameFromGDrivePicker={cameFromGDrivePicker}
              />
          }
        </DashboardContainer>
      </Card>
      <ConfirmModal
        open={openUpgradeDialog}
        title={"Action requires Upgrade"}
        message={"Sorry, GDrive Download and Upload is not avaliable on free plan. Upgrade your plan to use this feature. Thank you!"}
        confirmText={"UPGRADE"}
        cancelText={"CANCEL"}
        onCancel={() => {
          setOpenUpgradeDialog(false);
        }}
        onConfirm={() => {
          setOpenUpgradeDialog(false);
          navigate(`${RoutePaths.BILLING_STRIPE}`);
        }}
      />

      <GDriveConnectionRequestListener
        googleDriveRef={googleDriveRef}
      />
      <ConfirmModal
        open={openConfirmation}
        title={"Connect Your Google Drive Account"}
        message={
          "Your google drive account is not connected or has been disconnected. Do you wish to connect?"
        }
        confirmText={"PROCEED"}
        cancelText={"CANCEL"}
        showDialoguePrompt
        onCancel={() => {
          setOpenConfirmation(false);
          dispatch(
            setGoogleDriveManager({
              ...googleDriveManager,
              promptConnect: false,
            })
          );
        }}
        onConfirm={() => {
          handleConnect();
          dispatch(
            setGoogleDriveManager({
              ...googleDriveManager,
              promptConnect: false,
              fromPreviews: false,
            })
          );
        }}
      />
    </>
  );
};

export default GoogleDrivePage;
