import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button, Card, Dropdown, ListGroup } from 'react-bootstrap';
import { isIterableArray } from 'helpers/utils';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Notification from 'components/notification/Notification';
import {
  getNotifications, getUnreadNotificationsCount,
  updateAllNotificationsCount,
  updateNotifications, updateUnreadNotificationsCount
} from 'redux/slices/workspaceslice';
import APIService from 'http/api_service';
import { useSelector, useDispatch } from 'react-redux';
import usePubSub from 'pubsub';
import { toast } from 'react-toastify';
import { getLoggedInUser } from 'redux/slices/user_slice';
import AppContext from 'context/Context';

const NotificationDropdown = () => {

  const { config: { isDark } } = useContext(AppContext);
  const { addPubSubEventListener, removePubSubEventListener } = usePubSub();
  const dispatch = useDispatch();
  const notificationsCount = useSelector(getUnreadNotificationsCount);
  const notifications = useSelector(getNotifications);
  const [newNotifications, setNewNotifications] = useState();
  const [earlierNotifications, setEarlierNotifications] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isAllRead, setIsAllRead] = useState(false);
  const loggedInUser = useSelector(getLoggedInUser);
  const [clearingNotifications, setClearingNotifications] = useState(false);
  const [notificationsEmptyMessage, setNotificationsEmptyMesage] = useState("You have no notifications");
  const [fetchingNotifications, setFetchingNotifications] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  const notificationsEventHandler = (notification) => {
    let existingNotification = notifications.find((x) => x._id === notification._id);
    if (!existingNotification) {
      let notificationsCopy = [...notifications];
      notificationsCopy.unshift(notification);
      dispatch(updateNotifications([...notificationsCopy]));
    }
  };

  const newNotificationsCriteria = (notification) => {
    return !notification.read;
  }

  useEffect(() => {
    setNewNotifications(notifications.filter(x => newNotificationsCriteria(x)));
    setEarlierNotifications(notifications.filter(x => !newNotificationsCriteria(x)));
    if (loggedInUser) {
      addPubSubEventListener(`${loggedInUser._id}-notification`, notificationsEventHandler);
    }
    return () => {
      if (loggedInUser) {
        removePubSubEventListener(`${loggedInUser._id}-notification`, notificationsEventHandler);
      }
    }
  }, [notifications]);

  const fetchUserNotifications = () => {
    setFetchingNotifications(true);
    setErrorMessage(null);
    APIService.fetchUserNotifications((response, error) => {
      setFetchingNotifications(false);
      if (error) {
        setErrorMessage(error);
        return;
      }
      setErrorMessage(null);
      if (response) {
        let { data } = response;
        if (data.length > 0) {
          let allNotifs = [...data];
          data = data.filter((notification) => !notification.read);
          if (data.length > 0) {
            dispatch(updateUnreadNotificationsCount(data.length));
            dispatch(updateAllNotificationsCount(allNotifs.length));
          } else {
            dispatch(updateUnreadNotificationsCount(0));
          }
          dispatch(updateNotifications([...allNotifs]));
        }
      }
    });
  }

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
  }, []);

  useEffect(() => {
    fetchUserNotifications();
  }, []);

  useEffect(() => {
    if (notifications.length > 0) {
      let data = notifications.filter((notification) => !notification.read);
      dispatch(updateUnreadNotificationsCount(data.length));
      dispatch(updateAllNotificationsCount(notifications.length));
    }
  }, [notifications]);

  const clearAllNotifications = () => {
    setClearingNotifications(true);
    APIService.clearAllNotifications((response, error) => {
      setClearingNotifications(false);
      if (error) {
        toast.error(error, { theme: 'colored' });
        return;
      }
      let { message } = response;
      setNotificationsEmptyMesage(message);
      dispatch(updateNotifications([...[]]));
      dispatch(updateAllNotificationsCount(0));
      dispatch(updateUnreadNotificationsCount(0));
      setIsAllRead(true);
    })
  }

  const notificationDisplayBell = () => {
    let _notificationsCount = notificationsCount || 0;
    if (_notificationsCount > 99) {
      _notificationsCount = `99+`
    }
    if (!_notificationsCount || _notificationsCount == 0) {
      return <FontAwesomeIcon
        icon="bell"
        style={{ width: 22, height: 22 }}
      />
    }
    return <div style={{ position: "relative" }}>
      <FontAwesomeIcon
        icon="bell"
        style={{ width: 22, height: 22 }}
      />
      <span
        style={{
          position: "absolute",
          top: "-8px",
          right: "-5px",
          backgroundColor: "red",
          color: "white",
          borderRadius: "50%",
          padding: _notificationsCount > 99 ? "2px 4px" : "2px 5px",
          fontSize: _notificationsCount > 99 ? "8px" : "10px",
        }}
      >
        {_notificationsCount ? _notificationsCount : null}
      </span>
    </div>
  }

  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={'span'}
        className={classNames('px-0', {
          'notification-indicator-primary light cursor-pointer': !isAllRead
        }, notificationsCount > 0 ? '' : '')}
      >
        <div className={isDark ? 'text-white' : 'text-700'}>
          {notificationDisplayBell()}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg">
        <Card
          className="dropdown-menu-notification dropdown-menu-end shadow-none"
          style={{ maxWidth: '20rem' }}
        >
          <FalconCardHeader
            className="card-header"
            title="Notifications"
            titleTag="h6"
            light={false}
            endEl={
              notifications.length > 0 ?
                <span
                  className="cursor-pointer text-primary card-link fw-normal"
                  onClick={() => {
                    clearAllNotifications();
                  }}
                >
                  {clearingNotifications ? "Clearing..." : " Mark all as read"}
                </span> : <div></div>
            }
          />
          <ListGroup
            variant="flush"
            className="fw-normal fs--1 scrollbar"
            style={{ maxHeight: '40rem' }}
          >
            {
              newNotifications?.length > 0 &&
              <div className="list-group-title">NEW</div>
            }
            {' '}
            {
              isIterableArray(newNotifications) &&
              newNotifications.map(notification => (
                <ListGroup.Item key={notification._id} onClick={handleToggle}>
                  <Notification handleToggle={handleToggle} notification={notification} flush />
                </ListGroup.Item>
              ))
            }
            {
              earlierNotifications?.length > 0 &&
              <div className="list-group-title">EARLIER</div>
            }
            {
              isIterableArray(earlierNotifications) &&
              earlierNotifications.map(notification => (
                <ListGroup.Item key={notification._id} onClick={handleToggle}>
                  <Notification handleToggle={handleToggle} notification={notification} flush />
                </ListGroup.Item>
              ))
            }
          </ListGroup>
          {
            !fetchingNotifications && notifications.length < 1 &&
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 150
            }}>
              <span>{notificationsEmptyMessage}
              </span>
            </div>
          }
          {
            errorMessage &&
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 100,
              gap: 10
            }}>
              <span variant={'light'}>{errorMessage}</span>
              <Button
                size='sm'
                variant={'empty'}
                onClick={() => {
                  setFetchingNotifications(true);
                }}>Try again
              </Button>
            </div>
          }
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDropdown;
