import styled, { keyframes } from "styled-components";

const fadeInAnimation = keyframes`
from {
  opacity: 0;
  margin-left:-10%;
}
to {
  opacity: 1;
  margin-left:0%;
}
`;
export const MainDragContainer = styled.section`
    padding:0;
    height:auto;
    width:100%;
    // background-color:red;
    display:grid;
    grid-template-columns:1fr 1fr 1fr;   
    gap:10px; 
`;
export const ImageContainer = styled.div`
    height:100px !important;
    width:100%;
    background-image:url(${(props) => props.bgUrl});
    background-position:center;
    background-size:cover;
    border-radius:5px;
    cursor:move;
`;
export const VideoContainer = styled.div`
    height:100px !important;
    width:100%;
    background-color:blue;
    background-image:url(${(props) => props.bgUrl});
    background-position:center;
    background-size:cover;
    border-radius:5px;
    cursor:move;
`;