import useOrganizationStore from './organization_store';

const allStores = [
    useOrganizationStore,
];

export const resetAllStores = () => {
    allStores.forEach(store => {
        return store.getState().reset();
    });
}
