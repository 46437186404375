import React, { useEffect, useState } from "react";
import StringUtils from '../../../utils/string';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { MetricsProps, RoutePaths } from "../../../constants";
import { toast } from 'react-toastify';
import APIService from '../../../http/api_service';
import jwt_decode from "jwt-decode";
import { useQuery } from '../../../utils/url';
import ResultsPreview from "./results_preview";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import ConfirmModal from "components/common/ConfirmModal";
import Flex from "components/common/Flex";
import FreePageHeader from "components/common/FreePageHeader";
import { useDispatch, useSelector } from 'react-redux';
import { getLoggedInUser, setLoggedInUser } from "redux/slices/user_slice";
import { getActiveWorkSpace, setActiveWorkspace } from "redux/slices/workspaceslice";
import { animateScroll as scroll } from 'react-scroll';
import { setPopupUpgradeAlert } from "redux/slices/alertSlice";
import { setCollectMetric } from "redux/slices/analytics_slice";


export const maxUsableCharacters = (workSpaceOwner) => {
    let maxChars = workSpaceOwner?.active_plan?.AI_Writer_Words ?? workSpaceOwner?.active_plan?.AI_Writer_Chars ?? 0;
    if (workSpaceOwner?.active_ai_writer_subscription) {
        return "Unlimited";
    }
    return maxChars;
}

export const usedAIWriterChars = (workSpaceOwner) => {
    let max = maxUsableCharacters(workSpaceOwner);
    let used = workSpaceOwner?.used_ai_writer_characters ?? 0;
    if (workSpaceOwner.ai_writer_privileges) {
        used = ~~(workSpaceOwner.ai_writer_privileges?.used_ai_writer_tokens ?? 0);
        let used = workSpaceOwner?.used_ai_writer_characters ?? 0;
    }
    if (max === "Unlimited") {
        return used;
    }
    if (used > max) {
        used = max; // cap the used characters to the maximum allowed
    }
    return used;
}

export default function AIArtTool(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const maximumAllowedDescriptionLength = 4000;
    const [tools] = useState(props.tools);
    const tagName = StringUtils.substringAfterLast(window.location.href, "=");
    const tag = tagName === window.location.href ? null : tagName;
    const toolIdentifier = StringUtils.substringBefore(StringUtils.substringAfterLast(window.location.href, "/"), "?");
    const tool = toolIdentifier === window.location.href ? null : toolIdentifier;
    const [referenceTool] = useState(tools.find(x => x.tool.toLowerCase() === tool.toLowerCase()) ?? { name: 'Undefined' });
    const [title] = useState("");
    const [description, setDescription] = useState("");
    const [generatingArt, setGeneratingArt] = useState(false);
    const workSpace = useSelector(getActiveWorkSpace);
    const loggedInUser = useSelector(getLoggedInUser);
    const [buttonText, setButtonText] = useState("Create Arts");
    const [sizes] = useState([
        "1024x1024",
        "1792x1024",
        "1024x1792"
    ]);
    const [styles] = useState([
        "vivid",
        "natural",
    ]);

    const [size, setSize] = useState("1024x1024");
    const [style, setStyle] = useState("vivid");
    const [copiesCount] = useState(
        [...[...Array(10).keys()].map(x => x + 1)]
    );
    const [copyCount, setCopyCount] = useState(1);
    const [referenceTag] = useState(() => {
        if (referenceTool) {
            if (tag && referenceTool.sub_items) {
                let retrievedTag = referenceTool.sub_items[parseInt(tag)] ?? { name: 'Undefined' };
                return retrievedTag;
            }
            return null;
        }
        return null
    });
    const [results, setResults] = useState([]);
    const [openBuyMoreCharactersDialog, setOpenBuyMoreCharactersDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [generatedCopiesTitle, setGeneratedCopiesTitle] = useState("Generated Arts");
    const query = useQuery();
    const [savedCopies, setSavedCopies] = useState([]);
    const purchasableAIWriterCharacters = 'Unlimited';
    const costOfPurchasableAIWriterCharacters = '$29/month';
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const [aiArtOnAiWriterDivider] = useState(5)
    const [activePlan, setActivePlan] = useState(loggedInUser.active_plan);
    const [useHdQuality, setUseHdQuality] = useState(false);

    const [softHint, setSoftHint] = useState('Art generation response time may vary depending on the number of outputs expected and the sizes. Please be patient while the AI Art tool is generating your arts.');

    const attachOccupationToWorkspace = (firstWorkspace) => {
        if (loggedInUser) {
            if (loggedInUser.occupation) {
                if (!firstWorkspace.title) {
                    firstWorkspace.title = loggedInUser.occupation;
                }
            }
        }
    }

    const getMostRecentCopies = () => {
        let ref = referenceTag ? referenceTag : referenceTool;
        let cachedString = localStorage.getItem(`ai_art_recent_copies_for_${ref.name}`);
        if (cachedString && cachedString != 'undefined') {
            return JSON.parse(cachedString);
        }
        return null;
    }

    const cacheResults = (results) => {
        let ref = referenceTag ? referenceTag : referenceTool;
        localStorage.setItem(`ai_art_recent_copies_for_${ref.name}`, JSON.stringify(results));
    }

    const fetchAllSavedArtCopies = () => {
        let ref = referenceTag ? referenceTag : referenceTool;
        APIService.fetchAllSavedArtCopies(workSpace._id, (response, error) => {
            if (response) {
                let justSaved = response.data;
                setSavedCopies(justSaved);
            }
        });
    }

    useEffect(() => {
        if (savedCopies.length > 0) {
            const _savedCopies = savedCopies.map(x => {
                return x
            })
            setResults(_savedCopies);
        } else {
            if (results.length > 0) {
                setResults(results.map(x => {
                    return {
                        ...x,
                        saved: false,
                    }
                }));
            }
        }
    }, [savedCopies]);

    const refreshWorkspace = () => {
        APIService.fetchWorkSpace(workSpace['_id'], (response, error) => {
            if (error) {
                return;
            }
            if (response) {
                let responseData = response['data'];
                let receivedWorkspace = responseData['workspace'];
                receivedWorkspace['role'] = responseData['role'];
                receivedWorkspace['value'] = receivedWorkspace['name'];
                receivedWorkspace['title'] = responseData['title'];
                receivedWorkspace['label'] = receivedWorkspace['name'];
                attachOccupationToWorkspace(receivedWorkspace);
                dispatch(setActiveWorkspace({ ...receivedWorkspace }));
            }
        });
    }

    useEffect(() => {
        if (workSpace) {
            refreshWorkspace();
        }
        let mostRecentCopies = getMostRecentCopies();
        if (mostRecentCopies) {
            setGeneratedCopiesTitle("Recent Arts");
            setResults(mostRecentCopies);
        }
        fetchAllSavedArtCopies();
    }, []);

    const scrollToTop = () => {
        scroll.scrollToTop({
          duration: 500, 
          smooth: 'easeInOutQuart'
        });
      };

    const generateArt = () => {
        let requestBody = {
            size: size,
            copies: parseInt(copyCount),
            prompt: description,
            style: style
        }
        if (useHdQuality) {
            requestBody['hd'] = true;
        }
        setGeneratingArt(true);
        scrollToTop();
        setButtonText("Generating Arts...");
        APIService.generateArt(workSpace._id, requestBody, (response, error) => {
            setGeneratingArt(false);
            refreshWorkspace();
            if (error) {
                setButtonText("Create Arts");
                if (error.toLowerCase().includes("quota")) {
                    setErrorMessage(`${error}. Would you like to subscribe to ${purchasableAIWriterCharacters.toLocaleString()} Characters at ${costOfPurchasableAIWriterCharacters}?`);
                    setOpenBuyMoreCharactersDialog(true);
                } else if (error.toLowerCase().includes('upgrade')) {
                    let metricInitializer = {
                        action: MetricsProps.AI_ART_TOOL,
                        detailedAction: `Upgrade notice: Generating AI Art`,
                        timestamp: new Date().toISOString(),
                        route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
                      }
                      dispatch(setCollectMetric(metricInitializer))
                    dispatch(setPopupUpgradeAlert(true))
                } else {
                    toast.error(error, { theme: 'colored' });
                }
                return;
            }
            document.getElementById("ai-writer-tool-header")?.scrollIntoView();
            let { data } = response;
            if (data?.length > 0) {
                setButtonText("Generate Again");
                setGeneratedCopiesTitle("Generated Arts");
                try {
                    cacheResults(data?.data);
                } catch (e) {

                }
                setResults(data);
            } else {
                setButtonText("Create Arts");
            }
        });
    }

    useEffect(() => {
        let meta = query.get("meta");
        if (meta) {
            try {
                let metaData = jwt_decode(meta);
                if (metaData) {
                    if (metaData.success_message) {
                        toast.success(metaData.success_message, { theme: 'colored' });
                    }
                }
            } catch (e) {
                //Malformed jwt
            }
        }
    }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

    const buyMoreAIWriterCharacters = () => {
        APIService.buyMoreAIWriterQuotas(false, workSpace._id, (response, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { data } = response;
            window.location = data;
        });
    }

    const refreshLoggedInUserData = () => {
        APIService.fetchLatestMe((latestMeResponse, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                if (error.toLowerCase().includes('Invalid credentials provided'.toLowerCase())) {
                    window.localStorage.clear();
                    setTimeout(() => {
                        navigate(RoutePaths.AUTHENTICATION);
                    }, 100);
                }
                return;
            }
            let latestUserData = latestMeResponse.data;
            setActivePlan(latestUserData.active_plan);
            dispatch(setLoggedInUser({ ...latestUserData }));
        });
    }

    useEffect(() => {
        refreshLoggedInUserData();
    }, []);

    return (
        <>
            <FreePageHeader
                titleTag="h5"
                className="mb-3">
                <div
                    id="ai-writer-tool-header"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 10
                    }}>
                    <FontAwesomeIcon
                        color={referenceTag ? referenceTag.color : referenceTool.color}
                        icon={referenceTag ? referenceTag.icon : referenceTool.icon}
                        style={{
                            width: 30,
                            height: 30
                        }}
                    />
                    <h5>
                        {referenceTag ? referenceTag.name : referenceTool.name}
                    </h5>
                    <div style={{ flex: 1 }}></div>
                    {
                        savedCopies.length > 0 &&
                        <Button
                            variant="outline-info"
                            onClick={() => {
                                setGeneratedCopiesTitle("Saved Arts");
                                setResults(savedCopies.map(x => {
                                    return {
                                        ...x,
                                        saved: true
                                    }
                                }));
                            }}
                        >{`View ${savedCopies.length.toLocaleString()} Saved ${savedCopies.length > 1 ? 'arts' : 'Art'}`}
                        </Button>
                    }
                </div>
            </FreePageHeader>
            <Row>
                <Col lg={6}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 25,
                            paddingTop: 2,
                            gap: 15
                        }}>
                        {/* <Card
                            style={{
                                width: '100%',
                                padding: 25,
                                borderRadius: 5
                            }}>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 10
                                }}>
                                <Form.Group>
                                    <Form.Label>{referenceTag ? referenceTag.navigationTitle : referenceTool.navigationTitle}{' '}{referenceTag ? referenceTag.optional : referenceTool.optional ? '(optional)' : ''}</Form.Label>
                                    <Form.Control as={'textarea'}
                                        type="setGeneratingArt"
                                        className="shadow-none rounded-0 resize-none px-card border-200"
                                        value={title}
                                        onChange={(e) => {
                                            let changedText = e.target.value;
                                            if (changedText.length <= maximumAllowedDescriptionLength) {
                                                setTitle(changedText);
                                            }
                                            if (buttonText !== "Create arts") {
                                                setButtonText("Create Arts");
                                            }
                                        }}
                                        rows={(referenceTag ? referenceTag.navigationDescription : referenceTool.navigationDescription) ? 1 : 8}
                                        placeholder={referenceTag ? referenceTag.navigationTitleHint : referenceTool.navigationTitleHint}
                                    />
                                </Form.Group>
                                {
                                    !(referenceTag ? referenceTag.navigationDescription : referenceTool.navigationDescription) &&
                                    <span
                                        style={{
                                            textAlign: 'end',
                                            color: '#009688',
                                            paddingTop: 5, fontSize: 13
                                        }}>{`${title.length}/${maximumAllowedDescriptionLength}`}
                                    </span>
                                }
                            </div>
                        </Card> */}
                        {
                            referenceTag ? referenceTag.navigationDescription : referenceTool.navigationDescription &&
                                <Card
                                    style={{
                                        width: '100%',
                                        padding: 25,
                                        borderRadius: 5,
                                    }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}>
                                        <Form.Group>
                                            <Form.Label>{referenceTag ? referenceTag.navigationDescription : referenceTool.navigationDescription}</Form.Label>
                                            <Form.Control as={'textarea'}
                                                value={description}
                                                className="shadow-none rounded-0 resize-none px-card border-200"
                                                onChange={(e) => {
                                                    let changedText = e.target.value;
                                                    if (changedText.length <= maximumAllowedDescriptionLength) {
                                                        setDescription(changedText);
                                                    }
                                                    if (buttonText !== "Create arts") {
                                                        setButtonText("Create Arts");
                                                    }
                                                }}
                                                rows={8}
                                                placeholder={referenceTag ? referenceTag.navigationDescriptionHint : referenceTool.navigationDescriptionHint}
                                            />
                                        </Form.Group>
                                        <span
                                            style={{
                                                textAlign: 'end',
                                                color: '#009688',
                                                paddingTop: 5, fontSize: 11
                                            }}>{`${description.length}/${maximumAllowedDescriptionLength}`}
                                        </span>
                                    </div>
                                </Card>
                        }
                        <Card
                            style={{
                                width: '100%',
                                padding: 25,
                                borderRadius: 5,
                            }}>
                            <Form.Group>
                                <Form.Label>Choose output size</Form.Label>
                                <Form.Select
                                    value={size}
                                    onChange={(e) => {
                                        setSize(e.target.value);
                                    }}>
                                    {
                                        sizes.map(x => {
                                            return <option value={x}>{x}</option>
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Card>
                        <Card
                            style={{
                                width: '100%',
                                borderRadius: 5,
                                padding: 25,
                            }}>
                            <Form.Group>
                                <Form.Label>Choose image style</Form.Label>
                                <Form.Select
                                    value={style}
                                    onChange={(e) => setStyle(e.target.value)}>
                                    {
                                        styles.map(x => <option value={x}>{x}</option>)
                                    }
                                </Form.Select>
                            </Form.Group>
                            <span
                                style={{
                                    // textAlign: 'end',
                                    // color: '#009688',
                                    paddingTop: 5, fontSize: 11
                                }}
                            >
                                Vivid causes hyper-real and dramatic images. Natural produce more natural, less hyper-real looking images.
                            </span>
                        </Card>
                        <Card
                            style={{
                                width: '100%',
                                borderRadius: 5,
                                padding: 25,
                            }}>
                            <Form.Group>
                                <Form.Label>
                                    Generate HD quality images?
                                </Form.Label>
                                <Form.Select
                                    value={useHdQuality}
                                    onChange={(e) => setUseHdQuality(e.target.value)}>
                                    <option value={false}>{'NO'}</option>
                                    <option value={true}>{'YES'}</option>
                                </Form.Select>
                            </Form.Group>
                        </Card>
                        <Button
                            variant={'primary'}
                            isWorking={generatingArt}
                            disabled={generatingArt || (!title && !description)}
                            style={{ width: '100%' }}
                            onClick={() => {
                                generateArt();
                            }}>{buttonText}
                        </Button>
                    </div>
                </Col>
                <Col lg={6}>
                    {
                        generatingArt && <>
                            <Flex alignContent={'center'} alignItems={'center'} justifyContent={'center'}>
                                <Flex alignContent={'center'} alignItems={'center'} justifyContent={'center'}>
                                    <Spinner animation="border" />
                                </Flex>
                            </Flex>
                            {softHint && <span style={{ marginLeft: 10 }}>{softHint}</span>}
                        </>
                    }
                    {
                        !generatingArt && results.length > 0 &&
                        <ResultsPreview
                            generatedCopiesTitle={generatedCopiesTitle}
                            fetchAllSavedArtCopies={fetchAllSavedArtCopies}
                            referenceTag={referenceTag}
                            referenceTool={referenceTool}
                            workSpace={workSpace}
                            results={results}
                            description={description}
                        />
                    }
                </Col>
            </Row>
            <ConfirmModal
                open={openBuyMoreCharactersDialog}
                title={"Increase your AI Writer Quota"}
                message={errorMessage}
                confirmText={"BUY"}
                cancelText={"CANCEL"}
                onCancel={() => {
                    setOpenBuyMoreCharactersDialog(false);
                }}
                onConfirm={() => {
                    setOpenBuyMoreCharactersDialog(false);
                    buyMoreAIWriterCharacters();
                }}
            />
            <ConfirmModal
                open={openUpgradeDialog}
                title={"Upgrade Account"}
                message={"This feature requires an account upgrade. Kindly upgrade to a premium account to continue"}
                confirmText={"UPGRADE"}
                cancelText={"CANCEL"}
                onCancel={() => {
                    setOpenUpgradeDialog(false);
                }}
                onConfirm={() => {
                    setOpenUpgradeDialog(false);
                    navigate(`${RoutePaths.ACCOUNT}?t=billing`)
                }}
            />
        </>
    );
}