import React, { useState, useEffect, useContext } from 'react';
import APIService from '../../../../http/api_service';
import { toast } from 'react-toastify';
import DataTable, { createTheme } from 'react-data-table-component';
import moment from 'moment';
import AppContext from 'context/Context';
import { Button, Card, Spinner } from 'react-bootstrap';

export default function InvoicePage(props) {

    const [transactionHistory, setTransactionHistory] = useState();
    const { config } = useContext(AppContext);

    useEffect(() => {
        if (config.isDark) {
            createTheme('solarized', {
                text: {
                    primary: '#9da9bb',
                },
                background: {
                    default: '',
                },
            }, 'light');
        }
    }, [config]);

    const transactionHistoryColumns = [
        {
            name: 'Transaction Date',
            selector: row => moment(row.created_at).format('DD-MMM-YYYY'),
            sortable: true,
        },
        {
            name: 'Name|Email',
            selector: row => row.customer_name ?? row.customer_email,
            sortable: true,
        },
        {
            name: 'Processed by',
            selector: row => row?.meta_data?.website ?? 'Postly',
            sortable: true,
        },
        {
            cell: (row) => {
                return (
                    <>
                        {row.invoice_pdf ?
                            <div style={{ display: 'flex', marginRight: 2 }}>
                                <div>
                                    <a
                                        href={row.invoice_pdf}
                                        target={'_blank'}
                                        rel={'noreferrer'}
                                    >
                                        <Button
                                            size='sm'
                                            variant={'primary'}
                                        >
                                            Download
                                        </Button>
                                    </a>
                                </div>
                            </div>
                            :
                            (" ")
                        }
                    </>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];

    const fetchUserInvoiceHistory = () => {
        APIService.fetchUserInvoiceHistory((response, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { data } = response;
            setTransactionHistory(data)
        })
    }

    useEffect(() => {
        fetchUserInvoiceHistory();
    }, []);

    const TransactionsTable = React.forwardRef((props, ref) => {
        return (
            <DataTable
                columns={transactionHistoryColumns}
                data={transactionHistory}
                theme={config.isDark ? "solarized" : null}
            />
        )
    });
    TransactionsTable.displayName = "Transactions Table";

    return (
        <div style={{
            display: 'flex',
            height: '100%',
            flexDirection: 'column'
        }}>
            {
                !transactionHistory &&
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '70vh',
                        width: '100%'
                    }}>
                    <Spinner animation={'border'} size={30} />
                </div>
            }
            {
                transactionHistory &&
                <Card
                    canhover={false}
                    style={{
                        width: '100%',
                        borderTopLeftRadius: 5,
                        borderTopRightRadius: 5
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                            borderTopRightRadius: 5,
                            borderTopLeftRadius: 5
                        }}>
                        <TransactionsTable />
                    </div>
                </Card>
            }
        </div>
    );
}
