import FalconCardHeader from 'components/common/FalconCardHeader';
import APIService from 'http/api_service';
import React, { useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DeleteAccountVerification from './DeleteAccountVerification';
import { toast } from 'react-toastify';

const DangerZone = () => {

  const [openDeleteAccountConsent, setOpenDeleteAccountConsent] = useState(false);
  const [deletingAccount, setDeletingAccount] = useState(false);

  const attemptAccountDeletion = async () => {
    setDeletingAccount(true);
    APIService.deleteAccount((response, error) => {
      if (error) {
        toast.error(error);
        return;
      }
      window.localStorage.clear();
      window.localStorage.setItem('follow_up_deletion', "https://airtable.com/shrIaIlN8saXJN6bC");
      window.location.href = window.location.origin;
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  }

  return (
    <Card>
      <FalconCardHeader title="Danger Zone" />
      <Card.Body className="bg-light">
        <h5 className="mb-0">Delete this account</h5>
        <p className="fs--1">
          Once you delete an account, there is no going back. Please be certain.
        </p>
        <Button
          onClick={() => {
            setOpenDeleteAccountConsent(true);
          }}
          disabled={deletingAccount}
          as={Link}
          to="#!"
          variant="falcon-danger"
          className="w-100">
          {deletingAccount ? "Deleting..." : " Delete Account"}
        </Button>
      </Card.Body>
      <Modal
        show={openDeleteAccountConsent}
        size={'lg'}
        centered
        onHide={() => setOpenDeleteAccountConsent(false)}
        onExit={() => setOpenDeleteAccountConsent(false)}>
        <Modal.Body>
          <DeleteAccountVerification
            modalClose={() => setOpenDeleteAccountConsent(false)}
            confirmationDoneCallback={async (del) => {
              if (del) {
                setOpenDeleteAccountConsent(false);
                attemptAccountDeletion();
              }
            }} />
        </Modal.Body>
      </Modal>
    </Card>
  );
};

export default DangerZone;
