import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import Background from 'components/common/Background';
import Flex from 'components/common/Flex';
import Section from 'components/common/Section';
// import { TermsAndPolicies } from '../constants';
import bgShape from 'assets/img/illustrations/bg-shape.png';
import shape1 from 'assets/img/illustrations/shape-1.png';
import halfCircle from 'assets/img/illustrations/half-circle.png';
import FullLogo from 'components/common/FullLogo';

const GeneralCardLayout = ({
    leftSideContent,
    children,
    footer = true }) => {
        const currentURL = window.location.href;

    return (
        <Section fluid className="py-0">
            <Row className="g-0 min-vh-100 flex-center">
                <Col lg={8} xxl={5} className="py-3 position-relative">
                    <img
                        className="bg-auth-circle-shape"
                        src={bgShape}
                        alt=""
                        width="250"
                    />
                    <img
                        className="bg-auth-circle-shape-2"
                        src={shape1}
                        alt=""
                        width="150"
                    />
                    <Card  className="overflow-hidden z-index-1">
                        <Card.Body className="p-0">
                            <Row className="h-100 g-0">
                                <Col
                                    // md={7}
                                    as={Flex}
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{width: "100px"}}
                                >
                                    <div className="p-4 p-md-5 flex-grow-1">{children}</div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Section>
    );
};
GeneralCardLayout.propTypes = {
    leftSideContent: PropTypes.node,
    children: PropTypes.node.isRequired,
    footer: PropTypes.bool
};
export default GeneralCardLayout;
