import styled, { keyframes } from "styled-components";

export const UploadButton = styled.button`
  width: fit-content;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  background-color: #2e7ce5;
  color: #fff;
  padding: 0px 10px 0px 10px;
  font-size: small;
  border: none;
`;
export const ActionButton = styled.button`
  width: fit-content;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  background-color: rgba(155, 100, 0, 0.1);
  color: #1a1918;
  padding: 0px 10px 0px 10px;
  font-size: small;
  font-weight: 500;
  border: none;
  cursor: pointer;
`;
export const DivideLine = styled.hr`
  width: 100%;
  border: 0.5px solid #bbb5ae;
`;

export const TestButton = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  color: #1a1918;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* background-color: rgba(155, 100, 0, 0.1); */
  border: 1px solid #1a1918;
  padding: 0.375rem 0.75rem;
  font-size: small;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover,
  &:focus {
    color: #212529;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &.disabled,
  &:disabled {
    opacity: 0.65;
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;

    &:hover,
    &:focus {
      color: #fff;
      background-color: #0069d9;
      border-color: #0062cc;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    }

    &.disabled,
    &:disabled {
      color: #fff;
      background-color: #007bff;
      border-color: #007bff;
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
      color: #fff;
      background-color: #0062cc;
      border-color: #005cbf;
    }
  }
`;
