import Divider from "components/common/Divider";
import Flex from "components/common/Flex";
import AppContext from "context/Context";
import { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import "./recurring_post_options_style.css";
import SpecificTimeAndExpirationView from "./SpecificTimeAndExpirationView";
import WhenToBeginRepeatInfoBar from "./WhenToBeginRepeatInfoBar";

const WeeklyRepeatOptionView = ({
    presetOptions,
    startFrom,
    commitSelectedRecurringSettings,
    commitStopTime,
    commitSpecificTime,
    ...others
}) => {

    const [selectedWeeklyOptions, setSelectedWeeklyOptions] = useState(presetOptions['weekdays_to_repeat'] ?? []);

    const { config } = useContext(AppContext);

    const weeklyPostOptions = [
        {
            label: 'Sunday',
            value: 'sunday'
        },
        {
            label: 'Monday',
            value: 'monday'
        },
        {
            label: 'Tuesday',
            value: 'tuesday'
        },
        {
            label: 'Wednesday',
            value: 'wednesday'
        },
        {
            label: 'Thursday',
            value: 'thursday'
        },
        {
            label: 'Friday',
            value: 'friday'
        },
        {
            label: 'Saturday',
            value: 'saturday'
        }
    ];

    useEffect(() => {
        if (selectedWeeklyOptions.length) {
            commitSelectedRecurringSettings({
                weekdays_to_repeat: selectedWeeklyOptions,
                specific_time_to_repeat: presetOptions?.specific_time_to_repeat ?? new Date(),
                stop_time: presetOptions?.stop_time ?? "never"
            })
        }
    }, [selectedWeeklyOptions]);

    return (
        <Flex direction={'column'}>
            <Col className={'mb-3'}>
                <Flex direction={'column'}>
                    <span
                        style={{
                            fontSize: 14
                        }}>Repeat Every
                    </span>
                    <MultiSelect
                        options={weeklyPostOptions}
                        value={selectedWeeklyOptions}
                        onChange={setSelectedWeeklyOptions}
                        labelledBy={"Select"}
                        className={config.isDark ? 'multiselectDark' : 'multiselect'}
                    />
                </Flex>
            </Col>
            <SpecificTimeAndExpirationView
                presetOptions={presetOptions}
                commitStopTime={commitStopTime}
                commitSpecificTime={commitSpecificTime}
            />
            {
                selectedWeeklyOptions &&
                <WhenToBeginRepeatInfoBar
                    startFrom={startFrom}
                    feedBackData={{
                        weekdays_to_repeat: selectedWeeklyOptions,
                        specific_time_to_repeat: presetOptions?.specific_time_to_repeat ?? new Date(),
                        stop_time: presetOptions?.stop_time ?? "never"
                    }}
                    {...others}
                />
            }
            <Divider />
        </Flex>
    )
}
export default WeeklyRepeatOptionView;