import styled, { keyframes } from "styled-components";

const fadeInAnimation = keyframes`
from {
  opacity: 0;
  margin-left:-10%;
}
to {
  opacity: 1;
  margin-left:0%;
}
`;

export const ParentSection = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  animation: ${fadeInAnimation} 0.3s linear;
  cursor: auto;
  justify-content: center;
  align-items: flex-start;
`;

export const Wrapper = styled.div`
  /* flex: 9; */
  /* height: fit-content; */
  width: 100%;
  position: relative;
`;



export const RedditPreviewContentArea = styled.div` 
/* border-style:solid;
  border-width:1px; */
  width: 100%;
  background-color: ${(props) => props.mainBgColor};
  /* z-index: 2; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-top: 10px;
  padding-top: 8px;
  /* margin-bottom: -60px; */
  animation: ${fadeInAnimation} 0.4s linear;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0 !important;
  }
  `;

export const RedditFirstLayer = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  column-gap: 10px;
`;

export const RedditImageHolder = styled.div`
  min-height: 30px;
  min-width: 30px;
  max-height: 30px;
  max-width: 30px;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
`;

export const RedditInfo = styled.div`
  height: auto;
  width: 100%;
  overflow: hidden;
  display: flex;
  font-size: 12px;
  column-gap: 10px;
  position: relative;
  @media only screen and (max-width: 768px) {
    font-size: 8px;
  }
`;

export const RedditDotsBox = styled.div`
  height: 70px;
  padding: 0 2%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const RedditSecondLayer = styled.div`
  height: auto;
  width: 100%;
  font-size: 18px;
  font-family: IBMPlexSans, Arial, sans-serif;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const RedditThirdLayer = styled.div`
  height: 60px;
  width: 80%;
  margin-top: -10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
`;

export const RedditEachBtn = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 5px;
  font-size: 13px;
  @media only screen and (max-width: 768px) {
    font-size: 9px;
  }
`;

export const RedditFourthLayer = styled.div`
  height: auto;
  width: 100%;
`;

export const RedditPostImage = styled.img`
  max-height: 500px;
  width: 100%;
  position: relative;
`;

export const RedditQuickText = styled.p`
    font-size:13px;
    color:'grey;
`;

export const PencilEdit = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
  
  :active {
    opacity:0;
  }
`;

export const VideoEdit = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
  
  :active {
    opacity:0;
  }
`;

export const ImageDelete = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  color: white;
  right: 5%;
  top: 0%;
  cursor: pointer;
  transition: transform 0.2s;
  
  :active {
    opacity:0;
  }
`;

export const TextEdit = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
  
  :active {
    opacity:0;
  }
`;

export const PostImage = styled.img`
  max-height: 500px;
  width: 100%;
`;

export const EditContainer = styled.div`
  min-height: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: center;
  gap:10px;
  border-radius: 8px;
  margin-top: 10px;
  flex: 1;
`;
