import React, { useState, useRef, useEffect } from "react";
import { Card } from "react-bootstrap";
import FileUploader from "components/app/file-uploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "../../constants";
import ReactPlayer from "react-player";
import VideoPlayer from "react-video-js-player";
import { Button } from "react-bootstrap";
import VideoEditorTimeline from "./video_editor_timeline";
// import './styles.css';

import { Logo } from './videoEditing.js'

// import VideoPlayer from 'react'
import {
  VideoEditorContainer,
  VideoEditorTools,
  VideoPlayerContainer,
  VideoEditorToolBox,
  VideoMediaTools,
  VideoEditorToolsContainer,
  MainContainer,
  VideoTimelineContainer,
} from "./videoEditorStyles";
import {
  faTimeline,
  faTextHeight,
  faTableColumns,
  faCut,
  faVectorSquare,
  faTimesCircle,
  faFileAudio,
  faFileVideo,
  faPlay,
  faPause,
} from "@fortawesome/free-solid-svg-icons";

import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { getLoggedInUser } from "redux/slices/user_slice";
import { useSelector, useDispatch } from 'react-redux';
import { isFreeUser } from "utils/user_utils";

const VideoEditor = ({
  audioFileUploader,
  addedMediaFile,
  addTextToVideo,
  addStickerToVideo,
  resizeVideo,
  addBackground,
  watermarkVideo,
  uploadedAudioFile,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedInUser = useSelector(getLoggedInUser);
  const [openEditArea, setOpenEditArea] = useState(false);
  const [videoToEditUrl, setVideoToEditUrl] = useState(null);
  const [mediaItem, setMediaItem] = useState(null);
  const [videoDuration, setVideoDuration] = useState(null);
  const [videoDurationInSecs, setVideoDurationInSecs] = useState(null);
  const [playMedia, setPlayMedia] = useState(false);
  const videoRef = useRef(null);
  const [videoData, setVideoData] = useState({
    name: "",
    size: "",
    type: "",
  });
  const [file, setFile] = useState(null);

  const textMediaTool = () => {
    setMediaItem(addTextToVideo);
  };
  const auidoMediaTool = () => {
    setMediaItem(audioFileUploader);
  };
  const addWaterMark = () => {
    setMediaItem(watermarkVideo);
  };
  const backgroundMediaTool = () => {
    let videoBackgroundContext = addBackground(videoData);
    setMediaItem(videoBackgroundContext);
  };
  const resizeMediaTool = () => {
    let videoResizeContext = resizeVideo(videoData);
    setMediaItem(videoResizeContext);
  };
  const stickerMediaTool = () => {
    setMediaItem(addStickerToVideo);
  };

  useEffect(() => {
    setTimeout(() => {
      console.log(videoRef.current);
      const duration = videoRef.current?.getDuration().toFixed(2);
      console.log(duration, "video duration");
      setVideoDuration(duration);
    }, 1000);
  }, []);

  const video = document.getElementById("video");

  const handlePlayMedia = () => {
    setPlayMedia(!playMedia);
  };

  // const handlePlayMedia = () => {
  //   if (video.pause()) {
  //     video.play();
  //     setPlayMedia(true);
  //   } else {
  //     video.pause();
  //     setPlayMedia(false);
  //   }
  // };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const currentTime = videoRef.current?.getCurrentTime().toFixed(2);
  //    console.log(currentTime, 'video current time')
  //     setVideoDurationInSecs(vidInSecs);
  //   }, 500);
  //   return () => clearInterval(interval);
  // }, [videoDurationInSecs]);

  const setTime = () => {
    const minutes = Math.floor(videoRef.current?.getCurrentTime() / 60);
    const seconds = Math.floor(
      videoRef.current?.getCurrentTime() - minutes * 60
    );

    // const minuteValue = minutes.toString().padStart(2, "0");
    const secondValue = seconds.toString().padStart(2, "0");

    const mediaTime = `${secondValue} / ${videoRef.current.getDuration()}`;
    console.log(mediaTime, "media time");

    setInterval(() => {
      setTime();
    }, 200);
  };

  const videoEditorToolBoxItem = [
    {
      title: "Text",
      icon: faTextHeight,
      func: textMediaTool,
    },
    {
      title: "Audio",
      icon: faFileAudio,
      func: auidoMediaTool,
    },
    {
      title: "WaterMark",
      icon: faFileVideo,
      func: addWaterMark,
    },
    {
      title: "Background",
      icon: faTableColumns,
      func: backgroundMediaTool,
    },
    {
      title: "Resize",
      icon: faVectorSquare,
      func: resizeMediaTool,
    },
    {
      title: "Trim",
      icon: faCut,
      func: stickerMediaTool,
    },
  ];

  const introArea = (
    <>
      <FileUploader
        hint={"Select or Drag a video here"}
        accept={"video/*"}
        responseType={"video"}
        onFileLoaded={(data) => {
          let file = URL.createObjectURL(data[0]);
          setVideoToEditUrl(file);
          setOpenEditArea(true);
          setFile(data);
          for (let { name, size, type } of data) {
            setVideoData((prevState) => {
              let newState = {
                ...prevState,
                name,
                size,
                type,
              };
              return newState;
            });
          }
        }}
      />
      {isFreeUser(loggedInUser) && (
        <>
          <hr />
          <p>
            To enjoy full features, upgrade to paid plan.{" "}
            <span
              style={{ fontWeight: "700", cursor: "pointer" }}
              onClick={() => navigate(`${RoutePaths.ACCOUNT}?t=billing`)}
            >
              Click Here
            </span>
          </p>
        </>
      )}
    </>
  );

  const closeVideoEditor = () => {
    setOpenEditArea(false);
  };

  const maintainanceDisplay = (
    <>
      <Card className="text-center">
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          Under maintenance
        </p>
        <hr />
        <p>
          Sorry, this page may not be accessible to you at the moment, please
          check back later.
        </p>
      </Card>
    </>
  );

  const videoEditor = (
    <>
      <MainContainer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
            borderBottom: "2px solid #121212",
            fontSize: "15px",
            color: "#ffffff",
          }}
        >
          <div style={{ cursor: "pointer" }}>LOGO</div>
          <div>PROJECT TITLE</div>
          <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <Button size="10" color="" style={{ padding: "5px" }}>
              EXPORT
            </Button>
            <div onClick={closeVideoEditor}>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ fontSize: "20px" }}
              />
            </div>
          </div>
        </div>
        <VideoEditorContainer>
          <VideoEditorToolsContainer>
            <VideoEditorTools>
              {videoEditorToolBoxItem.map(({ title, func, icon, ...rest }) => (
                <VideoEditorToolBox onClick={func}>
                  <FontAwesomeIcon icon={icon} fontSize="17px" />
                  <div style={{ fontSize: "" }}>{title}</div>
                </VideoEditorToolBox>
              ))}
            </VideoEditorTools>
            <VideoMediaTools>
              <p>{mediaItem}</p>
            </VideoMediaTools>
          </VideoEditorToolsContainer>
          <VideoPlayerContainer>
            <div style={{ height: "40vh", padding: "0.5em" }}>
              <ReactPlayer
                url={videoToEditUrl}
                ref={videoRef}
                id={"video"}
                controls
                config={{
                  file: {
                    attributes: {
                      autoPlay: false,
                    },
                  },
                }}
                width={"100%"}
                height={"100%"}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />

              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    color: "hsla(0,0%,100%,.2)",
                    fontSize: "14px",
                    fontWeight: "500",
                    margin: "0.5em 2rem",
                  }}
                >
                  <span>{videoDurationInSecs ?? "00:00 "}</span>
                  <span style={{ margin: "0 5px" }}>/</span>
                  <span>{videoDuration ?? "00:00"}</span>
                </div>
                <div onClick={handlePlayMedia}>
                  {playMedia ? (
                    <FontAwesomeIcon
                      icon={faPause}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faPlay}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </div>

                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  icon={faSquare}
                />
              </div> */}
            </div>

            <div
              style={{
                width: "100%",
                height: "40vh",
                padding: "10px",
                margin: "1rem 0",
                borderTop: "2px solid #121212",
              }}
            >
              <div>
                <div>video trimming</div>
                <div>timeline zoom</div>
              </div>
              <VideoEditorTimeline />
            </div>
            {/* <VideoPlayer
            controls={true}
            src={videoToEditUrl}
            ref={videoRef}
            poster={`${(
              <FontAwesomeIcon
                icon={faCirclePlay}
                style={{ fontSize: "30px" }}
              />
            )}`}
            width="720"
            height="420"
            onReady={this.onPlayerReady.bind(this)}
            onPlay={onVideoPlay}
            onPause={this.onVideoPause.bind(this)}
            onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
            onSeeking={this.onVideoSeeking.bind(this)}
            onSeeked={this.onVideoSeeked.bind(this)}
            onEnd={this.onVideoEnd.bind(this)}
          /> */}
          </VideoPlayerContainer>
        </VideoEditorContainer>
      </MainContainer>
    </>
  );
  return (
    <>
      <div id="disclaimer">
        <div id="optimized">
          <div id="emoji">🤔</div>
          <div id="opt-title">Motionity isn't optimized for mobile</div>
          <div id="opt-desc">You need to use a computer to be able to create animations with Motionity.</div>
          {/* <a href="https://twitter.com/alyssaxuu" target="_blank" id="opt-button">Other products by the maker</a> */}
        </div>
        <div id="disc-overlay"></div>
      </div>

      <audio controls id="audio-thing">
        <source src="assets/audio.wav" type="audio/wav" />
      </audio>
      <input type="file" id="filepick" accept="image/*,video/*,audio/*" multiple />
      <input type="file" id="filepick2" accept="audio/*" />

      <input type="file" id="filepick3" accept="application/json" />
      <input type="file" id="import" style={{ display: 'none' }} accept='.json' aria-hidden="true" />

      <div id="upload-popup">
        <div id="upload-popup-container">
          <div id="upload-popup-header">
            <div id="upload-popup-title">Upload media</div>
            <img id="upload-popup-close" src="assets/close.svg" alt="" />
          </div>
          <div id="upload-drop-area">
            <div id="upload-drop-group">
              <img src="assets/upload.svg" alt="" />
              <div id="upload-drop-title">Click to upload</div>
              <div id="upload-drop-subtitle">Or drag and drop a file</div>
            </div>
          </div>
          <div id="upload-link">
            <input id="upload-link-input" placeholder="Paste an image of video URL" />
            <div id="upload-link-add">Add</div>
          </div>
        </div>
        <div id="upload-overlay"></div>
      </div>

      <div id="download-modal">
        <p class="header">Download settings</p>
        <p class="subheader">Formats</p>
        <div id="radio">
          <input class="magic-radio" type="radio" name="radio" id="webm-format" value="webm" />
          <label for="webm-format">WEBM video <span>(fastest)</span></label>
          <input class="magic-radio" type="radio" name="radio" value="mp4" id="mp4-format" />
          <label for="mp4-format">MP4 video</label>
          <input class="magic-radio" type="radio" name="radio" value="gif" id="gif-format" />
          <label for="gif-format">Animated GIF</label>
          <input class="magic-radio" type="radio" name="radio" value="image" id="image-format" />
          <label for="image-format">Image</label>
        </div>
        <div id="download-real">Download</div>
      </div>

      <div id="import-export-modal">
        <p class="header">Import & export</p>
        <p class="subtitle">Save this project locally, or load an existing one.</p>
        <p class="header-2">Import a project</p>
        <div id="import-project"><img src="assets/import.svg" alt="" /> <span>Import</span></div>
        <p class="header-2">Export this project</p>
        <div id="export-project"><img src="assets/download-icon.svg" alt="" /> <span>Export</span></div>
      </div>

      <div id="background-overlay"></div>
      <div id="color-picker"></div>
      <div id="color-picker-fill"></div>

      <div id="toolbar" class="noselect">
        <Logo>
          <img src="assets/logo.svg" alt="logo here" style={{
            verticalAlign: "middle",
            width: "22px"
          }} />
        </Logo>
        <div id="tool-wrap">
          <div class="tool" id="upload-tool"><img src="assets/uploads.svg" alt="" /><p>Uploads</p></div>
          <div class="tool tool-active" id="shape-tool"><img src="assets/shape-active.svg" alt="" /><p>Objects</p></div>
          <div class="tool" id="image-tool"><img src="assets/image.svg" alt="" /><p>Images</p></div>
          <div class="tool" id="text-tool"><img src="assets/text.svg" alt="" /><p>Text</p></div>
          <div class="tool" id="video-tool"><img src="assets/video.svg" alt="" /><p>Videos</p></div>
          <div class="tool" id="audio-tool"><img src="assets/audio.svg" alt="" /><p>Audio</p></div>
          <div class="tool" id="more-tool"><img src="assets/more-hoz.svg" alt="" /><p>More</p></div>
        </div>
      </div>
      <div id="more-over">
        <div id="upload-lottie">
          <img src="assets/upload-grey.svg" alt="" /> Upload Lottie
        </div>
        <div id="clear-project">
          <img src="assets/clear.svg" alt="" /> Clear project
        </div>
      </div>
      <div id="behind-browser"></div>

      <div id="browser">
        <div id="browser-container">
          <div id="search-fixed">
            <p class="property-title">Objects</p>
            <img id="collapse" src="assets/collapse.svg" alt="" />
            <div id="browser-search">
              <input placeholder="Search..." />
              <img src="assets/search.svg" id="search-icon" alt="" />
              <img src="assets/delete.svg" id="delete-search" alt="" />
              <div id="search-button">Go</div>
            </div>
          </div>
          <div id="shapes-cont">
            <p class="row-title">Shapes</p>
            <div class="gallery-row" id="shapes-row">
            </div>
            <p class="row-title">Emojis</p>
            <div class="gallery-row" id="emojis-row">
            </div>
          </div>
        </div>
      </div>
      <div id="properties">
        <div id="properties-overlay"></div>
        <div id="align" class="align-off">
          <div id="align-v">
            <img class="align" id="align-top" src="assets/align-top.svg" title="Align to the top" alt="" />
            <img class="align" id="align-center-v" src="assets/align-center-v.svg" title="Align to the center" alt="" />
            <img class="align" id="align-bottom" src="assets/align-bottom.svg" title="Align to the bottom" alt="" />
          </div>
          <div id="align-h">
            <img class="align" id="align-left" src="assets/align-left.svg" title="Align to the left" alt="" />
            <img class="align" id="align-center-h" src="assets/align-center-h.svg" title="Align to the center" alt="" />
            <img class="align" id="align-right" src="assets/align-right.svg" title="Align to the right" alt="" />
          </div>
        </div>
        <hr />
        <div id="object-specific">
          <div id="canvas-properties" class="panel-section">
            <p class="property-title">Canvas settings</p>
            <table>
              <tr>
                <th class="name-col">Preset</th>
                <th class="value-col"><select id="preset"><option>Dribbble shot</option><option>Facebook post</option></select></th>
              </tr>
              <tr>
                <th class="name-col">Size</th>
                <th class="value-col"><div id="canvas-w" class="property-input" data-label='W'>
                  <input min={1} type="number" value={1000} />
                </div>
                  <div id="canvas-h" class="property-input" data-label='H'>
                    <input type="number" value={1000} min={1} />
                  </div>
                </th>
              </tr>
              <tr>
                <th class="name-col">Color</th>
                <th class="value-col">
                  <div id="canvas-color">
                    <div id="color-side" class="color-picker"></div>
                    <input value="#FFFFFF" disabled="disabled" />
                  </div>
                  <div id="canvas-color-opacity" class="property-input" data-label='%'>
                    <input type="number" value={100} /></div>
                </th>
              </tr>
              <tr>
                <th class="name-col">Duration</th>
                <th class="value-col" id="duration-cell">
                  <div id="canvas-duration" class="property-input" data-label='s'>
                    <input type="number" value={15.00} />
                  </div>
                </th>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div id="canvas-area">
        <div id="filters-parent">
          <div id="filters">
            <div id="filters-container">
              <div id="filters-header">
                <div id="filters-title">Filters</div>
                <img src="assets/close.svg" id="filters-close" alt="" />
              </div>
              <select id="filters-list">
                <option value="none">No filter</option>
                <option value="Invert">Invert</option>
                <option value="Sepia">Sepia</option>
                <option value="BlackWhite">Black & white</option>
                <option value="Brownie">Retro</option>
                <option value="Vintage">Vintage</option>
                <option value="Technicolor">Technicolor</option>
                <option value="Kodachrome">Kodachrome</option>
                <option value="Polaroid">Polaroid</option>
              </select>
              <hr />
              <div id="filters-title">Adjustments</div>
              <div id="reset-filters"><img src="assets/repeat.svg" alt="" /> Reset</div>
              <div class="filter-row">
                <th class="name-col">Brightness</th>
                <th class="value-col">
                  <div id="filter-brightness" class="select-filter"></div>
                </th>
              </div>
              <div class="filter-row">
                <th class="name-col">Contrast</th>
                <th class="value-col">
                  <div id="filter-contrast" class="select-filter"></div>
                </th>
              </div>
              <div class="filter-row">
                <th class="name-col">Saturation</th>
                <th class="value-col">
                  <div id="filter-saturation" class="select-filter"></div>
                </th>
              </div>
              <div class="filter-row">
                <th class="name-col">Vibrance</th>
                <th class="value-col">
                  <div id="filter-vibrance" class="select-filter"></div>
                </th>
              </div>
              <div class="filter-row">
                <th class="name-col">Hue</th>
                <th class="value-col">
                  <div id="filter-hue" class="select-filter"></div>
                </th>
              </div>
              <hr />
              <div id="filters-title">Chroma key</div>
              <div class="filter-row">
                <th class="name-col">Status</th>
                <th class="value-col">
                  <div id="status-toggle">
                    <div id="status-on" class="status-trigger">On</div>
                    <div id="status-off" class="status-trigger status-active">Off</div>
                  </div>
                </th>
              </div>
              <div class="filter-row" id="filter-color">
                <th class="name-col">Color</th>
                <th class="value-col">
                  <div id="chroma-color">
                    <div id="color-chroma-side" class="color-picker"></div>
                    <input value="#FFFFFF" disabled="disabled" />
                  </div>
                </th>
              </div>
              <div class="filter-row">
                <th class="name-col">Distance</th>
                <th class="value-col">
                  <div id="chroma-distance" class="select-filter"></div>
                </th>
              </div>
              <hr />
              <div id="filters-title">Stylize</div>
              <div class="filter-row">
                <th class="name-col">Noise</th>
                <th class="value-col">
                  <div id="filter-noise" class="select-filter"></div>
                </th>
              </div>
              <div class="filter-row" id="blur">
                <th class="name-col">Blur</th>
                <th class="value-col">
                  <div id="filter-blur" class="select-filter"></div>
                </th>
              </div>
            </div>
          </div>
        </div>
        <div id="top-canvas">
          <div id="undo"><img src="assets/undo.svg" alt="" /> Undo</div>
          <div id="redo"><img src="assets/undo.svg" alt="" /> Redo</div>
          <div id="other-controls">
            <div title="Hand tool (Space bar)" id="hand-tool">
              <img src="assets/hand-tool.svg" alt="" />
            </div>
            <div id="zoom-level" title="Canvas zoom level">
              <span>100%</span>
              <img src="assets/arrow.svg" alt="" />
            </div>
            <div id="zoom-options" class="zoom-hidden">
              <div class="zoom-options-item" data-zoom="in">Zoom in</div>
              <div class="zoom-options-item" data-zoom="out">Zoom out</div>
              <div class="zoom-options-item" data-zoom="50">Zoom to 50%</div>
              <div class="zoom-options-item" data-zoom="100">Zoom to 100%</div>
              <div class="zoom-options-item" data-zoom="200">Zoom to 200%</div>
            </div>
          </div>
        </div>
        {/* <div id="bottom-canvas">
          <a id="sponsor" href="https://github.com/sponsors/alyssaxuu" target="_blank">
            <img src="assets/sponsor.svg" /> Sponsor</a>
          <a id="alyssa-credit" href="https://twitter.com/alyssaxuu" target="_blank">Made by <span>Alyssa X</span>
            <img src="assets/alyssaimg.jpeg" />
          </a>
        </div> */}
        <img src="assets/replace-image.svg" id="replace-image" alt="" />
        <img src="assets/loading-image.svg" id="load-image" class="load-media" alt="" />
        <img src="assets/loading-video.svg" id="load-video" class="load-media" alt="" />
        <canvas id="canvas"></canvas>
      </div>

      <div id="timeline-handle"></div>
      <div id="bottom-area" class="noselect">
        <div id="keyframe-properties">
          <div id="easing">
            <p class="property-title">Keyframe easing</p>
            <select id="easing">
              <option value="linear">Linear</option>
              <option value="easeInQuad">Ease in</option>
              <option value="easeOutQuad">Ease out</option>
              <option value="easeinOutQuad">Ease in-out</option>
              <option value="easeOutInQuad">Ease out-in</option>
              <option value="easeInBounce">Ease in bounce</option>
              <option value="easeOutBounce">Ease out bounce</option>
              <option value="easeinOutBounce">Ease in-out bounce</option>
              <option value="easeOutInBouce">Ease out-in bounce</option>
              <option value="easeOutInBouce">Ease out-in bounce</option>
              <option value="easeInSine">Ease in sine</option>
              <option value="easeOutSine">Ease out sine</option>
              <option value="easeinOutSine">Ease in-out sine</option>
              <option value="easeOutInSine">Ease out-in sine</option>
              <option value="easeOutInSine">Ease out-in sine</option>
              <option value="easeInCubic">Ease in cubic</option>
              <option value="easeOutCubic">Ease out cubic</option>
              <option value="easeinOutCubic">Ease in-out cubic</option>
              <option value="easeOutInCubic">Ease out-in cubic</option>
              <option value="easeOutInCubic">Ease out-in cubic</option>
            </select>
          </div>
        </div>
        <div id="nothing"></div>
        <div id="layer-list">
          <div id="layerhead">LAYERS</div>
          <div id="layer-inner-list">
            <img src="assets/nolayers.svg" id="nolayers" alt="" />
          </div>
        </div>
        <div id="timearea">
          <div id="timeline">
            <div id="seekarea"><div id="inner-seekarea"><div id="seekevents"></div></div><div id="time-numbers" class="noselect"></div><div id="seek-hover"></div><div id="seekbar"></div></div>
            <div id="line-snap"></div>
            <div id="inner-timeline"></div>
          </div>
        </div>
      </div>

      <div style={{ display: 'none' }}>
        <canvas id="canvasrecord"></canvas>
      </div>
      <div id="controls" class="noselect">
        <img id="timeline-big" src="assets/timeline-big.svg" alt="" />
        <div id="timeline-zoom"></div>
        <img id="timeline-small" src="assets/timeline-small.svg" alt="" />
        <div id="speed">
          <div id="speed-settings">
            <div class="speed" data-speed="4">4.0x</div>
            <div class="speed" data-speed="3">3.0x</div>
            <div class="speed" data-speed="2">2.0x</div>
            <div class="speed" data-speed="1.5">1.5x</div>
            <div class="speed" data-speed="1">1.0x</div>
            <div class="speed" data-speed="0.5">0.5x</div>
          </div>
          <img src="assets/zap.svg" alt="" />
          <span>1.0x</span>
          <img id="speed-arrow" src="assets/arrow.svg" alt="" />
        </div>
        <div id="playback">
          <div id="current-time">
            <input value="00:00:00" readonly />
          </div>
          <img src="assets/skip.svg" id="skip-backward" alt="" />
          <img src="assets/play-button.svg" id="play-button" alt="" />
          <img src="assets/skip.svg" id="skip-forward" alt="" />
          <div id="total-time">
            <input value="00:00:00" readonly />
          </div>
        </div>
        <div id="controls-right">
          <div id="share"><img src="assets/importexport.svg" alt="" /> Import & export</div>
          <div id="download"><img src="assets/download-icon.svg" alt="" /> Download</div>
        </div>
      </div>

      <video id="test-video"></video>
      <input id="emptyInput" value=" " style={{ opacity: 0 }}></input>
    </>
  );
};

export default VideoEditor;
