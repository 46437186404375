import styled, { keyframes } from "styled-components";

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
    margin-left:10%;
  }
  to {
    opacity: 1;
    margin-left:50%;
  }
`;

export const ParentSection = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  animation: ${fadeInAnimation} 0.3s linear;
  cursor: auto;
  justify-content: center;
  align-items: flex-start;
`;

export const Wrapper = styled.div`
  /* flex: 9; */
  /* height: fit-content; */
  width: 100%;
  position: relative;
`;

export const YTPreviewContentArea = styled.div`
  /* border-style:solid;
  border-width:1px; */
  width: 100%;
  background-color: ${(props) => props.mainBgColor};
  /* z-index: 2; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-top: 10px;
  padding-top: 8px;
  /* margin-bottom: -60px; */
  animation: ${fadeInAnimation} 0.4s linear;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0 !important;
  }
`;

export const YTFirstLayer = styled.div`
  margin-top: 10px;
  height: auto;
  width: 100%;
  display: flex;
  column-gap: 10px;
`;

export const YTTitle = styled.div`
fontSize: 15px,
fontWeight: 700,
width: 80%,
@media only screen and (max-width: 768px) {
  fontSize: 13px,
}
`;

export const YTTimeStamp = styled.div`
  height: 15px;
  width: fit-content;
  padding: 0 3px;
  background-color: black;
  color: white;
  position: absolute;
  bottom: 15px;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

export const YTImageHolder = styled.div`
  min-height: 30px;
  min-width: 30px;
  max-height: 30px;
  max-width: 30px;
  border-radius: 100%;
  margin: 0 0 0 2%;
  overflow: hidden;
  position: relative;
`;

export const YTInfo = styled.div`
  height: auto;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 13px;
  @media only screen and (max-width: 768px) {
    fontsize: 10px;
  }
`;

export const YTPostImage = styled.div`
  max-height: 400px !important;
  width: 100%;
  position: relative;
`;

export const ImageDelete = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  color: white;
  right: 5%;
  top: -5%;
  cursor: pointer;
  transition: transform 0.2s;
  :active {
    opacity:0;
  }
`;

export const VideoEdit = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.editBgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
  
  :active {
    opacity:0;
  }
`;

export const EditContainer = styled.div`
  min-height: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: center;
  gap:10px;
  border-radius: 8px;
  margin-top: 10px;
  flex: 1;
`;
