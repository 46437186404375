import { CircularButtonBase } from './styles';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from 'context/Context';

const CircularButton = React.forwardRef((props, ref) => {
    const { disabled, noBgColor = false } = props;
    const [canHover, setCanHover] = useState(props.canHover ?? true);
    const { config } = useContext(AppContext);

    useEffect(() => {
        setCanHover(props.canHover ?? true);
    }, [props.canHover]);

    return (
        <CircularButtonBase
            color={!noBgColor ? (disabled ? (!config.isDark ? '#d1d8dd' : '#3d434d') : config.isDark ? '#0b1727' : '#ECEFF1') : null}
            canHover={!noBgColor ? (!disabled && canHover) : null}
            title={props.title}
            onClick={props.onClick}
            {...props} > {props.children}
        </CircularButtonBase >
    )

});

export default CircularButton