import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import APIService from "http/api_service";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDisplayTourGuide,
  getLoggedInUser,
  getShowTourGuide,
  setLoggedInUser,
  setShowTourGuide,
} from "redux/slices/user_slice";
import {
  AIService,
  addTeam,
  billingService,
  connectPlatforms,
  createContent,
  createPost,
  previewPost,
  selectChannel,
} from "./description";
import { RoutePaths } from "../../constants";
import "./tour.css";

const TourGuide = () => {
  const dispatch = useDispatch();
  const showTour = useSelector(getShowTourGuide);
  const tour_complete = useSelector(getDisplayTourGuide);
  const loggedInUser = useSelector(getLoggedInUser);
  const tourConfig = driver({
    popoverClass: "driverjs-theme",
    onDestroyed: () => saveTourProgress(),
    onCloseClick: () => saveTourProgress(),
    allowClose: true,
    showProgress: true,
    steps: [
      {
        element: "#billing",
        popover: { title: "Billing", description: billingService },
      },
      {
        element: "#platforms",
        popover: { title: "Platforms", description: connectPlatforms },
      },
      {
        element: "#team",
        popover: { title: "Team", description: addTeam },
      },
      {
        element: "#create-a-post",
        popover: { title: "Create post", description: createPost },
      },
      {
        element: "#postly-ai",
        popover: { title: "Postly AI", description: AIService },
      },
      {
        element: "#select-platform",
        popover: { title: "Select Channels", description: selectChannel },
      },
      {
        element: "#create-content",
        popover: { title: "Create your content", description: createContent },
      },
      {
        element: "#preview-post",
        popover: {
          title: "Preview and edit posts on specific platforms",
          description: previewPost,
        },
      },
    ],
  });

  useEffect(() => {
    if (showTour) {
      tourConfig.drive();
    }
  }, [showTour]);

  useEffect(() => {
    if (RoutePaths.NEW_WORKSPACE) { 
      return;
    }; 

    if (tour_complete) {
      return;
    };
    
    tourConfig.drive();
  }, [tour_complete]);

  const saveTourProgress = () => {
    if (showTour) {
      dispatch(setShowTourGuide(false));
      return;
    }
    if (!tour_complete) {
      APIService.updatePersonalInfo({ tour_complete: true }, (response, error) => {
          if (error) {
            return;
          }
          dispatch(setLoggedInUser({ ...loggedInUser, tour_complete: true }));
        }
      );
    }
    tourConfig.destroy();
  };

  return <></>;
};

export default TourGuide;
