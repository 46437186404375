import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import appLogo from 'assets/img/logos/postly_symbol_logo.png';
import AppContext from 'context/Context';

const Logo = ({
    at,
    width,
    height,
    fontSize = 22,
    className,
    textClass,
    clickable=true,
    logo = appLogo,
    name = 'postly',
    darkColor = '#509BFC',
    lightColor = '#060606',
    ...rest
}) => {

    const { config } = useContext(AppContext);

    return (
        <Link
            to="/"
            className={classNames(
                'text-decoration-none',
                { 'navbar-brand text-left': at === 'navbar-vertical' },
                { 'navbar-brand text-left': at === 'navbar-top' }
            )}
            {...rest}
        >
            <div
                className={classNames(
                    'd-flex',
                    {
                        'align-items-center py-3': at === 'navbar-vertical',
                        'align-items-center': at === 'navbar-top',
                        'flex-center fw-bolder fs-5': at === 'auth'
                    },
                    className
                )}
            >
                <img
                    className="me-1 mt-2"
                    src={logo}
                    alt="Logo"
                    width={width}
                    height={height}
                />
                <span
                    style={{
                        color: config.isDark ? darkColor : lightColor,
                        fontSize: fontSize,
                        textAlign: 'center'
                    }}
                    className={classNames('font-sans-serif', textClass)}>{name}
                </span>
            </div>
        </Link>
    );
}

Logo.propTypes = {
    at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
    width: PropTypes.number,
    className: PropTypes.string,
    textClass: PropTypes.string
};

Logo.defaultProps = { at: 'auth', width: 8 };

export default Logo;