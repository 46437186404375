import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlatformIdentifier } from 'constants';
import React from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSideNotificationPanel, getSideNotifications } from 'redux/slices/notification_slice';
import { getPostSpecificDataVisualRequest } from 'redux/slices/postslice';
import { displayPlatformIcon } from 'utils/platform_utils';
import { abolishSpecificPlatformPostVisuals, preparePostToEdit } from 'utils/post_utils';
import Blogger from './blogger';
import Facebook from './facebook';
import Google from './google';
import Instagram from './instagram';
import LinkedIn from './linkedIn';
import Pinterest from './pinterest';
import Reddit from './reddit';
import Telegram from './telegram';
import Threads from './threads';
import TikTok from './tikTok';
import Twitter from './twitter';
import Wordpress from './wordpress';
import YouTube from './youtube';
import { isEditingDisabled } from '../FeedDropdown';

const PostPreviewCanvas = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const notificationPanel = useSelector(getSideNotificationPanel);
    const sideNotifications = useSelector(getSideNotifications);
    const postSpecificDataVisualRequest = useSelector(getPostSpecificDataVisualRequest)

    const renderActivePreview = (identifier) => {
        switch (identifier) {
            case PlatformIdentifier.FACEBOOK:
                return <Facebook
                    viewOnly={true}
                />;
            case PlatformIdentifier.LINKED_IN:
                return <LinkedIn
                    viewOnly={true}
                />;
            case PlatformIdentifier.GOOGLE_MY_BUSINESS:
                return <Google
                    viewOnly={true}
                />;
            case PlatformIdentifier.TWITTER:
                return <Twitter
                    viewOnly={true}
                />;
            case PlatformIdentifier.THREADS:
                return <Threads
                    viewOnly={true}
                />;
            case PlatformIdentifier.REDDIT:
                return <Reddit
                    viewOnly={true}
                />;
            case PlatformIdentifier.INSTAGRAM:
                return <Instagram
                    viewOnly={true}
                />;
            case PlatformIdentifier.YOUTUBE:
                return <YouTube
                    viewOnly={true}
                />;
            case PlatformIdentifier.TIKTOK:
                return <TikTok
                    viewOnly={true}
                />;
            case PlatformIdentifier.TELEGRAM:
                return <Telegram
                    viewOnly={true}
                />;
            case PlatformIdentifier.PINTEREST:
                return <Pinterest
                    viewOnly={true}
                />;
            case PlatformIdentifier.WORDPRESS:
                return <Wordpress
                    viewOnly={true}
                />;
            case PlatformIdentifier.BLOGGER:
                return <Blogger
                    viewOnly={true}
                />;
            default:
                return null;
        }
    }

    if (!postSpecificDataVisualRequest?.platform) return null;

    return (
        <Offcanvas
            show={postSpecificDataVisualRequest ? true : false}
            onHide={() => {
                abolishSpecificPlatformPostVisuals();
            }}
            placement="end"
            style={{ maxWidth: '32rem' }}
            className="border-0"
        >
            <Offcanvas.Header
                closeButton
                closeVariant="white"
                className="bg-shape settings-panel-header"
            >
                <Offcanvas.Title as="div" className="py-1 z-index-1 light">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                        <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center"
                                style={{
                                    backgroundColor: '#fff',
                                    borderRadius: '50%',
                                    padding: '5px',
                                    marginRight: '5px'
                                }}
                            >
                                {displayPlatformIcon(postSpecificDataVisualRequest?.platform)}
                            </div>
                            <h5 className="text-white">
                                {" "} {postSpecificDataVisualRequest?.platformTemplate?.name}
                            </h5>
                        </div>
                        {
                            !isEditingDisabled(postSpecificDataVisualRequest?.post) &&
                            <Button
                                variant="primary"
                                size="sm"
                                className="rounded-pill mt-0 mb-0"
                                style={{ fontSize: '12px', marginLeft: 20 }}
                                onClick={() => {
                                    preparePostToEdit(postSpecificDataVisualRequest?.post);
                                }}
                            >
                                <FontAwesomeIcon
                                    icon="edit"
                                    style={{ fontSize: '10px' }}
                                    className="me-1"
                                />
                                Open in Editor
                            </Button>
                        }
                    </div>
                    <p className="mb-0 fs--1 text-white opacity-75">
                        Previewing for {postSpecificDataVisualRequest?.platformTemplate?.name}.
                    </p>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="scrollbar">
                {renderActivePreview(postSpecificDataVisualRequest?.platform)}
                <div className='h-25 mt-5' />
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default PostPreviewCanvas;
