import React, { useState } from "react";
import VideoEditor from "./video_editor_fullscreen";
import FileUploader from "components/app/file-uploader";
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-regular-svg-icons";

function VideoEditorFunctions() {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [mediaDimension, setMediaDimension] = useState(null);
  const [audioSwitchView, setAudioSwitchView] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  // const [audioFile, setAudioFile] = useState(null);

  // const ffmpeg = createFFmpeg({
  //   log: true,
  // });
  // load ffmpeg
  // const doTranscode = async () => {
  //   setMessage("Loading ffmpeg-core.js");
  //   await ffmpeg.load();
  //   setMessage("Start transcoding");
  //   ffmpeg.FS("writeFile", "test.avi", await fetchFile("/flame.avi"));
  //   await ffmpeg.run("-i", "test.avi", "test.mp4");
  //   setMessage("Complete transcoding");
  //   const data = ffmpeg.FS("readFile", "test.mp4");
  //   setVideoSrc(
  //     URL.createObjectURL(new Blob([data.buffer], { type: "video/mp4" }))
  //   );
  // };

  const videoBackgroundFunc = (data) => {
    setSelectedColor(data);
  };

  const videoBackgroundColors = [
    {
      name: "blue",
      code: "#0000FF",
      func: videoBackgroundFunc,
    },
    {
      name: "white",
      code: "#FFFFFF",
      func: videoBackgroundFunc,
    },
    {
      name: "red",
      code: "#FF0000",
      func: videoBackgroundFunc,
    },
    {
      name: "green",
      code: "#008000",
      func: videoBackgroundFunc,
    },
    {
      name: "Transparent",
      code: "#FFFFFF",
      func: videoBackgroundFunc,
    },
  ];

  const resizeFunc = (dimension) => {
    // portrait :- 9 : 16
    // landscape :- 16 : 9
    if (dimension === "Portrait") dimension = "9x16";
    if (dimension === "Landspace") dimension = "16x9";
    if (dimension === "Square") dimension = "1x1";

    console.log("Media Dimension:", mediaDimension);
  };

  // aspect ratio: 16:9, 4:3
  const videoAspectRatios = [
    { dimension: "4x3", icon: faSquare, func: resizeFunc },
    { dimension: "16x9", icon: faSquare, func: resizeFunc },
    { dimension: "Portrait", icon: faSquare, func: resizeFunc },
    { dimension: "Landspace", icon: faSquare, func: resizeFunc },
    { dimension: "Square", icon: faSquare, func: resizeFunc },
  ];

  const audioNavLink = [
    {
      id: 0,
      title: "Library",
      event_key: "event-1",
      active: true,
    },
    {
      id: 1,
      title: "Your Music",
      event_key: "event-2",
    },
  ];

  const switchAudioType = (key) => {
    switch (key) {
      case 0:
        return setAudioSwitchView(fetchMusicLib);
      case 1:
        return setAudioSwitchView(fetchLocalAudioFile);
      default:
        return;
    }
  };

  const fetchMusicLib = <div>hello world</div>;

  const fetchLocalAudioFile = (
    <div style={{ border: "3px dotted black", margin: "auto" }}>
      <FileUploader
        hint={"Select or Drag a audio here"}
        accept={"audio/*"}
        responseType={"audio"}
        onFileLoaded={(data) => {
          // let file = URL.createObjectURL(data[0]);
          setUploadedFile(data);
          console.log("Audio file: ", data);
        }}
      />
    </div>
  );

  const audioFileUpload = () => (
    <>
      <div>
        <h6 style={{ textAlign: "center" }}>Add music</h6>
      </div>
      <div className="mb-3">
        <Nav justify variant="tabs">
          {audioNavLink.map(({ id, title, event_key, active }) => (
            <Nav.Item key={id}>
              <Nav.Link
                // href="#"
                eventKey={event_key}
                onClick={() => {
                  switchAudioType(id);
                }}
              >
                {title}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        <div className="mt-3">{audioSwitchView}</div>
      </div>
      <div></div>
    </>
  );

  const textToVideo = (
    <>
      <div style={{ display: "relative" }}>
        <h6>Add Text</h6>
        <div style={{ display: "absolute" }}>
          {/* <DragAndDropTable> */}
          {/* <TextEditorBlock
            width={200}
            height={300}
            left={30}
            top={40}
            initialFontColor={"black"}
            initialText={"Text here"}
            parentStyle={{ width: 600, height: 800 }}
            unit={"px"}
          /> */}
          {/* </DragAndDropTable> */}
        </div>
      </div>
    </>
  );
  const waterMarkToVideo = () => (
    <>
      <div>
        <h6>Add WaterMark</h6>
      </div>
    </>
  );
  const addBackgroundToVideo = (videoData) => {
    return (
      <>
        <h6 className="mb-2">Add Background</h6>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {selectedColor && (
            <div
              className="mb-2"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                gap: "10px",
                backgroundColor: "rgba(0,0,0,0.2)",
                padding: "5px 10px",
                borderRadius: "30px",
                color: "white",
                fontWeight: "600",
              }}
            >
              <div
                style={{
                  backgroundColor: selectedColor,
                  height: "15px",
                  width: "15px",
                  borderRadius: "30px",
                }}
              ></div>
              {selectedColor}
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          {videoBackgroundColors?.map(
            ({ name, code, func, ...rest }, index) => {
              return (
                <div key={index} onClick={() => func(code)}>
                  <div
                    className="mx-3"
                    style={{
                      backgroundColor: code,
                      height: "25px",
                      width: "25px",
                      borderRadius: "50px",
                      boxShadow: "1px 3px 8px #000000",
                      cursor: "pointer",
                    }}
                  ></div>
                  {/* <h5
                    className="mt-3 text-capitalize"
                    style={{ fontSize: "15px", color: "black" }}
                  >
                    {name}
                  </h5> */}
                </div>
              );
            }
          )}
        </div>
      </>
    );
  };
  const resizeVideo = (videoData) => {
    setMediaDimension(videoData);
    return (
      <>
        <h6>Resize Video</h6>
        <div
          className="mx-3 mt-4"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "30px",
            cursor: "pointer",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {videoAspectRatios?.map(({ dimension, icon }) => (
            <>
              <div
                style={{ display: "flex", flexDirection: "column" }}
                onClick={() => resizeFunc(dimension)}
              >
                <FontAwesomeIcon
                  icon={icon}
                  style={{ fontSize: "30px", borderWidth: "1px" }}
                />
                <div className="mt-2" style={{ fontSize: "15px" }}>
                  {dimension.replace("x", ":")}
                </div>
              </div>
            </>
          ))}
        </div>
      </>
    );
  };

  const stickerToVideo = () => {
    <>
      <div>
        <h6>Add Sticker</h6>
      </div>
    </>;
  };

  return (
    <VideoEditor
      addedMediaFile={uploadedFile}
      addTextToVideo={textToVideo}
      resizeVideo={resizeVideo}
      addBackground={addBackgroundToVideo}
      watermarkVideo={waterMarkToVideo}
      audioFileUploader={audioFileUpload}
      addStickerToVideo={stickerToVideo}
      uploadedAudioFile={uploadedFile}
    />
  );
}

export default VideoEditorFunctions;
