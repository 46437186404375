/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import SoftBadge from "components/common/SoftBadge";
import Avatar from "components/common/Avatar";
import APIService from "http/api_service";
import { GoAlert } from "react-icons/go";
import { IoIosWarning } from "react-icons/io";
import { PlatformIdentifier } from "constants";
import { useSelector, useDispatch } from "react-redux";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { isFreeWorkspace } from "utils/workspace_utils";
import { canLoggedInUserBeDemo } from "utils/user_utils";
import { getLoggedInUser } from "redux/slices/user_slice";
import { FaRegCheckCircle } from "react-icons/fa";
import {
  getFeeds,
  getdraftCounter,
  getfailedCounter,
  gethasCalledFetchPosts,
  getpostsTypeCounter,
  getrecurringCounter,
  getscheduledCounter,
  setFeeds,
  setdraftCounter,
  setfailedCounter,
  sethasCalledFetchPosts,
  setpostsTypeCounter,
  setrecurringCounter,
  setscheduledCounter,
} from "redux/slices/postslice";
import { toast } from "react-toastify";
import { PiNumberOneFill } from "react-icons/pi";
import { Badge } from "react-bootstrap";
import { debounce } from "lodash";

const NavbarVerticalMenuItem = ({ route }) => {
  
  const workSpace = useSelector(getActiveWorkSpace);
  const [platformStatus, setPlatformStatus] = useState(null);
  // const [hasCalledFetchPosts, setHasCalledFetchPosts] = useState(false);
  const [platforms, setPlatforms] = useState();
  const loggedInUser = useSelector(getLoggedInUser);
  const postsTypeCounter = useSelector(getpostsTypeCounter);
  const draftCounter = useSelector(getdraftCounter);
  const failedCounter = useSelector(getfailedCounter);
  const recurringCounter = useSelector(getrecurringCounter);
  const scheduledCounter = useSelector(getscheduledCounter);
  const hasCalledFetchPosts = useSelector(gethasCalledFetchPosts);
  const isDemoAccount = canLoggedInUserBeDemo(loggedInUser);
  const dispatch = useDispatch();

  const getAllPlatformsInWorkspace = () => {
    if (!workSpace) {
      return;
    }
    APIService.fetchWorkSpacePlatforms(workSpace?._id, (response, error) => {
      if (error) {
        return;
      }
      if (response) {
        let platformsInWorkSpace = response["data"];
        if (platformsInWorkSpace) {
          platformsInWorkSpace = platformsInWorkSpace.filter((x) => x.user_id);
          setPlatforms(platformsInWorkSpace);
        }
      }
    });
  };

  const getConnectionExpiryTime = (
    staticDateToExpire,
    is_platform_connection_active,
    defaultExpireWindow
  ) => {
    if (!staticDateToExpire) {
      return false;
    }
    if (is_platform_connection_active) {
      return true;
    } else {
      return false;
    }
    // const today = new Date();
    // let staticExpireDate = (new Date() - new Date(staticDateToExpire));
    // staticExpireDate = staticExpireDate ? parseInt((staticExpireDate / (1000 * 60 * 60 * 24))) : 0;
    // if (defaultExpireWindow > staticExpireDate) {
    //   return true;
    // }
    // if (defaultExpireWindow < staticExpireDate) {
    //   return false
    // }
  };

  const checkForPlatformConnectionIssues = async () => {
    const _platforms = [...platforms];
    let foundIssue = false;
    for (let index = 0; index < _platforms.length; index++) {
      const element = _platforms[index];
      if (element?.identifier === PlatformIdentifier.LINKED_IN) {
        if (element?.last_possible_connection_date) {
          const isActive = getConnectionExpiryTime(
            element?.updated_at,
            element?.is_platform_connection_active,
            element?.defaultExpireWindow
          );
          if (!isActive) {
            foundIssue = true;
            setPlatformStatus("non-active");
            return;
          }
        }
      }
      if (element?.identifier === PlatformIdentifier.FACEBOOK) {
        if (element?.last_possible_connection_date) {
          const isActive = getConnectionExpiryTime(
            element?.updated_at,
            element?.is_platform_connection_active,
            element?.defaultExpireWindow
          );
          if (!isActive) {
            foundIssue = true;
            setPlatformStatus("non-active");
            return;
          }
        }
      }
      if (element?.identifier === PlatformIdentifier.TIKTOK) {
        if (element?.last_possible_connection_date) {
          const isActive = getConnectionExpiryTime(
            element?.updated_at,
            element?.is_platform_connection_active,
            element?.defaultExpireWindow
          );
          if (!isActive) {
            foundIssue = true;
            setPlatformStatus("non-active");
            return;
          }
        }
      }
      if (!foundIssue) {
        setPlatformStatus("active");
        return;
      }
    }

    if (!foundIssue) {
      setPlatformStatus("active");
      return;
    }
  };

  useEffect(() => {
    route?.name === "Platforms" && getAllPlatformsInWorkspace();
  }, []);

  useEffect(() => {
    if (platforms?.length) {
      checkForPlatformConnectionIssues();
    }
  }, [platforms]);

  return (
    <Flex alignItems="center" gap={1} style={{ position: "relative" }}>
      {route.icon && (
        <span className="nav-link-icon">
          <FontAwesomeIcon icon={route.icon} />
        </span>
      )}
      {!route.icon && !route.avatar && route.iconifyName && (
        <Avatar name={route.name} size={"s"} />
      )}
      {route.avatar && <Avatar src={route.avatar} size={"s"} />}
      <span className="nav-link-text ps-1" id={route.identifier}>
        {route.name}
      </span>
      <>
        {route?.name === "Manage drafts" && draftCounter !== null && (
          <SoftBadge>{draftCounter}</SoftBadge>
        )}
        {route?.name === "Pending approval" &&
          postsTypeCounter?.pending !== null && (
            <SoftBadge>{postsTypeCounter?.pending}</SoftBadge>
          )}
        {route?.name === "Scheduled posts" &&
          scheduledCounter !== null && (
            <SoftBadge>{scheduledCounter}</SoftBadge>
          )}
        {route?.name === "Recurring posts" && recurringCounter !== null && (
          <SoftBadge>{recurringCounter}</SoftBadge>
        )}
        {route?.name === "Published posts" &&
          postsTypeCounter?.posted !== null && (
            <SoftBadge>{postsTypeCounter?.posted}</SoftBadge>
          )}
        {route?.name === "Failed posts" && failedCounter !== null && (
          <SoftBadge>{failedCounter}</SoftBadge>
        )}
        {platformStatus === "active" && route?.name === "Platforms" && (
          <FaRegCheckCircle
            style={{ position: "absolute", right: 5 }}
            title="The platform connections look good."
            color="green"
          />
        )}
        {platformStatus === "non-active" && route?.name === "Platforms" && (
          <IoIosWarning
            style={{ position: "absolute", right: 5 }}
            title="One of your platform connections is having issue. "
            color="red"
          />
        )}
      </>
      {route.badge && (
        <SoftBadge pill bg={route.badge.type} className="ms-2">
          {route.badge.text}
        </SoftBadge>
      )}
      {route.premium && isFreeWorkspace(workSpace) && (
        <span className="nav-link-icon">
          <FontAwesomeIcon
            style={{
              marginLeft: 5,
            }}
            icon="crown"
            color="orange"
          />
        </span>
      )}
    </Flex>
  );
};

// prop-types
const routeShape = {
  active: PropTypes.bool,
  name: PropTypes.string.isRequired,
  to: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};
routeShape.children = PropTypes.arrayOf(PropTypes.shape(routeShape));
NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape(routeShape).isRequired,
};

export default React.memo(NavbarVerticalMenuItem);
