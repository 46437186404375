import React, { useState } from "react";
import { Alert, Button, Card, Form, Spinner, Table } from "react-bootstrap";
import Flex from "components/common/Flex";
import Divider from "components/common/Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faClose } from "@fortawesome/free-solid-svg-icons";
import IconAlert from "components/common/IconAlert";
import APIService from "http/api_service";
import ConfirmModal from "components/common/ConfirmModal";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { getDomains, setDomains } from "redux/slices/links_shortener_slice";
import { useDispatch, useSelector } from "react-redux";
import CopyLinkButton from "components/common/copylinkbutton";
import { copyToClipboard } from "utils/browser";
import CopyIcon from "../../../assets/svgs/CopyIcon.svg";
import useProtectedRoute from "hooks/useProtectedRoute";
import { RoutePaths } from "constants";
import DomainItem from "./DomainItem";
import { getColor } from "helpers/utils";
import CloudflareSSLImage from 'assets/images/cloudflare_ssl.jpeg'
import { NoteContainer } from "components/app/platforms/styles";

const CustomDomainSettingsPage = () => {
  const dispatch = useDispatch();

  //selectors
  const domains = useSelector(getDomains);

  const [customDomains, setCustomDomains] = useState(domains);
  const [newDomainName, setNewDomainName] = useState();
  const [addingNewDomain, setAddingNewDomain] = useState(false);
  const [openRemoveDomainPrompt, setOpenRemoveDomainPrompt] = useState(false);
  const [removableDomain, setRemovableDomain] = useState();
  const [openMakePrimary, setOpenMakePrimary] = useState();
  const [removingDomain, setRemovingDomain] = useState(false);
  const [verifiableDomain, setVerifiableDomain] = useState();
  const [onLoading, setOnloading] = useState(false);
  const [verificationNeeded, setVerificationNeeded] = useState(false); // [true, false, 'pending'
  const [responseFromDomainPreparation, setResponseFromDomainPreparation] =
    useState(); // [true, false, 'pending'
  const [domainVerificationError, setDomainVerificationError] = useState();
  const [error, setError] = useState();
  const { status } = useProtectedRoute(['free'], `${RoutePaths.BILLING_STRIPE}/#schedulerSection001`)


  const prepareCustomDomainForWorkspace = (domain) => {
    APIService.prepareCustomDomainForWorkspace(domain, (response, error) => {
      if (error) {
        console.log(error);
        setAddingNewDomain(false);
        return;
      }
      setAddingNewDomain(false);
      setRemovingDomain(false);
      const { data, message } = response;
      setResponseFromDomainPreparation(data.data);
      if (data.verificationNeeded) {
        setVerificationNeeded(true);
      } else {
        setVerificationNeeded(false);
      }
    });
  };

  const getAllDomain = async () => {
    setOnloading(true);
    APIService.fetchDomains((response, error) => {
      if (error) {
        console.log(error);
        setOnloading(false);
        toast.error(error, { theme: "colored" });
        return;
      }
      setOnloading(false);
      const { data, message } = response;
      if (!data) {
        if (message !== "No domain available") {
          toast.error(message, { theme: "colored" });
        }
        return;
      }
      dispatch(setDomains(data));
      setCustomDomains(data);
    });
  };

  const updateWorkspace = (customDomainsCopy) => {
    APIService.updateWorkSpaceCustomDomains(
      customDomainsCopy,
      (response, error) => {
        if (error) {
          console.log(error);
          setAddingNewDomain(false);
          return;
        }
        prepareCustomDomainForWorkspace(newDomainName);
      }
    );
  };

  const handleNewDomainAddition = (e) => {
    e.preventDefault();
    // toast.info(newDomainName);
    // return;
    if (!newDomainName) {
      return;
    }
    if (!newDomainName.includes(".")) {
      setError("Invalid domain entered");
      return;
    }
    if (newDomainName.includes("postly.link")) {
      setError("Oops! Not acceptable");
      return;
    }

    if (newDomainName.includes("postly.click")) {
      setError("Oops! Not acceptable");
      return;
    }
    setAddingNewDomain(true);
    // let customDomainsCopy = [...customDomains];
    // if (!customDomainsCopy.includes(newDomainName.toLowerCase())) {
    //     customDomainsCopy.push(newDomainName.toLowerCase());
    //     updateWorkspace(customDomainsCopy);
    // }
    let requestBody = {
      custom_domain: newDomainName,
    };

    APIService.addDomain(requestBody, (response, error) => {
      if (error) {
        console.log(error);
        toast.error(error, { theme: "colored" });
        setAddingNewDomain(false);
        return;
      }
      const { message } = response;
      toast.success(message);
      setAddingNewDomain(false);
      getAllDomain();
      setNewDomainName("");
    });
  };

  const removeDomain = async () => {
    setRemovingDomain(true);
    // remove domain with id: removableDomain
    APIService.removeDomainFromWorkSpace(
      { custom_domain_id: removableDomain?.result?.id },
      (response, error) => {
        if (error) {
          console.log(error);
          setRemovingDomain(false);
          return;
        }
        setRemovingDomain(false);
        const { message } = response;
        toast.success(message);
        getAllDomain();
      }
    );
  };

  const getDomainVerificationStatements = () => {
    const verifications = responseFromDomainPreparation?.verification;
    return verifications?.map((x) => {
      const type = x.type;
      if (type === "TXT") {
        return [
          `Go to your domain provider (where you have hosted or bought your domain from)`,
          `Go to your DNS settings`,
          `Add a ${type} record with the following details;`,
          `Domain: <b>${x.domain}</b>`,
          `value: <b>${x.value}</b>`,
          `reason: <b>${x.reason}</b>`,
          `Save the record`,
          `We will verify and activate your custom domain if all is properly set up.`,
        ].map((x) => <li dangerouslySetInnerHTML={{ __html: x }}></li>);
      }
      if (type === "CNAME") {
        return [
          `Go to your domain provider (where you have hosted or bought your domain from)`,
          `Go to your DNS settings`,
          `Add a ${type} record with the following details;`,
          `Host: <b>${x.host}</b>`,
          `Points to: <b>${x.pointsTo}</b>`,
          `Save the record`,
          `We will verify and activate your custom domain if all is properly set up.`,
        ].map((x) => <li dangerouslySetInnerHTML={{ __html: x }}></li>);
      }
      return [`We have no verification steps as of now.`].map((x) => (
        <li dangerouslySetInnerHTML={{ __html: x }}></li>
      ));
    });
  };

  useEffect(() => {
    domains === null && getAllDomain();
  }, []);

  // onLoading
  return (
    <div>
      {onLoading ? (
        <div
          style={{
            width: "100%",
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" />
        </div>
      ) : (
        <Flex direction={"column"} style={{ width: "100%" }}>
          <Card>
            <Card.Body>
              {/* <div className="d-flex justify-content-end">
                <Button type="button" onClick={getAllDomain}>Refresh</Button>
              </div> */}
              {/* <Divider /> */}
              <Flex direction={"column"} className={"gap-2"} style={{width: "100%"}}>
                <form
                  onSubmit={handleNewDomainAddition}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Flex
                    direction={"column"}
                    style={{
                      flex: 1,
                      width: "100%"
                    }}
                  >
                    {/* <Card.Title style={{ fontSize: "12px" }}>
                      Add you domain below
                    </Card.Title> */}
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "10px", width: "100%"}}>
                      {/* <input
                        disabled={addingNewDomain}
                        value={newDomainName}
                        onChange={(e) => {
                          let enteredValue = e.target.value;
                          setNewDomainName(enteredValue);
                          if (!enteredValue) {
                            setError(null);
                          }
                        }}
                        placeholder="www.example.com"
                        style={{
                          display: "block",
                          width: "100%",
                          borderRadius: 5,
                          padding: "10px",
                          // margin: "10px 0",
                          fontSize: "14px",
                          fontWeight: 400,
                          outline: "none",
                          backgroundColor: "inherit",
                          color: "inherit",
                        }}
                      /> */}
                      <Form.Group style={{width: "50%"}}>
                        <Form.Label>Add you domain below</Form.Label>
                        <Form.Control
                          // as=""
                          disabled={addingNewDomain}
                          // rows={1}
                          style={{
                            padding: '10px',
                            borderColor: error ? 'red' : '#ced4da',
                            width: "100%",
                          }}
                          placeholder="example.com"
                          value={newDomainName}
                          onChange={(e) => {
                            let enteredValue = e.target.value;
                            setNewDomainName(enteredValue);
                            if (!enteredValue) {
                              setError(null);
                            }
                          }}
                        />
                      </Form.Group>
                      {newDomainName && (
                        <Button type="submit" style={{ fontSize: "12px" }} disabled={addingNewDomain}>
                          {addingNewDomain ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            "Add Domain"
                          )}
                        </Button>
                      )}
                    </div>
                    {error && <span className="fs--1 py-3" style={{ color: "red" }}>
                      {error}
                    </span>}
                    <Alert variant="warning" className="p-2 mt-3">
                      <Flex>
                        <FontAwesomeIcon icon="exclamation-triangle" className="fs-2" />
                        <div className="ms-3 flex-1" style={{ width: "50%", fontSize: "14px" }}>
                          {/* <h4 className="alert-heading">Please note</h4> */}
                         <div> Note: Custom domain support is currently available only for Cloudflare-hosted sites; we're working to expand compatibility with additional hosts soon. </div>
                         {/* <div>Change the SSL/TLS settings for <strong>example.com</strong> to <strong>Full</strong> not <i>Full(Strict)</i></div> */}
                        </div>
                      </Flex>
                      {/* For now we only support CloudFlare domains. */}
                    </Alert>
                  </Flex>
                </form>

                {customDomains?.length < 1 && (
                  <>
                    <span>You have not set up any custom domains</span>
                    <Divider />
                  </>
                )}
                {customDomains?.length > 0 && (
                  <Table striped bordered hover variant="default">
                    <thead>
                      <tr>
                        <th>Domain</th>
                        <th>Name</th>
                        <th>DNS Record Type</th>
                        <th>Content</th>
                        {/* <th></th> */}
                        <th></th>
                      </tr>
                      {customDomains?.map((x, index) => {
                        return (
                          <DomainItem key={index} x={x} getAllDomain={getAllDomain} />
                        );
                      })}
                    </thead>
                    <tbody></tbody>
                  </Table>
                )}
              </Flex>
              {verificationNeeded && (
                <Flex direction={"column"} className={"gap-2"}>
                  <p>
                    <b>
                      To proceed into using{" "}
                      {responseFromDomainPreparation?.name}, follow the steps
                      below;
                    </b>
                  </p>
                  <ol>{getDomainVerificationStatements()}</ol>
                </Flex>
              )}
              {customDomains?.length > 0 && <Card bg='light' className='p-3'>
                <Card.Title>
                  <IconAlert
                    variant={"info"}
                    dismissible={false}
                    style={{ backgroundColor: "inherit" }}
                  >
                    <span>Next steps</span>
                  </IconAlert>
                </Card.Title>
                <Card.Body>
                  {/* <u><p>Setting Up Custom Domain: Quick Steps</p></u> */}
                  <ol>
                    <li>
                      Create a CNAME record to link <strong>{customDomains[customDomains?.length - 1]?.result?.hostname}</strong> to <strong>postly.click</strong>:
                      <ul>
                        <li>Type: <strong>CNAME</strong></li>
                        <li>Name: <strong>{customDomains[customDomains?.length - 1]?.result?.hostname}</strong></li>
                        <li>Target: <strong>postly.click</strong></li>
                      </ul>
                    </li>
                    {/* <li>
                      Change the SSL/TLS settings for <strong>example.com</strong> to <strong>Full</strong> (not <i>Full(Strict)</i>), as shown below:
                      <div style="width: 80%; height: auto; overflow: hidden;">
                        <img style="width: 100%; height: 300px; object-fit: contain;"
                          src="CloudflareSSLImage" alt="SSL/TLS settings" />
                      </div>
                    </li>  */}
                    <li>
                    Add the TXT Record generated above to your DNS settings:
                      <ul>
                        <li>Type: <strong>TXT</strong></li>
                        <li>Name: <i>(copy the generated <strong>Name</strong> value)</i></li>
                        <li>Content: <i>(copy the generated <strong>Content</strong> value)</i></li>
                      </ul>
                    </li>
                  </ol>

                </Card.Body>
              </Card>}
            </Card.Body>
          </Card >
        </Flex >
      )}
    </div >
  );
};

export default CustomDomainSettingsPage;
