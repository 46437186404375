import { getDisconnectedPlatforms, setDisconnectedPlatforms } from '../../../../redux/slices/platformSlice';
import { useSelector, useDispatch } from 'react-redux';
import { findPlatformByIdentifier } from '..';
import { RoutePaths } from '../../../../constants';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import Divider from 'components/common/Divider';
import { Card } from 'react-bootstrap';
import usePubSub from 'pubsub';
import { useEffect } from 'react';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';

export default function PlatformDisconnectedHeadsUp(props) {

    const dispatch = useDispatch();
    const workSpace = useSelector(getActiveWorkSpace);
    const disconnectedPlatforms = useSelector(getDisconnectedPlatforms);
    const { addPubSubEventListener, removePubSubEventListener } = usePubSub();

    useEffect(() => {
        let platformDisconnectionHandler = (incomingData) => {
            let disconnectedPlatformsCopy = disconnectedPlatforms ?? [];
            disconnectedPlatformsCopy = [...disconnectedPlatformsCopy];
            let existingItem = disconnectedPlatformsCopy.find(platform => platform.user_id === incomingData.user_id);
            if (!existingItem) {
                disconnectedPlatformsCopy.push(incomingData);
            }
            dispatch(setDisconnectedPlatforms(disconnectedPlatformsCopy));
        }
        if (workSpace) {
            addPubSubEventListener(`${workSpace._id}_platform_disconnected_status`, platformDisconnectionHandler);
        }
        return () => {
            if (workSpace) {
                removePubSubEventListener(`${workSpace._id}_platform_disconnected_status`, platformDisconnectionHandler);
            }
        }
    }, []);

    const generateErrorLines = () => {
        let lines = [];
        if (disconnectedPlatforms) {
            for (let disconnectedPlatform of disconnectedPlatforms) {
                let userDisplayName = disconnectedPlatform.user_name;
                let identifier = disconnectedPlatform.identifier;
                let retrievedPlatform = findPlatformByIdentifier(identifier);
                if (!retrievedPlatform) {
                    continue;
                }
                let message = `Kindly disconnect and reconnect your connected <b>${retrievedPlatform.name} (${userDisplayName})</b> account`;
                let route = `${RoutePaths.PLATFORMS}/${identifier}`;
                lines.push({
                    message,
                    route,
                    retrievedPlatform
                })
            }
        }
        return lines;
    }

    const errorLines = generateErrorLines();

    return (
        errorLines.length > 0 ?
            <Card
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 10,
                    borderRadius: 5,
                    paddingBottom: 0,
                    paddingTop: 50,
                    paddingBottom: 50,
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative',
                    width: '100%',
                    gap: 15,
                    marginBottom: 10
                }}>
                <svg
                    style={{ width: 80, height: 80 }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    fill='#B71C1C'
                >
                    <path d="M96 0C113.7 0 128 14.33 128 32V128H64V32C64 14.33 78.33 0 96 0zM288 0C305.7 0 320 14.33 320 32V128H256V32C256 14.33 270.3 0 288 0zM352 160C369.7 160 384 174.3 384 192C384 194.3 383.7 196.6 383.3 198.8C309.8 219.1 256 287.7 256 368C256 379.4 257.1 390.5 259.1 401.3C248.1 406.4 236.3 410.3 224 412.8V512H160V412.8C86.97 397.1 32 333.4 32 256V224C14.33 224 0 209.7 0 192C0 174.3 14.33 160 32 160H352zM288 368C288 288.5 352.5 224 432 224C511.5 224 576 288.5 576 368C576 447.5 511.5 512 432 512C352.5 512 288 447.5 288 368zM491.3 331.3C497.6 325.1 497.6 314.9 491.3 308.7C485.1 302.4 474.9 302.4 468.7 308.7L432 345.4L395.3 308.7C389.1 302.4 378.9 302.4 372.7 308.7C366.4 314.9 366.4 325.1 372.7 331.3L409.4 368L372.7 404.7C366.4 410.9 366.4 421.1 372.7 427.3C378.9 433.6 389.1 433.6 395.3 427.3L432 390.6L468.7 427.3C474.9 433.6 485.1 433.6 491.3 427.3C497.6 421.1 497.6 410.9 491.3 404.7L454.6 368L491.3 331.3z" />
                </svg>
                <h4 type={'h4'}>Platform(s) Access Revoked</h4>
                <Divider />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <ul
                        style={{
                            padding: 30,
                            paddingTop: 0
                        }}>
                        {
                            errorLines.map(x => {
                                return (
                                    <li>
                                        <div
                                            style={{
                                                display: 'flex',
                                                gap: 10,
                                                flexWrap: 'wrap',
                                                alignItems: 'center',
                                                marginBottom: 20,
                                                marginTop: 20
                                            }}>
                                            <div>
                                                <div style={{
                                                    width: 40,
                                                    height: 40,
                                                    borderRadius: '100%',
                                                    backgroundColor: x.retrievedPlatform.color,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    position: 'relative'
                                                }}>
                                                    <FontAwesomeIcon
                                                        icon={faBan}
                                                        style={{
                                                            position: 'absolute',
                                                            zIndex: 1,
                                                            bottom: -1,
                                                            right: 0,
                                                            color: 'red'
                                                        }}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={x.retrievedPlatform.icon}
                                                        style={{
                                                            color: 'white',
                                                            height: 20,
                                                            width: 20
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <span
                                                style={{
                                                    fontSize: 15,
                                                    flex: 1,
                                                    marginLeft: 20,
                                                }}
                                                dangerouslySetInnerHTML={{ __html: x.message }}></span>
                                            <Link
                                                to={x.route}
                                                style={{
                                                    minWidth: 100,
                                                    fontWeight: 'bold',
                                                    fontWeight: 14,
                                                    color: '#4DB6AC'
                                                }}>
                                                RECONNECT
                                            </Link>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </Card> :
            <div></div>
    )
}