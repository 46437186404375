import React, { useState, useEffect } from 'react';
import APIService from "../../../http/api_service";
import { toast } from "react-toastify";
import { setComposerFocused, setOpenDraftPrompt } from "../../../redux/slices/postslice";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "../../../constants";
import { faRemove, faLocationArrow, faSave, faDownload } from "@fortawesome/free-solid-svg-icons";
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { getPublishablePostMedia, setPublishablePostMedia } from 'redux/slices/post_media_slice';

const ResultPreview = React.forwardRef((props, ref) => {
    const postMediaObjects = [...useSelector(getPublishablePostMedia)];
    const workspace = useSelector(getActiveWorkSpace);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        text = "dummy",
        _id,
        url,
        referenceTag,
        fetchAllSavedArtCopies,
        selectedTheme,
        referenceTool,
        workSpace,
        description,
        canShowTool = false,
        ...others
    } = props;
    const [saved, setSaved] = useState(others.saved ?? false);
    const [processingSave, setProcessingSave] = useState(false);
    const [downloadText, setDownloadText] = useState("Download");
    const characterLength = text.length;
    const wordLength = text.split(' ').length;

    useEffect(() => {
        setSaved(others.saved ?? false);
    }, [others.saved]);

    const getImageThroughProxy = async (url, sendAsDataUrl) => {
        const { data } = await APIService.getImageThoughServerProxy(url, sendAsDataUrl);
        if (!data) {
            toast.error('Error! We could not get the image', { theme: 'colored' });
            return null;
        }
        return data?.data
    }

    const uploadArtToFirebase = async (file) => {
        if (!file) {
            toast.error('Error! We could not get the file object', { theme: 'colored' });
            return;
        }
        let uploadRequestBody = new FormData();
        let fileSize = file.size;
        uploadRequestBody.append("file", file);
        let response = await APIService.uploadNewFile(workspace._id, uploadRequestBody, fileSize, "all targets");
        return response
    }

    const dataUrlToFIleObject = async (dataURL, fileName = "dummy.jpg") => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const _blob = await (await fetch(dataURL)).blob();
        const blob = URL.createObjectURL(_blob);
        const file = new File([blob], fileName, { type: mime, lastModified: new Date() });
        return {
            file,
            blob
        };
    }

    const saveCopy = async () => {
        setProcessingSave(true);
        let ref = referenceTag ? referenceTag : referenceTool;
        if (!ref) {
            setProcessingSave(false);
            return;
        }
        if (!url) {
            toast.error('Error! Image seems not to be generated yet', { theme: 'colored' });
            return;
        }
        const file = await dataUrlToFIleObject(`data:image/jpeg;base64,${url}`, 'dummySet.jpg');
        const responseToImageUpload = await uploadArtToFirebase(file?.file);
        if (responseToImageUpload?.statusCode != 200) {
            toast.error('Error! We could not upload the image.', { theme: 'colored' });
            return;
        }
        let requestBody = {
            art_url: responseToImageUpload?.data,
            art_description: description
        }
        APIService.saveArtCopy(workSpace._id, requestBody, (response, error) => {
            setProcessingSave(false);
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            if (response) {
                setSaved(true);
                toast.success(response.message, { theme: 'colored' });
            }
            fetchAllSavedArtCopies();
        });
    }

    const unSaveCopy = () => {
        setProcessingSave(true);
        APIService.removeArtCopy(workSpace._id, _id, (response, error) => {
            setProcessingSave(false);
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            if (response) {
                setSaved(false);
                toast.success(response.message, { theme: 'colored' });
            }
            console.log('about running fetch saved copies')
            fetchAllSavedArtCopies(workspace, [], dispatch);
        });
    }

    const isFromFirebase = (link) => {
        if (link) {
            return link.startsWith('https://firebasestorage');
        }
        return url.startsWith('https://firebasestorage');
    }

    const createMediaObject = async (url) => {
        let newMediaObj = {};
        if (isFromFirebase()) {
            console.log('is from firebase')
            newMediaObj = {
                url,
                extension: '.jpeg',
                external: true,
                is_video: false,
                name: url,
                thumbnail: false,
                uploaded: true,
            }
        } else {
            console.log('is not from firebase')
            const response = await dataUrlToFIleObject(`data:image/jpeg;base64,${url}`, 'dummy');
            newMediaObj = {
                extension: '.jpeg',
                file: response?.file,
                external: false,
                is_video: false,
                name: 'dummy',
                thumbnail: false,
                url: response?.blob,
            }
        }

        return newMediaObj;
    }

    const handlePost = async () => {
        const mediaObjectCreated = await createMediaObject(url);
        dispatch(setPublishablePostMedia([...postMediaObjects, mediaObjectCreated]))
        dispatch(setComposerFocused(true));
        navigate(RoutePaths.POSTS);
        dispatch(setOpenDraftPrompt(false));
    }

    const downloadImage = async (imageUrl, fileName) => {
        let url;
        setDownloadText("Downloading...");
        if (isFromFirebase(imageUrl)) {
            url = await getImageThroughProxy(imageUrl, true);
            if (!url) {
                setDownloadText("Download");
                return;
            }
        } else {
            url = `data:image/jpeg;base64,${imageUrl}`
        }
        try {
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName); //or any other extension
            document.body.appendChild(link);
            await link.click();
            setDownloadText("Downloaded");
        } catch (e) {
            setDownloadText("Download");
        }
    }

    return (
        <Card
            ref={ref}
            style={{
                width: '100%',
                padding: 25,
                borderRadius: 5
            }}>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column'
                }}>
                {
                    url ?
                        <img src={isFromFirebase() ? url : `data:image/jpeg;base64,${url}`} alt="" height="200px" width="200px" style={{
                            marginBottom: 10,
                        }} />
                        : null
                }
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        gap: 10
                    }}>
                    <Button
                        variant='outline-primary'
                        className="rounded-pill me-1 mb-1"
                        style={{
                            minWidth: 90
                        }}
                        onClick={() => {
                            handlePost()
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 5,
                            }}>
                            <FontAwesomeIcon
                                icon={faLocationArrow}
                            />
                            <span>Post
                            </span>
                        </div>
                    </Button>
                    <Button
                        variant='outline-primary'
                        className="rounded-pill me-1 mb-1"
                        onClick={() => {
                            downloadImage(url, 'dummy.jpg')
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 5,
                            }}>
                            <FontAwesomeIcon
                                icon={faDownload}
                            />
                            <span>{downloadText}
                            </span>
                        </div>
                    </Button>
                    {
                        !isFromFirebase() &&
                        <Button
                            variant='outline-primary'
                            className="rounded-pill me-1 mb-1"
                            disabled={processingSave}
                            onClick={() => {
                                saveCopy()
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 5,
                                }}>
                                <FontAwesomeIcon
                                    icon={faSave}
                                />
                                <span>{processingSave ? 'Saving...' : 'Save'}
                                </span>
                            </div>
                        </Button>
                    }
                    {
                        saved && isFromFirebase() &&
                        <Button
                            variant='outline-primary'
                            className="rounded-pill me-1 mb-1"
                            disabled={processingSave}
                            isWorking={processingSave}
                            onClick={() => {
                                unSaveCopy()
                            }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 5,
                                }}>
                                <FontAwesomeIcon
                                    icon={faRemove}
                                />
                                <span>{processingSave ? 'Unsaving...' : 'Unsave'}
                                </span>
                            </div>
                        </Button>
                    }
                </div>
                <div className='text-end'>
                    <span
                        style={{
                            color: '#78909C',
                            fontSize: 10
                        }}>{`${wordLength} words / ${characterLength} chars`}
                    </span>
                </div>
            </div>
        </Card>
    )
});
export default ResultPreview;