import bg3 from 'assets/img/icons/spot-illustrations/corner-3.png';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getBotRequestsCount } from 'redux/slices/admin_slice';
import StatisticsCard from './stats-cards/StatisticsCard';
import { RoutePaths } from 'constants';

const TotalBotRequests = () => {
    const [stat, setStat] = useState({
        title: 'Bot Requests',
        value: 0,
        decimal: false,
        suffix: '',
        prefix: '',
        valueClassName: '',
        badgeBg: 'success',
        badgeText: '',
        // link: null,
        link: RoutePaths.ADMIN_BOT_REQUESTS,
        // linkText: null,
        linkText: 'See all',
        image: bg3
    })
    const botRequestsCount = useSelector(getBotRequestsCount)

    useEffect(() => {
        if (!botRequestsCount) return;
        setStat({
            ...stat,
            value: botRequestsCount
        })
    }, [botRequestsCount])


    return (
        <>
            <StatisticsCard stat={stat} style={{ minWidth: '12rem' }} />
        </>
    )
}

export default TotalBotRequests