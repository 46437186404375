import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import {
    setFirstComment
} from '../../../../redux/slices/postslice';
import { Button, Form, Card, Modal } from "react-bootstrap";
import Flex from "components/common/Flex";
import StringUtils from "utils/string";
import ConfirmModal from "components/common/ConfirmModal";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "constants";
import { useTranslation } from "react-i18next";
import { findPlatformByIdentifier } from "components/app/platforms";
import { PremiumGuard } from "components/common/PremiumGuard";

export default function FirstCommentView(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const firstComment = props.firstComment;
    const postTextBody = props.postTextBody;
    const mediaObjects = props.mediaObjects;
    const [firstCommentText, setFirstCommentText] = useState(firstComment?.firstCommentText);
    const [openFirstCommentBox, setOpenFirstCommentBox] = useState(false);
    const [wordCount, setWordCount] = useState(0);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [firstCommentErrorFlagged, setFirstCommentErrorFlagged] = useState({
        error: false,
        message: "50 words limit. Hashtags not allowed"
    });
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);

    useEffect(() => {
        setOpenFirstCommentBox(postTextBody?.length > 0 || mediaObjects.length > 0);
    }, [postTextBody, mediaObjects]);

    const validateFirstComment = () => {
        if (!firstCommentText) {
            setFirstCommentErrorFlagged({
                error: false,
                message: "50 words limit. Hashtags not allowed"
            });
            return;
        }
        let words = firstCommentText.trim().split(/\s+/);
        if (firstCommentText.includes("#") && words.length > 50) {
            setFirstCommentErrorFlagged({
                error: true,
                message: "You have exceeded the 50 words limit. Hashtags is not allowed"
            });
            return;
        }
        if (firstCommentText.includes("#")) {
            setFirstCommentErrorFlagged({
                error: true,
                message: "Hashtags not allowed"
            });
            return;
        }
        if (words.length > 50) {
            setFirstCommentErrorFlagged({
                error: true,
                message: "You have exceeded the 50 words limit."
            });
            return;
        }
        setFirstCommentErrorFlagged({
            error: false,
            message: "50 words limit. Hashtags not allowed"
        });

    }

    useEffect(() => {
        validateFirstComment()
    }, [firstCommentText])

    const handleTextChange = (e) => {
        try {
            const value = e.target.value;
            let words = value.trim().split(/\s+/);
            setWordCount(words.length);
            setFirstCommentText(value);

            if (firstCommentErrorFlagged.error) {
                setOpenErrorModal(true);
                return;
            }
            dispatch(setFirstComment({
                ...firstComment,
                firstCommentText: value,
                id: StringUtils.generateRandomString(10)
            }))

        } catch (e) {
            console.log(e, "as error from handleTextChange");
        }
    }

    const getLabel = () => {
        let platform = findPlatformByIdentifier(props.identifier ?? 'instagram');
        return t('platform_first_comment', { platform: platform.name });
    }

    return (
        <PremiumGuard>
            <>
                {
                    openFirstCommentBox ?
                        <>
                            <Card className="bg-100 mt-2 shadow-none" style={{ borderRadius: 0 }}>
                                <Flex className={'m-2'}>
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Form.Group>
                                            <Form.Label>{getLabel()}</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                style={{
                                                    padding: '10px',
                                                    borderColor: firstCommentErrorFlagged.error ? 'red' : '#ced4da',
                                                }}
                                                placeholder="Type first comment"
                                                value={firstCommentText}
                                                onChange={(e) => {
                                                    handleTextChange(e);
                                                }}
                                            />
                                        </Form.Group>
                                        <div
                                            style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                                        >
                                            <p
                                                className="fs--1 text-start p-1"
                                            >
                                                {
                                                    firstCommentErrorFlagged.error ?
                                                        <span className="text-danger">{firstCommentErrorFlagged.message}</span> :
                                                        <span style={{ color: '#509bfc' }}>{firstCommentErrorFlagged.message}</span>
                                                }
                                            </p>
                                            <p
                                                className="fs--1 text-start p-1"
                                            >
                                                {
                                                    wordCount > 50 ?
                                                        <span className="text-danger">{wordCount}/50 words</span> :
                                                        <span>{wordCount}/50 words</span>
                                                }

                                            </p>
                                        </div>
                                    </div>
                                </Flex>
                            </Card>
                        </> : <></>
                }
                <Modal
                    show={openErrorModal}
                    onHide={() => {
                        setOpenErrorModal(false);
                    }}
                    onExit={() => {
                        setOpenErrorModal(false);
                    }}
                >
                    <Modal.Header>
                        <Modal.Title>
                            <span className="text-danger ">Error</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="text-lg">
                            {firstCommentErrorFlagged.message}
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant={"primary"}
                            onClick={() => {
                                setOpenErrorModal(false);
                            }}
                        >
                            Got it
                        </Button>
                    </Modal.Footer>
                </Modal>
                <ConfirmModal
                    open={openUpgradeDialog}
                    title={"Upgrade Account"}
                    message={"This feature requires an account upgrade. Kindly upgrade to a premium account to continue"}
                    confirmText={"UPGRADE"}
                    cancelText={"CANCEL"}
                    onCancel={() => {
                        setOpenUpgradeDialog(false);
                    }}
                    onConfirm={() => {
                        setOpenUpgradeDialog(false);
                        navigate(`${RoutePaths.BILLING_STRIPE}`)
                    }}
                />
            </>
        </PremiumGuard>
    )
}