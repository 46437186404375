import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedPlatforms,
  setMunchedPostTargetIds,
  setSelectedPlatforms
} from 'redux/slices/postslice';
import { PanelNames } from '../../../../constants';
import { setSelectAllAudience } from '../../../../redux/slices/postslice';
import WritePostPanel from './WritePostPanel';
import PostAudiencePage from './audience';
import TourGuide from 'components/tour-guide';

export const freeUpLocallyCachedPlatforms = () => {
  let locallyCachedPlatforms = localStorage.getItem("cached_platforms");
  if (locallyCachedPlatforms) {
    locallyCachedPlatforms = JSON.parse(locallyCachedPlatforms);
    for (let platform of locallyCachedPlatforms) {
      platform.selected = false;
      for (let account of platform.accounts) {
        account.selected = false;
        if (account.connected_pages_and_groups) {
          for (let page of account.connected_pages_and_groups) {
            page.selected = false;
          }
        }
      }
    }
    localStorage.setItem("cached_platforms", JSON.stringify(locallyCachedPlatforms));
  }
}

const CreatePost = (props) => {
  const publishFeedBack = props.publishFeedBack;
  const dispatch = useDispatch();
  const selectedPlatforms = useSelector(getSelectedPlatforms);

  const handleCancelChanges = (evt) => {
    dispatch(setSelectedPlatforms([]));
    dispatch(setMunchedPostTargetIds([]));
    dispatch(setSelectAllAudience(false));
    setTimeout(() => {
      freeUpLocallyCachedPlatforms();
      setCurrentPanel(defaultPanel);
    }, 100);
  }

  const handleSavedChanges = (evt) => {
    setCurrentPanel(defaultPanel);
  }

  const goBack = () => {
    panelChangeHandler(PanelNames.CreatePost);
  }

  const panelChangeHandler = (newPanelName) => {
    switch (newPanelName) {
      case PanelNames.CreatePost:
        setCurrentPanel(defaultPanel);
        break;
      case PanelNames.PostAudience:
        setCurrentPanel(
          <PostAudiencePage
            previouslySelectedPlatforms={selectedPlatforms}
            goBack={goBack}
            handleCancelChanges={handleCancelChanges}
            handleSavedChanges={handleSavedChanges}
            selectedPlatformsHandler={(userSelectedPlatforms) => {
              dispatch(setSelectedPlatforms(userSelectedPlatforms));
            }}
          />
        );
        break;
      default:
        setCurrentPanel(defaultPanel);
        break;
    }
  }

  const defaultPanel = <WritePostPanel
    eliminateTimePicker={props.eliminateTimePicker}
    panelChangeHandler={panelChangeHandler}
    publishFeedBack={publishFeedBack}
    {...props}
  />

  const [currentPanel, setCurrentPanel] = useState(defaultPanel);

  return (
    <>
      {currentPanel}
      <TourGuide />
    </>
  );

};

export default CreatePost;
