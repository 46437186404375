import React, { useEffect, useState } from 'react';
import { PlatformIdentifier, RoutePaths } from '../../../../../constants';
import { useNavigate } from 'react-router-dom';
import AccountSubListItem from './account_sub_list_item';
import AccountListItem from './account_list_item';
import { munchTarget } from '../audience';
import {
    getMunchedPostTargetIds,
    setMunchedPostTargetIds,
    setUpdatedPlatform
} from '../../../../../redux/slices/postslice';
import { useSelector, useDispatch } from 'react-redux';
import { AudienceListItemContainer } from './styles';
import { Button } from 'react-bootstrap';

export const oneOfPageOwners = (account) => {
    return (
        account.identifier === PlatformIdentifier.INSTAGRAM
        || account.identifier === PlatformIdentifier.FACEBOOK
        || account.identifier === PlatformIdentifier.LINKED_IN
        || account.identifier === PlatformIdentifier.YOUTUBE
        || account.identifier === PlatformIdentifier.PINTEREST
        || account.identifier === PlatformIdentifier.REDDIT
        || account.identifier === PlatformIdentifier.TELEGRAM
        || account.identifier === PlatformIdentifier.GOOGLE_MY_BUSINESS
        || account.identifier === PlatformIdentifier.WORDPRESS
    );
}

export default function AccountsList(props) {

    const dispatch = useDispatch();
    const munchedTargetIds = [...useSelector(getMunchedPostTargetIds)];
    let { platform, platformChangedListener } = props;
    const navigate = useNavigate();
    const [accounts, setAccounts] = useState(platform.accounts);

    useEffect(() => {
        setAccounts([...props.platform.accounts]);
    }, [props.platform]);

    const emptyPagesSubTitle = (account) => {
        let start = 'No connected';
        let middle = 'page(s)'
        let end = 'Head over to the <b>Platforms</b> page to connect';
        if (account.identifier === PlatformIdentifier.YOUTUBE) {
            middle = 'channel(s)';
        } else if (account.identifier === PlatformIdentifier.PINTEREST) {
            middle = 'board(s)';
        } else if (account.identifier === PlatformIdentifier.REDDIT) {
            middle = 'subreddit(s)';
        } else if (account.identifier === PlatformIdentifier.TELEGRAM) {
            middle = 'Group(s) & Channel(s)';
        } else if (account.identifier === PlatformIdentifier.FACEBOOK) {
            middle = 'page(s)/group(s)';
        } else if (account.identifier === PlatformIdentifier.GOOGLE_MY_BUSINESS) {
            middle = "location(s)";
        }
        return `${start} ${middle}. ${end} ${middle}`;
    }

    const AccountEntry = (account) => {
        if (oneOfPageOwners(account)) {
            let connectedPages = JSON.parse(JSON.stringify(account.connected_pages_and_groups ?? []));
            if (connectedPages.length < 1) {
                return null
            }
            return (
                <AudienceListItemContainer
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    {/* <br /> */}
                    {
                        connectedPages.map((page) => {
                            return (
                                <AccountSubListItem
                                    id={`${page.id}${page.target}`}
                                    key={page.id}
                                    subListItem={page}
                                    accountId={account._id}
                                    subListItemChangedHandler={(changedSubListItem, accountId) => {
                                        account = JSON.parse(JSON.stringify(account));
                                        platform = JSON.parse(JSON.stringify(platform));
                                        let existingSubListItem = connectedPages.find((item) => item.id === changedSubListItem.id);
                                        if (existingSubListItem) {
                                            let indexOfExistingSubListItem = connectedPages.indexOf(existingSubListItem);
                                            if (indexOfExistingSubListItem !== -1) {
                                                connectedPages[indexOfExistingSubListItem] = changedSubListItem;
                                            }
                                        }
                                        let existingAccountWithId = accounts.find(item => item._id === accountId);
                                        if (existingAccountWithId) {
                                            let allAccountsSelected = existingAccountWithId.connected_pages_and_groups.every((item) => item.selected);
                                            account.selected = allAccountsSelected;
                                            account.connected_pages_and_groups = [...connectedPages];
                                            if (!allAccountsSelected) {
                                                let munchedAcc = munchTarget(accountId, account.identifier);
                                                let indexOfMunchedTarget = munchedTargetIds.indexOf(munchedAcc);
                                                if (indexOfMunchedTarget !== -1) {
                                                    munchedTargetIds.splice(indexOfMunchedTarget, 1);
                                                }
                                                dispatch(setMunchedPostTargetIds([...munchedTargetIds]));
                                            }
                                            let indexOfExistingAccountWithId = accounts.indexOf(existingAccountWithId);
                                            accounts[indexOfExistingAccountWithId] = account;
                                        }
                                        setAccounts([...accounts]);
                                        platform.accounts = [...accounts];
                                        dispatch(setUpdatedPlatform(platform));
                                        platformChangedListener(platform);
                                    }}
                                />
                            )
                        })
                    }
                </AudienceListItemContainer>
            );
        } else {
            return (
                <AccountListItem
                    id={account._id}
                    account={account}
                    accountChangedListener={(changedAccount) => {
                        platform = JSON.parse(JSON.stringify(platform));
                        let accountsCopy = JSON.parse(JSON.stringify(accounts));
                        let existingAccount = accountsCopy.find((item) => item._id === changedAccount._id);
                        if (existingAccount) {
                            let indexOfExistingAccount = accountsCopy.indexOf(existingAccount);
                            if (indexOfExistingAccount !== -1) {
                                accountsCopy[indexOfExistingAccount] = changedAccount;
                                setAccounts([...accountsCopy]);
                                platform.accounts = [...accountsCopy];
                                dispatch(setUpdatedPlatform(platform));
                                platformChangedListener(platform);
                            }
                        }
                    }}
                />
            )
        }
    };
    return (
        <AudienceListItemContainer
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}>
            {
                accounts.map((account) => {
                    return (
                        <div key={account._id}>
                            {AccountEntry(account)}
                        </div>
                    );
                })
            }
        </AudienceListItemContainer>
    );
}