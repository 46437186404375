import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: localStorage.getItem('theme_choice') ?? 'light'
};

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        toggleTheme: (state, action) => {
            let existingTheme = state.value;
            if (existingTheme === 'light') {
                state.value = 'dark'
            } else {
                state.value = 'light';
            }
            localStorage.setItem('theme_choice', state.value);
        },
        manuallySetTheme: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const { toggleTheme } = themeSlice.actions;
export const { manuallySetTheme } = themeSlice.actions;
export default themeSlice.reducer;