import ConfirmModal from 'components/common/ConfirmModal'
import Flex from 'components/common/Flex'
import APIService from 'http/api_service'
import React, { useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { CircularProgressbar } from 'react-circular-progressbar'
import { getActiveWorkSpace } from 'redux/slices/workspaceslice'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const PendingBotComponent = (props) => {
    const { x, fetchPendingBotRequests } = props
    const workSpace = useSelector(getActiveWorkSpace);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
    const [processing, setProcessing] = useState(false)

    const cancelBotRequestNow = () => {
        setProcessing(true)
        APIService.cancelPendingBotRequest(workSpace._id, x?._id, (response, error) => {
            setProcessing(false)
            if (error) {
                console.log(error);
                toast.error(error, { theme: 'colored' });
                return
            }
            const { message } = response
            toast.success(message, { theme: 'colored' });
            // cancellation done.
            fetchPendingBotRequests()
        });
    }

    return (
        <Card
            style={{
                display: "flex",
                padding: 10,
                borderRadius: 10,
                marginTop: 10,
                flexDirection: "column",
            }}
        >
            <Flex
                gap={3}
                style={{
                    margin: 10
                }}
                justifyContent={'between'}
            >
                <Flex
                    gap={3}
                >
                    <div style={{ width: 50, height: 50 }}>
                        <CircularProgressbar
                            strokeWidth={10}
                            value={x.progress}
                            text={`${x.progress}%`}
                            styles={{
                                path: {
                                    stroke: "#F57C00",
                                },
                                text: {
                                    fontWeight: "bold",
                                    fontSize: 20,
                                },
                            }}
                        />
                    </div>
                    <p
                    >
                        Your bot "<b>{x?.name}</b>" is currently being processed. You will be notified once it is ready.
                    </p>
                </Flex>
                <Flex
                    style={{
                        width:'20%'
                    }}
                >
                    <Button
                        onClick={() => {
                            setOpenConfirmationModal(x)
                        }}
                        className="fs--1"
                        disabled={processing}
                        variant={'danger'}
                        style={{
                            padding: "5px 10px",
                            height: 40
                        }}
                    >
                        {
                            processing ? "cancelling..." : " Cancel request"
                        }
                    </Button>
                </Flex>
            </Flex>
            <ConfirmModal
                open={openConfirmationModal}
                title={"Cancel Bot Request"}
                message={`Are you sure you want to cancel the request for the bot "${openConfirmationModal?.name}"?`}
                confirmText={"OK"}
                cancelText={"CLOSE"}
                onConfirm={() => {
                    cancelBotRequestNow()
                    setOpenConfirmationModal(false)
                }}
                onCancel={() => {
                    setOpenConfirmationModal(false)
                }}
            />
        </Card>
    )
}

export default PendingBotComponent