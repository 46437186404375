import { PlatformIdentifier } from 'constants';
import APIService from 'http/api_service';
import usePubSub from 'pubsub';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLoggedInUser } from 'redux/slices/user_slice';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { onPageSocketRegistryDestroyer, onPageSocketRegistryInitializer } from 'utils/on_page_socket_registry';
import { v4 as uuidv4 } from 'uuid';
import InstagramPage from './instagramPage';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { dispatchHandler } from 'utils/post_utils';
import { setPopupCustomAlert } from 'redux/slices/alertSlice';


const InstagramPages = (props) => {
    const { selectedProfile, setProfilePages, profilePages, setShowLess, showLess, selectAll, managePagesOrGroupIdsSelectedForAction, addOrRemoveAllPagesOrGroupIdsSelectedForAction, clearAllChecksTrigger } = props;

    const loggedInUser = useSelector(getLoggedInUser);
    const [res_id, setRes_id] = useState(`${loggedInUser?._id}_facebook_page_request_job_update_${uuidv4()}`);
    const { addPubSubEventListener, removePubSubEventListener, socket } = usePubSub();
    const dispatch = useDispatch();
    const platform = props.platform;
    const [dataCopy, setDataCopy] = useState([]);
    const [pages, setPages] = useState([]);
    const [doneWithFetch, setDoneWithFetch] = useState(false);
    const workSpace = useSelector(getActiveWorkSpace);
    const [lastGroupCursor, setLastGroupCursor] = useState();
    const [lastPageCursor, setLastPageCursor] = useState();
    const [statement, setStatement] = useState();
    const [constantPages, setConstantPages] = useState([]);
    const [allPages, setAllPages] = useState([]);
    const [showMore, setShowMore] = useState(false);
    let limit = 4

    const mergePages = (data, prevPages) => {
        let updatedPages = [...prevPages];
        for (let page of data) {
            let existingPage = prevPages.find((x) => x.id === page.id);
            if (!existingPage) {
                updatedPages.push(page);
            }
        }
        return updatedPages;
    }

    useEffect(() => {
        if (selectAll) {
            let selectedPages = profilePages[selectedProfile?._id]
            addOrRemoveAllPagesOrGroupIdsSelectedForAction(selectedPages, true);
        } else {
            addOrRemoveAllPagesOrGroupIdsSelectedForAction(null, false);
        }
    }, [selectAll])

    useEffect(() => {
        if (clearAllChecksTrigger) {
            addOrRemoveAllPagesOrGroupIdsSelectedForAction(null, false);
        }
    }, [clearAllChecksTrigger])


    const handleServerResponseOnPageFetching = (response) => {

        const { status, no_of_items, platform_id } = response;
        if (status === 'progress') {
            if (response?.statement) {
                setStatement(response.statement);
                return;
            }
            if (no_of_items) {
                setStatement(`${no_of_items} pages fetched. Please wait and do not leave this page...`);
            }
            return;
        }
        if (status === 'done') {
            setStatement(null);
            let responseData = response["data"] ?? [];
            responseData.sort((a, b) => {
                return b["connected"] - a["connected"];
            });
            console.log(responseData, 'responseData')
            if (pages.length > 0 && responseData.length > 0) {
                let alreadyExistingPageIds = [];
                for (let pageInResponse of responseData) {
                    let existingPage = pages.find((x) => x.id === pageInResponse.id);
                    if (existingPage) {
                        alreadyExistingPageIds.push(pageInResponse.id);
                    }
                }
                responseData = responseData.filter(
                    (x) => !alreadyExistingPageIds.includes(x.id)
                );
            }
            let firstGroupWithCursor = responseData.find(
                (x) => !x.is_page && x.nextPageCursor
            );
            let firstPageWithCursor = responseData.find(
                (x) => x.is_page && x.nextPageCursor
            );
            setPages((prevPages) => {
                prevPages = prevPages ?? [];
                for (let page of responseData) {
                    let existingPage = prevPages.find((x) => x.id === page.id);
                    if (!existingPage) {
                        prevPages.push(page);
                    }
                }
                let updatedPages = [...prevPages];
                setDataCopy(updatedPages);
                return updatedPages;
            });
            ///
            setAllPages((prevPages) => {
                prevPages = prevPages ?? [];
                for (let page of responseData) {
                    let existingPage = prevPages.find((x) => x.id === page.id);
                    if (!existingPage) {
                        prevPages.push(page);
                    }
                }
                let updatedPages = [...prevPages];
                setDataCopy(updatedPages);
                return updatedPages;
            });

            setProfilePages({
                ...profilePages,
                [platform_id]: mergePages(responseData, profilePages[platform_id] ?? [])
            })

            setConstantPages((prevPages) => {
                prevPages = prevPages ?? [];
                for (let page of responseData) {
                    let existingPage = prevPages.find((x) => x.id === page.id);
                    if (!existingPage) {
                        prevPages.push(page);
                    }
                }
                let updatedPages = [...prevPages];
                setDataCopy(updatedPages);
                return updatedPages;
            });
            ///
            setLastGroupCursor(firstGroupWithCursor?.nextPageCursor);
            setLastPageCursor(firstPageWithCursor?.nextPageCursor);
            // setCanLoadMore(responseData.length > 4);
            setDoneWithFetch(true);

        }
    }

    const fetchPagesAndGroups = (p, resId) => {
        APIService.fetchPagesAndGroups(
            workSpace,
            p.target ?? p.identifier,
            p.user_id,
            () => { },
            `?cursors=${encodeURIComponent(
                JSON.stringify({
                    pagesCursor: lastPageCursor,
                    groupsCursor: lastGroupCursor,
                })
            )}&res_id=${res_id}`
        );
    };
    const handleIncomingConnections = (incomingData) => {
        let { verb, group } = incomingData;
        let groupFootPrint = pages.find((x) => x.id === group.id);
        if (!verb.includes("add")) {
            if (groupFootPrint) {
                let indexOfGroupFootPrint = pages.indexOf(groupFootPrint);
                setPages((prevPages) => {
                    prevPages.splice(indexOfGroupFootPrint, 1);
                    return [...prevPages];
                });
            }
        } else {
            if (!groupFootPrint) {
                setPages((prevPages) => {
                    prevPages.push(group);
                    return [...prevPages];
                });
            }
        }
    };


    useEffect(() => {
        if (selectedProfile?._id && res_id) {
            fetchPagesAndGroups(selectedProfile);
            if (platform.target === PlatformIdentifier.FACEBOOK) {
                addPubSubEventListener(
                    `${workSpace._id}_facebook_group_install_update`,
                    handleIncomingConnections
                );
            }
            return () => {
                removePubSubEventListener(
                    `${workSpace._id}_facebook_group_install_update`,
                    handleIncomingConnections
                );
            };
        }
    }, [selectedProfile, res_id])

    useEffect(() => {
        if (selectedProfile) {
            let workSpacesEventListener = (response) => {
                let { error, data } = response;
                if (error) {
                    dispatchHandler(setPopupCustomAlert({
                        type: 'error',
                        message: `An error occurred while fetching pages and groups.`,
                    }));
                    setDoneWithFetch(true);
                    setStatement(error?.message ?? "An error occurred while fetching pages and groups.");
                    return;
                }
                handleServerResponseOnPageFetching(data);
            }
            onPageSocketRegistryInitializer(socket, res_id, workSpacesEventListener);
            return () => {
                console.log('Removing event listener')
                onPageSocketRegistryDestroyer(socket, res_id);
            }
        }
    }, [selectedProfile]);

    if (statement && !profilePages[selectedProfile?._id]?.length) {
        return <SoftBadge bg="info" className="h-2">
            <p className="m-0">{statement}</p>
        </SoftBadge>
    }

    if (!profilePages[selectedProfile?._id]?.length) {
        return <></>
    }


    const cutPagesSizeBasedOnShowLessStatus = () => {
        if (showLess) {
            return profilePages[selectedProfile?._id]?.slice(0, limit)?.map((page, index) => {
                return <InstagramPage
                    key={index}
                    page={page}
                    selectedProfile={selectedProfile}
                    selectAll={selectAll}
                    managePagesOrGroupIdsSelectedForAction={managePagesOrGroupIdsSelectedForAction}
                    clearAllChecksTrigger={clearAllChecksTrigger}
                />
            }
            )
        }
        return profilePages[selectedProfile?._id]?.map((page, index) => {
            return <InstagramPage
                key={index}
                page={page}
                selectedProfile={selectedProfile}
                selectAll={selectAll}
                managePagesOrGroupIdsSelectedForAction={managePagesOrGroupIdsSelectedForAction}
                clearAllChecksTrigger={clearAllChecksTrigger}
            />
        }
        )
    }



    return (
        <>
            {
                cutPagesSizeBasedOnShowLessStatus()
            }
            {
                profilePages[selectedProfile?._id]?.length > limit &&
                // <SoftBadge bg="success" className="h-2">
                //     <p className="m-0">+{profilePages[selectedProfile?._id]?.length - 5} more</p>
                // </SoftBadge>
                <Flex
                    alignItems={'center'}
                    className={'gap-2'}
                >
                    <SoftBadge
                        onClick={() => setShowLess(!showLess)}
                        bg="success cursor-pointer" className="h-2">
                        {
                            showLess ? `Show +${profilePages[selectedProfile?._id]?.length - limit} more` : `Show less`
                        }
                    </SoftBadge>
                </Flex>
            }
        </>
    )
}

export default InstagramPages