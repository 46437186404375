import styled from "styled-components";

export const DashboardContainer = styled.div`
   height: 100%;
   width: 100%;
   padding: 20px;
`;
export const NavArea = styled.div`
  height: 80px;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   gap: 10px;
`;
export const LeftNav = styled.div`
   height: 100%;
   width: 100%;
   flex: 1;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   gap: 10px;
`;
export const RightNav = styled.div`
   height: 100%;
   width: 100%;
   flex: 1;
   display: flex;
   align-items: center;
   justify-content: flex-end;
   gap: 20px;
`;
export const Headings = styled.div`
   display: flex;
   align-items: center;
   justify-content: flex-start;
   gap: 10px;
   font-size: 23px;
`;
export const Container = styled.section`
   margin-top: 20px;
   padding: 0 5%;
   min-height: 200px;
   width: 100%;
   max-height: 100%;
   overflow-y: auto; 
   background-color: red;
`;

