import { Image } from "react-bootstrap";
import ReactPlayer from "react-player";
import { isPhoto } from "utils/file_utils";

const CalendarVideoOrPhotoView = (props) => {

    let { width, prefetchVideos = true, mediaObjects } = props;

    return (
        <>
            {
                mediaObjects.map((mediaItem) => {
                    return (
                        isPhoto(mediaItem.url) && !mediaItem.is_video ?
                            <Image
                                src={mediaItem.url}
                                fluid
                                rounded
                                style={{
                                    cursor: 'pointer',
                                    objectFit: 'cover',
                                    height: 50,
                                    width: width
                                }}
                            />
                            : <ReactPlayer
                                config={{
                                    file: {
                                        attributes: {
                                            autoPlay: false,
                                            preload: prefetchVideos
                                        }
                                    }
                                }}
                                url={mediaItem.url}
                                controls={true}
                                className="react-player"
                                height={50}
                            />
                    )
                })
            }
        </>
    )
}
export default CalendarVideoOrPhotoView;