import Flex from 'components/common/Flex';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

const BuyImageCreditModal = (props) => {

    const {
        title, onCancel, body,
        centered = true,
    } = props;
    const [open, setOpen] = useState(props.open);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <Modal
            show={open}
            onHide={() => setOpen(false)}
            onExit={() => {
                if (onCancel) {
                    onCancel();
                }
            }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered={centered}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {body}
            </Modal.Body>
        </Modal>
    );
}

export default BuyImageCreditModal;