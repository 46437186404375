import AppContext from 'context/Context';
import React, { useContext } from 'react';
import { darkTheme } from "utils/theme";
import {
    BottomBottom, BottomLayer,
    BottomTop, ImageHolder, LinkingTag,
    ParentPreviewSection, TopLayer
} from './styles/link_previewer';

const LinkPreviewer = ({ domain, img, requestUrl, title, favicon }) => {
    const { config } = useContext(AppContext);
    if (!img) {
        return <></>
    }
    return (
        <LinkingTag href={requestUrl} target='_blank'>
            <ParentPreviewSection>
                <TopLayer>
                    <ImageHolder src={img} alt={title} />
                </TopLayer>
                <BottomLayer style={{
                    backgroundColor: `${!config.isDark ? "#ededed" : darkTheme.workSpaceSettingsHeaderBar
                        }`
                }}>
                    <BottomTop style={{
                        color: `${!config.isDark ? "" : darkTheme.textLight
                            }`
                    }}>
                        {
                            favicon &&
                            <img src={favicon} alt="" height={16} width={16} />
                        }
                        {domain}
                    </BottomTop>
                    <BottomBottom style={{
                        color: `${!config.isDark ? darkTheme.surfacePrimary : darkTheme.backgroundMedium
                            }`
                    }}>
                        {title}
                    </BottomBottom>
                </BottomLayer>
            </ParentPreviewSection>
        </LinkingTag>

    )
}

export default LinkPreviewer