import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import InfoBar from "components/common/InfoBar";
import { useState } from "react";
import {
    Alert,
    Button,
    Card,
    Modal,
    OverlayTrigger,
    Table,
    Tooltip
} from "react-bootstrap";
import {
    cachePosts,
} from "redux/slices/postslice";
import { isVideo } from "utils/file_utils";
import StringUtils from "utils/string";
import { useDispatch, useSelector } from 'react-redux';
import APIService from "http/api_service";
import { toast } from "react-toastify";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { setPublishablePostTextBody } from "redux/slices/post_text_body_slice";
import { setPublishablePostMedia } from "redux/slices/post_media_slice";
import { setEditablePost, setOpenPostDetailsDialog, setAboutRepublishingPost } from 'redux/slices/postslice'
import { RoutePaths } from '../../../../constants'
import { useNavigate } from "react-router-dom";

const FailedTargetsButton = (props) => {
    let { parentModal, _id, data, post } = props;
    const workSpace = useSelector(getActiveWorkSpace);
    const dispatch = useDispatch();
    let failedTargets = data.failed_targets
    const [openFailedTargetsPreview, setOpenFailedTargetsPreview] = useState(false);
    const [dismissible, setDismissible] = useState(true);
    const [acknowlegding, setAcknowlegding] = useState(false);
    const navigate = useNavigate();
    const [rescheduleButton, setRescheduleButton] = useState('Reschedule to Failed Targets')
    const url = window.location.href;
    const isPublished = url.includes(`/published`);

    if (!failedTargets) {
        return (<></>);
    }

    const getFailedTargetsCount = () => {
        let failedTargetsCount = 0;
        if (failedTargets) {
            let failedTargetsArray = Object.entries(failedTargets);
            for (let entry of failedTargetsArray) {
                let failedCount = Object.entries(entry[1]).length;
                failedTargetsCount += failedCount;
            }
        }
        return failedTargetsCount;
    }

    const getFailedTargets = () => {
        let items = [];
        let failedTargetsArray = Object.entries(failedTargets);
        for (let entry of failedTargetsArray) {
            let failedEntries = Object.entries(entry[1]);
            for (let failedEntry of failedEntries) {
                items.push(failedEntry[1]);
            }
        }
        return items;
    }

    const restructureWithFailedTargets = (failedTargets, originalTargets) => {
        let newOriginalTargets = [];
        for (const service of Object.keys(failedTargets)) {
            let targetPlatform = originalTargets?.find(entry => entry.identifier === service)
            let targetAccounts = targetPlatform?.accounts || []
            let newAccountTargets = []
            for (const [key, value] of Object.entries(failedTargets[service])) {
                for (const account of targetAccounts) {
                    if (!account?.connected_pages_and_groups) {
                        if (account?.id !== key) {
                            continue;
                        } else {
                            newAccountTargets.push(account);
                        }
                    } else {
                        let page = account.connected_pages_and_groups.find(entry => entry.id === key)
                        if (page) {
                            let newAccountConnectedPagesAndGroups = newAccountTargets?.find(entry => entry._id === account?._id);
                            if (!newAccountConnectedPagesAndGroups) {
                                let newAcc = { ...account, connected_pages_and_groups: [page] }
                                newAccountTargets.push(newAcc);
                                continue;
                            }
                            let accId = newAccountConnectedPagesAndGroups?._id;
                            let filteredOffConnectedPagesAndGroups = [...newAccountConnectedPagesAndGroups?.connected_pages_and_groups]
                            filteredOffConnectedPagesAndGroups.push(page);
                            const index = newAccountTargets.findIndex(entry => entry._id === accId);
                            if (index !== -1) {
                                newAccountTargets[index] = newAccountConnectedPagesAndGroups;
                            }
                            continue;
                        }
                    }
                }
            }
            if (!newAccountTargets?.length) continue;
            targetPlatform.accounts = newAccountTargets
            newOriginalTargets.push(targetPlatform);
        }
        return newOriginalTargets
    }

    const rescheduleToFailedTargets = () => {
        setRescheduleButton('Processing, please wait...')
        setAcknowlegding(true)
        const newTargets = restructureWithFailedTargets(failedTargets, data?.targets)
        let newData = { ...data, targets: newTargets }
        navigate(RoutePaths.POSTS);
        dispatch(setOpenPostDetailsDialog({ open: false, data: null }));
        dispatch(setEditablePost({ ...post, data: newData }));
        dispatch(setAboutRepublishingPost(true));
    }

    const failed = getFailedTargets();

    const acknowlegeErrors = () => {
        setDismissible(false);
        setAcknowlegding(true);
        delete data.failed_targets;
        APIService.updatePost(workSpace._id, _id, data, (response, error) => {
            setAcknowlegding(false);
            setDismissible(true);
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let returnedData = response.data;
            let tempPosts = localStorage.getItem("cached_posts") ? JSON.parse(localStorage.getItem("cached_posts")) : [];
            let existingPost = tempPosts.find(x => x._id === _id);
            if (existingPost) {
                let indexOfPost = tempPosts.indexOf(existingPost);
                tempPosts[indexOfPost] = returnedData;
                dispatch(cachePosts([]));
                dispatch(cachePosts([...tempPosts]));
                setOpenFailedTargetsPreview(false);
            }
        })
    }
    if (isPublished) {
        return <></>
    }
    return (
        <>
            <OverlayTrigger
                overlay={<Tooltip>Click to see more</Tooltip>}>
                <Button
                    className="cursor-pointer mt-1 p-0 px-2 fs--2"
                    variant={'danger'}
                    onClick={() => {
                        setOpenFailedTargetsPreview(true);
                    }}>
                    <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 5 }} />
                    <span>{getFailedTargetsCount()} {`${getFailedTargetsCount() === 1 ? 'target' : 'targets'}`} failed</span>
                </Button>
                {/* <Alert
                    className="cursor-pointer mt-1 p-0 px-2 fs--2"
                    variant={'danger'}
                    onClick={() => {
                        setOpenFailedTargetsPreview(true);
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 3
                        }}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        <span>{getFailedTargetsCount()} {`${getFailedTargetsCount() === 1 ? 'target' : 'targets'}`} failed</span>
                    </div>
                </Alert> */}
            </OverlayTrigger>
            <Modal
                show={openFailedTargetsPreview}
                backdrop={dismissible}
                centered
                size="lg"
                onHide={() => {
                    setOpenFailedTargetsPreview(false);
                    setDismissible(true);
                }}
                onExit={() => {
                    setOpenFailedTargetsPreview(false);
                    setDismissible(true);
                }}>
                <Flex
                    direction={'column'}
                    className={'p-5'}>
                    <h5>Sorry, we couldn't publish to the following targets</h5>
                    <Card
                        className="my-3 mt-5 px-2"
                        style={{
                            maxHeight: 500,
                            overflowY: 'scroll'
                        }}>
                        <Table
                            striped
                            responsive>
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Reason for Failure</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    failed.map(({ targetName, failureReason }, index) => {
                                        return (
                                            <tr key={index}>
                                                <td
                                                    style={{
                                                        width: '50%',
                                                        verticalAlign: 'middle'
                                                    }}>
                                                    <InfoBar
                                                        variant={'danger'}
                                                        info={targetName}
                                                    />
                                                </td>
                                                <td
                                                    style={{
                                                        verticalAlign: 'middle'
                                                    }}>
                                                    <Alert variant={'info'}>
                                                        {failureReason}
                                                    </Alert>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Card>
                    <Flex
                        className="mt-4"
                        alignItems={'center'}>
                        <OverlayTrigger overlay={<Tooltip>When you acknowlege, the failed targets error badge will be removed permanently from the post</Tooltip>}>
                            <Button
                                disabled={acknowlegding}
                                onClick={() => {
                                    acknowlegeErrors();
                                }}
                                variant="outline-info"
                                size="sm">
                                {acknowlegding ? "ACKNOWLEDGING..." : "ACKNOWLEDGE"}
                            </Button>
                        </OverlayTrigger>
                        <div style={{ flex: 1 }}></div>
                        <Flex
                            alignItems={'end'}
                            justifyContent={'end'}>
                            <Flex
                                className={'text-end gap-2'}
                                alignItems={'center'}
                                style={{
                                    alignSelf: 'flex-end'
                                }}>
                                <Button
                                    disabled={acknowlegding}
                                    size="sm"
                                    variant="default"
                                    onClick={() => {
                                        setOpenFailedTargetsPreview(false);
                                    }}>CLOSE
                                </Button>
                                <Button
                                    disabled={acknowlegding}
                                    size="sm"
                                    variant="primary"
                                    onClick={() => {
                                        rescheduleToFailedTargets();
                                    }}>
                                    {rescheduleButton}
                                </Button>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Modal>
        </>
    )
}
export default FailedTargetsButton;