import React, { useState, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { Picker } from "emoji-mart";
import AppContext, { ChatContext } from "context/Context";
import { getColor } from "helpers/utils";
import "emoji-mart/css/emoji-mart.css";
import TextareaAutosize from "react-textarea-autosize";
import { useSelector } from "react-redux";
import APIService from "http/api_service";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { useFetchPageOwner } from "../hooks/useFetchUser";
import { toast } from "react-toastify";

const MAX_FILE_SIZE = 9856665;

// Helper function to determine media type based on file type
export const formatDate = (dateString) => {
  if (!dateString) return null;

  let date = dateString; // Assume the input is a date string
  if (typeof dateString !== "string") {
    // If it's not a string, assume it's a timestamp and convert it to a Date object
    date = new Date(dateString);
  }

  const optionsTime = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const optionsDate = {
    month: "long",
    day: "numeric",
    year: "numeric",
  };

  const optionsDay = {
    weekday: "long",
  };

  const now = new Date();
  const timeDifference = now - date;
  const hoursAgo = Math.floor(timeDifference / (1000 * 60 * 60));

  let time = date.toLocaleString("en-US", optionsTime);
  let formattedDate = date.toLocaleString("en-US", optionsDate);
  let day = date.toLocaleString("en-US", optionsDay);
  let ago = "";

  if (hoursAgo < 1) {
    ago = "just now";
  } else if (hoursAgo < 24) {
    ago = `${hoursAgo} hour${hoursAgo > 1 ? "s" : ""} ago`;
  }

  return {
    time: time,
    date: formattedDate,
    day: day,
    ago: ago,
  };
};

const uploadMedia = async (mediaItems, workspace) => {
  if (!mediaItems || mediaItems.length === 0) {
    return [];
  }

  const uploadResults = [];

  const uploadPromises = mediaItems.map(async (file, index) => {
    if (file.size > MAX_FILE_SIZE) {
      return null;
    }

    if (!file.uploaded) {
      const uploadRequestBody = new FormData();
      const fileSize = file.size;
      const fileType = determineMediaType(file.type);
      const originalFile = file.type;
      uploadRequestBody.append("file", file);

      try {
        const response = await APIService.uploadNewFile(
          workspace._id,
          uploadRequestBody,
          fileSize,
          "all targets",
          index + 1
        );

        const result = {
          type: fileType,
          payload: {
            url: response?.data,
          },
          OriginalFileType: originalFile,
          size: fileSize,
        };

        uploadResults.push(result);
      } catch (error) {
        console.error(error);
      }
    }

    return null;
  });

  if (uploadPromises.some((result) => result === null)) {
    //Stop execution if any file is greater than the max size
    return;
  }
  // Wait for all uploads to complete
  await Promise.all(uploadPromises);

  return uploadResults;
};

function formatFileSize(bytes) {
  const megabytes = bytes / (1024 * 1024);
  return megabytes.toFixed(2) + " MB";
}

const temporalMediaUpload = async (mediaItems) => {
  if (!mediaItems || mediaItems.length === 0) {
    return [];
  }

  const uploadResults = await Promise.all(
    mediaItems.map(async (file) => {
      if (file.size > MAX_FILE_SIZE) {
        toast(
          <span className="text-warning">
            Your file size must be below {formatFileSize(MAX_FILE_SIZE)}.
          </span>
        );
        return null;
      }
      if (!file.uploaded) {
        const fileSize = file.size;
        const fileType = determineMediaType(file.type);
        const originalFile = file.type;
        const mediaUrl = URL.createObjectURL(file);

        const result = {
          type: fileType,
          payload: {
            url: mediaUrl,
          },
          OriginalFileType: originalFile,
          size: fileSize,
        };

        return result;
      }

      return null;
    })
  );

  if (uploadResults.some((result) => result === null)) {
    //Stop execution if any file is greater than the max size
    return;
  }

  // return uploadResults.filter(Boolean); // Filter out null values if any
  return uploadResults;
};

const determineMediaType = (fileType) => {
  if (fileType.includes("image")) {
    return "image";
  } else if (fileType.includes("video")) {
    return "video";
  } else if (fileType.includes("audio")) {
    return "audio";
  } else {
    return null; // Adjust this as needed
  }
};

function getLastMessageType(message) {
  if (message?.message?.attachments) {
    let lastMessageType = message?.message?.attachments[0];
    if (lastMessageType?.type === "image") {
      return "Photo";
    } else if (lastMessageType?.type === "video") {
      return "Video";
    } else if (lastMessageType?.type === "audio") {
      return "Audio";
    }
  } else {
    return message?.message?.text || "";
  }
}

const processMediaFiles = async (files, workspace) => {
  let demoMedia;
  let firebaseMedia;

  if (files?.length !== 0) {
    // Process demoUrl asynchronously
    demoMedia = await temporalMediaUpload(files);

    // Start processing media, but don't await it here
    firebaseMedia = uploadMedia(files, workspace);
  }

  return { demoMedia, firebaseMedia };
};

const MessageTextArea = () => {
  const {
    setScrollToBottom,
    conversations,
    activeRoomId,
    senderId,
    setCurrentConversation,
    conversationsDispatch,
    currentChatsDispatch,
    users,
    isOpenThreadInfo,
    setUserChats,
  } = useContext(ChatContext);
  const [previewEmoji, setPreviewEmoji] = useState(false);
  const inputRef = useRef(null);
  const [formData, setFormData] = useState({
    message: "",
    files: [],
  });
  const { files, message } = formData;
  const { user } = useFetchPageOwner(activeRoomId, conversations, users) ?? {};

  const workspace = useSelector(getActiveWorkSpace);

  const {
    config: { isDark, isRTL },
  } = useContext(AppContext);
  const addEmoji = (e) => {
    let emoji = e.native;
    setFormData((prev) => ({ ...prev, message: message + emoji }));
    setPreviewEmoji(false);
    inputRef.current.focus();
  };

  const createText = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const date = new Date();
    const chatRoomId = activeRoomId;

    if ((files?.length !== 0 && message) || (files?.length === 0 && !message)) {
      toast(
        <span className="text-warning">
          {files?.length !== 0
            ? "You can't send both image and text"
            : "You can't send an empty field"}
        </span>
      );
      return;
    }

    let media;
    let demoUrl;

    if (files?.length !== 0) {
      setFormData({
        ...formData,
        message: "",
      });
      const { demoMedia, firebaseMedia } = await processMediaFiles(
        files,
        workspace
      );
      media = firebaseMedia;
      demoUrl = demoMedia;

      //? Check file size for each file before proceeding
      for (const file of files) {
        if (file.size > MAX_FILE_SIZE) {
          return;
        }
      }
    }

    const newConversation = conversations?.find(
      ({ room_id }) => room_id === chatRoomId
    );

    const newMessage = {
      sender: newConversation?.user_id,
      message: {
        ...(files?.length !== 0
          ? {
              attachments: demoUrl,
              from: "user",
            }
          : {
              text: `${message?.replace(/(?:\r\n|\r|\n)/g, "<br>")}`,
            }),
        avatar: user?.user_photo,
        name: user?.user_name,
      },
      read: false,
      status: "pending",
      time: date,
    };

    const { messages: roomChats } = newConversation;
    const updatedMessages = [...roomChats, newMessage];
    const lastMessage =
      updatedMessages?.length > 0
        ? updatedMessages[updatedMessages?.length - 1]
        : "";
    const commonPayload = {
      messages: updatedMessages,
    };

    const commonPayloadWithConversation = {
      ...newConversation,
      ...commonPayload,
    };

    conversationsDispatch({
      type: "ADD_MESSAGE",
      payload: commonPayloadWithConversation,
      room_id: chatRoomId,
    });

    currentChatsDispatch({
      type: "ADD_MESSAGE",
      payload: commonPayloadWithConversation,
      room_id: chatRoomId,
      isUpdatedStart: true,
    });

    setUserChats((prevUserChats) => {
      const updatedUserChats = prevUserChats.map((chat) => {
        if (chat.room_id === chatRoomId) {
          const updatedMessages = [...chat.messages, newMessage];
          return {
            ...chat,
            messages: updatedMessages,
            last_message: getLastMessageType(lastMessage),
            last_message_time: lastMessage?.time,
            last_message_status: lastMessage?.status,
            last_message_sender: lastMessage?.sender,
            last_message_read: lastMessage?.read,
          };
        }
        return chat;
      });

      return updatedUserChats;
    });

    setCurrentConversation({ ...newConversation, messages: updatedMessages });

    setFormData({
      message: "",
      files: [],
    });
    setScrollToBottom(true);

    if (media) {
      const mediaResult = await media; // Wait for the media promise to resolve
      console.log("mediaResult", mediaResult);

      const messageToSend = {
        data: {
          message: { attachments: mediaResult },
        },
        message: {
          attachments: mediaResult,
          from: "user",
          avatar: user?.user_photo,
          created_time: `${formatDate(date)?.date} ${formatDate(date)?.time}`,
          name: user?.user_name,
        },
      };

      // Send the POST request to the backend as soon as the media result is available
      const requestData = {
        senderPSID: newConversation?.user_id,
        recipientId: senderId,
        message: messageToSend,
        platform: newConversation?.identifier,
      };

      await Promise.all([
        new Promise((resolve) => setTimeout(resolve, 2000)),
        APIService.sendMessageFromSocialInbox(
          chatRoomId,
          requestData,
          (response, error) => {
            if (error) {
              toast.error(error, { theme: "colored" });
              return;
            }
            let message = response["message"];
            toast.success(message, { theme: "colored" });
          }
        ),
      ]);
    } else {
      const textMessage = {
        data: {
          message: { text: message },
        },
        message: {
          text: message,
          avatar: user?.user_photo,
          created_time: `${formatDate(date)?.date} ${formatDate(date)?.time}`,
          name: user?.user_name,
        },
      };
      const requestData = {
        senderPSID: newConversation?.user_id,
        recipientId: senderId,
        message: textMessage,
        platform: newConversation?.identifier,
      };
      // Send the POST request to the backend when no media is present
      await Promise.all([
        new Promise((resolve) => setTimeout(resolve, 2000)),
        APIService.sendMessageFromSocialInbox(
          chatRoomId,
          requestData,
          (response, error) => {
            if (error) {
              toast.error(error, { theme: "colored" });
              return;
            }
            let message = response["message"];
            toast.success(message, { theme: "colored" });
          }
        ),
      ]);
    }
  };

  const handleTextareaKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSubmit(e);
    }
  };

  const handleFileInputChange = (e) => {
    const selectedFiles = Array.from(e?.target?.files);
    setFormData((prev) => ({ ...prev, files: selectedFiles }));
    inputRef.current.focus();
  };

  useEffect(() => {
    if (isOpenThreadInfo) {
      setPreviewEmoji(false);
    }
  }, [isOpenThreadInfo]);

  const displayFile = (file, index) => {
    return file?.type?.startsWith("image/") ? (
      <img
        src={URL.createObjectURL(file)}
        alt={`uploaded file no. ${index}`}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          // aspectRatio: '1/1',
        }}
      />
    ) : file?.type?.startsWith("video/") ? (
      <video
        controls
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <source src={URL.createObjectURL(file)} type={file?.type} />
      </video>
    ) : file?.type?.startsWith("audio/") ? (
      <audio style={{ width: "100%" }} controls>
        <source src={URL.createObjectURL(file)} type={file?.type} />
        Your browser does not support the audio tag.
      </audio>
    ) : (
      <p
        style={{
          color: "red",
        }}
      >
        Unsupported file type
      </p>
    );
  };

  const removeFile = (index) => {
    const updatedFiles = [...formData?.files];
    updatedFiles.splice(index, 1); // Remove the file at the specified index
    setFormData({
      ...formData,
      files: updatedFiles,
    });
  };

  return (
    <Form className="chat-editor-area" onSubmit={handleSubmit}>
      {files?.length === 0 && (
        <TextareaAutosize
          ref={inputRef}
          minRows={1}
          maxRows={6}
          name="message"
          value={message}
          onKeyDown={handleTextareaKeyDown}
          onChange={createText}
          placeholder="Type your message"
          className="form-control outline-none resize-none rounded-0 border-0 emojiarea-editor"
        />
      )}

      {files?.length === 0 && (
        <>
          <Form.Group controlId="chatFileUpload">
            <Form.Label className="chat-file-upload cursor-pointer">
              <FontAwesomeIcon icon="paperclip" />
            </Form.Label>
            <Form.Control
              name="files"
              type="file"
              multiple
              onChange={handleFileInputChange}
              className="d-none"
            />
          </Form.Group>
          <Button
            variant="link"
            className="emoji-icon"
            onClick={() => setPreviewEmoji(!previewEmoji)}
          >
            <FontAwesomeIcon
              icon={["far", "laugh-beam"]}
              onClick={() => setPreviewEmoji(!previewEmoji)}
            />
          </Button>{" "}
        </>
      )}
      {files?.length > 0 && (
        <div
          className="previewFiles"
          style={{
            display: "grid",
            gridAutoFlow: "dense",
            gap: ".5rem",
            maxWidth: "430px",
            maxHeight: "300px",
            overflowX: "hidden",
            overflowY: "auto",
            backdropFilter: "blur(20px)",
            gridTemplateColumns:
              " repeat(auto-fit, minmax(min(100%, 200px), 1fr))",
            position: "absolute",
            bottom: "0",
            left: isRTL ? "2%" : "auto",
            right: isRTL ? "auto" : "2%",
            padding: 0,
            zIndex: 4,
          }}
        >
          {files?.map((file, index) => (
            <div
              key={index}
              className="previewFile"
              style={{
                display: "grid",
                placeItems: "center",
                gap: "1rem",
                position: "relative",
                overflow: " hidden",
                padding: "8px",
                width: "200px",
                height: "200px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                boxShadow: "0 0 4px rgba(0, 0, 0, 0.2)",
              }}
            >
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => removeFile(index)}
                style={{
                  cursor: "pointer",
                  fontSize: "20px",
                  color: "red",
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                }}
              />
              {displayFile(file, index)}
            </div>
          ))}
          <div
            style={{
              position: "sticky",
              gridColumn: "1 / -1",
              display: "grid",
              placeItems: "center",
              bottom: "0",
              top: "50px",
              marginBottom: "20px",
              width: "100%",
            }}
          >
            <button
              type="submit"
              style={{
                backgroundColor: "#2c7be5",
                borderRadius: "20px",
                padding: "10px",
                border: "0",
                color: "white",
                width: "200px",
              }}
            >
              Send
            </button>
          </div>
        </div>
      )}

      {previewEmoji && files?.length === 0 && (
        <Picker
          set="google"
          onSelect={addEmoji}
          sheetSize={20}
          style={{
            position: "absolute",
            bottom: "2rem",
            left: isRTL ? "2%" : "auto",
            right: isRTL ? "auto" : "2%",
            padding: 0,
            zIndex: 1,
            backgroundColor: getColor("100"),
          }}
          theme={isDark ? "dark" : "light"}
          showPreview={false}
          showSkinTones={false}
        />
      )}

      {files?.length === 0 && (
        <Button
          variant="send"
          size="sm"
          className={classNames({
            "text-primary": message.length > 0,
          })}
          type="submit"
        >
          Send
        </Button>
      )}
    </Form>
  );
};

MessageTextArea.propTypes = {
  thread: PropTypes.object,
};

export default MessageTextArea;