/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { MdPersonRemove } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { setUserTags } from 'redux/slices/postslice'
import { v4 as uuid } from 'uuid'
import DebounceableEditBox from '../debounceable_edit_box'
import { EachTagList, Header, MainContainer, TagContainer } from './styles/igTagDrop'

const IgTagDrop = (props) => {
    const { tags, currentTaggableImageIndex, useableMedia, setCanUpdateTags } = props;
    const dispatch = useDispatch()
    const [search, setSearch] = useState('')
    const [addButtonText, setAddButtonText] = useState('Add Tag')
    const [headerStatusOnTyping, setHeaderStatusOnTyping] = useState(false)
    const [activeMedia, setActiveMedia] = useState();

    useEffect(() => {
        const _activeMedia = useableMedia[currentTaggableImageIndex];
        setActiveMedia(_activeMedia);
    }, [useableMedia])



    const removeTag = (tag) => {
        if (!tag) return;
        const _tags = [...tags];
        const filteredTags = _tags?.filter((t, index) => t.id !== tag.id);
        setCanUpdateTags(true);
        dispatch(setUserTags(filteredTags));
    }


    const displayTags = (
        tags.map((tag, index) => (
            <EachTagList key={index}>
                {tag.username}
                <MdPersonRemove
                    size={20}
                    style={{
                        cursor: 'pointer',
                    }}
                    onClick={
                        () => removeTag(tag)
                    }
                />
            </EachTagList>
        ))
    )

    const handleSave = () => {
        if (!search) {
            return;
        }
        let template = { x: 0, y: 0, id: uuid(), imageUrl: activeMedia.url, username: search }
        setCanUpdateTags(true);
        dispatch(setUserTags([...tags, template]))
    }

    return (
        <>
            <MainContainer>
                <Header>
                    {
                        headerStatusOnTyping ?
                            <DebounceableEditBox
                                type='text'
                                placeholder={"E.g @johnson"}
                                onKeyDown={(e) => e.key === 'Enter' && handleSave()}
                                onChange={(value) => {
                                    setSearch(value);
                                }}
                                style={{
                                    width: '100%'
                                }}
                            /> : 'Tagged People'
                    }
                    <Button
                        size='sm'
                        variant={'none'}
                        className='px-2 cursor-pointer'
                        onClick={() => {
                            setAddButtonText(addButtonText === "Add" ? 'Add Tag' : 'Add');
                            if (headerStatusOnTyping) {
                                handleSave()
                            } else {

                            }
                            setHeaderStatusOnTyping(!headerStatusOnTyping)
                        }}
                    >
                        {addButtonText}
                    </Button>
                </Header>
                <TagContainer>
                    {displayTags}
                </TagContainer>
            </MainContainer>
        </>
    )
}

export default IgTagDrop