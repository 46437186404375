import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../constants';
import APIService from 'http/api_service';
import { useDispatch } from 'react-redux';
import { setLoggedInUser } from 'redux/slices/user_slice';
import { setActiveWorkspace } from 'redux/slices/workspaceslice';
import { resetAllStores } from 'state/all_stores';

const LogoutPromptContent = ({ titleTag: TitleTag, stateHandler }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logOut = () => {
        APIService.logOut((res, err) => { });
        dispatch(setLoggedInUser(null));
        dispatch(setActiveWorkspace(null));
        dispatch({ type: 'RESET' });
        localStorage.clear();
        resetAllStores();
    }

    return (
        <>
            <TitleTag>Are you sure you want to log out?</TitleTag>
            <Flex
                alignItems={'center'}
                justifyContent={'center'}
                className={'gap-2'}>
                <Button
                    size="m"
                    className="mt-3 btn-light"
                    onClick={() => {
                        navigate(RoutePaths.DEFAULT)
                    }}
                >
                    NO
                </Button>
                <Button
                    size="m"
                    className="mt-3 btn-primary"
                    onClick={() => {
                        logOut();
                        localStorage.clear();
                        stateHandler(true);
                    }}
                >
                    Yes
                </Button>
            </Flex>
        </>
    );
};

LogoutPromptContent.propTypes = {
    layout: PropTypes.string,
    titleTag: PropTypes.string
};

LogoutPromptContent.defaultProps = {
    layout: 'simple',
    titleTag: 'h4'
};

export default LogoutPromptContent;
